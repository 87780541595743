// 看板 -- 中文简体
module.exports = {
    Kanban:{
        a1:'年度累計',
        a2:'月度累計',
        a3:'日度累計',
        a4:'環比',
        a5:'實时功率',
        a6:'年度KPI',
        a7:'月度KPI',
        a8:'碳排放',
        a9:'清潔能源發電',
        a10:'看板設定',
        a11:'實时功率',
        a12:'總功率',
        a13:'顯示',
        a14:'資料類型',
        a15:'顯示層級',
        a16:'關聯網站',
        a17:'網站地址',
        a18:'數據比較',
        a19:'分段規則使用',
        a20:'使用網站分段規則',
        a21:'統一使用建築分段規則',
        a22:'統一使用客戶分段規則',
        a23:'當選擇“使用站點分段規則”時，如果對應站點未設定分段規則，則使用建築的分段規則。 當資料類型選擇“日”時； 統一使用建築分段規則。',
        a24:'當選擇“使用站點分段規則”時，如果對應站點未設定分段規則，則使用客戶的分段規則。 當資料類型選擇“日”時； 統一使用客戶分段規則。',
        a25:'每月快捷全選設定',
        a26:'每小時快捷全選設定',
        a27:'快捷選擇',
        a28:'嚴重警告',
        a29:'一般警告',
        a30:'已恢復',
        a31:'未恢復',
        a32:'返回',
        a33:'每日碳排放',
        a34:'每日使用量',
        a35:'注意',
        a36:'KPI規則',
        a37:'去設置',
        a38:'看板參數設置',
        a39:'添加公司資訊',
        a40:'編輯公司資訊',
        a41:'能源使用量訊號點',
        a42:'清潔能源發電量訊號點',
        a43:'公司名稱',
        a44:'顯示顏色',
        a45:'選擇並添加訊號點',
        a46:'删除訊號點',
        a47:'確認要保存所編輯的資訊嗎？',
        a48:'確認要删除嗎？',
        a49:'所選擇訊號點已存在，請不要重複選擇。',
        a50:'請選擇客戶/建築/網站資訊！',
        a51:'請輸入詳細地址。',
        a52:'詳細地址長度必須在1~100之間。',
        a53:'詳細地址不可以包含特殊字元。',
        a54:'删除公司資訊',
        a55:'選擇KPI規則',
        a56:'集團地址',
        a57:'總發電量',
        a58:'集團整體用電量',
        a59:'集團整體碳排放量',
        a60:'已達標${num}家',
        a61:'未達標${num}家',
        a62:'集團月度KPI',
        a63:'各公司KPI完成情况',
        a64:'已達標',
        a65:'未達標',
        a66:'峰穀分析',
        a67:'用能對比',
        a68:'用電排名',
        a69:'大氣壓',
        a70:'降水量',
        a71:'能見度',
        a72:'體感溫度',
        a73:'紫外線',
        a74:'相對濕度',
        a75:'北風',
        a76:'東北風',
        a77:'西北風',
        a78:'南風',
        a79:'東南風',
        a80:'西南風',
        a81:'西風',
        a82:'東風',
        a83:'級',
        a84: '集團年度KPI',
        a85: '集團每日KPI',
        a86: '未獲取',
        a87: '時間進度',
    }
}