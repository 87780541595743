// 空调路由表
// 多联机--空调模块
/*
    2023/4/6  lht 
    1.修改优化路由表层级匹配页面缓存机制 
    2.增加airConditionInlet（主页）和airConditionModuleInlet（模块主页）
    3.删除airConditionLayout页面
*/

export default [
    {
        path: '/homepage/airConditionInlet', // 多联机--空调主页入口
        redirect: '/404',
        component: () => import('@/views/air-condition/airConditionInlet'),
        children:[
            {
                path: '/homepage/air-condition/airConditionDetails', //空调模块--空调详情页
                component: () => import('@/views/air-conditionDetails/airConditionDetails')
            },
            // {
            //     path: '/homepage/air-condition/seasonInfoEditor', //空调模块--季节设置
            //     name: 'VRFAC_seasonInfoEditor',
            //     component: () => import('@/views/air-condition/seasonInfoEditor'),
            //     // meta: {keepAlive: false} // 不需要缓存  
            // },
            {
                path: '/homepage/air-condition/runmodeSeason', //空调模块--参数、运行模式设置
                name: 'VRFAC_runmodeSeason',
                component: () => import('@/views/air-condition/runmodeSeason'),
            },
            {
                path: '/homepage/air-condition/terminalEditor', //空调模块--设备编辑
                name: 'VRFAC_terminalEditor',
                component: () => import('@/views/common/terminalEditor'),
            },
            {
                path: '/homepage/airConditionModuleInlet', // 多联机--空调模块主页入口
                redirect: '/404',
                component: () => import('@/views/air-condition/airConditionModuleInlet'),
                children:[
                    {
                        path: '/homepage/air-condition/equipment', //空调模块--空调主页
                        name: 'VRFAC_FAC_EQUIPMENT',
                        component: () => import('@/views/air-condition/equipment'),
                        // meta: { keepAlive: true }// 需要被缓存 
                    },
                    {
                        path: '/homepage/air-condition/controllSeason', //空调模块--设备季节切换控制
                        name: 'VRFAC_FAC_KISETU',
                        component: () => import('@/views/common/controllSeason'),
                        // meta: { keepAlive: true,hidden: true }// 需要被缓存 
                    },
                    {
                        path: '/homepage/air-condition/seasonGroup', //空调模块--设备季节切换控制组设置
                        name: 'VRFAC_FAC_KISETU_GR',
                        component: () => import('@/views/common/seasonGroup'),
                        // meta: { keepAlive: true,hidden: true }// 需要被缓存 
                    },
                    {
                        path: '/homepage/air-condition/controllGroupIntermittent', //空调模块--设备间歇运行控制组设置
                        name: 'VRFAC_FAC_KANKETU_GR',
                        component: () => import('@/views/common/controllGroupIntermittent'),
                        // meta: { keepAlive: true }// 需要被缓存 
                    },
                    {
                        path: '/homepage/air-condition/controllIntermittent', //空调模块--设备间歇运行控制
                        name: 'VRFAC_FAC_KANKETU',
                        component: () => import('@/views/common/controllIntermittent'),
                        // meta: { keepAlive: true }// 需要被缓存 
                    },
                    {
                        path: '/homepage/air-condition/loggingData', //空调模块--数据输出
                        name: 'VRFAC_FAC_DATA_OUTPUT',
                        component: () => import('@/views/common/loggingData'),
                    },
                    {
                        path: '/homepage/air-condition/dataCompareTrend', //空调模块--数据比较趋势
                        name: 'VRFAC_FAC_DATA_HIKAKU_TLEND',
                        // component: () => import('@/views/common/trend/dataCompareTrend'),
                        component: () => import('@/views/energy/trend/eneTrendChart'),
                    },  
                    {
                        path: '/homepage/air-condition/dataCompareTrendList', //空调模块--数据比较趋势列表
                        name: 'VRFAC_compareTrendList',
                        component: () => import('@/views/common/trend/dataCompareTrendList'),
                    },
                    {
                        path: '/homepage/air-condition/timeSetupTrend', //空调模块--时间设置趋势
                        name: 'VRFAC_FAC_TIME_TLEND',
                        // component: () => import('@/views/common/trend/timeSetupTrend'),
                        component: () => import('@/views/energy/trend/eneTimeTrendChart'),
                    }, 
                    {
                        path: '/homepage/air-condition/timeSetupTrendList', //空调模块--时间设置趋势列表
                        name: 'VRFAC_timeSetupTrendList',
                        component: () => import('@/views/common/trend/timeSetupTrendList'),
                    },           
                    {
                        path: '/homepage/air-condition/equipment', //空调模块--设备页
                        name: 'VRFAC_equipment',
                        component: () => import('@/views/air-condition/equipment'),
                        // meta: {keepAlive: true }// 需要被缓存 
                    },
                    {
                        path: '/homepage/air-condition/controllGroup', //空调模块--时间表设置
                        name: 'VRFAC_FAC_SCEDULE_GR',
                        component: () => import('@/views/common/controllGroup'),
                    },
                    {
                        path: '/homepage/air-condition/schedule', //空调模块--时间表
                        name: 'VRFAC_FAC_SCEDULE',
                        component: () => import('@/views/light/lightTimetable'),
                    },
                    {
                        path: '/homepage/air-condition/lightRunTimetable', //空调模块--执行时间表控制
                        name: "VRFAC_FAC_RUN_SCEDULE",
                        component: () => import('@/views/light/lightRunTimetable'),
                    },
                    {
                        path: '/homepage/air-condition/linkageGR', //空调模块--设备联动控制组设置
                        name: 'VRFAC_FAC_KIKIRENDOU_GR',
                        component: () => import('@/views/common/controllGroupLinkage'),
                    },
                    {
                        path: '/homepage/air-condition/controllGroupTrend', //空调模块--趋势组
                        name: 'VRFAC_FAC_TOLEND_GR',
                        component: () => import('@/views/common/trend/controllGroupTrend'),
                    },
                    {
                        path: '/homepage/air-condition/controllGroupTimeTrend', //空调模块--时间趋势组
                        name: 'VRFAC_FAC_TIME_TLEND_GR',
                        component: () => import('@/views/common/trend/controllGroupTimeTrend'),
                    },
                    {
                        path: '/homepage/air-condition/linkage', //空调模块--设备联动控制
                        name: 'VRFAC_FAC_KIKIRENDOU',
                        component: () => import('@/views/common/controllLinkage'),
                    },
                    {
                        path: '/homepage/air-condition/controllGroupComfort', //空调模块--设备舒适控制组设置
                        name: 'VRFAC_FAC_SAITEKI_GR',
                        component: () => import('@/views/common/controllGroupComfort'),
                        // meta: { keepAlive: true }// 需要被缓存 
                    },
                    {
                        path: '/homepage/air-condition/comfort', //空调模块--设备舒适控制
                        name: 'VRFAC_FAC_SAITEKI',
                        component: () => import('@/views/common/controllComfort'),
                    },
                    {
                        path: '/homepage/air-condition/alarm',
                        name: 'VRFAC_alarm',
                        component: () => import('@/views/energy/alarm'),//与能源设备使用同一页面
                    },
                    {
                        path: '/homepage/air-condition/controlRecord',
                        name: 'VRFAC_FAC_HISTORY',
                        // component: () => import('@/views/door/controlRecord'),//空调模块--操作控制记录（与门禁使用同一页面）
                        component: () => import('@/views/energy/history'),

                    },
                    {
                        path: '/homepage/air-condition/alarm',
                        name: 'VRFAC_FAC_ALARM',
                        component: () => import('@/views/common/alarm'),//空调模块--警报
                    }
                ]
            }
        ]
    },
    // {
    //     path: '/homepage/air-condition/airConditionDetails', //空调模块--空调详情页
    //     component: () => import('@/views/air-conditionDetails/airConditionDetails'),
    // },
    // {
    //     path: '/homepage/air-condition',//空调主页
    //     redirect: '/404',
    //     component: () => import('@/views/air-condition/airConditionLayout'),
    //     children: [
    //         {
    //             path: '/homepage/air-condition/equipment', //空调模块--空调主页
    //             name: 'VRFAC_FAC_EQUIPMENT',
    //             component: () => import('@/views/air-condition/equipment'),
    //             meta: { keepAlive: true }// 需要被缓存 
    //         },
    //         {
    //             path: '/homepage/air-condition/seasonInfoEditor', //空调模块--季节设置
    //             name: 'VRFAC_seasonInfoEditor',
    //             component: () => import('@/views/air-condition/seasonInfoEditor'),
    //             meta: {keepAlive: false} // 不需要缓存
                    
    //         },
    //         {
    //             path: '/homepage/air-condition/controllSeason', //空调模块--设备季节切换控制
    //             name: 'VRFAC_FAC_KISETU',
    //             component: () => import('@/views/common/controllSeason'),
    //             // meta: { keepAlive: true,hidden: true }// 需要被缓存 
    //         },
    //         {
    //             path: '/homepage/air-condition/seasonGroup', //空调模块--设备季节切换控制组设置
    //             name: 'VRFAC_FAC_KISETU_GR',
    //             component: () => import('@/views/common/seasonGroup'),
    //             // meta: { keepAlive: true,hidden: true }// 需要被缓存 
    //         },
    //         {
    //             path: '/homepage/air-condition/controllGroupIntermittent', //空调模块--设备间歇运行控制组设置
    //             name: 'VRFAC_FAC_KANKETU_GR',
    //             component: () => import('@/views/common/controllGroupIntermittent'),
    //             // meta: { keepAlive: true }// 需要被缓存 
    //         },
    //         {
    //             path: '/homepage/air-condition/controllIntermittent', //空调模块--设备间歇运行控制
    //             name: 'VRFAC_FAC_KANKETU',
    //             component: () => import('@/views/common/controllIntermittent'),
    //             // meta: { keepAlive: true }// 需要被缓存 
    //         },
    //         {
    //             path: '/homepage/air-condition/runmodeSeason', //空调模块--参数、运行模式设置
    //             name: 'VRFAC_runmodeSeason',
    //             component: () => import('@/views/air-condition/runmodeSeason'),
    //         },
    //         {
    //             path: '/homepage/air-condition/terminalEditor', //空调模块--设备编辑
    //             name: 'VRFAC_terminalEditor',
    //             component: () => import('@/views/common/terminalEditor'),
    //         },
    //         {
    //             path: '/homepage/air-condition/loggingData', //空调模块--数据输出
    //             name: 'VRFAC_FAC_DATA_OUTPUT',
    //             component: () => import('@/views/common/loggingData'),
    //         },
    //         {
    //             path: '/homepage/air-condition/dataCompareTrend', //空调模块--数据比较趋势
    //             name: 'VRFAC_FAC_DATA_HIKAKU_TLEND',
    //             // component: () => import('@/views/common/trend/dataCompareTrend'),
    //             component: () => import('@/views/energy/trend/eneTrendChart'),
    //         },  
    //         {
    //             path: '/homepage/air-condition/dataCompareTrendList', //空调模块--数据比较趋势列表
    //             name: 'VRFAC_compareTrendList',
    //             component: () => import('@/views/common/trend/dataCompareTrendList'),
    //         },
    //         {
    //             path: '/homepage/air-condition/timeSetupTrend', //空调模块--时间设置趋势
    //             name: 'VRFAC_FAC_TIME_TLEND',
    //             // component: () => import('@/views/common/trend/timeSetupTrend'),
    //             component: () => import('@/views/energy/trend/eneTimeTrendChart'),
    //         }, 
    //         {
    //             path: '/homepage/air-condition/timeSetupTrendList', //空调模块--时间设置趋势列表
    //             name: 'VRFAC_timeSetupTrendList',
    //             component: () => import('@/views/common/trend/timeSetupTrendList'),
    //         },           
    //         {
    //             path: '/homepage/air-condition/equipment', //空调模块--设备页
    //             name: 'VRFAC_equipment',
    //             component: () => import('@/views/air-condition/equipment'),
    //             // meta: {keepAlive: true }// 需要被缓存 
    //         },
    //         {
    //             path: '/homepage/air-condition/controllGroup', //空调模块--时间表设置
    //             name: 'VRFAC_FAC_SCEDULE_GR',
    //             component: () => import('@/views/common/controllGroup'),
    //         },
    //         {
    //             path: '/homepage/air-condition/schedule', //空调模块--时间表
    //             name: 'VRFAC_FAC_SCEDULE',
    //             component: () => import('@/views/light/lightTimetable'),
    //         },
    //         {
    //             path: '/homepage/air-condition/lightRunTimetable', //空调模块--执行时间表控制
    //             name: "VRFAC_FAC_RUN_SCEDULE",
    //             component: () => import('@/views/light/lightRunTimetable'),
    //         },
    //         {
    //             path: '/homepage/air-condition/linkageGR', //空调模块--设备联动控制组设置
    //             name: 'VRFAC_FAC_KIKIRENDOU_GR',
    //             component: () => import('@/views/common/controllGroupLinkage'),
    //         },
    //         {
    //             path: '/homepage/air-condition/controllGroupTrend', //空调模块--趋势组
    //             name: 'VRFAC_FAC_TOLEND_GR',
    //             component: () => import('@/views/common/trend/controllGroupTrend'),
    //         },
    //         {
    //             path: '/homepage/air-condition/controllGroupTimeTrend', //空调模块--时间趋势组
    //             name: 'VRFAC_FAC_TIME_TLEND_GR',
    //             component: () => import('@/views/common/trend/controllGroupTimeTrend'),
    //         },
    //         {
    //             path: '/homepage/air-condition/linkage', //空调模块--设备联动控制
    //             name: 'VRFAC_FAC_KIKIRENDOU',
    //             component: () => import('@/views/common/controllLinkage'),
    //         },
    //         {
    //             path: '/homepage/air-condition/controllGroupComfort', //空调模块--设备舒适控制组设置
    //             name: 'VRFAC_FAC_SAITEKI_GR',
    //             component: () => import('@/views/common/controllGroupComfort'),
    //             // meta: { keepAlive: true }// 需要被缓存 
    //         },
    //         {
    //             path: '/homepage/air-condition/comfort', //空调模块--设备舒适控制
    //             name: 'VRFAC_FAC_SAITEKI',
    //             component: () => import('@/views/common/controllComfort'),
    //         },
    //         {
    //             path: '/homepage/air-condition/alarm',
    //             name: 'VRFAC_alarm',
    //             component: () => import('@/views/energy/alarm'),//与能源设备使用同一页面
    //         },
    //         {
    //             path: '/homepage/air-condition/controlRecord',
    //             name: 'VRFAC_FAC_HISTORY',
    //             component: () => import('@/views/door/controlRecord'),//空调模块--操作控制记录（与门禁使用同一页面）
    //         },
    //         {
    //             path: '/homepage/air-condition/alarm',
    //             name: 'VRFAC_FAC_ALARM',
    //             component: () => import('@/views/common/alarm'),//空调模块--警报
    //         },            
    //     ],
    // },  
]