// 拓扑图设置
module.exports = {
    topologySet:{
        a1:'拓扑图设置',
        a2:'添加拓扑图节点',
        a3:'请选择节点',
        a4:'节点名称：',
        a5:'设备列表',
        a6:'设备编辑',
        a7:'分组设置',
        a8:'添加',
        a9:'分组编辑',
        a10:'删除',
        a11:'解除',
        a12:'新增节点',
        a13:'取消',
        a14:'确认',
        a15:'名称：',
        a16:'请输入名称' ,
        a17:'能源类型：' ,
        a18:'电' ,
        a19:'燃气' ,
        a20:'水' ,
        a21:'油' ,
        a22:'其他' ,
        a23:'关联点类型：' ,
        a24:'虚拟点' ,
        a25:'关联点：' ,
        a26:'节点修改',
        a27:'计量点',
        a28:'设备列表编辑',
        a29:'增加↓',
        a30:'删除↑',
        a31:'添加分组',
        a32:'设备名称',
        a33:'名称：',
        a34:'该名称已存在',
        a35:'确认删除该节点',
        a36:'运算点',
        a37:'请输入设备名称',
        a38:'名称',
        a39:'拓扑图',
        a40:'日数据',
        a41:'月数据',
        a42:'年数据',
        a43:'未设置下级设备'
    }
}