// 能效KPI设置
module.exports = {
    analyseKPISet:{
        a1: 'energy efficiency KPI setting',
        a2: 'search',
        a3: 'year:',
        a4: 'rule:',
        a5: 'comparison year:',
        a6: 'month:',
        a7: 'comparison month:',
        a8: 'proportion in the same period',
        a9: 'weekend proportion average',
        a10: 'equal proportion of holidays',
        a11: 'query',
        a12: 'annual total target value:',
        a13: 'target value:',
        a14: 'actual consumption:',
        a15: 'total target value:',
        a16: 'ratio of weekend to weekday consumption:',
        a17: 'consumption ratio between holidays and working days:',
        a18: 'ratio of weekend to weekday consumption:',
        a19: 'consumption ratio between holidays and working days:',
        a20: 'reset',
        a21: '1. Actual power consumption: the cumulative power consumption of the selected metering point as of the current date',
        a22: '2. Estimated power consumption: the estimated total power consumption in the current year, and the end year is the actual power consumption',
        a23: '3. Year on year comparison of power consumption: the ratio of the estimated total power consumption to the actual power consumption last year',
        a24: '4. Power saving target setting: set the energy saving target of the current year and the proportion of saving compared with the actual total power consumption of last year',
        a25: '5. Target value setting: set the total power consumption target of the current year in synchronization with the power saving target setting. One of the two setting methods can be used at the same time',
        a26: '6. Setting date: the setting time of the current target value of the current year',
        a27: '7. The end year target value cannot be set',
        a28: 'remarks',
        a29: 'rule management',
        a30: 'Auto Update',
        a31: 'start alert',
        a32: 'setting',
        a33: 'close',
        a34: 'rule list',
        a35: 'Add',
        a36: 'rule name',
        a37: 'starting month',
        a38: 'setting',
        a39: 'Delete',
        a40: 'year',
        a41: 'actual consumption',
        a42: 'predicted consumption',
        a43: 'year on year consumption',
        a44: 'saving target setting (%)',
        a45: 'target value',
        a46: 'set date',
        a47: 'month',
        a48: 'target value proportion',
        a49: 'locked',
        a50: 'actual proportion',
        a51: 'date',
        a52: 'please enter the rule name',
        a53: 'no annual rule',
        a54: 'Please add rules through rule management',
        a55: 'Target value of saving',
        a56: 'The saving target value format is incorrect, please enter a number.',
        a57: 'The saving target value cannot be greater than 100%.',
        a58: 'The target value cannot be less than zero.',
        a59: 'The format of the target value is incorrect, please enter a number.',
        a60: 'The target value proportion cannot be less than zero.',
        a61: 'The target value proportion format is incorrect, please enter a number.',
    }
}