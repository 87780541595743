
const state = {
    area:JSON.parse(sessionStorage.getItem("areaValue")),
}

const mutations = {
    areaDetails: (state, value) => {
        // console.log(value)
        state.area = value;
        sessionStorage.setItem("areaValue", JSON.stringify(value));
    },
}

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
