<!--主页侧边栏--站点主页-->
<template>
  <div id="homebutton" :title="$t('sidebar.b1')" @click="goSite">
      <div :class="bgimage"></div>
  </div>
</template>

<script>
export default {
  methods:{
    goSite(){
      const cid = this.$store.getters.clientId
      const sid = this.$store.getters.sitegrId
      const path = this.$route.path
      if(path !== '/homepage/site'){ this.$router.push({ path: '/homepage/site',  query: { cid, sid } }) }
    }
  },
  computed: {
    bgimage(){
      let length = this.$route.matched.length;
      let path1 = "";
      let path2 = "";
      if (length > 2) {
        path1 = this.$route.matched[1].path;
        path2 = this.$route.matched[2].path;
      } else {
        path1 = this.$route.matched[1].path;
      }
      if (path1 == "/homepage" && path2 !== "/information") {
        return 'selectedImage'
      } else {
        return 'normalImage'
      }
    }
  }
}
</script>

<style scoped>
#homebutton {
  width: 80px;
  height: 80px;
  flex-shrink:0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
}
.normalImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.2);
  border-radius: 50%;
  background-color: #f2f4f7;
  background-image: url('../../../../public/images/sidebar/home_normal.png');
  background-repeat: no-repeat;
  background-size: 36px 36px;
  background-position: 9px 9px;
  transition: 400ms ease-out;
}
.normalImage:hover{
  box-shadow: 0 0px 7px #7682ce;
}
.selectedImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-radius: 50%;
  background-color: #7682ce;
  background-image: url('../../../../public/images/sidebar/home_selected.png');
  background-repeat: no-repeat;
  background-size: 36px 36px;
  background-position: 9px 9px;
  transition: 400ms ease-out;
}
</style>