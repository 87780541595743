// 清洁能源设置
module.exports = {
    cleanEnergySetting:{
        a1: 'Clean energy settings',
        a2: 'Please select a group',
        a3: 'Total power generation',
        a4: 'Grid connected power',
        a5: 'Self consumption',
        a6: 'Total power consumption',
        a7: 'Power supply from power grid',
        a8: 'Please select a group',
        a9: 'Reset',
        a10: 'Setting',
        a11: 'Add group',
        a12: 'Cancel',
        a13: 'Confirm',
        a14: 'Group name',
        a15: 'Please enter the name',
        a16: 'Modify the group name',
        a17: 'Confirm to delete the report'
    }
}