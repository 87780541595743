// 门禁模块--设置--通行模块设置--通行权限人员信息组件 lht 2023-3-31
module.exports = {
    accessStaffInfo:{
        a1:'通行权限人员信息',
        a2:'取消',
        a3:'加载中....',
        a4:'人员信息列表',
        a5:'当前页导出',
        a6:'全部导出',
        a7:'人员信息导出',
        a8:'显示内容',
        a9:'管理代码',
        a10:'姓名',
        a11:'部门',
        a12:'卡编号',
        a13:'国籍',
        a14:'注册日期',
        a15:'关联建筑',
        a16:'所属1',
        a17:'所属2',
        a18:'所属3',
        a19:'所属4',
        a20:'所属5'
    }
}