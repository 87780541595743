<!-- 退出登录功能按钮 -->
<template>
  <div id="outbutton" @click="logout" :title="$t('sidebar.g1')">
    <div :class="bgimage" ref="outbutton"></div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'

export default {
  data(){
    return{
      bgimage:'normalImage'
    }
  },
  methods: {
    logout() {
      this.bgimage = 'selectedImage'
      this.$confirm({
        title: this.$t("sidebar.g2"),
        content: this.$t("sidebar.g3"),
        centered: true,
        maskClosable: true,
        zIndex:9999,
        onOk: async () => {
          await this.$store.dispatch("user/logout");
          sessionStorage.clear();
          Cookies.remove('webVideoCtrlProxy');
          Cookies.remove('WebSession_*');
          Cookies.remove('webSmartDisplayProxy');
          this.$store.commit("menu/outLogin");
          this.$router.push(`/login?redirect=${this.$route.fullPath}`);
          sessionStorage.clear();
          this.bgimage = 'normalImage'
        },
        onCancel:() =>{
          this.bgimage = 'normalImage'
        }
      })
    },
  },
};
</script>

<style scoped>
#outbutton {
  position: relative;
  width: 80px;
  height: 80px;
  flex-shrink:0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
}
.normalImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.2);
  border-radius: 50%;
  background-color: #f2f4f7;
  background-image: url('../../../../public/images/sidebar/out_normal.png');
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position: 17px 15px;
  transition: 400ms ease-out;
}
.normalImage:hover{
  box-shadow: 0 0px 7px #7682ce;
}
.selectedImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-radius: 50%;
  background-color: #7682ce;
  background-image: url('../../../../public/images/sidebar/out_selected.png');
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position: 17px 15px;
  transition: 400ms ease-out;
}
</style>