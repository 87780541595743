// 电能--能耗流向
module.exports = {
    energyFlow:{
        a1: '能耗流向圖',
        a2: '能耗',
        a3: '碳足迹',
        a4: '客戶',
        a5: '建築',
        a6: '網站',
        a7: '顯示內容',
        a8: '顯示分組',
        a9: '顯示設備',
        a10:'顯示租戶',
        a11:'年',
        a12:'月',
        a13:'日',
        a14:'自定義起始月',
        a15:'1月',
        a16:'2月',
        a17:'3月',
        a18:'4月',
        a19:'5月',
        a20:'6月',
        a21:'7月',
        a22:'8月',
        a23:'9月',
        a24:'10月',
        a25:'11月',
        a26:'12月',
        a27:'査詢',
        a28:'請選擇條件進行査詢',
        a29:'計量點',
    }
}