
export default [
  {
    path: '/homepage/consume', //消费模块--门禁主页
    component: () => import('@/views/consume/consumeLayout'),
    meta: { hidden: true },
    children: [
      {
        path: '/homepage/consume/equipment', //消费模块--设备页
        name: 'CSM_EQUIPMENT',
        component: () => import('@/views/consume/equipment'),
        meta: { hidden: true }
      },
      {
        path: '/homepage/consume/cardRecords', //消费模块--卡片记录
        name: 'CSM_HISTORY',
        component: () => import('@/views/consume/cardRecords'),
        meta: { hidden: true }
      },
      {
        path: '/homepage/consume/menuSettings', //消费模块--每日菜单设置
        name: 'CSM_MEAL',
        component: () => import('@/views/consume/menuSettings'),
        meta: { hidden: true }
      },
      {
        path: '/homepage/consume/consumptionSetting', //消费模块--消费设置
        name: 'CSM_CONSUME',
        component: () => import('@/views/consume/consumptionSetting'),
        meta: { hidden: true }
      },
      {
        path: '/homepage/consume/settlementSettings', //消费模块--结算规则设置
        name: 'CSM_SETTLEMENT_RULES',
        component: () => import('@/views/consume/settlementSettings'),
        meta: { hidden: true }
      },
      {
        path: '/homepage/consume/reportData', //消费模块--消费报表
        name: 'CSM_REPORT',
        component: () => import('@/views/consume/reportData'),
        meta: { hidden: true }
      },
      {
        path: '/homepage/consume/report', //消费模块--数据输出
        name: 'CSM_DATAOUTPUT',
        component: () => import('@/views/consume/dataView'),
        meta: { hidden: true }
      },
      {
        path: '/homepage/consume/alarm', //消费模块--报警页
        name: 'CSM_ALARM',
        component: () => import('@/views/door/alarm'),
        meta: { hidden: true }
      },
    ]
  }
]