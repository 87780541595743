import { getBelonging, getPersonList } from '@/api/info'

const state = {
    cardList: [],
    personList: [],
}

const mutations = {
    cardComile: (state, data) => {
        state.cardList = data
    },
    personComile: (state, data) => {
        state.personList = data
    }
}

const actions = {
    getBelonging({ commit }, data) {
        return new Promise((resolve, reject) => {
            getBelonging(data)
                .then(response => {
                    let { data } = response;
                    commit('cardComile', data)
                    resolve(data)
                }).catch(error => {
                    reject(error)
                })
        })
    },
    getPersonList({ commit }, data) {
        return new Promise((resolve, reject) => {
            getPersonList(data).then(response => {
                let { data } = response;
                commit('personComile', data)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
