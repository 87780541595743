import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue';
import App from './App.vue';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets
import router from './router';
import store from './store';
import i18n from './common/lang'
import Antd from 'ant-design-vue';// 蚂蚁金服ui组件库引入
import 'ant-design-vue/dist/antd.less';
import './common/font/font.css';// 字体包引入
import * as echarts from 'echarts';// ECharts图表库引入
import './permission';
// import JsEncrypt from 'jsencrypt';  //通过VUE引入，Vue打包扫描提示存在中危风险过期的YUI版本；所以改为在index.html中引入
import '../src/theme/index.css'
import { Loading, Tree, Checkbox, Tag, MessageBox, Image, Tooltip } from 'element-ui';
//chw 20210720 star
import x2js from 'x2js';
import platform from './utils/platformConfig';
// import 'qweather-icons/font/qweather-icons.css'
import VueClipboard from 'vue-clipboard2'

// 人脸照片的裁剪 add by pzj20230710
import VueCropper from 'vue-cropper';
Vue.use(VueCropper);

Vue.use(VueClipboard);

Vue.use(VueClipboard)
//chw 20210720 end



// lht 2022/04/24 解决Chrome增加了新的事件捕获机制
// import 'default-passive-events'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import Swiper, { Pagination, Scrollbar, Navigation } from 'swiper'

// Vue.prototype.jsEncrypt = JsEncrypt; //通过VUE引入，Vue打包扫描提示存在中危风险过期的YUI版本；所以改为在index.html中引入
Swiper.use([Pagination, Scrollbar, Navigation])
// import Swiper from 'swiper'
//import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

// lht 2021/10/29
import "./utils/instruct"

//li_yj
import qs from 'qs';
//配全局属性配置，在任意组件内可以使用this.$qs获取qs对象
Vue.prototype.$qs = qs;
//chw 20210720 star
Vue.prototype.$x2js = new x2js();
Vue.prototype.$platform = platform;
//chw 20210720 end

Vue.prototype.$messagebox = MessageBox;
// Vue.prototype.$ = jQuery;
Vue.use(Loading.directive);
Vue.use(Tree);
Vue.use(Checkbox);
Vue.use(Tag);
Vue.use(Image);
Vue.use(Tooltip);
Vue.use(Antd);
// Vue.use(jQuery);
// Vue.component('v-chart', ECharts);


Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

window.vm = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
