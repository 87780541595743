export default [    
    {
        path: '/set/Home', //设置管理主页
        component: () => import('@/views/setManagement/Home'),
    },
    {
        path: '/set/customerCenter', //设置管理--客户中心
        component: () => import('@/views/setManagement/customerCenter'),
    },
    {
        path: '/set/userSettings', //设置管理--用户设置
        component: () => import('@/views/setManagement/userSettings'),
    },
    {
        path: '/set/areaDetails', //设置管理--建筑物详情页
        component: () => import('@/views/setManagement/areaDetails'),
    },
    {
        path: '/set/siteJurisdictionSet', //设置管理--用户设置--权限站点页
        component: () => import('@/views/setManagement/siteJurisdictionSet'),
    },
    {
        path: '/set/controller/controllerManagement', //设置管理--控制器管理
        component: () => import('@/views/setManagement/controller/equipment'),
        redirect:'/homepage/management/controller/controllerAdministration',
        meta: { hidden: true },
        children: [
            {
                path: '/homepage/management/controller/controllerAdministration', // 控制器管理
                name: 'controller-controller',
                component: () => import('@/views/setManagement/controller/controllerManagement'),
                meta: { hidden: true }
            },
            {
                path: '/homepage/management/controller/gatewayAdministration', // 智能网关管理
                name: 'controller-canonly',
                component: () => import('@/views/setManagement/controller/edgeSmartGateway'),
                meta: { hidden: true }
            }
        ],
    },
    {
        path: '/homepage/management/notificationSetting', //通知设置管理主页
        component: () => import('@/views/setManagement/notificationSetting/equipment'),
        redirect:'/homepage/management/notificationSetting/guide',
        meta: { hidden: true },
        children: [
            {
                path: '/homepage/management/notificationSetting/guide', //指南
                name: 'Notice-guide',
                component: () => import('@/views/setManagement/notificationSetting/commonInfoSetting'),
                meta: { hidden: true }
            },
            {
                path: '/homepage/management/notificationSetting/consulting', // 咨询处
                name: 'Notice-consulting',
                component: () => import('@/views/setManagement/notificationSetting/inquireSetting'),
                meta: { hidden: true }
            },
            {
                path: '/homepage/management/notificationSetting/handover', // 继承事项
                name: 'Notice-handover',
                component: () => import('@/views/setManagement/notificationSetting/transferSetting'),
                meta: { hidden: true }
            },
            {
                path: '/homepage/management/notificationSetting/download', // 下载
                name: 'Notice-download',
                component: () => import('@/views/setManagement/notificationSetting/downloadSetting'),
                meta: { hidden: true }
            }
        ],
    },
    {
        path: '/homepage/management/warning/equipment', //警报管理主页
        component: () => import('@/views/setManagement/warning/equipment'),
        redirect:'/homepage/management/warning/alarm',
        meta: { hidden: true },
        children: [
            {
                path: '/homepage/management/warning/alarm', // 警报记录信息
                name: 'alert-recordInformation',
                component: () => import('@/views/setManagement/warning/record/alarm'),
                meta: { hidden: true }
            },
            {
                path: '/homepage/management/warning/setUp', // 警报设置
                name: 'alert-setUp',
                component: () => import('@/views/setManagement/warning/set/warningSetting'),
                meta: { hidden: true }
            },
            {
                path: '/homepage/management/warning/notice', // 通知设置
                name: 'alert-notice',
                component: () => import('@/views/setManagement/warning/notification/notification'),
                meta: { hidden: true }
            }
        ],
    },  
    {
        path: '/set/userOperationHistory', //设置管理--用户操作记录页面
        component: () => import('@/views/setManagement/userOperationHistory'),
    },
    
]