<!--主页侧边栏-->
<template>
  <div id="sidebar">
    <a-select v-model="language" style="width: 100%; margin-bottom: 20px">
      <a-select-option value="zhcn">简体</a-select-option>
      <a-select-option value="zhtw">繁体</a-select-option>
      <a-select-option value="jajp">日本語</a-select-option>
      <a-select-option value="enus">English</a-select-option>
    </a-select>
    <div class="logo">
      <img src="../../../public/images/login/login02.png" />
    </div>
    <div ref="Container" class="Container">
      <div class="layout">
        <my-avatar></my-avatar>
        <my-visualing v-if="clientHeight >= 320"></my-visualing>
        <my-home v-if="clientHeight >= 320"></my-home>
        <my-certification v-if="clientHeight >= 400"></my-certification>
        <my-set v-if="userInfo.userType == 1 && clientHeight >= 480"></my-set>
        <my-infrom :isNew="isNewMsg" v-if="clientHeight >= 560"></my-infrom>
        <my-warning :numberOfMsg="numberOfMsg" :msgs="msgAlarms" @clearMsg="clearMsg" v-if="clientHeight >= 640"></my-warning>
        <!-- <my-alarm></my-alarm> -->
        <my-board v-if="clientHeight >= 720"></my-board>
        <CustomerStatistics v-if="clientHeight > 770"></CustomerStatistics>

      </div>
      <div class="layout">
        <!-- <my-more></my-more> -->
        <!-- 更多功能按钮 -->
        <div class="morebutton" :title="$t('sidebar.f1')" v-if="clientHeight <= 770">
          <a-popover placement="rightTop" v-model="visible" :getPopupContainer="(triggerNode) => triggerNode.parentNode">
            <template slot="content">
              <div class="buttonContainer">
                <my-visualing v-if="clientHeight <= 320"></my-visualing>
                <my-home v-if="clientHeight <= 320"></my-home>
                <my-certification placement="top" v-if="clientHeight <= 400"></my-certification>
                <my-set v-if="userInfo.userType == 1 && clientHeight <= 480"></my-set>
                <my-infrom :isNew="isNewMsg" v-if="clientHeight <= 560"></my-infrom>
                <my-warning :numberOfMsg="numberOfMsg" :msgs="msgAlarms" @clearMsg="clearMsg" v-if="clientHeight <= 640"></my-warning>
                <my-board v-if="clientHeight <= 720"></my-board>
                <CustomerStatistics v-if="clientHeight < 770"></CustomerStatistics>
                <my-out v-if="clientHeight <= 830"></my-out>
              </div>
            </template>
            <div :class="bgimage" :title="$t('sidebar.a16')"></div>
          </a-popover>
        </div>
        <my-out v-if="clientHeight > 770"></my-out>
        <!-- 前后端版本号 added by li_yj on 2023-03-30 -->
        <!-- <div>{{backEndVersion}}</div>
        <div>{{version}}</div> -->
      </div>
    </div>
    <!-- 视频弹框 -->
    <camera-dialog :cameraId="cameraId" :vmode="vmode" :starDate="starDate.format('YYYY/MM/DD HH:mm:ss')" :endDate="endDate.format('YYYY/MM/DD HH:mm:ss')"></camera-dialog>
  </div>
</template>

<script>
import Avatar from "./components/Avatar";
import HomeButton from "./components/HomeButton";
import Visualing from "./components/Visualing";
import CertificationButton from "./components/CertificationButton";
import SetButton from "./components/SetButton";
import InfromButton from "./components/InfromButton";
import MoreButton from "./components/MoreButton";
import WarningButton from "./components/WarningButton";
import OutButton from "./components/OutButton";
import Alarm from "./components/Alarm";
import bulletinBoard from "./components/bulletinBoard.vue"
import { language } from "../../api/user";
import { getInformation, closeSSE, pushMsg,pushMsgWebsocket  } from "../../api/sysmanage";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import cameraDialog from '../../views/monitoring/cameraDialog.vue';
import CustomerStatistics from "./components/CustomerStatistics";
//added by li_yj on 2023-03-30
import { getVersion } from "../../api/api";
import config from '../../../package.json'


export default {
  inject: ["reload"],
  components: {
    "my-avatar": Avatar,
    "my-home": HomeButton,
    "my-certification": CertificationButton,
    "my-set": SetButton,
    "my-infrom": InfromButton,
    "my-more": MoreButton,
    "my-out": OutButton,
    "my-warning": WarningButton,
    "my-alarm": Alarm,
    "my-board":bulletinBoard,
    "camera-dialog":cameraDialog,
    "my-visualing": Visualing,
    CustomerStatistics,
  },
  data() {
    return {
      msgMap:new Map(),//用于筛选重复消息
      numberOfMsg:0,
      isNewMsg: false,
      isNewWarning: false,
      openSSE: false,
      language: this.$i18n.locale,
      source: {},
      isConceal: false,
      userInfo: JSON.parse(sessionStorage.getItem("user")),
      selectedImage: require("../../../public/images/sidebar/more_selected.png"),
      normalImage: require("../../../public/images/sidebar/more_normal.png"),
      clientHeight:0,
      visible:false,
      msgAlarms:[],
      // listTest:[
      //   {
      //     title: "设备GWC-1通信异常。",
      //     content: "设备通信异常-警报发生"
      //   },
      //   {
      //     title: "设备GWC-2通信异常。",
      //     content: "设备通信异常-警报恢复"
      //   }
      // ]
      cameraId:0,
      vdialog: false,
      vmode:'1',
      starDate:new moment().startOf('day'),
      endDate:new moment(),
      backEndVersion:"",
    };
  },
  computed: {
    bgimage: function () {
      let route = this.$route.matched[1].path;
      if (route == this.url) {
        return 'selectedImage'
      } else {
        return 'normalImage'
      }
    },
    uid:function(){//取唯一ID
      let sid=''
      if(localStorage.getItem('sid')){
        sid=localStorage.getItem('sid')
      }else{
        sid=uuidv4()
        localStorage.setItem('sid',sid)
      }
      return sid;
    },
    version:function(){
      return "web:V"+config.version;
    }
  },
  created() {
    //使用事件推送服务
    // this.listeningSSE();
    //使用websocket
    this.listeningWebSocket();
  },
  mounted() {
    //暂时取消消息推送功能
    // if(!this.openSSE)
    //   this.listeningSSE();
    this.getVersion();//added by li_yj on 2023-03-30
    //查询并比较，判断是否有新消息
    this.refresh();

    // 2022/06/15 lht
    // 监听侧边栏功能按钮容器的高度变化
    // 高度小于一定数值时改变功能按钮的排版方式
    const element = this.$refs.Container
    let observer = new ResizeObserver(() => {
      this.clientHeight = element.clientHeight
    })
    observer.observe(element);
  },
  destroyed() {
    // this.closeSse();
  },
  methods: {
    clearMsg(){
      this.msgMap.clear();
      this.numberOfMsg=0;
    },
    onTest() {
      //发送消息
      // this.pushMsgWebsocket();

      // this.numberOfMsg = 100;
      // console.log("强制修改接收到消息数量为:",this.numberOfMsg);
      console.log("取版本号为:",config.version)

      //使用事件推送服务
      // this.listeningSSE();
      // if(this.isNewMsg)
      //   this.isNewMsg=false;
      // else
      //   this.isNewMsg=true;
    },
    pushMsgWebsocket(){
      let par = {
        message: "test",
      };
      console.log("pushMsgWebsocket par:", par);
      pushMsgWebsocket(par)
        .then((res) => {
          let { data } = res;
          // this.$message.info("推送消息成功");
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("推送消息失败");
        });
    },
    //added by li_yj on 2023-03-30取后端版本号
    getVersion() {
      getVersion()
        .then((res) => {
          let { data } = res;
          this.backEndVersion="interface:"+data;
          console.log("getVersion:", data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    receiveMessage(e) {
      this.openSSE = true;
      console.log("receiveMessage:", e.data);
      if (e.data != null) {
        if (parseInt(e.data.split(",")[0]) == 0) this.isNewMsg = true;
        else this.isNewWarning = true;
      }
    },
    receiveStatus(e) {
      this.openSSE = true;
      console.log("建立连接:", e);
    },

    listeningWebSocket() {
      var socket;
      var proxy=this;
      if (typeof WebSocket == "undefined") {
        console.log("Your browser does not support WebSocket");
      } else {
        console.log("Your browser support WebSocket");
        const userId = JSON.parse(this.$store.getters.user).loginName;
        //实现化WebSocket对象，指定要连接的服务器地址与端口  建立连接
        //等同于socket = new WebSocket("ws://localhost:8888/xxxx/im/25");

        // var socketUrl = "http://localhost:8443/websocket/" + userId;
        // var socketUrl="ws://"+location.host+'/websocket/'+userId;
        // var socketUrl="ws://"+location.host+'/websocket/'+this.uid;//li_yj on 2022-10-14由登录用户ID改为唯一ID
        var socketUrl=location.origin+'/websocket/'+this.uid;//li_yj on 2023-3-13 https问题处理
        // var socketUrl='https://web.bivale.cn/websocket/'+this.uid;//li_yj on 2023-3-13 https问题处理
        console.log("WebSocket location",location);
        console.log("WebSocket socketUrl",socketUrl);
        //https与http对应设置不一样
        socketUrl = socketUrl.replace("https", "wss").replace("http", "ws");
        console.log("WebSocket socketUrl replace",socketUrl);
        if (socket != null) {
          socket.close();
          socket = null;
        }
        socket = new WebSocket(socketUrl);
        //打开事件
        socket.onopen = function () {
          console.log("websocket opened");
          // proxy.hasVideoChange({cameraIdList:[1003],date:'2023/03/27 14:21:03',normalTime:'2023/03/27 14:31:03'});
          //socket.send("这是来自客户端的消息" + location.href + new Date());
        };
        //获得消息事件
        var msgAlarm;
        var key;
        socket.onmessage = function (msg) {
          msgAlarm=JSON.parse(msg.data);
          key=msgAlarm.title+","+msgAlarm.content;
          console.log("listeningWebSocket receiveMessage key:",key);
          console.log("listeningWebSocket receiveMessage msgAlarm:",msgAlarm);
          proxy.hasVideoChange(msgAlarm);
          //不存在的才添加

          // if(!proxy.msgMap.get(key)) {
          //   proxy.msgMap.set(key,msg.data);
          //   proxy.numberOfMsg = proxy.msgMap.size;
          //   proxy.msgAlarms.push(msgAlarm);
          // }

          let exist = false;
          for(let i=0;i<proxy.msgAlarms.length;i++){
            if(msgAlarm.title==proxy.msgAlarms[i].title&&msgAlarm.content==proxy.msgAlarms[i].content){
              exist=true;
              break;
            }
          }
          if(!exist){
            proxy.msgAlarms.push(msgAlarm);
            proxy.numberOfMsg =proxy.msgAlarms.length;
          }
        };
        //关闭事件
        socket.onclose = function () {
          console.log("websocket close");
          // proxy.hasVideoChange({cameraIdList:[1003],date:'2023/03/27 14:21:03',normalTime:'2023/03/27 14:31:03'});
        };
        //发生了错误事件
        socket.onerror = function () {
          console.log("websocket error");
        };
      }
    },

    listeningSSE() {
      // 用时间戳模拟登录用户
      // const userId = new Date().getTime();
      const userId = JSON.parse(this.$store.getters.user).loginName;

      if (window.EventSource) {
        //避免未断开时重连
        if (this.source != null && this.openSSE) return;
        // 建立连接
        this.source = new EventSource(
          "/bivale/sysmanage/notificationSSE/connect/" + userId, { withCredentials: true }
        );
        // this.$message.info("连接用户=" + userId);
        console.log("连接用户:" + userId);
        /**
         * 连接一旦建立，就会触发open事件
         * 另一种写法：source.onopen = function (event) {}
         */
        this.source.addEventListener(
          "open",
          function(e) {
            this.openSSE =true;
            console.log("建立连接。。。",e);
              // this.$message.info("建立连接。。。");
          },
          false
        );

        /**
         * 客户端收到服务器发来的数据
         * 另一种写法：source.onmessage = function (event) {}
         */
        this.source.addEventListener('message', function(e) {
            // setMessageInnerHTML(e.data);
        });

        /**
         * 如果发生通信错误（比如连接中断），就会触发error事件
         * 或者：
         * 另一种写法：source.onerror = function (event) {}
         */
        this.source.addEventListener(
          "error",
          function (e) {
            if (e.readyState === EventSource.CLOSED) {
              this.openSSE = false;
              console.log("连接关闭", e);
            } else {
              console.log("发生错误：", e);
            }
          },
          false
        );
      } else {
        this.$message.info("你的浏览器不支持SSE");
      }
    },

    // 监听窗口关闭事件，主动去关闭sse连接，如果服务端设置永不过期，浏览器关闭后手动清理服务端数据
    // window.onbeforeunload = function() {
    //     closeSse();
    // },

    // 关闭Sse连接
    closeSse() {
      const userId = JSON.parse(this.$store.getters.user).loginName;
      let par = {
        userId: userId,
      };
      closeSSE(par)
        .then((res) => {
          let { data } = res;
          if (data) {
            this.$message.info(this.$t("general.a6"));
          } else {
            this.$message.err(this.$t("general.a7"));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    refresh() {
      //查询并比较，判断是否有新消息
      this.inquire(1);
      this.inquire(2);
      this.inquire(3);
      this.inquire(4);
    },

    // 查询比较是否有新消息
    inquire(type) {
      let condition = "";
      this.isNewMsg = false;
      if (type == 4)
        //下载有2个参数，逗号分隔
        condition = ",";
      let par = {
        updateUser: "",
        infoType: type, //1：公共指南；2：咨询处；3：继承事项；4：下载；
        info: condition,
      };
      // console.log("inquire par:", par);
      getInformation(par)
        .then((res) => {
          let { data } = res;
          if (data != null && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              data[i].key = i + 1;
            }
            let oldInfo = null;
            let isNew = false;
            switch (type) {
              case 1:
                //比较是否有新消息
                oldInfo = localStorage.getItem("commonInfo");
                if (oldInfo != null) oldInfo = JSON.parse(oldInfo);
                isNew = this.compareInfo(oldInfo, data);
                if (isNew) {
                  this.isNewMsg = isNew;
                  // localStorage.setItem("commonInfo",JSON.stringify(this.commonInfo));
                }
                break;
              case 2:
                //比较是否有新消息
                oldInfo = localStorage.getItem("inquireInfo");
                if (oldInfo != null) oldInfo = JSON.parse(oldInfo);
                this.inquireInfoNew = this.compareInfo(oldInfo, data);
                if (isNew) {
                  this.isNewMsg = isNew;
                  // localStorage.setItem("inquireInfo",JSON.stringify(this.inquireInfo));
                }
                break;
              case 3:
                //比较是否有新消息
                oldInfo = localStorage.getItem("transferInfo");
                if (oldInfo != null) oldInfo = JSON.parse(oldInfo);
                this.transferInfoNew = this.compareInfo(oldInfo, data);
                if (isNew) {
                  this.isNewMsg = isNew;
                  // localStorage.setItem("transferInfo",JSON.stringify(this.transferInfo));
                }
                break;
              case 4:
                //比较是否有新消息
                oldInfo = localStorage.getItem("downloadInfo");
                if (oldInfo != null) oldInfo = JSON.parse(oldInfo);
                this.downloadInfoNew = this.compareInfo(oldInfo, data);
                if (isNew) {
                  this.isNewMsg = isNew;
                  // localStorage.setItem("downloadInfo",JSON.stringify(this.downloadInfo));
                }
                break;
            }
            console.log("getInformation isNewMsg:", this.isNewMsg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //对比判断是否有新消息
    compareInfo(oldInfo, data) {
      if (data == null || data.length == 0) return false;
      if (oldInfo == null || oldInfo.length == 0) {
        if (data != null && data.length > 0) return true;
      } else {
        let isFound = false;
        for (let i = 0; i < data.length; i++) {
          isFound = false;
          for (let j = 0; j < oldInfo.length; j++) {
            if (data[i].hidden == oldInfo[j].hidden) {
              isFound = true;
              break;
            }
          }
          if (!isFound)
            //找不到，表示有新消息
            return true;
        }
      }
      return false;
    },
    // 卡片视频按钮点击回调函数
    hasVideoChange(record){
        let date = record.date + " " + record.time;
        console.log("has Video Change :", record,record.cameraIdList,this.vdialog,date);
        if(record.cameraIdList.length>0){
            if(!this.vdialog){
                if('-' === record.date){
                      this.vdialog = false;
                      this.cameraId = parseInt(record.cameraIdList[0]);
                      let mstar     = new moment(date).subtract(15, 'seconds');
                      let mend      = new moment(date).add(15, 'seconds');
                      this.starDate = mstar;
                      this.endDate  = mend;
                      this.vmode = '0';
                      this.vdialog = true;
                }
            }
        }

    },
  },
  watch: {
    language: {
      handler: function (newValue) {
        // console.log(newValue);
        this.$i18n.locale = newValue;
        localStorage.setItem("lang", newValue);
        let data = {
          language,
        };
        if (newValue == "zhcn") {
          data.language = "zh_CN";
        } else if (newValue == "zhtw") {
          data.language = "zh_TW";
        } else if (newValue == "jajp") {
          data.language = "ja_JP";
        } else if (newValue == "enus") {
          data.language = "en_US";
        }
        // console.log(data);
        language(data)
          .then((res) => {
            // console.log(res);
            if (res.errorCode == "00") {
              this.reload();
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  },

};
</script>

<style scoped>
#sidebar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0 20px 0;
}
.logo {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
}
.logo img {
  width: 80px;
}
.Container {
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.morebutton {
  width: 80px;
  height: 80px;
  flex-shrink:0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
  position: relative;
}
.morebutton img{
  width: 100%;
}
.normalImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.2);
  border-radius: 50%;
  background-color: #f2f4f7;
  background-image: url('../../../public/images/sidebar/more_normal.png');
  background-repeat: no-repeat;
  background-size: 31px 31px;
  background-position: 12px 12px;
  transition: 400ms ease-out;
}
.normalImage:hover{
  box-shadow: 0 0px 7px #7682ce;
}
.selectedImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-radius: 50%;
  background-color: #7682ce;
  background-image: url('../../../public/images/sidebar/more_selected.png');
  background-repeat: no-repeat;
  background-size: 31px 31px;
  background-position: 12px 12px;
  transition: 400ms ease-out;
}
.image {
  width: 55px;
  height: 55px;
  border: 1px solid #f2f4f7;
  border-radius: 50%;
  transition: 200ms ease;
}
.image:hover {
  width: 60px;
  height: 60px;
  box-shadow: 0 0px 10px #7682ce;
}
.buttonContainer{
  display: flex;
  padding: 5px;
}
.layout{
  display: flex;
  flex-direction: column;
  align-items: center;
}
div /deep/.ant-select-selection{
  border-top: none;
  border-right: none;
  border-left: none;
}
div /deep/.ant-popover-inner-content{
  padding: 0px;
}
div /deep/.ant-popover{
  z-index: 3000;
}
</style>