// 操作记录 -- 中文简体
module.exports = {
    userOperationHistory:{
        a1: 'Operation Record',
        a2: 'User Name',
        a3: 'Login Name',
        a4: 'Service',
        a5: 'Screen Name',
        a6: 'Operation content',
        a7: 'Equipment Name',
        a8: 'Signal Name',
        a9: 'Date Time',
        a10: 'Operation Type',
        a11: 'Operation Time',
        a12: 'Operation Details',
        a13: 'Operation Item',
        a14: 'Original Data',
        a15: 'New Data',
    }
}