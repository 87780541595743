import request from '@/utils/request'
import fileDownload from '@/utils/fileDownload'

// 认证信息--获取卡所属列表
export function getBelonging(params) {
    return request({
        url: 'bivale/authentication/cardBelong/list',
        method: 'get',
        params
    });
}

// 认证信息--根据添加查询条件获取表格数据
export function getInfoList(body) {
    return request({
        url: "bivale/authentication/search/cardList",
        method: 'post',
        data: body,
    });
}

// 认证信息--根据添加查询条件获取表格数据（分页）
export function getInfoList_page(body) {
    return request({
        url: "bivale/authentication/search/page/cardList",
        method: 'post',
        data: body,
    });
}

// 认证信息--表格数据删除请求
export function deleteInfo(body) {
    return request({
        url: "bivale/authentication/cardManage/delete",
        method: 'delete',
        data: body,
    });
}

// 认证信息--表格数据添加请求
export function addData(body) {
    return request({
        url: "bivale/authentication/cardManage/add",
        method: 'post',
        data: body,
    });
}

// 认证信息--表格数据单一编辑请求
export function onlyCompile(body) {
    return request({
        url: "bivale/authentication/cardManage/singleUpdate",
        method: 'post',
        data: body,
    });
}

// 认证信息--表格数据批量编辑请求
// export function batchCompile(body) {
//     return request({
//         url: "bivale/authentication/cardManage/batchUpdate",
//         method: 'post',
//         data: body,
//     });
// }

// 认证信息--卡所属编辑--添加卡所属
export function addCardBelong(body, query) {
    return request({
        url: "bivale/authentication/cardBelong/add",
        method: 'post',
        data: body,
        params: query,
    });
}

// 认证信息--卡所属编辑--更新卡所属名称
export function updateCardBelongName(body, query) {
    return request({
        url: "bivale/authentication/cardBelong/update",
        method: 'post',
        data: body,
        params: query,
    });
}

// 认证信息--卡所属编辑--删除卡所属
export function deleteCardBelong(body, query) {
    return request({
        url: "bivale/authentication/cardBelong/delete",
        method: 'delete',
        data: body,
        params: query,
    });
}

// 认证信息组--获取管理所属列表
export function getPersonList(params) {
    return request({
        url: 'bivale/authentication/personGr/list',
        method: 'get',
        params
    });
}

// 认证信息组--根据添加查询条件获取表格数据
export function getpersonData(body) {
    return request({
        url: "bivale/authenticationGroup/search/personList",
        method: 'post',
        data: body,
    });
}

// 认证信息组--根据添加查询条件获取表格数据(分页) POST /bivale/authenticationGroup/search/page/personList
export function getpersonData_page(body) {
    return request({
        url: "bivale/authenticationGroup/search/page/personList",
        method: 'post',
        data: body,
    });
}

// 认证信息组--根据添加查询条件获取人员信息列表
export function getPersons(params) {
    return request({
        url: 'bivale/authenticationGroup/search/persons',
        method: 'get',
        params
    });
}

// 认证信息组--根据添加查询条件获取表格数据
export function personDelete(body) {
    return request({
        url: "bivale/authentication/personManage/delete",
        method: 'post',
        data: body,
    });
}

// 认证信息组--管理所属编辑--添加管理所属
export function addPersonGr(body, query) {
    return request({
        url: "bivale/authentication/personGr/add",
        method: 'post',
        data: body,
        params: query,
    });
}

// 认证信息组--管理所属编辑--更新管理所属名称
export function updatePersonGr(body, query) {
    return request({
        url: "bivale/authentication/personGr/update",
        method: 'post',
        data: body,
        params: query,
    });
}

// 认证信息组--管理所属编辑--删除管理所属
export function deletePersonGr(body, query) {
    return request({
        url: "bivale/authentication/personGr/delete",
        method: 'post',
        data: body,
        params: query,
    });
}

// 人员管理--编辑时获取人员信息
export function getPersonInfoManage(query) {
    return request({
        url: "bivale/authentication/authenticationGroup/personInfoManage",
        method: 'post',
        params: query,
    });
}

// 认证信息组--人员管理--添加及编辑（发卡接口）
export function personSetUp(body) {
    return request({
        url: "bivale/authentication/authenticationGroup/personInfoManage/setUp",
        method: 'post',
        data: body,
    });
}

// 认证信息组--人员管理--添加及编辑--获取发行站点的设备状态（发卡接口）
export function getSiteDeviceStatus(body) {
    return request({
        url: "bivale/authentication/authenticationGroup/personInfoManage/getSiteDeviceStatus",
        method: 'post',
        data: body,
    });
}

// 认证信息组--人员管理--查询未使用的卡
export function getUnusedCard(body) {
    return request({
        url: "bivale/authentication/search/unUseCardList",
        method: 'post',
        data: body,
    });
}

// 认证信息组--人员管理--选择站点数据获取
export function getRelationSiteInfo(params) {
    return request({
        url: "bivale/authentication/authenticationGroup/personInfoManage/getRelationSiteInfo",
        method: 'post',
        params,
    });
}

// 认证信息组--人员管理--选择站点设置
export function setUpSiteInfo(data) {
    return request({
        url: "bivale/authentication/authenticationGroup/personInfoManage/getRelationSiteInfo/setUp",
        method: 'post',
        data,
    });
}

// 认证信息组--通行模式列表
export function getThroughList(params) {
    return request({
        url: 'bivale/accessRole/list',
        method: 'get',
        params
    });
}

// 认证信息组--人员管理--梯控房间获取
export function getroomList(params) {
    return request({
        url: "bivale/elevator/roomList",
        method: 'post',
        params,
    });
}

// 认证信息组--批量信息注册获取相关信息
export function getbaseInfoList(params) {
    return request({
        url: 'bivale/brachRegist/baseInfoList',
        method: 'get',
        params
    });
}

// 认证信息组--批量信息上传文件
export function uploadFile(body) {
    return request({
        url: 'bivale/brachRegist/uploadFile',
        method: 'post',
        data: body,
    });
}

// 认证信息组--批量信息下载文件
export function dataDownload(body, query) {
    return fileDownload({
        url: "bivale/brachRegist/downloadFile",
        method: 'post',
        responseType: "blob",
        data: body,
    });
}

export function staffPassModeEquipmentFileDownload(params){
    return fileDownload({
        url: "bivale/authentication/authenticationGroup/pass/door/download/excel",
        method: 'post',
        responseType: "blob",
        data: params,
    });
}

// 认证信息组--获取卡类型列表
export function getCardTypeList(params) {
    return request({
        url: 'bivale/consumeInfo/cardTypeList',
        method: 'get',
        params
    });
}

// 认证信息组--卡类型设置
export function setCardType(body) {
    return request({
        url: 'bivale/consumeInfo/setCardType',
        method: 'post',
        data: body,
    });
}

// 认证信息组--获取消费卡（充值）的信息
export function getRechargeCardInfo(params) {
    return request({
        url: 'bivale/consumeInfo/findCard',
        method: 'get',
        params
    });
}

// 认证信息组--获取消费卡充值接口
export function recharge(params) {
    return request({
        url: 'bivale/consumeInfo/recharge',
        method: 'post',
        params
    });
}


// 认证信息组--获取国籍信息列表接口
export function getNationalities() {
    return request({
        url: 'bivale/authentication/personManage/nationality',
        method: 'get',
        data:null,
    });
}

// 人员信息--人员信息批量注册上传文件详情文件下载
export function getDetailsFile(url) {
    return request({
        url,
        method: 'post',
    });
}

// 通行模式匹配设置 -- 获取侧边栏站点列表
export function getAccessRoleSiteList(params) {
    return request({
        url: 'bivale/personGrMapAccessRole/accessRoleSiteList',
        method: 'get',
        params
    });
}

// 通行模式匹配设置 -- 获取通行模式列表
export function getTrafficModeData(params) {
    return request({
        url: 'bivale/personGrMapAccessRole/accessRoleList',
        method: 'post',
        data:params
    });
}

// 通行模式匹配设置 -- 获取通行模式列表
export function setTrafficMode(params) {
    return request({
        url: 'bivale/personGrMapAccessRole/update',
        method: 'post',
        data:params
    });
}

// 通行模式匹配设置 -- 匹配管理所属的通行模式 -- 缺省值获取
export function matchAccessRole(params) {
    return request({
        url: 'bivale/personGrMapAccessRole/matchAccessRole',
        method: 'post',
        data:params
    });
}