// 能效KPI设置
module.exports = {
    analyseKPISet:{
        a1:'能效KPI設定',
        a2:'蒐索',
        a3:'年度：',
        a4:'規則：',
        a5:'對比年度：',
        a6:'月份：',
        a7:'對比月份：',
        a8:'同期比例',
        a9:'週末比例均分',
        a10:'節假日比例均分',
        a11:'査詢',
        a12:'年總目標值：',
        a13:'目標值：',
        a14:'實際用量：',
        a15:'總目標值：',
        a16:'週末與工作日用量比值：',
        a17:'節假日與工作日用量比值：',
        a18:'週末與工作日用量比值：',
        a19:'節假日與工作日用量比值：',
        a20:'重置',
        a21:'1、實際用電量：截至當前日期所選計量點的累計用電量',
        a22:'2、預計用電量：當前年度預計總用電量，已結束年度為實際用電量',
        a23:'3、用電同比：預計總用電量於去年實際用電量的比值',
        a24:'4、節電目標設定：設定當前年份節能目標，比去年實際總用電量節約比例',
        a25:'5、目標值設定：設定當前年的總用電量目標，與節電目標設定同步，兩種設定管道盡可同時採用一種',
        a26:'6、設定日期：當前年份的當前目標值的設定時間',
        a27:'7、已結束年度目標值不可在設定',
        a28:'備註',
        a29:'規則管理',
        a30:'自動更新',
        a31:'啟動預警',
        a32:'設定',
        a33:'關閉',
        a34:'規則清單',
        a35:'添加',
        a36:'規則名稱',
        a37:'起始月份',
        a38:'設定',
        a39:'删除',
        a40:'年度',
        a41:'實際用量',
        a42:'預計用量',
        a43:'用量同比',
        a44:'節約目標設定（%）',
        a45:'目標值',
        a46:'設定日期',
        a47:'月份',
        a48:'目標值占比',
        a49:'鎖定',
        a50:'實際占比',
        a51:'日期',
        a52:'請輸入規則名稱',
        a53:'未有年度規則',
        a54:'請通過規則管理添加規則',
        a55:'節約目標值',
        a56:'節約目標值格式錯誤，請輸入數位。',
        a57:'節約目標值不能大於100%。',
        a58:'目標值不能小於零。',
        a59:'目標值格式錯誤，請輸入數位。',
        a60:'目標值占比不能小於零。',
        a61:'目標值占比格式錯誤，請輸入數位。',
    }
}