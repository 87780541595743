// 自动报表
module.exports = {
    automaticReport:{
        a1:'自动报表设置',
        a2:'报表列表',
        a3:'添加',
        a4:'请选择报表',
        a5:'每日导出时间',
        a6:'导出模版',
        a7:'信号点列表',
        a8:'启动',
        a9:'设置',
        a10:'文件仅保存7份',
        a11:'取消',
        a12:'确认',
        a13:'添加报表',
        a14:'报表名称',
        a15:'报表类型',
        a16:'请输入名称',
        a17:'报表名称修改',
        a18:'信号点列表',
        a19:'文件列表',
        a20:'确定删除报表',
        a21:'下载',
        a22:'操作'
    }
}