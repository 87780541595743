// 自动报表
module.exports = {
    automaticReport:{
        a1:'自動報表設定',
        a2:'報表清單',
        a3:'添加',
        a4:'請選擇報表',
        a5:'每日匯出時間',
        a6:'匯出模版',
        a7:'訊號點清單',
        a8:'啟動',
        a9:'設定',
        a10:'檔案僅保存7份',
        a11:'取消',
        a12:'確認',
        a13:'添加報表',
        a14:'報表名稱',
        a15:'報表類型',
        a16:'請輸入名稱',
        a17:'報表名稱修改',
        a18:'訊號點清單',
        a19:'檔案清單',
        a20:'確定删除報表',
        a21:'下載',
        a22:'操作'
    }
}