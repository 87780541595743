// 能耗报表设置
module.exports = {
    energyConsumptionReportSettings:{
        a1: 'Energy consumption report setting',
        a2: 'Template list',
        a3: 'Add',
        a4: 'Total consumption',
        a5: 'Display details',
        a6: 'Setting',
        a7: 'Cancel',
        a8: 'Confirm',
        a9: 'Name:',
        a10: 'Please enter the name',
        a11: 'Name modification',
        a12: 'Confirm to delete the template'
    }
}