// 门禁模块 -- 设备页
module.exports = {
    doorequipment:{
        a1: 'タイプ選択',
        a2: 'フロア/テナント選択',
        a3: 'すべて',
        a4: 'ドア',
        a5: '認証端末',
        a6: '正常',
        a7: '通信異常',
        a8: 'メンテナンス',
        a9: '機械異常',
        a10: '障害/アラート',
        a11: '保守アラーム/上下限アラーム・運転異常',
        a12: 'ロード中...',
        a13: 'カードの種類に誤りがある',
        a14: '操作が成功し、ドアを開けるボタンは5秒後に閉じた状態に戻る',
        a15: 'ドアを開ける操作に失敗した',
        a16: 'アイコン',
        a17: '表',
        a18: 'てんかい',
        a19: 'しまって',
        a20: '詳細',
        a21: 'バーデータ',
        a22: 'フロア/テナント',
        a23: 'デバイス名',
        a24: 'スイッチ',
        a25: 'ビデオ',
        a26: 'ステータス',
        a27: '操作',
        a28:'障害'
    }
}