import layout from '@/layout'
import information from './modules/information'
import light from './modules/light'
import environment from './modules/environment'
import airCondition from './modules/air-condition'
import airConditionWater from './modules/air-conditionWater'
import door from './modules/door'
import energyMenus from './modules/energy'
import visitorMenus from './modules/visitor'
import passagewayMenus from './modules/passageway'
import attendanceMenus from './modules/attendance'
import consumeMenus from './modules/consume'
import videoMenus from './modules/video'
import facilityMenus from './modules/facility'
import setManagement from './modules/setManagement'
import elevator from './modules/elevator'
import distribution from './modules/distribution'
import velocityMeasurement from './modules/velocityMeasurement'  // 道路测速  2023/04/19 lht

const constantRoutes = [
  {
    path: '/',
    component: layout,
    redirect: '/homepage/site',
    children: [
      {
        path: '/set', //设置管理主页
        component: () => import('@/views/setManagement/index'),
        children: [
          ...setManagement,
        ]
      },
      
      {
        path: '/customerStatistics', // 客户信息统计页面
        component: () => import('@/views/customerStatistics/index'),
      },
      {
        path: '/energyManagement', // 电能看板
        component: () => import('@/views/energyManagement/index'),
      },
      {
        path: '/energyKanBanSetting', // 电能看板
        component: () => import('@/views/energyManagement/setting'),
      },
      {
        path: '/info', //信息通知主页
        component: () => import('@/views/info/index'),
        children: [
          {
            path: '/info/Home', //信息通知主页
            component: () => import('@/views/info/Home'),
          }
        ]
      },
      {
        path: '/warning', //警报管理主页
        component: () => import('@/views/warning/index'),
        children: [
          {
            path: '/warning/warning', //警报管理主页
            component: () => import('@/views/warning/warning'),
          }
        ]
      },
      {
        path: '/homepage', //主页
        redirect: '/homepage/site',  //重定向站点页
        component: () => import('@/views/homepage'),
        children: [
          {
            path: '/homepage/site', //站点页
            component: () => import('@/views/site/index')
          },
          {
            path: '/homepage/eleplus', //电梯客户端
            component: () => import('@/views/eleplus/elefacLayout'),
          },
          {
            path: '/homepage/conference', //会议
            component: () => import('@/views/conference/conferenceLayout'),
          },
          ...information,//认证信息

          ...door,//门禁

          ...light,//照明

          ...environment,//环境

          ...airCondition,//空调

          ...airConditionWater,//水冷空调                

          ...energyMenus, //能源菜单

          ...visitorMenus,//访客菜单

          ...passagewayMenus,//出入口菜单

          ...attendanceMenus,//考勤菜单

          ...consumeMenus,//消费菜单

          ...videoMenus,//视频菜单

          // ...elevatorFacilities,//电梯设施管理

          ...elevator,//电梯设施管理

          ...facilityMenus,//通用设备

          ...velocityMeasurement, // 道路测速  2023/04/19 lht
          {
            path: '/homepage/conference', //电梯模块--数据报表
            component: () => import('@/views/conference/conferenceLayout'),
            meta: { hidden: true },
          },
           
          ...distribution,//信息发布
        ]
      }
    ]
  },
  {
    path: '/login',
    hidden: true,
    component: () => import('@/views/login')
  },
  {
    path: '/404',
    name: "*",
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/board',
    name: "board",
    component: () => import('@/views/customization/index'),
    hidden: true
  },
  {
    path: '/display',
    name: "display",
    component: () => import('@/views/customization/display'),
    hidden: true
  },

]

export default constantRoutes
