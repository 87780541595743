// 能效KPI设置
module.exports = {
    analyseKPISet:{
        a1:'能效KPI设置',
        a2:'搜索',
        a3:'年度:',
        a4:'规则:',
        a5:'对比年度:',
        a6:'月份:',
        a7:'对比月份:',
        a8:'同期比例',
        a9:'周末比例均分',
        a10:'节假日比例均分',
        a11:'查询',
        a12:'年总目标值：',
        a13:'目标值：',
        a14:'实际用量：',
        a15:'总目标值：',
        a16:'周末与工作日用量比值：',
        a17:'节假日与工作日用量比值：',
        a18:'周末与工作日用量比值：',
        a19:'节假日与工作日用量比值：',
        a20:'重置',
        a21:'1、实际用电量：截至当前日期所选计量点的累计用电量',
        a22:'2、预计用电量：当前年度预计总用电量，已结束年度为实际用电量',
        a23:'3、用电同比：预计总用电量于去年实际用电量的比值',
        a24:'4、节电目标设置：设定当前年份节能目标，比去年实际总用电量节约比例',
        a25:'5、目标值设置：设置当前年的总用电量目标，与节电目标设置同步，两种设置方式尽可同时采用一种',
        a26:'6、设置日期：当前年份的当前目标值的设置时间',
        a27:'7、已结束年度目标值不可在设置',
        a28:'备注',
        a29:'规则管理',
        a30:'自动更新',
        a31:'启动预警',
        a32:'设置',
        a33:'关闭',
        a34:'规则列表',
        a35:'添加',
        a36:'规则名称',
        a37:'起始月份',
        a38:'设置',
        a39:'删除',
        a40:'年度',
        a41:'实际用量',
        a42:'预计用量',
        a43:'用量同比',
        a44:'节约目标设置(%)',
        a45:'目标值',
        a46:'设置日期',
        a47:'月份',
        a48:'目标值占比',
        a49:'锁定',
        a50:'实际占比',
        a51:'日期',
        a52:'请输入规则名称',
        a53:'未有年度规则',
        a54:'请通过规则管理添加规则',
        a55:'节约目标值',
        a56:'节约目标值格式错误，请输入数字。',
        a57:'节约目标值不能大于100%。',
        a58:'目标值不能小于零。',
        a59:'目标值格式错误，请输入数字。',
        a60:'目标值占比不能小于零。',
        a61:'目标值占比格式错误，请输入数字。',
    }
}