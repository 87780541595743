//Customer Statistics - 英文
module.exports = {
    customerStatistics:{
        a1: 'Customer information statistics',
        a2: 'Customer Information',
        a3: 'Number of Customers',
        a4: 'Number of Buildings',
        a5: 'Number of Sites',
        a6: 'Number of Services',
        a7: 'Number of Floors/Tenants',
        a8: 'Number of Devices',
        a9: 'Device information statistics',
        a10: 'Device Type',
        a11: 'Please select a device type!',
        a12: 'Number of devices',
        a13: 'Service information statistics',
        a14: 'Service Name',
        a15: 'Customer Name',
        a16: 'Building Name',
        a17: 'Building Information',
        a18: 'Please select building information!',
        a19: 'Please select customer information!',
        a20: 'Please select',
        a21: 'Site Name',
        a22: 'Site Information',
        a23: 'Please select site information!',
        a24: 'Please select a service type!',
        a25: 'Floor/Tenant Information',
        a26: 'Floor/Tenant Name',
        a27: 'Total grouping of all device types',
        a28: 'Total grouping of customer equipment types',
        a29: 'Total grouping of building equipment types',
        a30: 'Total grouping of station equipment types',
        a31: 'Total of all devices',
        a32: 'Total customer equipment',
        a33: 'Total construction equipment',
        a34: 'Total Station Equipment',
        a35: 'Details of all station equipment',
        a36: 'Customer Site Equipment Details',
        a37: 'Building site equipment details',
        a38: 'Site equipment details',
        a39: 'Service Information',
        a40: 'Device Information',
        a41: 'Please select a statistical classification!',
        a42: 'Total',
        a43: 'Current $1-$2/$3 Data in Total',
        a44:'',
        a45:'',
        a46:'',
        a47:'',
        a48:'',
        a49:'',
    }
}