// 门禁模块 -- 详情页
module.exports = {
    doorDetails:{
        a1: 'Loading....',
        a2: 'device name ',
        a3: 'device ID',
        a4: 'device GUID',
        a5: 'left entrance ',
        a6: 'Name ',
        a7: 'customer name ',
        a8: 'Type ',
        a9: 'feature site ',
        a10: 'Floor · Tenant Name ',
        a11: 'Cancel ',
        a12: 'Confirm ',
        a13: 'Item ',
        a14: 'Status · Operations ',
        a15: 'time of state change ',
        a17: 'select ',
        a18: 'Schedule Settings ',
        a19: 'Equipment details ',
        a20: 'Schedule ',
        a21: 'Calendar ',
        a22: 'Door Opening Time ',
        a23: 'Door Opening Overtime Detection Time ',
        a24: 'With or without sound ',
        a25: 'There is a sound ',
        a26: 'No sound ',
        a27: 'Number of electric lock actions ',
        a28: 'Electrical lock action times exceed monitoring ',
        a29: 'Maximum number of electric lock operations ',
        a30: 'Are you sure you want to register your content?',
        a31: 'Door status ',
        a32: 'abnormal open ',
        a33: 'temporarily unlocked ',
        a34: 'open over ',
        a35: 'Invasion ',
        a36: 'Unlock up state ',
        a37: 'no circulation ',
        a38: 'pass status ',
        a39: 'running state ',
        a40: 'Accept prohibited status ',
        a41: 'Illegal Traffic Alert ',
        a42: 'Equipment Abnormal Alert ',
        a43: 'Entry door open ',
        a44: 'Exit door open ',
        a45: 'No Entry Direction ',
        a46: 'No passing in exit direction ',
        a47: 'Continuously through Settings ',
        a48: 'fire signal output ',
        a49: 'card NG signal output ',
        a50: 'specify target ',
        a51: 'Door Schedule ',
        a52: 'Card Certification Schedule ',
        a53: 'Mechanical Alert Schedule ',
        a54: 'Model 1',
        a55: 'Model 2',
        a56: 'Model 3',
        a57: 'Model 4',
        a58: 'Model 5',
        a59: 'The initial value is "Card Mode", if you need to change, please make a new setting ',
        a60: 'card mode ',
        a61: 'Free mode ',
        a62: 'Repeat mode ',
        a63: 'Enter card/exit free mode ',
        a64: 'Enter Free/Exit card mode',
        a65: 'Equipment details ',
        a66: 'Schedule ',
        a67: 'Calendar ',
        a68: 'numeric key timeout time in seconds ',
        a69: 'Number key terminal with or without ',
        a70: 'Warning temperature lower limit (℃)',
        a71: 'Warning temperature upper limit (℃)',
        a72: 'Warning for abnormal temperature ',
        a73: 'no',
        a74: 'is',
        a75: 'Authentication Mode ',
        a76: 'operating state ',
        a77: 'Illegal card ',
        a78: 'Schedule Restore ',
        a79: 'Automatic cycle recovery ',
        a80: 'valid ',
        a81: 'invalid ',
        a82: 'Cycle recovery time 1',
        a83: 'Cycle recovery time 2',
        a84: 'Cycle recovery time 3',
        a85: 'Cycle recovery time 4',
        a86: 'Cycle recovery time 5',
        a87: 'device IP',
        a89: 'device subnet mask ',
        a90: 'device gateway ',
        a91: 'Operation ',
        a92: 'Network Information for Devices ',
        a93: 'Operation successful, the door button will return to the closed state after 5 seconds ',
        a94: 'Door operation failed ',
        a95: 'Day Schedule ',
        a96: 'On the Day ',
        a97: 'Next Day Schedule ',
        a98: 'The Next Day ',
        a99: 'Timetable manual restore ',
        a100: 'Settings',
        a101: 'Operating status/fault/alarm ',
        a102: 'H',
        a103: 'M',
        a104: 'Name update',
        a105: 'Name modification',
        a106: 'Close',
        a107: 'Settings',
        a108: 'Please enter a name',
        a109: 'Operation status',
        a110: 'Device details',
        a111: 'Schedule setting',
        a112: 'Access rights',
        a113: 'Passengers',
        a114: 'Unlock',
        a115: 'Reset',
        a116: 'Lock',
        a117: 'Status',
        a118: '(0-99 seconds)',
        a119: '(0-3599 seconds)',
        a120: 'Please select the traffic mode',
        a121: '(0~999999 times)',
        a122: 'The time was not specified correctly, please specify it again',
        a123: 'Enter search text',
        a124: 'Registered authentication terminal',
        a125: 'Display content',
        a126: 'Access mode replication',
        a127: 'Authentication terminal editing',
        a128: 'Copy the original traffic mode',
        a129: 'pieces of data',
        a130: 'Enterprise name',
        a131: 'Building name',
        a132: 'Floor · Tenant Name',
        a133: 'Device name',
        a134: 'Authentication terminal name',
        a135: 'Access type',
        a136: 'Alert setting',
        a137: 'None',
        a138: 'Yes',
        a139: 'Schedule name',
        a140: 'Calendar name',
        a141: 'No traffic mode selected',
        a142: 'Please select the traffic mode to be copied',
        a143: 'Personnel information list',
        a144: 'Current page export',
        a145: 'Export all',
        a146: 'Export personnel information',
        a147: 'Delete',
        a148: 'Personnel editing',
        a149: 'Select operation',
        a150: 'Management code',
        a151: 'Name',
        a152: 'Department',
        a153: 'Card number',
        a154: 'Nationality',
        a155: 'Registration date',
        a156: 'Associated building',
        a157: 'Affiliated 1',
        a158: 'Affiliation 2',
        a159: 'Affiliation 3',
        a160: 'Affiliation 4',
        a161: 'Affiliated 5',
        a162: 'Operation',
        a163: 'Are you sure you want to delete'
    }
}