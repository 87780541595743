// 清洁能源流向
module.exports = {
    cleanEnergyFlows:{
        a1:'加載中….',
        a2:'清潔能源流向',
        a3:'電量',
        a4:'碳足迹',
        a5:'自定義起始月',
        a6:'査詢',
        a7:'請選擇條件進行査詢',
        a8:'計量點',
        a9:'碳足迹'
    }
}