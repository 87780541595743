<template>
    <div id="equipmentVideo"  >
        <a-modal v-model="visible" :width="width" :title="title" >
            <template slot="footer">
                <a-button  @click="destroy"> 关闭</a-button>
          </template>
            <div v-loading="vcloading" :element-loading-text="$t('alarm.a0')">
                <a-row>
                    <a-col v-if="brand==12" :span="24">
                        <div class="header-tool">
                            <div>
                                <a-radio-group v-model="mode" button-style="solid">
                                    <a-radio-button value="1">
                                    实时预览
                                    </a-radio-button>
                                    <a-radio-button value="0">
                                    回放
                                    </a-radio-button>
                                </a-radio-group>
                            </div>
                            <div v-if="mode==='0'">
                                <a-date-picker v-model="date" />
                                <a-time-picker v-model="star" :default-open-value="moment('00:00:00', 'HH:mm:ss')" />
                                <a-time-picker v-model="end" :default-open-value="moment('23:59:59', 'HH:mm:ss')" />
                            </div>
                            <a-button  @click="play()" icon="play-circle"  type="primary" title="播放">播放</a-button>
                        </div>
                        <div id="divSimplePlugin" :style="{width:vwidth+'px',height:vhigh+'px'}"></div>
                        <div class="video-tool-box" style="">
                            <a-button type="primary" icon="fullscreen"  :title="$t('video.viddata038')" :disabled="!isplay"   class="video-tool-button"    @click="fullscreen" />
                            <a-button type="primary" icon="stop"        :title="$t('video.viddata048')" :disabled="!isplay"   class="video-tool-button"    @click="stop" />


                        </div>
                    </a-col>
                    <a-col v-else-if="brand==13 || brand==11" :span="24">
                        <player ref="player" :style="{width:vwidth+'px',height:vhigh+'px'}" videoUrl="" fluent @screenshot="shot"  @destroy="destroy"/>
                    </a-col>
                    <a-col v-else-if="brand==14" :span="24">
                        <ysplayer :cameraId="cameraId" :dialog="dialog" :mode="mode" :autoClose="autoClose"
                            :vdate="date.format('YYYY-MM-DD')" :stime="star.format('YYYY-MM-DD HH:mm:ss')" :etime="end.format('YYYY-MM-DD HH:mm:ss')"
                            ref="ysplayer"  fluent @screenshot="shot"  @destroy="destroy"/>
                    </a-col>
                    <a-col v-else :span="16">
                        <a-empty>
                            <span slot="description"> {{videoBrandTxt}}</span>
                        </a-empty>
                    </a-col>

                </a-row>
            </div>
        </a-modal>
    </div>
</template>
<script>
import {getEquipmentVideoCamera,proxySave,getVideoCameraInfo} from '../../api/video'
import player from '../../components/video/jessibuca.vue'
import ysplayer from './components/ysplayer.vue'

import moment from "moment";
export default {
    name:'equipmentVideo',
    props:{
        cameraId:{
            type:Number,
            default:0
        },
        vmode:String,
        starDate:String,
        endDate:String,
    },
    data() {
        return {
            vcloading:false,
            visible: false,
            camera:null,
            recorder:null,
            title:'',
            brand:0,
            videoBrandTxt:this.$t('video.viddata061'),
            width:800,
            vwidth:750,
            vhigh:500,
            mode:'1',
            date:new moment(),
            star:new moment().startOf('day'),
            end:new moment(),
            dialog:1,
            autoClose:0,
            islogin:false,
            isplay:false,
            protocol:1,
        };
    },
    mounted(){
        if(location.protocol==='https'){
            this.protocol = 2;
        }
        // this.date=new moment('2023/03/23');
        // this.star=new moment('2023/03/23 10:00:00');
        // this.end=new moment('2023/03/23 10:01:00');

    },
    destroyed(){
        if(this.camera!=null && this.brand==12){
            this.videoStop();
            this.videoLogout(this.camera);
            this.visible = false;
        } else if(this.brand==14){
            this.visible = false;
        } else if(this.brand==13){
            this.visible = false;
        }
    },
    watch:{
        "$parent.vdialog":{
             handler(val){
                console.log(val);
                if(val){
                    this.visible = val;
                    this.vcloading = true;
                    if(this.cameraId>0){
                        this.mode = this.vmode;
                        this.date = new moment(this.starDate);
                        this.star = new moment(this.starDate);
                        this.end  = new moment(this.endDate);
                        this.getCameraInfo();
                    }

                }
            },
            deep:true,
            immediate:true,
        },
        "visible":{
            handler(val){
                if(!val){
                    this.$parent.vdialog =  val;
                    if(this.camera!=null){
                        this.videoStop();
                        this.videoLogout(this.camera);

                        this.camera=null;
                        this.cameraNum=0;
                        this.title='';
                        this.brand=0;
                    }
                }
            },
            deep:true,
            immediate:true,
        },
    },
    methods: {
        moment,
        videoStop(){
            if(this.brand===11){

            } else  if(this.brand===12){
                WebVideoCtrl.I_Stop();
            }
        },
        // 初始化插件
        videoInit(width,high,pluginId){
            // 检查插件是否已经安装过
            let iRet = WebVideoCtrl.I_CheckPluginInstall();
            if (-1 == iRet) {
                this.$message.error(this.$t('viddata062'));
                return;
            }

            let that = this;
            // 初始化插件参数及插入插件
            WebVideoCtrl.I_InitPlugin(width, high, {
                bWndFull: true,//是否支持单窗口双击全屏，默认支持 true:支持 false:不支持
                iWndowType: 1,
                cbInitPluginComplete: function () {
                    console.log('[I_InitPlugin]: 插件初始化完成');
                    WebVideoCtrl.I_InsertOBJECTPlugin(pluginId);
                    if(that.camera!=null){
                        console.log('开始登录')
                        that.videoLogin();
                    }
                }
            });
        },
        // 登录设备
        videoLogin(){
            let that = this;
            console.log('I_Login-登录设备信息:', [
                `szIP: ${this.camera.recorderIpAddress}`,
                `iPrototocol: ${this.protocol}`,
                `iPort: ${this.camera.recorderConnectPort}`,
                `szUserName: ${this.camera.monitoringUserId}`,
                `szPassword: ${this.camera.monitoringUserPassword}`,
            ]);
            WebVideoCtrl.I_Login(
                this.camera.recorderIpAddress,
                this.protocol,                      // 1 表示 http 协议 2 表示 https 协议
                this.camera.recorderConnectPort,    // 登录设备的 http/https 端口号
                this.camera.monitoringUserId,
                this.camera.monitoringUserPassword,
                {
                    success: function (xmlDoc) {
                        console.log('[I_Login]-success:', xmlDoc);
                        that.islogin = true;
                        if(that.mode==='1'){
                            // 开始预览
                            that.videoRealPlay(that.camera);
                        }else{
                            // 开始回放
                            that.videoPlayBack(that.camera);
                        }

                    },
                    error: function (status, xmlDoc) {
                        console.error('[I_Login]-error:', status, xmlDoc);
                    }
                }
            );
        },
        play(){
            let that = this;
            if(that.isplay){
                WebVideoCtrl.I_Stop({
                    iWndIndex:0,
                    success:function(){
                        console.log("窗口关闭成功！");
                        that.isplay = false;
                        if(that.mode==='1'){
                            // 开始预览
                            that.videoRealPlay(that.camera);
                        }else{
                            // 开始回放
                            that.videoPlayBack(that.camera);
                        }
                    },
                    error:function(){
                        console.log("窗口关闭失败！");
                    }
                });
            }else{

                if(that.islogin){

                    if(that.mode==='1'){
                        // 开始预览
                        that.videoRealPlay(that.camera);
                    }else{
                        // 开始回放
                        that.videoPlayBack(that.camera);
                    }
                }else{
                    this.videoLogin();
                }
            }
        },
        fullscreen(){
            WebVideoCtrl.I_FullScreen(true);
        },
        stop(){
            let that = this;
            WebVideoCtrl.I_Stop({
                iWndIndex:0,
                success:function(){
                    console.log("窗口关闭成功！");
                    that.isplay = false;
                },
                error:function(){
                    console.log("窗口关闭失败！");
                }
            });
        },
        videoLogout(camera){
            let iRet = 0;
            if(this.brand===11){
                this.destroy();
            }else  if(this.brand===12){
                let that = this;
                camera = camera?camera:this.camera;
                let szDeviceIdentify = camera.recorderIpAddress + "_" + camera.recorderConnectPort;
                iRet = WebVideoCtrl.I_Logout(szDeviceIdentify);
                console.log('Video logout',iRet);
                that.isplay = false;
                that.islogin = false;
            }
            return iRet;
        },
        videoPlayBack(camera){
            camera = camera?camera:this.camera;
            let that = this;
            let szDeviceIdentify = camera.recorderIpAddress + "_" + camera.recorderConnectPort;
            let szStartTime = that.date.format('YYYY-MM-DD') +" "+ that.star.format('HH:mm:ss');
            let szEndTime   = that.date.format('YYYY-MM-DD') +" "+ that.end.format('HH:mm:ss');
            console.log('videoPlayBack',{iWndIndex: 0,
                szStartTime:szStartTime,
                szEndTime:szEndTime,
                iChannelID: camera.recorderChannelNo,
                iStreamType: 1});

            WebVideoCtrl.I_StartPlayback(szDeviceIdentify, {
                iWndIndex: 0,
                szStartTime:szStartTime,
                szEndTime:szEndTime,
                iChannelID: camera.recorderChannelNo,
                iStreamType: 1,
                success:function(){
                        console.log('videoPlayBack success');
                        that.isplay = true;
                },
                error:function(){
                        console.log('videoPlayBack error');
                }

            });
            // setTimeout(function () {

            // }, 500);
        },
        videoRealPlay(camera){
            camera = camera?camera:this.camera;
            let szDeviceIdentify = camera.recorderIpAddress + "_" + camera.recorderConnectPort;
            let that = this;
            console.log('[I_StartRealPlay]: 开始播放; (设备标识（IP_Port）):', szDeviceIdentify);
            WebVideoCtrl.I_StartRealPlay(szDeviceIdentify, {
                iStreamType: 1,                         // 码流类型 1-主码流，2-子码流，默认使用主码流预览
                iChannelID: camera.recorderChannelNo,   // 播放通道号，默认通道 1
                bZeroChannel: false,                    // 是否播放零通道，默认为 false
                success:function(){
                    console.log('[I_StartRealPlay]: 播放success');
                    that.isplay = true;
                },
                error:function(){
                    console.log('[I_StartRealPlay]: 播放error');
                }

            });
            // setTimeout(function () {

            // }, 500);
        },
        signalChange(e){
            console.log('signalChange1',e);
            let s = this.signalMap.get(parseInt(e));
            console.log('signalChange2',s,this.operationMap,s.memberType);
            this.operations = this.operationMap.get(parseInt(s.memberType));
            console.log('signalChange3', this.operations);
            this.operation = this.operations[0];
            this.operaId = this.operations[0].no;
            this.signal = s;
        },

        // 1.获取设备配置信息
        getCameraInfo(){
            this.vcloading = true;
            getVideoCameraInfo(this.cameraId)
                .then((res) => {
                console.log("GetVideoCameraInfo",res);
                if(res.errorCode==='00'){

                    this.brand = res.data.camera.cameraType;
                    this.title = res.data.camera.name;

                    //本地推流服务
                    if(this.brand===13){
                        this.camera = res.data.camera;
                        this.proxyVideoPlay(this.camera);
                    }
                    //海康威视
                    else if(this.brand===12){
                        let param ={
                            id:res.data.camera.cameraId,
                            name:res.data.camera.name,
                            monitoringUserId:res.data.camera.cameraUserId,
                            monitoringUserPassword:res.data.camera.cameraUserPassword,
                            recorderIpAddress:res.data.camera.recorderIpAddress,
                            downloadPort: res.data.camera.cameraHttpPort,
                            recorderConnectPort: res.data.camera.recorderVideoPort,
                            recorderChannelNo:res.data.camera.recorderCh,
                            cameraType: this.brand,
                        }
                       this.recorder = param;
                       this.camera = param;
                       this.videoInit(this.vwidth,this.vhigh,'divSimplePlugin');
                    }
                    //萤石云推流
                    else {
                        this.camera = res.data.camera;
                        this.recorder = res.data.recorder;
                    }

                    this.vcloading = false;
                }else{

                }
                this.vcloading = false;
            }).catch((err) => {
                console.log(err);
                this.vcloading = false;
            });
            // return {
            //     cameraType: 12,
            //     downloadPort: 8000,
            //     id: 51,
            //     key: 1,
            //     monitoringUserId: "admin",
            //     monitoringUserPassword: "123456asd",
            //     name: "南大门外",
            //     recorderChannelNo: 10,
            //     recorderConnectPort: 80,
            //     recorderIpAddress: "10.98.35.233",
            // }
        },
        handleOk(e) {
             let param = this.params;
             param.action = 1;
             param.operaId = this.operaId;
             param.memberType = this.signal.memberType;
             param.signalId = this.signalId;
             param.signalType = this.signalType;
             console.log('handleOk',param);
             this.$confirm({
                // title: "确定要进行设定内容注册吗?",
                title:this.$t("energy.enedata184"),
                centered: true,
                onOk: () => {
                    this.vcloading = true;
                    getEquipmentVideoCamera(param)
                        .then((res) => {
                            console.log("SetEquipmentVideoCamera res",res);
                            if(res.errorCode==='00' || res.errorCode==='01'){
                                this.$message.info(res.msg);
                            }else{
                                this.$message.error(res.msg);
                            }
                            this.vcloading = false;
                    }).catch((err) => {
                        console.log(err);
                        this.vcloading = false;
                    });
                }
            });
        },
        shot(e) {
            // console.log(e)
            // send({code:'image',data:e})
            var base64ToBlob = function (code) {
              let parts = code.split(';base64,');
              let contentType = parts[0].split(':')[1];
              let raw = window.atob(parts[1]);
              let rawLength = raw.length;
              let uInt8Array = new Uint8Array(rawLength);
              for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
              }
              return new Blob([uInt8Array], {
                type: contentType
              });
            };
            let aLink = document.createElement('a');
            let blob = base64ToBlob(e); //new Blob([content]);
            let evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
            aLink.download = '截图';
            aLink.href = URL.createObjectURL(blob);
            aLink.click();
        },
        destroy() {
            if(this.brand===11 || this.brand===13){
                this.$refs["player"].destroy();
                this.visible = false;
            } else if(this.brand===14) {
                this.visible = false;
            } else if(this.brand===12) {
                this.visible = false;
            }
        },
        proxyVideoPlay(record){
            setTimeout(() => {
                this.$refs["player"].destroy();
                this.vcloading = true;
                let param ={
                    id:record.cameraId,
                    name:record.name,
                    monitoringUserId:record.cameraUserId,
                    monitoringUserPassword:record.cameraUserPassword,
                    recorderIpAddress:record.recorderIpAddress,
                    recorderChannelNo:record.recorderCh,
                }
                proxySave(this.brand,param)
                    .then((res) => {
                    if(res.code===0){
                        // this.$refs["player"].play("ws://10.98.234.210:80/" + this.brand + "/" + record.cameraId + ".live.flv");
                        let url = "ws://10.98.234.210:80/" + this.brand + "/" + record.cameraId + ".live.flv";
                        if(location.protocol==='https:'){
                            // url = "wss://10.98.234.210:443/" + this.cameraGroupId + "/" + record.cameraId + ".live.flv";
                            url = "wss://"+location.hostname+":443/localeVideoProxy/"+ this.brand + "/" + record.cameraId + ".live.flv";
                        }
                        this.$refs["player"].play(url);
                    }
                    this.vcloading = false;
                }).catch((err) => {
                    this.$message.error('摄像头播放失败，请检查摄像头是否异常。');
                    this.vcloading = false;
                });
            }, 100);
        },
    },
    components: {
        player,
        ysplayer
    },
};
</script>
<style scoped>
.header-condition {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}
.header-condition button{
  margin-bottom: 5px;
}
.header-condition span{
  margin-bottom: 5px;
}
.header-tool{
    display: flex;
    justify-content: space-between;
    background: #000;
    padding: 5px;
    width: 750px;
}
.video-tool-box{
    background: #000;
    padding: 5px 0;
    width: 750px;
}
</style>