<template>
    <div class="player">
        <!-- <div :id="playerId" style="width:600px;"> </div> -->
        <div class="player-header">
            <div>
                <a-radio-group v-model="live" button-style="solid">
                    <a-radio-button value="1">
                        {{ $t('video.viddata031') }}
                    </a-radio-button>
                    <a-radio-button value="0">
                        {{ $t('video.viddata032') }}
                    </a-radio-button>
                </a-radio-group>
            </div>
            <div v-if="live==='0'">
                <a-date-picker v-model="date" />
                <a-time-picker v-model="star" :default-open-value="moment('00:00:00', 'HH:mm:ss')" />
                <a-time-picker v-model="end" :default-open-value="moment('23:59:59', 'HH:mm:ss')" />
            </div>
            <a-button  @click="play()" icon="play-circle"  type="primary" title="播放">播放</a-button>
        </div>
        <div id="video-container" ref="video-container" style="width:100%;"></div>
    </div>
</template>
<script>
import { getCameraTokenInfo,refreshCameraTokenInfo } from "../../../api/video";
import moment from "moment";
import  EZUIKit from 'ezuikit-js';
export default {
    props: {
        cameraId: {
            type: Number,
            required: true,
        },
        mode:String,
        vdate:String,
        stime:String,
        etime:String,
        dialog:Number,
        autoClose:Number,
    },
  data() {
    const that = this;
    return {
      loading: false,
      player:undefined,
      live:'1',
      date:new moment(),
      star:new moment().startOf('day'),
      end:new moment(),
      isplay:true,
      full:true,
      save:false,
      url:'',
      close:0,
      option:{
        id: 'video-container', // 视频容器ID
        accessToken: '',
        url: '',
        audio:0,
        template: 'standard', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
        plugin: [],   // 加载插件，talk-对讲 'talk'
        width: 752,
        height: 405,
        handleError:error =>{
            console.log('handleError>>',that.$parent);
            //回放时，弹窗模式，播放完毕 自动关闭。
            if( that.dialog === 1 && that.close === 1 && that.mode === '0' ){
                that.$emit('destroy');
            }
        },
        handleSuccess:() =>{
            console.log('handleSuccess>>');
        }
      }
    };
  },
  mounted() {
    let width = this.$refs['video-container'].clientWidth;
    let high = this.$refs['video-container'].clientHeight;
    let height = width/16*9;
    if(height>600){
        height = 600;
        width = 600/9*16;
    }
    console.log(high,width,height);
    this.option.width = width;
    this.option.height = height;

    this.close= this.autoClose;
    this.live = this.mode;
    this.date = new moment(this.vdate); 
    this.star = new moment(this.stime);
    this.end = new moment(this.etime);
    if(this.cameraId>0){
        this.goTokenInfo();
    }
    
  },
  methods: {
    moment,
    goTokenInfo() {
        const data = {
            action: 1,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id,
            tenantId: 0,
            id:this.cameraId
        }
        getCameraTokenInfo(data)
        .then(res=>{
            console.log(res)
            if (res.errorCode == "00") {
                this.option.accessToken = res.data.token;
                this.url = res.data.openUrl;
                
                if(this.live==='1'){
                    this.option.url = res.data.openUrl+"1.live";
                }else{
                    this.option.url = res.data.openUrl+"1.rec?begin="+this.date.format("YYYYMMDD")+this.star.format("HHmmss")+"&end="+this.date.format("YYYYMMDD")+this.end.format("HHmmss");
                }
                this.initPlayer();
            } else {
                this.$message.error(res.msg)
            }
        })
        .catch(err=>{
            console.log(err)
        })

    },
    refreshTokenInfo() {

        const data = {
            action: 1,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id,
            tenantId: 0,
            cameraId:this.cameraId
        }
        refreshCameraTokenInfo(data)
        .then(res=>{
            console.log(res)
            if (res.errorCode == "00") {
                this.option.accessToken = res.data.token;
                this.url = res.data.openUrl;
                
                if(this.live==='1'){
                    this.option.url = res.data.openUrl+"1.live";
                }else{
                    this.option.url = res.data.openUrl+"1.rec?begin="+this.date.format("YYYYMMDD")+this.star.format("HHmmss")+"&end="+this.date.format("YYYYMMDD")+this.end.format("HHmmss");
                }

            } else {
                this.$message.error(res.msg)
            }
            this.ruleManagementLoading = false
        })
        .catch(err=>{
            this.ruleManagementLoading = false
            console.log(err)
        })
        
      
    },
    handleError(data){
        console.log('handleError>>',data);
    },
    handleSuccess(data){
        console.log('handleSuccess>>',data);
    },
    initPlayer(){
        console.log('this.option',this.option);
        this.player = new EZUIKit.EZUIKitPlayer(this.option);
    },
    reinitPlayer(){
        this.player = undefined;
        this.player = new EZUIKit.EZUIKitPlayer(this.option);
    },
    
    play() {
        let url = this.url ;
        if(this.live==='1'){
            url = url+"1.live";
        }else{
            url = url+"1.rec?begin="+this.date.format("YYYYMMDD")+this.star.format("HHmmss")+"&end="+this.date.format("YYYYMMDD")+this.end.format("HHmmss");
        }

        if(!this.player){
            this.player = new EZUIKit.EZUIKitPlayer(this.option);
        }else{
            let that = this;
            that.player.stop().then(()=>{
                that.player.play({url:url});
            });
        }
        
    },
    stop() {
        let stopPromise = this.player.stop();
        stopPromise.then((data) => {
            console.log("promise 获取 数据", data)
        });
    },
     getOSDTime() {
        let getOSDTimePromise = this.player.getOSDTime();
        getOSDTimePromise.then((data) => {
            console.log("promise 获取 数据", data)
        });
    },
     getOSDTime2() {
        let getOSDTimePromise = this.player2.getOSDTime();
        getOSDTimePromise.then((data) => {
            console.log("promise 获取 数据", data)
        });
    },
     capturePicture() {
        let capturePicturePromise = this.player.capturePicture();
        capturePicturePromise.then((data) => {
            console.log("promise 获取 数据", data)
        });
    },
     openSound() {
        let openSoundPromise = this.player.openSound();
        openSoundPromise.then((data) => {
            console.log("promise 获取 数据", data)
        });
    },
     closeSound() {
        let closeSoundPromise = this.player.closeSound();
        closeSoundPromise.then((data) => {
            console.log("promise 获取 数据", data)
        });
    },
     startSave() {
        let startSavePromise = this.player.startSave();
        startSavePromise.then((data) => {
            console.log("promise 获取 数据", data)
        });
    },
     stopSave() {
      let stopSavePromise = this.player.stopSave();
      stopSavePromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
     ezopenStartTalk() {
        this.player.startTalk();
    },
     ezopenStopTalk() {
        this.player.stopTalk();
    }
  },
}
</script>
<style scoped>
.player{
    height: 100%;
    width: 100%;
}
.player-header{
    width:100%;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
}
</style>