module.exports = {
    cleanEnergyAnalysis:{
        a1:'ロード中...',
        a2:'クリーンエネルギー',
        a3:'詳細データ',
        a4:'スケール解析',
        a5:'エネルギー比較',
        a6:'カスタム開始月',
        a7:'照会',
        a8:'CSVエクスポート',
        a9:'クエリーに条件を選択してください',
        a10:'電力量(kWh)',
        a11:'占有率(%)',
        a12:'累積量(kWh)'
    }
}