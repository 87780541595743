import request from '@/utils/request'

export function getVideoActiveXList(siteId){
    return request({
        url: 'bivale/video/getCameraTypeList?siteId='+siteId,
        method: 'get'
    });
}
export function downloadVideoActiveX(id){
    return request({
        url: 'bivale/video/pulgin/download?id='+id,
        method: 'post'
    });
}

export function getCameraList(siteId,siteGrId){
    return request({
        url: 'bivale/video/camera/list?siteId='+siteId+'&siteGrId='+siteGrId,
        method: 'get'
    });
}

export function saveCameraList(param){
    console.log(" 视频模块--设置--摄像机设置",param);
    return request({
        url: 'bivale/video/updateCameraSettingInfo',
        method: 'post',
        data:param
    });
}

export function getCameraGroupList(cameraGrId,siteGrId,siteId){
    return request({
        url: 'bivale/video/camera/group/list?siteId='+siteId+'&siteGrId='+siteGrId+'&cameraGrId='+cameraGrId,
        method: 'get'
    });
}

export function getCameraInfoList(cameraGrId){
    return request({
        url: 'bivale/video/cameraList?camerasetId='+cameraGrId,
        method: 'get'
    });
}

export function getCameraGroupInfoList(siteId,cameraGrId){
    return request({
        url: 'bivale/video/cameraGroupInfoList?siteId='+siteId+'&camerasetId='+cameraGrId,
        method: 'get'
    });
}


export function saveCameraGroupInfo(param){
    console.log(" 视频模块--设置--摄像机组设置",param);
    return request({
        url: 'bivale/video/camera/group/save',
        method: 'post',
        data:param
    });
}

export function deleteCameraGroupInfo(param){
    console.log(" 视频模块--设置--删除摄像机组",param);
    return request({
        url: 'bivale/video/camera/group/delete',
        method: 'post',
        data:param
    });
}

export function updateCameraGroupInfoList(param){
    console.log(" 视频模块--设置--设置摄像机组对应摄像机",param);
    return request({
        url: 'bivale/video/updateCameraGroupInfoList',
        method: 'post',
        data:param
    });
}


export function getVideoCameraSet(siteId){
    return request({
        url: 'bivale/video/camerasetList?siteId='+siteId,
        method: 'get'
    });
}
export function getVideoCameraList(cameraGrId){
    return request({
        url: 'bivale/video/cameraList?camerasetId='+cameraGrId,
        method: 'get'
    });
}

export function getEquipmentVideoCamera(param){
    return request({
        url: 'bivale/video/equipment/camera',
        method: 'post',
        data:param
    });
}


export function getVideoCardData(siteId){
    return request({
        url: 'bivale/video/card/data?siteId='+siteId,
        method: 'get'
    });
}

export function proxySave(cameraGroupId,param){
    var model = new Object();
    model.name = param.name;
    model.app = cameraGroupId;
    model.stream = param.id;
    //model.url = "rtsp://admin:admin@10.98.4.164:554/cam/realmonitor?channel=3&subtype=1";
    model.url = "rtsp://"+param.monitoringUserId + ":" + param.monitoringUserPassword + "@" 
    + param.recorderIpAddress + ":554/cam/realmonitor?channel="
    + param.recorderChannelNo + "&subtype=0";
    model.ffmpeg_cmd_key = "ffmpeg.cmd";
    model.enable = true;
    model.enable_hls = true;
    model.enable_remove_none_reader = true;
    model.enable_mp4 = true;
    // model.mediaServerId = "server_id_" + cameraGroupId + "_" + param.id;
    model.mediaServerId = "your_server_id";
    console.log('proxySave',model);
    let url = 'http://10.98.234.210:18080/api/proxy/save';
    if(location.protocol==='https:'){
        url = '/wvm/proxy/save';
    }
    // let url = '/wvm/proxy/save';
    return request({
        url: url,
        method: 'post',
        data:model
    });
}
export function getStreamInfo(app,stream){
    //http://10.98.234.210:18080/api/media/stream_info_by_app_and_stream
    return request({
        url: "/wvm/media/stream_info_by_app_and_stream?app="
        + app +"&stream="+ stream +"&mediaServerId=your_server_id",
        method: 'get'
    });
}


export function getCameraTokenInfo(param){
    return request({
        url: 'bivale/end/camera/tuken/get',
        method: 'post',
        data:param
    });
}

export function refreshCameraTokenInfo(param){
    return request({
        url: 'bivale/end/camera/tuken/refresh',
        method: 'post',
        data:param
    });
}

export function getVideoCameraInfo(id){
    return request({
        url: 'bivale/end/camera/info/'+id,
        method: 'get',
        data:null
    });
}

export function getVideoRecorerInfo(id){
    return request({
        url: 'bivale/end/recorder/info/'+id,
        method: 'get',
        data:null
    });
}

export function saveVideoCameraInfo(param){
    return request({
        url: 'bivale/end/camera/save',
        method: 'post',
        data:param
    });
}

export function saveVideoRecorerInfo(param){
    return request({
        url: 'bivale/end/recorder/save',
        method: 'post',
        data:param
    });
}

export function deleteVideoCameraInfo(param){
    return request({
        url: 'bivale/end/camera/delete',
        method: 'post',
        data:param
    });
}

export function deleteVideoRecorerInfo(param){
    return request({
        url: 'bivale/end/recorder/delete',
        method: 'post',
        data:param
    });
}


export function getVideoParamInfo(param){
    return request({
        url: 'bivale/end/video/param',
        method: 'post',
        data:param
    });
}

export function getVideoRecorerList(param){
    return request({
        url: 'bivale/end/recorder/list',
        method: 'post',
        data:param
    });
}

export function getVideoCameraInfoList(param){
    return request({
        url: 'bivale/end/camera/list',
        method: 'post',
        data:param
    });
}