import request from '@/utils/request'
import platform from '../utils/platformConfig';
// 登录接口
export function login(parameter) {
  return request({
    url: 'bivale/authen/loginNew',
    method: 'post',
    data: parameter
  });
}

// 获取图片验证码
export function validateCodeRequest() {
  return request({
    url: 'bivale/validateCode',
    method: 'get',
    responseType: 'blob',
    headers: {
      Accept: 'application/octet-stream',
    }
  });
}

// 获取RSA公钥接口
export function getRsa() {
  return request({
    url: 'bivale/publicKey',
    method: 'get'
  });
}

// 重置密码
export function resetPass(param) {
  return request({
    url: 'bivale/resetpass',
    method: 'post',
    data:param,
  });
}

// 获取导航栏接口(客户/建筑群/站点)
export function getstairmenu() {
  return request({
    url: '/bivale/custom/client/infos/list',
    method: 'get'
  });
}


export function getUserSiteServiceTypes() {
  return request({
    url: '/bivale/custom/client/build/service',
    method: 'get'
  });
}

// 退出登录接口
export function logout() {
  return request({
    url: 'bivale/logout',
    method: 'post'
  });
}

// 获取电梯模块卡片统计数据
export function getElevatorCardData(params) {
  return request({
    url: '/bivale/elevator/deviceCountList',
    method: 'get',
    params
  });
}

// 后端多语言设置
export function language(params) {
  return request({
    url: 'bivale/maintenance/setting/language',
    method: 'post',
    params
  });
}

// 设置管理--通知设置信息--获取用户信息
export function getUser(params) {
  return request({
    url: 'bivale/userById',
    method: 'get',
    params
  });
}

// 设置管理--通知设置信息--变更用户信息
export function updateUser(body) {
  return request({
    url: "bivale/updateUser",
    method: 'post',
    data: body,
  });
}

// 设置管理--通知设置信息--发送测试邮件
export function sendMail(params) {
  return request({
    url: 'bivale/sendMail',
    method: 'get',
    params
  });
}

// 设置管理--通知设置信息--检查密码
export function checkPassword(params) {
  return request({
    url: 'bivale/checkPassword',
    method: 'get',
    params
  });
}

// 会议管理--布局路径获取
export function homePageAddress(body) {
  return request({
    url: "bivale/Meeting/homePageAddress",
    method: 'post',
    data: body,
  });
}

// 会议管理--站点卡片数据获取
export function getlistWithinWeekMeetingInfo(params) {
  return request({
    url: 'bivale/Meeting/getlistWithinWeekMeetingInfo',
    method: 'get',
    params
  });
}


// 电梯设施管理系统--获取跳转地址
export function getRmcsUrl(params) {
  let url  = 'http://119.23.143.201:9090/elevatorms/account/login?token=xx';
  if(platform.bivale === 'ALIYUN'){
      url = 'https://elevator.bivale.cn:10502/elevatorms/account/login?token=xx';
  }
  return request({
    url:url,
    method: 'get',
    params
  });
}
