module.exports = {
    weather:{
        WEATHER_100:'晴れ',
        WEATHER_101:'曇りが多い',
        WEATHER_102:'少雲',
        WEATHER_103:'晴間曇り',
        WEATHER_104:'陰',
        WEATHER_150:'晴れ',
        WEATHER_151:'曇りが多い',
        WEATHER_152:'少雲',
        WEATHER_153:'晴間曇り',
        WEATHER_154:'陰',
        WEATHER_300:'にわか雨',
        WEATHER_301:'強いにわか雨',
        WEATHER_302:'夕立',
        WEATHER_303:'強い夕立',
        WEATHER_304:'夕立に雹が伴う',
        WEATHER_305:'小雨',
        WEATHER_306:'普雨',
        WEATHER_307:'大雨',
        WEATHER_308:'極端な降雨',
        WEATHER_309:'霧雨',
        WEATHER_310:'豪雨',
        WEATHER_311:'豪雨',
        WEATHER_312:'ゲリラ豪雨',
        WEATHER_313:'冷たい雨',
        WEATHER_314:'小雨から中雨まで',
        WEATHER_315:'大雨になる',
        WEATHER_316:'豪雨になる',
        WEATHER_317:'豪雨から豪雨へ',
        WEATHER_318:'豪雨からゲリラ豪雨へ',
        WEATHER_350:'にわか雨',
        WEATHER_351:'強いにわか雨',
        WEATHER_399:'雨',
        WEATHER_400:'小雪',
        WEATHER_401:'普雪',
        WEATHER_402:'大雪',
        WEATHER_403:'大雪',
        WEATHER_404:'みぞれ',
        WEATHER_405:'みぞれの天気',
        WEATHER_406:'にわか雨が雪をはさむ',
        WEATHER_407:'にわか雪',
        WEATHER_408:'小雪から中雪まで',
        WEATHER_409:'大雪になる',
        WEATHER_410:'大雪になる',
        WEATHER_456:'にわか雨が雪をはさむ',
        WEATHER_457:'にわか雪',
        WEATHER_499:'雪',
        WEATHER_500:'もや',
        WEATHER_501:'霧',
        WEATHER_502:'スモッグ',
        WEATHER_503:'サンドブラスト',
        WEATHER_504:'ちり',
        WEATHER_507:'砂嵐',
        WEATHER_508:'強い砂嵐',
        WEATHER_509:'濃霧',
        WEATHER_510:'強い濃霧',
        WEATHER_511:'ちゅうかんスモッグ',
        WEATHER_512:'ヘビースモッグ',
        WEATHER_513:'深刻なスモッグ',
        WEATHER_514:'深い霧',
        WEATHER_515:'とくべつ濃霧',
        WEATHER_800:'新月',
        WEATHER_801:'蛾眉月',
        WEATHER_802:'上弦の月',
        WEATHER_803:'満ちる月',
        WEATHER_804:'満月',
        WEATHER_805:'赤字月',
        WEATHER_806:'下弦の月',
        WEATHER_807:'残月',
        WEATHER_900:'あつい',
        WEATHER_901:'冷たい',
        WEATHER_999:'不明',
        WEATHER_1001:'台風',
        WEATHER_1002:'竜巻',
        WEATHER_1003:'あらしが吹く',
        WEATHER_1004:'吹雪',
        WEATHER_1005:'寒波',
        WEATHER_1006:'強い風',
        WEATHER_1007:'黄砂警報',
        WEATHER_1008:'低温冷凍',
        WEATHER_1009:'高温',
        WEATHER_1010:'熱波',
        WEATHER_1011:'かんねつくうき',
        WEATHER_1012:'ダウンパンチ暴流',
        WEATHER_1013:'アバランシェ',
        WEATHER_1014:'稲妻',
        WEATHER_1015:'雹',
        WEATHER_1016:'厳寒の天気',
        WEATHER_1017:'濃霧警告',
        WEATHER_1018:'ていそうふうせんだん',
        WEATHER_1019:'煙の警告',
        WEATHER_1020:'雷がひとしきり鳴る',
        WEATHER_1021:'道路が凍結する',
        WEATHER_1022:'干ばつ',
        WEATHER_1023:'海上の大風',
        WEATHER_1024:'暑気あたり',
        WEATHER_1025:'野火',
        WEATHER_1026:'草原火災',
        WEATHER_1027:'とうけつ',
        WEATHER_1028:'くうかんきこう',
        WEATHER_1029:'深刻な大気汚染',
        WEATHER_1030:'ていおんみぞれ',
        WEATHER_1031:'きょうたいりゅう',
        WEATHER_1032:'オゾン',
        WEATHER_1033:'大雪注意',
        WEATHER_1034:'寒さの警告',
        WEATHER_1035:'降り続く雨',
        WEATHER_1036:'はんらんさせる',
        WEATHER_1037:'地質災害',
        WEATHER_1038:'豪雨',
        WEATHER_1039:'深刻な温度低下',
        WEATHER_1040:'雪害',
        WEATHER_1041:'草原の野火',
        WEATHER_1042:'医学気象学',
        WEATHER_1043:'雷雨',
        WEATHER_1044:'学校が休講になる',
        WEATHER_1045:'工場閉鎖',
        WEATHER_1046:'海上リスク',
        WEATHER_1047:'春塵',
        WEATHER_1048:'温度を下げる',
        WEATHER_1049:'台風と豪雨',
        WEATHER_1050:'厳寒',
        WEATHER_1051:'砂塵',
        WEATHER_1052:'かいじょうらいげき',
        WEATHER_1053:'海霧',
        WEATHER_1054:'海雷',
        WEATHER_1055:'海上台風',
        WEATHER_1056:'低温',
        WEATHER_1057:'道路の氷と雪',
        WEATHER_1058:'雷雨と強風',
        WEATHER_1059:'れんぞくていおん',
        WEATHER_1061:'きょうじん',
        WEATHER_1064:'短い豪雨',
        WEATHER_1101:'洪水',
        WEATHER_1302:'土石流',
        WEATHER_1402:'ストーム潮',
        WEATHER_1601:'非常に暑い天気',
        WEATHER_1602:'きょうせいモンスーンしんごう',
        WEATHER_1603:'すべり',
        WEATHER_1604:'ねったいサイクロン',
        WEATHER_1605:'火災の危険',
        WEATHER_1606:'新界北部の水浸し',
        WEATHER_1607:'寒い天気',
        WEATHER_2001:'風',
        WEATHER_2002:'氷雪',
        WEATHER_2003:'霧',
        WEATHER_2004:'沿海事件',
        WEATHER_2005:'森林火災',
        WEATHER_2006:'雨の警告',
        WEATHER_2007:'雨水',
        WEATHER_2008:'冷たい雨が凍る',
        WEATHER_2009:'ゆかしあげ',
        WEATHER_2010:'ばいじん風',
        WEATHER_2011:'強い地上風',
        WEATHER_2012:'暑い日',
        WEATHER_2013:'暖かい夜は',
        WEATHER_2014:'寒い日',
        WEATHER_2015:'雷雨と稲妻',
        WEATHER_2016:'雹',
        WEATHER_2017:'海域警告',
        WEATHER_2018:'漁民の警告',
        WEATHER_2019:'低湿度',
        WEATHER_2020:'雨が積もる',
        WEATHER_2021:'危険な波浪の警告',
        WEATHER_2022:'海上風力警報',
        WEATHER_2023:'道路気象警報',
        WEATHER_2024:'雷雨',
        WEATHER_2025:'雷雨霧',
        WEATHER_2026:'有害風',
        WEATHER_2027:'草原火災条件',
        WEATHER_2028:'天気予報',
        WEATHER_9998:'悪天候警告',
        WEATHER_9999:'警告デフォルト',
    }
}