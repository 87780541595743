// 操作记录 -- 中文简体
module.exports = {
    userOperationHistory:{
        a1:'操作記錄',
        a2:'用戶名稱',
        a3:'登入ID',
        a4:'服務',
        a5:'畫面名稱',
        a6:'操作內容',
        a7:'設備名稱',
        a8:'訊號名稱',
        a9:'發生日期',
        a10:'操作類型',
        a11:'操作時間',
        a12:'操作詳情',
        a13:'操作項',
        a14:'原數據',
        a15:'新數據',
    }
}