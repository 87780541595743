import axios from 'axios'
import { Modal } from 'ant-design-vue'
import store from '@/store'
import { getToken, removeToken } from '@/utils/auth'

const service = axios.create({
    // baseURL: "http://10.98.1.207:8443",
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 30 * 100000
})

service.interceptors.request.use(
    config => {
        if (store.getters.token) {
            config.headers['Authorization'] = `BIVALE ${getToken('Admin-Token')}`
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response
        if (res.data.errorCode == '64') {
            Modal.error({
                title: '登录失败',
                content: '账户或密码错误',
                okText: '重新输入',
                width: 320,
                maskClosable: true,
                centered: true,
            })
            return Promise.reject(new Error(errMsg))
        } else if (res.data.errorCode == '65') {
            Modal.error({
                title: '登录失败',
                content: '该账户已在其他地方登录',
                width: 320,
                maskClosable: true,
                centered: true,
            })
            return Promise.reject(new Error(errMsg))
        } else if (res.data.errorCode == '68') {
            Modal.error({
                title: '登录失败',
                content: '登录账户登录次数超过限制',
                width: 320,
                maskClosable: true,
                centered: true,
            })
            return Promise.reject(new Error(errMsg))
        } else if (res.data.errorCode == 'EE') {
            Modal.error({
                title: '登录失败',
                content: '系统错误',
                width: 320,
                maskClosable: true,
                centered: true,
            })
            return Promise.reject(new Error(errMsg))
        } else {
            return res
        }
    },
    error => {
        console.log('reject', error.response)
        let data = error.response.data
        console.log('data', data)
        if (data.status == 401) {
            Modal.error({
                title: '登录失效',
                content: '您的登录状态已经失效，请重新登录',
                okText: '重新登录',
                centered: true,
                onOk: () => {
                    removeToken('Admin-Token')
                    location.reload()
                }
            })
        }
        return Promise.reject(error)
    }
)

export default service
