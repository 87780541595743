export default [
    {
        path: '/homepage/attendance', //考勤模块--考勤主页
        component: () => import('@/views/attendance/attendanceLayout'),
        meta: { hidden: true },
        children: [
            //设备 1.1
            {
              path: '/homepage/attendance/equipment', //考勤模块--设备页
              name: 'ATD_EQUIPMENT',
              component: () => import('@/views/door/equipment'),
              meta: { hidden: true }
            },
            //警报 2.1
            {
              path: '/homepage/attendance/alarm', //考勤模块--报警页
              name: 'ATD_ALARM',
              component: () => import('@/views/door/alarm'),
              meta: { hidden: true }
            },
            //记录 3.1
            {
              path: '/homepage/attendance/throughRecords', //考勤模块--通行记录
              name: 'ATD_RIREKI',
              component: () => import('@/views/door/throughRecords'),
              meta: { hidden: true }
            },
            //记录 3.2
            {
              path: '/homepage/attendance/attendance', //考勤模块--通行记录
              name: 'ATD_RIREKI_ATTENDANCE',
              component: () => import('@/views/attendance/relationship'),
              meta: { hidden: true }
            },
            //状况显示 4.1
            {
              path: '/homepage/attendance/strandedPersonnel', //考勤模块--滞留人员显示
              name: 'ATD_ZANRYU',
              component: () => import('@/views/attendance/relationship'),
              meta: { hidden: true }
            },
            //4.2
            {
              path: '/homepage/attendance/indoorPersonne', //考勤模块--室内人员显示
              name: 'ATD_ZAISITU',
              component: () => import('@/views/attendance/relationship'),
              meta: { hidden: true }
            },

            //数据 5.1
            {
              path: '/homepage/attendance/dataOutput', //考勤模块--数据输出
              name: 'ATD_REPORT',
              component: () => import('@/views/attendance/relationship'),
              meta: { hidden: true }
            },
            {
              path: '/homepage/attendance/dataOutput', //考勤模块--数据输出
              name: 'ATD_DATAOUTPUT',
              component: () => import('@/views/attendance/relationship'),
              meta: { hidden: true }
            },
          

            //设置 6.1
            {
              path: '/homepage/attendance/facialInfo', //考勤模块--人脸终端信息
              name: 'ATD_FACE_NINSHOU',
              component: () => import('@/views/door/facialInfo'),
              meta: { hidden: true }
            },
            //6.2
            {
              path: '/homepage/attendance/cardNumber', //考勤模块--车牌管理
              name: 'ATD_CAR_PLATENUMBER',
              component: () => import('@/views/attendance/relationship'),
              meta: { hidden: true }
            },
            //6.3
            {
              path: '/homepage/attendance/carPark', //考勤模块--人脸终端信息
              name: 'ATD_CAR_PARK',
              component: () => import('@/views/attendance/relationship'),
              meta: { hidden: true }
            },

            //6.4
            {
              path: '/homepage/attendance/doorTimetable', //考勤模块--门禁时间表
              name: 'ATD_NYUTAI_SCE',
              component: () => import('@/views/door/doorTimetable'),
              meta: { hidden: true }
            },
            //6.5
            {
              path: '/homepage/attendance/trafficPatterns', //考勤模块--通行模式
              name: 'ATD_TUKO_PATTERN',
              component: () => import('@/views/door/trafficPatterns'),
              meta: { hidden: true }
            },
            //6.6
            {
              path: '/homepage/attendance/cardReaderAlarm', //考勤模块--读卡器报警
              name: 'ATD_CR_ALERM',
              component: () => import('@/views/door/cardReaderAlarm'),
              meta: { hidden: true }
            },
            //6.7
          
            {
              path: '/homepage/attendance/area', //考勤模块--滞留人员显示
              name: 'ATD_AREA',
              component: () => import('@/views/attendance/relationship'),
              meta: { hidden: true }
            },
            //6.8
            {
              path: '/homepage/attendance/stayArea', //考勤模块--室内人员显示
              name: 'ATD_AREA_STAY',
              component: () => import('@/views/attendance/relationship'),
              meta: { hidden: true }
            },
        ],
      },
]