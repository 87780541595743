// 测速模块
module.exports = {
    velocityMeasurement:{
        a1: 'Loading...',
        a2: 'Vehicle overspeed statistics',
        a3: 'Device',
        a4: 'Device details',
        a5: 'Close',
        a6: 'Device name',
        a7: 'Speed measurement record',
        a8: 'Speed measurement point',
        a9: 'License plate number',
        a10: 'Speed:',
        a11: 'Query',
        a12: 'License plate number:',
        a13: 'License plate color:',
        a14: 'Body color:',
        a15: 'Speed measurement location:',
        a16: 'Snap time:',
        a17: 'Vehicle speed:',
        a18: 'Vehicle type:',
        a19: 'Details',
        a20: 'pieces of data',
        a21: 'Vehicle image',
        a22: 'Snap time',
        a23: 'Vehicle type',
        a24: 'Driving direction',
        a25: 'East>West',
        a26: 'West>East',
        a27: 'South>North',
        a28: 'North>South',
        a29: 'Southeast ->Northwest',
        a30: 'Northwest ->Southeast',
        a31: 'Northeast ->Southwest',
        a32: 'Southwest ->Northeast',
        a33: 'Speed measurement location',
        a34: 'Speed',
        a35: 'License plate number',
        a36: 'Operation',
        a37: 'Data statistics',
        a38: 'Speed measurement record list',
        a39: 'Overspeed speed setting',
        a40: 'OK',
        a41: 'Overspeed speed:',
        a42: 'Not exceeding the speed limit',
        a43: 'Over speed'
    }
}