module.exports = {
    cleanEnergyAnalysis:{
        a1:'加载中....',
        a2:'清洁能源',
        a3:'详情数据',
        a4:'比例分析',
        a5:'能源对比',
        a6:'自定义起始月',
        a7:'查询',
        a8:'CSV导出',
        a9:'请选择条件进行查询',
        a10:'电量(kWh)',
        a11:'占比(%)',
        a12:'累积电量(kWh)'
    }
}