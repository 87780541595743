// 日文表
module.exports = {
  // 登录页面
  login: {
    Q1: 'ログイン',
    Q2: 'ユーザー名',
    Q3: 'パスワード1',
    Q4: 'パスワード2',
    Q5: '言語',
    Q6: 'ログイン',
    Q7: 'パスワードが入力されていません。',
    Q8: 'ユーザー名が入力されていません。',
    Q18:"ログイン失敗",
    Q9:"再入力",
    Q10:"ログイン失効",
    Q11:"再ログイン",
    Q12:"ＩＤ、またはパスワードが間違っています。",
    Q13:"同一のＩＤが他のクライアントでログインしています。",
    Q14:"アカウントはロックされています。後で再試行してください。",
    Q15:"システムエラー",
    Q16:"パスワードの期限切れ",
    Q17:"ログインステータスが無効になっています。再ログインしてください。",
    Q19:'パスワードを忘れた？',
    Q20:'パスワードのリセット',
    Q21:'認証コード',
    Q22:'認証コードを入力してください！',
    Q23:'電子メールアドレス',
    Q24:'電子メールアドレスを入力してください！',
    Q25:'入力されたメールボックスが無効です！',
    Q26:'送信',
    Q27:'ログインに戻る',
    Q28:'IDと認証コードと電子メールアドレスを入力し、送信ボタンをクリックしてください。',
    Q29:'新規パスワード1とパスワード2を事前に登録した電子メールアドレスに送信します。',
    Q30:'認証コードを入力してください',
    ERR0:'パスワードのリセットに成功しました。電子メールを確認してください。',
    ERR1:'ユーザー名、認証コード、または電子メールアドレスが間違っています。',
    ERR2:'ユーザーがログインしています。',
    ERR3:'メール送信に失敗しました。',
    ERR4:'パスワード規定リセット回数を超えました。',
    ERR5:'パスワードリセット操作が無効です。',
    ERR6:'パスワードのリセットに失敗しました。',
  },
  // 侧边栏 a:头像/b:站点/c:认证信息/d:设置管理/e:通知/f:帮助/g:退出
  sidebar: {
    a1: '私の',
    a2: '私のIBMS',
    b1: 'サイト',
    c1: '人員管理',
    c2: 'カード情報',
    c3: '人員情報',
    d1: '設定管理',
    e1: '通知',
    e2: '警報',
    f1: '助けて',
    f2: 'CSV エクスポート',
    f3: 'プリント',
    f4: '助けて',
    f5: 'ウェブサイト声明',
    g1: 'ログアウト',
    g2: 'ログアウトするかどうか',
    g3: 'ログアウト後、再ログインしてから操作を再開する必要があります',
    a3: 'ユーザー名：',
    a4: 'ログインアカウント：',
    a5: '電子メール：',
    a6: 'パスワードを変更する',
    a7: 'パスワード1',
    a8: 'パスワード2',
    a9: 'パスワードを入力してください',
    a10: '閉じる',
    a11: '確定',
    a12: '新しいパスワード1',
    a13: '新しいパスワード2',
    a14: '再登録してください',
    a15: 'エネルギー効率カンバン',
    a16: 'より多く',
    a17: '編集',
    a18:'携帯電話：',
    a19:'微信を開通するかどうか',
    a20:'携帯番号を入力してください',
    a21:'WeChatユーザー名を入力してください',
    a22:'WeChatユーザー名:',
    a23: 'パスワードの2回の入力が一致しません！',
    a24: 'パスワードのフォーマットが間違っており、パスワードには特殊文字、数字、アルファベットの3つの組み合わせが必要です。パスワードの長さは8 ~ 16です。',
    a25: 'パスワード1とパスワード2は同じにできません。再入力してください！',
    a26: 'ユーザー設定',
    a27:'新しいパスワードの確認1',
    a28:'新しいパスワードの確認2',
    a29:'ウィーチャット設定',
    a30:'パスワードのリセット',
    a31:'ログインアカウントを入力する',
    a32:'入力メールボックス'
  },
  // 门禁模块--报警页
  alarm: {
    a0: 'ローディング中....',
    a1: '警報',
    a2: '機器名称',
    a3: 'フロア・テナント選択',
    a4: '初期日時選択',
    a5: '終了日時選択',
    a6: 'クエリ',
    a7: '警報リスト',
    a8: '条データ',
    a9: '警報確認',
    a10: '手動復旧',
    a11: '対応',
    a12: '順位復旧',
    a13: 'アラート出力',
    a14: 'コメント登録',
    a15: '選択操作',
    a16: 'コメント登録',
    a17: '内容を入力してください......',
    a18: '日時を選択してください',
    a19: 'ローディング中....',
    a20: '発生日時',
    a21: '復旧日時',
    a22: '発報種別',
    a23: '警報種別',
    a24: '建物名称',
    a25: '機器名称',
    a26: '映像リンク',
    a27: '信号／端末名称',
    a28: '警報内容',
    a29: '対応状態',
    a30: '説明',
    a31: 'カードID',
    a32: '人はだ',
    a33: 'コメント',
    a34: '操作',
    a35: '未確認',
    a36: '未復旧',
    a37: '未対応',
    a38: 'すべて',
    a39: 'デバイス名を入力してください',
    a40: 'キャンセル',
    a41: '確認',
    a42: '対応状態変更',
    a43: '現在の対応状況:',
    a44: '変更後:',
    a45: '対応中',
    a46: '対応済',
    a47: '選択されたアラートの確認登録を行うと判断しますか?',
    a48: '確定手動修復するの?',
    a49: '警報状態',
    a50: 'コンテンツを表示する',
    a51: 'CSVエクスポート',
    a52: 'KPI種別',
    a53: '現在値',
    a54: '限界値',
    a55: 'デッドバンド',
    a56: '比率（%）',
    a57: '警報レベル',
    a58:'サイト情報を選択してください',
  },
  // 门禁模块--卡片记录（通行记录）
  throughrecords: {
    a0: 'ローディング中....',
    a1: 'カード記録',
    a2: '名前',
    a3: 'カード番号',
    a4: '初期日時選択',
    a5: '終了日時選択',
    a6: 'クエリ',
    a7: '記録リスト',
    a8: '日時を選択してください',
    a9: '顔認識画像',
    a10: '注釈を追加します',
    a11: '選択操作',
    a12: '注釈',
    a13: 'キャンセル',
    a14: '確認',
    a15: '内容を入力してください....',
    a16: '条データ',
    a17: '発生日',
    a18: '認証タイプ',
    a19: '名前',
    a20: 'カード番号',
    a21: '体温',
    a22: 'デバイスグループ名',
    a23: '設備名',
    a24: '信号/端末名',
    a25: '建物名',
    a26: '注釈',
    a27: '操作',
    a28: '名前を入力してください',
    a29: 'カード番号を入力してください',
    a30: '場所',
    a31: 'マスク',
    a32: '顔写真の読み込みに失敗しました。後でやり直してください。',
    a33: '管理コード',
    a34: '顔認識記録',
    a35: '訪問者通行記録',
    a36: '管理コードを入力してください',
    a37: '信号/ターミナル名を入力してください',
    a38: 'デバイスグループ名を入力してください',
    a39: 'デバイス名を入力してください',
  },
  // 门禁模块--考勤记录
  attendance: {
    a1: 'ローディング中....',
    a2: '勤務記録',
    a3: '人の所属',
    a4: '社員番号',
    a5: 'スタッフの名前',
    a6: '初期日時選択',
    a7: '終了日時選択',
    a8: 'クエリ',
    a9: '出席記録リスト',
    a10: '日付または時間を選択してください',
    a11: '条データ',
    a12: '社員番号',
    a13: '従業員の名前',
    a14: '勤務日',
    a15: '勤務開始時刻',
    a16: '勤務終了時刻',
    a17: '入力してください',
    a18: '従業員番号を入力してください',
    a19: '人員名を入力してください',
  },
  // 门禁模块--滞留人员显示
  strandedPersonnel: {
    a1: 'ローディング中....',
    a2: '滞在人員表示',
    a3: '領域選択',
    a4: '照会',
    a5: '滞在人員一覧',
    a6: '削除',
    a7: 'タイムアウト設定',
    a8: '設定',
    a9: '閉鎖',
    a10: '時間を超えて',
    a11: ' hh時mm分',
    a12: '条データ',
    a13: '地域名',
    a14: '名前',
    a15: '入室時間',
    a16: 'カード番号',
    a17: '滞在時間',
    a18: '部署',
    a19: '所属1',
    a20: '所属2',
    a21: '所属3',
    a22: '所属4',
    a23: '所属5',
    a24: '操作',
    a25: '削除確定',
    a26: 'メッセージ',
    a27: '時間を選んでください',
  },
  // 门禁模块--室内人员显示
  indoorPersonne: {
    a1: 'ローディング中....',
    a2: '室内スタッフ表示',
    a3: '領域選択',
    a4: '照会',
    a5: '室内関係者リスト',
    a6: 'リセット',
    a7: '室内スタッフ設置',
    a8: '設定',
    a9: '閉鎖',
    a10: 'カード番号',
    a11: 'カード番号を入力してください',
    a12: '管理コード',
    a13: '管理コードを入力してください',
    a14: 'クエリ',
    a15: '全個人リスト',
    a16: '追加',
    a17: '登録者リスト',
    a18: '削除',
    a19: '条データ',
    a20: '地域名',
    a21: '名前',
    a22: '部門',
    a23: 'いる/いない',
    a24: '門を入る/出かける時間',
    a25: '所属1',
    a26: 'グループ名',
    a27: 'カード番号',
    a28: '所属2',
    a29: '所属3',
    a30: '所属4',
    a31: '所属5',
    a32: '操作',
    a33: 'カード番号',
    a34: '管理コード',
    a35: '名前',
    a36: '所属1',
    a37: '操作',
    a38: '更新を確定します',
    a39: 'メッセージ',
    a40: '領域を選択してください',
  },
  // 门禁模块--门禁时间表
  doortimetable: {
    a1: 'ローディング中....',
    a2: 'アクセススケジュール',
    a3: 'スケジュール名:',
    a4: 'キャンセル',
    a5: '確認',
    a6: 'カードモード',
    a7: 'フリーモード',
    a8: '反復パターン',
    a9: 'カード・アウト・フリーモード',
    a10: 'フリー/アウトカードモードになります',
    a11: 'カード認証',
    a12: 'カード認証+テンキー認証',
    a13: 'カード認証+指静脈認証',
    a14: '通行不可',
    a15: '通行可',
    a16: '非警戒',
    a17: '警戒中',
    a18: '警戒無効時間',
    a19: 'スケジュール間複製',
    a20: '時間割複製',
    a21: '時刻表全表コピー',
    a22: '設定',
    a23: 'キャンセル',
    a24: '複製元時刻表',
    a25: 'スケジュールを選択してください',
    a26: '時刻表複製',
    a27: '複製元',
    a28: 'コピーします',
    a29: '月曜日',
    a30: '火曜日',
    a31: '水曜日',
    a32: '木曜日',
    a33: '金曜日',
    a34: '土曜日',
    a35: '日曜日',
    a36: '休日',
    a37: 'テイル1',
    a38: 'テイル2',
    a39: 'テイル3',
    a40: 'テイル4',
    a41: '曜日が正しく指定されていませんので再指定してください',
    a42: 'スケジュールを選択していません。スケジュールを選択してください',
    a43: '曜日を選択していません。曜日を選択してください',
    a44: 'カレンダーをセットしてください',
    a45: 'スケジュール名を入力してください',
    a46: 'スケジュール名の更新',
    b1: 'スケジュール変更',
    b2: 'スケジュールタイプ:',
    b3: 'タイムテーブル名:',
    b4: 'プロジェクト:',
    b5: '初期値は「カードモード」ですので、他のモードを設定してください。',
    b6: 'モード1',
    b7: 'モード2',
    b8: 'モード3',
    b9: 'モード4',
    b10: 'モード5',
    b11: 'リセット',
    b12: '門時刻表',
    b13: 'カード認証スケジュール',
    b14: '通行時刻表',
    b15: '警備時間割',
    b16: '設定内容の登録はokですか?',
    b17: '"時刻が正しく指定されていません。再度指定してください',
    b18: 'とき',
    b19: 'ふん'
  },
  // 门禁模块--车牌管理
  plateNumber: {
    a1: "ローディング中",
    a2: "ナンバープレート管理",
    a3: "管理コード",
    a4: "管理コードを入力してください",
    a5: "スタッフの名前",
    a6: "名前を入力してください",
    a7: "携帯電話番号",
    a8: "携帯電話番号を入力してください",
    a9: "ナンバープレート",
    a10: "ナンバープレートの番号を入力してください",
    a11: "ナンバープレートの色",
    a12: "無",
    a13: "白牌",
    a14: "ブラックカード",
    a15: "藍牌",
    a16: "イエローカード",
    a17: "緑牌",
    a18: "認証モード",
    a19: "無",
    a20: "ホワイトリスト",
    a21: "ブラックリスト",
    a22: "臨時車",
    a23: " vip車",
    a24: "カーカー",
    a25: "時間",
    a26: "無",
    a27: "開始日",
    a28: "有効日",
    a29: "クエリ",
    a30: "ナンバープレートの一覧",
    a31: "削除",
    a32: "ナンバープレート管理",
    a33: "ナンバープレート管理",
    a34: "選択操作",
    a35: "ナンバープレート管理",
    a36: "ナンバープレートをつける",
    a37: "ナンバープレート管理",
    a38: "設定",
    a39: "閉じる",
    a40: "基本情報",
    a41: "管理コード",
    a42: "名前",
    a43: "部門",
    a44: "携帯電話番号",
    a45: "メール",
    a46: "所属サイト",
    a47: "所属チーム",
    a48: "ナンバープレート情報",
    a49: "ナンバープレートを付ける",
    a50: "ナンバープレートの所属",
    a51: "ボディカラー",
    a52: "ブランド",
    a53: "認証モード",
    a54: "開始日",
    a55: "有効日",
    a56: "携帯電話番号を入力してください",
    a57: "ナンバープレートの番号を入力してください",
    a58: "時間を選んでください",
    a59: "条データ",
    a60: "操作",
    a61: "開始日",
    a62: "開始日を選択してください",
    a63: "確定",
    a64: "終了日",
    a65: "終了日を選択してください",
    a66: " 1人に5枚までナンバープレートを付けてください",
    a67: "携帯番号のフォーマットが間違っています",
    a68: "ナンバープレートのフォーマットが間違っています",
    a69: "認証モードを選択してください」",
    a71:"ホワイトリスト",
    a72:"ブラックリスト",
    a73:"臨時車",
    a74:"vip車",
    a75:"家賃車",
    a76:"けんさ免除車",
    a77:"ノンチェックカー",
    a78:"一時カード",
    a79:"ロングレンタルカード",
    a80:"月極カード",
    a81:"MVPカード",
    a82:"VIPカード",
    a83:"メモリカード",
    a84:"無料カード",
    a85:"ビジターカード",
    a86: "ナンバープレートを削除することを確認しますか?",
    a87:'ナンバープレートの所属者情報を入力してください。',
    a88:'ナンバープレートの色を選択してください',
    a89:'テンプレートファイルのダウンロード',
    a90:'ナンバープレート情報をアップロードする',
    a91:'ナンバープレート情報のエクスポート',
    a92:'通行区域',
    a93:'車主の性別',
    a94:'女',
    a95:'男',
    a96:'組織部門',
    a97:'組織部門を選択してください',
    a98:'サードパーティシステム',
    a99:'同期データ',
    a100:'通行エリアを選択してください',
    a101:'ファイルテンプレートのインポート',
    a102:'ユーザタイプ',
    a103:'システムマン',
    a104:'普通の人',
    a105:'所属サイトを選択してください',
    a106:'個人情報を選択してください',
    a107:'入力された電子メールは無効です！',
    a108:'設定されたナンバープレート情報を保存することを確認しますか？',
    a109:'インポートファイルの選択',
    a110:'ナンバープレート情報のインポートに失敗しました。',
    a111:'ナンバープレート情報の一括アップロード',
  },

  // 门禁模块--室内人员区域
  indoorArea: {
    a1: 'ローディング中....',
    a2: '室内スタッフエリア',
    a3: '領域選択',
    a4: '照会',
    a5: '登録の門',
    a6: '追加エリア/パケット',
    a7: 'エリア/パケットを削除する',
    a8: 'エリア編集',
    a9: '名称変更',
    a10: '設定',
    a11: 'クローズ',
    a12: 'パケット・エリア選択を追加します',
    a13: 'パケット',
    a14: 'エリア',
    a15: 'パケット/エリア名',
    a16: '室内人員区域名',
    a17: '室内エリアの設定',
    a18: '地域名:',
    a19: 'すべての扉',
    a20: '追加',
    a21: '登録済みゲート',
    a22: '削除',
    a23: '領域名を入力してください',
    a24: '条データ',
    a25: 'デバイス名',
    a26: '認証端末名',
    a27: 'フロア・テナント名',
    a28: '操作',
    a29: 'パケット/エリアを追加するのはokですか?',
    a30: 'パケット/エリア名を入力してください',
    a31: '削除確定',
    a32: 'か?',
    a33: '設定内容の登録はokですか?',
  },
  // 门禁模块--滞留人员区域
  detentionArea: {
    a1: 'ローディング中....',
    a2: '滞在人員区域',
    a3: '領域選択',
    a4: '照会',
    a5: '登録の門',
    a6: '追加エリア/パケット',
    a7: 'エリア/パケットを削除する',
    a8: 'エリア編集',
    a9: '名称変更',
    a10: '設定',
    a11: 'クローズ',
    a12: 'パケット・エリア選択を追加します',
    a13: 'パケット',
    a14: 'エリア',
    a15: 'パケット/エリア名',
    a16: '滞在人員区域名',
    a17: '滞在区域設置',
    a18: '地域名:',
    a19: 'すべての扉',
    a20: '追加',
    a21: '登録済みゲート',
    a22: '削除',
    a23: '領域名を入力してください',
    a24: '条データ',
    a25: 'デバイス名',
    a26: '認証端末名',
    a27: 'フロア・テナント名',
    a28: '操作',
    a29: 'パケット/エリアを追加するのはokですか?',
    a30: 'パケット/エリア名を入力してください',
    a31: '削除確定',
    a32: 'か?',
    a33: '設定内容の登録はokですか?',
  },
  // 门禁模块--车场设置
  parkingLotSet: {
    a1: 'ローディング中',
    a2: 'トラック設置',
    a3: 'トラック人員構造情報',
    a4: 'トラック配置情報',
    a5: '同期組織構造',
    a6: '同期組織を削除します',
    a7: '仮無数拠',
    a8: '更新する組織構造を選択していません',
    a9: '更新が必要な組織を選択してください',
    a10: '確定',
    a11: '選択した組織アーキテクチャとそのサブアーキテクチャ情報をサードパーティーシステムに更新すると判断したか?',
  },
  // 门禁模块--人脸认证信息设置
  facialInfo: {
    a1: '顔認証情報',
    a2: 'デバイス状態',
    a3: 'デバイス名',
    a4: 'クエリ',
    a5: '顔機器の一覧',
    a6: '識別記録管理',
    a7: '編集',
    a8: '選択操作',
    a9: '設備管理',
    a10: '機器シリアル番号',
    a11: '関連リーダ',
    a12: '機器タイプ',
    a13: '設備ip ',
    a14: 'デバイスパスワード',
    a15: '心拍コールバックurl ',
    a16: '認証コールバックurl ',
    a17: 'デバイスコールバックurl ',
    a18: '設備備考',
    a19: '条データ',
    a20: '備考',
    a21: '操作',
    a22: 'ウパン',
    a23: '奇智',
    a24: '海康',
    a25: '編集した情報を設定するのはokですか?',
    a26: 'すべて',
    a27: '正常',
    a28: 'トラブル',
    a29: '読み込み中....',
    a30: 'デバイス名を入力してください',
    a31: '端末ブランド',
    a32: 'アップロード背景図',
    a33: '閉じる',
    a34: '顔端末',
    a35: '設定',
    a36: 'ピクチャポート',
    a37: '背景画像',
    a38: 'すべて削除',
    a39: '削除',
    a40: '画像を選択',
    a41: '画像をアップロード',
    a42: '設備現場図',
    a43: '画像を読み取る',
    a44: 'すべての画像を読み込む',
    a45: '顔端末待機背景図設定',
    a46: 'pngとjpg形式の画像しかアップロードできない！',
    a47: '画像サイズは300 KBを超えてはいけない！',
    a48: '設定した情報を保存することを確認しますか？',
    a49: '選択した画像をアップロードすることを確認しますか？',
    a50: 'この画像を削除することを確認しますか？',
    a51: 'すべての画像を削除することを確認しますか？'
  },
  // 门禁模块--读卡器报警
  cardreaderalarm: {
    a1: 'ローディング中....',
    a2: 'カードリーダアラーム',
    a3: '認証端末',
    a4: '認証端末を入力してください',
    a5: 'クエリ',
    a6: '認証端末リスト',
    a7: '警告設定',
    a8: 'アラーム設定',
    a9: '開扉タイムアウト警報:',
    a10: '無',
    a11: 'あります',
    a12: '(警報提示時刻:',
    a13: '秒',
    a14: '復旧時に停止します)',
    a15: 'ドア障害警報:',
    a16: '異常開扉警報:',
    a17: '強行進入警報:',
    a18: '無循環警報:',
    a19: '条データ',
    a20: '認証端末',
    a21: '開超鳴働',
    a22: '門故障鳴働',
    a23: '異常開鳴動',
    a24: '侵入鳴働',
    a25: '無循環鳴働',
    a26: '操作',
    a27: '設定内容の登録はokですか?',
  },
  // 门禁模块--实际数据
  realdata: {
    a1: 'ローディング中....',
    a2: 'レポートデータ',
    a3: 'すべて',
    a4: '門',
    a5: 'カード',
    a6: '認証動作履歴',
    a7: '月報',
    a8: '日報',
    a9: '出力期間:',
    a10: 'ファイル分解数:',
    a11: '出力日数:',
    a12: '1分割せずにファイルを生成する',
    a13: ' 15日ごとに分割(最後のファイルの終了日を含む)ファイルを作成',
    a14: '3 20日ごとに分割(最後のファイルの終了日を含む)ファイルを作成',
    a15: '4 7日ごとに分割(最後のファイルの終了日を含む)ファイルを作成する',
    a16: '5 6日ごとに分割して(最後のファイルの終了日も含めて)ファイルを生成する',
    a17: '出力量が大きい場合、除算数は自然に5に変更されます',
    a18: 'ダウンロードファイル名:',
    a19: '出力中…',
    a20: '出力',
    a21: 'ファイル分割の説明',
    a22: '名前',
    a23: '名前を入力してください',
    a24: 'カード番号',
    a25: 'カード番号を入力してください',
    a26: 'カードid ',
    a27: 'カードidを入力してください',
    a28: '管理コード',
    a29: '管理コードを入力してください',
    a30: 'クエリ',
    a31: '認証情報リスト',
    a32: '条データ',
    a33: 'マネジメント所属',
    a34: '日付を選択していません',
    a35: '日付を選択してください',
    a36: 'デバイスグループ名',
    a37: 'デバイスグループ名を入力してください',
    a38: 'デバイス名',
    a39: 'デバイス名を入力してください',
    a40: 'ドアリスト',
  },
  // 门禁模块--通行模式
  trafficpatterns: {
    a1: 'ローディング中....',
    a2: '通行パターン',
    a3: '通行パターン名：',
    a4: '通行パターン番号:',
    a5: '登録済みの認証端末',
    a6: '通行モードを選択してください',
    a7: '歩行パターンコピー',
    a8: '認証端末編集',
    a9: '元通行パターンを複製します',
    a10: '通行モードを選択していません',
    a11: 'コピーが必要な通行パターンを選択してください',
    a12: '認証端末名',
    a13: 'クエリ',
    a14: '全ての認証端末',
    a15: '追加',
    a16: '削除',
    a17: '通行スケジュール・カレンダー変更',
    a18: '設定',
    a19: 'スケジュール・カレンダー選択',
    a20: '通行時刻表:',
    a21: '通行スケジュールを選択してください',
    a22: 'カレンダー:',
    a23: 'カレンダーを選択してください',
    a24: '警戒・解錠切替操作設定:',
    a25: '有効',
    a26: '無効',
    a27: '条データ',
    a28: '企業名',
    a29: '建物名',
    a30: 'フロア・テナント名',
    a31: 'デバイス名',
    a32: '認証端末名',
    a33: '出入りタイプ',
    a34: '警戒設定',
    a35: '無',
    a36: 'ある',
    a37: 'スケジュール名',
    a38: 'カレンダー名',
    a39: '認証端末が選択されていません',
    a40: '変更が必要な認証端末を選択してください',
    a41: 'スケジュールやカレンダーが設定されていない端末があります',
    a42: 'スケジュールやカレンダーを設定していない端末で、スケジュールやカレンダーを設定してください',
    a43: '通行パターン名の更新',
    a44: '通行モード名を入力してください',
    a45: '検索テキストの入力',
    a46: '通行許可者情報'
  },
  details: {
    a1: 'ローディング中....',
    a2: 'デバイス名',
    a3: 'デバイスid ',
    a4: 'デバイスguid ',
    a5: '左入口',
    a6: '名前',
    a7: '顧客名',
    a8: 'タイプ',
    a9: '機能サイト',
    a10: 'フロア・テナント名',
    a11: 'キャンセル',
    a12: '確認',
    a13: 'プロジェクト',
    a14: 'ステータス・オペレーション',
    a15: '状態変化時間',
    a17: '選択',
    a18: 'スケジュール設定',
    a19: '設備詳細情報',
    a20: '時間割',
    a21: 'カレンダー',
    a22: 'ドア開有効時間',
    a23: 'ドア開タイムアウト検出時間',
    a24: '音の有無',
    a25: '有声音',
    a26: '無音',
    a27: '電気ロック動作回数',
    a28: '電気ロック動作回数オーバー監視',
    a29: '電気ロック動作回数上限',
    a30: '設定内容の登録はokですか?',
    a31: 'ゲート状態',
    a32: '異常開',
    a33: '一時的にロック解除',
    a34: 'オープンオーバー',
    a35: '侵入',
    a36: 'アンロック状態',
    a37: '無循環',
    a38: '通行状態',
    a39: '運行状態',
    a40: '禁止状態を受け入れます',
    a41: '不法通行警報',
    a42: '設備異常警報',
    a43: '入方向ドア開',
    a44: '出方向ドア開',
    a45: '入方面通行禁止',
    a46: '出先通行禁止',
    a47: 'コンティニュアス設定',
    a48: '火災信号出力',
    a49: 'カードng信号出力',
    a50: 'ターゲットを指定します',
    a51: 'ドア時刻表',
    a52: 'カード認証スケジュール',
    a53: '機械警備時間割',
    a54: 'モデル1',
    a55: 'モデル2',
    a56: 'モデル3',
    a57: 'モデル4',
    a58: 'モデル5',
    a59: '初期値を「カードモード」に変更する場合は新たに設定してください',
    a60: 'カードモード',
    a61: 'フリーモード',
    a62: '反復パターン',
    a63: 'カード・アウト・フリーモード',
    a64: 'フリー/バックカードモードに入る',
    a65: '機器詳細情報',
    a66: '時刻表',
    a67: 'カレンダー',
    a68: 'テンキータイムアウト時間(秒)',
    a69: 'テンキー端末の有無',
    a70: '警戒温度下限値(℃)',
    a71: '警戒温度上限(℃)',
    a72: '温度異常を警戒してドアを開けるかどうか',
    a73: ' no ',
    a74: 'はい',
    a75: '認証モード',
    a76: '稼働状態',
    a77: '不正カード',
    a78: 'スケジュール回復',
    a79: '周期自動復元',
    a80: '有効',
    a81: '無効',
    a82: '周期回復時間1',
    a83: '周期回復時間2',
    a84: '周期回復時間3',
    a85: '周期回復時間4',
    a86: '周期回復時間5',
    a87: 'デバイスip ',
    a89: 'デバイスサブネットマスク',
    a90: 'デバイス・ゲートウェイ',
    a91: 'オペレーション',
    a92: '関連機器ネットワーク情報',
    a93: '操作成功、ドアボタンは5秒後にクローズ状態に戻ります',
    a94: '開扉操作失敗',
    a95: '当日の時刻表',
    a96: 'その日',
    a97: '翌日の時間割',
    a98: '翌日',
    a99: 'スケジュール手動復旧',
    a100: '設定',
    a101: '動作状態/障害・アラーム',
    a102: 'とき',
    a103: 'ふん'
  },

  //デバイスモジュール——操作\制御レコード
  operaterecords: {
    a1: '操作・制御記録',
    a2: 'デバイス名',
    a3: '初期日時選択',
    a4: '終了日時選択',
    a5: 'クエリ',
    a6: '記録リスト',
    a7: '日時を選択してください',
    a8: 'アラーム確認',
    a9: '手動リカバリ',
    a10: '対処',
    a11: '順位回復',
    a12: 'アラート出力',
    a13: '注釈を追加します',
    a14: '選択操作',
    a15: '内容を入力してください......',
    a16: '条データ',
    b1: '発生日',
    b2: '要因',
    b3: 'サービス',
    b4: '状態',
    b5: 'デバイスグループ名',
    b6: '設備名',
    b7: '信号/端子名',
    b8: '注釈',
    b9: 'オペレーション',
  },

  //デバイスモジュール——データ出力
  dataoutput: {
    a1: '設備',
    a2: 'シグナル',
    a3: '初期日時選択',
    a4: '終了日時選択',
    a5: 'クエリ',
    a6: 'データプレビュー',
    a7: 'データダウンロード',
    a8: '信号で個別表示',
    a9: '機器ごとに表示を統合します',
    a10: 'ファイル作成',
    a11: 'ファイルリスト',
    a12: '生成された出力ファイルはこのリストに表示され、その後ダウンロードすることができます',
    a13: '生成された出力ファイルは3日間だけ保存され、3日後にファイルは削除されます;ダウンロードする必要があれば再エクスポートしてください',
    a14: '条データ',
    b1: '設備番号',
    b2: 'デバイス名',
    b3: '信号点id ',
    b4: '信号名',
    b5: '信号番号',
    b6: '計量値',
    b7: '計量単位',
    b8: '日付',
    b9: '時間',
  },

  //機器モジュール——機器連働制御
  controlllinkage: {
    a1: 'グループ名',
    a2: '番号:',
    a3: '入力点',
    a4: '出力ポイント',
    a5: '連動点',
    a6: '連動条件',
    a7: 'スケジュールを選択してください',
    a8: '設定',
    a9: '名称はすでに存在する',
    b1: '状態',
    b2: '警報',
    b3: '条データ',
    b4: '機器名',
    b5: '信号名',
    b6: '条件',
    b7: '表示(メンテナンス)',
    b8: '操作',
    b9: '有効',
    b10: '無効',
    b11: '制御',
    b12: 'コントロールしない',
    b13: '連動制御',
    b14: '出力デバイス動作',
    b15: '停電または復旧待ちの場合',
    b16: '出力遅延',
    b17: 'タイプ',
    b18: '開く',
    b19: '関',
    a10: 'ロード中...',
    a11: '機器連動制御',
    a12: '名称更新',
    a13: '閉じる',
    a14: '確定',
    a15: '名称：',
    a16: '名前を入力してください',
    a17: '名前を空白にすることはできません'
  },

  //装置モジュール——季節制御
  controllseason: {
    a1: 'グループ名',
    a2: '番号:',
    a3: 'アナログ設置装置',
    a4: '上部下限警報設置設備',
    a5: '動作モード設定装置',
    a6: 'スケジュールを選択してください',
    a7: '季節設定',
    a8: 'パラメータおよび実行モード設定',
    b1: "季節設定",
    b2: "夏(サマーモード)",
    b3: "月",
    b4: "日",
    b5: " winter(ウィンターモード)",
    b6: "その他季節(その他季節モード)",
    b7: "夏と冬以外",
    b8: "臨時変更(運行パターン)",
    b9: "今日からのセットアップ",
    b10: "確定",
    b11: "設定",
    b12: "一時変更なし",
    b13: "夏モード",
    b14: "冬モード",
    b15: "その他のモデル",
    c1: "ローディング中…",
    c2: "パラメータ、実行モード設定",
    c3: "アナログ値",
    c4: "アナログ機器の一覧",
    c5: "アナログ値設定",
    c6: "上限値",
    c7: "上下限警報リスト",
    c8: "上限線警報設置",
    c9: "実行モードの一覧",
    c10: "ランモード設定",
    c11: "設定",
    c12: "有効",
    c13: '「無効」',
    c14: "デバイス名",
    c15: "信号名",
    c16: "設定値",
    c17: "有効/無効",
    c18: "ミッドシーズンモード",
    c19: "タイプ",
    c20: "条データ",
    a9: 'ロード中...',
    a10:'季節切替制御',
    a11:'名称更新',
    a12:'閉じる',
    a13:'確定',
    a14:'名称：',
    a15:'名前を入力してください',
    a16:'名前を空白にすることはできません'
  },

  //機器モジュール——間欠運転制御
  controllIntermittent: {
    a1: 'グループ名',
    a2: '番号:',
    a3: '対象機器',
    a4: '制御条件',
    a5: 'スケジュールを選択してください',
    a6: '設定',
    a7: "デバイス名",
    a8: "信号名",
    a9: "タイプ",
    a10: "条データ",
    a11: '「間欠動作制御」',
    a12: "有効",
    a13: "無効",
    a14: "分",
    a15: "起動",
    a16: "停止",
    a17: "実行時間",
    a18: "時間を止める",
    a19: "計測点",
    a20: "季節パケット",
    a21: "夏の目標温度",
    a22: "冬の目標温度",
  },

  //デバイスモジュール——デバイスがスケジュール制御を行う
  lightRunTimetable: {
    a1: 'デバイス実行スケジュール',
    a2: 'スケジュール名:',
    a3: 'キャンセル',
    a4: '確認',
    a5: 'カードモード',
    a6: 'フリーモード',
    a7: "繰り返しパターン",
    a8: "エントリーカード/退出フリーモード",
    a9: "フリー/バックカードモードに入る",
    a10: "スケジュールを選んでください",
    a11: "スケジュール間コピー",
    a12: "スケジュールコピー",
    a13: "時刻表全表コピー",
    a14: "元のスケジュールを複製する",
    a15: "コピー元",
    a16: "コピーする",
    a17: '月曜日',
    a18: '火曜日',
    a19: '水曜日',
    a20: '木曜日',
    a21: '金曜日',
    a22: '土曜日',
    a23: '日曜日',
    a24: '休日',
    a25: 'テイル1',
    a26: 'テイル2',
    a27: 'テイル3',
    a28: 'テイル4',
    a29: '設備スケジュール',
    a30: '曜日が正しく指定されていませんので再指定してください',
    a31: 'スケジュールリストをメールで送る',
    a32: 'メール送信スケジュール名',
    a33: '新たに',
    a34: '削除',
    a35: '修正',
    a36: 'カレンダー',
    a37: 'カレンダーをセットしてください',
  },

  //デバイスモジュール——スケジュール制御ページ
  controllGroup: {
    a1: 'グループ名',
    a2: '番号:',
    a3: '登録機器',
    a4: 'スケジュールを選択してください',
    a5: '登録デバイス編集',
    a6: '時間割複製',
    a7: '元の時刻表を複製します',
    a8: 'スケジュールを選択していません',
    a9: 'コピーするスケジュールを選択してください',
  },

  //デバイスモジュール——デバイスを編集する
  terminaleditor: {
    a1: 'ローディング中....',
    a2: '登録デバイス編集',
    a3: '登録機器gr1 ',
    a4: 'クエリ',
    a5: 'すべての設備',
    a6: '追加',
    a7: '削除',
    a8: '登録済みデバイス',
    a9: '設定',
    a10: 'スケジュール・カレンダー選択',
    a11: '通行時刻表:',
    a12: 'カレンダー:',
    a13: '警戒・解錠切替操作設定:',
    a14: '有効',
    a15: '無効',
    a16: '条データ',
    a17: '装置名',
    a18: '信号名',
    a19: 'タイプ',
    a20: '入力点デバイス編集',
    a21: '出力ポイントデバイス編集',
    a22: '連動点装置編集',
    a23: '間欠ランポイント編集',
    a24: '計測点編集',
    a25: '無',
    a26: 'アナログ設置装置',
    a27: '上部下限警報設置設備',
    a28: '運行モード設備を設置',
    a29: '温度测量点',
    a30: 'エアコン監視点',
    a31: 'トレンドポイント編集',
    a32: '時刻設定トレンドポイント編集',
    a33: 'デバイスが選択されていません',
    a34: '変更するデバイスを選択してください',
    a35: 'スケジュールやカレンダーを設定していない端末があります',
    a36: 'スケジュールやカレンダーを設定していない端末に、スケジュールやカレンダーを設定してください',
    a37: '1つしか選べない',
    a38:'サイト',
    a39:'連働制御入力点',
    a40:'連働制御出力点',
    a41:'計量点',
    a42:'演算点',
    a43:'仮想点',
  },



  //装置モジュール——連働制御グループ設定ページ
  controllGroupLinkage: {
    a1: 'グループ名',
    a2: '番号:',
    a3: '入力点',
    a4: '出力ポイント',
    a5: '連動点',
    a6: 'グループを選択してください',
    a7: '入力ポイント編集',
    a8: '出力ポイント編集',
    a9: '連動点編集',
  },

  //機器モジュール——季節切替制御グループ設定ページ
  seasonGroup: {
    a1: 'グループ名',
    a2: '番号:',
    a3: 'アナログ設置装置',
    a4: '上部下限警報設置設備',
    a5: '動作モード設定装置',
    a6: 'グループを選択してください',
    a7: 'アナログ設定装置編集',
    a8: '上部下限警報設定装置編集',
    a9: 'ランモード設定デバイス編集',
  },

  //デバイスモジュール——間欠実行制御グループページ
  controllGroupIntermittent: {
    a1: 'グループ名',
    a2: '番号:',
    a3: '間欠運行点',
    a4: '計測点',
    a5: 'グループを選択してください',
    a6: '計測点編集',
    a7: '間欠ランポイント編集',
    a8: 'ランモード設定デバイス編集',
  },

  //デバイスモジュール——コンフォートコントロールグループページ
  controllGroupComfort: {
    a1: 'グループ名',
    a2: '番号:',
    a3: 'エアコン監視点',
    a4: '温度測定点',
    a5: 'グループを選択してください',
    a6: '計測点編集',
    a7: '温度測定点編集',
    a8: 'エアコン監視点編集',
    a9: '対象機器',
    a10: '制御条件',
    a11: '設定',
  },
  //タイムトレンドグループ
  timeTrend: {
    a1: 'グループ名',
    a2: '番号:',
    a3: 'トレンドポイント',
    a4: 'グループを選択してください',
    a5: 'トレンドポイント編集',
    a6: '条データ',
    a7: 'デバイス名',
    a8: '信号名',
    a9: 'タイプ',
    a10: '収集周期',
    a11: '追加',
    a12: '修正',
    a13: '削除',
    a14: '削除確定?',
    a15: '確かに',
    a16: 'キャンセル',
    a17: '成功',
    a18: '失敗',
    a19: '名前は既に存在します',
  },

  //時間にトレンドグループを設定する
  timeSetupTrend: {
    a1: '時刻設定トレンド',
    a2: 'トレンドグループ:',
    a3: '番号:',
    a4: '時間軸ビュー',
    a5: '時間を選択してください',
    a6: '1日',
    a7: '1時間',
    a8: '日付選択',
    a9: '時間選択',
    a10: '軸形式',
    a11: '状態',
    a12: '多状態',
    a13: 'グループを選択してください',
    a14: 'リスト表示',
    a15: 'グラフ表示',
    a16: '左軸',
    a17: '右軸',
    a18: '単位',
    a19: '最大値',
    a20: '最小値',
    a21: '軸分割数',
    a22: 'デバイス名',
    a23: '信号名',
    a24: 'データタイプ',
    a25: 'グラフタイプ',
    a26: '無机器信号点,請先到時間設定傾向群設定ページ進行設定',
  },

  //データ比較トレンドグループ
  dataCompareTrend: {
    a1: 'データの比較傾向',
    a2: 'トレンドグループ:',
    a3: '番号:',
    a4: '追加',
    a5: '削除確定?',
    a6: '削除',
    a7: '状態',
    a8: '多状態',
    a9: 'グループを選択してください',
    a10: 'リスト表示',
    a11: 'グラフ表示',
    a12: '左軸',
    a13: '右軸',
    a14: ',単位',
    a15: '最大',
    a16: '最小値',
    a17: '軸分割数',
    a18: '日付',
    a19: '曜日',
    a20: 'グラフタイプ',
    a21: '日付操作',
    a22: '信号点',
    a23: '設備名',
    a24: '信号名',
    a25: 'タイプ',
    a26: '日付を添付してください',
    a27: '折れ線グラフ',
    a28: 'マルチ状態図',
  },

  //照明詳細情報
  lightDetails: {
    a1: 'スイッチ照度',
    a2: '環境照度',
    a3: 'スイッチ設定',
    a4: 'スイッチ状態',
    a5: 'トラブル',
    a6: '調光設定',
    a7: '調光状態',
    a8: '操作/状態/障害。アラート',
    a9: 'コメント登録欄',
    a10: 'メンテナンス監視',
    a11: '正常',
    a12: 'コメント5件まで',
    a13: 'スケジュール制御',
    a14: '上限値',
    a15: '上下値監視',
    b1: '累積走行時間が監視点走行時間を超えます',
    b2: '連続実行時間が監視連続時間を超えます',
    b3: '状態変化回数が監視状態変化回数を超える',
    b4: '監視障害アラーム時間超過',
    b5: '障害通報回数が監視障害回数を超えます',
    b6: '時間',
    b7: '次',
    c1: ' rs名',
    c2: 'オブジェクトid ',
    c3: 'トレンド',
    c4: 'ユニットカテゴリ',
    c5: 'モジュール',
    c6: '拡張',
    c7: '端子no',
    d1: '変更時間:',
    d2: 'ファクター:',
    d3: 'メンテナンス登録',
    d4: '重要機器登録',
    d5: 'サウンドカテゴリ:',
    d6: 'オン',
    d7: '閉鎖',
    d8: '操作',
    d9: 'ステータス',
    d10: '故障警報',
    d11: '設定値',
    d12: '測定値',
    d13: '設定',
    d14: 'セット成功',
    d15: '設定に失敗する',
  },

  //エアコン詳細情報
  airConditionDetails: {
    a1: 'リモコンモード',
    a2: '冷たい風',
    a3: '熱風',
    a4: '省エネ',
    a5: '風速',
    a6: 'スイッチ設定',
    a7: 'スイッチ状態',
    a8: 'トラブル',
    a9: '温度設定',
    a10: '吸入温度',
    a11: '運転モード設定',
    a12: '風量設定',
    a13: 'リモコン温度スイッチ禁止状態',
    a14: '操作/状態/障害。アラート',
    a15: 'コメント登録欄',
    a16: 'メンテナンス監視',
    a17: '装置名',
    a18: '運行',
    a19: '冷却',
    a20: '熱熱',
    a21: '正常',
    a22: '弱い風',
    a23: '中風',
    a24: '強風',
    a25: 'ない',
    a26: '停止',
    a27: "室温",
    a28: '火災信号',
    a29: '送风',
    a30: '吸って濡れ',
    a31: 'メンテナンス',
    a32: '保守警報／上下限警報・発停異常',
  },

  //環境詳細情報
  EnvironmentDetails: {
    a1: '環境目盛り',
    a2: 'トラブル',
    a3: 'コメント登録欄',
    a4: 'メンテナンス監視',
    a5: '楽器名',
    a6: '測定値',
    a7: '上限値監視',
    a8: '設定',
    a9: '上限値が下限値より小さい',
    a10: '設定値は上限と下限の範囲内にない',
    a11: '運行',
    a12: '停止',
  },



  //アカウント設定ウィンドウ
  accountModal: {
    a1: 'アカウントパケット',
    a2: '所属',
    a3: 'アカウントid ',
    a4: 'アカウント',
    a5: ' captcha ',
    a6: '有効',
    a7: '無効',
    a8: 'アカウント権限',
    a9: 'テストメールを送信します',
    a10: 'パスワード',
    a11: 'パスワードを変更します',
    a12: '最終修正日',
    a13: 'パスワードの有効期限',
    a14: 'メール送信',
    a15: 'ダウンロード成功メールを記録しません',
    a16: 'ダウンロード失敗',
    a17: '次送信メール',
    a18: 'パスワードを変更します',
    a19: 'メール送信エラー',
    a20: '新旧パスワードは同じです',
    a21: '古いパスワード入力エラー',
    a22: '不法文字が存在します',
    a23: 'パスワードが長すぎる',
    a24: 'その他のエラー',
    a25: 'パスワード検証エラー',
  },

  //共通案内
  commoninfoSetting: {
    a1: 'マニュアル',
    a2: '最終更新人員',
    a3: 'クエリ',
    a4: 'オプションを削除します',
    a5: '確定',
    a6: 'キャンセル',
    a7: '削除',
    a8: 'マニュアルタイプ',
    a9: '表示期間',
    a10: '重要情報',
    a11: '追加',
    a12: '変更',
    a13: '通知設定',
    a14: '条データ',
    a15: '公共',
    a16: '設備',
    a17: 'エネルギー',
    a18: 'ドアゲート',
    a19: '動画配信',
    a20: '簡単省エネ',
    a21: 'エアコンの省エネ',
    a22: 'デマンドレスポンス',
    a23: 'アクセスデータ出力',
    a24: '変更成功',
    a25: '変更失敗:',
    a26: '成功を追加します',
    a27: '追加失敗:',
    a28: '削除成功',
    a29: '削除失敗:',
    a30: '新規/修正',
    a31: '最終更新日時',
    a32: '最終更新人員',
    a33: '表示対象',
    a34: '表示開始時刻',
    a35: '表示終了時刻',
  },



  //ダウンロード
  downloadSetting: {
    a1: 'ダウンロード名',
    a2: 'ファイル名',
    a3: '最終更新人員',
    a4: 'クエリ',
    a5: '削除対象を確定します',
    a6: '確定',
    a7: 'キャンセル',
    a8: '削除',
    a9: '追加',
    a10: '変更',
    a11: '通知設定',
    a12: '条データ',
    a13: '新規/修正',
    a14: '最終更新日時',
    a15: '最終更新人員',
    a16: '表示対象',
    a17: '変更成功',
    a18: '変更失敗:',
    a19: '成功を追加します',
    a20: '追加失敗:',
    a21: '削除成功',
    a22: '削除失敗:',
    a23: '掲載',
    a24: '修正は1つだけ選択できる',
  },



  //詳細表示ウィンドウ
  editModal: {
    a1: '表示期間',
    a2: '重要情報',
  },



  //管理ホームページの設定を通知します
  equipment: {
    a1: '共通案内',
    a2: '相談所',
    a3: '相続事項',
    a4: 'ダウンロード',
    a5: '通知設定',
  },



  //相談所
  inquireSetting: {
    a1: 'コンサルティング',
    a2: '最終更新人員',
    a3: 'クエリ',
    a4: 'オプションを削除します',
    a5: '確定',
    a6: 'キャンセル',
    a7: '削除',
    a8: '追加',
    a9: '変更',
    a10: '通知設定',
    a11: '条データ',
    a12: '新規/修正',
    a13: '最終更新日時',
    a14: '最終更新人員',
    a15: '表示対象',
    a16: '表示開始時刻',
    a17: '表示終了時刻',
    a18: '成功に変更します',
    a19: '変更失敗:',
    a20: '成功を追加します',
    a21: '追加失敗:',
    a22: '削除成功',
    a23: '削除失敗:',
    a24: '引き継ぎ事項',
  },

  //アカウント設定パスワード変更ウィンドウ
  passwordModal: {
    a1: '現在のパスワード1',
    a2: '新しいパスワード1',
    a3: 'パスワード1を確認します',
    a4: 'カレントパスワード2',
    a5: '新暗号2',
    a6: 'パスワード確認2',
  },



  //ホームページをお知らせします
  infoHome: {
    a1: '通知',
    a2: '最終更新日:',
    a3: '公共案内',
    a4: 'ショートカット',
    a5: '検索先',
    a6: 'ダウンロード',
    a7: '登録・設定',
    a8: '引き継ぎ事項',
    a9: 'ポップアップ名',
    a10: 'アカウント設定',
    a11: '成功を追加します',
    a12: '失敗を追加する:',
    a13: 'ユーザー情報を更新します',
    a14: '更新成功',
    a15: '更新失敗',
  },

  //管理ホームページを設置する
  setHome: {
    a1: '顧客センター',
    a2: 'ユーザー設定',
    a3: '通知設定',
    a4: 'カード番号管理',
    a5: 'コントローラ・マネジメント',
    a6: 'アラーム管理',
    a7: '監視センター',
    a8: '顧客、建物群、建物サイトデータ:',
    a9: 'menuエラー',
  },



  //アラーム管理ページ
  notificationEquipment: {
    a1: 'アラーム設定',
    a2: 'アラーム記録情報',
    a3: '通知設定',
    a4: 'アラーム管理',
    a5: 'メール送信スケジュール',
  },



  //アラーム通知設定
  notification: {
    a1: 'サイト情報を選択します',
    a2: 'カメラ',
    a3: 'ビデオ',
    a4: 'クエリ',
    a5: '抑制',
    a6: '抑制しない',
    a7: '設定を確定します',
    a8: '確定',
    a9: 'キャンセル',
    a10: '設定',
    a11: '設定をキャンセルします',
    a12: '通知設定',
    a13: '条データ',
    a14: 'アラートid ',
    a15: '警報情報',
    a16: 'アラーム受信',
    a17: '管制通知',
    a18: '設定失敗、設定値なし',
    a19: '設定成功',
    a20: '設定失敗:',
  },



  //アラーム記録

  warningRecord: {
    a1: 'ローディング中....',
    a2: '建築コード',
    a3: '初期日時選択',
    a4: '終了日時選択',
    a5: 'オプション',
    a6: '値',
    a7: '企業コード',
    a8: '送信データ種別',
    a9: '管制種別',
    a10: 'クエリ',
    a11: 'アラーム記録リスト',
    a12: '強制回復',
    a13: '種別変更',
    a14: '現在の発信タイプは:',
    a15: '変更後:',
    a16: '設定',
    a17: 'キャンセル',
    a18: 'すべて',
    a19: '顧客番号',
    a20: '建物名',
    a21: '管理コード',
    a22: '監視センター名',
    a23: '送信データを復元しません',
    a24: '試験送信データ',
    a25: '警報',
    a26: 'ジョブ送信データ',
    a27: 'データを集中送信',
    a28: '通信異常',
    a29: '日立ビル',
    a30: '正式警報',
    a31: '試験警報',
    a32: '作業警報',
    a33: 'バッチ警報',
    a34: '発信タイプ変更',
    a35: '条データ',
    a36: '企業名',
    a37: '顧客名',
    a38: '顧客管理番号',
    a39: '建物名',
    a40: '監視センターの名称',
    a41: '設備名称',
    a42: '信号/端末名',
    a43: ' 打とうタイプ',
    a44: '警報タイプ',
    a45: '警報内容',
    a46: '発生日',
    a47: '回復日',
    a48: 'メール処理',
    a49: '注釈',
    a50: 'カテゴリを変更する項目を選択してください',
    a51: '復元する項目を選択してください',
  },

  //連絡先選択ページ
  deviceContact: {
    a1: 'ローディング中....',
    a2: '顧客名',
    a3: '建物名',
    a4: '連絡先名',
    a5: '連絡先1~3',
    a6: 'クエリ',
    a7: '連絡先リスト',
    a8: '連絡先1',
    a9: '連絡先2',
    a10: '連絡先3',
    a11: '追加',
    a12: '更新',
    a13: '削除',
    a14: '確定',
    a15: '入力ポイント機器編集',
    a16: '条データ',
    a17: '認証端末が選択されません',
    a18: '変更が必要な認証端末を選択してください',
    a19: '連絡先が選択されていません',
    a20: '削除する連絡先を選択してください',
    a21: '削除成功',
    a22: '更新する連絡先を選択してください',
    a23: '更新に成功します',
  },

  //アラート設定編集ページ——編集デバイス
  deviceEditor: {
    a1: 'ローディング中....',
    a2: '設備',
    a3: '名前',
    a4: '登録デバイスgr1 ',
    a5: '登録デバイスgr2 ',
    a6: '登録機器gr3 ',
    a7: 'クエリ',
    a8: 'すべての設備',
    a9: '追加',
    a10: '削除',
    a11: '登録済みデバイス',
    a12: '設定',
    a13: '入力ポイント機器編集',
    a14: '条データ',
    a15: 'ドアゲート',
    a16: 'すべて',
    a17: '電気ロック',
    a18: '自動ドア',
    a19: 'ブレーキ',
    a20: '警備',
    a21: '接点',
    a22: 'ビデオ',
    a23: 'カメラ',
    a24: 'ビデオ',
    a25: 'デバイス名',
    a26: '信号名',
    a27: 'タイプ',
    a28: '認証端末が選択されていません',
    a29: '変更が必要な認証端末を選択してください',
    a30: 'デバイスが選択されていません',
    a31: '変更する機器を選択してください',
  },

  //アラーム管理ページ——アラーム設定
  warningSetting: {
    a1: 'サイト情報を選択します',
    a2: '警報タイプ',
    a3: '担当者',
    a4: 'クエリ',
    a5: '削除',
    a6: '編集',
    a7: '新規追加',
    a8: '企業コード',
    a9: '顧客番号',
    a10: 'クライアント名',
    a11: '建物名',
    a12: '警報対象',
    a13: '設備',
    a14: 'システム',
    a15: '設備名',
    a16: 'タイプ',
    a17: 'アラーム設定',
    a18: '説明',
    a19: '処理方法',
    a20: '設備連絡先1',
    a21: '設備連絡先2',
    a22: '設備連絡先3',
    a23: '設備連絡先4',
    a24: '設備連絡先5',
    a25: '設備連絡先6',
    a26: '警報処理マニュアル',
    a27: '対応区分',
    a28: '出動するかどうか',
    a29: '電話対応',
    a30: ' hbs出動',
    a31: 'アラーム表示',
    a32: '表示',
    a33: '発信',
    a34: 'はい',
    a35: 'いいえ',
    a36: 'メール送信',
    a37: '起動時送信',
    a38: '復旧時送信',
    a39: 'サウンド設定',
    a40: 'アラームなし',
    a41: '火事が発生します',
    a42: '停電発生',
    a43: '大トラブルが発生します',
    a44: '小さなトラブルが発生します',
    a45: '更新時間',
    a46: '更新人員',
    a47: '送信設定',
    a48: 'ユーザーセレクト',
    a49: 'メール送信アドレス',
    a50: '送信スケジュール',
    a51: 'カレンダーを送ります',
    a52: '設定',
    a53: 'キャンセル',
    a54: '機器選択',
    a55: '連絡先',
    a56: '条データ',
    a57: '設備連絡1名',
    a58: '設備連絡1方式1',
    a59: '設備連絡1方式2',
    a60: '設備連絡1方式3',
    a61: 'スタッフ作成',
    a62: '監視スケジュール',
    a63: 'ユーザーサイト情報を追加してください',
  },

  //能源模块
  energy: {
    ok: '設定',
    cancel: '取消',
    add: '追加',
    remove: '削除',
    search: '検索',
    showTotal: '${total}件',
    placeSelect: '選択してください',
    setting: {
      reportGr: {
        inputLabel: '${label}を入力してください',
        spLabel: '${label}に制限文字が入力されています。',
        lenLabel: '${label}の入力桁数がオーバーしました。入力桁数さは${min}から${max}の間でなければなりません。',
        deleLabel: '${label}【${name}】を削除します。よろしいですか？',
        saveLabel: '${label}を追加します。よろしいですか？',
        saveSuce: 'データ登録に成功しました。',
        saveFail: 'データ登録に失败しました。',
        loadFail: 'データ検索に失败しました。',
        daily: { name: '日報グループ', nameLabel: '日報グループ名', edit: '日報ポイント編集' },
        monthly: { name: '月報グループ', nameLabel: '月報グループ名', edit: '月報ポイント編集' },
        yearly: { name: '年報グループ', nameLabel: '年報グループ名', edit: '年報ポイント編集' },
        trendGr: { name: 'トレンドグループ', nameLabel: 'トレンドグループ名', edit: 'トレンドポイント編集',chartName:'トレンドグラフ' },
        timeTrendGr: { name: '時刻設定トレンドグループ', nameLabel: '時刻設定トレンドグループ名', edit: 'トレンドポイント編集',chartName:'時刻設定トレンドグラフ' },
        empty: 'グループを選択してください。',
        stop: '以下の特殊文字の入力を禁止します',
        grlabel: 'グループ名称',
        kikiName: '設備名称',
        pointName: '信号名称',
        dataKind: 'データ種別',
        numberTips: '日報/月報/年報で設定できるポイントの最大数は32個です。',
        trendTips:'トレンドグループ/時刻設定トレンドグループで設定できる計量ポイントの最大数は12です。',
        saveMP: '設定内容を登録します。よろしいですか？',
        addMp: '追加する計量点を選択してください。',
        removeMp: '削除する計量ポイントを選択してください。',
        limitMp: '登録可能件数32を超える為登録できません。',
        groupingLabel:"非同じレベルではない計量パラメータが同じパケットに分けられ、継続するかどうか？",
      },
    },
    enedata001: "統計データ",
    enedata002: "種別",
    enedata003: "計量対象",
    enedata004: "グラフ種別",
    enedata005: "対象年度",
    enedata006: "クエリー",
    enedata007: "積算使用量",
    enedata008: "積算",
    enedata009: "使用量",
    enedata010: "予測データ",
    enedata011: "実際データ",
    enedata012: "表示対象",
    enedata013: "折れ線グラフ",
    enedata014: "棒グラフ",
    enedata015: "円グラフ",
    enedata016: "時間",
    enedata017: "期間",
    enedata018: "積層グラフ",
    enedata019: "使用量実績",
    enedata020: "原単位指標",
    enedata021: "CO2換算係数",
    enedata022: "※上段は「使用量」、下段は「熱量」を表示しています。(水のみ使用量表示)",
    enedata023: "設定",
    enedata024: "ポイント",
    enedata025: "全部選択",
    enedata026: "全部選択しない",
    enedata027: "印刷プレビュー",
    enedata028: "登録機器変更",
    enedata029: "料金用CSV出力",
    enedata030: "実行",
    enedata031: "グループを選択してください。",
    enedata032: "用途",
    enedata033: "建物を選択してください。",
    enedata034: "日報",
    enedata035: "月報",
    enedata036: "年報",
    enedata037: "期間",
    enedata038: "数字を入力してください。",
    enedata039: "日次データの日付期限は一日を超えてはいけません。",
    enedata040: "月次データの日付は一ヶ月を超えてはいけません。",
    enedata041: "年次データ期日は一年を超えてはいけません。",
    enedata042: "レポートのエクスポート",
    enedata043: "総合報告書",
    enedata044: "データ出力",
    enedata045: "ポイント種類",
    enedata046: "年度",
    enedata047: "出力",
    enedata048: "取り出し期間",
    enedata049: "取り出したい期間を設定し、出力ボタンを押してください",
    enedata050: "1年以内",
    enedata051: "6ヶ月以内",
    enedata052: "3ヶ月以内",
    enedata053: "1年以内",
    enedata054: "13ヶ月以内",
    enedata055: "月ごとデータ期間必要です1年以内",
    enedata056: "日ごとデータ期間必要です6ヶ月以内",
    enedata057: "時間ごとデータ期間必要です3ヶ月以内",
    enedata058: "換算係数データ期間必要です1年以内",
    enedata059: "電気需要平準化データ期間必要です13ヶ月以内",
    enedata060: "エネルギー種別",
    enedata061: "平準化",
    enedata062: "省エネ管理",
    enedata063: "統計年度比較",
    enedata064: "建物比較",
    enedata065: "データがありません。",
    enedata066: "ポイントがありません。",
    enedata067: "目標（平準化評価後）",
    enedata068: "実績（平準化評価後）",
    enedata069: "単位(月)",
    enedata070: "単位(年度)",
    enedata071: "平準化評価前",
    enedata072: "平準化評価分",
    enedata073: "平準化評価後",
    enedata074: "年度合計",
    enedata075: "階層を表示",
    enedata076: "顧客",
    enedata077: "建物",
    enedata078: "サイト",
    enedata079: "テナント",
    enedata080: "目標年度は可視エネルギーがない",
    enedata081: "設定成功",
    enedata082: "設定に失敗しました",
    enedata083: "テナント入力",
    enedata084: "費用計算",
    enedata085: "実績値入力",
    enedata086: "未入力確認",
    enedata087: "エネルギー",
    enedata088: "オプション",
    enedata089: "テナントも含む",
    enedata090: "確定対象",
    enedata091: "未入力のみ表示",
    enedata092: "お客様を選択し、確定対象ボタンを押して、確定対象を登録して下さい。",
    enedata093: "未入力",
    enedata094: "入力済",
    enedata095: "未入力があります。全ての項目を入力後、再度実行してください。",
    enedata096: "単価",
    enedata097: "分",
    enedata098: "角",
    enedata099: "元",
    enedata100: "電気カテゴリー",
    enedata101: "ピーク谷期間",
    enedata102: "ピーク",
    enedata103: "フラット",
    enedata104: "谷",
    enedata105: "計測値",
    enedata106: "エンドレスカウンタ",
    enedata107: "エンドレスデータ",
    enedata108: "時データ",
    enedata109: "日データ",
    enedata110: "月データ",
    enedata111: "メンテナンス登録",
    enedata112: "重み",
    enedata113: "単位",
    enedata114: "音声種別",
    enedata115: "上下限監視",
    enedata116: "上限異常監視",
    enedata117: "下限異常監視",
    enedata118: "パルスレート上限値",
    enedata119: "パルスレート下限値",
    enedata120: "レート時間間隔",
    enedata121: "秒",
    enedata122: "パルスレート（パルスカウント/レート時間間隔）",
    enedata123: "現在値",
    enedata124: "コメント登録欄",
    enedata125: "最大5件まで登録可",
    enedata126: "コメントを書く",
    enedata127: "全てクリア",
    enedata128: "再表示",
    enedata129: "確認",
    enedata130: "関連信号点",
    enedata131: "取得範囲",
    enedata132: "上限値",
    enedata133: "下限値",
    enedata134: "不感帯は",
    enedata135: "0～（上限値-下限値）の範囲で設定します。",
    enedata136: "編集名をクリックします",
    enedata137: "パワー要因",
    enedata138: "データのロードに失敗しました",
    enedata139: "トレンドグラフ",
    enedata140: "本当にすべてのコメントをクリアしますか？",
    enedata141: "状態",
    enedata142: "ON名称",
    enedata143: "OFF名称",
    enedata144: "保守監視",
    enedata145: "スケジュール制御",
    enedata146: "制御状態",
    enedata147: "上記コメントは、本ポイントが属する機器に対して共通です。",
    enedata148: "COS監視",
    enedata149: "映像",
    enedata151: "操作",
    enedata152: "状態名称",
    enedata153: "条件不一致監視",
    enedata154: "アンサー監視時間",
    enedata155: "設定値",
    enedata156: "故障・警報時間超過監視故障・警報時間",
    enedata157: "時間",
    enedata158: "故障・警報回数超過監視故障・警報回数",
    enedata159: "回",
    enedata160: "積算運転時間超過監視積算運転時間",
    enedata161: "連続運転時間超過監視連続運転時間",
    enedata162: "状態変化回数超過監視状態変化回数",
    enedata163: "原単位指標",
    enedata164: "名称",
    enedata165: "単位",
    enedata166: "入力方法",
    enedata167: "計算方法",
    enedata168: "通年値",
    enedata169: "削除",
    enedata170: "手入力",
    enedata171: "計算",
    enedata172: "平均",
    enedata173: "登録",
    enedata174: "名称が入力されていません。",
    enedata175: "単位が入力されていません。",
    enedata176: "計算方法が選択されていません。計算方法を選択してください。",
    enedata177: "計算方法が循環しているので登録できません。",
    enedata178: "計算方法に同一の項目を選択することは出来ません。",
    enedata179: "計算方法に未登録の項目を選択することは出来ません。",
    enedata180: "データ登録に成功しました。",
    enedata181: "データ登録に失敗しました。",
    enedata182: "データ削除成功!",
    enedata183: "データ削除に失敗!",
    enedata184: "設定内容を登録します。よろしいですか？",
    enedata185: "削除します。よろしいですか？",
    enedata186: "入力データを破棄します。よろしいですか？",
    enedata187: "演算ポイントを削除します。よろしいですか？",
    enedata188: "数式",
    enedata189: "編集",
    enedata190: "ポイントGr設定",
    enedata191: "新規追加",
    enedata192: "登録名称",
    enedata193: "演算ポイント種別",
    enedata194: "演算式タイプ",
    enedata195: "合計${total}件のデータ",
    enedata196: "演算ポイント",
    enedata197: "演算ポイントが選択されていません。再度選択してから実行してください。",
    enedata198: "演算式",
    enedata199: "所属",
    enedata200: "トップページのデータ展示",
    enedata201: "はい、",
    enedata202: "いいえ、",
    enedata203: "単位名称",
    enedata204: "（自由単位）選択時に自由単位の入力が可能になります。また、単位を変更しても、演算は変更されません。",
    enedata205: "単位名称を選択してください。",
    enedata206: "自由単位",
    enedata207: "小数点以下桁数",
    enedata208: "信号種別",
    enedata209: "機器一覧",
    enedata210: "加減演算設定",
    enedata211: "選択機器一覧（＋）",
    enedata212: "選択",
    enedata213: "解除",
    enedata214: "選択機器一覧（－）",
    enedata215: "一般式演算設定",
    enedata216: "演算式選択",
    enedata217: "機器を選択してください。",
    enedata218: "制限文字が入力されています。",
    enedata219: "演算ポイント名称は1～30の範囲で設定してください。",
    enedata220: "自由単位は0～30の範囲で設定してください。",
    enedata221: "数式は0～30の範囲で設定してください。",
    enedata222: "演算ポイント名称で入力してください。",
    enedata223: "演算式で入力してください。",
    enedata224: "演算ポイント種別を選択してください。",
    enedata225: "演算式を選択してください。",
    enedata227: "小数点以下桁数を選択してください。",
    enedata228: "所属を選択してください。",
    enedata229: "演算ポイントを保存します。よろしいですか？",
    enedata230: "機器が選択されていません。再度選択してから実行してください。",
    enedata231: "電気需要平準化報告初年度設定",
    enedata232: "計量種別",
    enedata233: "7月検針日",
    enedata234: "12月検針日",
    enedata235: "機器名称",
    enedata236: "機器名称",
    enedata237: "エネルギー種別を選択してください。",
    enedata238: "エネルギー種別計量対象編集中のデータが破棄されます。よろしいですか？",
    enedata239: "エネルギー種別計量対象を保存します。よろしいですか？",
    enedata240: "削減目標",
    enedata241: "建物／テナント",
    enedata242: "建物グループも含む",
    enedata243: "建物も含む",
    enedata244: "※お客様または建物グループ選択時、その階層以下の全ての削減目標（テナント以外）が上書きされます。尚、【テナントも含む】にチェックを入れると、その階層以下の削減目標がテナントを含めて全て上書きされますのでご注意ください。",
    enedata245: "※削減目標は、前年度のデータを自動で引き継ぎます。よって、変更が必要な場合は、毎年度再設定して下さい。",
    enedata246: "エネルギー使用量",
    enedata247: "前年度比",
    enedata248: "目標値",
    enedata249: "最終更新日",
    enedata250: "削減目標を保存します。よろしいですか？",
    enedata251: "削減目標値を空白に変更して登録すると、設定済みの削減目標値と最終更新日が削除されます。よろしいですか？",
    enedata252: "原単位指標の入力",
    enedata253: "原単位指標の入力を保存します。よろしいですか？",
    enedata254: "建物名称",
    enedata255: "通年共通",
    enedata256: "仮想ポイント",
    enedata257: "仮想ポイントを削除します。よろしいですか？",
    enedata258: "仮想ポイントが選択されていません。再度選択してから実行してください。",
    enedata259: "エネルギー/オプション",
    enedata260: "名称に制限文字が入力されています。",
    enedata261: "仮想ポイント名称で入力してください。",
    enedata262: "仮想ポイント名称は1～30の範囲で設定してください。",
    enedata263: "エネルギー/オプションを選択してください。",
    enedata264: "仮想ポイントを保存します。よろしいですか？",
    enedata265: "エネルギー使用種別設定",
    enedata266: "現在登録されているエネルギー",
    enedata267: "ガス種類",
    enedata268: "供給会社",
    enedata269: "権原分",
    enedata270: "料金",
    enedata271: "エネルギー換算",
    enedata272: "昼間",
    enedata273: "夜間",
    enedata274: "産気率",
    enedata275: "値",
    enedata276: "電気需要平準化報告初年度",
    enedata278: "エネルギー使用種別",
    enedata279: "エネルギー使用種別を保存します。よろしいですか？",
    enedata280: "料金を持つポイントがあります。自社テナント区分設定画面で設定を確認して下さい。",
    enedata281: "選択されたエネルギー使用種別のテナント権原分を持つポイントがあります。自社テナント区分設定画面で設定を確認して下さい。",
    enedata282: "選択されたエネルギー使用種別を使用しているポイントがあります。エネルギー種別設定画面で設��を確認して下さい。",
    enedata283: "ポイントGrを削除します。よろしいですか？",
    enedata284: "ポイントGrを保存します。よろしいですか？",
    enedata286: "全使用量",
    enedata287: "テナント使用量",
    enedata288: "選択した機器の計測ポイントは登録されています。",
    enedata289: "該当するエネルギー使用種別を持つポイントがありません。エネルギー種別設定画面でポイントを登録して下さい。",
    enedata290: "建物／テナントを選択してください。",
    enedata291: "対象年度を選択してください。",
    enedata292: "ポイント管理",
    enedata293: "設定内容",
    enedata294: "自社/テナント区分",
    enedata295: "計量対象区分",
    enedata296: "仮想ポイント",
    enedata297: "供給者",
    enedata298: "番号",
    enedata299: "計量対象グループを保存します。よろしいですか？",
    enedata300: "計量対象グループ名称を保存します。よろしいですか？",
    enedata301: "計量対象グループ【${text}】を削除します。よろしいですか？",
    enedata302: "計量対象グループ名称で入力してください。",
    enedata303: "計量対象グループ名称は1～30の範囲で設定してください。",
    enedata304: "計量対象グループ",
    enedata305: "計量対象グループ名称に制限文字が入力されています。",
    enedata306: "デバイス名の編集をクリック",
    enedata307: "{text}で入力してください。	",
    enedata308: "{text}は{star}～{end}の範囲で設定してください。	",
    enedata309: "{text}に制限文字が入力されています。	",
    enedata310: "4月",
    enedata311: "5月",
    enedata312: "6月",
    enedata313: "7月",
    enedata314: "8月",
    enedata315: "9月",
    enedata316: "10月",
    enedata317: "11月",
    enedata318: "12月",
    enedata319: "1月",
    enedata320: "2月",
    enedata321: "3月",
    enedata322: "{text}を選択してください。",
    enedata323: "数式で入力してください。",
    enedata324: "自由単位で入力してください。",
    enedata325: "ポイントGr追加	",
    enedata326: "ポイントGrを選択してください。	",
    enedata327: "ポイントGr	",
    enedata328: "ポイントGr名称で入力してください。	",
    enedata329: "ポイントGr名称に制限文字が入力されています。	",
    enedata330: "ポイントGr名称は1～30の範囲で設定してください。	",
    enedata331: "計量対象グループ追加	",
    enedata332: "計量対象を選択してください。	",
    enedata333: "リセット	",
    enedata334: "履歴",
    enedata335: "履歴一覧",
    enedata336: "履歴種別",
    enedata337: "ロギング記録",
    enedata338: "トレンド",
    enedata339: "開始期間",
    enedata340: "終了期間",
    enedata341: "設備名称",
    enedata342: "機器番号",
    enedata343: "機器名称",
    enedata344: "機器",
    enedata345: "設備",
    enedata346: "開始期間を選択してください。",
    enedata347: "終了期間を選択してください。",
    enedata348: "電気使用量",
    enedata349: "時間",
    enedata350: "期間",
    enedata351: "機器番号",
    enedata352: "計測値",
    enedata353: "単位",
    enedata354: "換算係数をコピーします。",
    enedata355: "対象年度に当年度が選択されています。換算係数をコピーして問題ないですか？",
    enedata356: "対象年度に当年度より過去の年度が選択されています。換算係数をコピーして問題ないですか？",
    enedata357: "換算係数をコピーして本当に問題ないですか？",
    enedata358: "換算係数の取得に失敗しました。",
    enedata359: "エネルギー種別グループの換算値単位が見つかりません。",
    enedata360: "登録に成功しました。",
    enedata361: "只今繋がりにくい状態になっており、ご迷惑をお掛けしています。しばらく経過しましたら再度実行してください。",
    enedata362: "換算値ののオーバーです。再度入力してください。",
    enedata363: "エネルギー換算値のオーバーです。再度入力してください。",
    enedata364: "調整前CO2換算値のオーバーです。再度入力してください。",
    enedata365: "調整後CO2換算値のオーバーです。再度入力してください。",
    enedata366: "LPG単位変換換算量のオーバーです。再度入力してください。",
    enedata367: "No.{num}の換算値を未入力登録する場合、確定チェックボックスは選択できません。",
    enedata368: "No.{num}の換算値は、全て未入力または、全て入力としてください。",
    enedata369: "前年度コピーを出来る範囲を超えているため{num}年は実行できません。",
    enedata370: "前年度コピー",
    enedata371: "{name}の入力が範囲外です。",
    enedata372: "{name}の入力が範囲外です。{min}～{max}の範囲で設定してください。",
    enedata373: "{name}の入力に誤りがあります。",
    enedata374: "上限値より下限値が大きな値になっています。",
    enedata375: "確認状態",
    enedata376: 'データがありません。ファイルを生成できませんでした。ダウンロードできませんでした。',
    enedata377: "水使用量",
    enedata378: "トレンド：設備は5分ごとに一回のデータを記録します。",
    enedata379: "ログ：設備は1時間ごとに一回のデータを記録する。",
    enedata380: "実績入力画面は、お客様側にて計量したデータを入力する画面です。この機能はお客様に実際のエネルギー使用量と実際の費用を入力して修正するために提供されます。",
    enedata381: "未入力確認画面は、お客様実績入力画面での入力状況を月毎に確認及びデータの確定を行う画面です。",
    enedata382: "省エネ管理画面は、エネルギー使用量や料金の月別推移をグラフで表示する画面です。",
    enedata383: "統計年度比較画面は、「当年度」「前年度」「前々年度」のエネルギー使用量実績を月毎、年度毎のグラフで比較する画面です。",
    enedata384: "建物比較画面は、全体又はグループ別のエネルギー使用量や料金の建物の比較を表示する画面です。",
    enedata385: "統計データ画面は、計測対象毎のエネルギー使用量を、年単位、月単位、日単位でグラフ表示する画面です。",
    enedata386: "積層グラフ画面は、計量対象グループ毎のエネルギー使用量を、月単位、日単位、時間単位でグラフ表示する画面です。",
    enedata387: "使用量実績画面は、電気、ガス、油、水、その他のエネルギーに対し、月別に原単位換算やＣＯ２排出量換算を行い一覧で表示する画面です。",
    enedata388: "削減目標は、指定年度のエネルギー使用量の削減目標を登録する画面です。",
    enedata389: "原単位指標の登録,原単位の算出の際に使用する分母を登録する画面です。",
    enedata390: "原単位指標の入力画面は、月単位毎の原単位指標の登録、確認をする画面です。",
    enedata391: "エネルギー使用種別設定画面は、建物のエネルギー供給会社や種別の登録を行う画面です。",
    enedata392: "ポイント管理画面は、各建物やテナントのエネルギー供給会社やポイントを登録、確認する画面です。",
    enedata393: "ポイント管理画面は、エネルギー種別設定画面、自社/テナント区分登録画面、計量対象区分登録画面、仮想ポイント登録画面の４つです。",
    enedata394: "エネルギー種別設定画面は、ポイントに対して供給会社やエネルギー種別を設定する画面です。",
    enedata395: "自社/テナント区分登録画面は、ポイントに対して区分(全使用量/テナント使用量/テナント権原分)を登録する画面です。",
    enedata396: "計量対象区分登録画面は、ポイントに対して計量対象区分(例：空調/照明)を登録する画面です。",
    enedata397: "仮想ポイント登録画面は、料金や自動計量以外のデータを手入力するためのポイントを登録する画面です。",
    enedata398: "カスタム日付",
    enedata399: "開始月",
    enedata400: "フロア・テナント",
    enedata401:'信号点名の編集をクリック',
    enedata402:"試験情報を発表する",
    enedata403:"タイトルフォントの設定",
    enedata404:"サブタイトルフォントの設定",
    enedata405:"ラベル項目名フォント設定",
    enedata406:"ラベル項目内容フォント設定",
    enedata407:"警告プロンプトフォント設定",
    enedata408:"コメントフォント設定",
    enedata409:"モニタに送信するかどうか",
    enedata410:"サイズ",
    enedata411:"色",
    enedata412:"保存のみ",
    enedata413:"ディスプレイに同期",
    enedata414:"フォントスタイルの設定",
    enedata415:"タイトルを入力してください。",
    enedata416:"サブタイトルを入力してください。",
    enedata417:"ラベル項目名を入力してください。",
    enedata418:"ラベル項目の内容を入力してください。",
    enedata419:"警告メッセージを入力してください。",
    enedata420:"コメントを入力してください。",
    enedata421:"タイトルの長さは1～30でなければなりません。",
    enedata422:"サブタイトルの長さは1～30でなければなりません。",
    enedata423:"ラベル・アイテム名の長さは1～30でなければなりません。",
    enedata424:"ラベル項目の内容の長さは1～30でなければなりません。",
    enedata425:"警告メッセージの長さは1～30でなければなりません。",
    enedata426:"メモの長さは1～100でなければなりません。",
    enedata427:"試験情報のみ発表",
    enedata428:"フォントスタイルのみを設定",
    enedata429:"同時に情報を発表し、フォントスタイルを設定します。",
    enedata430:"日付を選択してください",
    enedata431:"色を選択",
    enedata432:"色を選択してください",
    enedata433:"色コードフォーマットエラー",
    enedata434: "遷移アラート設定ページをクリック",
    enedata435: "計測情報",
    enedata436:"前回計量日",
    enedata437: "有効期間(月)を計量する",
    enedata438: "次回計量日",
    enedata439:"日付",
    enedata440:"週の選択",
    enedata441:"デバイス/信号名",
    enedata442:"月",
    enedata443:"炭素排出",
    enedata444:"設備連動設定",
    enedata445:"前の日",
    enedata446:"次の日",
    enedata447:"前の時間",
    enedata448:"次の時間",
    enedata449:"トレンドリスト",
    enedata450:"年度を選択してください",
    enedata451:"エリア",
    enedata452:"エリアを選択してください",
    enedata453:"換算係数",
    enedata454:"換算係数を入力してください",
    enedata455:"換算単位",
    enedata456:"換算単位を入力してください",
    enedata457:"換算単位長は1から30の間でなければならない",
    enedata458:"設定された換算係数情報を保存しますか？",
    enedata459:"この換算係数を削除しますか？",
    enedata460:"削除する換算係数を先に選択してください！",
    enedata461:"選択した換算係数を削除しますか？",
    enedata462:"サイトエネルギー換算係数設定",
    enedata463:"エリアエネルギー換算係数設定",
    enedata464:"エネルギー換算係数",
    enedata465:"サイトエネルギー換算係数",
    enedata466:"エリアエネルギー換算係数",
    enedata467:'デバイスタイプ',
    enedata468:'デバイスタイプを選択してください！',
    enedata469:'関連信号を選択してください！',
    enedata470:'仮想デバイス名を入力してください！',
    enedata471:'仮想デバイス名の長さは1～30でなければなりません',
    enedata472:'を選択してください',
    enedata473:'デバイス所属',
    enedata474:'仮想デバイス設定',
    enedata475:'この仮想デバイスを削除しますか？',
    enedata476:'削除する仮想デバイスを先に選択してください！',
    enedata477:'選択した換算係数を削除しますか？',
    enedata478:'仮想デバイス管理',
    enedata479:'演算点管理',
    enedata480:'ライン名を入力してください！',
    enedata481:'ライン名の長さは1から30の間でなければなりません。',
    enedata482:'生産ライン',
    enedata483:'ライン設定',
    enedata484:'生産ライン名',
    enedata485:'設定されたライン情報を保存することを確認しますか？',
    enedata486:'この生産ラインを削除することを確認しますか？',
    enedata487:'生産ラインの追加',
    enedata488:'生産ラインの編集',
    enedata489:'製品名',
    enedata490:'生産オーダー番号',
    enedata491:'生産開始日',
    enedata492:'生産終了日',
    enedata493:'生産数量',
    enedata494:'製品単位',
    enedata495:'生産エネルギー消費',
    enedata496:'エネルギー消費単位',
    enedata497:'生産単価',
    enedata498:'生産単価単位',
    enedata499:'生産単価管理',
    enedata500:'生産ラインを選択してください',
    enedata501:'製品名を入力してください',
    enedata502:'生産オーダー番号を入力してください',
    enedata503:'生産開始日を選択してください',
    enedata504:'生産終了日を選択してください',
    enedata505:'生産数量を入力してください',
    enedata506:'製品単位を入力してください',
    enedata507:'生産エネルギー消費を入力してください',
    enedata508:'エネルギー消費単位を入力してください',
    enedata509:'生産単価入力してください',
    enedata510:'生産単価単位を入力してください',
    enedata511:'生産期間',
    enedata512:'生産期間を選択してください',
    enedata513:'製品名の長さは1~30でなければなりません',
    enedata514:'生産オーダー番号の長さは1~30の間でなければなりません',
    enedata515:'製品単位の長さは1~30でなければならない',
    enedata516:'エネルギー消費単位長は1~30の間でなければならない',
    enedata517:'注釈説明長は1~300でなければなりません',
    enedata518:'設定された生産エネルギー消費情報を保存することを確認しますか？',
    enedata519:'この生産エネルギー消費を削除することを確認しますか？',
    enedata520:'削除する生産エネルギー消費を先に選択してください！',
    enedata521:'選択した生産エネルギー消費を削除することを確認しますか？',
    enedata522:'生産数量はゼロにできません！',
    enedata523:'生産単価はゼロにできません！',
    enedata524:'値はゼロにできません！',
    enedata525:'CSVインポート',
    enedata526:'生産エネルギー消費データのインポート',
    enedata527:'テンプレートファイル',
    enedata528:'生産エネルギー消費テンプレートファイル',
    enedata529:'生産エネルギー消費テンプレートファイルのダウンロード',
    enedata530:'エラーメッセージ',
    enedata531:'ダウンロードエラーメッセージ',
    enedata532:'NO,ライン名,製品名,生産オーダー番号,生産開始日,生産終了日,生産数量,製品単位,生産エネルギー消費,エネルギー消費単位,コメント',
    enedata533:'グループ化の基準',
    enedata534: ' 生産単価レポート',
    enedata535:'計量ポイントを選択してください。',
    enedata536:'時刻設定トレンドグラフ',
    enedata537:'データ比較トレンドグラフ',
    enedata538:'データ出力',
    enedata539:'データ比較トレンドグループ設定',
    enedata540:'時刻設定トレンドグループ設定',
    enedata541:'選択された日付はすでに存在します。選択を繰り返さないでください」',
    enedata542:'最大7つの日付を追加します。',
    enedata543:'ファイル形式が間違っています。CSVファイルを選択してください。',
    enedata544:'今日',
    enedata545:'当月',
    enedata546:'先月',
    enedata547 :'トレンドレコード情報',
    enedata548 :'記録生成間隔時間',
    enedata549 :'リードインターバル時間',
    enedata550 :'最新トレンド記録時間',
    enedata551 :'分',
    enedata552 :'トレンド読み取り状態',
    enedata553 :'読み込み中',
    enedata554 :'読み取り停止',
    enedata555 :'最近の読み取り時間',
    enedata556 :'最近の読み取り番号',
    enedata557:'データ一括インポート',
    enedata558:'年報データ一括インポート',
    enedata559:'月報データ一括インポート',
    enedata560:'日報データ一括インポート',
    enedata561:'年報一括入力テンプレートファイル',
    enedata562:'月報一括入力テンプレートファイル',
    enedata563:'日報一括入力テンプレートファイル',
    enedata564:'月／単位',
    enedata565:'日／単位',
    enedata566:'時／単位',
    enedata567:'パラメータエラー',
    enedata568:'信号点使用量修正',
    enedata569:'使用量修正モード',
    enedata570:'デバイス情報に基づいて修正',
    enedata571:'手動入力使用量修正',
    enedata572:'現在の日付と将来の日付の使用量は変更できません。',
    enedata573:'昼間使用量',
    enedata574:'夜間使用量',
    enedata575:'昼と夜の使用量を区別するのは毎月/毎日です。',
    enedata576:'昼間の時間帯は9時~ 22時。',
    enedata577:'夜間の時間帯は1時~ 8時と23時~ 24時。',
    enedata578:'データソース',
    enedata579:'データベース',
    enedata580:'デバイス',
    enedata581:'同期更新データ',
    enedata582:'はい',
    enedata583:'いいえ',
    enedata584:'同期更新データ：毎時データを修正し、毎日と毎月のデータを同期更新し、当日と当月のデータを除く。毎日のデータを修正し、同期して毎月のデータを更新し、当月のデータを除く。毎月のデータを変更同期なしでデータを更新します。',
    enedata585:'KPI日付',
    enedata586:'操作日',
    enedata587:'実行日',
    enedata588:'アラーム状態',
    enedata589:'運転状態',
    enedata590:'KPI目標値',
    enedata591:'年次規則',
    enedata592:'エネルギー効率KPIアラーム設定',
    enedata593:'有効化',
    enedata594:'停止',
    enedata595:'実行',
    enedata596:'設定するKPIターゲットアラートを選択してください。',
    enedata597:'アラームレベルを選択してください。',
    enedata598:'エネルギー効率KPI自動タスク管理',
    enedata599:'昼間使用量を入力してください',
    enedata600:'夜間使用量を入力してください',
    enedata601:'全天使使用量を入力してください',
    enedata602:'昼間使用量値の範囲は0～999999999です。',
    enedata603:'夜間使用量値の範囲は0～999999999です。',
    enedata604:'終日使用量値の範囲は0～1999999998です。',
    enedata605:'サイトエネルギー消費対比',
    enedata606:'親ノード',
    enedata607:'デバイスタイプ',
    enedata608:'親ノード',
    enedata609:'演算式入力エラー',
    enedata610:'残高',
  },
  video: {
    viddata001: 'ダウンロード',
    viddata002: 'ビデオコントロール',
    viddata003: 'ダウンロード',
    viddata004: 'カメラ種類',
    viddata005: 'カメラID',
    viddata006: 'カメラ名称',
    viddata007: 'カメラIP',
    viddata008: 'カメラポート',
    viddata009: 'レコーダー',
    viddata010: 'レコーダーIP',
    viddata011: 'レコーダーポート',
    viddata012: 'レコーダー種類',
    viddata013: '帯域制限',
    viddata014: '帯域制限一括設定',
    viddata015: '全方位補正値',
    viddata016: '全方位補正一括設定',
    viddata017: '標準',
    viddata018: 'スクウェアビュー',
    viddata019: 'ダブルパノラマ',
    viddata020: '未登録カメラ一覧',
    viddata021: '登録カメラ一覧',
    viddata022: 'グループ名',
    viddata023: 'カメラグループ設定',
    viddata024: 'カメラ設定',
    viddata025: '新規グループ作成',
    viddata026: 'グループ削除',
    viddata027: 'グループ名称変更',
    viddata028: '設定する権限が許可されていません。',
    viddata029: '一括編集',
    viddata030: 'カメラグループ',
    viddata031: 'リアルタイム動画',
    viddata032: '再生',
    viddata033: 'カメラ一覧',
    viddata034: '動画一覧',
    viddata035: '注意',
    viddata036: 'ビデオファイルの保存場所',
    viddata037: 'スナップショットファイルの保存場所',
    viddata038: 'フルスクリーン',
    viddata039: 'シングルスクリーン再生',
    viddata040: '{num}スクリーン放送',
    viddata041: '電子増幅を有効にする',
    viddata042: '電子増幅を無効にする',
    viddata043: 'スナップショット',
    viddata044: '録画を開始する',
    viddata045: 'ビデオを終了します',
    viddata046: '再生',
    viddata047: '一時停止',
    viddata048: '停止',
    viddata049: '減速再生',
    viddata050: '加速再生',
    viddata051: '保存',
    viddata052: 'ローカル再生',
    viddata053: '再接続',
    viddata054: '閉じる',
    viddata055: "ステータスパネル",
    viddata056: "電子増幅",
    viddata057: "元に戻す",
    viddata058: "動画リスト名称",
    viddata059: "開始時間",
    viddata060: "終了時間",
    viddata061: "システムはカメラタイプに対応していません。",
    viddata062: "プラグインはまだインストールされていません。ダウンロード機能ページでプラグインをダウンロードしてインストールしてください。",
    viddata063: "すべて停止",
    viddata064: "カメラ",
    viddata065: "カメラの数",
    viddata066: "プレビューを停止できませんでした。",
    viddata067: "プレビュー失敗！",
    viddata068: "設備はWebsocketの流出に対応していません。",
    viddata069: "ビデオはもう登録しました。",
    viddata070: "ビデオの接続に失敗しました。",
    viddata071: "カメラ合計",
    viddata072: "グループ化済みカメラ合計",
    viddata073: "グループ化されていませんカメラ合計",
  },

  // 电梯模块--设备页
  elevatorequipment: {
    a1: 'タイプ選択',
    a2: '全体',
    a3: 'コントローラ',
    a4: '認証端末',
    a5: 'ローディング中...',
    a6: '正常',
    a7: '通信異常',
    a8: 'メンテナンス',
    a9: '機器異常',
    a10: '故障／警報',
    a11: '保守警報／上下限警報・発停異常',
    a12: 'クエリー',
    a13: 'サイトのデータに異常がある場合は管理者に連絡してください'
  },
  // 电梯模块--远程控制
  elevatorecontrol: {
    a1: '遠隔管理',
    a2: '棟内相互訪問',
    a3: '遠隔召梯',
    a4: '家にはしごを呼ぶ',
    a5: '遠隔授権',
    a6: 'ローディング中...',
    a7: '時間帯',
  },
  // 电梯模块--栋内互访页
  elevatorexchangevisits: {
    a1: '相互訪問のプロセス',
    a2: '来訪者の位置',
    a3: '対象者の位置',
    a4: '終了した',
    a5: '【フロア】と【部屋】を選択します',
    a6: '【相互訪問】をクリックします',
    a7: '家にはしごを呼ぶ',
    a8: '【家にはしごを呼ぶ】をクリックします',
    a9: '互いに訪問する',
    a10: '来訪者の部屋',
    a11: '対象者の階数',
    a12: '全体',
    a13: '来訪者の階数',
    a14: '操作が成功しました',
    a15: '操作に失敗しました',
  },
  // 电梯模块--远程召梯页
  elevatorRemoteemErgencyRecall: {
    a1: '全体',
    a2: '階段の位置を選んでください',
    a3: 'フロアに行く',
    a4: '部屋に行きます',
    a5: '階段だけを呼ぶ',
    a6: '階段を呼んで、フロア権限を開放します',
    a7: '操作が成功しました',
    a8: '操作に失敗しました',
  },
  // 电梯模块--家にはしごを呼ぶ页
  elevatorIndoorErgencyRecall: {
    a1: '全体',
    a2: '階段の位置を選んでください',
    a3: '階段の位置',
    a4: '家にはしごを呼ぶ',
    a5: '操作が成功しました',
    a6: '操作に失敗しました',
  },
  // 电梯模块--远程授权页
  controlElevator: {
    a1: 'エレベーター',
    a2: 'エレベーターを選んでください',
    a3: 'エレベーター制限階の設置',
    a4: '解除',
    a5: '投げ入れる',
    a6: '注意：いったん解除または投入したら、エレベーターは自動的に解除できなくなり、手動で状態切り替えが必要です。',
    a7: '単一フロア制限を臨時解除する',
    a8: '一時的に権限を解除する場所',
    a9: 'この階にはまだ権限を解除できる場所がありません。',
    a10: '制限を解除する',
    a11: '操作が成功しました',
    a12: '操作に失敗しました',
  },
  //エレベーターモジュール－フロア時間帯設定ページ
  controlTimetable:{
    a1:'ロード中....',
    a2:'フロア時間帯',
    a3:'フロア時間帯リスト',
    a4:'編集',
    a5:'適用',
    a6:'エレベーターをお選びください',
    a7:'フロアスケジュール設定',
    a8:'設定',
    a9:'キャンセル',
    a10:'フロア',
    a11:'フロアを選択してください',
    a12:'メインゲート',
    a13:'副門',
    a14:'スケジュールを選択してください',
    a15:'適用',
    a16:'現在のフロア',
    a17:'全フロア',
    a18:'公共層',
    a19:'秘密保持層',
    a20:'主',
    a21:'副',
    a22:'フロアパラメータが更新されました。[適用]をクリックしてください。',
    a23:'フロアの更新に失敗しました。詳細:',
    a24:'期間の設定',
    a25:'この操作はフロア情報の時間帯を更新します。更新しているか確認してください。',
    a26:'確認',
    a27:'キャンセル',
    a28:'応用成功',
    a29:'適用に失敗しました。理由',
    //tb表
    tb1:'物理層',
    tb2:'数顕',
    tb3:'属性',
    tb4:'エレベータードア',
    tb5:'メインゲート時間帯',
    tb6:'サブゲート期間',
  },
  // 设置 - 电梯时间表
  elevatorTimetable: {
    a1:'ロード中....',
    a2:'スケジュールの追加',
    a3:'エレベータースケジュール',
    a4:'関連コントローラ',
    a5:'スケジュール',
    a6:'関連',
    a7:'スケジュール名',
    a8:'カードモード',
    a9:'カードモードを終了',
    a10:'有効',
    a11:'閉じる',
    a12:'エレベーターのスケジュールを選択してください',
    a13:'スケジュールコピー',
    a14:'関連付けを追加',
    a15:'適用',
    a16:'スケジュールの追加',
    a17:'スケジュール名を入力してください',
    a18:'設定',
    a19:'閉じる',
    a20:'ソースのコピー',
    a21:'ターゲットソース',
    a22:'スケジュール名の更新',
    a23:'設備ロット関連',
    a24:'関連するコントローラ',
    a25:'関連するコントローラを選択してください',
    a26:'テーブルのコピーに成功しました',
    a27:'テーブルのコピープロセスに失敗しました',
    a28:'次の期間シーケンス番号の取得に失敗しました',
    a29:'応用成功',
    a30:'アプリケーションの設定に失敗しました',
  },
  // 设置 - 电梯时间表 - 时间表页
  timetableModule:{
    a1:'ロード中...',
    a2:'月曜日',
    a3:'火曜日',
    a4:'水曜日',
    a5:'木曜日',
    a6:'金曜日',
    a7:'土曜日',
    a8:'日曜日',
    a9:'週',
    a10:'モード',
    a11:'有効日設定',
    a12:'有効日',
    a13:'設定',
    a14:'キャンセル',
    a15:'設定週',
    a16: '年',
    a17: '月',
    a18: '日',
    a19: '至',
  },
  //// 设置 - 电梯时间表 - 时间表页 - 表
  timetableModuleDT:{
    a1:'ロード中...',
    a2:'スケジュール変更',
    a3:'スケジュールタイプ',
    a4:'エレベータースケジュール',
    a5:'スケジュール名',
    a6:'初期値は',
    a7:'カードモードを終了',
    a8:',他のモードを設定してください。',
    a9:'時',
    a10:'分',
    a11:'設定',
    a12:'キャンセル',
    a13:'カードモード',
  },
  timedeviceModule:{
    a1:'ロード中...',
    a2:'除去',
    a3:'デバイスは関連付けられていません',
    a4:'エレベーターのスケジュールとの関連付けをキャンセルしますか?',
    a5:'削除に成功しました。',
    a6:'解縛を行うには、スケジュールを停止してください。',
    a7:'アプリケーションの設定に成功しました',
    a8:'コントローラの有効化期間に失敗しました',
    a9:'コントローラ無効期間失敗',
    a10:'エレベーターのスケジュールと関連付けを解除します。',
  },
  // 电梯模块--卡片记录
  elevatorCardRecords: {
    a0: 'ローディング中...',
    a1: '記録',
    a2: 'カード番号',
    a3: '結果',
    a4: '初期日付/時間選択',
    a5: '日付/時間の選択を終了します',
    a6: 'クエリー',
    a7: 'レコードリスト',
    a8: '日付または時間を選択してください',
    a9: '発生日',
    a10: '認証の種類',
    a11: '名前',
    a12: 'カード番号',
    a13: '体温',
    a14: 'エレベーター名',
    a15: 'シグナル/ターミナル名',
    a16: '建物の名前',
    a17: 'コメント',
    a18: '結果',
    a19: 'せいこう',
    a20: 'しっぱい',
    a21: 'カード番号を入力してください',
    a22: '全体',
    a23: '共に',
    a24: '条データ',
    a25: 'か-ど'
  },
  elevatorQrCodeRecords: {
    a0: 'ローディング中....',
    a1: 'ＱＲコード記録',
    a2: 'ＱＲコード',
    a3: '同期結果',
    a4: '初期日/時間選択',
    a5: '終了日/時間選択',
    a6: 'クエリー',
    a7: 'レコードリスト',
    a8: '日付または時間を選択してください',
    a9: '発生日',
    a10: 'qrコードを入力してください',
    a11: '全体',
    a12: '共に',
    a13: '条データ',
    a14: ' ',
    a15: ' ',
    a16: ' ',
    a17: ' ',
    a18: ' ',
    a19: ' ',
    a20: ' ',
    a21: ' ',
    a22: ' ',
    a23: ' ',
    a24: ' ',
    a25: ' ',
    a26: ' ',
    a27: ' ',
    a28: ' ',
    a29: '',
    td1: '発生日',
    td2: '申請人',
    td3: '番号',
    td4: 'qrコード証明書',
    td5: 'コード状態',
    td6: '開始時間を申請します',
    td7: '有効時間',
    td8: 'スタートフロア',
    td9: 'エレベーター名',
    td10: '信号/端末名',
    td11: '建物名',
    td12: '注釈',
    td13: '所属ルーム',
    td14: '同期時間',
    td15: '同期結果',
    s1: 'せいこう',
    s2: 'しっぱい',
    s3: '情報異常',
    s4: 'アップロード中',
    s5: '有効',
    s6: '無効',
  },
  elevatorRemoteRecords: {
    a0: 'ローディング中....',
    a1: '遠隔制御の操作',
    a2: 'コマンド',
    a3: '結果',
    a4: '初期日/時間選択',
    a5: '終了日/時間選択',
    a6: 'クエリー',
    a7: '記録リスト',
    a8: '日付または時間を選択してください',
    a9: '発生日',
    a10: '認証タイプ',
    a11: '',
    a12: 'カード番号',
    a13: '',
    a14: 'エレベーター名',
    a15: '信号/端末名',
    a16: '建物名',
    a17: '注釈',
    a18: '結果',
    a19: 'せいこう',
    a20: 'しっぱい',
    a21: 'カード番号を入力してください',
    a22: 'すべて',
    a23: '共',
    a24: '箇条データ',
    a25: 'カード',
    td1: '召し召しコマンド',
    td2: '要求時間',
    td3: '応答時間',
    td4: '名前',
    td5: '番号',
    td6: 'ID',
    td7: '所属ルーム',
    td8: '部屋を訪れる',
    td9: '部屋を訪問される',
    td10: '注釈',
    td11: '結果',
    s1: 'すべて',
    s2: '遠隔開錠',
    s3: 'インドアドア',
    s4: '相互訪問',
    s5: '微信召梯',
    s6: '失敗',
    s7: '情報異常',
    s8: '設備がオンラインではない',
    s9: '1番ハシゴ応答',
    s10: '2番ハシゴ応答',
    s11: '3番ハシゴ応答',
    s12: '4番ハシゴ応答',
    s13: '5番ハシゴ応答',
    s14: '6番ハシゴ応答',
    s15: '7番梯応答',
    s16: '8番ハシゴ応答',
    s17: '9番梯応答',
    s18: '10ハシゴ応答',
    s19: '11ハシゴ応答',
    s20: '12番ハシゴ応答',
    s21: '13ハシゴ応答',
    s22: '14ハシゴ応答',
    s23: '15ハシゴ応答',
    s24: '16番ハシゴ応答'
  },
  // 电梯模块--实际数据
  elevatorRealdata: {
    a1: 'ローディング中...',
    a2: 'レポートデータ',
    a3: '全体',
    a4: 'カード',
    a5: 'カード読取機',
    a6: '式',
    a7: '月報',
    a8: '日報',
    a9: '出力期間:',
    a10: 'ファイル分解数:',
    a11: '出力日数:',
    a12: '1分割せずにファイルを生成する',
    a13: ' 15日ごとに分割(最後のファイルの終了日を含む)ファイルを作成',
    a14: '3 20日ごとに分割(最後のファイルの終了日を含む)ファイルを作成',
    a15: '4 7日ごとに分割(最後のファイルの終了日を含む)ファイルを作成する',
    a16: '5 6日ごとに分割して(最後のファイルの終了日も含めて)ファイルを生成する',
    a17: '出力量が大きい場合、除算数は自然に5に変更されます',
    a18: 'ダウンロードファイル名:',
    a19: '出力中…',
    a20: '出力',
    a21: 'ファイル分割の説明',
    a22: '名前',
    a23: '名前を入力してください',
    a24: 'カード番号',
    a25: 'カード番号を入力してください',
    a26: 'カードid',
    a27: 'カードidを入力してください',
    a28: 'エレベーターリーダー',
    a29: 'アクセスカードを読み取ります',
    a30: '問合わす',
    a31: '認証情報リスト',
    a32: '条データ',
    a33: '所属を管理する',
    a34: '日付を選ばなかった',
    a35: '日付を選んでください',
    a36: '所属コントローラー',
    a37: '共に',
    a38: '装置名',
    a39: '装置名を入力してください',
    a40: 'ドアリスト',
  },
  // 电梯模块--运行状态
  elevatorRunning: {
    a1: 'ローディング中...',
    a2: '選別',
    a3: '消防と火災',
    a4: 'vip直行便',
    a5: 'ITM閉鎖',
    a6: 'ITM対応',
    a7: '故障',
    a8: '設備未アクセス',
    a9: '請求中',
    a10: '失敗を収める',
    a11: 'パラメータ異常',
    a12: '設備が存在しない',
    a13: 'コントローラパラメータ異常',
    a14: 'せいじょう',
    a15: '上り方向',
    a16: '運動上り',
    a17: '下り方向',
    a18: '運動が下る',
    a19: '静止/無方向',
  },
  // 认证信息
  information: {
    a1: "ローディング中....",
    a2: "カード情報",
    a3: "人員情報",
    a4: "カードの所属選択",
    a5: "名前",
    a6: "名前を入力してください",
    a7: "管理コード",
    a8: "管理コードを入力してください",
    a9: "カード番号",
    a10: "カード番号を入力してください",
    a11: "カード番号",
    a12: "カード番号を入力してください",
    a13: "表示オプション",
    a14: "所属から",
    a15: "すべて",
    a16: "使用タイプ",
    a17: "すべての使用タイプ",
    a18: "普通カード",
    a19: "臨時カード",
    a20: "使用状態",
    a21: "すべての使用状態",
    a22: "未割り当て",
    a23: "使用中",
    a24: "カード状態",
    a25: "全カードステータス",
    a26: "使用・貸出可",
    a27: "使用不可・返却",
    a28: "使用停止",
    a29: "クエリ",
    a30: "カード情報リスト",
    a31: "削除",
    a32: "編集",
    a33: "選択操作",
    a34: "カード所属編集者",
    a35: "追加",
    a36: "条データ",
    a37: "使用可能",
    a38: "使用不可",
    a39: "使用停止",
    a40: "貸す",
    a41: "返す",
    a42: "管理所属",
    a43: "関連建築",
    a44: "使用開始日",
    a45: "有効期限",
    a46: "最終使用日",
    a47: "メモ",
    a48: "操作",
    a49: "カード情報を削除します?",
    a50: "変更",
    a51: "閉じる",
    a52: "下位所属を追加する",
    a53: "所属名称:",
    a54: "名前を入力してください",
    a55: "所属名称変更",
    a56: "削除確定",
    a57: "「ですか?」と",
    a58: "カード情報を追加",
    a59: "カードタイプ",
    a60: "カードが所属する",
    a61: "カード番号を空にしてはいけません",
    a62: "カード番号を空にしてはいけません",
    a64: "カード情報を編集する",
    a65: "設定",
    a66: "所属選択を管理する",
    a67: "部署",
    a68: "部門を入力してください",
    a69: "ユーザ情報リスト",
    a70: "スタッフ編集者",
    a71: "人員チャージ",
    a72: "所属編集者の管理",
    a73: "人員追加",
    a74: "カードタイプ設定",
    a75: "口座のチャージ",
    a76: "バッチ追加登録",
    a77: "アカウントタイプ",
    a78: "記帳",
    a79: "現金",
    a80: "残高",
    a81: "登録日",
    a82: "所属1",
    a83: "所属2",
    a84: "所属3",
    a85: "所属4",
    a86: "所属5",
    a87: "ユーザ情報一括登録",
    a88: "ユーザ情報",
    a89: "所属選択を管理します",
    a90: "カード所属選択です",
    a91: "アクセスサイト選択です",
    a92: "ユーザ情報(XLSX、CSV)アップロード",
    a93: "*管理所属は「全ての管理所属」を選択します",
    a94: "ファイルを選択します",
    a95: "アップロード",
    a96: "情報(ダウンロード",
    a97: "部下の管理所属を同時にダウンロードする",
    a98: "ダウンロード",
    a99: "指静脈情報をアップロードする",
    a100: "指静脈情報(. csv)ダウンロード",
    a101: "指静脈が登録されているデータのみダウンロードします",
    a102: "指静脈情報",
    a103: "ナンバープレート情報",
    a104: "ナンバープレート情報(. csv)をアップロードします",
    a105: "ナンバープレート情報(. csv)ダウンロード",
    a106: "ナンバープレートが登録されているデータだけをダウンロードします",
    a107: "ファイルフォーマットエラー",
    a108: "ファイル形式が。csvのファイルを選択してください",
    a109: "カードタイプ設定",
    a110: "消費カードタイプ設定",
    a111: "スタッフ管理",
    a113: "基本情報",
    a114: "携帯電話番号",
    a116: "携帯電話番号を入力してください",
    a117: "所属:",
    a118: "所属チーム:",
    a119: "登録日:",
    a120: "更新日:",
    a121: "所属変更",
    a122: "カード",
    a123: "カード情報",
    a124: "カードを削除する",
    a125: "関連サイト",
    a126: "サイトを選択する",
    a127: "普通カード",
    a128: "カード番号:",
    a129: "有効期限:",
    a130: "カードステータス:",
    a131: "使用可能",
    a132: "使用不可",
    a133: "使用停止",
    a134: "指静脈",
    a135: "第一指情報:",
    a136: "第2指情報:",
    a137: "閾値レベル:",
    a138: "顔",
    a139: "顔情報:",
    a140: "特権設定",
    a141: "清掃員特権:",
    a142: "無",
    a143: "ある",
    a144: "指静脈特権:",
    a145: "消費設定",
    a146: "カードタイプ:",
    a147: "決済方法:",
    a148: "はしご制御",
    a149: "部屋名:",
    a150: "関連エレベーター",
    a151: "エレベーターを選択します",
    a152: "サイト名",
    a153: "走行パターン1",
    a154: "走行パターン",
    a155: "有効期限",
    a156: "走行パターン2",
    a157: "パターン3",
    a158: "走行パターン4",
    a159: "歩行パターン5",
    a160: "エレベーター名",
    a161: "階",
    a162: "部屋名",
    a163: "登録していない",
    a164: "登録済み",
    a165: "採集済み",
    a166: "所属管理を選択していない",
    a167: "所属情報の管理を選択してください",
    a168: "所属選択および編集の管理",
    a169: "カードセレクト",
    a170: "カード番号 ",
    a171: "カードidを入力してください",
    a172: "カード番号",
    a173: "カード番号を入力してください",
    a174: "クエリ",
    a175: "カードの一覧",
    a176: "カード番号",
    a177: "カード番号 ",
    a178: "カード状態",
    a179: "使用可能",
    a180: "使用不可",
    a181: "使用停止",
    a182: "貸す",
    a183: "返す",
    a184: "カードタイプ",
    a185: "普通カード",
    a186: "臨時カード",
    a187: "カード使用開始日",
    a188: "操作",
    a189: "有効期限設定",
    a190: "キャンセル",
    a191: "確認",
    a192: "最初の日付",
    a193: "終了日",
    a194: "指静脈採集",
    a195: "第一指:",
    a196: "読み取り",
    a197: " 2つめの指:",
    a198: "設備:指静脈採集器接続成功! ",
    a199: "設備:指静脈採集器が接続されていません! ",
    a200: "読み取り待ち",
    a201: "「読み取り中…」",
    a202: "読み取り失敗",
    a203: "採集しました",
    a204: "顔画像設定",
    a205: "画像を選択します",
    a206: "注:標準的な証明写真、画像形式jpg。",
    a207: "サイト選択",
    a208: "設定",
    a209: "閉じる",
    a210: "登録されていないサイトの一覧",
    a211: "↓を追加します",
    a212: "登録したサイトの一覧",
    a213: "削除↑",
    a214: "条データ",
    a215: "名前",
    a216: "アドレス",
    a217: "タイプ",
    a218: "番号",
    a219: "歩行モード選択",
    a220: "通行パターンのリスト",
    a221: "走行パターン",
    a222: "開始日",
    a223: "終了日",
    a224: "選択済み",
    a225: "この通行パターンは選択済みです。他の通行パターンを選択してください。",
    a226: "追加上限上限に達します",
    a227: " 5つのパターンしか追加できません",
    a228: "エレベーター選択",
    a229: "登録されていないエレベーターの一覧",
    a230: "登録されているエレベーターの一覧",
    a231: "フロア名",
    a232: "部屋番号",
    a233: "口座のチャージ",
    a234: "チャージ",
    a235: "チャージカード番号照会:",
    a236: "カード番号を入力してください",
    a237: "基本情報",
    a238: "消費情報",
    a239: "口座残高",
    a240: "決済方法",
    a241: "登録日",
    a242: "更新日",
    a243: "最近のチャージ金額",
    a244: "最近のチャージ時間",
    a245: "記帳カードはチャージ不要",
    a246: "チャージ金額",
    a247: "チャージ金額を入力してください",
    a248: "この人の情報がないか調べます",
    a249: "入力に間違いがないかチェックしてください",
    a250: "カード番号未入力",
    a251: "チャージ金額が入力されていません",
    a252: "部屋番号が選択されていません",
    a253: "部屋番号を選んでください",
    a254: "画像サイズは6Mまでです",
    a255: "JGP形式のファイルを選択してください",
    a256: "国籍",
    a257: "エレベーター情報",
    a258: "エレベーター情報(.CSV)アップロード",
    a259: "エレベーター情報(.CSV)ダウンロード",
    a260: "管理コードは空にできません",
    a261: "名前は空にできません",
    a262: "正しい携帯番号を記入してください",
    a263: '通行モード詳細',
    a264: '通行モード名',
    a265: '企業名',
    a266: '建築名',
    a267: 'フロア・テナント名',
    a268: 'デバイス名',
    a269: '認証端末名',
    a270: '出入りタイプ',
    a271: '警戒設定',
    a272: 'スケジュール名',
    a273: 'カレンダー名',
    a274: '通行モード名を入力してください',
    a275: '総数量：',
    a276: '追加数：',
    a277: '更新数：',
    a278: '失敗数：',
    a279: '詳細ダウンロード',
    a280: 'データ詳細',
    a281: 'サイトを選択してください',
    a282: '通行モードリストを登録できます',
    a283: '通行パターンリストが登録されています',
    a284: '名前検索',
    a285: '数量が5つの上限を超えた',
    a286: '権限設定',
    a287: '顔情報',
    a288: '顔情報(.zip)アップロード',
    a289: '顔情報(.zip)ダウンロード',
    a290: 'ファイル形式が.zipのファイルを選択してください',
    a291: '検索テキストの入力',
    a292: '人員通行設備情報ダウンロード',
    a293: '人員通行情報ダウンロードします',
    a294: '最近人員情報のダウンロードを大量に導入しました',
    a295:'画像を選択します',
    a296:'画像編集です',
    a297:'図で説明します',
  },
  // 消费管理模块
  consume: {
    a1: "ローディング中....",
    a2: "デバイスの詳細",
    a3: "閉じて",
    a4: "基本情報",
    a5: "消費時間帯",
    a6: "価格一覧",
    a7: "正常に運行しています",
    a8: "オフライン/ メンテナンス",
    a9: "朝ごはん",
    a10: "ランチ",
    a11: "夕食",
    a12: "宵宵",
    a13: "端末名",
    a14: "サイト名",
    a15: "顧客名",
    a16: "状態",
    a17: "月曜日",
    a18: "火曜日",
    a19: "水曜日",
    a20: "木曜日に",
    a21: "金曜日",
    a22: "土曜日",
    a23: "日曜日",
    a24: "カード記録",
    a25: "名前",
    a26: "名前を入力してください",
    a27: "カード番号",
    a28: "カード番号を入力してください",
    a29: "管理コード",
    a30: "管理コードを入力してください",
    a31: "消費者机名",
    a32: "消費者机名を入力してください",
    a33: "消費者番号",
    a34: "消費者机名を入力してください",
    a35: "開始日",
    a36: "終了日",
    a37: "検索",
    a38: "記録リスト",
    a39: "日付か時間を選んでください",
    a40: "箇条データ",
    a41: "発生日",
    a42: "消費スタイル",
    a43: "口座の種類",
    a44: "名前",
    a45: "管理コード",
    a46: "カード番号",
    a47: "サイト名",
    a48: "消費者番号",
    a49: "消費者机名",
    a50: "消費金額",
    a51: "残額",
    a52: "食事の次",
    a53: "所属1",
    a54: "所属2",
    a55: "所属3",
    a56: "所属4",
    a57: "所属5",
    a58: "日替わりメニュー設定",
    a59: "ファイルを選択する",
    a60: "備考:ファイル形式のみxlsxのアップロードを受け付けます。",
    a61: "アップロード",
    a62: "消費設定",
    a63: "消費期間パラメータ設定",
    a64: "消費機設置のパラメータ",
    a65: "カード使用时间间隔(秒)",
    a66: "1日の消費限度額(元) ",
    a67: "1日の消費回数制限",
    a68: "音声案内タイプ",
    a69: "— —",
    a70: "1-消費成功音声アラート",
    a71: "2- dit一色",
    a72: "3-ロングdit一色",
    a73: " 4−voicetype1",
    a74: " 5−voicetype2",
    a75: "設定",
    a76: "消費パラメータ設定?",
    a77: "消費パラメータ設定を変更するか。",
    a78: "決済ルールの設定",
    a79: "各席別の価格設定一覧",
    a80: "編集",
    a81: "回ごとの価格設定",
    a82: "を取り消し",
    a83: "を確認し",
    a84: "価格設定",
    a85: "朝食(元)",
    a86: "ランチ(元)",
    a87: "ディナー(元)",
    a88: "宵宵(元)",
    a89: "操作",
    a90: "消費者報告書",
    a91: "報表時間帯選択",
    a92: "食堂選択",
    a93: "時間帯",
    a94: "出力期間",
    a95: "1か月",
    a96: "一日",
    a97: "時間をカスタムする",
    a98: "出力報テーブルタイプ選択",
    a99: "すべて",
    a100: "ダウンロードファイル名",
    a101: "出力中に…",
    a102: "アウトプット",
    a103: "消費明細書",
    a104: "箇人消費のスケジュール",
    a105: "箇人消費月報表",
    a106: "総合消費者情報表",
    a107: "セクター消費者情報表",
    a108: "食事報告書",
    a109: "データ出力",
    a110: "開始日",
    a111: "開始日を入力してください。",
    a112: "終了日",
    a113: "終了日を入力してください。",
    a114: "データグラフ",
    a115: "(カードを使った回数)",
    a116: "日付を選択してください",
    a117: "消費金額統計",
    a118: "合計カード決済回数：",
  },
  //ゲストモジュール——ゲスト予約記録
  visitorBookingRecord: {
    a1: 'ロード中',
    a2: '訪問者予約記録',
    a3: 'カスタム条件1',
    a4: 'カスタム条件2',
    a5: '時間',
    a6: '初期の日付/時間選択',
    a7: '終了日時選択',
    a8: '検索',
    a9: 'ゲスト予約記録リスト',
    a10: '日または時間を選択してください',
    a11: 'データ',
    a12: 'ゲストの名前',
    a13: 'ゲストコール',
    a14: '訪問先/会社',
    a15: 'バイト状態',
    a16: '移動状態',
    a17: '来訪時間の予約',
    a18: '訪問終了時間',
    a19: '訪問事由',
    a20: '说明を訪れ',
    a21: '客ナンバー',
    a22: '対象者番号',
    a23: '人の名前',
    a24: 'クリース・アントネッティ人所属',
    a25: '来訪者数',
    a26: '申し込み期間',
    a27: '注文番号',
    a28: 'インタビュイー電話',
    a29: '详细',
    a30: 'アクセスキャンセル',
    a31: '未アクセス',
    a32: '訪問中',
    a33: 'アクセス完了',
    a34: '訪問失約',
    a35: '未訪問先',
    a36: '訪れた',
    a37: 'もう去りました',
    a38: '終わりました',
    a39: '商談',
    a40: '行政事務',
    a41: '技術交流',
    a42: '市場調査',
    a43: '面接',
    a44: '配達',
    a45: '補修',
    a46: '臨時職員',
    a47: 'その他',
    a48: '操作',
    a49: 'キャンセル',
    a50: '選択操作',
    a51: '訪問者情報',
    a52: '訪問者情報',
    a53: 'すべて（訪問事由）',
    a54: 'すべて（移動先状態）',
    a55: 'すべて（製造オーダステータス）',
    a56: 'すべて（認証タイプ）'
  },

  //訪問者モジュール——訪問者人員記録
  visitorPeopleRecord: {
    a1: 'ロード中',
    a2: '訪問者の記録',
    a3: 'カスタム条件1',
    a4: 'カスタム条件2',
    a5: '時間',
    a6: '初期日時選択',
    a7: '終了日/時間選択',
    a8: '検索',
    a9: '訪問者記録リスト',
    a10: '日時を選択してください',
    a11: '条データ',
    a12: 'ゲストネーム',
    a13: '訪問者電話',
    a14: 'ゲストユニット/会社',
    a15: '作業表状態',
    a16: '移動先状態',
    a17: '予約訪問時間',
    a18: 'アクセス終了時間',
    a19: '認証タイプ',
    a20: '滞在時間',
    a21: 'ゲストコード',
    a22: '訪問者番号',
    a23: '訪問者名',
    a24: '訪問者の所属',
    a25: 'お電話です',
    a26: '申し込み時間',
    a27: '注文番号',
    a28: 'お電話です',
    a29: '詳細',
    a30: 'アクセスキャンセル',
    a31: '未アクセス',
    a32: '訪問中',
    a33: 'アクセス完了',
    a34: '訪問失約',
    a35: '未訪問先',
    a36: '訪れた',
    a37: 'もう去りました',
    a38: '終わりました',
    a39: 'カード認証',
    a40: '掌形操作',
    a41: 'カード認証+指静脈認証',
    a42: 'カード認証+指紋認証',
    a43: '指静脈認証',
    a44: '指紋認証',
    a45: 'bluetooth認証',
    a46: 'テンキー認証',
    a47: '身分証明書',
    a48: 'qrコード認証',
    a49: '顔認証',
    a50: '滞在訪問者のみ表示',
    a51: 'クリア',
    a52: '選択動作',
    a53: '入門設備',
    a54: '外出設備',
  },

  //ゲストモジュール——将来のゲスト統計記録
  visitorFutureRecord: {
    a1: 'ロード中',
    a2: '未来の訪問者統計記録',
    a3: 'オブジェクトを表示',
    a4: '表示項目',
    a5: '時間',
    a6: '初期の日付/時間選択',
    a7: '終了日時選択',
    a8: '検索',
    a9: 'ゲスト予約記録リスト',
    a10: '日または時間を選択してください',
    a11: 'データ',
    a12: '時間',
    a13: '訪問者数',
    a14: '何度も',
    a15: '訪数',
    a16: '数を離れる',
    a17: '完了数',
    a18: '滞在数',
    a19: 'キャンセル数',
    a20: '約束を破る数',
    a21: '表示オブジェクトを選択してください',
    a22: '対象者番号',
    a23: '人の名前',
    a24: 'インタビューアー所属',
    a25: '来訪者数',
    a26: '申し込み期間',
    a27: 'これから一週間',
    a28: '未来月',
    a29: 'テナント',
    a30: 'ゲスト名',
    a31: '訪問先/会社',
  },

  //ゲストモジュール——ゲスト統計表
  visitorStatisticalReport: {
    a1: 'ロード中',
    a2: 'ゲスト統計表',
    a3: 'オブジェクトを表示',
    a4: '表示項目',
    a5: '時間',
    a6: '初期の日付/時間選択',
    a7: '終了日時選択',
    a8: '検索',
    a9: '訪問先/会社',
    a10: 'ゲスト名',
    a11: 'データ',
    a12: '時間',
    a13: '訪問者数',
    a14: '何度も',
    a15: '訪数',
    a16: '数を離れる',
    a17: '完了数',
    a18: '滞在数',
    a19: 'キャンセル数',
    a20: '約束を破る数',
    a21: 'ゲスト携帯電話番号',
    a22: 'ゲストタイプ',
    a23: 'インタビュイー所属',
    a24: '人の名前',
    a25: '対象者コード',
    a26: '人の携帯電話番号',
    a27: '年度統計',
    a28: '月間統計',
    a29: '日度統計',
    a30: 'テナント',
  },

  //ゲストモジュール——ゲスト情報
  visitorRecord: {
    a1: 'ロード中',
    a2: 'ゲスト情報管理',
    a3: 'カスタム条件',
    a4: '追加',
    a5: '修正',
    a6: '削除',
    a7: '操作',
    a8: '検索',
    a9: 'ゲスト情報リスト',
    a10: '日または時間を選択してください',
    a11: 'データ',
    a12: 'ゲストタイプ',
    a13: 'ゲスト名',
    a14: 'ゲスト携帯電話番号',
    a15: '訪問者証明タイプ',
    a16: '訪問者登録番号',
    a17: '訪問先/会社',
    a18: 'ビジター所属部門',
    a19: 'ゲスト職務',
    a20: 'ゲスト固定番号',
    a21: 'ゲストwechat ',
    a22: 'ゲストアドレス',
    a23: '日',
    a24: '備考',
    a25: '成功',
    a26: '失敗',
    a27: '訪問者の名前を入力してください',
    a28: '訪問者の携帯番号を入力してください'
  },

  //ゲストモジュール——ゲストブラックリスト情報
  visitorBlacklist: {
    a1: 'ロード中',
    a2: '訪問者ブラックリスト管理',
    a3: 'カスタム条件',
    a4: '追加',
    a5: '修正',
    a6: '削除',
    a7: '操作',
    a8: '検索',
    a9: '訪問者ブラックリスト',
    a10: '日または時間を選択してください',
    a11: 'データ',
    a12: 'ゲストの名前',
    a13: 'ゲスト携帯電話番号',
    a14: '訪問者証明タイプ',
    a15: '訪問者登録番号',
    a16: '編集日',
    a17: '備考',
    a18: '成功',
    a19: '失敗',
    a20: 'ブラックリストをアップロードする',
    a21: 'ゲストブラックリスト情報のインポートに失敗しました。',
    a22: '本当にこの情報を削除しますか？',
  },

  //ゲストモジュール——人員を上下に配置する
  upperAndLowerSetting: {
    a1: '人は上下に配置する',
    a2: '番号:',
    a3: '人員情報表',
    a4: '上升者表',
    a5: '人の顔',
    a6: '人の番号',
    a7: '名前',
    a8: '所属',
    a9: '上の名前',
    a10: 'メールするかどうか',
    a11: '微信を発信するかどうか',
    a12: '下の名前',
    a13: '上級者を選ぶ',
    a14: '下の人を選ぶ',
    a15: '削除',
    a16: '全員削除',
    a17: '設定',
    a18: '成功',
    a19: '失敗',
    a20: '箇条データ',
    a21: '検索',
    a22: '定義条件',
    a23: 'ローディング中....',
    a24: '条件を選択してください',
    a25: '上級者名',
    a26: '下位者名'
  },

  //ゲストモジュール——ゲストルール設定
  visitorRuleSetting: {
    a1: '微信とドッキングするか',
    a2: ' wechatドッキングURL',
    a3: '通行可能時間の前倒し/遅延値',
    a4: '訪問者通行許可パターン',
    a5: '訪問者の権限によって',
    a6: '通行パターンに応じて',
    a7: '訪問者通行パターン',
    a8: '削除',
    a9: '通行モード選択',
    a10: 'ゲスト認証モード',
    a11: '設定',
    a12: '通行モード選択',
    a13: 'ゲストルール設定',
    a14: '成功',
    a15: '失敗',
    a16: 'は',
    a17: 'いいえ',
    a18: '通行パターン',
    a20: '箇条データ',
    a21: '検索',
    a22: '定義条件',
    a23:'訪問者情報の微信同期を行うことを確認しますか？',
    a24:'訪問者のブラックリストの微信同期を行うことを確認しますか？',
    a25:'辞書パラメータの微信同期を行うことを確認しますか？',
    a26:'ゲスト情報ウィーチャット同期設定',
    a27:'ゲストブラックリスト微信同期設定',
    a28:'辞書パラメータ微信同期設定',
  },

  //ゲストモジュール——カード
  visitor: {
    a1: '時間:',
    a2: 'ゲスト:',
    a3: 'された:',
    a4: '事由:',
    a5: '状態:',
    a6: '順豊物流',
    a7: 'フロント係',
    a8: 'ミーティング',
    a9: '来ていない',
    a10: '普通の訪問者',
    a11: 'タイプ:',
  },

  // 访客模块--访客详情窗口
  //ビジターモジュール：ビジター詳細ウィンドウ
visitorDetailsModel:{
  a1:'基本情報',
  a2:'訪問者情報',
  a3:'申請者情報',
  a4:'訪問者情報',
  a5:'処理情報へのアクセス',
  a6:'オーダー番号',
  a7:'受注ステータス',
  a8:'訪問状態',
  a9:'アクセス時間',
  a10:'申請時間',
  a11:'訪問者タイプ',
  a12:'訪問事由',
  a13:'アクセスコメント',
  a14:'その他の注釈',
  a15:'管理コード',
  a16:'名前',
  a17:'所属',
  a18:'携帯番号',
  a19:'証明書タイプ',
  a20:'証明書番号',
  a21:'訪問者情報',
  a22:'認証情報',
  a23:'通行権限',
  a24:'操作',
  a25:'発生時間',
  a26:'動作タイプ',
  a27:'動作情報',
  a28:'オペレータコード',
  a29:'オペレータ名',
  a30:'注釈説明',
  a31:'訪問者コード',
  a32:'認証方式',
  a33:'通行モード',
  a34:'通行時間',
  },

  //データ比較トレンドグループ一覧
  dataCompareTrendList: {
    a1: 'ローディング中....',
    a2: 'CSV導出',
    a3: 'リロード',
    a4: '日付け1',
    a5: '本データ',
    a6: 'データの種類',
    a7: 'デバイス名',
    a8: '信号名',
    a9: '単位',
    a10: '時/日',
    a11: '状態/30分',
  },
  sitecard: {
    a1: 'これまでの14日間の電気量',
    a2: 'これまでの14日間の水量',
    a3: 'フロア/アクセス数',
    a4: 'フロア/エアコン数',
    a5: '場所/装置数',
    a6: '種類/装置数',
    a7: 'エリア/電気回線数',
    a8: '出入口設備数',
    a9: '今月の統計',
    a10: 'フロア/設備数',
    a11: '空調運転数',
    a12: '1時間当たりの通行認証数',
    a13: '設備通行認証数',
    a14: '過去14日の通行認証数',
  },
  carThroughrecords: {
    a0: 'ローディング中....',
    a1: '車両記録',
    a2: '名前',
    a3: 'ナンバープレート',
    a4: '初期日時選択',
    a5: '終了日時選択',
    a6: 'クエリ',
    a7: '記録リスト',
    a8: '日時を選択してください',
    a9: '車両識別画像',
    a10: '注釈を追加します',
    a11: '選択操作',
    a12: '注釈',
    a13: 'キャンセル',
    a14: '確認',
    a15: '内容を入力してください....',
    a16: '条データ',
    a17: '発生日',
    a18: '車のタイプ',
    a19: '名前',
    a20: 'ナンバープレート',
    a21: '体温',
    a22: 'デバイスグループ名',
    a23: '設備名',
    a24: '信号/端末名',
    a25: '建物名',
    a26: '注釈',
    a27: '操作',
    a28: '名前を入力してください',
    a29: '車のナンバーを入力してください',
    a30: '場所',
    a31: 'マスク',
    a32: 'ナンバープレートの写真の読み取りに失敗しました。後で試してみてください。',
    a33: '管理コード',
    a34: 'ナンバープレート識別記録',
    a35: '車の動き',
    a36: 'をクリックして拡大画像をクリックして、',
    a37: 'をクリックして隠し画像をクリックして、',
  },
  //コントローラ管理ページ
  controller: {
    a1: 'コントローラ管理',
    a2: '建物の情報',
    a3: '検索',
    a4: '端末情報',
    a5: 'デバイス情報記録',
    a6: 'コントローラ状態管理',
    a7: 'コントローラ状態送信記録',
    a8: '運行状態',
    a9: '注釈',
    a10: 'コントローラ動作状態設定',
    a11: 'デバイスタイプ:',
    a12: '未起働',
    a13: 'テスト',
    a14: '運行',
    a15: '止まれ',
    a16: '更新成功',
    a17: '更新失敗',
    a18: '正常',
    a19: '異常',
    a20: '未知',
    a21: '確認します',
    a22: '取り消します」',
    a23: '通信状態を取得します',
    a24:'コントローラの通信状態をリアルタイムで取得するには待ち時間が必要です',
    a25: 'コントローラーです',
    a26: 'IPアドレスです',
    a27: '通信状態です',
    a28: '検査日です',
    a29: '個人番号です',
    a30:'サイトログ読み出し管理です',
    a31:'読みません',
    a32:'読みます',
    a33:'保存します',
    a34:'設定します',
    a35:'キャンセルです',
    a36:'パラメータタイプ」です',
    a37:'読み取り状態」です',
    a38:'読み取り周波数(分/回)',
    a39:'最後の読み取り時間 です',
    a40:'操作 です',
    a41:'読み出し周波数入力パラメータエラー」です',
    a42:'整数を入力してくださいます;入力値が−1の場合は読み出しをしない状態に設定します',
    a43:'LAST ID入力パラメータエラー」です',
    a44:'正の整数を入力してくださいます;入力値が0の場合は、1の記録を読み取ります',
    a45:'修正情報の更新を確認しますか?',
  },
  // 客户中心
  customerCenter: {
    a1: "ロード中....",
    a2: "顧客センター",
    a3: "編集",
    a4: "建造物の一覧",
    a5: "顧客情報設定",
    a6: "キャンセル",
    a7: "确认",
    a8: "顧客名",
    a9: "お客様名を入力してください",
    a10: "别名",
    a11: "別名を入力してください",
    a12: "連絡先1",
    a13: "連絡先を入力してください",
    a14: "連絡先2",
    a15: "連絡先",
    a16: "連絡先を入力してください",
    a17: "アドレス",
    a18: "住所を入力してください",
    a19: "機能サイト",
    a20: "カレンダー設定",
    a21: "祝日を追加する",
    a22: "サイト情報",
    a23: "サイト名",
    a24: "别名",
    a25: "管理コード",
    a26: "連絡先",
    a27: "建築コード",
    a28: "GWの名称",
    a29: "フロア情報",
    a30: "修正",
    a31: "契約情報",
    a32: "契約がない",
    a33: "準備中",
    a34: "サービス中",
    a35: "建物情報の設定",
    a36: "建物の名称",
    a37: "建物名を入力してください",
    a38: "描写",
    a39: "記述を入力してください",
    a40: "サイト情報設定",
    a41: "サイト名を入力してください",
    a42: "サイトの別名",
    a43: "祝日の設置",
    a44: "祝日の名称",
    a45: "祝日の名前を入力してください",
    a46: "日付",
    a47: "期日指定",
    a48: "曜日指定",
    a49: "重複",
    a50: "期限指定",
    a51: "お祭りタイプ",
    a52: "休日",
    a53: "特日1",
    a54: "特日2",
    a55: "特日3",
    a56: "特日4",
    a57: "カレンダーの名称が変わる",
    a58: "カレンダーの名称",
    a59: "カレンダー名を入力してください",
    a60: "フロア情報更新",
    a61: "フロア・テナント名",
    a62: "フロア・テナント別名",
    a63: "テナント住所",
    a64: "テナント連絡先1",
    a65: "テナント連絡先2",
    a66: "契約情報の更新",
    a67: "契約がない",
    a68: "準備中",
    a69: "サービス中",
    a70: "01月",
    a71: "02月",
    a72: "03月",
    a73: "04月",
    a74: "05月",
    a75: "06月",
    a76: "07月",
    a77: "08月",
    a78: "09月",
    a79: "10月",
    a80: "11月",
    a81: "12月",
    a82: "01日",
    a83: "02日",
    a84: "03日",
    a85: "04日",
    a86: "05日",
    a87: "06日",
    a88: "07日",
    a89: "08日",
    a90: "09日",
    a91: "10日",
    a92: "11日",
    a93: "12日",
    a94: "13日",
    a95: "14日",
    a96: "15日",
    a97: "16日",
    a98: "17日",
    a99: "18日",
    a100: "19日",
    a101: "20日",
    a102: "21日",
    a103: "22日",
    a104: "23日",
    a105: "24日",
    a106: "25日",
    a107: "26日",
    a108: "27日",
    a109: "28日",
    a110: "29日",
    a111: "30日",
    a112: "31日",
    a113: "1週目",
    a114: "2週目",
    a115: "3週目",
    a116: "4週目",
    a117: "5週目",
    a118: "月曜日",
    a119: "火曜日",
    a120: "水曜日",
    a121: "木曜日",
    a122: "金曜日",
    a123: "土曜日",
    a124: "日曜日",
    a125: "毎週",
    a126: "毎月",
    a127: "操作",
    a128: "サービス名",
    a129: "サービス状態",
    a130: "カレンダー名を入力してください",
    a131: "祝日削除?",
    a132: "祝日の削除を確定する",
    a133: "違う状態を選んでください",
    a134: "異なる状態を選択して修正してください",
    a135: "検索テキストを入力する",
    a136: "契約情報の更新に成功しました。",
    a137: "契約情報の更新に失敗しました。",
  },
  // 用户设置
  UserSettings: {
    a1: "ロード中....",
    a2: "ユーザー設定",
    a3: "ユーザータイプ",
    a4: "システム利用者",
    a5: "顧客ユーザー。",
    a6: "顧客",
    a7: "ユーザーグループを選択してください",
    a8: "ユーザグループID",
    a9: "加入者上限数",
    a10: "ユーザー数",
    a11: "編集",
    a12: "権限を持つサイト",
    a13: "サイトを設置する",
    a14: "サイトID",
    a15: "所属する建物",
    a16: "ユーザー一覧",
    a17: "新規加入者",
    a18: "ユーザー名",
    a19: "級",
    a20: "アカウントにログインする",
    a21: "口座の有効期間",
    a22: "パスワード有効日数",
    a23: "認証コード",
    a24: "新規ユーザーグループ",
    a25: "キャンセル",
    a26: "确认",
    a27: "ユーザーグループ名:",
    a28: "アクセス許可ユーザグループ選択",
    a29: "HBS管理者ユーザグループを選択する",
    a30: "ビルオーナーを選ぶユーザーグループ",
    a31: "元のユーザグループのコピーを選択する",
    a32: "サイトの設置",
    a33: "表示",
    a34: "操作",
    a35: "設置",
    a36: "ユーザー情報の更新",
    a37: "ユーザーグループ名",
    a38: "ユーザーグループ名を入力してください",
    a39: "加入者上限数",
    a40: "ユーザー上限数を入力してください",
    a41: "アカウントID",
    a42: "アカウントIdを入力してください",
    a43: "アカウント",
    a44: "アカウントを入力してください",
    a45: "验证ヤード",
    a46: "認証コードを入力してください",
    a47: "口座の有効期間",
    a48: "アカウントの有効期限を入力してください",
    a49: "メールアドレス",
    a50: "メールアドレスを入力してください",
    a51: "パスワードをリセットする",
    a52: "パスワード1",
    a53: "パスワード1を入力してください",
    a54: "パスワード2",
    a55: "パスワード2を入力してください",
    a56: "ユーザー上限数を入力してください",
    a57: "数字を入力してください",
    a58: "現在のユーザー数が20未満より大きい数字を入力してください",
    a59: "ユーザーグループ名を入力してください",
    a60: "アカウントIDは空にできない",
    a61: "アカウントは空にできない",
    a62: "captchaを空にすることはできない",
    a63: "パスワードの有効期限は空にしない",
    a64: "パスワードは空欄にしない",
    a65: "正しいメールアドレスを入力してください",
    a66: "メールボックスは空にしてはいけない",
    a67: "ビルのオーナー",
    a68: "テナント",
    a69: "ビルの管理人",
    a70: "権限サイト設置",
    a71: "サイトID",
    a72: "所属する建物",
    a73: "ユーザグループ権限",
    a74: "メニューサービス権限",
    a75: "サービス:",
    a76: "権限外サービスメニュー",
    a77: "を追加する↓",
    a78: "権限内サービスメニュー",
    a79: "を削除↑",
    a80: "リセット",
    a81: "確定",
    a82: "サービス",
    a83: "メニュー",
    a84: "表示",
    a85: "更新成功",
    a86: "更新失敗",
    a87: "永久に"
  },

  //一般的な記述
  general: {
    a1: 'ロード中…」と',
    a2: '設定',
    a3: '追加',
    a4: '修正',
    a5: '削除',
    a6: '成功',
    a7: '失敗',
    a8: '確定',
    a9: 'キャンセル',
    a10: '編集',
    a11: '更新',
    a12: '検索',
    a13: '確定要削除?',
    a14: '削除する項目を選んでください',
    a15: '空にしてはならない',
    a16: 'オプション',
    a17: '値',
  },
  meeting: {
    m1: '会議管理',
    m2: 'は前もって終了します',
    m3: '遅延終了',
    m4: '正常終了',
    m5: '審査が通らない',
    m6: '会議キャンセル',
  },
  personnelIOStatistics: {
    a1: "人員統計",
    a2: "種別",
    a3: "所属会社",
    a4: "所属会社が入力されていません。",
    a5: "国籍",
    a6: "国籍が入力されていません。",
    a7: "名前",
    a8: "名前が入力されていません。",
    a9: "開始期間",
    a10: "終了期間",
    a11: "グループバイ",
    a12: "クエリー",
    a13: "人員出入り統計",
    a14: "人員出入り記録",
    a15: "ダウンロード",
    a16: "在室人員り統計",
    a17: "在室人員り記録",
  },

    edge:{
      edge001:'インテリジェントゲートウェイ管理',
      edge002:'種類',
      edge003:'ゲートウェイ名',
      edge004:'所属する場所',
      edge005:'ゲートウェイIP',
      edge006:'ポート',
      edge007:'アカウント',
      edge008:'パスワード',
      edge009:'アクセスURL',
      edge010:'状態検出URL',
      edge011:'アラームサイクル',
      edge012:'状態検出モード',
      edge013:'モニタモード',
      edge014:'説明',
      edge015:'コメント',
      edge016:'建物コード',
      edge017:'建物名稱',
      edge018:'接続状態',
      edge019:'運転状態',
      edge020:'接続時間',
      edge021:'収集設備情報',
      edge022:'バージョン番号',
      edge023:'更新日',
      edge024:'操作',
      edge025:'スマートゲートウェイの追加',
      edge026:'スマートゲートウェイの編集',
      edge027:'ソフトウェアのアップグレード',
      edge028:'ソフトウェアファイルのアップロード',
      edge029:'ゲートウェイ名を入力してください',
      edge030:'所属する場所を入力してください',
      edge031:'ゲートウェイIPを入力してください',
      edge032:'ポートを入力してください',
      edge033:'ゲートウェイアカウントを入力してください',
      edge034:'ゲートウェイパスワードを入力してください',
      edge035:'ゲートウェイアクセスURLを入力してください',
      edge036:'状態検出URLを入力してください',
      edge037:'アラームサイクルを入力してください',
      edge038:'状態検出モードを入力してください',
      edge039:'モニタモードを入力してください',
      edge040:'説明を入力してください',
      edge041:'コメントを入力してください',
      edge042:'スマートゲートウェイ設定',
      edge043:'ゲートウェイ名の長さは1～30でなければなりません',
      edge044:'所属する場所の長さは1～30でなければなりません',
      edge045:'ゲートウェイIPの長さは1～30でなければなりません',
      edge046:'ゲートウェイアカウントの長さは6～30でなければなりません',
      edge047:'ゲートウェイパスワードの長さは6～20でなければなりません',
      edge048:'ゲートウェイアクセスURLの長さは1～200でなければなりません',
      edge049:'状態検出URLの長さは1～200でなければなりません',
      edge050:'説明の長さは1～800でなければなりません',
      edge051:'コメントの長さは1～200でなければなりません',
      edge052:'設定したスマートゲートウェイ情報を保存することを確認しますか?',
      edge053:'選択したスマートゲートウェイ情報を削除することを確認しますか?',
      edge054:'アップグレード',
      edge055:'ファイルの種類',
      edge056:'ファイルの選択',
      edge057:'アップロード中',
      edge058:'アップロード',
      edge059:'アップロード成功',
      edge060:'アップロードに失敗しました',
      edge061:'ゲートウェイ/コントローラリスト',
      edge062:'ファイルリスト',
      edge063:'未稼働',
      edge064:'試験中にする',
      edge065:'監視中にする',
      edge066:'作業発報',
      edge067:'集中発報',
      edge068:'休止',
      edge069:'削除発報',
      edge070:'建物番号を入力してください',
      edge071:'ゲートウェイタイプを選択してください',
      edge072:'コンフィギュレーション',
      edge073:'ゲートウェイ番号の構成',
      edge074:'元GWC番号',
      edge075:'元FLC/FMC番号',
      edge076:'新しいGWC番号',
      edge077:'新FLC/FMC番号',
      edge078:'アクションコマンド',
      edge079:'コマンドの種類',
      edge080:'ゲートウェイ/コントローラの選択',
      edge081:'アクションコマンドの選択',
      edge082:'ファイルの選択',
      edge083:'確認',
      edge084:'アップグレード',
      edge085:'ファイル送信',
      edge086:'ファイルの送信とアップグレード',
      edge087:'ファイル削除',
      edge088:'コマンドタイプを選択してください。または「OK」ボタンをクリックして対応コマンド操作を行ってください。',
      edge089:'アップグレードプログラムを行うには、「OK」ボタンをクリックしてください。',
      edge090:'ファイルを選択してください',
      edge091:'アップグレードプログラムを準備するには、プログラムファイルを選択します。',
      edge092:'新しいGWC番号を入力してください',
      edge093:'新しいFLC/FMC番号を入力してください',
      edge094:'新しいGWC番号の長さは8ビットでなければなりません。',
      edge095:'FLC番号の範囲は101-140でなければなりません。',
      edge096:'FMC番号の範囲は17-19でなければなりません。',
      edge097:'ファイル管理',
    },
    linkage:{
      chooseSignal:'信号点を選んでください',
      hour:"小さな時間",
      compare:'比較する',
      critical:'臨界値',
      greater:'より大きい',
      less:'より小さく',
      equal:'イコール',
      index:'条件番号',
      condition:'条件',
      relation:'条件間の関係',
      output:'アウトプット',
      addRuleAndInput:'ルール入力条件項目を追加',
      addruleCondition:'ルール条件をつける',
      addCondition:'条件をつける',
      and:"と",
      or:"または",
    },
    peopleDevice:{
        a1:"デバイス通信失敗情報です",
        a2:"データを送り続けます",
        a3:"分かりました",
        a4:"継続して落ちるかもしれませんが、継続して落ちるデータを確定しますか? ",
    },
    faceErrorMessage:{
        a1:"人の顔は失敗します",
        a2:"分かりました",
    },
}