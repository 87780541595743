import request from '@/utils/request';

// 获取导航栏接口
export function getstairmenu() {
    return request({
        url: '/bivale/custom/client/infos/list',
        method: 'get'
    });
}

// get请求
export function getdata(url, params) {
    return request({
        url: url,
        method: 'get',
        params
    });
}
export function remarks(parameter) {
    return request({
        url: 'bivale/alarm/history/comment/update',
        method: 'post',
        data: parameter
    });
}

// 取后端版本号 added by li_yj on 2023-03-30
export function getVersion() {
    return request({
        url: '/vs/version',
        method: 'get',
    });
}