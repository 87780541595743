// 谷峰能效
module.exports = {
    peakValleyAnalyse:{
        title:'分段能耗',
        electricity: '用电量',
        power:'功率',
        price:'电费',
        periodOfTimeName:'时间段名称',
        year: '年',
        month: '月',
        day: '日',
        startMonth: '自定义起始月',
        inquire: '查询',
        peak: '尖峰',
        high: '高峰',
        flat: '平段',
        trough: '低谷',
        accumulateElectricity:'累积用电量Kwh',
        proportion:'占比%',
        averagePower:'平均功率Kwh',
        maximumPower:'最大功率Kwh',
        yuan:'元',
      },
}