module.exports = {
    distribution:{
        a1: 'Display Module',
        a2: 'Please select a bound user',
        a3: 'Bind User',
        a4: 'Page refresh time (minutes)',
        a5: 'Please enter the page refresh time in minutes',
        a6: 'Title',
        a7: 'Please enter a title',
        a8: 'Theme',
        a9: 'Please select a theme',
        a10: 'Publish information',
        a11: 'Equipment KEY',
        a12: 'Please enter Equipment KEY',
        a13: 'Access Address',
        a14: 'Please enter the access address',
        a15: 'Service Port',
        a16: 'Please enter the service port',
        a17: 'Service IP',
        a18: 'Please enter the service IP',
        a19: 'Equipment Port',
        a20: 'Please enter the Equipment port',
        a21: 'Equipment IP',
        a22: 'Please enter the Equipment IP',
        a23: 'Configuration information',
        a24: 'Copy Link',
        a25: 'Equipment Access Code',
        a26: 'Screen Type',
        a27: 'Equipment Type',
        a28: 'Belonging To',
        a29: 'Name',
        a30: 'Equipment Number',
        a31: 'Basic Information',
        a32: 'Normal large screen',
        a33: 'Independent deployment of service screen',
        a34: 'Ordinary small screen',
        a35: 'Flat screen',
        a36: 'Face terminal screen',
        a37: 'Advertising screen',
        a38: 'Theme 1',
        a39: 'Theme 2',
        a40: 'Please enter a title',
        a41: 'Please enter the data refresh time',
        a42: 'Please select the display module',
        a43: ' Successfully copied!',
        a44: 'Are you sure you want to save the set publishing information parameters?',
        a45: 'Are you sure you want to save the set configuration information?',
        a46: 'Add Equipment',
        a47: 'Please enter a Equipment name!',
        a48: 'Please select screen type',
        a49: 'Controller',
        a50: 'Please select a controller',
        a51: 'The Equipment name length must be between 1 and 30',
        a52: 'Facial terminal information',
        a53: 'Image port',
        a54: 'Background image',
        a55: 'Delete all',
        a56: 'Delete',
        a57: 'Select image',
        a58: 'Upload images',
        a59: 'Equipment site diagram',
        a60: 'Read image',
        a61: 'Read all images',
        a62: 'Only images in png and jpg formats can be uploaded!',
        a63: 'The image size cannot exceed 300KB!',
        a64: 'Are you sure you want to save the set information?',
        a65: 'Are you sure you want to upload the selected image?',
        a66: 'Are you sure you want to delete this image?',
        a67: 'Are you sure you want to delete all images?',
        a68: 'Equipment settings',
        a69:'',
    }
}