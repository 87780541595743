// 测速模块
module.exports = {
    velocityMeasurement:{
        a1: 'ロード中...',
        a2: '車両速度超過統計',
        a3: 'デバイス',
        a4: '設備詳細',
        a5: '閉じる',
        a6: 'デバイス名',
        a7: '速度測定記録',
        a8: '速度測定点',
        a9: 'ナンバープレート',
        a10: '速度：',
        a11: '照会',
        a12: 'ナンバープレート番号',
        a13: 'ナンバープレートの色：',
        a14: '車体色：',
        a15: '速度測定場所：',
        a16: 'スナップ時間：',
        a17: '車速：',
        a18: '車両タイプ：',
        a19: '詳細',
        a20: 'ストライプデータ',
        a21: '車両画像',
        a22: 'スナップタイム',
        a23: '車両タイプ',
        a24: '進行方向',
        a25: '東->西',
        a26: '西->東',
        a27: '南->北',
        a28: '北->南',
        a29: '東南->西北',
        a30: '北西->南東',
        a31: '東北->南西',
        a32: '南西->東北',
        a33: '速度測定地点',
        a34: '速度',
        a35: 'ナンバープレート',
        a36: '操作',
        a37: 'データ統計',
        a38: '速度測定記録リスト',
        a39: '速度超過設定',
        a40: '確定',
        a41: '超速速度:',
        a42: '速度超過なし',
        a43: '速度超過',
    }
}