import Vue from 'vue'

// antd-modal组件拖拽指令 
Vue.directive('drag', (el, binding, vnode, oldVnode) => {
  // inserted (el, binding, vnode, oldVnode) {
  Vue.nextTick(() => {
    // console.log(el)
    const { visible } = vnode.componentInstance
    if (visible) {
      const dialogHeaderEl = el.querySelector('.ant-modal-header')
      const dragDom = el.querySelector('.ant-modal')
      dialogHeaderEl.style.cssText += ';cursor:move;'
      dragDom.style.cssText += ';padding: 0px;'

      // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
      const sty = (function () {
        if (window.document.currentStyle) {
          return (dom, attr) => dom.currentStyle[attr]
        } else {
          return (dom, attr) => getComputedStyle(dom, false)[attr]
        }
      })()

      dialogHeaderEl.onmousedown = (e) => {
        // 鼠标按下，计算当前元素距离可视区的距离
        const disX = e.clientX - dialogHeaderEl.offsetLeft
        const disY = e.clientY - dialogHeaderEl.offsetTop

        const screenWidth = document.body.clientWidth // body当前宽度
        const screenHeight = document.documentElement.clientHeight // 可见区域高度(应为body高度，可某些环境下无法获取)

        const dragDomWidth = dragDom.offsetWidth // 对话框宽度
        const dragDomheight = dragDom.offsetHeight// 对话框高度

        const minDragDomLeft = dragDom.offsetLeft
        const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth

        const minDragDomTop = dragDom.offsetTop
        const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight

        // 获取到的值带px 正则匹配替换
        let styL = sty(dragDom, 'left')
        let styT = sty(dragDom, 'top')

        // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
        if (styL.includes('%')) {
          // eslint-disable-next-line no-useless-escape
          styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100)
          // eslint-disable-next-line no-useless-escape
          styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100)
        } else {
          styL = +styL.replace(/\px/g, '')
          styT = +styT.replace(/\px/g, '')
        };

        document.onmousemove = function (e) {
          // 通过事件委托，计算移动的距离
          let left = e.clientX - disX
          let top = e.clientY - disY

          // 边界处理
          if (-(left) > minDragDomLeft) {
            left = -(minDragDomLeft)
          } else if (left > maxDragDomLeft) {
            left = maxDragDomLeft
          }

          if (-(top) > minDragDomTop) {
            top = -(minDragDomTop)
          } else if (top > maxDragDomTop) {
            top = maxDragDomTop
          }
          // 移动当前元素
          dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`
        }

        document.onmouseup = function (e) {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    }
  })
})

// 输入框输入格式：纯数字，开头数字不能是0。
Vue.directive('pureNumber', {
  bind(el) {
    const inputDom = el.querySelector(".ant-input")
    inputDom.onkeypress = (event) => {
      if (inputDom.value.length == 0) {
        return (!/^0/.test(String.fromCharCode(event.keyCode || event.which)));
      } else {
        return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which)));
      }
    };
    inputDom.oninput = () => {
      inputDom.value = inputDom.value
        .replace(/\D/ig, '')
        .replace(/^0{1,}/g, "")
    };
  },
});

// 输入框输入格式：禁止符号输入
Vue.directive('InputText', {
  bind(el) {
    const inputDom = el.querySelector(".ant-input")
    inputDom.onkeypress = (event) => {
      return (/[\w]/.test(String.fromCharCode(event.keyCode || event.which)));
    };
    inputDom.oninput = () => {
      inputDom.value = inputDom.value
        .replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, "")
        .replace(/^\s+|\s+$/g, "");
    };
  },
});

// 输入框输入格式：仅限数字和字母
Vue.directive('Input-NumberText', {
  bind(el) {
    const inputDom = el.querySelector(".ant-input")
    inputDom.onkeypress = (event) => {
      const value1 = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/;
      const value2 = /[^\x00-\xff]/;
      const value3 = /[, ]/;
      return (!value1.test(String.fromCharCode(event.keyCode || event.which))) &&
        (!value2.test(String.fromCharCode(event.keyCode || event.which))) &&
        (!value3.test(String.fromCharCode(event.keyCode || event.which)));
    };
    inputDom.oninput = () => {
      inputDom.value = inputDom.value
        .replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, "")
        .replace(/[^\x00-\xff]/g, "")
        .replace(/[, ]/g, "");
    };
  },
});

// 表格自适应指令
Vue.directive('table-size', {
  bind(el, binding) {
    const mainDom = el;
    const tableDom = el.getElementsByClassName("ant-table-thead")[0];
    let mainHeight = 0;
    let tableHeight = 0;
    let observer = new ResizeObserver(() => {
      if (mainDom.clientHeight !== mainHeight || tableDom.clientHeight !== tableHeight) {
        binding.value(binding.arg)
        mainHeight = mainDom.clientHeight;
        tableHeight = tableDom.clientHeight;
      }
    });
    observer.observe(mainDom);
    observer.observe(tableDom);
  },
});