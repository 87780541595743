// 空调设备页
module.exports = {
    airconditiondDevice:{
        a1: '加載中….',
        a2: '圖標',
        a3: '表格',
        a4: '展開',
        a5: '收起',
        a6: '批量設定',
        a7: '正常',
        a8: '异常',
        a9: '上下限警報',
        a10: '製冷',
        a11: '制熱',
        a12: '送風',
        a13: '抽濕',
        a14: '低風',
        a15: '中風',
        a16: '高風',
        a17: '詳情頁',
        a18: '關閉',
        a19: '確認',
        a20: '開關',
        a21: '空調運行模式',
        a22: '空調風速',
        a23: '溫度設定',
        a24: '開',
        a25: '關',
        a26: '室溫',
        a27: '維護或故障狀態',
        a28: '空調不可設定',
        a29: '關機狀態',
        a30: '空調模式不可設定',
        a31: '條數據',
        a32: '空調風速不可設定',
        a33: '風速',
        a34: '維護',
        a35: '設備名稱',
        a36: '設備狀態',
        a37: '開關',
        a38: '設定溫度',
        a39: '室內溫度',
        a40: '操作',
        a41: '批量設定失敗清單',
        a42: '請選擇空調設備',
        a43: '未選擇空調設備',
        a44: '選擇操作',
        a45: '設定',
        a46: '空調設定'
    }
}