export default [
    {
        path: '/homepage/monitoring', //视频模块--视频主页
        component: () => import('@/views/monitoring/monitoringLayout'),
        meta: { hidden: true },
        children: [
          {
            path: '/homepage/monitoring/live', //视频模块--设备页
            name: 'VID_SERVERLESS',
            component: () => import('@/views/monitoring/video'),
            meta: { hidden: true }
          },
          //警报 2.1
          {
            path: '/homepage/monitoring/alarm', //视频模块--警报页
            name: 'VID_ALARM',
            component: () => import('@/views/door/alarm'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/monitoring/cameraGr', //视频模块--摄像机组
            name: 'VID_CAMERA_GR',
            component: () => import('@/views/monitoring/cameraGroupSetting'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/monitoring/camera', //视频模块--摄像机
            name: 'VID_CAMERA',
            component: () => import('@/views/monitoring/cameraSetting'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/monitoring/down', //视频模块--插件下载
            name: 'VID_DOWNLOAD',
            component: () => import('@/views/monitoring/videoActiveX'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/monitoring/cameras', //通用设备模块----摄像机管理
            name: 'VID_ICON',
            component: () => import('@/views/monitoring/cameras'),
            meta: { hidden: true }
        },
        {
            path: '/homepage/monitoring/records', //通用设备模块--录像机管理
            name: 'VID_RECORDER',
            component: () => import('@/views/monitoring/recorder'),
            meta: { hidden: true }
        },
        ],
      },
]