// 清洁能源流向
module.exports = {
    cleanEnergyFlows:{    
        a1: 'Loading....',
        a2: 'Clean energy flow',
        a3: 'Power',
        a4: 'Carbon footprint',
        a5: 'Custom start month',
        a6: 'Query',
        a7: 'Please select criteria to query',
        a8: 'Metering point',
        a9: 'Carbon footprint'
    }
}