import ja_JP from "./modules/ja_JP"
// 日文
import energyFlow from './modules/energyFlow'
import Kanban from './modules/Kanban'
import analyseKPISet from './modules/analyseKPISet'
import analyseKPI from './modules/analyseKPI'
import cleanEnergyAnalysis from './modules/cleanEnergyAnalysis'
import cleanEnergyFlows from './modules/cleanEnergyFlows'
import cleanEnergySetting from './modules/cleanEnergySetting'
import weather from './modules/weather'
import automaticReport from './modules/automaticReport'
import energyConsumptionReportSettings from './modules/energyConsumptionReportSettings'
import topologySet from './modules/topologySet'
import equipmentConvert from './modules/equipmentConvert'
// 门禁模块 -- 设备页
import doorequipment from './modules/doorequipment' 
// 门禁模块 -- 详情页
import doorDetails from './modules/doorDetails'
// 门禁模块--设置--通行模块设置--通行权限人员信息组件 lht 2023-3-31
import accessStaffInfo from './modules/accessStaffInfo'
// 客户统计信息
import customerStatistics from './modules/customerStatistics'
// 信息发布模块
import distribution from './modules/distribution'
// 操作记录
import userOperationHistory from './modules/userOperationHistory'
// 空调设备页
import airconditiondDevice from './modules/airconditiondDevice'
// 道路测速
import velocityMeasurement from './modules/velocityMeasurement'

export default {
    ...ja_JP,
    ...energyFlow,
    ...Kanban,
    ...analyseKPISet,
    ...analyseKPI,
    ...cleanEnergyAnalysis,
    ...cleanEnergyFlows,
    ...cleanEnergySetting,
    ...weather,
    ...automaticReport,
    ...energyConsumptionReportSettings,
    ...topologySet,
    ...equipmentConvert,
    // 门禁模块 -- 设备页
    ...doorequipment,
    // 门禁模块 -- 详情页
    ...doorDetails,
    // 门禁模块--设置--通行模块设置--通行权限人员信息组件 lht 2023-3-31
    ...accessStaffInfo,
    // 客户统计信息
    ...customerStatistics,
    // 信息发布模块
    ...distribution,
    // 操作记录
    ...userOperationHistory,
    // 空调设备页
    ...airconditiondDevice,
    // 道路测速
    ...velocityMeasurement,
}