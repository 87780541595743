<!--能效看板按钮-->
<template>
  <div id="bulletinBoard" :title="$t('sidebar.a15')" @click="goToEnergyManagement">
    <router-link to="/energyManagement">
      <div :class="bgimage"></div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "bulletinBoard",
  computed: {
    bgimage(){
      let route = this.$route.matched[1].path;
      console.log(route,route == "/energyManagement");
      if (route == "/energyManagement") {
        return 'selectedImage'
      } else {
        return 'normalImage'
      }
    }
  },
  methods:{
    goToEnergyManagement(){
      this.$router.push({ path: '/energyManagement' })
    }
  },
};
</script>

<style scoped>
#bulletinBoard {
  width: 80px;
  height: 80px;
  flex-shrink:0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
}
.normalImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.2);
  border-radius: 50%;
  background-color: #f2f4f7;
  background-image: url('../../../../public/images/sidebar/energy_normal.png');
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: 12px 12px;
  transition: 400ms ease-out;
}
.normalImage:hover{
  box-shadow: 0 0px 7px #7682ce;
}
.selectedImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-radius: 50%;
  background-color: #7682ce;
  background-image: url('../../../../public/images/sidebar/energy_selected.png');
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: 12px 12px;
  transition: 400ms ease-out;
}
</style>