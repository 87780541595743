// 看板 -- 英文
module.exports = {
    Kanban:{
        a1:'Annual ',
        a2:'Monthly ',
        a3:'Daily ',
        a4:'Month on month',
        a5:'Real time power',
        a6:'Annual KPI',
        a7:'Monthly KPI',
        a8:'Carbon emission',
        a9:'Clean energy power generation',
        a10:'Kanban settings',
        a11:'real time power',
        a12:'Total power',
        a13:'Display',
        a14:'Data type',
        a15:'Display level',
        a16:'Associated site',
        a17:'Site address',
        a18:'Data comparison',
        a19:'Use of segmentation rules',
        a20:'Use site segmentation rules',
        a21:'Unified use of building segmentation rules',
        a22:'Unified use of customer segmentation rules',
        a23:'When "use site segmentation rule" is selected, if no segmentation rule is set for the corresponding site, the segmentation rule of the building will be used. When "day" is selected as the data type; Unified use of building segmentation rules. ',
        a24:'When "use site segmentation rule" is selected, if no segmentation rule is set for the corresponding site, the segmentation rule of the customer will be used. When "day" is selected as the data type; Unified use of customer segmentation rules. ',
        a25:'monthly quick select all settings',
        a26:'Quick select all settings per hour',
        a27:'Quick selection',
        a28:'Severe warning',
        a29:'General warning',
        a30:'Recovered',
        a31:'Not recovered',
        a32:'Return',
        a33:'Daily carbon emissions',
        a34:'Daily energy consumption',
        a35:'Attention',
        a36:'KPI rules',
        a37:'Set Up',
        a38: 'Kanban Parameter Setting',
        a39: "Add Company Information",
        a40: "Edit Company Information",
        a41: "Energy Consumption Signal Point",
        a42: "Clean Energy Power Generation Signal Point",
        a43: "Company Name",
        a44: "Display Color",
        a45: "Select and add signal points",
        a46: "Delete signal point",
        a47: "Are you sure you want to save the edited information?",
        a48: "Are you sure you want to delete?",
        a49: "The selected signal point already exists, please do not repeat the selection.",
        a50: "Please select customer / building / site information!",
        a51: "Please enter the detailed address.",
        a52: "The length of the detailed address must be between 1 and 100.",
        a53: "The detailed address cannot contain special characters.",
        a54: 'Delete Company Information',
        a55: 'Select KPI rules',
        a56: 'Group Address',
        a57:' Total Power Generation',
        a58: "Group's electricity consumption ",
        a59: "Group's carbon emissions ",
        a60: '${num} qualified',
        a61: '${num} not up to standard',
        a62: 'Group monthly KPI',
        a63: 'KPI completion of company',
        a64: 'Qualified',
        a65: 'Not up to standard',
        a66: 'Peak valley analysis',
        a67: 'Comparison of energy consumption',
        a68: 'Power consumption ranking',
        a69: 'Pressure',
        a70: 'Precipitation',
        a71: 'Visibility',
        a72: 'Sensory temperature',
        a73: 'UV',
        a74: 'Humidity',
        a75: 'North Wind',
        a76: 'Northeast wind',
        a77: 'Northwest wind',
        a78: 'South Wind',
        a79: 'Southeast wind',
        a80: 'Southwest wind',
        a81: 'West Wind',
        a82: 'East Wind',
        a83: 'level',
        a84: 'Group Annual KPI',
        a85: 'Group Daily KPI',
        a86: 'Not obtained',
        a87: 'Time progress',
        a88:'',
        a89:'',
        a90:'',
    }
}