// 门禁模块 -- 设备页
module.exports = {
    doorequipment:{
        a1: '類型選擇',
        a2: '樓層/租戶選擇',
        a3: '全部',
        a4: '門',
        a5: '認證終端',
        a6: '正常',
        a7: '通訊异常',
        a8: '維護',
        a9: '機器异常',
        a10: '故障/警報',
        a11: '維護警報/上下限警報·運行异常',
        a12: '加載中….',
        a13: '卡片類型有誤',
        a14: '操作成功，開門按鈕將在5秒鐘後恢復關閉狀態',
        a15: '開門操作失敗',
        a16: '圖標',
        a17: '表格',
        a18: '展開',
        a19: '收起',
        a20: '詳情',
        a21: '條數據',
        a22: '樓層/租戶',
        a23: '設備名稱',
        a24: '開關',
        a25: '視頻',
        a26: '狀態',
        a27: '操作',
        a28: '故障'
    }
}