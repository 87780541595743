// import menu from "./modules/menu"

const getters = {
    oneMenu: state => state.menu.oneMenu,
    clientId: state => state.menu.clientId,
    sitegrId: state => state.menu.sitegrId,
    twoMenu: state => state.menu.twoMenu,
    threeMenu: state => state.menu.threeMenu,
    loading: state => state.menu.loading,
    homepage_loading: state => state.loadings.homepage_loading,
    siteMenu: state => state.menu.siteMenu,
    siteName: state => state.menu.siteName,
    siteServiceTypeName:state => state.menu.siteServiceTypeName,//2021-12-07取服务类型名称
    siteType: state => state.menu.siteType,
    cardList: state => state.info.cardList,
    personList: state => state.info.personList,
    user: state => state.user.user,
    area: state => state.areaDetails.area,
    size: state => state.app.size,
    device: state => state.app.device,
    visitedViews: state => state.tagsView.visitedViews,
    cachedViews: state => state.tagsView.cachedViews,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    introduction: state => state.user.introduction,
    roles: state => state.user.roles,
    permission_routes: state => state.permission.routes,
    errorLogs: state => state.errorLog.logs,
}
export default getters
