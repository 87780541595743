<!--客户信息统计-->
<template>
    <div id="CustomerStatistics" title="客户信息统计" @click="goToCustomerStatistics">
      <div :class="bgimage"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CustomerStatistics",
    methods:{
        goToCustomerStatistics(){
          this.$router.push({ path: '/customerStatistics' })
      }
    },
    computed: {
      bgimage: function () {
        console.log(this.$route.matched);
        let route = this.$route.matched[1].path;
        console.log(route,route == "/customerStatistics");
        if (route == "/customerStatistics") {
          return 'selectedImage'
        } else {
          return 'normalImage'
        }
      }
    }
  }
  </script>
  
  <style scoped>
  #CustomerStatistics {
    width: 80px;
    height: 80px;
    flex-shrink:0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
  }
  .normalImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.2);
  border-radius: 50%;
  background-color: #f2f4f7;
  background-image: url('../../../../public/images/sidebar/statistics_normal.png');
  background-repeat: no-repeat;
  background-size: 32px 32px;
  background-position: 12px 10px;
  transition: 400ms ease-out;
}
.normalImage:hover{
  box-shadow: 0 0px 7px #7682ce;
}
.selectedImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-radius: 50%;
  background-color: #7682ce;
  background-image: url('../../../../public/images/sidebar/statistics_selected.png');
  background-repeat: no-repeat;
  background-size: 32px 32px;
  background-position: 12px 10px;
  transition: 400ms ease-out;
}
  </style>