module.exports = {
    distribution:{
        a1: '表示モジュール',
        a2: 'バインドユーザーを選択してください',
        a3: 'ユーザーをバインド',
        a4: 'ページリフレッシュ時間（分）',
        a5: 'ページ更新時間（分）を入力してください',
        a6: 'タイトル',
        a7: 'タイトルを入力してください',
        a8: 'テーマ',
        a9: 'テーマを選択してください',
        a10: 'リリース情報',
        a11: '設備KEY',
        a12: '設備KEYを入力してください',
        a13: 'アクセスアドレス',
        a14: 'アクセス先を入力してください',
        a15: 'サービスポート',
        a16: 'サービスポートを入力してください',
        a17: 'サービスIP',
        a18: 'サービスIPを入力してください',
        a19: '設備ポート',
        a20: '設備ポートを入力してください',
        a21: '設備IP',
        a22: '設備IPを入力してください',
        a23: '構成情報',
        a24: 'リンクをコピー',
        a25: '設備アクセスコード',
        a26: 'スクリーンタイプ',
        a27: '設備の種類',
        a28: '所属',
        a29: '名称',
        a30: '設備番号',
        a31: '基本情報',
        a32: '普通の大画面',
        a33: '独立配置サービス大画面',
        a34: '普通小画面',
        a35: 'フラットスクリーン',
        a36: '顔端末画面',
        a37: '広告画面',
        a38: 'テーマ1',
        a39: 'テーマ2',
        a40: 'タイトルを入力してください',
        a41: 'データ更新時間を入力してください',
        a42: '表示モジュールを選択してください',
        a43: 'コピー成功！',
        a44: '設定されたパブリケーション情報パラメータを保存することを確認しますか？',
        a45: '設定された構成情報を保存することを確認しますか？',
        a46: '設備を追加する',
        a47: '設備名を入力してください！',
        a48: '画面タイプを選択してください',
        a49: 'コントローラ',
        a50: 'コントローラを選択してください',
        a51: '設備名の長さは1から30の間でなければならない',
        a52: '顔端末情報',
        a53: 'ピクチャポート',
        a54: '背景画像',
        a55: 'すべて削除',
        a56: '削除',
        a57: '画像を選択する',
        a58: '画像をアップロード',
        a59: '設備現場図',
        a60: '画像を読み込む',
        a61: 'すべての画像を読み込む',
        a62: 'pngとjpg形式の画像しかアップロードできない！',
        a63: '画像サイズは300 KBを超えてはいけない！',
        a64: '設定した情報を保存することを確認しますか？',
        a65: '選択した画像をアップロードすることを確認しますか？',
        a66: 'この画像を削除することを確認しますか？',
        a67: 'すべての画像を削除することを確認しますか？',
        a68: '設備設定',
        a69:'',
    }
}