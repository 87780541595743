//客戶統計資訊--中文簡體
//客戶統計資訊--中文簡體
module.exports = {
	customerStatistics:{
	a1:'客戶資訊統計',
	a2:'客戶資訊',
	a3:'客戶數',
	a4:'建築數',
	a5:'網站數',
	a6:'服務數',
	a7:'樓層/租戶數',
	a8:'設備數',
	a9:'設備資訊統計',
	a10:'設備類型',
	a11:'請選擇設備類型！',
	a12:'設備數量',
	a13:'服務資訊統計',
	a14:'服務名稱',
	a15:'客戶名稱',
	a16:'建築名稱',
	a17:'建築資訊',
	a18:'請選擇建築資訊！',
	a19:'請選擇客戶資訊！',
	a20:'請選擇',
	a21:'網站名稱',
	a22:'網站資訊',
	a23:'請選擇網站資訊！',
	a24:'請選擇服務類型！',
	a25:'樓層/租戶資訊',
	a26:'樓層/租戶名稱',
	a27:'所有設備類型分組合計',
	a28:'客戶設備類型分組合計',
	a29:'建築設備類型分組合計',
	a30:'網站設備類型分組合計',
	a31:'所有設備合計',
	a32:'客戶設備合計',
	a33:'建築設備合計',
	a34:'網站設備合計',
	a35:'所有網站設備詳情',
	a36:'客戶網站設備詳情',
	a37:'建築網站設備詳情',
	a38:'網站設備詳情',
	a39:'服務資訊',
	a40:'設備資訊',
	a41:'請選擇統計分類！',
	a42:'合計',
	a43:'當前$1-$2/總共$3條數據',
	a44:'',
	a45:'',
	a46:'',
	a47:'',
	a48:'',
	a49:'',
}
}