<!--主页侧边栏--认证信息-->
<template>
  <div id="certificationbutton" :title="$t('sidebar.c1')">
    <a-popover :placement="placement" :getPopupContainer="(triggerNode) => triggerNode.parentNode">
      <template slot="content">
        <a-menu :selectedKeys="$route.name ? [$route.name] : []">
          <a-menu-item  v-for="item in list" :key="item.key"  @click="getItem(item)">{{ $t(item.name) }}</a-menu-item>
        </a-menu>
      </template>
      <div :class="bgimage"></div>
    </a-popover>
  </div>
</template>

<script>
export default {
  props:{
    placement:{
      type:String,
      default:'right'
    }
  },
  data() {
    return {
      list: [
        {
          url: "/information/info",
          name: "sidebar.c2",
          key: 'info'
        },
        {
          url: "/information/infoGroup",
          name: "sidebar.c3",
          key: 'infoGroup'
        },
      ],
    }
  },
  computed: {
    bgimage: function () {
      let length = this.$route.matched.length;
      let path = undefined;
      if (length > 2) {
        path = this.$route.matched[2].path;
      } else {
        path = undefined;
      }
      if (path == "/information") {
        return 'selectedImage'
      }else{
        return 'normalImage'
      }
    },
  },
  methods: {
    getItem(item){
      this.$router.push({ path: item.url }) 
    },
  }
}
</script>

<style scoped>
#certificationbutton {
  width: 80px;
  height: 80px;
  flex-shrink:0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
}
.normalImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.2);
  border-radius: 50%;
  background-color: #f2f4f7;
  background-image: url('../../../../public/images/sidebar/certification_normal.png');
  background-repeat: no-repeat;
  background-size: 29px 29px;
  background-position: 13px 12px;
  transition: 400ms ease-out;
}
.normalImage:hover{
  box-shadow: 0 0px 7px #7682ce;
}
.selectedImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-radius: 50%;
  background-color: #7682ce;
  background-image: url('../../../../public/images/sidebar/certification_seletced.png');
  background-repeat: no-repeat;
  background-size: 29px 29px;
  background-position: 13px 12px;
  transition: 400ms ease-out;
}
div /deep/.ant-popover-inner-content{
  padding: 0px;
}
div /deep/.ant-popover{
  z-index: 3000;
}
</style>