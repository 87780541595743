// 自动报表
module.exports = {
    automaticReport:{
        a1: 'Automatic report setting',
        a2: 'Report list',
        a3: 'Add',
        a4: 'Please select a report',
        a5: 'Daily export time',
        a6: 'Export template',
        a7: 'List of signal points',
        a8: 'Start',
        a9: 'Settings',
        a10: 'Only 7 copies of documents are saved',
        a11: 'Cancel',
        a12: 'Confirm',
        a13: 'Add report',
        a14: 'Report name',
        a15: 'Report type',
        a16: 'Please enter the name',
        a17: 'Modify report name',
        a18: 'List of signal points',
        a19: 'File list',
        a20: 'Confirm to delete the report',
        a21: 'download',
        a22: 'operation'
    }
}