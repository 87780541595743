// 客户统计信息 -- 中文简体
module.exports = {
    customerStatistics:{
        a1:'客户信息统计',
        a2:'客户信息',
        a3:'客户数',
        a4:'建筑数',
        a5:'站点数',
        a6:'服务数',
        a7:'楼层/租户数',
        a8:'设备数',
        a9:'设备信息统计',
        a10:'设备类型',
        a11:'请选择设备类型！',
        a12:'设备数量',
        a13:'服务信息统计',
        a14:'服务名称',
        a15:'客户名称',
        a16:'建筑名称',
        a17:'建筑信息',
        a18:'请选择建筑信息！',
        a19:'请选择客户信息！',
        a20:'请选择',
        a21:'站点名称',
        a22:'站点信息',
        a23:'请选择站点信息！',
        a24:'请选择服务类型！',
        a25:'楼层/租户信息',
        a26:'楼层/租户名称',
        a27:'所有设备类型分组合计',
        a28:'客户设备类型分组合计',
        a29:'建筑设备类型分组合计',
        a30:'站点设备类型分组合计',
        a31:'所有设备合计',
        a32:'客户设备合计',
        a33:'建筑设备合计',
        a34:'站点设备合计',
        a35:'所有站点设备详情',
        a36:'客户站点设备详情',
        a37:'建筑站点设备详情',
        a38:'站点设备详情',
        a39:'服务信息',
        a40:'设备信息',
        a41:'请选择统计分类！',
        a42:'合计',
        a43:'当前$1-$2/总共$3条数据',
        a44:'',
        a45:'',
        a46:'',
        a47:'',
        a48:'',
        a49:'',
    }
}