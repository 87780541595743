module.exports = {
    eqct:{
        a1	:'车场配置信息',
        a2	:'设备映射关系设置',
        a3	:'设备区域信息',
        a4	:'车场人员组织架构',
        a5	:'接口信息',
        a6	:'终端品牌',
        a7	:'用户名',
        a8	:'密码',
        a9	:'API路径',
        a10	:'接口版本',
        a11	:'同步访问',
        a12	:'APP ID',
        a13	:'APP Key',
        a14	:'设备信息',
        a15	:'设备编号',
        a16	:'设备名称',
        a17	:'设备IP',
        a18	:'设备网关IP',
        a19	:'设备子网掩码',
        a20	:'选择门',
        a21	:'关联终端类型',
        a22	:'设备/终端名称',
        a23	:'关联道闸终端',
        a24	:'移除转换',
        a25	:'全部移除',
        a26	:'移除转换',
        a27	:'区域初始化',
        a28	:'区域名称',
        a29	:'设备列表',
        a30	:'本地人员组织架构信息',
        a31	:'车场人员组织架构信息',
        a32	:'设备转换',
        a33	:'设备类型',
        a34	:'认证终端列表',
        a35	:'选择认证终端',
        a36	:'关联终端名称',
        a37	:'人脸终端名称',
        a38	:'序列号',
        a39	:'设备品牌',
        a40	:'是否带测温',
        a41	:'车场区域设置',
        a42	:'设备映射列表',
        a43	:'全选',
        a44	:'关联设备',
        a45	:'设备/终端类型',
        a46	:'出入口设备',
        a47	:'访客设备',
        a48	:'考勤设备',
        a49	:'清除提示',
        a50	:'是',
        a51	:'否',
        a52	:'请输入第三方接口账户。',
        a53	:'请输入第三方接口账户密码。',
        a54	:'请输入第三方接口地址。',
        a55	:'请选择终端品牌。',
        a56	:'请输入第三方接口版本。',
        a57	:'车场道闸设备信息设置',
        a58	:'闸机设备列表',
        a59	:'确认要取消该设备的转换信息吗？',
        a60	:'确认要取消所有的转换信息吗？',
        a61	:'确认要取消所选择的设备的转换信息吗？',
        a62	:'确认要保存所设置的信息吗？',
        a63	:'出入类型',
        a64	:'站点名称',
        a65	:'第三方接口信息',
        a66	:'确认要取消该设备的转换信息吗？',
        a67	:'',
        a68	:'',
        a69	:'',

    }
}