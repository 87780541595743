module.exports = {
    eqct:{
        a1	:'駐車場配置情報',
        a2	:'デバイスマッピング関係設定',
        a3	:'デバイス領域情報',
        a4	:'駐車場人員組織構造',
        a5	:'インタフェース情報',
        a6	:'ターミナルブランド',
        a7	:'ユーザー名',
        a8	:'パスワード',
        a9	:'APIパス',
        a10	:'インタフェースバージョン',
        a11	:'同期アクセス',
        a12	:'APP ID',
        a13	:'APP Key',
        a14	:'デバイス情報',
        a15	:'デバイス番号',
        a16	:'デバイス名',
        a17	:'デバイスIP',
        a18	:'デバイスゲートウェイIP',
        a19	:'デバイスサブネットマスク',
        a20	:'せんたくゲート',
        a21	:'関連端末タイプ',
        a22	:'デバイス/端末名',
        a23	:'れんけつゲートたんまつ',
        a24	:'変換の削除',
        a25	:'すべて除去',
        a26	:'変換の削除',
        a27	:'ゾーン初期化',
        a28	:'領域名',
        a29	:'デバイスリスト',
        a30	:'ローカルユーザ組織スキーマ情報',
        a31	:'駐車場人員組織構造情報',
        a32	:'デバイス変換',
        a33	:'デバイスタイプ',
        a34	:'認証端末リスト',
        a35	:'認証端末の選択',
        a36	:'関連端末名',
        a37	:'顔端末名',
        a38	:'シリアル番号',
        a39	:'設備ブランド',
        a40	:'帯域温度測定',
        a41	:'駐車場区域の設定',
        a42	:'デバイスマッピングリスト',
        a43	:'すべて選択',
        a44	:'関連デバイス',
        a45	:'デバイス/端末タイプ',
        a46	:'出入口設備',
        a47	:'ゲストデバイス',
        a48	:'勤務評定設備',
        a49	:'プロンプトのクリア',
        a50:'はい',
        a51:'いいえ',
        a52:'サードパーティインタフェースアカウントを入力してください。',
        a53:'サードパーティインタフェースアカウントのパスワードを入力してください。',
        a54:'サードパーティインタフェースアドレスを入力してください。',
        a55:'ターミナルブランドを選択してください。',
        a56:'サードパーティインタフェースバージョンを入力してください。',
        a57:'駐車場ゲート設備情報設定',
        a58:'シャッタ装置リスト',
        a59:'デバイスの変換情報をキャンセルすることを確認しますか？',
        a60:'変換情報をすべてキャンセルすることを確認しますか？',
        a61:'選択されたデバイスの変換情報をキャンセルすることを確認しますか？',
        a62:'設定した情報を保存することを確認しますか？',
        a63:'出入りタイプ',
        a64:'サイト名',
        a65:'サードパーティインタフェース情報',
        a66:'デバイスの変換情報をキャンセルすることを確認しますか？',
    }
}