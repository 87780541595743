// 能耗报表设置
module.exports = {
    energyConsumptionReportSettings:{
        a1:'エネルギー消費レポート設定',
        a2:'テンプレートリスト',
        a3:'追加',
        a4:'合計総使用量',
        a5:'展示詳細',
        a6:'設定',
        a7:'キャンセル',
        a8:'確認',
        a9:'名称：',
        a10:'名前を入力してください',
        a11:'名称変更',
        a12:'テンプレートを削除することを決定する'
    }
}