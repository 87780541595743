// 认证信息路由表
export default [{
    path: '/information',
    name: 'information',
    component: () => import('@/views/authentication/informationLayout'),
    children: [
        {
            path: '/information/info', //认证信息页
            name: 'info',
            component: () => import('@/views/authentication/information'),
        },
        {
            path: '/information/infoGroup', //认证信息组页
            name: 'infoGroup',
            component: () => import('@/views/authentication/informationGroup'),
        },
        {
            path: '/information/peopleManagement', //人员管理
            name: 'peopleManagement',
            component: () => import('@/views/authentication/peopleManagement'),
        },
        {
            path: '/information/matchingSet', //通行模式匹配设置
            name: 'matchingSet',
            component: () => import('@/views/authentication/matchingSet'),
        },
    ]
}]