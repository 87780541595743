// 能耗报表设置
module.exports = {
    energyConsumptionReportSettings:{
        a1:'能耗報表設定',
        a2:'模版清單',
        a3:'添加',
        a4:'合計總用量',
        a5:'展示明細',
        a6:'設定',
        a7:'取消',
        a8:'確認',
        a9:'名稱：',
        a10:'請輸入名稱',
        a11:'名稱修改',
        a12:'確定删除模版'
    }
}