// 能效KPI
module.exports = {
    analyseKPI:{
        a1:'加载中....',
        a2:'能效KPI',
        a3:'搜索',
        a4:'实际数值',
        a5:'目标达成',
        a6:'查询',
        a7:'CSV下载',
        a8:'请选择设备进行查询',
        a9:'未选择设备',
        a10:'年度',
        a11:'月度',
        a12:'日度',
        a13:'KPI报表',
        a14:'年目标值',
        a15:'月目标值',
        a16:'月实际值',
        a17:'年累积值',
        a18:'日目标值',
        a19:'日实际值',
        a20:'月累积值',
        a21:'月度值',
        a22:'年度值' ,
        a23:'已达成目标' ,
        a24:'未达成目标' ,
        a25:'累计用电进度' ,
        a26:'预计用电进度' ,
        a27:'用电量' ,
        a28:'目标值' ,
        a29:'累积值' ,
        a30:'小时使用量' ,
        a31:'累积量' ,
        a32:'日度值',
        a33:'单位：'
    }
}