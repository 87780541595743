// 看板 -- 中文简体
module.exports = {
    Kanban:{
        a1:'当年累计',
        a2:'当月累计',
        a3:'当日累计',
        a4:'环比',
        a5:'实时功率',
        a6:'年度KPI',
        a7:'月度KPI',
        a8:'碳排放',
        a9:'清洁能源发电',
        a10:'看板设置',
        a11:'实时功率',
        a12:'总功率',
        a13:'显示',
        a14:'数据类型',
        a15:'显示层级',
        a16:'关联站点',
        a17:'站点地址',
        a18:'数据比较',
        a19:'分段规则使用',
        a20:'使用站点分段规则',
        a21:'统一使用建筑分段规则',
        a22:'统一使用客户分段规则',
        a23:'当选择“使用站点分段规则”时，如果对应站点未设置分段规则，则使用建筑的分段规则。当数据类型选择“日”时；统一使用建筑分段规则。',
        a24:'当选择“使用站点分段规则”时，如果对应站点未设置分段规则，则使用客户的分段规则。当数据类型选择“日”时；统一使用客户分段规则。',
        a25:'每月快捷全选设置',
        a26:'每小时快捷全选设置',
        a27:'快捷选择',
        a28:'严重警告',
        a29:'一般警告',
        a30:'已恢复',
        a31:'未恢复',
        a32:'返回',
        a33:'每日碳排放',
        a34:'每日使用量',
        a35:'注意',
        a36:'KPI规则',
        a37:'去设置',
        a38:'看板参数设置',
        a39:"添加公司信息",
        a40:"编辑公司信息",
        a41:"能源使用量信号点",
        a42:"清洁能源发电量信号点",
        a43:"公司名称",
        a44:"显示颜色",
        a45:"选择并添加信号点",
        a46:"删除信号点",
        a47:"确认要保存所编辑的信息吗？",
        a48:"确认要删除吗？",
        a49:"所选择信号点已存在，请不要重复选择。",
        a50:"请选择客户/建筑/站点信息！",
        a51:"请输入详细地址。",
        a52:"详细地址长度必须在1~100之间。",
        a53:"详细地址不可以包含特殊字符。",
        a54:'删除公司信息',
        a55:'选择KPI规则',
        a56:'集团地址',
        a57:'总发电量',
        a58:'集团整体用电量',
        a59:'集团整体碳排放量',
        a60:'已达标${num}家',
        a61:'未达标${num}家',
        a62:'集团月度KPI',
        a63:'各公司KPI完成情况',
        a64:'已达标',
        a65:'未达标',
        a66:'峰谷分析',
        a67:'用能对比',
        a68:'用电排名',
        a69:'大气压',
        a70:'降水量',
        a71:'能见度',
        a72:'体感温度',
        a73:'紫外线',
        a74:'相对湿度',
        a75:'北风',
        a76:'东北风',
        a77:'西北风',
        a78:'南风',
        a79:'东南风',
        a80:'西南风',
        a81:'西风',
        a82:'东风',
        a83:'级',
        a84:'集团年度KPI',
        a85:'集团每日KPI',
        a86:'未获取',
        a87:'时间进度',
        a88:'',
        a89:'',
        a90:'',
    }
}