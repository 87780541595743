// 道路测速路由表 2023/04/19 lht

export default [
    {
        path: '/homepage/velocityMeasurement', // 道路测速主页入口
        redirect: '/404',
        component: () => import('@/views/velocityMeasurement/index'),
        children:[
            {
                path: '/homepage/velocityMeasurement/module', // 道路测速模块入口
                redirect: '/404',
                component: () => import('@/views/velocityMeasurement/moduleIndex'),
                children:[
                    {
                        path: '/homepage/velocityMeasurement/equipment', // 道路测速--设备页
                        name: 'ENT_EQUIPMENT1',
                        component: () => import('@/views/velocityMeasurement/equipment')
                    },
                    {
                        path: '/homepage/velocityMeasurement/record', // 道路测速--测速记录
                        name: 'ENT_EQUIPMENT2',
                        component: () => import('@/views/velocityMeasurement/record')
                    },
                    // {
                    //     path: '/homepage/velocityMeasurement/set', // 道路测速--设置
                    //     name: 'ENT_EQUIPMENT9',
                    //     component: () => import('@/views/velocityMeasurement/set')
                    // },
                    {
                        path: '/homepage/velocityMeasurement/actualData', // 道路测速--实际数据
                        name: 'ENT_EQUIPMENT3',
                        component: () => import('@/views/velocityMeasurement/actualData')
                    }
                ]
            }
        ]
    }
]