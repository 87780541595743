// 能效KPI
module.exports = {
    analyseKPI:{
        a1:'ロード中...',
        a2:'エネルギー効率KPI',
        a3:'検索',
        a4:'実際の数値',
        a5:'目標達成',
        a6:'照会',
        a7:'CSVダウンロード',
        a8:'照会するデバイスを選択してください',
        a9:'デバイスが選択されていない',
        a10:'年度',
        a11:'月度',
        a12:'日度',
        a13:'KPIレポート',
        a14:'年間目標値',
        a15:'月目標値',
        a16:'月実績',
        a17:'年間累積値',
        a18:'日目標値',
        a19:'日実績値',
        a20:'月累積値',
        a21:'月度値',
        a22:'年度値',
        a23:'目標を達成した',
        a24:'目標未達成',
        a25:'積算電力使用進捗',
        a26:'予定電力使用進捗',
        a27:'電気使用量',
        a28:'目標値',
        a29:'累積値',
        a30:'時間使用量',
        a31:'累積量',
        a32:'日度値',
        a33:'単位：'
    }
}