// 门禁模块路由表
export default [
    {
        path: '/homepage/door', // 门禁主页入口
        redirect: '/404',
        component: () => import('@/views/door/doorInlet'),
        children:[
            {
                path: '/homepage/door/gateMachineDetails', // 门禁 -- 闸机详情页
                component: () => import('@/views/doorDetails/gateMachineDetails')
            },
            {
                path: '/homepage/door/cardReaderDetails', // 门禁 -- 读卡器详情页
                component: () => import('@/views/doorDetails/cardReaderDetails')
            },
            {
                path: '/homepage/door/barrierGateDetails', // 门禁 -- 道闸详情
                component: () => import('@/views/doorDetails/barrierGateDetails')
            },
            {
                path: '/homepage/door/doorDetails', // 门禁 -- 门详情页
                component: () => import('@/views/doorDetails/doorDetails')
            },
            {
                path: '/homepage/door/terminalEditor', //门禁模块--认证终端编辑
                name: 'terminalEditor',
                component: () => import('@/views/door/terminalEditor')
            },
            {
                path: '/homepage/door/peopleManagement', //门禁模块--人员管理
                component: () => import('@/views/authentication/peopleManagement')
            },
            {
                path: '/homepage/doorModule', // 门禁模块入口
                redirect: '/404',
                component: () => import('@/views/door/doorModuleInlet'),
                children:[
                    {
                        path: '/homepage/door/equipment', //门禁模块--设备页
                        name: 'ENT_EQUIPMENT',
                        component: () => import('@/views/door/equipment')
                    },
                    {
                        path: '/homepage/door/alarm', //门禁模块--报警页
                        name: 'ENT_ALARM',
                        component: () => import('@/views/door/alarm')
                    },
                    {
                        path: '/homepage/door/throughRecords', //门禁模块--通行记录
                        name: 'ENT_RIREKI',
                        component: () => import('@/views/door/throughRecords')
                    },
                    {
                        path: '/homepage/door/throughRecords', //门禁模块--通行记录
                        name: 'ENT_HISTORY',
                        component: () => import('@/views/door/throughRecords')
                    },
                    {
                        path: '/homepage/door/strandedPersonnel', //门禁模块--滞留人员显示
                        name: 'ENT_ZANRYU',
                        component: () => import('@/views/door/strandedPersonnel'),
                    },
                    {
                        path: '/homepage/door/indoorPersonne', //门禁模块--室内人员显示
                        name: 'ENT_ZAISITU',
                        component: () => import('@/views/door/indoorPersonne')
                    },
                    {
                        path: '/homepage/door/detentionArea', //门禁模块--滞留人员区域
                        name: 'ENT_AREA',
                        component: () => import('@/views/door/detentionArea')
                    },
                    {
                        path: '/homepage/door/indoorArea', //门禁模块--室内人员区域
                        name: 'ENT_AREA_STAY',
                        component: () => import('@/views/door/indoorArea')
                    },
                    {
                        path: '/homepage/door/doorTimetable', //门禁模块--门禁时间表
                        name: 'ENT_NYUTAI_SCE',
                        component: () => import('@/views/door/doorTimetable')
                    },
                    {
                        path: '/homepage/door/facialInfo', //门禁模块--人脸认证信息
                        name: 'ENT_FACE_NINSHOU',
                        component: () => import('@/views/door/facialInfo')
                    },
                    {
                        path: '/homepage/door/doorTimetable', //门禁模块--门禁时间表
                        name: 'doorTimetable',
                        component: () => import('@/views/door/doorTimetable')
                    },
                    {
                        path: '/homepage/door/trafficPatterns', //门禁模块--通行模式
                        name: 'ENT_TUKO_PATTERN',
                        component: () => import('@/views/door/trafficPatterns')
                    },
                    {
                        path: '/homepage/door/cardReaderAlarm', //门禁模块--读卡器报警
                        name: 'ENT_CR_ALERM',
                        component: () => import('@/views/door/cardReaderAlarm')
                    },
                    {
                        path: '/homepage/door/reportData', //门禁模块--数据报表
                        name: 'ENT_DATA',
                        component: () => import('@/views/door/reportData')
                    },
                    {
                        path: '/homepage/door/parkingLotSet', //门禁模块--车场设置
                        name: 'ENT_CAR_PARK',
                        component: () => import('@/views/door/parkingLotSet')
                    },
                    {
                        path: '/homepage/door/plateNumber', //门禁模块--车牌管理
                        name: 'ENT_CAR_PLATENUMBER',
                        component: () => import('@/views/door/plateNumber')
                    },
                    {
                        path: '/homepage/door/attendanceRecord', //门禁模块--考勤记录
                        name: 'ENT_RIREKI_ATTENDANCE',
                        component: () => import('@/views/door/attendanceRecord')
                    },
                    {
                        path: '/homepage/door/equipmentConvert', //门禁模块--考勤记录
                        name: 'ACS_EQUIPMENT_CONVERT_SETTING',
                        component: () => import('@/views/door/equipmentConvert')
                    },
                        //记录 3.2
                    {
                        path: '/homepage/door/carRecord', //出入口模块--车辆记录
                        name: 'ENT_RIREKI_CAR',
                        component: () => import('@/views/passageway/carThroughRecords')
                    }
                ]
            }
        ]
    }
    // {
    //     path: '/homepage/door/gateMachineDetails', //门禁模块--闸机详情页
    //     component: () => import('@/views/doorDetails/gateMachineDetails'),
    // },
    // {
    //     path: '/homepage/door/cardReaderDetails', //门禁模块--读卡器详情页
    //     component: () => import('@/views/doorDetails/cardReaderDetails'),
    // },
    // {
    //     path: '/homepage/door/barrierGateDetails', //门禁模块--道闸详情页
    //     component: () => import('@/views/doorDetails/barrierGateDetails'),
    // },
    // {
    //     path: '/homepage/door/doorDetails', //门禁模块--门详情页
    //     component: () => import('@/views/doorDetails/doorDetails'),
    //     meta: { hidden: false }
    // },
    // {
    //     path: '/homepage/door', //门禁模块--门禁主页
    //     redirect: '/404',
    //     component: () => import('@/views/door/doorLayout'),
    //     children: [
    //         {
    //             path: '/homepage/door/equipment', //门禁模块--设备页
    //             name: 'ENT_EQUIPMENT',
    //             component: () => import('@/views/door/equipment'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/alarm', //门禁模块--报警页
    //             name: 'ENT_ALARM',
    //             component: () => import('@/views/door/alarm'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/throughRecords', //门禁模块--通行记录
    //             name: 'ENT_RIREKI',
    //             component: () => import('@/views/door/throughRecords'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/throughRecords', //门禁模块--通行记录
    //             name: 'ENT_HISTORY',
    //             component: () => import('@/views/door/throughRecords'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/strandedPersonnel', //门禁模块--滞留人员显示
    //             name: 'ENT_ZANRYU',
    //             component: () => import('@/views/door/strandedPersonnel'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/indoorPersonne', //门禁模块--室内人员显示
    //             name: 'ENT_ZAISITU',
    //             component: () => import('@/views/door/indoorPersonne'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/detentionArea', //门禁模块--滞留人员区域
    //             name: 'ENT_AREA',
    //             component: () => import('@/views/door/detentionArea'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/indoorArea', //门禁模块--室内人员区域
    //             name: 'ENT_AREA_STAY',
    //             component: () => import('@/views/door/indoorArea'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/doorTimetable', //门禁模块--门禁时间表
    //             name: 'ENT_NYUTAI_SCE',
    //             component: () => import('@/views/door/doorTimetable'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/facialInfo', //门禁模块--人脸认证信息
    //             name: 'ENT_FACE_NINSHOU',
    //             component: () => import('@/views/door/facialInfo'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/doorTimetable', //门禁模块--门禁时间表
    //             name: 'doorTimetable',
    //             component: () => import('@/views/door/doorTimetable'),
    //         },
    //         {
    //             path: '/homepage/door/trafficPatterns', //门禁模块--通行模式
    //             name: 'ENT_TUKO_PATTERN',
    //             component: () => import('@/views/door/trafficPatterns'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/cardReaderAlarm', //门禁模块--读卡器报警
    //             name: 'ENT_CR_ALERM',
    //             component: () => import('@/views/door/cardReaderAlarm'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/reportData', //门禁模块--数据报表
    //             name: 'ENT_DATA',
    //             component: () => import('@/views/door/reportData'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/parkingLotSet', //门禁模块--车场设置
    //             name: 'ENT_CAR_PARK',
    //             component: () => import('@/views/door/parkingLotSet'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/plateNumber', //门禁模块--车牌管理
    //             name: 'ENT_CAR_PLATENUMBER',
    //             component: () => import('@/views/door/plateNumber'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/terminalEditor', //门禁模块--认证终端编辑
    //             name: 'terminalEditor',
    //             component: () => import('@/views/door/terminalEditor'),
    //             meta: { hidden: false }
    //         },
    //         {
    //             path: '/homepage/door/attendanceRecord', //门禁模块--考勤记录
    //             name: 'ENT_RIREKI_ATTENDANCE',
    //             component: () => import('@/views/door/attendanceRecord'),
    //             meta: { hidden: true }
    //         },
    //         {
    //             path: '/homepage/door/equipmentConvert', //门禁模块--考勤记录
    //             name: 'ACS_EQUIPMENT_CONVERT_SETTING',
    //             component: () => import('@/views/door/equipmentConvert'),
    //             meta: { hidden: true }
    //         },
    //          //记录 3.2
    //         {
    //             path: '/homepage/door/carRecord', //出入口模块--车辆记录
    //             name: 'ENT_RIREKI_CAR',
    //             component: () => import('@/views/passageway/carThroughRecords'),
    //             meta: { hidden: true }
    //         },
            
    //     ],
    // }
]