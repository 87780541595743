// 清洁能源设置
module.exports = {
    cleanEnergySetting:{
        a1:'清潔能源設定',
        a2:'請選擇分組',
        a3:'總發電量',
        a4:'並網電量',
        a5:'自用電量',
        a6:'總用電量',
        a7:'電網取電',
        a8:'請選擇分組',
        a9:'重置',
        a10:'設定',
        a11:'添加分組',
        a12:'取消',
        a13:'確認',
        a14:'分組名稱',
        a15:'請輸入名稱',
        a16:'分組名稱修改',
        a17:'確定删除報表'
    }
}