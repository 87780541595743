<template>
  <a-ConfigProvider :locale="Changelocale">
    <div id="app">
      <router-view></router-view>
    </div>
  </a-ConfigProvider>
</template>

<script>
import zhcn from "ant-design-vue/lib/locale-provider/zh_CN";
import zhtw from "ant-design-vue/lib/locale-provider/zh_TW";
import jajp from "ant-design-vue/lib/locale-provider/ja_JP";

export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    //antd vue的国际化语言改变方法
    Changelocale() {
      var locale = zhcn;
      if (this.$i18n.locale == "zhcn") {
        locale = zhcn;
      } else if (this.$i18n.locale == "zhtw") {
        locale = zhtw;
      } else if (this.$i18n.locale == "jajp") {
        locale = jajp;
      } else {
        locale = null;
      }
      return locale;
    },
  },
};
</script>


<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #000000;
}
ul {
  padding-left: 0;
}
.ant-modal-header {
  background-color: #7682ce;
}
</style>