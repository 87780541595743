// 环境路由表
export default [
    {
        path: '/homepage/environment/environmentDetails', //环境模块--环境详情页
        component: () => import('@/views/environmentDetails/environmentDetails'),
    },
    {
        path: '/homepage/environment',//环境主页
        component: () => import('@/views/environment/environmentLayout'),
        children: [
            {
            path: '/homepage/environment/equipment', //环境模块--设备页
            name: 'ENVIRONMENT_FAC_EQUIPMENT',
            component: () => import('@/views/environment/equipment'),
            meta: { index: 0 }
            },
            {
                path: '/homepage/environment/terminalEditor', //照明模块--联动控制设备编辑
                name: 'ENVIRONMENT_terminalEditor',
                component: () => import('@/views/common/terminalEditor'),
            },
            {
                path: '/homepage/environment/schedule', //环境模块--时间表
                name: 'ENVIRONMENT_FAC_SCEDULE_GR',
                component: () => import('@/views/common/controllGroup'),
            },
            {
                path: '/homepage/environment/alarm',
                name: 'ENVIRONMENT_FAC_ALARM',
                component: () => import('@/views/common/alarm'),
            },
            {
                path: '/homepage/environment/controlRecord',
                name: 'ENVIRONMENT_FAC_HISTORY',
                // component: () => import('@/views/door/controlRecord'),//环境模块--操作控制记录（与门禁使用同一页面）
                component: () => import('@/views/energy/history'),
            },
            {
                path: '/homepage/environment/lightTimetable', //环境模块--时间表控制
                name: 'ENVIRONMENT_FAC_SCEDULE',
                component: () => import('@/views/light/lightTimetable'),
            },
            {
                path: '/homepage/environment/lightRunTimetable', //环境模块--执行时间表控制
                name: "ENVIRONMENT_FAC_RUN_SCEDULE",
                component: () => import('@/views/light/lightRunTimetable'),
            },
            {
                path: '/homepage/environment/linkage', //环境模块--设备联动控制组设置页面
                name: 'ENVIRONMENT_FAC_KIKIRENDOU_GR',
                component: () => import('@/views/common/controllGroupLinkage'),
            },
            {
                path: '/homepage/environment/linkageCtrl', //环境模块--设备联动控制
                name: 'ENVIRONMENT_FAC_KIKIRENDOU',
                component: () => import('@/views/common/controllLinkage'),
            },
            {
                path: '/homepage/environment/trendGR', //环境模块--趋势组
                name: 'ENVIRONMENT_FAC_TOLEND_GR',
                // component: () => import('@/views/common/trend/controllGroupTrend'),
                component: () => import('@/views/energy/trend/eneTrendGr'),
            },
            {
                path: '/homepage/environment/timeTrendGR', //环境模块--时间趋势组
                name: 'ENVIRONMENT_FAC_TIME_TLEND_GR',
                // component: () => import('@/views/common/trend/controllGroupTimeTrend'),
                component: () => import('@/views/energy/trend/eneTimeTrendGr'),
            },
            {
                path: '/homepage/environment/dataCompareTrend', //环境模块--数据比较趋势
                name: 'ENVIRONMENT_FAC_DATA_HIKAKU_TLEND',
                // component: () => import('@/views/common/trend/dataCompareTrend'),
                component: () => import('@/views/energy/trend/eneTrendChart'),
            },  
            {
                path: '/homepage/environment/dataCompareTrendList', //环境模块--数据比较趋势列表
                name: 'ENVIRONMENT_compareTrendList',
                component: () => import('@/views/common/trend/dataCompareTrendList'),
            },
            {
                path: '/homepage/environment/timeSetupTrend', //环境模块--时间设置趋势
                name: 'ENVIRONMENT_FAC_TIME_TLEND',
                // component: () => import('@/views/common/trend/timeSetupTrend'),
                
                component: () => import('@/views/energy/trend/eneTimeTrendChart'),
            }, 
            {
                path: '/homepage/environment/timeSetupTrendList', //环境模块--数据比较趋势列表
                name: 'ENVIRONMENT_timeSetupTrendList',
                component: () => import('@/views/common/trend/timeSetupTrendList'),
            }, 
            {
                path: '/homepage/environment/loggingData', //环境模块--数据输出
                name: 'ENVIRONMENT_FAC_DATA_OUTPUT',
                component: () => import('@/views/common/loggingData'),
            }, 
        ],
    },   
]