module.exports = {
    cleanEnergyAnalysis:{
        a1: 'Loading....',
        a2: 'Clean energy',
        a3: 'Detail data',
        a4: 'Proportional analysis',
        a5: 'Energy comparison',
        a6: 'Custom start month',
        a7: 'Query',
        a8: 'CSV export',
        a9: 'Please select criteria to query',
        a10: 'Power (kWh)',
        a11: 'Proportion(%)',
        a12: 'Cumulative (kWh)',
    }
}