// 空调设备页
module.exports = {
    airconditiondDevice:{
        a1: 'Loading...',
        a2: 'Icon',
        a3: 'Table',
        a4: 'Expand',
        a5: 'Put it away',
        a6: 'Batch Settings',
        a7: 'Normal',
        a8: 'Exception',
        a9: 'Upper and lower limit alarms',
        a10: 'Cooling',
        a11: 'Heating',
        a12: 'Air supply',
        a13: 'Dehumidification',
        a14: 'Low wind',
        a15: 'Stroke',
        a16: 'High wind',
        a17: 'Details page',
        a18: 'Close',
        a19: 'Confirm',
        a20: 'Switch',
        a21: 'Air conditioning operation mode',
        a22: 'Air conditioning wind speed',
        a23: 'Temperature setting',
        a24: 'On',
        a25: 'Off',
        a26: 'RT',
        a27: 'Maintenance or fault status',
        a28: 'Air conditioning cannot be set',
        a29: 'Shutdown status',
        a30: 'Air conditioning mode cannot be set',
        a31: 'pieces of data',
        a32: 'The air conditioning wind speed cannot be set',
        a33: 'Wind speed',
        a34: 'Maintenance',
        a35: 'Device name',
        a36: 'Device status',
        a37: 'Switch',
        a38: 'Set temperature',
        a39: 'Indoor temperature',
        a40: 'Operation',
        a41: 'List of failed batch settings',
        a42: 'Please select air conditioning equipment',
        a43: 'No air conditioning equipment selected',
        a44: 'Select operation',
        a45: 'Settings',
        a46: 'Air conditioning settings'
    }
}