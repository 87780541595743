// 操作记录 -- 中文简体
module.exports = {
    userOperationHistory:{
        a1:'操作记录',
        a2:'用户名称',
        a3:'登录ID',
        a4:'服务',
        a5:'画面名称',
        a6:'操作内容',
        a7:'设备名称',
        a8:'信号名称',
        a9:'发生日期',
        a10:'操作类型',
        a11:'操作时间',
        a12:'操作详情',
        a13:'操作项',
        a14:'原数据',
        a15:'新数据',
    }
}