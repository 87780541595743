import Vue from 'vue'
import Router from 'vue-router'


const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

// Separate constantRoutes and asyncRoutes to make logic and structure clearer
//constantRoutes:代表那些不需要动态判断权限的路由,如登录页、404、等通用页面
//asyncRoutes:代表那些需求动态判断权限并通过addRoutes动态添加的页面
export const constantRoutes = require('./constantRoutes').default
export const asyncRoutes = require('./asyncRoutes').default

const createRouter = () => new Router({
  mode: 'hash', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export const resetRouter = () => {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
