import { login, logout } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import AES from "@/utils/aes.js";
import { Base64 } from "js-base64";
// import JsEncrypt from 'jsencrypt'; //通过VUE引入，Vue打包扫描提示存在中危风险过期的YUI版本；所以改为在index.html中引入
const state = {
  token: getToken('Admin-Token'),
  user: sessionStorage.getItem('user'),
  name: '',
  avatar: '',
  roles: [],
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER: (state, user) => {
    state.user = user
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
}

const actions = {
  login({ commit }, userInfo) {
    let keys = AES.generatekey(16)
    let password1 = AES.encrypt(userInfo.password1, keys)
    let validateCode = userInfo.validateCode;
    let username = Base64.encode(userInfo.username)
    let encrypt = new JSEncrypt();
    console.log('encrypt',encrypt);
    encrypt.setPublicKey(userInfo.rsakey);
    let aeskey = encrypt.encrypt(keys);
    let locale = userInfo.locale;
    let data = {
      username,
      password1,
      validateCode,
      aeskey,
      locale,
    }
    return new Promise((resolve, reject) => {
      login(data).then(response => {
        console.log(response)
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken('Admin-Token',data.token)
        sessionStorage.setItem('user', JSON.stringify(data.user))
        commit('SET_USER', JSON.stringify(data.user))
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      let role = JSON.parse(sessionStorage.getItem('user')).userType.toFixed()
      let roles = []
      roles.push(role)
      commit('SET_ROLES', roles)
      resolve(roles)
    })
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout().then(response => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        resetRouter()
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken('Admin-Token')
      resolve()
    })
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise(async resolve => {
      const token = role + '-token'
      commit('SET_TOKEN', token)
      setToken('Admin-Token',token)
      const { roles } = await dispatch('getInfo')
      resetRouter()
      // generate accessible routes map based on roles
      const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
      // dynamically add accessible routes
      router.addRoutes(accessRoutes)
      // reset visited views and cached views
      dispatch('tagsView/delAllViews', null, { root: true })
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
