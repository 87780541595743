<!--全界面布局-->
<template>
  <div id="home">
    <div class="sidebar">
      <my-sidebar></my-sidebar>
    </div>
    <div class="main" v-loading="loading" :element-loading-text="$t('alarm.a0')">
      <transition name="slide-fade" mode="out-in">
        <router-view v-if="isRouterAlive"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import sidebar from "../components/Sidebar/index";

export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return { 
      isRouterAlive: true,
    };
  },
  computed: {
    loading: function () {
      let loading = this.$store.getters.loading;
      return loading;
    },
  },
  mounted(){

  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
  components: {
    "my-sidebar": sidebar,
  },
};
</script>

<style scoped>
#home {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
.sidebar {
  position: relative;
  width: 110px;
  height: 100%;
  box-shadow: 0px 0px 15px #c3c0c0;
}
.main {
  width: calc(100% - 110px);
  height: 100%;
  background: #f3f3f7;
  overflow: hidden;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>