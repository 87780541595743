// 清洁能源设置
module.exports = {
    cleanEnergySetting:{
        a1:'清洁能源设置',
        a2:'请选择分组',
        a3:'总发电量',
        a4:'并网电量',
        a5:'自用电量',
        a6:'总用电量',
        a7:'电网取电',
        a8:'请选择分组',
        a9:'重置',
        a10:'设置',
        a11:'添加分组',
        a12:'取消',
        a13:'确认',
        a14:'分组名称',
        a15:'请输入名称',
        a16:'分组名称修改',
        a17:'确定删除报表'
    }
}