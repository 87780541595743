// 门禁模块 -- 设备页
module.exports = {
    doorequipment:{
        a1: '类型选择',
        a2: '楼层/租户选择',
        a3: '全部',
        a4: '门',
        a5: '认证终端',
        a6: '正常',
        a7: '通讯异常',
        a8: '维护',
        a9: '机器异常',
        a10: '故障/警报',
        a11: '维护警报/上下限警报·运行异常',
        a12: '加载中....',
        a13: '卡片类型有误',
        a14: '操作成功，开门按钮将在5秒钟后恢复关闭状态',
        a15: '开门操作失败',
        a16: '图标',
        a17: '表格',
        a18: '展开',
        a19: '收起',
        a20: '详情',
        a21: '条数据',
        a22: '楼层/租户',
        a23: '设备名称',
        a24: '开关',
        a25: '视频',
        a26: '状态',
        a27: '操作',
        a28:'故障'
    }
}