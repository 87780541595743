// 清洁能源流向
module.exports = {
    cleanEnergyFlows:{
        a1:'加载中....',
        a2:'清洁能源流向',
        a3:'电量',
        a4:'碳足迹',
        a5:'自定义起始月',
        a6:'查询',
        a7:'请选择条件进行查询',
        a8:'计量点',
        a9:'碳足迹'
    }
}