// 能效KPI
module.exports = {
    analyseKPI:{
        a1: 'Loading....',
        a2: 'Energy efficiency KPI',
        a3: 'Search',
        a4: 'Actual value',
        a5: 'Goal achievement',
        a6: 'Query',
        a7: 'CSV download',
        a8: 'Please select a device to query',
        a9: 'No device selected',
        a10: 'Year',
        a11: 'Month',
        a12: 'Day',
        a13: 'KPI report',
        a14: 'Annual target value',
        a15: 'Monthly target value',
        a16: 'Monthly actual value',
        a17: 'Annual cumulative value',
        a18: 'Daily target value',
        a19: 'Daily actual value',
        a20: 'Monthly cumulative value',
        a21: 'Monthly value',
        a22: 'Annual value',
        a23: 'Goal achieved',
        a24: 'Goal not achieved',
        a25: 'Cumulative power consumption progress',
        a26: 'Estimated power consumption progress',
        a27: 'Power consumption',
        a28: 'Target value',
        a29: 'Cumulative value',
        a30: 'Hourly usage',
        a31: 'Cumulative quantity',
        a32: 'Daily value',
        a33: 'Unit:'
    }
}