// 操作记录 -- 中文简体
module.exports = {
    userOperationHistory:{
        a1:'操作記録',
        a2:'ユーザー名',
        a3:'ログインID',
        a4:'サービス',
        a5:'画面名',
        a6:'操作内容',
        a7:'デバイス名',
        a8:'信号名',
        a9:'発生日',
        a10:'操作タイプ',
        a11:'操作時間',
        a12:'操作詳細',
        a13:'操作項目',
        a14:'原データ',
        a15:'新しいデータ',
    }
}