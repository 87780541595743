<!--主页侧边栏--头象-->
<template>
  <div id="avatar">
    <div class="rahmen" :title="$t('sidebar.a1')">
      <img src="../../../../public/images/sidebar/headPortrait.png" @click="showDrawer"/>
      <div class="name">
        <span>{{ username }}</span>
      </div>
    </div>
    <!-- 详情和密码修改抽屉 -->
    <a-drawer :title="$t('sidebar.a2')" width="300" placement="left" :closable="true" :zIndex="2009" :visible="visible" @close="onClose">
      <div class="header">
        <a-row class="row">
          <a-col :span="10">{{ $t("sidebar.a3") }}</a-col>
          <a-col :span="14">{{ username }}</a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="10">{{ $t("sidebar.a4") }}</a-col>
          <a-col :span="14">{{ loginName }}</a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="10">{{ $t("sidebar.a5") }}</a-col>
          <a-col :span="14">{{ usermail }}</a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="10">{{ $t("sidebar.a18") }}</a-col>
          <a-col :span="14">{{ phone }}</a-col>
        </a-row>
      </div>
      <div class="floor">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-button class ="button" type="primary" ghost @click="changePwd" style="width:100%">{{ $t("sidebar.a26") }}</a-button>
          </a-col>
          <a-col :span="24">
            <a-button class ="button" type="primary" ghost @click="edit" style="width:100%">{{ $t("sidebar.a29") }}</a-button>
          </a-col>
        </a-row>
      </div>
      <!-- 用户设置 -->
      <a-drawer :title="$t('sidebar.a26')" width="600" placement="left" :closable="true" :zIndex="2009" :visible="userDrawer" @close="userDrawerClose" :destroyOnClose="true">
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-model-item :label="$t('sidebar.a4')" prop="loginName">
                <a-input :placeholder="$t('sidebar.a31')" v-model="form.loginName" allow-clear />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('sidebar.a5')">
                <a-input :placeholder="$t('sidebar.a32')" v-model="form.email" allow-clear />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-model-item :label="$t('sidebar.a7')" prop="pwdOld1">
                <a-input-password :placeholder="$t('sidebar.a9')" v-model="form.pwdOld1" allow-clear />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('sidebar.a8')" prop="pwdOld2">
                <a-input-password :placeholder="$t('sidebar.a9')" v-model="form.pwdOld2" allow-clear/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-model-item>
                <a-checkbox v-model="form.isPwd"> {{ $t("sidebar.a30") }} </a-checkbox>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
            </a-col>
          </a-row>
          <transition name="slide-fade">
            <div v-if="form.isPwd">
              <a-row :gutter="16" >
                <a-col :span="12">
                  <a-form-model-item :label="$t('sidebar.a12')" prop="pwd1">
                    <a-input-password :placeholder="$t('sidebar.a9')" v-model="form.pwd1" allow-clear/>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item :label="$t('sidebar.a27')" prop="pwd1Check">
                    <a-input-password :placeholder="$t('sidebar.a9')" v-model="form.pwd1Check" allow-clear/>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-model-item :label="$t('sidebar.a13')" prop="pwd2">
                    <a-input-password :placeholder="$t('sidebar.a9')" v-model="form.pwd2" allow-clear/>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item :label="$t('sidebar.a28')" prop="pwd2Check">
                    <a-input-password :placeholder="$t('sidebar.a9')" v-model="form.pwd2Check" allow-clear/>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>
          </transition>
        </a-form-model>
        <div class="drawerFloor">
          <a-button class="margin" type="primary" @click="changePassword" :loading="userLoading">{{ $t("sidebar.a11") }}</a-button>
        </div>
      </a-drawer>
      <!-- 微信设置 -->
      <a-drawer :title="$t('sidebar.a29')" width="400" placement="left" :closable="true" :zIndex="2009" :visible="weChatDrawer" @close="weChatDrawerClose" :destroyOnClose="true">
        <a-form-model ref="ruleForm1" :model="form" :rules="rules1">
          <a-form-model-item :label="$t('sidebar.a19')" prop="wechat">
            <a-checkbox  v-model="form.wechat" />
          </a-form-model-item>
          <a-form-model-item  :label="$t('sidebar.a22')" prop="wechatUserName">
            <a-input :disabled="!form.wechat" v-model="form.wechatUserName" />
          </a-form-model-item>
          <a-form-model-item :label="$t('sidebar.a18')" prop="phone">
            <a-input :disabled="!form.wechat" v-model="form.phone" />
          </a-form-model-item>
          <a-form-model-item :label="$t('sidebar.a5')" prop="email">
            <a-input :disabled="!form.wechat" v-model="form.email" />
          </a-form-model-item>
        </a-form-model>
        <div class="drawerFloor">
          <a-button class="margin" type="primary" @click="weChatSetting" :loading="weChatLoading">{{ $t("sidebar.a11") }}</a-button>
        </div>
      </a-drawer>
    </a-drawer>
  </div>
</template>

<script>
import AES from "@/utils/aes.js"
import { userPasswordUpdate, updateUserInfo, userUpdate } from "../../../api/setManagement"
import { Base64 } from "js-base64"
import { getRsa } from "../../../api/user"
import Cookies from 'js-cookie'
const ex = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}$/

export default {
  data() {
    let pass1Validate1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t("sidebar.a9")));//'Please input the password'
      } else  if (!ex.test(value)) {
        callback(new Error(this.$t("sidebar.a24")));//'密码格式错误,密码需包括特殊字符、数字、字母三种组合；且密码长度在8~20之间。'
      } else {
        if (this.form.pwd1Check !== '') {
          this.$refs.ruleForm.validateField('pwd1Check');
        }
        if(this.form.pwd2 !== ''){
          this.$refs.ruleForm.validateField('pwd2');
        }
        callback();
      }
    };
    let pass1Validate2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t("sidebar.a9")));
      } else  if (!ex.test(value)) {
       callback(new Error(this.$t("sidebar.a24")));//'密码格式错误,密码需包括特殊字符、数字、字母三种组合；且密码长度在8~20之间。'
      } else if (value !== this.form.pwd1) {
        callback(new Error(this.$t("sidebar.a23")));//Two inputs don't match! 密码两次输入不匹配！
      } else {
        callback();
      }
    };

    let pass2Validate1 = (rule, value, callback) => {
       if (value === '') {
        callback(new Error(this.$t("sidebar.a9")));
      } else if (value === this.form.pwd1) {
        callback(new Error(this.$t("sidebar.a25")));
      } else  if (!ex.test(value)) {
       callback(new Error(this.$t("sidebar.a24")));//'密码格式错误,密码需包括特殊字符、数字、字母三种组合；且密码长度在8~20之间。'
      }else {
        if (this.form.pwd2Check !== '') {
          this.$refs.ruleForm.validateField('pwd2Check');
        }
        callback();
      }
    };
    let pass2Validate2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t("sidebar.a9")));
      } else  if (!ex.test(value)) {
        callback(new Error(this.$t("sidebar.a24")));//'密码格式错误,密码需包括特殊字符、数字、字母三种组合；且密码长度在8~20之间。'
      } else if (value !== this.form.pwd2) {
        callback(new Error(this.$t("sidebar.a23")));//Two inputs don't match! 密码两次输入不匹配！
      } else {
        callback();
      }
    };

    return {
      visible: false,
      rsa: "",
      user: {},
      visible1: false,
      confirmLoading: false,
      Pageselection: 0,
      form: {
        phone:"",
        email:"",
        wechat:false,
        wechatUserName:"",
        pwdOld1: "",
        pwdOld2: "",
        pwd1: "",
        pwd1Check: "",
        pwd2: "",
        pwd2Check: "",
        loginName:"",
        isPwd: false,
      },
      rules1:{
        phone:[
          {
            required: true,
            message: () => {
              return this.$t("sidebar.a20");
            },
            trigger: "change",
          },
        ],
        wechatUserName:[
          {
            required: true,
            message: () => {
              return this.$t("sidebar.a21");
            },
            trigger: "change",
          },
        ],        
      },
      rules: {
        loginName: [{ required: true, message: () => { return this.$t("sidebar.a9"); }, trigger: "change" }],
        pwdOld1: [{ required: true, message: () => { return this.$t("sidebar.a9"); }, trigger: "change" }],
        pwdOld2: [{ required: true, message: () => { return this.$t("sidebar.a9"); }, trigger: "change" }],
        pwd1: [
          { required: true, message: () => { return this.$t("sidebar.a9"); }, trigger: "change" },
          { validator: pass1Validate1, trigger: 'change' }
        ],
        pwd1Check: [
          { required: true, message: () => { return this.$t("sidebar.a9"); }, trigger: "change" },
          { validator: pass1Validate2, trigger: 'change' }
        ],
        pwd2: [
          { required: true, message: () => { return this.$t("sidebar.a9"); }, trigger: "change" },
          { validator: pass2Validate1, trigger: 'change' }
        ],
        pwd2Check: [
          { required: true, message: () => { return this.$t("sidebar.a9"); }, trigger: "change" },
          { validator: pass2Validate2, trigger: 'change' }
        ]
      },
      userDrawer: false,
      weChatDrawer: false,
      isPwd: true,
      userLoading: false,
      weChatLoading: false
    }
  },
  computed: {
    username: function () {
      const value = JSON.parse(this.$store.getters.user).name
      if (!value) {
        return "----"
      } else {
        return value
      }
    },
    usermail: function () {
      const value = JSON.parse(this.$store.getters.user).mailAddress
      if (!value) {
        return "----"
      } else {
        return value
      }
    },
    loginName() {
      const value = JSON.parse(this.$store.getters.user).loginName
      if (!value) {
        return "----"
      } else {
        return value
      }
    },
    phone(){
      const value = JSON.parse(this.$store.getters.user).mobilePhone
      if (!value) {
        return "----"
      } else {
        return value
      }
    }
  },
  created() {
    // 获取服务端Ras公钥
    getRsa()
      .then((res) => {
        this.rsa = res.data
        // console.log("RSA公钥", res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    // 关闭用户设置对话框
    userDrawerClose(){ 
      this.userDrawer = false 
    },
    // 关闭微信设置对话框
    weChatDrawerClose(){ 
      this.weChatDrawer = false 
    },
    // 打开用户设置对话框
    changePwd() {
      const user = JSON.parse(this.$store.getters.user)
      if(user){
        this.form.loginName = user.loginName
        this.form.email = user.mailAddress
        this.form.pwdOld1 = ''
        this.form.pwdOld2 = ''
        this.form.pwd1 = ''
        this.form.pwd2 = ''
        this.form.pwd1Check = ''
        this.form.pwd2Check = ''
        this.form.isPwd = false
      }
      this.userDrawer = true
    },
    // 打开微信设置框
    edit(){
      this.weChatDrawer = true
      const user = JSON.parse(this.$store.getters.user)
      if(user){
        this.form.email = user.mailAddress
        this.form.phone = user.mobilePhone
        this.form.wechat = user.wxFlg == 1 ? true : false
        this.form.wechatUserName = user.wxName
      }
    },
    //修改微信设置
    weChatSetting(){
      this.$refs.ruleForm1.validate((valid) => {
        if (valid) {
          const user = JSON.parse(this.$store.getters.user)
          console.log("weChatSetting user:", user)
          this.weChatLoading = true
          const para = {
            id: user.id,
            mailAddress: this.form.email,
            mobilePhone: this.form.phone,
            notificationGeneralLevel: 0,
            notificationSeriousLevel: 0,
            wxFlg: this.form.wechat?1:0,
            wxName: this.form.wechatUserName
          }
          console.log("weChatSetting para:", para)
          updateUserInfo(para)
            .then((res) => {
              if (res.data) {
                this.weChatLoading = false
                this.$message.success(res.msg)
                this.weChatDrawerClose()
                // this.onClose()
                // this.logout()
                // console.log(sessionStorage.getItem('user'))
                const user = JSON.parse(sessionStorage.getItem('user'))
                user.mobilePhone = this.form.phone
                user.mailAddress = this.form.email
                user.wxName=this.form.wechatUserName
                user.wxFlg=this.form.wechat?1:0,
                sessionStorage.setItem('user',JSON.stringify(user))
                this.$store.commit('user/SET_USER', JSON.stringify(user))
              } else this.$message.error(res.msg)
            })
            .catch((err) => {
              console.log(err)
              this.weChatLoading = false
            });
        }else {
          console.log("error submit!!")
          return false
        }
      });
    },
    // 修改密码
    changePassword() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log("changePassword user:",JSON.parse(this.$store.getters.user))
          this.userLoading = true
          const value = JSON.parse(this.$store.getters.user)
          const keys = AES.generatekey(16)
          const loginName = Base64.encode(this.form.loginName)
          const encrypt = new JSEncrypt()
          encrypt.setPublicKey(this.rsa)
          const aeskey = encrypt.encrypt(keys)
          const email = Base64.encode(this.form.email)
          const isPwd = this.form.isPwd
          const pwd1 = AES.encrypt(this.form.pwd1, keys)
          const pwd2 = AES.encrypt(this.form.pwd2, keys)
          const pwdOld1 = AES.encrypt(this.form.pwdOld1, keys)
          const pwdOld2 = AES.encrypt(this.form.pwdOld2, keys)
          const data = {
            aeskey: aeskey,
            isPwd,
            loginName: loginName,
            pwdOld1,
            pwdOld2,
            userId: value.id,
            email,
            pwd1,
            pwd2
          }
          // console.log("changePassword data:", data)
          userPasswordUpdate(data)
            .then((res) => {
              console.log(res)
              this.userLoading = false
              if (res.data) {
                this.$message.success(res.msg + "," + this.$t("sidebar.a14"))
                this.logout()
              } else{
                this.$message.error(res.msg)
              } 
            })
            .catch((err) => {
              console.log(err)
              this.userLoading = false
            });
        } else {
          console.log("error submit!!")
          return false
        }
      });
    },
    // 点击头像弹出账户详情框
    showDrawer() {
      this.visible = true
    },
    onClose() {
      this.visible = false
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      sessionStorage.clear();
      Cookies.remove('webVideoCtrlProxy');
      Cookies.remove('WebSession_*');
      Cookies.remove('webSmartDisplayProxy');
      this.$store.commit("menu/outLogin");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
  },
};
</script>

<style scoped>
#avatar {
  width: 100%;
  padding: 30px 0 30px 0;
}
.rahmen {
  width: 100%;
  padding: 0 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rahmen img {
  width: 60px;
  border-radius: 50%;
  border: 1px solid rgba(12, 12, 12, 0.2);
  transition: 200ms ease-out;
  cursor: pointer;
}
.rahmen img:hover {
  box-shadow: 0 0px 5px #7682ce;
}
.name {
  width: 70px;
  padding: 10px 0;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.header {
  margin-bottom: 40px;
}
.row{
  margin-bottom: 15px;
}
.drawerFloor{
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: '#fff';
  border-radius: '0 0 4px 4px';
}
.main {
  position: relative;
  width: 100%;
}
.tabs {
  position: absolute;
  width: 100%;
}
.floor {
  margin-top: 100px;
}
.margin {
  margin-left: 20px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.button{
  margin-right: 10px;
}
</style>