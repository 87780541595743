// 电能--能耗流向
module.exports = {
    energyFlow:{
        a1:'能耗流向图',
        a2:'能耗',
        a3:'碳足迹',
        a4:'客户',
        a5:'建筑',
        a6:'站点',
        a7:'显示内容',
        a8:'显示分组',
        a9:'显示设备',
        a10:'显示租户',
        a11:'年',
        a12:'月',
        a13:'日',
        a14:'自定义起始月',
        a15:'1月',
        a16:'2月',
        a17:'3月',
        a18:'4月',
        a19:'5月',
        a20:'6月',
        a21:'7月',
        a22:'8月',
        a23:'9月',
        a24:'10月',
        a25:'11月',
        a26:'12月',
        a27:'查询',
        a28:'请选择条件进行查询',
        a29:'计量点',
    }
}