<!--主页侧边栏--站点主页-->
<template>
    <div id="visualing" :title="$t('sidebar.d2')" @click="goSite">
        <div class="image">
            <img :src="normalImage" />
        </div>
    </div>
  </template>

  <script>
  import { getToken } from '@/utils/auth' // get token from cookie
  export default {
    data() {
      return {
        normalImage: require("../../../../public/images/sidebar/visualing_selected.png"),
      };
    },
    methods:{
        goSite(){
            const { origin: routerOrigin, port } = window.location;
            const userToken = getToken('Admin-Token');
            let bigScreenByPort = Number(port);
            bigScreenByPort+=1;
            console.log('bigScreenByPort:', bigScreenByPort);
            let pageLocation = null;
            if (process.env.NODE_ENV === "development") {
                pageLocation = `http://localhost:${bigScreenByPort}/index/${encodeURIComponent(userToken)}`;
            } else {
                pageLocation = `http://175.178.255.96:1688/index/${encodeURIComponent(userToken)}`;
            }
            window.open(pageLocation,'_blank');
        }
    }
  };
  </script>

  <style scoped>
  #visualing {
    width: 80px;
    height: 80px;
    flex-shrink:0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
  }
  img {
    width: 100%;
  }
  .image {
    width: 55px;
    height: 55px;
    border: 1px solid #f2f4f7;
    border-radius: 50%;
    transition: 200ms ease;
  }
  .image:hover {
    width: 60px;
    height: 60px;
    box-shadow: 0 0px 10px #7682ce;
  }
  </style>