// 中文繁体
module.exports = {
  login: {
    Q1: '登錄',
    Q2: '用戶名',
    Q3: '密碼',
    Q4: '驗證碼',
    Q5: '語言',
    Q6: '登錄',
    Q7: '請輸入密碼',
    Q8: '請輸入用戶名',
    Q18:"登錄失敗",
    Q9 :"重新輸入",
    Q10:"登錄失效",
    Q11:"重新登錄",
    Q12:"帳戶或密碼錯誤",
    Q13:"該帳戶已在其他地方登錄",
    Q14:"帳戶已鎖定，請稍後再試",
    Q15:"系統錯誤",
    Q16:"密碼過期",
    Q17:"您的登錄狀態已經失效，請重新登錄",
    Q19:'忘記密碼？',
    Q20:'重置密碼',
    Q21:'認證碼',
    Q22:'請輸入認證碼！',
    Q23:'電子郵箱',
    Q24:'請輸入電子郵箱！',
    Q25:'輸入的電子郵箱無效！',
    Q26:'發送',
    Q27:'返回登入',
    Q28:'請輸入ID與認證碼及電子郵箱,並點擊發送按鈕。',
    Q29:'將給事先注册的電子郵箱發送新密碼1和密碼2。',
    Q30:"請輸入驗證碼",
    ERR0:'密碼重置成功,請注意查收電子郵件。',
    ERR1:'用戶名、認證碼或電子郵寄地址錯誤。',
    ERR2:'用戶已登入。',
    ERR3:'郵件發送失敗。',
    ERR4:'超過密碼規定重置次數。',
    ERR5:'密碼重置操作無效。',
    ERR6:'密碼重置失敗。',
  },
  // 侧边栏 a:头像/b:站点/c:认证信息/d:设置管理/e:通知/f:帮助/g:退出
  sidebar: {
    a1: '我的',
    a2: '我的IBMS',
    b1: '站點',
    c1: '人員管理',
    c2: '卡片資訊',
    c3: '人員資訊',
    d1: '設置管理',
    e1: '通知',
    e2: '警報',
    f1: '幫助',
    f2: 'CSV 導出',
    f3: '打印',
    f4: '幫助',
    f5: '站點聲明',
    g1: '退出登錄',
    g2: '是否確定退出登錄',
    g3: '退出登錄后須重新登錄，才能重新操作',
    a3: '用戶名稱：',
    a4: '登入帳戶：',
    a5: '郵箱：',
    a6: '修改密碼',
    a7: '密碼1',
    a8: '密碼2',
    a9: '請輸入密碼',
    a10: '關閉',
    a11: '確定',
    a12: '新密碼1',
    a13: '新密碼2',
    a14: '請重新登錄',
    a15: '能效看板',
    a16: '更多',
    a17: '編輯',
    a18: '手機：',
    a19: '是否開通微信',
    a20: '請輸入手機號',
    a21: '請輸入微信用戶名',
    a22: '微信用戶名：',
    a23: '密碼兩次輸入不匹配！',
    a24: '密碼格式錯誤,密碼需包括特殊字元、數位、字母三種組合； 且密碼長度在8~16之間。',
    a25: '密碼1和密碼2不能相同,請重新輸入！',
    a26: '用戶設置',
    a27: '確認新密碼1',
    a28: '確認新密碼2',
    a29: '微信設定',
    a30: '重置密碼',
    a31: '輸入登入帳戶',
    a32: '輸入郵箱'
  },
  // 门禁模块--报警页
  alarm: {
    a0: '加載中….',
    a1: '警報',
    a2: '設備名稱',
    a3: '樓層/租戶選擇',
    a4: '初始日期/時間選擇',
    a5: '終止日期/時間選擇',
    a6: '査詢',
    a7: '警報清單',
    a8: '條數據',
    a9: '警報確認',
    a10: '手動恢復',
    a11: '應對',
    a12: '順比特恢復',
    a13: '警報輸出',
    a14: '添加注釋',
    a15: '選擇操作',
    a16: '添加注釋',
    a17: '請輸入內容……',
    a18: '請選擇日期或時間',
    a19: '加載中….',
    a20: '發生日期',
    a21: '恢復日期',
    a22: '發報類型',
    a23: '警報類型',
    a24: '建築物名稱',
    a25: '設備名稱',
    a26: '視頻',
    a27: '訊號/終端名稱',
    a28: '警報內容',
    a29: '應對狀態',
    a30: '姓名',
    a31: '卡ID',
    a32: '體溫',
    a33: '注釋',
    a34: '操作',
    a35: '未確認',
    a36: '未恢復',
    a37: '未處理',
    a38: '全部',
    a39: '請輸入裝置名稱',
    a40: '取消',
    a41: '確認',
    a42: '處理狀態變更',
    a43: '現時的應對狀態：',
    a44: '變更後：',
    a45: '處理中',
    a46: '處理後',
    a47: '確定要進行被選擇的警報的確認登記嗎？',
    a48: '確定要進行手動修復嗎？',
    a49: '警報狀態',
    a50: '顯示內容',
    a51: 'CSV導出',
    a52: 'KPI類型',
    a53: '當前值',
    a54: '限值',
    a55: '死區',
    a56: '占比（%）',
    a57: '警報等級',
    a58:'請選擇網站資訊',
  },
  // 门禁模块--卡片记录（通行记录）
  throughrecords: {
    a0: '加載中….',
    a1: '卡片記錄',
    a2: '姓名',
    a3: '卡片編號',
    a4: '初始日期/時間選擇',
    a5: '終止日期/時間選擇',
    a6: '査詢',
    a7: '記錄清單',
    a8: '請選擇日期或時間',
    a9: '人臉識別圖片',
    a10: '添加注釋',
    a11: '選擇操作',
    a12: '注釋',
    a13: '取消',
    a14: '確認',
    a15: '請輸入內容….',
    a16: '條數據',
    a17: '發生日期',
    a18: '認證類型',
    a19: '姓名',
    a20: '卡片編號',
    a21: '體溫',
    a22: '設備組名稱',
    a23: '設備名稱',
    a24: '訊號/終端名稱',
    a25: '建築物名稱',
    a26: '注釋',
    a27: '操作',
    a28: '請輸入姓名',
    a29: '請輸入卡片編號',
    a30: '地點',
    a31: '口罩',
    a32: '人臉照片讀取失敗，請稍後再試。',
    a33: '管理代碼',
    a34: '人臉識別記錄',
    a35: '訪客通行記錄',
    a36: '請輸入管理程式碼',
    a37: '請輸入信號/總端名稱',
    a38: '請輸入裝置組名稱',
    a39: '請輸入裝置名稱',
  },
  // 门禁模块--考勤记录
  attendance: {
    a1: '加載中....',
    a2: '考勤記錄',
    a3: '人員所屬',
    a4: '員工編號',
    a5: '人員姓名',
    a6: '初始日期/時間選擇',
    a7: '終止日期/時間選擇',
    a8: '査詢',
    a9: '考勤記錄清單',
    a10: '請選擇日期或時間',
    a11: '條數據',
    a12: '員工編號',
    a13: '員工姓名',
    a14: '考勤日期',
    a15: '考勤開始時間',
    a16: '考勤結束時間',
    a17: '請輸入人員所屬',
    a18: '請輸入員工編號',
    a19: '請輸入人員姓名',
  },
  // 门禁模块--滞留人员显示
  strandedPersonnel: {
    a1: '加載中....',
    a2: '滯留人員顯示',
    a3: '區域選擇',
    a4: '査詢',
    a5: '滯留人員清單',
    a6: '删除',
    a7: '超時設定',
    a8: '設定',
    a9: '關閉',
    a10: '超過時間',
    a11: 'HH時mm分',
    a12: '條數據',
    a13: '區域名稱',
    a14: '姓名',
    a15: '進門時間',
    a16: '卡號碼',
    a17: '滯留時間',
    a18: '部門',
    a19: '所屬1',
    a20: '所屬2',
    a21: '所屬3',
    a22: '所屬4',
    a23: '所屬5',
    a24: '操作',
    a25: '確定要删除',
    a26: '的資訊',
    a27: '請選擇時間',
  },
  // 门禁模块--室内人员显示
  indoorPersonne: {
    a1: '加載中....',
    a2: '室內人員顯示',
    a3: '區域選擇',
    a4: '査詢',
    a5: '室內人員清單',
    a6: '重置',
    a7: '室內人員設定',
    a8: '設定',
    a9: '關閉',
    a10: '卡編號',
    a11: '請輸入卡編號',
    a12: '管理代碼',
    a13: '請輸入管理代碼',
    a14: '査詢',
    a15: '所有個人清單',
    a16: '添加',
    a17: '登記人員清單',
    a18: '删除',
    a19: '條數據',
    a20: '區域名稱',
    a21: '姓名',
    a22: '部門',
    a23: '在/不在',
    a24: '進門/出門時間',
    a25: '所屬1',
    a26: '分組名稱',
    a27: '卡編號',
    a28: '所屬2',
    a29: '所屬3',
    a30: '所屬4',
    a31: '所屬5',
    a32: '操作',
    a33: '卡編號',
    a34: '管理代碼',
    a35: '姓名',
    a36: '所屬1',
    a37: '操作',
    a38: '確定要更新',
    a39: '的資訊',
    a40: '請選擇區域',
  },
  // 门禁模块--门禁时间表
  doortimetable: {
    a1: '加載中....',
    a2: '門禁時間表',
    a3: '時間表名稱：',
    a4: '取消',
    a5: '確認',
    a6: '卡模式',
    a7: '自由模式',
    a8: '重複模式',
    a9: '進入卡/退出自由模式',
    a10: '進入自由/退出卡模式',
    a11: '卡認證',
    a12: '卡認證+數字鍵認證',
    a13: '卡認證+指靜脈認證',
    a14: '不可通行',
    a15: '可通行',
    a16: '非警戒',
    a17: '警戒中',
    a18: '警戒無效時間段',
    a19: '時間表間複製',
    a20: '時間表複製',
    a21: '時間表全錶複製',
    a22: '設定',
    a23: '取消',
    a24: '複製原時間表',
    a25: '請選擇時間表',
    a26: '時間表複製',
    a27: '複製源',
    a28: '複製到',
    a29: '星期一',
    a30: '星期二',
    a31: '星期三',
    a32: '星期四',
    a33: '星期五',
    a34: '星期六',
    a35: '星期日',
    a36: '假日',
    a37: '特日1',
    a38: '特日2',
    a39: '特日3',
    a40: '特日4',
    a41: '星期沒有被正確指定，請再次指定',
    a42: '沒有選擇時間表，請選擇時間表',
    a43: '沒有選擇星期，請選擇星期',
    a44: '請先設置日曆',
    a45: '請輸入時間表名稱',
    a46: '時間表名稱更新',
    b1: '時間表變更',
    b2: '時間表類型：',
    b3: '時間表名稱：',
    b4: '項目：',
    b5: '由於初始值是“卡模式"，所以請設定其它的模式。',
    b6: '模式1',
    b7: '模式2',
    b8: '模式3',
    b9: '模式4',
    b10: '模式5',
    b11: '重置',
    b12: '門時間表',
    b13: '卡認證時間表',
    b14: '通行時間表',
    b15: '警備時間表',
    b16: '確定要進行設定內容注册嗎？',
    b17: '“時間沒有被正確指定，請再次指定',
    b18: '時',
    b19: '分'
  },
  // 门禁模块--车牌管理
  plateNumber: {
    a1: '加載中',
    a2: '車牌管理',
    a3: '管理代碼',
    a4: '請輸入管理代碼',
    a5: '人員姓名',
    a6: '請輸入姓名',
    a7: '手機號碼',
    a8: '請輸入手機號碼',
    a9: '車牌號碼',
    a10: '請輸入車牌號碼',
    a11: '車牌顏色',
    a12: '無',
    a13: '白牌',
    a14: '黑牌',
    a15: '藍牌',
    a16: '黃牌',
    a17: '綠牌',
    a18: '認證模式',
    a19: '無',
    a20: '白名單',
    a21: '黑名單',
    a22: '臨時車',
    a23: 'VIP車',
    a24: '月租車',
    a25: '時間',
    a26: '無',
    a27: '開始日期',
    a28: '有效日期',
    a29: '査詢',
    a30: '車牌清單',
    a31: '删除',
    a32: '人員車牌管理',
    a33: '車牌管理',
    a34: '選擇操作',
    a35: '批量車牌管理',
    a36: '添加車牌',
    a37: '人員車牌管理',
    a38: '設定',
    a39: '關閉',
    a40: '基本資訊',
    a41: '管理代碼',
    a42: '姓名',
    a43: '部門',
    a44: '手機號碼',
    a45: '郵箱',
    a46: '所屬站點',
    a47: '所屬分組',
    a48: '車牌資訊',
    a49: '添加車牌',
    a50: '車牌所屬',
    a51: '車身顏色',
    a52: '汽車品牌',
    a53: '認證模式',
    a54: '開始日期',
    a55: '有效日期',
    a56: '請輸入手機號碼',
    a57: '請輸入車牌號碼',
    a58: '請選擇時間',
    a59: '條數據',
    a60: '操作',
    a61: '開始日期',
    a62: '請選擇開始日期',
    a63: '確定',
    a64: '結束日期',
    a65: '請選擇結束日期',
    a66: '每個人最多添加5塊車牌',
    a67: '手機號碼格式錯誤',
    a68: '車牌號碼格式錯誤',
    a69: '請選擇認證模式',
    a71:"白名單",
    a72:"黑名單",
    a73:"臨時車",
    a74:"VIP車",
    a75:"約租車",
    a76:"免檢車",
    a77:"非免檢車",
    a78:"臨時卡",
    a79:"長租卡",
    a80:"月租卡",
    a81:"貴賓卡",
    a82:"萬全卡",
    a83:"儲值卡",
    a84:"免費卡",
    a85:"訪客卡",
    a86: "確認要删除車牌嗎？",
    a87:'請輸入車牌所屬人員資訊。',
    a88:'請選擇車牌顏色',
    a89:'下載範本檔案',
    a90:'上傳車牌資訊',
    a91:'匯出車牌資訊',
    a92:'通行區域',
    a93:'車主性別',
    a94:'女',
    a95:'男',
    a96:'組織部門',
    a97:'請選擇組織部門',
    a98:'協力廠商系統',
    a99:'同步數據',
    a100:'請選擇通行區域',
    a101:'導入檔案範本',
    a102:'人員類型',
    a103:'系統人員',
    a104:'普通人員',
    a105:'請選擇所屬網站',
    a106:'請選擇人員資訊',
    a107:'輸入的電子郵件無效！',
    a108:'確認要保存所設定的車牌資訊嗎？',
    a109:'選擇導入檔案',
    a110:'車牌資訊導入失敗。',
    a111:'批量上傳車牌資訊',
  },
  // 门禁模块--室内人员区域
  indoorArea: {
    a1: '加載中....',
    a2: '室內人員區域',
    a3: '區域選擇',
    a4: '査詢',
    a5: '登記的門',
    a6: '添加區域/分組',
    a7: '删除區域/分組',
    a8: '區域編輯',
    a9: '變更名稱',
    a10: '設定',
    a11: '關閉',
    a12: '添加分組/區域選擇',
    a13: '分組',
    a14: '區域',
    a15: '分組/區域名稱',
    a16: '室內人員區域名稱',
    a17: '室內區域設置',
    a18: '區域名稱：',
    a19: '所有的門',
    a20: '添加',
    a21: '已注册的門',
    a22: '删除',
    a23: '請輸入區域名稱',
    a24: '條數據',
    a25: '設備名稱',
    a26: '認證終端名稱',
    a27: '樓層·租戶名稱',
    a28: '操作',
    a29: '確定要添加分組/區域嗎？',
    a30: '請輸入分組/區域名稱',
    a31: '確定要删除',
    a32: '嗎？',
    a33: '確定要進行設定內容注册嗎？',
  },
  // 门禁模块--滞留人员区域
  detentionArea: {
    a1: '加載中....',
    a2: '滯留人員區域',
    a3: '區域選擇',
    a4: '査詢',
    a5: '登記的門',
    a6: '添加區域/分組',
    a7: '删除區域/分組',
    a8: '區域編輯',
    a9: '變更名稱',
    a10: '設定',
    a11: '關閉',
    a12: '添加分組/區域選擇',
    a13: '分組',
    a14: '區域',
    a15: '分組/區域名稱',
    a16: '滯留人員區域名稱',
    a17: '滯留區域設置',
    a18: '區域名稱：',
    a19: '所有的門',
    a20: '添加',
    a21: '已注册的門',
    a22: '删除',
    a23: '請輸入區域名稱',
    a24: '條數據',
    a25: '設備名稱',
    a26: '認證終端名稱',
    a27: '樓層·租戶名稱',
    a28: '操作',
    a29: '確定要添加分組/區域嗎？',
    a30: '請輸入分組/區域名稱',
    a31: '確定要删除',
    a32: '嗎？',
    a33: '確定要進行設定內容注册嗎？',
  },
  // 门禁模块--车场设置
  parkingLotSet: {
    a1: '加載中',
    a2: '車場設定',
    a3: '車場人員結構架構資訊',
    a4: '車場配寘資訊',
    a5: '同步組織架構',
    a6: '删除同步組織架構',
    a7: '暫無數據',
    a8: '沒有選擇需要更新的組織結構',
    a9: '請選擇需要更新的組織架構',
    a10: '確定',
    a11: '確定要更新所選組織架構及其子架構資訊到協力廠商系統嗎？',
  },
  // 门禁模块--人脸认证信息设置
  facialInfo: {
    a1: '人臉認證資訊',
    a2: '設備狀態',
    a3: '設備名稱',
    a4: '査詢',
    a5: '人臉設備清單',
    a6: '識別記錄管理',
    a7: '編輯',
    a8: '選擇操作',
    a9: '設備管理',
    a10: '設備序號',
    a11: '關聯讀卡器',
    a12: '設備類型',
    a13: '設備IP',
    a14: '設備密碼',
    a15: '心跳回檔URL',
    a16: '認證回檔URL',
    a17: '設備回檔URL',
    a18: '設備備註',
    a19: '條數據',
    a20: '備註',
    a21: '操作',
    a22: '宇泛',
    a23: '奇智',
    a24: '海康',
    a25: '確定要設定所編輯的資訊嗎？',
    a26: '全部',
    a27: '正常',
    a28: '故障',
    a29: '加載中....',
    a30: '請輸入裝置名稱',
    a31: '終端品牌',
    a32: '上傳背景圖',
    a33: '關閉',
    a34: '人臉終端',
    a35: '設定',
    a36: '圖片埠',
    a37: '背景圖片',
    a38: '全部删除',
    a39: '删除',
    a40: '選擇圖片',
    a41: '上傳圖片',
    a42: '設備現場圖',
    a43: '讀取圖片',
    a44: '讀取全部圖片',
    a45: '人臉終端待機背景圖設定',
    a46: '只能上傳png和jpg格式的圖片！',
    a47: '圖片大小不能超過300KB！',
    a48: '確認要保存所設定的資訊嗎？',
    a49: '確認要上傳所選擇的圖片嗎？',
    a50: '確認要删除該圖片嗎？',
    a51: '確認要删除所有圖片嗎？'
  },
  // 门禁模块--读卡器报警
  cardreaderalarm: {
    a1: '加載中....',
    a2: '讀卡器報警',
    a3: '認證終端',
    a4: '請輸入認證終端',
    a5: '査詢',
    a6: '認證終端清單',
    a7: '警告設定',
    a8: '警報設定',
    a9: '開門超時警報：',
    a10: '無',
    a11: '有',
    a12: '（警報提示時間：',
    a13: '秒',
    a14: '恢復警報時停止）',
    a15: '門故障警報：',
    a16: '异常開門警報：',
    a17: '强行進門警報：',
    a18: '無迴圈警報：',
    a19: '條數據',
    a20: '認證終端',
    a21: '開超過鳴動',
    a22: '門故障鳴動',
    a23: '异常開鳴動',
    a24: '入侵鳴動',
    a25: '無迴圈鳴動',
    a26: '操作',
    a27: '確定要進行設定內容注册嗎？',
  },
  // 门禁模块--实际数据
  realdata: {
    a1: '加載中....',
    a2: '報表數據',
    a3: '全部',
    a4: '門',
    a5: '卡',
    a6: '認證操作歷史記錄',
    a7: '月報',
    a8: '日報',
    a9: '輸出期間：',
    a10: '檔案拆分數：',
    a11: '輸出日數：',
    a12: '1生成一個檔案而不進行折開',
    a13: '2每15天劃分一次（包括最後一個檔案的結束日期）生成檔案',
    a14: '3每20天劃分一次（包括最後一個檔案的結束日期）生成檔案',
    a15: '4每7天劃分一次（包括最後一個檔案的結束日期）生成檔案',
    a16: '5每6天劃分一次（包括最後一個檔案的結束日期）生成檔案',
    a17: '如果輸出量很大，則除法的數目會自然更改為5',
    a18: '下載檔案名稱：',
    a19: '輸出中…',
    a20: '輸出',
    a21: '檔案折開說明',
    a22: '姓名',
    a23: '請輸入姓名',
    a24: '卡編號',
    a25: '請輸入卡編號',
    a26: '卡ID',
    a27: '請輸入卡ID',
    a28: '管理代碼',
    a29: '請輸入管理代碼',
    a30: '査詢',
    a31: '認證信息清單',
    a32: '條數據',
    a33: '管理所屬',
    a34: '沒有選擇日期',
    a35: '請選擇日期',
    a36: '設備組名稱',
    a37: '請輸入裝置組名稱',
    a38: '設備名稱',
    a39: '請輸入裝置名稱',
    a40: '門清單',
  },
  // 门禁模块--通行模式
  trafficpatterns: {
    a1: '加載中....',
    a2: '通行模式',
    a3: '通行模式名稱：',
    a4: '通行模式編號：',
    a5: '已注册的認證終端',
    a6: '請選擇通行模式',
    a7: '通行模式複製',
    a8: '認證終端編輯',
    a9: '複製原通行模式',
    a10: '未選擇通行模式',
    a11: '請選擇需要複製的通行模式',
    a12: '認證終端名稱',
    a13: '査詢',
    a14: '全部的認證終端',
    a15: '添加',
    a16: '删除',
    a17: '通行時間表·行事曆變更',
    a18: '設定',
    a19: '時間表·行事曆選擇',
    a20: '通行時間表：',
    a21: '請選擇通行時間表',
    a22: '行事曆：',
    a23: '請選擇行事曆',
    a24: '警戒/解鎖切換操作設定：',
    a25: '有效',
    a26: '無效',
    a27: '條數據',
    a28: '企業名稱',
    a29: '建築名稱',
    a30: '樓層·租戶名稱',
    a31: '設備名稱',
    a32: '認證終端名稱',
    a33: '出入類型',
    a34: '警戒設定',
    a35: '無',
    a36: '有',
    a37: '時間表名稱',
    a38: '行事曆名稱',
    a39: '認證終端沒有被選擇',
    a40: '請選擇需要被改變的認證終端',
    a41: '有未設定時間表或行事曆的終端',
    a42: '請在未設定時間表和行事曆的終端，設定時間表和行事曆',
    a43: '通行模式名稱更新',
    a44: '請輸入通行模式名稱',
    a45: '輸入蒐索文字',
    a46: '通行許可權人員資訊'
  },
  // 详情页
  details: {
    a1: '加載中.....',
    a2: '設備名稱',
    a3: '設備ID',
    a4: '設備GUID',
    a5: '左入口',
    a6: '名稱',
    a7: '客戶名稱',
    a8: '類型',
    a9: '功能站點',
    a10: '樓層·租戶名稱',
    a11: '取消',
    a12: '確認',
    a13: '項目',
    a14: '狀態·操作',
    a15: '狀態變化時間',
    a17: '選擇',
    a18: '時間表設定',
    a19: '設備詳情資訊',
    a20: '時間表',
    a21: '行事曆',
    a22: '門開有效時間',
    a23: '門開超時檢測時間',
    a24: '有無聲音',
    a25: '有聲音',
    a26: '無聲音',
    a27: '電力鎖動作次數',
    a28: '電力鎖動作次數超過監視',
    a29: '電力鎖動作次數上限',
    a30: '確定要進行設定內容注册嗎？',
    a31: '門狀態',
    a32: '异常開',
    a33: '暫時解鎖',
    a34: '開超過',
    a35: '入侵',
    a36: '上解鎖狀態',
    a37: '無迴圈',
    a38: '通行狀態',
    a39: '運行狀態',
    a40: '接受禁止狀態',
    a41: '非法通行警報',
    a42: '設備异常警報',
    a43: '入方向門開',
    a44: '出方向門開',
    a45: '入方向通行禁止',
    a46: '出方向通行禁止',
    a47: '連續通過設定',
    a48: '火灾訊號輸出',
    a49: '卡NG訊號輸出',
    a50: '指定目標',
    a51: '門時間表',
    a52: '卡認證時間表',
    a53: '機械警備時間表',
    a54: '模型1',
    a55: '模型2',
    a56: '模型3',
    a57: '模型4',
    a58: '模型5',
    a59: '初始值為“卡模式"，如需更改請新設定',
    a60: '卡模式',
    a61: '自由模式',
    a62: '重複模式',
    a63: '進入卡/退出自由模式',
    a64: '進入自由/退卡模式',
    a65: '設備詳情資訊',
    a66: '時間表',
    a67: '行事曆',
    a68: '數字鍵超時時間（秒）',
    a69: '數字鍵終端有無',
    a70: '警戒溫度下限（℃）',
    a71: '警戒溫度上限（℃）',
    a72: '警戒溫度异常是否開門',
    a73: '否',
    a74: '是',
    a75: '認證模式',
    a76: '運轉狀態',
    a77: '非法卡',
    a78: '時間表恢復',
    a79: '週期自動復原',
    a80: '有效',
    a81: '無效',
    a82: '週期恢復時間1',
    a83: '週期恢復時間2',
    a84: '週期恢復時間3',
    a85: '週期恢復時間4',
    a86: '週期恢復時間5',
    a87: '設備IP',
    a89: '設備子網路遮罩',
    a90: '設備閘道',
    a91: '操作',
    a92: '關聯設備網絡資訊',
    a93: '操作成功，開門按鈕將在5秒鐘後恢復關閉狀態',
    a94: '開門操作失敗',
    a95: '當日時間表',
    a96: '當日',
    a97: '次日時間表',
    a98: '次日',
    a99: '時間表手動恢復',
    a100: '設定',
    a101: '操作狀態/故障·報警',
    a102: '時',
    a103: '分'
  },
  // 设备模块--操作\控制记录
  operaterecords: {
    a1: '操作·控制記錄',
    a2: '設備名稱',
    a3: '初始日期/時間選擇',
    a4: '終止日期/時間選擇',
    a5: '查詢',
    a6: '記錄清單',
    a7: '請選擇日期或時間',
    a8: '警報確認',
    a9: '手動恢復',
    a10: '應對',
    a11: '順位恢復',
    a12: '警報輸出',
    a13: '添加注釋',
    a14: '選擇操作',
    a15: '請輸入內容......',
    a16: '條數據',
    b1: '發生日期',
    b2: '要因',
    b3: '服務',
    b4: '狀態',
    b5: '設備組名稱',
    b6: '設備名稱',
    b7: '信號/終端名稱',
    b8: '注釋',
    b9: '操作',
  },
  // 設備模組--資料輸出
  dataoutput: {
    a1: '設備',
    a2: '信號',
    a3: '初始日期/時間選擇',
    a4: '終止日期/時間選擇',
    a5: '查詢',
    a6: '數據預覽',
    a7: '數據下載',
    a8: '按信號單獨顯示',
    a9: '按設備合併顯示',
    a10: '生成檔',
    a11: '文件列表',
    a12: '生成的輸出檔會在該清單顯示，供後續下載',
    a13: '生成的輸出檔僅保存3天，3天后檔會被刪除；如需下載請重新匯出生成',
    a14: '條數據',
    b1: '設備編號',
    b2: '設備名稱',
    b3: '信號點ID',
    b4: '信號名稱',
    b5: '信號編號',
    b6: '計量值',
    b7: '計量單位',
    b8: '日期',
    b9: '時間',
  },
  // 設備模組--設備聯動控制
  controlllinkage: {
    a1: '組名稱',
    a2: '編號',
    a3: '輸入點',
    a4: '輸出點',
    a5: '聯鎖點',
    a6: '聯動條件',
    a7: '請選擇時間表',
    a8: '設置',
    a9: '名稱已存在',
    b1: '狀態',
    b2: '警報',
    b3: '條數據',
    b4: '設備名稱',
    b5: '信號名稱',
    b6: '條件',
    b7: '顯示（維護）',
    b8: '操作',
    b9: '有效',
    b10: '無效',
    b11: '控制',
    b12: '不控制',
    b13: '聯動控制',
    b14: '輸出設備操作',
    b15: '停電或等待恢復的情況',
    b16: '輸出延遲',
    b17: '類型',
    b18: '開',
    b19: '關',
    a10: '加載中....',
    a11: '設備聯動控制',
    a12: '名稱更新',
    a13: '關閉',
    a14: '確定',
    a15: '名稱：',
    a16: '請輸入名稱',
    a17: '名稱不能為空'
  },
  // 設備模組--季节控制
  controllseason: {
    a1: '組名稱',
    a2: '編號：',
    a3: '類比設置設備',
    a4: '上下限預警設置設備',
    a5: '運行模式設置設備',
    a6: '請選擇時間表',
    a7: '季節設置',
    a8: '參數和運行模式設置',
    b1: "季節設置",
    b2: "夏季（夏季模式）",
    b3: "月",
    b4: "日",
    b5: "冬季（冬季模式）",
    b6: "其他季節（其他季節模式）",
    b7: "夏季與冬季之外",
    b8: "臨時變更（運行模式）",
    b9: "從今天開始的設置",
    b10: "確定",
    b11: "設置",
    b12: "無臨時變更",
    b13: "夏季模式",
    b14: "冬季模式",
    b15: "其他模式",
    c1: "載入中...",
    c2: "參數、運行模式設置",
    c3: "模擬值",
    c4: "類比設備清單",
    c5: "模擬值設置",
    c6: "上下限值",
    c7: "上下限警報列表",
    c8: "上下限警報設置",
    c9: "運行模式清單",
    c10: "運行模式設置",
    c11: "設置",
    c12: "有效",
    c13: "無效",
    c14: "設備名稱",
    c15: "信號名稱",
    c16: "設定值",
    c17: "有效/無效",
    c18: "中間季模式",
    c19: "類型",
    c20: "條數據",
    a9: '加載中….',
    a10: '季節切換控制',
    a11: '名稱更新',
    a12: '關閉',
    a13: '確定',
    a14: '名稱：',
    a15: '請輸入名稱',
    a16: '名稱不能為空'
  },
  // 設備模組--間歇運行控制
  controllIntermittent: {
    a1: '組名稱',
    a2: '編號：',
    a3: '物件設備',
    a4: '控制條件',
    a5: '請選擇時間表',
    a6: '設置',
    a7: "設備名稱",
    a8: "信號名稱",
    a9: "類型",
    a10: "條數據",
    a11: "間歇運行控制",
    a12: "有效",
    a13: "無效",
    a14: "分",
    a15: "啟動",
    a16: "停止",
    a17: "執行時間",
    a18: "停止時間",
    a19: "測得點",
    a20: "季節分組",
    a21: "夏季目標溫度",
    a22: "冬季目標溫度",
  },
  // 設備模組--設備執行時間表控制
  lightRunTimetable: {
    a1: '設備執行時間表',
    a2: '時間表名稱：',
    a3: '取消',
    a4: '確認',
    a5: '卡模式',
    a6: '自由模式',
    a7: "重複模式",
    a8: "進入卡/退出自由模式",
    a9: "進入自由/退卡模式",
    a10: "請選擇時間表",
    a11: "時間表間複製",
    a12: "時間表複製",
    a13: "時間表全表複製",
    a14: "複製原時間表",
    a15: "複製源",
    a16: "複製到",
    a17: '星期一',
    a18: '星期二',
    a19: '星期三',
    a20: '星期四',
    a21: '星期五',
    a22: '星期六',
    a23: '星期日',
    a24: '假日',
    a25: '特日1',
    a26: '特日2',
    a27: '特日3',
    a28: '特日4',
    a29: '設備時間表',
    a30: '星期沒有被正確指定，請再次指定',
    a31: '郵件發送時間表列表',
    a32: '郵件發送時間表名稱',
    a33: '新增',
    a34: '刪除',
    a35: '修改',
    a36: '日曆',
    a37: '請先設置日曆',
  },
  //設備模組--時間表控制頁
  controllGroup: {
    a1: '組名稱',
    a2: '編號：',
    a3: '註冊設備',
    a4: '請選擇時間表',
    a5: '註冊設備編輯',
    a6: '時間表複製',
    a7: '複製原時間表',
    a8: '未選擇時間表',
    a9: '請選擇需要複製的時間表',
  },
  //設備模組--編輯設備
  terminaleditor: {
    a1: '載入中....',
    a2: '註冊設備編輯',
    a3: '註冊設備Gr1',
    a4: '查詢',
    a5: '全部的設備',
    a6: '添加',
    a7: '刪除',
    a8: '已註冊的設備',
    a9: '設置',
    a10: '時間表·日曆選擇',
    a11: '通行時間表：',
    a12: '日曆：',
    a13: '警戒/解鎖切換操作設置：',
    a14: '有效',
    a15: '無效',
    a16: '條數據',
    a17: '設備名稱',
    a18: '信號名稱',
    a19: '類型',
    a20: '輸入點設備編輯',
    a21: '輸出點設備編輯',
    a22: '聯動點設備編輯',
    a23: '間歇運行點編輯',
    a24: '測量點編輯',
    a25: '無',
    a26: '類比設置設備',
    a27: '上下限預警設置設備',
    a28: '運行模式設置設備',
    a29: '溫度測量點',
    a30: '空調監控點',
    a31: '趨勢點編輯',
    a32: '時間設置趨勢點編輯',
    a33: '設備沒有被選擇',
    a34: '請選擇需要被改變的設備',
    a35: '有未設定時間表或日曆的終端',
    a36: '請在未設定時間表和日曆的終端，設定時間表和日曆',
    a37: '只能選擇一項',
    a38: '網站',
    a39: '聯動控制輸入點',
    a40: '聯動控制輸出點',
    a41: '計量點',
    a42: '運算點',
    a43: '虛擬點',
  },
  //設備模組--聯動控制組設置頁
  controllGroupLinkage: {
    a1: '組名稱',
    a2: '編號：',
    a3: '輸入點',
    a4: '輸出點',
    a5: '聯鎖點',
    a6: '請選擇組',
    a7: '輸入點編輯',
    a8: '輸出點編輯',
    a9: '聯鎖點編輯',
  },
  //設備模組--季節切換控制組設置頁
  seasonGroup: {
    a1: '組名稱',
    a2: '編號：',
    a3: '類比設置設備',
    a4: '上下限預警設置設備',
    a5: '運行模式設置設備',
    a6: '請選擇組',
    a7: '類比設置設備編輯',
    a8: '上下限預警設置設備編輯',
    a9: '運行模式設置設備編輯',
  },
  //設備模組--間歇運行控制組頁
  controllGroupIntermittent: {
    a1: '組名稱',
    a2: '編號：',
    a3: '間歇運行點',
    a4: '測量點',
    a5: '請選擇組',
    a6: '測量點編輯',
    a7: '間歇運行點編輯',
    a8: '運行模式設置設備編輯',
  },
  //設備模組--舒適控制組頁
  controllGroupComfort: {
    a1: '組名稱',
    a2: '編號：',
    a3: '空調監控點',
    a4: '溫度測量點',
    a5: '請選擇組',
    a6: '測量點編輯',
    a7: '溫度測量點編輯',
    a8: '空調監控點編輯',
    a9: '物件設備',
    a10: '控制條件',
    a11: '設置',
  },
  //時間趨勢組
  timeTrend: {
    a1: '組名稱',
    a2: '編號：',
    a3: '趨勢點',
    a4: '請選擇組',
    a5: '趨勢點編輯',
    a6: '條數據',
    a7: '設備名稱',
    a8: '信號名稱',
    a9: '類型',
    a10: '收集週期',
    a11: '添加',
    a12: '修改',
    a13: '刪除',
    a14: '確定要刪除?',
    a15: '確定',
    a16: '取消',
    a17: '成功',
    a18: '失敗',
    a19: '名稱已存在',

  },
  //時間設置趨勢組
  timeSetupTrend: {
    a1: '時間設置趨勢',
    a2: '趨勢組：',
    a3: '編號：',
    a4: '時間軸視圖',
    a5: '請選擇時間',
    a6: '1日',
    a7: '1小時',
    a8: '日期選擇',
    a9: '時間選擇',
    a10: '軸格式',
    a11: '狀態',
    a12: '多狀態',
    a13: '請選擇組',
    a14: '清單顯示',
    a15: '圖表顯示',
    a16: '左軸',
    a17: '右軸',
    a18: '單位',
    a19: '最大值',
    a20: '最小值',
    a21: '軸分割數',
    a22: '設備名稱',
    a23: '信號名稱',
    a24: '資料類型',
    a25: '圖表類型',
    a26: '無設備信號點，請先到時間設置趨勢組設置頁面進行設置',
  },
  //資料比較趨勢組
  dataCompareTrend: {
    a1: '資料比較趨勢',
    a2: '趨勢組：',
    a3: '編號：',
    a4: '添加',
    a5: '確定要刪除?',
    a6: '刪除',
    a7: '狀態',
    a8: '多狀態',
    a9: '請選擇組',
    a10: '清單顯示',
    a11: '圖表顯示',
    a12: '左軸',
    a13: '右軸',
    a14: '單位',
    a15: '最大值',
    a16: '最小值',
    a17: '軸分割數',
    a18: '日期',
    a19: '星期',
    a20: '圖表類型',
    a21: '日期操作',
    a22: '信號點',
    a23: '設備名稱',
    a24: '信號名稱',
    a25: '類型',
    a26: '請添加日期',
    a27: '折線圖',
    a28: '多狀態圖',
  },
  //照明詳細資訊
  lightDetails: {
    a1: '開關照度',
    a2: '環境照度',
    a3: '開關設置',
    a4: '開關狀態',
    a5: '故障',
    a6: '調光設置',
    a7: '調光狀態',
    a8: '操作/狀態/故障.警報',
    a9: '評論登記欄',
    a10: '維護監視',
    a11: '正常',
    a12: '最多5條評論',
    a13: '時間表控制',
    a14: '上限值',
    a15: '上下限值監視',
    b1: '累計執行時間超過監控點執行時間',
    b2: '連續執行時間超過監視連續執行時間',
    b3: '狀態變化次數超過監視狀態變化次數',
    b4: '故障報警時間超過監視故障報警時間',
    b5: '故障報警次數超過監視故障次數',
    b6: '小時',
    b7: '次',
    c1: 'RS名稱',
    c2: '對象ID',
    c3: '趨勢',
    c4: '單元類別',
    c5: '模組',
    c6: '擴展',
    c7: '端子no',
    d1: '變更時間：',
    d2: '因素：',
    d3: '維護登記',
    d4: '重要設備登記',
    d5: '聲音類別：',
    d6: '開',
    d7: '關',
    d8: '操作',
    d9: '狀態',
    d10: '故障警報',
    d11: '設置值',
    d12: '測量值',
    d13: '設置',
    a14: '設置成功',
    a15: '設置失敗',
  },
  //空調詳細資訊
  airConditionDetails: {
    a1: '遙控模式',
    a2: '冷風',
    a3: '熱風',
    a4: '節能',
    a5: '風速',
    a6: '開關設置',
    a7: '開關狀態',
    a8: '故障',
    a9: '溫度設置',
    a10: '吸入溫度',
    a11: '運轉模式設置',
    a12: '風量設置',
    a13: '遙控器禁止溫度開關狀態',
    a14: '操作/狀態/故障.警報',
    a15: '評論登記欄',
    a16: '維護監視',
    a17: '設備名稱',
    a18: '運行',
    a19: '製冷',
    a20: '制熱',
    a21: '正常',
    a22: '弱風',
    a23: '中風',
    a24: '強風',
    a25: '無',
    a26: "停止",
    a27: "室溫",
    a28: '火災信號',
    a29: '送風',
    a30: '抽濕',
    a31: '維護',
    a32: '維護警報/上下限警報·運行異常',
  },
  //環境詳細資訊
  EnvironmentDetails: {
    a1: '環境刻度',
    a2: '故障',
    a3: '評論登記欄',
    a4: '維護監視',
    a5: '儀器名稱',
    a6: '測量值',
    a7: '上下限值監視',
    a8: '設置',
    a9: '上限值比下限值小',
    a10: '設置值不在上下限範圍內',
    a11: '運行',
    a12: "停止",
  },
  // 電梯模塊--設備頁
  elevatorequipment: {
    a1: '類型選擇',
    a2: '全部',
    a3: '控制器',
    a4: '認證終端',
    a5: '加載中...',
    a6: '正常',
    a7: '通訊異常',
    a8: '維護',
    a9: '機器異常',
    a10: '故障/警報',
    a11: '維護警報/上下限警報·運行異常',
    a12: '查詢',
    a13: '站點數據異常，請聯繫管理員',
  },
  // 電梯模塊--遠程控制
  elevatorecontrol: {
    a1: '遠程控制',
    a2: '棟內互訪',
    a3: '遠程召梯',
    a4: '室内召梯',
    a5: '遠程授權',
    a6: '加載中...',
    a7: '樓層時間段',
  },
  // 电梯模块--栋内互访页
  elevatorexchangevisits: {
    a1: '互訪流程',
    a2: '來訪人位置',
    a3: '被訪者位置',
    a4: '完成',
    a5: '選擇【樓層】和【房間】',
    a6: '點擊【互訪】',
    a7: '室内召梯',
    a8: '點擊【室内召梯】',
    a9: '互訪',
    a10: '來訪者房間',
    a11: '被訪者樓層',
    a12: '全部',
    a13: '來訪者樓層',
    a14: '操作成功',
    a15: '操作失敗',
  },
  // 电梯模块--远程召梯页
  elevatorRemoteemErgencyRecall: {
    a1: '全部',
    a2: '請選擇召梯位置',
    a3: '前往樓層',
    a4: '前往房間',
    a5: '只召梯',
    a6: '召梯並開放樓層權限',
    a7: '操作成功',
    a8: '操作失敗',
  },
  // 电梯模块--室内召梯页
  elevatorIndoorErgencyRecall: {
    a1: '全部',
    a2: '請選擇召梯位置',
    a3: '召梯位置',
    a4: '室内召梯',
    a5: '操作成功',
    a6: '操作失敗',
  },
  // 电梯模块--远程授权页
  controlElevator: {
    a1: '全部電梯',
    a2: '請選擇電梯',
    a3: '設置電梯樓層受限',
    a4: '解除',
    a5: '投入',
    a6: '注意：一旦解除或投入後，電梯無法自動解除該狀態，需要手動進行狀態切換。',
    a7: '臨時解除單一樓層受限',
    a8: '臨時解除權限的位置',
    a9: '該樓層暫無可解除權限的位置',
    a10: '解除受限',
    a11: '操作成功',
    a12: '操作失敗',
  },
  //電梯模塊--樓層時間段設定頁
  controlTimetable:{
    a1: '加載中….',
    a2: '樓層時間段',
    a3: '樓層時間段清單',
    a4: '編輯',
    a5: '應用',
    a6: '請選擇電梯',
    a7: '樓層時間表設定',
    a8: '設定',
    a9: '取消',
    a10: '樓層',
    a11: '請選擇樓層',
    a12: '主門',
    a13: '副門',
    a14: '請選擇時間表',
    a15: '應用',
    a16: '當前樓層',
    a17: '全部樓層',
    a18: '公共層',
    a19: '保密層',
    a20: '主',
    a21: '副',
    a22: '樓層參數已更新，請點擊[應用]才能生效。',
    a23: '樓層更新失敗。 詳情：',
    a24: '設定時間段',
    a25: '該操作將更新樓層資訊時間段，請確認是否更新？',
    a26: '確認',
    a27: '取消',
    a28: '應用成功',
    a29: '應用失敗，原因',
    // tb錶
    tb1: '實體層',
    tb2: '數顯',
    tb3: '内容',
    tb4: '電梯門',
    tb5: '主門時間段',
    tb6: '副門時間段',
  },
  // 设置 - 电梯时间表
  elevatorTimetable: {
    a1: '加載中….',
    a2: '添加電梯時間表',
    a3: '電梯時間表',
    a4: '關聯控制器',
    a5: '時間表',
    a6: '關聯',
    a7: '時間表名稱',
    a8: '卡模式',
    a9: '退出卡模式',
    a10: '啟用',
    a11: '關閉',
    a12: '請選擇電梯時間表',
    a13: '時間表複製',
    a14: '添加關聯',
    a15: '應用',
    a16: '添加時間表',
    a17: '請輸入時間表名稱',
    a18: '設定',
    a19: '取消',
    a20: '複製源',
    a21: '目標源',
    a22: '時間表名稱更新',
    a23: '設備批量關聯',
    a24: '關聯的控制器',
    a25: '請選擇關聯的控制器',
    a26: '複製錶成功',
    a27: '複製錶過程失敗',
    a28: '獲取下一個時間段序號時失敗',
    a29: '應用成功',
    a30: '設定應用失敗',
  },
  //設定-電梯時間表-時間表頁
  timetableModule:{
    a1: '加載中…',
    a2: '禮拜一',
    a3: '禮拜二',
    a4: '禮拜三',
    a5: '禮拜四',
    a6: '禮拜五',
    a7: '禮拜六',
    a8: '禮拜日',
    a9: '禮拜',
    a10: '模式',
    a11: '有效日期設定',
    a12: '有效日期',
    a13: '設定',
    a14: '取消',
    a15: '設定禮拜',
    a16: '年',
    a17: '月',
    a18: '日',
    a18: '至',
  },
  //設定-電梯時間表-時間表頁-錶
  timetableModuleDT:{
    a1: '加載中…',
    a2: '時間表變更',
    a3: '時間表類型',
    a4: '電梯時間表',
    a5: '時間表名稱',
    a6: '由於初始值是',
    a7: '退出卡模式',
    a8: '，所以請設定其它的模式。',
    a9: '時',
    a10: '分',
    a11: '設定',
    a12: '取消',
    a13: '卡模式',
  },
  // 设置 - 电梯时间表 - 设备关联页
  timedeviceModule:{
    a1: '加載中…',
    a2: '移除',
    a3: '未關聯任何設備',
    a4: '確定與電梯時間表進行取消關聯？',
    a5: '移除成功。',
    a6: '請先停用時間表，才能進行解綁。',
    a7: '設定應用成功',
    a8: '控制器啟用時間段失敗',
    a9: '控制器禁用時間段失敗',
    a10: '與電梯時間表取消關聯。',
  },
  // 电梯模块--卡片记录
  elevatorCardRecords: {
    a0: '加載中....',
    a1: '記錄',
    a2: '卡片編號',
    a3: '結果',
    a4: '初始日期/時間選擇',
    a5: '終止日期/時間選擇',
    a6: '查詢',
    a7: '記錄列表',
    a8: '請選擇日期或時間',
    a9: '發生日期',
    a10: '認證類型',
    a11: '姓名',
    a12: '卡片編號',
    a13: '體溫',
    a14: '電梯名稱',
    a15: '信號/終端名稱',
    a16: '建築物名稱',
    a17: '註釋',
    a18: '結果',
    a19: '成功',
    a20: '失敗',
    a21: '請輸入卡片編號',
    a22: '全部',
    a23: '共',
    a24: '條數據',
    a25: '卡片'
  },
  elevatorQrCodeRecords: {
    a0: '加載中....',
    a1: '二維碼記錄',
    a2: '二維碼',
    a3: '同步結果',
    a4: '初始日期/時間選擇',
    a5: '終止日期/時間選擇',
    a6: '查詢',
    a7: '記錄列表',
    a8: '請選擇日期或時間',
    a9: '發生日期',
    a10: '請輸入二維碼',
    a11: '全部',
    a12: '共',
    a13: '條數據',
    a14: '',
    a15: '',
    a16: '',
    a17: '',
    a18: '',
    a19: '',
    a20: '',
    a21: '',
    a22: '',
    a23: '',
    a24: '',
    a25: '',
    a26: '',
    a27: '',
    a28: '',
    a29: '',
    td1: '發生日期',
    td2: '申請人',
    td3: '編號',
    td4: '二維碼憑證',
    td5: '碼狀態',
    td6: '申請起始時間',
    td7: '有效時長',
    td8: '起始樓層',
    td9: '電梯名稱',
    td10: '信號/終端名稱',
    td11: '建築物名稱',
    td12: '註釋',
    td13: '所屬房間',
    td14: '同步時間',
    td15: '同步結果',
    s1: '成功',
    s2: '失敗',
    s3: '信息異常',
    s4: '上傳中',
    s5: '有效',
    s6: '無效',
  },
  elevatorRemoteRecords: {
    a0: '加載中....',
    a1: '手機召梯記錄',
    a2: '指令',
    a3: '結果',
    a4: '初始日期/時間選擇',
    a5: '終止日期/時間選擇',
    a6: '查詢',
    a7: '記錄列表',
    a8: '請選擇日期或時間',
    a9: '發生日期',
    a10: '認證類型',
    a11: '姓名',
    a12: '卡片編號',
    a13: '體溫',
    a14: '電梯名稱',
    a15: '信號/終端名稱',
    a16: '建築物名稱',
    a17: '註釋',
    a18: '結果',
    a19: '成功',
    a20: '失敗',
    a21: '請輸入卡片編號',
    a22: '全部',
    a23: '共',
    a24: '條數據',
    a25: '卡片',
    td1: '召梯指令',
    td2: '請求時間',
    td3: '響應時間',
    td4: '姓名',
    td5: '編號',
    td6: 'ID',
    td7: '所屬房間',
    td8: '來訪房間',
    td9: '被訪房間',
    td10: '註釋',
    td11: '請求結果',
    s1: '全部',
    s2: '遠程開鎖',
    s3: '室內召梯',
    s4: '互訪',
    s5: '微信召梯',
    s6: '失敗',
    s7: '信息異常',
    s8: '設備不在線',
    s9: '1號梯響應',
    s10: '2號梯響應',
    s11: '3號梯響應',
    s12: '4號梯響應',
    s13: '5號梯響應',
    s14: '6號梯響應',
    s15: '7號梯響應',
    s16: '8號梯響應',
    s17: '9號梯響應',
    s18: '10號梯響應',
    s19: '11號梯響應',
    s20: '12號梯響應',
    s21: '13號梯響應',
    s22: '14號梯響應',
    s23: '15號梯響應',
    s24: '16號梯響應'
  },
  // 电梯模块--实际数据
  elevatorRealdata: {
    a1: '加載中',
    a2: '報表數據',
    a3: '全部',
    a4: '卡',
    a5: '讀卡器',
    a6: '類型',
    a7: '月報',
    a8: '日報',
    a9: '輸出期間：',
    a10: '檔案拆分數：',
    a11: '輸出日數：',
    a12: '1生成一個檔案而不進行折開',
    a13: '2每15天劃分一次（包括最後一個檔案的結束日期）生成檔案',
    a14: '3每20天劃分一次（包括最後一個檔案的結束日期）生成檔案',
    a15: '4每7天劃分一次（包括最後一個檔案的結束日期）生成檔案',
    a16: '5每6天劃分一次（包括最後一個檔案的結束日期）生成檔案',
    a17: '如果輸出量很大，則除法的數目會自然更改為5',
    a18: '下載檔案名稱：',
    a19: '輸出中…',
    a20: '輸出',
    a21: '檔案折開說明',
    a22: '姓名',
    a23: '請輸入姓名',
    a24: '卡片編號',
    a25: '請輸入卡片編號',
    a26: '卡ID',
    a27: '請輸入卡ID',
    a28: '電梯讀卡器',
    a29: '門禁讀卡器',
    a30: '査詢',
    a31: '認證信息清單',
    a32: '條數據',
    a33: '管理所屬',
    a34: '沒有選擇日期',
    a35: '請選擇日期',
    a36: '所屬控制器',
    a37: '共',
    a38: '設備名稱',
    a39: '請輸入裝置名稱',
    a40: '門清單',
  },
  // 电梯模块--运行状态
  elevatorRunning: {
    a1: '加載中',
    a2: '電梯篩選',
    a3: '消防/火災',
    a4: 'VIP直駛',
    a5: 'ITM退出',
    a6: 'ITM投入',
    a7: '故障',
    a8: '設備未接入平臺',
    a9: '正在請求電梯狀態',
    a10: '獲取電梯狀態失敗',
    a11: '參數異常',
    a12: '電梯未存在',
    a13: '控制器參數異常',
    a14: '正常',
    a15: '上行',
    a16: '運動上行',
    a17: '下行',
    a18: '運動下行',
    a19: '靜止/無方向',
  },
  //帳號設置視窗
  accountModal: {
    a1: '帳號分組',
    a2: '所屬',
    a3: '帳號ID',
    a4: '帳號',
    a5: '驗證碼',
    a6: '有效',
    a7: '無效',
    a8: '帳號許可權',
    a9: '發送測試郵件',
    a10: '密碼',
    a11: '修改密碼',
    a12: '最後修改日期',
    a13: '密碼有效期',
    a14: '發送郵件',
    a15: '記錄未下載成功發送郵件',
    a16: '下載失敗',
    a17: '次發送郵件',
    a18: '更改密碼',
    a19: '發送郵件出錯',
    a20: '新舊密碼相同',
    a21: '舊密碼輸入錯誤',
    a22: '存在非法文字',
    a23: '密碼太長',
    a24: '其他錯誤',
    a25: '密碼驗證錯誤',
  },

  //公共指南
  commonInfoSetting: {
    a1: '指南',
    a2: '最後更新人員',
    a3: '查詢',
    a4: '確定要刪除所選項',
    a5: '確定',
    a6: '取消',
    a7: '刪除',
    a8: '指南類型',
    a9: '顯示時間段',
    a10: '重要資訊',
    a11: '添加',
    a12: '變更',
    a13: '通知設置',
    a14: '條數據',
    a15: '公共',
    a16: '設備',
    a17: '能源',
    a18: '門禁',
    a19: '視頻發送',
    a20: '簡單節能',
    a21: '空調能源節約',
    a22: '需求回應',
    a23: '門禁資料輸出',
    a24: '變更成功',
    a25: '變更失敗：',
    a26: '添加成功',
    a27: '添加失敗：',
    a28: '刪除成功',
    a29: '刪除失敗：',
    a30: '新建/修改',
    a31: '最後更新日期時間',
    a32: '最後更新人員',
    a33: '顯示物件',
    a34: '顯示開始時間',
    a35: '顯示結束時間',
  },

  //下載
  downloadSetting: {
    a1: '下載名稱',
    a2: '檔案名稱',
    a3: '最後更新人員',
    a4: '查詢',
    a5: '確定要刪除所選項',
    a6: '確定',
    a7: '取消',
    a8: '刪除',
    a9: '添加',
    a10: '變更',
    a11: '通知設置',
    a12: '條數據',
    a13: '新建/修改',
    a14: '最後更新日期時間',
    a15: '最後更新人員',
    a16: '顯示物件',
    a17: '變更成功',
    a18: '變更失敗：',
    a19: '添加成功',
    a20: '添加失敗：',
    a21: '刪除成功',
    a22: '刪除失敗：',
    a23: '上傳',
    a24: '只能選擇一項進行修改',
  },

  //詳情顯示視窗
  editModal: {
    a1: '顯示時間段',
    a2: '重要資訊',
  },

  //通知設置管理主頁
  equipment: {
    a1: '公共指南',
    a2: '諮詢處',
    a3: '繼承事項',
    a4: '下載',
    a5: '通知設置',
  },

  //諮詢處
  inquireSetting: {
    a1: '諮詢',
    a2: '最後更新人員',
    a3: '查詢',
    a4: '確定要刪除所選項',
    a5: '確定',
    a6: '取消',
    a7: '刪除',
    a8: '添加',
    a9: '變更',
    a10: '通知設置',
    a11: '條數據',
    a12: '新建/修改',
    a13: '最後更新日期時間',
    a14: '最後更新人員',
    a15: '顯示物件',
    a16: '顯示開始時間',
    a17: '顯示結束時間',
    a18: '變更成功',
    a19: '變更失敗：',
    a20: '添加成功',
    a21: '添加失敗：',
    a22: '刪除成功',
    a23: '刪除失敗：',
    a24: '交接事項',
  },

  //帳號設置密碼修改視窗
  passwordModal: {
    a1: '當前密碼1',
    a2: '新密碼1',
    a3: '確認密碼1',
    a4: '當前密碼2',
    a5: '新密碼2',
    a6: '確認密碼2',
  },

  //資訊通知主頁
  infoHome: {
    a1: '通知',
    a2: '最後更新日期：',
    a3: '公共指南',
    a4: '快捷方式',
    a5: '諮詢地址',
    a6: '下載',
    a7: '註冊/設定',
    a8: '交接事項',
    a9: '彈出窗名稱',
    a10: '帳號設置',
    a11: '添加成功',
    a12: '添加失敗：',
    a13: '更新使用者資訊',
    a14: '更新成功',
    a15: '更新失敗',
  },

  //設置管理主頁
  setHome: {
    a1: '客戶中心',
    a2: '用戶設置',
    a3: '通知設置',
    a4: '卡號管理',
    a5: '控制器管理',
    a6: '警報管理',
    a7: '監控中心',
    a8: '客戶、建築群和建築站點資料：',
    a9: 'menu 出錯',
  },

  //警報管理頁
  notificationEquipment: {
    a1: '警報設置',
    a2: '警報記錄資訊',
    a3: '通知設置',
    a4: '警報管理',
    a5: '郵件發送時間表',
  },

  //警報通知設置
  notification: {
    a1: '選擇站點資訊',
    a2: '攝像機',
    a3: '錄影機',
    a4: '查詢',
    a5: '抑制',
    a6: '不抑制',
    a7: '確定要進行設置',
    a8: '確定',
    a9: '取消',
    a10: '設置',
    a11: '確定取消設置',
    a12: '通知設置',
    a13: '條數據',
    a14: '警報ID',
    a15: '警報資訊',
    a16: '警報接收',
    a17: '管制通知',
    a18: '設置失敗，無設置值',
    a19: '設置成功',
    a20: '設置失敗：',
  },

  //警報記錄
  warningRecord: {
    a1: '載入中....',
    a2: '建築代碼',
    a3: '初始日期/時間選擇',
    a4: '終止日期/時間選擇',
    a5: '選項',
    a6: '值',
    a7: '企業代碼',
    a8: '發送資料類別',
    a9: '管制類別',
    a10: '查詢',
    a11: '警報記錄清單',
    a12: '強制恢復',
    a13: '類別變更',
    a14: '目前的發報類型是：',
    a15: '變更後：',
    a16: '設置',
    a17: '取消',
    a18: '全部',
    a19: '客戶編號',
    a20: '建築名稱',
    a21: '管理代碼',
    a22: '監控中心名稱',
    a23: '未恢復發送資料',
    a24: '試驗發送資料',
    a25: '警報',
    a26: '作業發送資料',
    a27: '集中發送資料',
    a28: '通信異常',
    a29: '日立樓宇',
    a30: '正式警報',
    a31: '試驗警報',
    a32: '作業警報',
    a33: '批量警報',
    a34: '發報類型變更',
    a35: '條數據',
    a36: '企業名稱',
    a37: '客戶名稱',
    a38: '客戶管理編號',
    a39: '建築物名稱',
    a40: '監控中心名稱',
    a41: '設備名稱',
    a42: '信號/終端名稱',
    a43: '發報類型',
    a44: '警報類型',
    a45: '警報內容',
    a46: '發生日期',
    a47: '恢復日期',
    a48: '處理郵件',
    a49: '注釋',
    a50: '請選擇要變更類別的項',
    a51: '請選擇要恢復的項',
  },
  //聯繫方式選擇頁
  deviceContact: {
    a1: '載入中....',
    a2: '客戶名稱',
    a3: '建築物名稱',
    a4: '聯繫方式名稱',
    a5: '聯繫方式1~3',
    a6: '查詢',
    a7: '聯繫方式清單',
    a8: '聯繫方式1',
    a9: '聯繫方式2',
    a10: '聯繫方式3',
    a11: '添加',
    a12: '更新',
    a13: '刪除',
    a14: '確定',
    a15: '輸入點設備編輯',
    a16: '條數據',
    a17: '認證終端沒有被選擇',
    a18: '請選擇需要被改變的認證終端',
    a19: '聯繫方式沒有被選擇',
    a20: '請選擇需要被刪除的聯繫方式',
    a21: '刪除成功',
    a22: '請選擇要更新的聯繫方式',
    a23: '更新成功',
  },
  //警報設置編輯頁--編輯設備
  deviceEditor: {
    a1: '載入中....',
    a2: '設備',
    a3: '名稱',
    a4: '註冊設備Gr1',
    a5: '註冊設備Gr2',
    a6: '註冊設備Gr3',
    a7: '查詢',
    a8: '全部的設備',
    a9: '添加',
    a10: '刪除',
    a11: '已註冊的設備',
    a12: '設置',
    a13: '輸入點設備編輯',
    a14: '條數據',
    a15: '門禁',
    a16: '全部',
    a17: '電氣鎖',
    a18: '自動門',
    a19: '閘機',
    a20: '警備',
    a21: '接點',
    a22: '視頻',
    a23: '攝像機',
    a24: '錄影機',
    a25: '設備名稱',
    a26: '信號名稱',
    a27: '類型',
    a28: '認證終端沒有被選擇',
    a29: '請選擇需要被改變的認證終端',
    a30: '設備沒有被選擇',
    a31: '請選擇需要被改變的設備',
  },
  //警報管理頁--警報設置
  warningSetting: {
    a1: '選擇站點資訊',
    a2: '警報類型',
    a3: '負責人',
    a4: '查詢',
    a5: '刪除',
    a6: '編輯',
    a7: '新增',
    a8: '企業代碼',
    a9: '客戶編號',
    a10: '客戶名稱',
    a11: '建築名稱',
    a12: '警報對象',
    a13: '設備',
    a14: '系統',
    a15: '設備名稱',
    a16: '類型',
    a17: '警報設置',
    a18: '說明',
    a19: '處理方法',
    a20: '設備聯繫方式1',
    a21: '設備聯繫方式2',
    a22: '設備聯繫方式3',
    a23: '設備聯繫方式4',
    a24: '設備聯繫方式5',
    a25: '設備聯繫方式6',
    a26: '警報處理手冊',
    a27: '對應區分',
    a28: '是否出動',
    a29: '電話對應',
    a30: 'HBS出動',
    a31: '警報顯示',
    a32: '顯示',
    a33: '發報',
    a34: '是',
    a35: '否',
    a36: '郵件發送',
    a37: '啟動時發送',
    a38: '恢復時發送',
    a39: '聲音設置',
    a40: '沒有警報',
    a41: '發生火災',
    a42: '發生停電',
    a43: '發生大故障',
    a44: '發生小故障',
    a45: '更新時間',
    a46: '更新人員',
    a47: '發送設置',
    a48: '用戶選擇',
    a49: '發送郵寄地址',
    a50: '發送時間表',
    a51: '發送日曆',
    a52: '設置',
    a53: '取消',
    a54: '設備選擇',
    a55: '聯繫方式',
    a56: '條數據',
    a57: '設備聯繫1名稱',
    a58: '設備聯繫1方式1',
    a59: '設備聯繫1方式2',
    a60: '設備聯繫1方式3',
    a61: '創建人員',
    a62: '監視時間表',
    a63: '請選擇添加人員站點資訊',
  },

  energy: {
    ok: '設置',
    cancel: '取消',
    add: '添加',
    remove: '刪除',
    search: '查詢',
    showTotal: '共 ${total} 條數據',
    placeSelect: '請選擇',
    setting: {
      reportGr: {
        inputLabel: '請輸入${label}',
        spLabel: '${label}不能包含特殊字元!',
        lenLabel: '${label}長度必須在 ${min} 到 ${max}之間',
        deleLabel: '確認要刪除${label}【${name}】嗎？',
        saveLabel: '確認要保存所設置的${label}嗎？',
        saveSuce: '資料保存成功',
        saveFail: '資料保存失敗',
        loadFail: '數據載入失敗',
        daily: { name: '日報組', nameLabel: '日報組名稱', edit: '日報計量點編輯' },
        monthly: { name: '月報組', nameLabel: '月報組名稱', edit: '月報計量點編輯' },
        yearly: { name: '年報組', nameLabel: '年報組名稱', edit: '年報計量點編輯' },
        trendGr: { name: '趨勢組',nameLabel: '趨勢組名稱',edit: '計量點編輯',chartName:'趨勢圖' },
        timeTrendGr: { name: '時間設置趨勢組',nameLabel: '時間設置趨勢組名稱',edit: '計量點編輯' ,chartName:'時間設置趨勢圖'},
        empty: '請選擇一個分組。',
        stop: '禁止輸入以下特殊字元',
        grlabel: '分組名稱',
        kikiName: '設備名稱',
        pointName: '信號名稱',
        dataKind: '資料類型',
        numberTips: '日報/月報/年報可設置的計量點最大數量為32個。',
        trendTips: '趨勢組/時間趨勢組可設定的計量點最大數量為12個。',
        saveMP: '確認要保存所設選擇的計量嗎？',
        addMp: '請選擇需要添加的計量點。',
        removeMp: '請選擇需要刪除的計量點。',
        limitMp: '所選擇的計量點數量不可超過32個。',
        groupingLabel:"非同一等級的計量參數被分入同一分組，是否繼續？",
      },
    },
    enedata001: "統計數據",
    enedata002: "類型",
    enedata003: "計量對象",
    enedata004: "圖表類型",
    enedata005: "目標年度",
    enedata006: "查詢",
    enedata007: "累計使用量",
    enedata008: "累計",
    enedata009: "使用量",
    enedata010: "預測數據",
    enedata011: "實際數據",
    enedata012: "顯示對象",
    enedata013: "折線圖",
    enedata014: "柱狀圖",
    enedata015: "餅狀圖",
    enedata016: "時間",
    enedata017: "日期",
    enedata018: "對比圖表",
    enedata019: "實際使用量",
    enedata020: "原單位指標",
    enedata021: "CO2換算係數",
    enedata022: "※上端表示「使用量」、下端表示「熱量」。(水能的情況下僅顯示使用量)",
    enedata023: "設置",
    enedata024: "計量點",
    enedata025: "全選",
    enedata026: "全不選",
    enedata027: "預覽列印",
    enedata028: "註冊設備變更",
    enedata029: "費用CSV輸出",
    enedata030: "執行",
    enedata031: "請選擇一個分組。",
    enedata032: "用途",
    enedata033: "請選擇建築物。",
    enedata034: "日報",
    enedata035: "月報",
    enedata036: "年報",
    enedata037: "時間期限",
    enedata038: "請輸入數位。",
    enedata039: "日度數據日期期限不可超過一天。",
    enedata040: "月度數據日期期限不可超過一個月。",
    enedata041: "年度數據日期期限不可超過一年。",
    enedata042: "報表匯出",
    enedata043: "綜合報表",
    enedata044: "數據輸出",
    enedata045: "計量點類型",
    enedata046: "年度",
    enedata047: "輸出",
    enedata048: "輸出日期",
    enedata049: "設置想輸出的時間，然後點擊輸出按鈕。",
    enedata050: "1年以內",
    enedata051: "6個月以內",
    enedata052: "3個月以內",
    enedata053: "1年以內",
    enedata054: "13個月以內",
    enedata055: "每月數據期限需在1年以內",
    enedata056: "每日數據期限需在6個月以內",
    enedata057: "每小時數據期限需在3個月以內",
    enedata058: "換算係數數據期限需在1年以內",
    enedata059: "電力需求平准化數據期限需在13個月以內",
    enedata060: "能源類型",
    enedata061: "平準化",
    enedata062: "節能管理",
    enedata063: "統計年度比較",
    enedata064: "建築物比較",
    enedata065: "没有数据。",
    enedata066: "没有计量点。",
    enedata067: "目標(平准化換算後)",
    enedata068: "實際(平准化換算後)",
    enedata069: "单位(月)",
    enedata070: "单位(年度)",
    enedata071: "平准化換算前",
    enedata072: "平准化換算值",
    enedata073: "平准化換算後",
    enedata074: "年度合計",
    enedata075: "顯示層級",
    enedata076: "客戶",
    enedata077: "建築",
    enedata078: "站點",
    enedata079: "租戶",
    enedata080: "目標年度沒有可見能源",
    enedata081: "設置成功",
    enedata082: "設置失敗",
    enedata083: "租戶輸入",
    enedata084: "費用計算",
    enedata085: "實際值輸入",
    enedata086: "未輸入確認",
    enedata087: "能源",
    enedata088: "選項",
    enedata089: "包括租戶",
    enedata090: "確定對象",
    enedata091: "僅顯示未輸入",
    enedata092: "選擇客戶並點擊確定對象按鈕",
    enedata093: "未輸入",
    enedata094: "已輸入",
    enedata095: "存在未輸入項。請在輸入所有項後再次執行。",
    enedata096: "單價",
    enedata097: "分",
    enedata098: "角",
    enedata099: "圓",
    enedata100: "用電類型",
    enedata101: "峰谷時段",
    enedata102: "高峰",
    enedata103: "平段",
    enedata104: "低谷",
    enedata105: "計量值",
    enedata106: "計量計數器",
    enedata107: "累計資料",
    enedata108: "時數據",
    enedata109: "日數據",
    enedata110: "月數據",
    enedata111: "維護登記",
    enedata112: "權重",
    enedata113: "計量單位",
    enedata114: "聲音類別",
    enedata115: "上下限值監視",
    enedata116: "上限值異常監視",
    enedata117: "下限值異常監視",
    enedata118: "使用率的上限值",
    enedata119: "使用率的下限值",
    enedata120: "速率間隔",
    enedata121: "秒",
    enedata122: "使用率(使用量/速率間隔)",
    enedata123: "當前值",
    enedata124: "評論註冊欄",
    enedata125: "最多5條評論",
    enedata126: "點擊編寫評論",
    enedata127: "清空",
    enedata128: "刷新",
    enedata129: "確認",
    enedata130: "關聯信號點",
    enedata131: "取值範圍",
    enedata132: "上限值",
    enedata133: "下限值",
    enedata134: "死區",
    enedata135: "0~（上限值-下限值）範圍內設置",
    enedata136: "點擊編輯名稱",
    enedata137: "功率因素",
    enedata138: "數據載入失敗",
    enedata139: "趨勢圖",
    enedata140: "確定要清除所有評論內容嗎?",
    enedata141: "狀態值",
    enedata142: "ON名稱",
    enedata143: "OFF名稱",
    enedata144: "維護監視",
    enedata145: "時間表控制",
    enedata146: "控制狀態",
    enedata147: "上述評論對於本點所屬設備是共通的。",
    enedata148: "COS監視",
    enedata149: "視頻",
    enedata151: "操作",
    enedata152: "狀態類別",
    enedata153: "條件不一致監視",
    enedata154: "監控時間",
    enedata155: "設置值",
    enedata156: "故障報警時間超過故障監視報警時間",
    enedata157: "小時",
    enedata158: "故障報警次數超過故障監視報警次數",
    enedata159: "次",
    enedata160: "累計執行時間超過監控點執行時間",
    enedata161: "連續執行時間超過監視連續執行時間",
    enedata162: "狀態變化次數超過監視狀態變化次數",
    enedata163: "基本單位指標",
    enedata164: "名稱",
    enedata165: "單位",
    enedata166: "輸入方法",
    enedata167: "計算方法",
    enedata168: "全年值",
    enedata169: "刪除",
    enedata170: "手動輸入",
    enedata171: "計算",
    enedata172: "平均",
    enedata173: "註冊",
    enedata174: "名稱沒有輸入。",
    enedata175: "單位沒有輸入。",
    enedata176: "計算方法沒有被選擇。請選擇計算方法。",
    enedata177: "計算方法不能選擇自己。",
    enedata178: "計算方法不能選擇相同的項目。",
    enedata179: "計算方法不能選擇未註冊的項目。",
    enedata180: "資料註冊成功。",
    enedata181: "數據註冊失敗。",
    enedata182: "資料刪除成功！",
    enedata183: "資料刪除失敗！",
    enedata184: "確認要進行設定內容註冊嗎？",
    enedata185: "確認要進行刪除操作嗎？",
    enedata186: "確認要放棄所編輯的資料嗎？",
    enedata187: "確認要刪除該運算點嗎?",
    enedata188: "公式",
    enedata189: "編輯",
    enedata190: "點GR設置",
    enedata191: "添加",
    enedata192: "註冊名稱",
    enedata193: "運算式類型",
    enedata194: "運算類型",
    enedata195: "共${total}條數據",
    enedata196: "運算點設置",
    enedata197: "請先選擇需要刪除的運算點",
    enedata198: "運算式",
    enedata199: "所屬",
    enedata200: "首頁數據展示",
    enedata201: "是",
    enedata202: "否",
    enedata203: "單位名稱",
    enedata204: "選擇“自由單位”時可以在【自由單位】中輸入單位；單位修改不影響運算。",
    enedata205: "請選擇單位名稱!",
    enedata206: "自由單位",
    enedata207: "小數點以下位數",
    enedata208: "信號類型",
    enedata209: "設備清單",
    enedata210: "加減運算設置",
    enedata211: "加法選擇設備清單",
    enedata212: "選擇",
    enedata213: "解除",
    enedata214: "減法選擇設備清單",
    enedata215: "一般運算設置",
    enedata216: "選擇運算式",
    enedata217: "請選擇設備信號點",
    enedata218: "不能包含特殊字元",
    enedata219: "運算點名稱長度必須在 1 到 30之間",
    enedata220: "自由單位長度必須在 0 到 30之間",
    enedata221: "公式長度必須在 0 到 30之間",
    enedata222: "請輸入運算點名稱",
    enedata223: "請輸入運算點公式",
    enedata224: "請選擇運算點類型",
    enedata225: "請選擇運算式",
    enedata227: "請選擇小數點以下位數",
    enedata228: "請選擇所屬",
    enedata229: "確認要保存所設置的運算點資訊嗎？",
    enedata230: "請選擇所要設置的設備。",
    enedata231: "電力需求平准化報告首年度設置",
    enedata232: "計量類型",
    enedata233: "7月檢查日",
    enedata234: "12月檢查日",
    enedata235: "計量信號編號",
    enedata236: "計量信號名稱",
    enedata237: "請選擇能源類型",
    enedata238: "確認要放棄所編輯的能源類型計量對象資訊嗎？",
    enedata239: "確認要保存所設置的能源類型計量對象資訊嗎？",
    enedata240: "削減目標",
    enedata241: "建築/租戶",
    enedata242: "包括建築群",
    enedata243: "包括建築",
    enedata244: "※當客戶或建築組選擇時，該階層以下的全部削減目標（除了租戶）將被覆蓋。 另外，選擇【包括租戶】的話，那個樓層以下包括租戶的削減目標將全部被覆蓋，請注意。",
    enedata245: "※削減目標是自動繼承前一年度的資料。因此，如果需要變更的話，請每年重新設定。 ",
    enedata246: "能源使用量",
    enedata247: "上一年度比",
    enedata248: "目標值",
    enedata249: "最後更新日",
    enedata250: "確認要保存所設置的削減目標嗎？",
    enedata251: "如果將削減目標值更改為空白井進行註冊，則削減目標和上次修改如期將被刪除，確定要註冊嗎？",
    enedata252: "基本單位指標值",
    enedata253: "確認要保存所設置的基本單位指標值嗎？",
    enedata254: "建築名稱",
    enedata255: "全年通用",
    enedata256: "虛擬點設置",
    enedata257: "確認要刪除該虛擬點嗎？",
    enedata258: "請選擇需要刪除的虛擬點",
    enedata259: "能源/選項",
    enedata260: "名稱不能包含特殊字元!",
    enedata261: "請輸入虛擬點名稱",
    enedata262: "虛擬點名稱長度必須在 1 到 30之間",
    enedata263: "請選擇能源/選項",
    enedata264: "確認要保存所設置的虛擬點資訊嗎？",
    enedata265: "能源使用類型設置",
    enedata266: "現在已註冊能源",
    enedata267: "燃氣類型",
    enedata268: "能源供應公司",
    enedata269: "租戶權原分",
    enedata270: "費用",
    enedata271: "能源換算",
    enedata272: "白天",
    enedata273: "夜間",
    enedata274: "產氣率",
    enedata275: "值",
    enedata276: "第一年度電力需求平准化報告",
    enedata278: "能源使用類型",
    enedata279: "確認要保存所設置的能源使用類型嗎？",
    enedata280: "已設置費用信號點，不許取消設置；請在計量點-本司/租戶區分設置畫面確認費用信號點設置。",
    enedata281: "所選擇能源使用類型已設置租戶權原分計量信號點，不許取消設置；請在計量點-本司/租戶區分設置畫面確認租戶權原分計量信號點設置。",
    enedata282: "所選擇能源使用類型已設置計量信號點，不許取消註冊；請在計量點-能源類型設置畫面確認計量信號點設置。",
    enedata283: "確認要刪除所選擇的點Gr資訊嗎？",
    enedata284: "確認要保存所設置的點Gr資訊嗎？",
    enedata286: "總使用量",
    enedata287: "租戶使用量",
    enedata288: "所選擇設備計量點已被註冊",
    enedata289: "還沒有相應能源使用類型的設備信號點；請在運算點管理中進行註冊，並在能源類型中進行設置。",
    enedata290: "請選擇建築/租戶",
    enedata291: "請選擇目標年度",
    enedata292: "計量點管理",
    enedata293: "設置內容",
    enedata294: "本司/租戶區分",
    enedata295: "計量對象區分",
    enedata296: "虛擬點",
    enedata297: "供應商",
    enedata298: "編號",
    enedata299: "確認要保存所設置的計量對象組詳細資訊嗎？",
    enedata300: "確認要保存所設置的計量對象組名稱嗎？",
    enedata301: "確認要刪除計量對象組【${text}】嗎？",
    enedata302: "請輸入計量對象組名稱",
    enedata303: "計量對象組名稱長度必須在 1 到 30之間",
    enedata304: "計量對象組",
    enedata305: "計量對象組名稱不能包含特殊字元!",
    enedata306: "點擊編寫名稱",
    enedata307: "請輸入{text}	",
    enedata308: "{text}長度必須在 {star} 到 {end}之間	",
    enedata309: "{text}不能包含特殊字元!	",
    enedata310: "4月",
    enedata311: "5月",
    enedata312: "6月",
    enedata313: "7月",
    enedata314: "8月",
    enedata315: "9月",
    enedata316: "10月",
    enedata317: "11月",
    enedata318: "12月",
    enedata319: "1月",
    enedata320: "2月",
    enedata321: "3月",
    enedata322: "請選擇{text}	",
    enedata323: "請輸入公式	",
    enedata324: "請輸入��由單位	",
    enedata325: "添加點Gr	",
    enedata326: "請選擇點所屬	",
    enedata327: "點Gr	",
    enedata328: "請輸入點Gr名稱	",
    enedata329: "點Gr名稱不能包含特殊字元!	",
    enedata330: "點Gr名稱長度必須在 1 到 30之間	",
    enedata331: "添加計量對象組	",
    enedata332: "請選擇計量物件	",
    enedata333: "重置	",
    enedata334: "記錄",
    enedata335: "記錄清單",
    enedata336: "記錄類型",
    enedata337: "日誌記錄",
    enedata338: "趨勢記錄",
    enedata339: "開始日期",
    enedata340: "結束日期",
    enedata341: "設備名稱",
    enedata342: "信號編號",
    enedata343: "信號名稱",
    enedata344: "信號點",
    enedata345: "設備",
    enedata346: "請選擇開始日期",
    enedata347: "請選擇結束日期",
    enedata348: "用電量",
    enedata349: "時間",
    enedata350: "日期",
    enedata351: "信號編號",
    enedata352: "計量值",
    enedata353: "計量單位",
    enedata354: "複製換算係數。",
    enedata355: "在目標年度中選擇了當前年度。確認複製換算係數嗎？",
    enedata356: "在目標年度中選擇了過去的年度。確認複製換算係數嗎？",
    enedata357: "確認複製換算係數嗎？",
    enedata358: "取得換算係數失敗。",
    enedata359: "找不到能源類型組的換算值單位。",
    enedata360: "註冊成功。",
    enedata361: "現在處於難以聯繫的狀態，給您添麻煩。請過一段時間再執行。",
    enedata362: "換算值超過設置值限制，請重新輸入。",
    enedata363: "能量換算值超過設置值限制，請重新輸入。",
    enedata364: "調整前CO2換算值超過設置值限制，請重新輸入。",
    enedata365: "調整後CO2換算值超過設置值限制，請重新輸入。",
    enedata366: "LPG單位變換換算量超過設置值限制，請重新輸入。",
    enedata367: "No.{num}的換算值未輸入的情況下，不能選擇確定檢查框。",
    enedata368: "No.{num}的換算值，請全部未輸入或全部輸入。",
    enedata369: "因為超過了上年度複製的範圍，所以{num}年不能實行。",
    enedata370: "上年度複製",
    enedata371: "{name}的輸入超出範圍。",
    enedata372: "{name}的輸入超出範圍。請在{min}～{max}的範圍內設置。",
    enedata373: "{name}輸入值錯誤。",
    enedata374: "下限值大於上限值。",
    enedata375: "確認狀態",
    enedata376: '無數據，不能生成檔，無法下載。',
    enedata377: "用水量",
    enedata378: "趨勢記錄：設備每隔5分鐘記錄一次的數據。",
    enedata379: "日誌記錄：設備每隔一小時記錄一次的數據。",
    enedata380: "實際值能源站點每月實際的能源使用量。該功能是提供給客戶輸入和修改實際的能源使用量和實際費用的。",
    enedata381: "未輸入確認功能是對顧客實績輸入畫面中的輸入資料，每個月進行確認以及進行資料的確定的功能。",
    enedata382: "節能管理畫面是用圖表顯示能源使用量和費用的月變化的功能。對比顯示每個月的實際資料與節能目標資料。",
    enedata383: "統計年度比較畫面是使用圖表比較「當年度」「上年度」「上上年度」每月、每年度的實際能源使用量的畫面",
    enedata384: "建築比較畫面是顯示能源使用量或費用及租戶使用量的建築物比較的畫面。",
    enedata385: "統計資料畫面是將每個計量物件的能源使用量以月為單位、日為單位、時間為單位進行圖表顯示的畫面。",
    enedata386: "累計圖表畫面是將每個計量物件組的能源使用量以月為單位、日為單位、小時為單位進行圖表顯示的畫面。",
    enedata387: "使用量實績畫面是對電，煤氣，油，水，其他的能源，按每月進行原單位換算和CO2排放量換算的畫面。",
    enedata388: "削減目標是註冊指定年度的能源使用量的節能目標的畫面。",
    enedata389: "基本單位指標的註冊 計算基本單位時使用的分母的註冊畫面。 ",
    enedata390: "基本單位指標的輸入畫面是為每個月單位的基本單位指標的登記、確認的畫面。",
    enedata391: "能源使用類型設置畫面是進行建築物的能源供給公司和類型的登記的畫面。",
    enedata392: "計量點管理畫面是登記、確認各建築物和租賃的能源供應公司及點的畫面。",
    enedata393: "計量點管理畫面包含了能源類型設定畫面、本司/租戶區分註冊畫面、計量物件區分註冊畫面、虛擬點註冊畫面4個畫面。",
    enedata394: "能源類型設置畫面是對點設定供給公司和能源類型的畫面。  ",
    enedata395: "本司/租戶區分註冊畫面對點進行區分（總使用量/租戶使用量/租戶權原分）的註冊畫面;本司/租戶區分僅可以設置運算點及虛擬點。",
    enedata396: "計量物件區分註冊畫面是對點進行計量物件區分（例：空調/照明等）的註冊畫面。",
    enedata397: "虛擬點註冊畫面是登記用於接收費用和自動計量以外的資料的點的畫面。",
    enedata398: "自定義日期",
    enedata399: "起始月份",
    enedata400: "樓層/租戶",
    enedata401: "點擊編輯信號點名稱",
    enedata402:"發佈資訊",
    enedata403:"標題字體設置",
    enedata404:"副標題字體設置",
    enedata405:"標籤項名稱字體設置",
    enedata406:"標籤項內容字體設置",
    enedata407:"警告提示字體設置",
    enedata408:"備註字體設置",
    enedata409:"是否發送到顯示器",
    enedata410:"大小",
    enedata411:"顏色",
    enedata412:"僅保存",
    enedata413:"同步到顯示器",
    enedata414:"字體樣式設置",
    enedata415:"請輸入標題。",
    enedata416:"請輸入副標題。",
    enedata417:"請輸入標籤項名稱。",
    enedata418:"請輸入標籤項內容。",
    enedata419:"請輸入警告提示。",
    enedata420:"請輸入備註。",
    enedata421:"標題長度必須在1到30之間。",
    enedata422:"副標題長度必須在1到30之間。",
    enedata423:"標籤項名稱長度必須在1到30之間。",
    enedata424:"標籤項內容長度必須在1到30之間。",
    enedata425:"警告提示長度必須在1到30之間。",
    enedata426:"備註長度必須在1到100之間。",
    enedata427:"僅發佈資訊",
    enedata428:"僅設置字體樣式",
    enedata429:"同時發佈資訊和設置字體樣式",
    enedata430:"請選擇日期",
    enedata431:"選擇顏色",
    enedata432:"請選擇顏色",
    enedata433:"顏色代碼格式錯誤",
    enedata434: "點擊跳轉警報設置頁面",
    enedata435: "計量資訊",
    enedata436:"上次計量日期",
    enedata437: "計量有效期(月)",
    enedata438: "下次計量日期",
    enedata439:"日期",
    enedata440:"選擇周",
    enedata441:"設備/訊號名稱",
    enedata442:"月份",
    enedata443:"碳排放",
    enedata444:"設備聯動設定",
    enedata445:"上一日",
    enedata446:"下一日",
    enedata447:"上一小時",
    enedata448:"下一小時",
    enedata449:"趨勢清單",
    enedata450:"請選擇年度",
    enedata451:"區域",
    enedata452:"請選擇區域",
    enedata453:"換算係數",
    enedata454:"請輸入換算係數",
    enedata455:"換算單位",
    enedata456:"請輸入換算單位",
    enedata457:"換算單位長度必須在 1 到 30之間",
    enedata458:"確認要保存所設定的換算係數資訊嗎？",
    enedata459:"確認要删除該換算係數嗎？",
    enedata460:"請先選擇需要删除的換算係數！",
    enedata461:"確認要删除所選擇的換算係數嗎？",
    enedata462:"站點能源換算係數設定",
    enedata463:"區域能源換算係數設定",
    enedata464:"能源換算係數",
    enedata465:"站點能源換算係數",
    enedata466:"區域能源換算係數",
    enedata467:'設備類型',
    enedata468:'請選擇設備類型！',
    enedata469:'請選擇關聯訊號！',
    enedata470:'請輸入虛擬設備名稱！',
    enedata471:'虛擬設備名稱長度必須在 1 到 30之間',
    enedata472:'請選擇',
    enedata473:'設備所屬',
    enedata474:'虛擬設備設定',
    enedata475:'確認要删除該虛擬設備嗎？',
    enedata476:'請先選擇需要删除的虛擬設備！',
    enedata477:'確認要删除所選擇的換算係數嗎？',
    enedata478:'虛擬設備管理',
    enedata479:'演算點管理',
    enedata480: '請輸入生產線名稱！',
    enedata481: '生產線名稱長度必須在 1 到 30之間',
    enedata482: '生產線',
    enedata483: '生產線設置',
    enedata484: '生產線名稱',
    enedata485: '確認要保存所設置的生產線資訊嗎？',
    enedata486: '確認要刪除該生產線嗎？',
    enedata487: '添加生產線',
    enedata488: '編輯生產線',
    enedata489: '產品名稱',
    enedata490: '生產訂單號',
    enedata491: '生產開始日期',
    enedata492: '生產結束日期',
    enedata493: '生產數量',
    enedata494: '產品單位',
    enedata495: '生產能耗',
    enedata496: '能耗單位',
    enedata497: '生產單耗',
    enedata498: '單耗單位',
    enedata499: '生產單耗管理',
    enedata500: '請選擇生產線',
    enedata501: '請輸入產品名稱',
    enedata502: '請輸入生產訂單號',
    enedata503: '請選擇生產開始日期',
    enedata504: '請選擇生產結束日期',
    enedata505: '請輸入生產數量',
    enedata506: '請輸入產品單位',
    enedata507: '請輸入生產能耗',
    enedata508: '請輸入能耗單位',
    enedata509: '請輸入生產單耗',
    enedata510: '請輸入單耗單位',
    enedata511: '生產日期',
    enedata512: '請選擇生產日期',
    enedata513: '產品名稱長度必須在 1 到 30之間',
    enedata514: '生產訂單號長度必須在 1 到 30之間',
    enedata515: '產品單位長度必須在 1 到 30之間',
    enedata516: '能耗單位長度必須在 1 到 30之間',
    enedata517: '備註說明長度必須在 1 到 300之間',
    enedata518: '確認要保存所設置的生產能耗資訊嗎？',
    enedata519: '確認要刪除該生產能耗嗎？',
    enedata520: '請先選擇需要刪除的生產能耗！',
    enedata521: '確認要刪除所選擇的生產能耗嗎？',
    enedata522: '生產數量不能為零！',
    enedata523: '生產單耗不能為零！',
    enedata524: '值不能為零！',
    enedata525: 'CSV導入',
    enedata526: '導入生產能耗資料',
    enedata527: '範本檔',
    enedata528: '生產能耗範本檔',
    enedata529: '下載生產能耗範本檔',
    enedata530: '錯誤資訊',
    enedata531: '下載錯誤資訊',
    enedata532: 'NO,生產線名稱,產品名稱,生產訂單號,生產開始日期,生產結束日期,生產數量,產品單位,生產能耗,能耗單位,備註',
    enedata533: '分組依據',
    enedata534: '生產單耗報表',
    enedata535: '請選擇計量點。',
    enedata536:'時間設置趨勢圖表',
    enedata537:'資料比較趨勢圖表',
    enedata538:'資料輸出',
    enedata539:'資料比較趨勢組設置',
    enedata540:'時間設置趨勢組設置',
    enedata541: '所選日期已存在，請勿重複選擇',
    enedata542: '最多添加7個日期。',
    enedata543: '檔案格式錯誤，請選擇CSV檔案。',
    enedata544: '今天',
    enedata545: '當月',
    enedata546: '上月',
    enedata547:'趨勢記錄資訊',
    enedata548:'記錄生成間隔時間',
    enedata549:'讀取間隔時間',
    enedata550:'最新趨勢記錄時間',
    enedata551:'分鐘',
    enedata552:'趨勢讀取狀態',
    enedata553:'讀取中',
    enedata554:'停止讀取',
    enedata555:'最近讀取時間',
    enedata556:'最近讀取編號',
    enedata557:'年報資料批量導入',
    enedata558:'年報資料批量導入',
    enedata559:'月報數據批量導入',
    enedata560:'日報數據批量導入',
    enedata561:'年報批量錄入範本檔案',
    enedata562:'月報批量錄入範本檔案',
    enedata563:'日報批量錄入範本檔案',
    enedata564:'月/單位',
    enedata565:'日/單位',
    enedata566:'時/單位',
    enedata567:'參數錯誤',
    enedata568:'訊號點使用量修改',
    enedata569:'使用量修改模式',
    enedata570:'根據設備資訊修改',
    enedata571:'手動輸入使用量修改',
    enedata572:'當前日期和未來日期的使用量不可修改。',
    enedata573:'白天使用量',
    enedata574:'夜間使用量',
    enedata575:'每月/每日才區分白天和夜間使用量。',
    enedata576:'白天時間段為9點~22點。',
    enedata577:'夜間時間段為1點~8點和23點~24點。',
    enedata578:'數據來源',
    enedata579:'資料庫',
    enedata580:'設備',
    enedata581:'同步更新數據',
    enedata582:'是',
    enedata583:'否',
    enedata584:'同步更新數據：修改每小時數據,同步更新每日和每月的數據,當天和當月數據除外。 修改每日數據,同步更新每月的數據,當月數據除外。 修改每月數據無同步更新數據。',
    enedata585:'KPI日期',
    enedata586:'操作日期',
    enedata587:'執行日期',
    enedata588:'警報狀態',
    enedata589:'運行狀態',
    enedata590:'KPI目標值',
    enedata591:'年度規則',
    enedata592:'能效KPI警報設定',
    enedata593:'啟用',
    enedata594:'停止',
    enedata595:'執行',
    enedata596:'請先選擇需要設定的KPI目標警報。',
    enedata597:'請選擇警報等級。',
    enedata598:'能效KPI自動任務管理',
    enedata599:'請輸入白天使用量',
    enedata600:'請輸入夜間使用量',
    enedata601:'請輸入全天使用量',
    enedata602:'白天使用量值範圍在0~999999999之間。',
    enedata603:'夜間使用量值範圍在0~999999999之間。',
    enedata604:'全天使用量值範圍在0~1999999998之間。',
    enedata605:'站點能耗對比',
    enedata606:'父節點',
    enedata607:'設備類型',
    enedata608:'父節點',
    enedata609:'運算公式輸入錯誤',
    enedata610:'實时結餘',

  },
  video: {
    viddata001: '外掛程式下載',
    viddata002: '視頻外掛程式',
    viddata003: '下載',
    viddata004: '攝像機類型',
    viddata005: '攝像機ID',
    viddata006: '攝像機名稱',
    viddata007: '攝像機IP',
    viddata008: '攝像機埠',
    viddata009: '錄影機名稱',
    viddata010: '錄影機IP',
    viddata011: '錄影機埠',
    viddata012: '錄影機類型',
    viddata013: '頻寬限制',
    viddata014: '頻寬限制批量設置',
    viddata015: '全方位校正',
    viddata016: '全方位校正批量設置',
    viddata017: '標準',
    viddata018: '任務視圖',
    viddata019: '雙全景',
    viddata020: '可註冊攝像機列表',
    viddata021: '已註冊攝像機列表',
    viddata022: '攝像機分組名稱',
    viddata023: '攝像機分組設置',
    viddata024: '攝像機設置',
    viddata025: '創建分組',
    viddata026: '刪除分組',
    viddata027: '編輯名稱',
    viddata028: '設置許可權未被許可。',
    viddata029: '批量編輯',
    viddata030: '攝像機分組',
    viddata031: '即時視頻',
    viddata032: '重播',
    viddata033: '攝像機列表',
    viddata034: '視頻記錄清單',
    viddata035: '注意',
    viddata036: '視頻錄影檔存放位置',
    viddata037: '抓圖文件存放位置',
    viddata038: '全屏',
    viddata039: '單屏播放',
    viddata040: '{num}屏播放',
    viddata041: '啟用電子放大',
    viddata042: '禁用電子放大',
    viddata043: '抓圖',
    viddata044: '開始錄影',
    viddata045: '結束錄影',
    viddata046: '播放',
    viddata047: '暫停',
    viddata048: '停止',
    viddata049: '減速播放',
    viddata050: '加速播放',
    viddata051: '保存',
    viddata052: '本地播放',
    viddata053: '重連',
    viddata054: '關閉',
    viddata055: "狀態面板",
    viddata056: "電子放大",
    viddata057: "恢復",
    viddata058: "視頻記錄名稱",
    viddata059: "開始時間",
    viddata060: "結束時間",
    viddata061: "系統不支援攝像機類型",
    viddata062: "您還未安裝過外掛程式，請在下載功能頁面下載外掛程式進行安裝。",
    viddata063: "全部停止",
    viddata064: "攝像機",
    viddata065: "攝像機數量",
    viddata066: "停止預覽失敗！",
    viddata067: "預覽失敗！",
    viddata068: "設備不支援Websocket取流！",
    viddata069: "錄影機已登錄過！",
    viddata070: "錄影機登錄連接失敗！",
    viddata071: "攝像機總數",
    viddata072: "已分組攝像機總數",
    viddata073: "未分組攝像機總數",
  },
  // 认证信息
  information: {
    a1: "加載中....",
    a2: "卡片資訊",
    a3: "人員資訊",
    a4: "卡所屬選擇",
    a5: "姓名",
    a6: "請輸入姓名",
    a7: "管理代碼",
    a8: "請輸入管理代碼",
    a9: "卡號",
    a10: "請輸入卡號",
    a11: "卡編號",
    a12: "請輸入卡編號",
    a13: "顯示選項",
    a14: "從所屬",
    a15: "全部",
    a16: "使用類型",
    a17: "全部使用類型",
    a18: "普通卡",
    a19: "臨時卡",
    a20: "使用狀態",
    a21: "全部使用狀態",
    a22: "未分配",
    a23: "使用中",
    a24: "卡狀態",
    a25: "全部卡狀態",
    a26: "可使用·借出",
    a27: "不可使用·返還",
    a28: "停止使用",
    a29: "査詢",
    a30: "卡片信息清單",
    a31: "删除",
    a32: "編輯",
    a33: "選擇操作",
    a34: "卡所屬編輯",
    a35: "添加",
    a36: "條數據",
    a37: "可使用",
    a38: "不可使用",
    a39: "停止使用",
    a40: "借出",
    a41: "返還",
    a42: "管理所屬",
    a43: "關聯建築",
    a44: "開始使用日期",
    a45: "有效期限",
    a46: "最終使用日期",
    a47: "備註",
    a48: "操作",
    a49: "確定要删除卡片資訊?",
    a50: "變更",
    a51: "關閉",
    a52: "添加下級所屬",
    a53: "所屬名稱：",
    a54: "請輸入名稱",
    a55: "所屬名稱變更",
    a56: "確定要删除",
    a57: "嗎？",
    a58: "添加卡片資訊",
    a59: "卡類型",
    a60: "卡所屬",
    a61: "卡號不能為空",
    a62: "卡編號不能為空",
    a64: "編輯卡片資訊",
    a65: "設定",
    a66: "管理所屬選擇",
    a67: "部門",
    a68: "請輸入部門",
    a69: "人員信息清單",
    a70: "人員編輯",
    a71: "人員充值",
    a72: "管理所屬編輯",
    a73: "人員添加",
    a74: "卡類型設定",
    a75: "帳戶充值",
    a76: "批量添加注册",
    a77: "帳戶類型",
    a78: "記帳",
    a79: "現金",
    a80: "餘額",
    a81: "注册日期",
    a82: "所屬1",
    a83: "所屬2",
    a84: "所屬3",
    a85: "所屬4",
    a86: "所屬5",
    a87: "人員資訊批量注册",
    a88: "人員資訊",
    a89: "管理所屬選擇",
    a90: "卡片所屬選擇",
    a91: "通行站點選擇",
    a92: "人員資訊（XLSX、CSV）上傳",
    a93: "*管理所屬，請選擇“全部管理所屬”",
    a94: "選擇檔案",
    a95: "上傳",
    a96: "資訊下載",
    a97: "同時下載下級的管理所屬",
    a98: "下載",
    a99: "指靜脈資訊（.CSV）上傳",
    a100: "指靜脈資訊（.CSV）下載",
    a101: "只下載手指靜脈已注册的數據",
    a102: "指靜脈資訊",
    a103: "車牌資訊",
    a104: "車牌資訊（.CSV）上傳",
    a105: "車牌資訊（.CSV）下載",
    a106: "僅下載車牌已注册的數據",
    a107: "檔案格式錯誤",
    a108: "請選擇檔案格式為.csv的檔案",
    a109: "卡類型設定",
    a110: "消費卡類型設定",
    a111: "人員管理",
    a113: "基本資訊",
    a114: "手機號碼",
    a116: "請輸入手機號碼",
    a117: "所屬：",
    a118: "所屬分組：",
    a119: "注册日期：",
    a120: "更新日期：",
    a121: "所屬變更",
    a122: "卡",
    a123: "卡資訊",
    a124: "刪除卡片",
    a125: "關聯站點",
    a126: "選擇站點",
    a127: "普通卡",
    a128: "卡編號：",
    a129: "有效期限：",
    a130: "卡狀態：",
    a131: "可使用",
    a132: "不可使用",
    a133: "停止使用",
    a134: "指靜脈",
    a135: "第一指資訊：",
    a136: "第二指資訊：",
    a137: "閾值水准：",
    a138: "人臉",
    a139: "人臉資訊：",
    a140: "特權設定",
    a141: "清潔員特權：",
    a142: "無",
    a143: "有",
    a144: "指靜脈特權：",
    a145: "消費設定",
    a146: "卡類型：",
    a147: "結帳管道：",
    a148: "梯控",
    a149: "房間名：",
    a150: "關聯電梯",
    a151: "選擇電梯",
    a152: "站點名稱",
    a153: "通行模式1",
    a154: "通行模式",
    a155: "有效期限",
    a156: "通行模式2",
    a157: "通行模式3",
    a158: "通行模式4",
    a159: "通行模式5",
    a160: "電梯名稱",
    a161: "樓層",
    a162: "房間名",
    a163: "未注册",
    a164: "已注册",
    a165: "已採集",
    a166: "沒有選擇管理所屬",
    a167: "請選擇管理所屬資訊",
    a168: "管理所屬選擇及編輯",
    a169: "卡選擇",
    a170: "卡號",
    a171: "請輸入卡ID",
    a172: "卡編號",
    a173: "請輸入卡編號",
    a174: "査詢",
    a175: "卡清單",
    a176: "卡編號",
    a177: "卡號",
    a178: "卡狀態",
    a179: "可使用",
    a180: "不可使用",
    a181: "停止使用",
    a182: "借出",
    a183: "返還",
    a184: "卡類型",
    a185: "普通卡",
    a186: "臨時卡",
    a187: "卡使用開始日期",
    a188: "操作",
    a189: "有效期限設定",
    a190: "取消",
    a191: "確認",
    a192: "初始日期",
    a193: "終止日期",
    a194: "指靜脈採集",
    a195: "第一指：",
    a196: "讀取",
    a197: "第二指：",
    a198: "設備：指靜脈採集器連接成功！",
    a199: "設備：指靜脈採集器未連接！",
    a200: "待讀取",
    a201: "讀取中…",
    a202: "讀取失敗",
    a203: "已採集",
    a204: "人臉圖片設定",
    a205: "選擇圖片",
    a206: "注：建議使用標準證件照，圖片格式jpg。",
    a207: "站點選擇",
    a208: "設定",
    a209: "關閉",
    a210: "未注册站點清單",
    a211: "添加↓",
    a212: "注册的站點清單",
    a213: "删除↑",
    a214: "條數據",
    a215: "名稱",
    a216: "地址",
    a217: "類型",
    a218: "編號",
    a219: "通行模式選擇",
    a220: "通行模式清單",
    a221: "通行模式",
    a222: "開始日期",
    a223: "結束日期",
    a224: "已選用",
    a225: "該通行模式已經選用，請選擇其他通行模式",
    a226: "達到添加上限",
    a227: "僅能添加5條通行模式",
    a228: "電梯選擇",
    a229: "未注册電梯清單",
    a230: "注册的電梯清單",
    a231: "樓層名稱",
    a232: "房間號",
    a233: "帳戶充值",
    a234: "充值",
    a235: "儲值卡號査詢：",
    a236: "請輸入卡號",
    a237: "基本資訊",
    a238: "消費資訊",
    a239: "帳戶餘額",
    a240: "結帳管道",
    a241: "注册日期",
    a242: "更新日期",
    a243: "最近充值金額",
    a244: "最近充值時間",
    a245: "記帳卡無需充值",
    a246: "充值金額",
    a247: "請輸入充值金額",
    a248: "查無此人員資訊",
    a249: "請檢查輸入是否有誤",
    a250: "未輸入卡號",
    a251: "未輸入充值金額",
    a252: "沒有選擇房間號",
    a253: "請選擇選擇房間號",
    a254: "圖片大小不能超過6M",
    a255: "請選擇JGP格式的文件",
    a256: "國籍",
    a257: "電梯資訊",
    a258: "電梯資訊（.CSV）上傳",
    a259: "電梯資訊（.CSV）下載",
    a260: "管理程式碼不能為空",
    a261: "姓名不能為空",
    a262: "請填寫正確的手機號碼",
    a263: '通行模式詳情',
    a264: '通行模式名稱：',
    a265: '企業名稱',
    a266: '建築名稱',
    a267: '樓層·租戶名稱',
    a268: '設備名稱',
    a269: '認證終端名稱',
    a270: '出入類型',
    a271: '警戒設定',
    a272: '時間表名稱',
    a273: '行事曆名稱',
    a274: '請輸入通行模式名稱',
    a275: '總數量：',
    a276: '添加數量：',
    a277: '更新數量：',
    a278: '失敗數量：',
    a279: '詳情下載',
    a280: '數據詳情',
    a281: '請選擇站點',
    a282: '可註冊通行模式列表',
    a283: '已註冊通行模式列表',
    a284: '名稱搜索',
    a285: '數量已超過5條上限',
    a286: '權限設置',
    a287: '人臉資訊',
    a288: '人臉資訊（.zip）上傳',
    a289: '人臉資訊（.zip）下載',
    a290: '請選擇檔案格式為.zip的檔案',
    a291: '輸入蒐索文字',
    a292: '人員通行設備資訊下載',
    a293: '人員通行資訊下載',
    a294: '最近批量導入人員信息下載',
    a295:'選擇圖片',
    a296:'圖片編輯',
    a297:'圖片示例説明',
  },
  // 消费管理模块
  consume: {
    a1: "加載中....",
    a2: "設備詳情",
    a3: "關閉",
    a4: "基本信息",
    a5: "消費時段",
    a6: "價格一覽",
    a7: "正常運行",
    a8: "離線/維修",
    a9: "早餐",
    a10: "午餐",
    a11: "晚餐",
    a12: "宵夜",
    a13: "終端名稱",
    a14: "站點名稱",
    a15: "客戶名稱",
    a16: "狀態",
    a17: "星期一",
    a18: "星期二",
    a19: "星期三",
    a20: "星期四",
    a21: "星期五",
    a22: "星期六",
    a23: "星期日",
    a24: "卡片記錄",
    a25: "姓名",
    a26: "請輸入姓名",
    a27: "卡片編號",
    a28: "請輸入卡片編號",
    a29: "管理代碼",
    a30: "請輸入管理代碼",
    a31: "消費機名稱",
    a32: "請輸入消費機名稱",
    a33: "消費機號",
    a34: "請輸入消費機名稱",
    a35: "開始日期",
    a36: "結束日期",
    a37: "查詢",
    a38: "記錄列表",
    a39: "請選擇日期或時間",
    a40: "條數據",
    a41: "發生日期",
    a42: "消費方式",
    a43: "賬戶類型",
    a44: "姓名",
    a45: "管理代碼",
    a46: "卡片編號",
    a47: "站點名稱",
    a48: "消費機號",
    a49: "消費機名稱",
    a50: "消費金額",
    a51: "餘額",
    a52: "餐次",
    a53: "所屬1",
    a54: "所屬2",
    a55: "所屬3",
    a56: "所屬4",
    a57: "所屬5",
    a58: "每日菜單設置",
    a59: "選擇文件",
    a60: "備註：只接受文件格式爲xlsx上傳。",
    a61: "上傳",
    a62: "消費設置",
    a63: "消費時段參數設置",
    a64: "消費機參數設置",
    a65: "刷卡間隔時間(秒)",
    a66: "日消費額度限額(元)",
    a67: "日消費次數限制",
    a68: "語音提示類型",
    a69: "--",
    a70: "1-消費成功語音提醒",
    a71: "2-短嘀一色",
    a72: "3-長嘀一色",
    a73: "4-VOICEType1",
    a74: "5-VOICEType2",
    a75: "設置",
    a76: "消費參數設置?",
    a77: "是否修改消費參數設置。",
    a78: "結算規則設置",
    a79: "各餐位分餐次價格設置一覽表",
    a80: "編輯",
    a81: "餐次價格設置",
    a82: "取消",
    a83: "確認",
    a84: "價格設置",
    a85: "早餐（元）",
    a86: "午餐（元）",
    a87: "晚餐（元）",
    a88: "宵夜（元）",
    a89: "操作",
    a90: "消費報表",
    a91: "報表時間段選擇",
    a92: "食堂選擇",
    a93: "時間段",
    a94: "輸出期間",
    a95: "一個月",
    a96: "一天",
    a97: "自定義時間",
    a98: "輸出報表類型選擇",
    a99: "全部",
    a100: "下載文件名稱",
    a101: "輸出中...",
    a102: "輸出",
    a103: "消費明細報表",
    a104: "個人消費日報表",
    a105: "個人消費月報表",
    a106: "綜合消費報表",
    a107: "部門消費報表",
    a108: "報餐報表",
    a109: "數據輸出",
    a110: "開始日期",
    a111: "請輸入開始日期",
    a112: "結束日期",
    a113: "請輸入結束日期",
    a114: "數據圖表",
    a115: "（刷卡次數）",
    a116: "請選擇日期",
    a117: "消費金額統計",
    a118: "總刷卡次數：",
  },

  // 訪客模組--訪客預約記錄
  visitorBookingRecord: {
    a1: '載入中',
    a2: '訪客預約記錄',
    a3: '自訂條件1',
    a4: '自訂條件2',
    a5: '時間',
    a6: '初始日期/時間選擇',
    a7: '終止日期/時間選擇',
    a8: '查詢',
    a9: '訪客預約記錄清單',
    a10: '請選擇日期或時間',
    a11: '條數據',
    a12: '訪客姓名',
    a13: '訪客電話',
    a14: '訪客單位/公司',
    a15: '工單狀態',
    a16: '到訪狀態',
    a17: '預約來訪時間',
    a18: '訪問結束時間',
    a19: '拜訪事由',
    a20: '拜訪說明',
    a21: '訪客車牌號碼',
    a22: '受訪人編號',
    a23: '受訪人姓名',
    a24: '受訪人所屬',
    a25: '來訪人數',
    a26: '申請時間',
    a27: '訂單編號',
    a28: '受訪人電話',
    a29: '詳情',
    a30: '訪問取消',
    a31: '未訪問',
    a32: '訪問中',
    a33: '訪問完成',
    a34: '訪問失約',
    a35: '未到訪',
    a36: '已到訪',
    a37: '已離開',
    a38: '已結束',
    a39: '商務洽談',
    a40: '政府行政事務',
    a41: '技術交流',
    a42: '市場調查',
    a43: '面試',
    a44: '送貨',
    a45: '維修',
    a46: '臨時員工',
    a47: '其他',
    a48: '操作',
    a49: '取消',
    a50: '選擇操作',
    a51: '訪客資訊',
    a52: '受訪人資訊',
    a53: '全部（拜訪事由）',
    a54: '全部（到訪狀態）',
    a55: '全部（工單狀態）',
    a56: '全部（認證類型）'
  },

  // 訪客模組--訪客人員記錄
  visitorPeopleRecord: {
    a1: '載入中',
    a2: '訪客人員記錄',
    a3: '自訂條件1',
    a4: '自訂條件2',
    a5: '時間',
    a6: '初始日期/時間選擇',
    a7: '終止日期/時間選擇',
    a8: '查詢',
    a9: '訪客人員記錄清單',
    a10: '請選擇日期或時間',
    a11: '條數據',
    a12: '訪客姓名',
    a13: '訪客電話',
    a14: '訪客單位/公司',
    a15: '工單狀態',
    a16: '到訪狀態',
    a17: '預約來訪時間',
    a18: '訪問結束時間',
    a19: '認證類型',
    a20: '滯留時間',
    a21: '訪客碼',
    a22: '受訪人編號',
    a23: '受訪人姓名',
    a24: '受訪人所屬',
    a25: '受訪人電話',
    a26: '申請時間',
    a27: '訂單編號',
    a28: '受訪人電話',
    a29: '詳情',
    a30: '訪問取消',
    a31: '未訪問',
    a32: '訪問中',
    a33: '訪問完成',
    a34: '訪問失約',
    a35: '未到訪',
    a36: '已到訪',
    a37: '已離開',
    a38: '已結束',
    a39: '卡認證',
    a40: '掌形操作',
    a41: '卡認證+指靜脈認證',
    a42: '卡認證+指紋認證',
    a43: '指靜脈認證',
    a44: '指紋認證',
    a45: '藍牙認證',
    a46: '數字鍵認證',
    a47: '身份證認證',
    a48: '二維碼認證',
    a49: '人臉認證',
    a50: '僅顯示滯留訪客',
    a51: '清除',
    a52: '選擇操作',
    a53: '進門設備',
    a54: '出門設備',
  },


  // 訪客模組--未來訪客統計記錄
  visitorFutureRecord: {
    a1: '載入中',
    a2: '未來訪客統計記錄',
    a3: '顯示物件',
    a4: '顯示專案',
    a5: '時間',
    a6: '初始日期/時間選擇',
    a7: '終止日期/時間選擇',
    a8: '查詢',
    a9: '訪客預約記錄清單',
    a10: '請選擇日期或時間',
    a11: '條數據',
    a12: '時間',
    a13: '訪客總數',
    a14: '待訪數',
    a15: '在訪數',
    a16: '離開數',
    a17: '完成數',
    a18: '滯留數',
    a19: '取消數',
    a20: '失約數',
    a21: '請選擇顯示物件',
    a22: '受訪人編號',
    a23: '受訪人姓名',
    a24: '受訪人所屬',
    a25: '來訪人數',
    a26: '申請時間',
    a27: '未來一周',
    a28: '未來一月',
    a29: '租戶',
    a30: '訪客姓名',
    a31: '訪客單位/公司',
  },
  // 訪客模組--訪客統計報表
  visitorStatisticalReport: {
    a1: '載入中',
    a2: '訪客統計報表',
    a3: '顯示物件',
    a4: '顯示專案',
    a5: '時間',
    a6: '初始日期/時間選擇',
    a7: '終止日期/時間選擇',
    a8: '查詢',
    a9: '訪客單位/公司',
    a10: '訪客姓名',
    a11: '條數據',
    a12: '時間',
    a13: '訪客總數',
    a14: '待訪數',
    a15: '在訪數',
    a16: '離開數',
    a17: '完成數',
    a18: '滯留數',
    a19: '取消數',
    a20: '失約數',
    a21: '訪客手機號碼',
    a22: '訪客類型',
    a23: '受訪人所屬',
    a24: '受訪人姓名',
    a25: '受訪人代碼',
    a26: '受訪人手機號碼',
    a27: '年度統計',
    a28: '月度統計',
    a29: '日度統計',
    a30: '租戶',
  },
  // 訪客模組--訪客資訊
  visitorRecord: {
    a1: '載入中',
    a2: '訪客資訊管理',
    a3: '自訂條件',
    a4: '添加',
    a5: '修改',
    a6: '刪除',
    a7: '操作',
    a8: '查詢',
    a9: '訪客信息清單',
    a10: '請選擇日期或時間',
    a11: '條數據',
    a12: '訪客類型',
    a13: '訪客姓名',
    a14: '訪客手機號碼',
    a15: '訪客證件類型',
    a16: '訪客證件號',
    a17: '訪客單位/公司',
    a18: '訪客所屬部門',
    a19: '訪客職務',
    a20: '訪客固定號碼',
    a21: '訪客微信',
    a22: '訪客地址',
    a23: '日期',
    a24: '備註',
    a25: '成功',
    a26: '失敗',
    a27: '請輸入訪客姓名',
    a28: '請輸入訪客手機號碼'
  },
  // 訪客模組--訪客黑名單資訊
  visitorBlacklist: {
    a1: '載入中',
    a2: '訪客黑名單管理',
    a3: '自訂條件',
    a4: '添加',
    a5: '修改',
    a6: '刪除',
    a7: '操作',
    a8: '查詢',
    a9: '訪客黑名單列表',
    a10: '請選擇日期或時間',
    a11: '條數據',
    a12: '訪客姓名',
    a13: '訪客手機號碼',
    a14: '訪客證件類型',
    a15: '訪客證件號',
    a16: '編輯日期',
    a17: '備註',
    a18: '成功',
    a19: '失敗',
    a20: '上傳黑名單',
    a21: '訪客黑名單資訊導入失敗。',
    a22: '確定要删除此資訊嗎？',
  },
  // 訪客模組--人員上下級設置
  upperAndLowerSetting: {
    a1: '人員上下級設置',
    a2: '編號：',
    a3: '人員資訊表',
    a4: '上級人員表',
    a5: '下級人員表',
    a6: '人員編號',
    a7: '姓名',
    a8: '所屬',
    a9: '上級姓名',
    a10: '是否發短信',
    a11: '是否發微信',
    a12: '下級姓名',
    a13: '選擇上級人員',
    a14: '選擇下級人員',
    a15: '刪除',
    a16: '刪除所有人員',
    a17: '設置',
    a18: '成功',
    a19: '失敗',
    a20: '條數據',
    a21: '查詢',
    a22: '自訂條件',
    a23: '加載中.....',
    a24: '請選擇條件',
    a25: '上級人員姓名',
    a26: '下級人員姓名'
  },
  // 訪客模組--訪客規則設置
  visitorRuleSetting: {
    a1: '是否對接微信',
    a2: '微信對接URL',
    a3: '可通行時間提前/延後值',
    a4: '訪客通行授權模式',
    a5: '根據受訪人許可權',
    a6: '根據通行模式',
    a7: '訪客通行模式',
    a8: '刪除',
    a9: '選擇通行模式',
    a10: '訪客認證模式',
    a11: '設置',
    a12: '通行模式選擇',
    a13: '訪客規則設置',
    a14: '成功',
    a15: '失敗',
    a16: '是',
    a17: '否',
    a18: '通行模式',
    a20: '條數據',
    a21: '查詢',
    a22: '自訂條件',
    a23: '確認要進行訪客資訊微信同步嗎？',
    a24: '確認要進行訪客黑名單微信同步嗎？',
    a25: '確認要進行字典參數微信同步嗎？',
    a26: '訪客資訊微信同步設定',
    a27: '訪客黑名單微信同步設定',
    a28: '字典參數微信同步設定',
  },
  // 访客模块--卡片
  visitor: {
    a1: '時間：',
    a2: '訪客：',
    a3: '被訪：',
    a4: '事由：',
    a5: '狀態：',
    a6: '順豐物流',
    a7: '前臺人員',
    a8: '開會',
    a9: '未到訪',
    a10: '普通訪客',
    a11: '類型：',
  },

  //訪客模塊--訪客詳情視窗
//訪客模塊--訪客詳情視窗
visitorDetailsModel:{
  a1:'基本資訊',
  a2:'受訪人資訊',
  a3:'申請人資訊',
  a4:'訪客人員資訊',
  a5:'訪問處理資訊',
  a6:'訂單編號',
  a7:'訂單狀態',
  a8:'拜訪狀態',
  a9:'訪問時間',
  a10:'申請時間',
  a11:'訪客類型',
  a12:'訪問事由',
  a13:'訪問備註',
  a14:'其他備註',
  a15:'管理程式碼',
  a16:'姓名',
  a17:'所屬',
  a18:'手機號碼',
  a19:'證件類型',
  a20:'證件號碼',
  a21:'訪客資訊',
  a22:'認證資訊',
  a23:'通行許可權',
  a24:'操作',
  a25:'發生時間',
  a26:'動作類型',
  a27:'動作資訊',
  a28:'操作人員程式碼',
  a29:'操作人員姓名',
  a30:'備註說明',
  a31:'訪客碼',
  a32:'認證管道',
  a33:'通行模式',
  a34:'通行時間',
  },

  //数据比较趋势组列表
  dataCompareTrendList: {
    a1: '載入中....',
    a2: 'CSV匯出',
    a3: '重載',
    a4: '日期1',
    a5: '條數據',
    a6: '數據種類',
    a7: '設備名稱',
    a8: '信號名稱',
    a9: '單位',
    a10: '時/日期',
    a11: '狀態/30分',
  },
  sitecard: {
    a1: '過往14日用電量',
    a2: '過往14日用水量',
    a3: '樓層/門禁數',
    a4: '樓層/空調數',
    a5: '地點/設備數',
    a6: '種類/設備數',
    a7: '區域/電燈線路數',
    a8: '出入口設備數',
    a9: '當月統計數',
    a10: '樓層/設備數',
    a11: '空調運行數',
    a12: '每小時通行認證數',
    a13: '設備通行認證數',
    a14: '過往14日通行認證數',
  },
  // 出入口模块--车辆记录（通行记录）
  carThroughrecords: {
    a0: '加載中….',
    a1: '車輛記錄',
    a2: '姓名',
    a3: '車牌號碼',
    a4: '初始日期/時間選擇',
    a5: '終止日期/時間選擇',
    a6: '査詢',
    a7: '記錄清單',
    a8: '請選擇日期或時間',
    a9: '車牌識別圖片',
    a10: '添加注釋',
    a11: '選擇操作',
    a12: '注釋',
    a13: '取消',
    a14: '確認',
    a15: '請輸入內容….',
    a16: '條數據',
    a17: '發生日期',
    a18: '車輛類型',
    a19: '姓名',
    a20: '車牌號碼',
    a21: '體溫',
    a22: '設備組名稱',
    a23: '設備名稱',
    a24: '訊號/終端名稱',
    a25: '建築物名稱',
    a26: '注釋',
    a27: '操作',
    a28: '請輸入姓名',
    a29: '請輸入車牌號碼',
    a30: '地點',
    a31: '口罩',
    a32: '車輛照片讀取失敗，請稍後再試。',
    a33: '管理代碼',
    a34: '車牌識別記錄',
    a35: '車輛動作',
    a36: '點擊放大圖片',
    a37: '點擊隱藏圖片',
  },

  //控制器管理頁
  controller: {
    a1: '控制器管理',
    a2: '建築物信息',
    a3: '查詢',
    a4: '終端資訊',
    a5: '設備資訊記錄',
    a6: '控制器狀態管理',
    a7: '控制器狀態發送記錄',
    a8: '運行狀態',
    a9: '注釋',
    a10: '控制器運行狀態設置',
    a11: '設備類型：',
    a12: '未啟動',
    a13: '測試',
    a14: '運行',
    a15: '停止',
    a16: '更新成功',
    a17: '更新失敗',
    a18: '正常',
    a19: '異常',
    a20: '未知',
    a21: '確認',
    a22: '取消',
    a23: '獲取通信狀態',
    a24: '實時獲取控制器的通信狀態需要等等待一些时间，是否確認 ?',
    a25: '控制器',
    a26: 'IP地址',
    a27: '通信狀態',
    a28: '检查日',
    a29: '个人編號',
    a30: '站點記錄讀取管理',
    a31:'不讀取',
    a32:'讀取',
    a33:'保存',
    a34:'設置',
    a35:'取消',
    a36:'參數類型',
    a37:'讀取狀態',
    a38:'讀取頻率 (分鐘/次)',
    a39:'最後讀取時間',
    a40:'操作',
    a41:'讀取頻率輸入參數錯誤',
    a42:'請輸入整數;輸入值為-1時,讀取狀態將設置為不讀取.',
    a43:'LAST ID輸入參數錯誤',
    a44:'請輸入正整數;輸入值為0時,讀取編號為1的記錄.',
    a45:'確認要更新修改信息嗎?',
  },
  // 客户中心
  customerCenter: {
    a1: "加載中....",
    a2: "客戶中心",
    a3: "編輯",
    a4: "建築物列表",
    a5: "客戶信息設置",
    a6: "取消",
    a7: "確認",
    a8: "客戶名稱",
    a9: "請輸入客戶名稱",
    a10: "別名",
    a11: "請輸入別名",
    a12: "聯繫方式1",
    a13: "請輸入聯繫方式",
    a14: "聯繫方式2",
    a15: "聯繫人",
    a16: "請輸入聯繫人",
    a17: "地址",
    a18: "請輸入地址",
    a19: "功能站點",
    a20: "日曆設置",
    a21: "添加節日",
    a22: "站點信息",
    a23: "站點名稱",
    a24: "別名",
    a25: "管理代碼",
    a26: "聯繫人",
    a27: "建築代碼",
    a28: "GW名稱",
    a29: "樓層信息",
    a30: "修改",
    a31: "契約信息",
    a32: "無契約",
    a33: "準備中",
    a34: "服務中",
    a35: "建築物信息設置",
    a36: "建築物名稱",
    a37: "請輸入建築物名稱",
    a38: "描述",
    a39: "請輸入描述",
    a40: "站點信息設置",
    a41: "請輸入站點名稱",
    a42: "站點別名",
    a43: "節日設置",
    a44: "節日名稱",
    a45: "請輸入節日名稱",
    a46: "日期",
    a47: "日期指定",
    a48: "星期指定",
    a49: "重複",
    a50: "期限指定",
    a51: "節日類型",
    a52: "假日",
    a53: "特日1",
    a54: "特日2",
    a55: "特日3",
    a56: "特日4",
    a57: "日曆名稱更新",
    a58: "日曆名稱",
    a59: "請輸入日曆名稱",
    a60: "樓層信息更新",
    a61: "樓層·租戶名稱",
    a62: "樓層·租戶別名",
    a63: "租戶地址",
    a64: "租戶聯繫方式1",
    a65: "租戶聯繫方式2",
    a66: "契約信息更新",
    a67: "無契約",
    a68: "準備中",
    a69: "服務中",
    a70: "01月",
    a71: "02月",
    a72: "03月",
    a73: "04月",
    a74: "05月",
    a75: "06月",
    a76: "07月",
    a77: "08月",
    a78: "09月",
    a79: "10月",
    a80: "11月",
    a81: "12月",
    a82: "01日",
    a83: "02日",
    a84: "03日",
    a85: "04日",
    a86: "05日",
    a87: "06日",
    a88: "07日",
    a89: "08日",
    a90: "09日",
    a91: "10日",
    a92: "11日",
    a93: "12日",
    a94: "13日",
    a95: "14日",
    a96: "15日",
    a97: "16日",
    a98: "17日",
    a99: "18日",
    a100: "19日",
    a101: "20日",
    a102: "21日",
    a103: "22日",
    a104: "23日",
    a105: "24日",
    a106: "25日",
    a107: "26日",
    a108: "27日",
    a109: "28日",
    a110: "29日",
    a111: "30日",
    a112: "31日",
    a113: "第1周",
    a114: "第2周",
    a115: "第3周",
    a116: "第4周",
    a117: "第5周",
    a118: "星期一",
    a119: "星期二",
    a120: "星期三",
    a121: "星期四",
    a122: "星期五",
    a123: "星期六",
    a124: "星期日",
    a125: "每週",
    a126: "每月",
    a127: "操作",
    a128: "服務名稱",
    a129: "服務狀態",
    a130: "請輸入日曆名稱",
    a131: "節日刪除?",
    a132: "確定刪除該節日",
    a133: "請選擇不同狀態",
    a134: "請選擇不同狀態，再進行修改",
    a135: "輸入搜索文本",
    a136: "契約信息更新成功",
    a137: "契約信息更新失敗",
  },
  // 用户设置
  UserSettings: {
    a1: "加載中...",
    a2: "用戶設置",
    a3: "用戶類型",
    a4: "系統用戶",
    a5: "客戶用戶",
    a6: "客戶",
    a7: "請選擇用戶組",
    a8: "用戶組ID",
    a9: "用戶上限數",
    a10: "用戶數",
    a11: "編輯",
    a12: "具有權限的站點",
    a13: "設置站點",
    a14: "站點ID",
    a15: "所屬建築",
    a16: "用戶列表",
    a17: "新建用戶",
    a18: "用戶名稱",
    a19: "級",
    a20: "登錄賬戶",
    a21: "賬戶有效期",
    a22: "密碼有效天數",
    a23: "認證代碼",
    a24: "新建用戶組",
    a25: "取消",
    a26: "確認",
    a27: "用戶組名稱：",
    a28: "訪問許可用戶羣組選擇",
    a29: "選擇HBS管理者用戶組",
    a30: "選擇大廈業主用戶組",
    a31: "選擇複製原用戶組",
    a32: "站點設置",
    a33: "顯示",
    a34: "操作",
    a35: "設置",
    a36: "用戶信息更新",
    a37: "用戶組名稱",
    a38: "請輸入用戶組名稱",
    a39: "用戶上限數",
    a40: "請輸入用戶上限數",
    a41: "賬號ID",
    a42: "請輸入賬號Id",
    a43: "賬號",
    a44: "請輸入賬號",
    a45: "驗證碼",
    a46: "請輸入驗證碼",
    a47: "賬戶有效期",
    a48: "請輸入賬號有效期",
    a49: "郵箱地址",
    a50: "請輸入郵箱地址",
    a51: "重置密碼",
    a52: "密碼1",
    a53: "請輸入密碼1",
    a54: "密碼2",
    a55: "請輸入密碼2",
    a56: "請輸入用戶上限數",
    a57: "請輸入數字",
    a58: "請輸入大於當前用戶數小於20的數字",
    a59: "請輸入用戶組名稱",
    a60: "賬號ID不能爲空",
    a61: "賬號不能爲空",
    a62: "驗證碼不能爲空",
    a63: "密碼有效期不能爲空",
    a64: "密碼不能爲空",
    a65: "請輸入正確郵箱格式",
    a66: "郵箱不能爲空",
    a67: "大廈業主",
    a68: "租戶",
    a69: "大廈管理員",
    a70: "權限站點設置",
    a71: "站點ID",
    a72: "所屬建築",
    a73: "用戶組權限",
    a74: "菜單服務權限",
    a75: "服務：",
    a76: "權限外服務菜單",
    a77: "添加↓",
    a78: "權限內服務菜單",
    a79: "刪除↑",
    a80: "重置",
    a81: "確定",
    a82: "服務",
    a83: "菜單",
    a84: "顯示",
    a85: "更新成功",
    a86: "更新失敗",
    a87: "永久"
  },

  //通用的描述
  general: {
    a1: "加載中...",
    a2: "設置",
    a3: "添加",
    a4: "修改",
    a5: "删除",
    a6: "成功",
    a7: "失敗",
    a8: "確定",
    a9: "取消",
    a10: "編輯",
    a11: "更新",
    a12: "查詢",
    a13: '確定要刪除?',
    a14: '請選擇要刪除的項',
    a15: '不能為空',
    a16: '選項',
    a17: '值',
  },
  meeting: {
    m1: '會議管理',
    m2: '提前結束',
    m3: '延時結束',
    m4: '正常結束',
    m5: '稽核不通過',
    m6: '會議取消',
  },
  personnelIOStatistics: {
    a1: "人員統計",
    a2: "類型",
    a3: "所屬公司/單位",
    a4: "請輸入所屬公司/單位",
    a5: "國籍",
    a6: "請輸入國籍",
    a7: "姓名",
    a8: "請輸入姓名",
    a9: "開始日期",
    a10: "結束日期",
    a11: "分組",
    a12: "查詢",
    a13: "人員出入記錄",
    a14: "人員出入統計",
    a15: "下載",
    a16: "室内人員記錄",
    a17: "室内人員統計",
  },
    edge:{
      edge001:'智慧網關管理',
      edge002:'網關類型',
      edge003:'網關名稱',
      edge004:'所屬位置',
      edge005:'網關IP',
      edge006:'埠號',
      edge007:'網關帳戶',
      edge008:'網關密碼',
      edge009:'網關訪問URL',
      edge010:'狀態檢測URL',
      edge011:'警報週期',
      edge012:'狀態檢測模式',
      edge013:'監控模式',
      edge014:'說明',
      edge015:'備註',
      edge016:'建築代碼',
      edge017:'建築名稱',
      edge018:'通信狀態',
      edge019:'運行狀態',
      edge020:'連線時間',
      edge021:'讀取設備資訊',
      edge022:'軟體版本',
      edge023:'軟體更新日期',
      edge024:'操作',
      edge025:'新增智能網關',
      edge026:'編輯智能網關',
      edge027:'軟體升級',
      edge028:'上傳升級軟體檔案',
      edge029:'請輸入網關名稱',
      edge030:'請輸入所屬位置',
      edge031:'請輸入網關IP',
      edge032:'請輸入埠號',
      edge033:'請輸入網關帳戶',
      edge034:'請輸入網關密碼',
      edge035:'請輸入網關訪問URL',
      edge036:'請輸入狀態檢測URL',
      edge037:'請輸入警報週期',
      edge038:'請輸入狀態檢測模式',
      edge039:'請輸入監控模式',
      edge040:'請輸入說明',
      edge041:'請輸入備註',
      edge042:'智能網關設置',
      edge043:'網關名稱長度必須在1到30之間',
      edge044:'所屬位置長度必須在1到30之間',
      edge045:'網關IP長度必須在1到30之間',
      edge046:'網關帳戶長度必須在6到30之間',
      edge047:'網關密碼長度必須在6到20之間',
      edge048:'網關訪問URL長度必須在1到200之間',
      edge049:'狀態檢測URL長度必須在1到200之間',
      edge050:'說明長度必須在1到800之間',
      edge051:'備註長度必須在1到200之間',
      edge052:'確認要保存所設置的智慧網關資訊嗎？',
      edge053:'確認要刪除所選擇的智慧網關資訊嗎？',
      edge054:'升級',
      edge055:'檔案類型',
      edge056:'選擇檔案',
      edge057:'上傳中',
      edge058:'上傳',
      edge059:'上傳成功',
      edge060:'上傳失敗',
      edge061:'待升級軟體網關/控制器清單',
      edge062:'文件列表',
      edge063:'未啟動',
      edge064:'試驗/維護',
      edge065:'運轉',
      edge066:'作業告警',
      edge067:'集中告警',
      edge068:'停止',
      edge069:'刪除告警',
      edge070:'請輸入建築代碼',
      edge071:'請選擇網關類型',
      edge072:'配置',
      edge073:'網關編號配置',
      edge074:'原GWC編號',
      edge075:'原FLC/FMC編號',
      edge076:'新GWC編號',
      edge077:'新FLC/FMC編號',
      edge078:'動作命令',
      edge079:'命令類型',
      edge080:'選擇網關/控制器',
      edge081:'選擇動作命令',
      edge082:'選擇檔案',
      edge083:'確認',
      edge084:'升級',
      edge085:'文件發送',
      edge086:'檔案發送並升級',
      edge087:'檔案刪除',
      edge088:'請選擇命令類型；或者請點擊確定按鈕進行對應命令操作。',
      edge089:'請點擊確定按鈕進行升級程式。',
      edge090:'請選擇檔案',
      edge091:'準備升級程式，請選擇程式檔案',
      edge092:'請輸入新GWC編號',
      edge093:'請輸入新FLC/FMC編號',
      edge094:'新GWC編號長度必須為8位。',
      edge095:'FLC編號範圍必須在101-140之間。',
      edge096:'FMC編號範圍必須在17-19之間。',
      edge097:'檔案管理',
      },
      linkage:{
        chooseSignal:"請選擇信號點",
        hour:"小時",
        compare:"比較",
        critical:"臨界值",
        greater:"大於",
        less:"小於",
        equal:"等於",
        index:"條件序號",
        condition:"條件",
        relation:"條件間關係",
        output:"輸出",
        addRuleAndInput:"添加規則輸入條件專案",
        addruleCondition:"添加規則條件項",
        addCondition:"添加條件",
        and:"與",
        or:"或者",
      },
    peopleDevice:{
        a1:"設備通信失敗信息",
        a2:"繼續下發數據",
        a3:"我知道了",
        a4:"繼續下發可能會下發失敗，確定繼續下發數據嗎？",
    },
    faceErrorMessage:{
        a1:"人臉下發失敗",
        a2:"我知道了",
    },

}

