export default [
  {
    path: '/homepage/visitor', //访客模块--门禁主页
    component: () => import('@/views/visitor/visitorLayout'),
    meta: { hidden: true },
    children: [
      //设备 1.1
      {
        path: '/homepage/visitor/equipment', //访客模块--设备页
        name: 'VST_EQUIPMENT',
        component: () => import('@/views/door/equipment'),
        meta: { hidden: true }
      },
      //警报 2.1
      {
        path: '/homepage/visitor/alarm', //访客模块--报警页
        name: 'VST_ALARM',
        component: () => import('@/views/door/alarm'),
        meta: { hidden: true }
      },
      //记录 3.1
      {
        path: '/homepage/visitor/orderRecords', //访客模块--访客预约记录
        name: 'VST_WORK_ORDER_RECORD',
        // component: () => import('@/views/visitor/visitorPeopleRecord'),//test
        component: () => import('@/views/visitor/visitorBookingRecord'),
        meta: { hidden: true }
      },      
      //3.2
      { 
        path: '/homepage/visitor/orderPeople', //访客模块--访客人员记录
        name: 'VST_WORK_ORDER_PEOPLE',
        component: () => import('@/views/visitor/visitorPeopleRecord'),
        meta: { hidden: true }
      },
      //3.3
      { 
        path: '/homepage/visitor/carThroughRecords', //访客模块--访客车辆记录
        name: 'VST_RIREKI_CAR',
        component: () => import('@/views/visitor/carThroughRecords'),
        meta: { hidden: true }
      },
      //3.4
      {
        path: '/homepage/visitor/throughRecords', //访客模块--通行记录
        name: 'VST_RIREKI',
        component: () => import('@/views/visitor/visitorThroughRecords'),
        meta: { hidden: true }
      },

      //状况显示 4.1
      {
        path: '/homepage/visitor/strandedPersonnel', //访客模块--滞留人员显示
        name: 'VST_ZANRYU',
        component: () => import('@/views/visitor/relationship'),
        meta: { hidden: true }
      },
      //4.2
      {
        path: '/homepage/visitor/indoorPersonne', //访客模块--室内人员显示
        name: 'VST_ZAISITU',
        component: () => import('@/views/visitor/relationship'),
        meta: { hidden: true }
      },

      //数据 5.1
      {
        path: '/homepage/visitor/dataOutput', //访客模块--数据输出
        name: 'VST_DATAOUTPUT',
        component: () => import('@/views/visitor/visitorFutureRecord'),
        meta: { hidden: true }
      },
      //5.2
      {
        path: '/homepage/visitor/reportData', //访客模块--统计报表
        name: 'VST_REPORT',
        component: () => import('@/views/visitor/visitorStatisticalReport'),
        meta: { hidden: true }
      },

      //设置 6.1
      {
        path: '/homepage/visitor/facialInfo', //访客模块--人脸终端信息
        name: 'VST_FACE_NINSHOU',
        component: () => import('@/views/door/facialInfo'),
        meta: { hidden: true }
      },
      //6.2
      {
        path: '/homepage/visitor/parkingLotSet', //访客模块--车场设置
        name: 'VST_CAR_PARK',
        component: () => import('@/views/door/parkingLotSet'),
        meta: { hidden: true }
      },
      //6.3
      {
          path: '/homepage/visitor/plateNumber', //访客模块--车牌管理
          name: 'VST_CAR_PLATENUMBER',
          component: () => import('@/views/door/plateNumber'),
          meta: { hidden: true }
      },
      //6.4
      {
        path: '/homepage/visitor/doorTimetable', //访客模块--门禁时间表
        name: 'VST_NYUTAI_SCE',
        component: () => import('@/views/door/doorTimetable'),
        meta: { hidden: true }
      },
      //6.5
      {
        path: '/homepage/visitor/trafficPatterns', //访客模块--通行模式
        name: 'VST_TUKO_PATTERN',
        component: () => import('@/views/door/trafficPatterns'),
        meta: { hidden: true }
      },
      //6.6
      {
        path: '/homepage/visitor/cardReaderAlarm', //访客模块--读卡器报警
        name: 'VST_CR_ALERM',
        component: () => import('@/views/door/cardReaderAlarm'),
        meta: { hidden: true }
      },
      //6.7
     
      {
        path: '/homepage/visitor/area', //访客模块--滞留人员显示
        name: 'VST_AREA',
        component: () => import('@/views/visitor/relationship'),
        meta: { hidden: true }
      },
      //6.8
      {
        path: '/homepage/visitor/stayArea', //访客模块--室内人员显示
        name: 'VST_AREA_STAY',
        component: () => import('@/views/visitor/relationship'),
        meta: { hidden: true }
      },

      //6.9
      {
        path: '/homepage/visitor/upperAndLowerSetting', //访客模块--人员上下级设置
        name: 'VST_LEADER_MEMBER',
        component: () => import('@/views/visitor/upperAndLowerSetting'),
        meta: { hidden: true }
      },
      
      //6.10
      {
        path: '/homepage/visitor/info', //访客模块--访客信息管理
        name: 'VST_VISTOR_INFO',
        component: () => import('@/views/visitor/visitorRecord'),
        meta: { hidden: true }
      },
      
      //6.11
      {
        path: '/homepage/visitor/black', //访客模块--访客黑名单管理
        name: 'VST_BLACK_LIST',
        component: () => import('@/views/visitor/visitorBlacklist'),
        meta: { hidden: true }
      },
      //6.12
      {
        path: '/homepage/visitor/rule', //访客模块--访客规则设置
        name: 'VST_ACCESS_RULE',
        component: () => import('@/views/visitor/visitorRuleSetting'),
        meta: { hidden: true }
      },
      
    ]
  }
]