// 门禁模块--设置--通行模块设置--通行权限人员信息组件 lht 2023-3-31
module.exports = {
    accessStaffInfo:{
        a1:'通行許可權人員資訊',
        a2:'取消',
        a3:'加載中….',
        a4:'人員信息清單',
        a5:'當前頁匯出',
        a6:'全部匯出',
        a7:'人員資訊匯出',
        a8:'顯示內容',
        a9:'管理程式碼',
        a10:'姓名',
        a11:'部門',
        a12:'卡編號',
        a13:'國籍',
        a14:'注册日期',
        a15:'關聯建築',
        a16:'所屬1',
        a17:'所屬2',
        a18:'所屬3',
        a19:'所屬4',
        a20:'所屬5'
    }
}