// 英文表
module.exports = {
  login: {
    Q1: 'Login',
    Q2: 'User Name',
    Q3: 'Password',
    Q4: 'Captcha',
    Q5: 'Language',
    Q6: 'Login',
    Q7: 'Please enter your password.',
    Q8: 'Please enter a user name',
    Q18:"Login Failed",
    Q9:"Re-enter",
    Q10:"Login Failure",
    Q11:"Login Again",
    Q12:"Wrong account or password",
    Q13:"The account has been logged in elsewhere",
    Q14:"The account is locked, please try again later",
    Q15:"System error",
    Q16:"Password expiration",
    Q17:"Your login status has expired. Please login again",
    Q19: 'Forgot Password?',
    Q20: 'Reset Password',
    Q21: 'Authentication Code',
    Q22: 'Please enter the authentication code!',
    Q23: 'Email',
    Q24: 'Please enter your email address!',
    Q25: 'The email address entered is invalid!',
    Q26: 'Send',
    Q27: 'Return to login',
    Q28: 'Please enter your ID, authentication code, and email address, and click the send button.',
    Q29: 'New password 1 and password 2 will be sent to the pre registered email address.',
    Q30: 'Please enter verification code',
    ERR0: 'Password reset successful, please check your email.',
    ERR1: 'The username, authentication code, or email address is incorrect.',
    ERR2: 'User logged in.',
    ERR3: 'Email sending failed.',
    ERR4: 'Password reset count exceeded.',
    ERR5: 'Password reset operation is invalid.',
    ERR6: 'Password reset failed.',
  },
  // 侧边栏 a:头像/b:站点/c:认证信息/d:设置管理/e:通知/f:帮助/g:退出
  sidebar: {
    a1: 'My',
    a2: 'My IBMS',
    b1: 'Site',
    c1: 'Personnel management',
    c2: 'Card information',
    c3: 'Personnel information',
    d1: 'Set up management',
    e1: 'Notice',
    e2: 'Warning',
    f1: 'Help',
    f2: 'CSV Export',
    f3: 'Print',
    f4: 'Help',
    f5: 'Website stated',
    g1: 'Log out',
    g2: 'Are you sure to log out',
    g3: 'Login must be logged in again after logging out to restart the operation',
    a3: 'User name：',
    a4: 'Login account：',
    a5: 'Email：',
    a6: 'Change password ',
    a7: 'password 1',
    a8: 'password 2',
    a9: 'Please enter your password ',
    a10: 'close ',
    a11: 'Sure ',
    a12: 'New password 1',
    a13: 'New password 2',
    a14: 'Please log in again',
    a15: 'Energy efficiency Kanban',
    a16: 'More',
    a17: 'Edit',
    a18: 'Mobile phone:',
    a19: 'Whether to open WeChat',
    a20: 'Please enter your mobile number',
    a21: 'Please enter the WeChat user name',
    a22: 'WeChat user name:',
    a23: 'The passwords entered twice do not match!',
    a24: 'The password format is incorrect. The password must include three combinations of special characters, numbers and letters; And the password length is between 8 and 16. ',
    a25: 'Password 1 and password 2 cannot be the same, please re-enter!',
    a26: 'User settings',
    a27: 'Confirm new password 1',
    a28: 'Confirm new password 2',
    a29: 'WeChat settings',
    a30: 'Reset password',
    a31: 'Enter login account',
    a32: 'Enter email'
  },
  // 门禁模块--报警页
  alarm: {
    a0: 'Loading....',
    a1: 'Alarm ',
    a2: 'Device Name ',
    a3: 'Floor/Tenant Selection ',
    a4: 'Start Date ',
    a5: 'End Date ',
    a6: 'Query ',
    a7: 'Alert List ',
    a8: 'Piece of Data ',
    a9: 'Alarm Confirmation ',
    a10: 'Manual Restore ',
    a11: 'Coping ',
    a12: 'Sequence Recovery ',
    a13: 'Alarm output ',
    a14: 'Add comment ',
    a15: 'Select operation ',
    a16: 'Add comment ',
    a17: 'Please enter the content......',
    a18: 'Please select date or time ',
    a19: 'Loading....',
    a20: 'Occurrence Date ',
    a21: 'Recovery Date ',
    a22: 'Posting type ',
    a23: 'Alarm type ',
    a24: 'Building Name   ',
    a25: 'Device Name ',
    a26: 'Video ',
    a27: 'Signal/terminal name ',
    a28: 'Alarm content ',
    a29: 'Coping state ',
    a30: 'Name ',
    a31: 'ID card',
    a32: 'Body temperature ',
    a33: 'Comments ',
    a34: 'Operation ',
    a35: 'Not confirmed ',
    a36: 'Not restored ',
    a37: 'Unprocessed ',
    a38: 'ALL ',
    a39: 'Please enter device name ',
    a40: 'Cancel ',
    a41: 'Confirm ',
    a42: 'Handle state change ',
    a43: 'Current response status: ',
    a44: 'after change: ',
    a45: 'in process ',
    a46: 'After treatment ',
    a47: 'Are you sure you want to confirm the registration of the selected alert?',
    a48: 'Are you sure you want a manual fix?',
    a49: 'Alarm status',
    a50: 'According to the content',
    a51: 'CSV export',
    A52: 'KPI type ',
    A53: 'Current value',
    A54: 'Limit value',
    A55: 'Dead band',
    A56: 'Proportion (%)',
    a57: 'Alarm level',
    a58:'Please select site information',
  },
  // 门禁模块--卡片记录（通行记录）
  throughrecords: {
    a0: 'Loading....',
    a1: 'Card History ',
    a2: 'Name ',
    a3: 'Card Number ',
    a4: 'Start Date ',
    a5: 'End Date ',
    a6: 'Query ',
    a7: 'Record List ',
    a8: 'Please select date or time ',
    a9: 'Face Authentication Picture ',
    a10: 'Add Comment ',
    a11: 'Select Operation ',
    a12: 'Comments ',
    a13: 'Cancel ',
    a14: 'Confirm ',
    a15: 'Please enter the content....',
    a16: 'Piece of Data ',
    a17: 'Occurrence Date  ',
    a18: 'Authentication Type ',
    a19: 'Name ',
    a20: 'Card Number ',
    a21: 'Body Temperature ',
    a22: 'Device Group Name ',
    a23: 'Device Name ',
    a24: 'Signal/Terminal Name ',
    a25: 'Building Name ',
    a26: 'Comments ',
    a27: 'Operation ',
    a28: 'Please enter name ',
    a29: 'Please enter card number ',
    a30: 'Location ',
    a31: 'Mask ',
    a32: 'Face photo reading failed, please try again later.',
    a33: 'Management Code ',
    a34: 'Face Authentication Record ',
    a35: 'Visitor Access Record',
    a36: 'Please enter the management code',
    a37: 'Please input the name of the signal/terminal',
    a38: 'Please enter the device group name',
    a39: 'Please enter the device name',
  },
  // 门禁模块--考勤记录
  attendance: {
    a1: 'Loading.... ',
    a2: 'Attendance History ',
    a3: 'Personnel Ownership ',
    a4: 'Employee Number ',
    a5: 'Name of Officer ',
    a6: 'Start Date ',
    a7: 'End Date ',
    a8: 'Query ',
    a9: 'Attendance Record List ',
    a10: 'Please select date or time ',
    a11: 'piece of data ',
    a12: 'Employee Number ',
    a13: 'name of employee ',
    a14: 'Attendance Date ',
    a15: 'Attendance Start Time ',
    a16: 'End of attendance time ',
    a17: 'Please enter the name of the person ',
    a18: 'Please enter employee number ',
    a19: 'Please enter name of person ',
  },
  // 门禁模块--滞留人员显示
  strandedPersonnel: {
    a1: 'Loading.... ',
    a2: 'Over Stay Display ',
    a3: 'region selection ',
    a4: 'Query ',
    a5: 'Detainer List ',
    a6: 'Delete ',
    a7: 'timeout setting ',
    a8: 'Settings ',
    a9: 'Close ',
    a10: 'out of time ',
    a11: 'HH mm ',
    a12: 'piece of data ',
    a13: 'region name ',
    a14: 'Name ',
    a15: 'entry time ',
    a16: 'card number ',
    a17: 'Retention time ',
    a18: 'Department ',
    a19: 'belong to 1',
    a20: 'belong to 2',
    a21: 'belong to 3',
    a22: 'belong to 4',
    a23: 'belong to 5',
    a24: 'Operation ',
    a25: 'Make sure to delete ',
    a26: 'Information ',
    a27: 'Please select the time',
  },
  // 门禁模块--室内人员显示
  indoorPersonne: {
    a1: 'Loading.... ',
    a2: 'Stay Display ',
    a3: 'Personnel information',
    a4: 'query',
    a5: 'List of persons in the room ',
    a6: 'Reset ',
    a7: 'Indoor Personnel Settings ',
    a8: 'Settings ',
    a9: 'Close ',
    a10: 'card number ',
    a11: 'Please enter the card number ',
    a12: 'manage code ',
    a13: 'Please enter the admin code ',
    a14: 'Query ',
    a15: 'List of all individuals ',
    a16: 'add ',
    a17: 'List of Registrants ',
    a18: 'Delete ',
    a19: 'piece of data ',
    a20: 'region name ',
    a21: 'Name ',
    a22: 'Department ',
    a23: 'in/out ',
    a24: 'Entry/Exit Time ',
    a25: 'belong to 1',
    a26: 'group name ',
    a27: 'card number ',
    a28: 'belong to 2',
    a29: 'belongs to 3',
    a30: 'belong to 4',
    a31: 'belong to 5',
    a32: 'Action ',
    a33: 'card number ',
    a34: 'manage code ',
    a35: 'Name ',
    a36: 'belong to 1',
    a37: 'Operation ',
    a38: 'Make sure to update ',
    a39: 'Information ',
    a40: 'Please select region ',
  },
  // 门禁模块--门禁时间表
  doortimetable: {
    a1: 'Loading.... ',
    a2: 'Access Control Schedule Settings ',
    a3: 'Schedule name: ',
    a4: 'Cancel ',
    a5: 'Confirm ',
    a6: 'card mode ',
    a7: 'Free mode ',
    a8: 'Repeat mode ',
    a9: 'Enter card/exit free mode ',
    a10: 'Enter Free/Exit card mode',
    a11: 'Card Certification ',
    a12: 'card authentication + digital key authentication ',
    a13: 'card certification + finger vein certification ',
    a14: 'impassable ',
    a15: 'Passable ',
    a16: 'not alert ',
    a17: 'On alert ',
    a18: 'The alert period is invalid ',
    a19: 'copy between schedules ',
    a20: 'schedule copy ',
    a21: 'Schedule full table copy ',
    a22: 'Settings ',
    a23: 'Cancel ',
    a24: 'Copy the original schedule ',
    a25: 'Please select schedule ',
    a26: 'schedule copy ',
    a27: 'copy source ',
    a28: 'Copy to ',
    a29: 'Monday ',
    a30: 'Tuesday ',
    a31: 'Wednesday ',
    a32: 'Thursday ',
    a33: 'Friday ',
    a34: 'Saturday ',
    a35: 'Sunday ',
    a36: 'holiday ',
    a37: 'Special Day 1',
    a38: 'Special Day 2',
    a39: 'Terriday 3',
    a40: 'Special Day 4',
    a41: 'week not specified correctly, please specify again ',
    a42: 'No schedule selected, please select schedule ',
    a43: 'No select week, please select week ',
    a44: 'Please set the calendar first',
    a45: 'Please enter a schedule name',
    a46: 'Schedule name update',
    b1: 'Schedule Change ',
    b2: 'Schedule type: ',
    b3: 'Schedule name: ',
    b4: 'Item: ',
    b5: 'Since the initial value is "card mode", please set other modes.',
    b6: 'Mode 1',
    b7: 'Mode 2',
    b8: 'Mode 3',
    b9: 'Mode 4',
    b10: 'Mode 5',
    b11: 'Reset ',
    b12: 'Door Schedule ',
    b13: 'Card Certification Schedule ',
    b14: 'Traffic Schedule ',
    b15: 'Alert Schedule ',
    b16: 'Are you sure you want to register your content?',
    b17: " The time is not specified correctly, please specify it again ",
    b18: 'H',
    b19: 'M'
  },
  // 门禁模块--车牌管理
  plateNumber: {
    a1: "Loading ",
    a2: "License Plate Management ",
    a3: "Manage Code ",
    a4: "Please enter the admin code ",
    a5: "Person name ",
    a6: "Please enter name ",
    a7: "Mobile phone ",
    a8: "Please enter mobile phone number ",
    a9: "License Plate ",
    a10: "Please enter license plate number ",
    a11: "License Plate Color ",
    a12: "None",
    a13: "The white card ",
    a14: "Black Card ",
    a15: "Blue Plate ",
    a16: "Yellow Card ",
    a17: "The green card ",
    a18: "Authentication Mode ",
    a19: "None",
    a20: "White List ",
    a21: "The Blacklist ",
    a22: "Temporary car ",
    a23: "VIP car",
    a24: "Monthly Car Rent ",
    a25: "Time ",
    a26: "None",
    a27: "Start Date ",
    a28: "Expiration Date ",
    a29: "query ",
    a30: "License Plate List ",
    a31: "Delete ",
    a32: "Personnel License Plate Management ",
    a33: "License Plate Management ",
    a34: "Select Operation ",
    a35: "Batch License Plate Management ",
    a36: "Add license plate ",
    a37: "Personnel License Plate Management ",
    a38: "Settings ",
    a39: "CLOSE ",
    a40: "Basic Information ",
    a41: "Manage code ",
    a42: "Name ",
    a43: "Department ",
    a44: "Mobile phone ",
    a45: "E-mail ",
    a46: "Site of Ownership ",
    a47: "belong to group ",
    a48: "License Plate Information ",
    a49: "Add license plate ",
    a50: "License Plate Ownership ",
    a51: "Car Color ",
    a52: "Car Brand ",
    a53: "Certification Mode ",
    a54: "Start Date ",
    a55: "Expiration Date ",
    a56: "Please enter mobile phone number ",
    a57: "Please enter license plate number ",
    a58: "Please select a time ",
    a59: "piece of data ",
    a60: "Operation ",
    a61: "Start Date ",
    a62: "Please select a start date ",
    a63: "Confirm ",
    a64: "End Date ",
    a65: "Please select the end date ",
    a66: "Each person can add up to 5 plates ",
    a67: "mobile phone number format error ",
    a68: "car plate number format error ",
    a69: "please select authentication mode",
    a71:"Allow List ",
    a72:"Reject List ",
    a73:"Temporary ",
    a74:"VIP Car   ",
    a75:"Rent Car",
    a76:"Exemption from inspection Card",
    a77:"Non inspection exemption Card",
    a78:"Temporary",
    a79:"Rent Card",
    a80:"Monthly Rent Card",
    a81:"MVP Card",
    a82:"VIP Card",
    a83:"Pre-pay Card",
    a84:"Free Card",
    a85:"Visitor Card ",
    a86: "Are you sure you delete the plate? ",
    a87: "Please enter the information of the person to whom the license plate belongs.",
    a88: "Please select a license plate color",
    a89: "Download template file",
    a90: "Upload license plate information",
    a91: "Export license plate information",
    a92: "Access area",
    a93: "Gender",
    a94: "Female",
    a95: "Male",
    a96: "Department",
    a97: "Please select an organizational department",
    a98: "Third party system",
    a99: "Synchronizing Data",
    a100: "Please select the passage area",
    a101: "Import File Template",
    a102: "Personnel Type",
    a103: "System personnel",
    a104: "Ordinary personnel",
    a105: "Please select the site",
    a106: "Please select personnel information",
    a107: "The email entered is invalid!",
    a108: "Are you sure you want to save the set license plate information?",
    a109: "Select Import File",
    a110: "License plate information import failed.",
    a111: "Upload license plate information in batches",
  },
  //The input is not valid E-mail!
  // 门禁模块--室内人员区域
  indoorArea: {
    a1: 'Loading.... ',
    a2: 'Stay Display Area Settings ',
    a3: 'region selection ',
    a4: 'Query ',
    a5: 'Registration Door ',
    a6: 'Add region/group ',
    a7: 'delete region/group ',
    a8: 'region edit ',
    a9: 'change name ',
    a10: 'Settings ',
    a11: 'CLOSE ',
    a12: 'Add group/region selection ',
    a13: 'Group ',
    a14: 'region ',
    a15: 'group/region name ',
    a16: 'Name of indoor personnel Area',
    a17: 'Indoor area setting',
    a18: 'region name: ',
    a19: 'All Doors ',
    a20: 'add ',
    a21: 'Registered Door ',
    a22: 'Delete ',
    a23: 'Please enter the region name ',
    a24: 'piece of data ',
    a25: 'device name ',
    a26: 'Authentication terminal name ',
    a27: 'Floor · Tenant Name ',
    a28: 'Operation ',
    a29: 'Are you sure you want to add groups/regions?',
    a30: 'Please enter group/region name ',
    a31: 'Make sure to delete ',
    a32: '?',
    a33: 'Are you sure you want to register your content?',
  },
  // 门禁模块--滞留人员区域
  detentionArea: {
    a1: 'Loading.... ',
    a2: 'Over Stay Display Area Settings ',
    a3: 'region selection ',
    a4: 'Query ',
    a5: 'Registration Door ',
    a6: 'Add region/group ',
    a7: 'delete region/group ',
    a8: 'region edit ',
    a9: 'change name ',
    a10: 'Settings ',
    a11: 'CLOSE ',
    a12: 'Add group/region selection ',
    a13: 'Group ',
    a14: 'region ',
    a15: 'group/region name ',
    a16: 'Name of detainee area ',
    a17: 'Retain locale ',
    a18: 'region name: ',
    a19: 'All Doors ',
    a20: 'add ',
    a21: 'Registered Door ',
    a22: 'Delete ',
    a23: 'Please enter the region name ',
    a24: 'piece of data ',
    a25: 'device name ',
    a26: 'Authentication terminal name ',
    a27: 'Floor · Tenant Name ',
    a28: 'Operation ',
    a29: 'Are you sure you want to add groups/regions?',
    a30: 'Please enter group/region name ',
    a31: 'Make sure to delete ',
    a32: '?',
    a33: 'Are you sure you want to register your content?',
  },
  // 门禁模块--车场设置
  parkingLotSet: {
    a1: 'Loading ',
    a2: 'Car Park Settings ',
    a3: 'Depot Personnel Structure Structure Information ',
    a4: 'Yard Configuration Information ',
    a5: 'Synchronous Organizational Structure ',
    a6: 'Delete Synchronous Organizational Schema ',
    a7: 'No data at present ',
    a8: 'No organizational structure to be updated selected ',
    a9: 'Please select the organizational structure to be updated ',
    a10: 'OK ',
    a11: 'Are you sure you want to update the selected organizational schema and its subschema information to a third party system?',
  },
  // 门禁模块--人脸认证信息设置
  facialInfo: {
    a1: 'Face Authentication Information Settings',
    a2: 'device status ',
    a3: 'device name ',
    a4: 'Query ',
    a5: 'Face Device List ',
    a6: 'Identification Records Management ',
    a7: 'edit ',
    a8: 'select operation ',
    a9: 'Device Management ',
    a10: 'Device serial number ',
    a11: 'Associated Card Reader ',
    a12: 'device type ',
    a13: 'device IP',
    a14: 'device password ',
    a15: 'Heartbeat callback URL',
    a16: 'Authentication callback URL',
    a17: 'device callback URL',
    a18: 'Equipment Remarks ',
    a19: 'piece of data ',
    a20: 'Remarks ',
    a21: 'Operation ',
    a22: 'Yupan ',
    a23: 'Chi Chi ',
    a24: 'Haikang ',
    a25: 'Are you sure you want to set the information you want to edit?',
    a26: 'All ',
    a27: 'Normal ',
    a28: 'Fault ',
    a29: 'Loading....',
    a30: 'Please enter the device name',
    a31: 'End brand',
    a32: 'Upload background image',
    a33: 'Close',
    a34: 'Face terminal',
    a35: 'Settings',
    a36: 'Image port',
    a37: 'Background image',
    a38: 'Delete all',
    a39: 'Delete',
    a40: 'Select image',
    a41: 'Upload image',
    a42: 'Equipment site diagram',
    a43: 'Read image',
    a44: 'Read all images',
    a45: 'Face terminal standby background image settings',
    a46: 'Only images in png and jpg formats can be uploaded!',
    a47: 'The image size cannot exceed 300KB!',
    a48: 'Are you sure you want to save the set information?',
    a49: 'Are you sure you want to upload the selected image?',
    a50: 'Are you sure you want to delete this image?',
    a51: 'Are you sure you want to delete all images?'
  },
  // 门禁模块--读卡器报警
  cardreaderalarm: {
    a1: 'Loading.... ',
    a2: 'Card Reader Alarm Settings ',
    a3: 'Authentication Terminal ',
    a4: 'Please enter authentication terminal ',
    a5: 'Query ',
    a6: 'Authentication Terminal List ',
    a7: 'Warning Settings ',
    a8: 'Alarm Settings ',
    a9: ' Open timeout alert :',
    a10: 'no',
    a11: 'a',
    a12: ' (alarm time :',
    a13: 'seconds',
    a14: 'stop when alarm resumes)',
    a15: ' Door fault alarm :',
    a16: ' Unusual Door Alert :',
    a17: ' Force-entry Alert :',
    a18: ' No loop alarm :',
    a19: 'piece of data ',
    a20: 'Authentication Terminal ',
    a21: 'open over chirp ',
    a22: 'Door fault buzzing ',
    a23: 'abnormal open chirp ',
    a24: 'Intrusion chirp ',
    a25: 'no cyclic chirping ',
    a26: 'Operation ',
    a27: 'Are you sure you want to register your content?',
  },
  // 门禁模块--实际数据
  realdata: {
    a1: 'Loading.... ',
    a2: 'report data ',
    a3: 'All ',
    a4: 'door',
    a5: 'card',
    a6: 'Authentication operation history',
    a7: 'Monthly Bulletin ',
    a8: 'Daily ',
    a9: 'Output period: ',
    a10: 'file split score: ',
    a11: 'output days: ',
    a12: '1 generates a file without splitting ',
    a13: '2 Generate files every 15 days (including the end date of the last file) ',
    a14: '3 Generate files every 20 days (including the end date of last file) ',
    a15: '4 Generate files every 7 days (including the end date of the last file) ',
    a16: '5 divide every 6 days (including the end date of the last file) generate file ',
    a17: 'If the output is large, the number of divisions will naturally change to 5',
    a18: 'Download file name: ',
    a19: 'Output...',
    a20: 'output ',
    a21: 'File Splitting Instructions ',
    a22: 'Name ',
    a23: 'Please enter name ',
    a24: 'card number ',
    a25: 'Please enter card number ',
    a26: 'ID card',
    a27: 'Please enter card ID',
    a28: 'manage code ',
    a29: 'Please enter the admin code ',
    a30: 'Query ',
    a31: 'Authentication Information List ',
    a32: 'bar of data ',
    a33: 'Manage ownership ',
    a34: 'No date selected ',
    a35: 'Please select a date ',
    a36: 'device group name ',
    a37: 'Please enter device group name ',
    a38: 'device name ',
    a39: 'Please enter device name ',
    a40: 'Door List ',
  },
  // 门禁模块--通行模式
  trafficpatterns: {
    a1: 'Loading.... ',
    a2: 'Pass Mode Settings ',
    a3: 'pass mode name：',
    a4: 'Pass mode number: ',
    a5: 'Registered authentication terminal ',
    a6: 'Please select pass mode ',
    a7: 'pass mode copy ',
    a8: 'Authenticated Terminal Edit ',
    a9: 'Copy the original mode ',
    a10: 'Traffic mode not selected ',
    a11: 'Please select the traffic mode you want to copy ',
    a12: 'Authentication terminal name ',
    a13: 'Query ',
    a14: 'All authentication terminals ',
    a15: 'add ',
    a16: 'Delete ',
    a17: 'Passing Schedule · Calendar Change ',
    a18: 'Settings ',
    a19: 'Schedule · Calendar Selection ',
    a20: 'Traffic Schedule: ',
    a21: 'Please select a passage schedule ',
    a22: 'Calendar: ',
    a23: 'Please select calendar ',
    a24: 'Alert/unlock switch operation Settings: ',
    a25: 'valid ',
    a26: 'invalid ',
    a27: 'piece of data ',
    a28: 'Business name ',
    a29: 'name of building ',
    a30: 'Floor · Tenant Name ',
    a31: 'device name ',
    a32: 'Authentication terminal name ',
    a33: 'Access Type ',
    a34: 'Alert Settings ',
    a35: 'no',
    a36: 'a',
    a37: 'Schedule name ',
    a38: 'Calendar name ',
    a39: 'Authentication terminal not selected ',
    a40: 'Please select the authentication terminal to be changed ',
    a41: 'Terminal with no schedule or calendar ',
    a42: 'Please set a schedule and calendar on a terminal that has not set a schedule or calendar ',
    a43: 'Access mode name update',
    a44: 'Please enter the access mode name',
    a45: 'Enter search text',
    a46: 'Access permission personnel information'
  },
  // 详情页
  details: {
    a1: 'Loading....',
    a2: 'device name ',
    a3: 'device ID',
    a4: 'device GUID',
    a5: 'left entrance ',
    a6: 'Name ',
    a7: 'customer name ',
    a8: 'Type ',
    a9: 'feature site ',
    a10: 'Floor · Tenant Name ',
    a11: 'Cancel ',
    a12: 'Confirm ',
    a13: 'Item ',
    a14: 'Status · Operations ',
    a15: 'time of state change ',
    a17: 'select ',
    a18: 'Schedule Settings ',
    a19: 'Equipment details ',
    a20: 'Schedule ',
    a21: 'Calendar ',
    a22: 'Door Opening Time ',
    a23: 'Door Opening Overtime Detection Time ',
    a24: 'With or without sound ',
    a25: 'There is a sound ',
    a26: 'No sound ',
    a27: 'Number of electric lock actions ',
    a28: 'Electrical lock action times exceed monitoring ',
    a29: 'Maximum number of electric lock operations ',
    a30: 'Are you sure you want to register your content?',
    a31: 'Door status ',
    a32: 'abnormal open ',
    a33: 'temporarily unlocked ',
    a34: 'open over ',
    a35: 'Invasion ',
    a36: 'Unlock up state ',
    a37: 'no circulation ',
    a38: 'pass status ',
    a39: 'running state ',
    a40: 'Accept prohibited status ',
    a41: 'Illegal Traffic Alert ',
    a42: 'Equipment Abnormal Alert ',
    a43: 'Entry door open ',
    a44: 'Exit door open ',
    a45: 'No Entry Direction ',
    a46: 'No passing in exit direction ',
    a47: 'Continuously through Settings ',
    a48: 'fire signal output ',
    a49: 'card NG signal output ',
    a50: 'specify target ',
    a51: 'Door Schedule ',
    a52: 'Card Certification Schedule ',
    a53: 'Mechanical Alert Schedule ',
    a54: 'Model 1',
    a55: 'Model 2',
    a56: 'Model 3',
    a57: 'Model 4',
    a58: 'Model 5',
    a59: 'The initial value is "Card Mode", if you need to change, please make a new setting ',
    a60: 'card mode ',
    a61: 'Free mode ',
    a62: 'Repeat mode ',
    a63: 'Enter card/exit free mode ',
    a64: 'Enter Free/Reject Mode ',
    a65: 'Equipment details ',
    a66: 'Schedule ',
    a67: 'Calendar ',
    a68: 'numeric key timeout time in seconds ',
    a69: 'Number key terminal with or without ',
    a70: 'Warning temperature lower limit (℃)',
    a71: 'Warning temperature upper limit (℃)',
    a72: 'Warning for abnormal temperature ',
    a73: 'no',
    a74: 'is',
    a75: 'Authentication Mode ',
    a76: 'operating state ',
    a77: 'Illegal card ',
    a78: 'Schedule Restore ',
    a79: 'Automatic cycle recovery ',
    a80: 'valid ',
    a81: 'invalid ',
    a82: 'Cycle recovery time 1',
    a83: 'Cycle recovery time 2',
    a84: 'Cycle recovery time 3',
    a85: 'Cycle recovery time 4',
    a86: 'Cycle recovery time 5',
    a87: 'device IP',
    a89: 'device subnet mask ',
    a90: 'device gateway ',
    a91: 'Operation ',
    a92: 'Network Information for Devices ',
    a93: 'Operation successful, the door button will return to the closed state after 5 seconds ',
    a94: 'Door operation failed ',
    a95: 'Day Schedule ',
    a96: 'On the Day ',
    a97: 'Next Day Schedule ',
    a98: 'The Next Day ',
    a99: 'Timetable manual restore ',
    a100: 'Settings',
    a101: 'Operating status/fault/alarm ',
    a102: 'H',
    a103: 'M'
  },
  // 设备模块--操作\控制记录
  operaterecords: {
    a1: 'Operation records',
    a2: 'Device name',
    a3: 'Start Date',
    a4: 'Termination date/time selection',
    a5: 'Inquire',
    a6: 'Record list',
    a7: 'Please choose date or time',
    a8: 'Alarm confirm',
    a9: 'Manual restore',
    a10: 'To deal with',
    a11: 'The sequence to restore',
    a12: 'Alarm output',
    a13: 'Add comments',
    a14: 'Select operation',
    a15: 'Please enter the contents......',
    a16: 'datas',
    b1: 'Date of occurrence',
    b2: 'Essential factor',
    b3: 'Service',
    b4: 'Status',
    b5: 'Equipment group name',
    b6: 'Device name',
    b7: 'Signal/terminal name',
    b8: 'Annotation',
    b9: 'Operation',
  },
  // 设备模块--数据输出
  dataoutput: {
    a1: 'Device',
    a2: 'Signal',
    a3: 'Start Date',
    a4: 'Termination date/time selection',
    a5: 'Inquire',
    a6: 'Data preview',
    a7: 'Download data',
    a8: 'Separate display by signal',
    a9: 'Consolidated display by device',
    a10: 'Generate the file',
    a11: 'File list',
    a12: 'The generated output file is displayed in this list for later download',
    a13: 'The generated output file is only saved for 3 days, after which the file will be deleted; If you need to download, please re-export the generation',
    a14: 'datas',
    b1: 'Equipment serial number',
    b2: 'Device name',
    b3: 'Signal point ID',
    b4: 'The signal name',
    b5: 'The signal number',
    b6: 'Measurement value',
    b7: 'The measuring unit',
    b8: 'Date',
    b9: 'Time',
  },
  // 设备模块--设备联动控制
  controlllinkage: {
    a1: 'Group name',
    a2: 'Serial number：',
    a3: 'Input point',
    a4: 'Output point',
    a5: 'Linkage point',
    a6: 'Linkage conditions',
    a7: 'Please select schedule',
    a8: 'Setting',
    a9: 'Name already exists',
    b1: 'Status',
    b2: 'Alarm',
    b3: 'datas',
    b4: 'Device name',
    b5: 'Signal name',
    b6: 'conditions',
    b7: 'Display (maintenance)',
    b8: 'Operation',
    b9: 'Effective',
    b10: 'Invalid',
    b11: 'Control',
    b12: 'Not control',
    b13: 'Linkage control',
    b14: 'Output device operation',
    b15: 'Power outage or waiting to be restored',
    b16: 'The output delay',
    b17: 'type',
    b18: 'open',
    b19: 'close',
    a10: 'Loading.... ',
    a11: 'Device Linkage Control ',
    a12: 'Name Update ',
    a13: 'Close ',
    a14: 'OK ',
    a15: 'Name: ',
    a16: 'Please enter a name',
    a17: 'Name cannot be empty'
  },
  // 设备模块--季节控制
  controllseason: {
    a1: 'Group name',
    a2: 'Serial number：',
    a3: 'Analog setup equipment',
    a4: 'Upper and lower alarm setting equipment',
    a5: 'Run mode setup device',
    a6: 'Please select schedule',
    a7: 'Season set',
    a8: 'Parameters Settings',
    b1: "Season set",
    b2: "Summer Mode",
    b3: "Month",
    b4: "Day",
    b5: "Winter Mode",
    b6: "Other Seasons Mode",
    b7: "Outside of summer and winter",
    b8: "Temporary change (running mode)",
    b9: "Start setting today",
    b10: "Confirm",
    b11: "Set",
    b12: "No temporary change",
    b13: "Summer Mode",
    b14: "Winter Mode",
    b15: "Other Seasons Mode",
    c1: "Loading...",
    c2: "Parameters, operation mode Settings",
    c3: "Simulation value",
    c4: "Analog Device List",
    c5: "Analog value setting",
    c6: "Upper and lower limit",
    c7: "List of upper and lower alarm",
    c8: "Upper and lower alarm Settings",
    c9: "List of running modes",
    c10: "Operation mode setting",
    c11: "Set",
    C12: "effective ",
    C13: "invalid ",
    C14: "Device Name ",
    C15: "Signal name ",
    C16: "set value ",
    C17: "valid/invalid ",
    C18: "Mid-season mode ",
    C19: "type",
    C20: "piece of data ",
    a9: 'Loading...',
    a10: 'Season switching control',
    a11: 'Name update',
    a12: 'Close',
    a13: 'OK',
    a14: 'Name:',
    a15: 'Please enter a name',
    a16: 'Name cannot be empty'
  },
  // 设备模块--间歇运行控制
  controllIntermittent: {
    a1: 'Group name',
    a2: 'Serial number：',
    a3: 'Object equipment',
    a4: 'Control condition',
    a5: 'Please select schedule',
    a6: 'Set',
    a7: "Device name",
    a8: "The signal name",
    a9: "Type",
    a10: "datas",
    a11: "Intermittent operation control",
    a12: "effective",
    a13: "Ineffective",
    a14: "minute",
    a15: "Start",
    a16: "Stop",
    a17: "Run time",
    a18: "Stop time",
    a19: "Measured point",
    a20: "Season group",
    a21: "Summer target temperature",
    a22: "Winter target temperature",
  },
    //设备模块--时间表控制页
    controllGroup: {
      a1: 'Group name',
      a2: 'No：',
      a3: 'Register Device',
      a4: 'Please select schedule',
      a5: 'Edite Register Device',
      a6: 'Copy Schedule',
      a7: 'Copy Source Schedule',
      a8: 'Unselected Schedule',
      a9: 'Please select the schedule to be copied!',
    },
  //设备模块--编辑设备
  terminaleditor: {
    a1: 'Loading....',
    a2: 'Registered Device Editor',
    a3: 'Registered device GR1',
    a4: 'inquire',
    a5: 'Complete equipment',
    a6: 'Add',
    a7: 'Delete',
    a8: 'Registered devices',
    a9: 'Set',
    a10: 'Schedule · Calendar selection',
    a11: 'Passing schedule:',
    a12: 'Calendar:',
    a13: 'Alert/unlock switch operation Settings:',
    a14: 'effective',
    a15: 'invalid',
    a16: 'datas',
    a17: 'Device name',
    a18: 'The signal name',
    a19: 'Type',
    a20: 'Input point device editing',
    a21: 'Output point device editing',
    a22: 'Linkage point device editing',
    a23: 'Intermittent run point editing',
    a24: 'Measure point editing',
    a25: 'None',
    a26: 'Analog setup equipment',
    a27: 'Upper and lower alarm setting equipment',
    a28: 'Run mode setup device',
    a29: 'Temperature measuring point',
    a30: 'Air conditioning monitoring point',
    a31: 'Trend point editing',
    a32: 'Time to set trend points for editing',
    a33: 'The device is not selected',
    a34: 'Please select the device to be changed',
    a35: 'Terminal with no schedule or calendar',
    a36: 'Please set a schedule and calendar on a terminal where no schedule or calendar is set',
    a37: 'You can only choose one option',
    a38: 'site ',
    a39: 'Linkage control input point ',
    a40: 'Linkage control output point ',
    a41: 'measurement point ',
    a42: 'operation point ',
    a43: 'virtual point ',
  },
  //设备模块--联动控制组设置页
  controllGroupLinkage: {
    a1: 'Group name',
    a2: 'No. :',
    a3: 'Input point',
    a4: 'Output point',
    a5: 'Interlocking point',
    a6: 'Please select a group',
    a7: 'Input point editing',
    a8: 'Output point editing',
    a9: 'Interlocking point editing',
  },
  //设备模块--季节切换控制组设置页
  seasonGroup: {
    a1: 'Group name',
    a2: 'No. :',
    a3: 'Analog setup equipment',
    a4: 'Upper and lower alarm setting equipment',
    a5: 'Run mode setup device',
    a6: 'Please select a group',
    a7: 'Simulation Settings for device editing',
    a8: 'Upper and lower alarm Settings for device editing',
    a9: 'Run mode Settings for device editing',
  },
  //设备模块--间歇运行控制组页
  controllGroupIntermittent: {
    a1: 'Group name',
    a2: 'No. :',
    a3: 'Intermittent running point',
    a4: 'Measurement point',
    a5: 'Please select a group',
    a6: 'Measure point editing',
    a7: 'Intermittent run point editing',
    a8: 'Run mode Settings for device editing',
  },
  //设备模块--舒适控制组页
  controllGroupComfort: {
    a1: 'Group name',
    a2: 'No. :',
    a3: 'Air conditioning monitoring point',
    a4: 'Temperature measuring point',
    a5: 'Please select the control group',
    a6: 'Measure point editing',
    a7: 'Temperature measurement point editing',
    a8: 'Air conditioning monitoring point editor',
    a9: 'Object equipment',
    a10: 'Control condition',
    a11: 'Set',
  },
  //时间趋势组
  timeTrend: {
    a1: 'Group name',
    a2: 'No. :',
    a3: 'Trend point',
    a4: 'Please select a group',
    a5: 'Trend point editing',
    a6: 'datas',
    a7: 'Device name',
    a8: 'The signal name',
    a9: 'Type',
    a10: 'Collection cycle',
    a11: 'Add ',
    a12: 'modify ',
    a13: 'delete ',
    a14: 'Sure you want to delete? ',
    a15: 'Sure ',
    a16: 'cancel',
    a17: 'success',
    a18: 'failure',
    a19: 'name already exists',
  },
  //时间设置趋势组
  timeSetupTrend: {
    a1: 'Time setting trend',
    a2: 'Trend group:',
    a3: 'No. :',
    a4: 'Timeline view',
    a5: 'Please select time',
    a6: '1day',
    a7: '1hour',
    a8: 'Date selection',
    a9: 'Time to choose',
    a10: 'Axis format',
    a11: 'state',
    a12: 'Many state',
    a13: 'Please select a group',
    a14: 'The list shows',
    a15: 'The chart shows',
    a16: 'The left shaft',
    a17: 'The right shaft',
    a18: 'unit',
    a19: 'The maximum',
    a20: 'The minimum',
    a21: 'Shaft number',
    a22: 'Device name',
    a23: 'The signal name',
    a24: 'The data type',
    a25: 'Chart type',
    a26: 'There is no device signal point. Please go to the page for setting trend Group time first.'
  },
  //数据比较趋势组
  dataCompareTrend: {
    a1: 'Trend of data comparison',
    a2: 'Trend group:',
    a3: 'No. :',
    a4: 'Add',
    a5: 'Are you sure you want to delete it?',
    a6: 'Delete',
    a7: 'state',
    a8: 'Many state',
    a9: 'Please select a group',
    a10: 'The list shows',
    a11: 'The chart shows',
    a12: 'The left shaft',
    a13: 'The right shaft',
    a14: 'unit',
    a15: 'The maximum',
    a16: 'The minimum',
    a17: 'Shaft number',
    a18: 'The date',
    a19: 'Week',
    a20: 'Chart type',
    a21: 'Date of operation',
    a22: 'The signal points',
    a23: 'Device name',
    a24: 'The signal name',
    a25: 'Type',
    a26: 'Please add date',
    a27: 'Broken-line chart',
    a28: 'Multistate graph',
  },
  //照明详细信息
  lightDetails: {
    a1: 'Switch illumination',
    a2: 'Environment illumination',
    a3: 'Switch Settings',
    a4: 'Switch state',
    a5: 'The fault',
    a6: 'The dimming setting',
    a7: 'The dimming state',
    a8: 'Operation/status/failure. The alarm',
    a9: 'Comment Registration Box',
    a10: 'Maintain surveillance',
    a11: 'normal',
    a12: 'Maximum 5 comments',
    a13: 'Schedule control',
    a14: 'Upper limit',
    a15: 'Upper and lower limit monitoring',
    b1: 'The cumulative running time exceeds the running time of the monitoring point',
    b2: 'Continuous elapsed time exceeds monitored continuous elapsed time',
    b3: 'The number of state changes exceeds the number of monitored state changes',
    b4: 'Failure alarm time exceeds monitoring fault alarm time',
    b5: 'The number of fault alarms exceeds the number of monitoring failures',
    b6: 'hour',
    b7: 'time',
    c1: 'The name of the RS',
    c2: 'The object ID',
    c3: 'Trend',
    c4: 'Unit classes',
    c5: 'The module',
    c6: 'extension',
    c7: 'Terminal no',
    d1: 'Change time:',
    d2: 'Factors:',
    d3: 'Maintain registration',
    d4: 'Important Equipment Registration',
    d5: 'Sound Category:',
    d6: 'open',
    d7: 'close',
    d8: 'Operation',
    d9: 'Status',
    d10: 'Fault alarm',
    d11: 'Setting',
    d12: 'Measurements',
    d13: 'Setting',
    d14: 'Setting success',
    d15: 'Setting failure',
  },
  //空调详细信息
  airConditionDetails: {
    a1: 'Remote control mode',
    a2: 'Cold wind',
    a3: 'Hot air',
    a4: 'Energy saving',
    a5: 'The wind speed',
    a6: 'Switch Settings',
    a7: 'Switch state',
    a8: 'The fault',
    a9: 'Temperature setting',
    a10: 'Suction temperature',
    a11: 'Operation mode setting',
    a12: 'Volume set',
    a13: 'Remote control disables temperature switch status',
    a14: 'Operation/status/failure. The alarm',
    a15: 'Comment Registration Box',
    a16: 'Maintain surveillance',
    a17: 'Device name',
    a18: 'run',
    a19: 'refrigeration',
    a20: 'heating',
    a21: 'normal',
    a22: 'weak wind ',
    a23: 'medium Wind',
    a24: 'strong Wind',
    a25: 'no',
    a26: "stop",
    a27: "RT",
    a28: 'Fire signal',
    a29: 'blowing',
    a30: 'dry',
    a31: 'Maintain',
    a32: 'Maintenance Alarm/LLS Alarm · Operation Stop Exceptions'
  },
  //环境详细信息
  EnvironmentDetails: {
    a1: 'Environmental scale',
    a2: 'The fault',
    a3: 'Comment Registration Box',
    a4: 'Maintain surveillance',
    a5: 'The name of the instrument',
    a6: 'measured value ',
    a7: 'upper and lower limit monitoring ',
    a8: 'Settings ',
    a9: 'upper limit is less than lower limit ',
    a10: 'Set value is not within the upper and lower limits ',
    a11: 'run',
    a12: 'stop',
  },
  //帐号设置窗口
  accountModal: {
    a1: 'Account Group ',
    a2: 'belong ',
    a3: 'account ID',
    a4: 'account number ',
    a5: 'verification code ',
    a6: 'valid ',
    a7: 'invalid ',
    a8: 'account permissions ',
    a9: 'Send test email ',
    a10: 'password ',
    a11: 'Change password ',
    a12: 'Last modified date ',
    a13: 'Password Period ',
    a14: 'Send mail ',
    a15: 'Record not downloaded successfully sent email ',
    a16: 'Download failed ',
    a17: 'Send mail once ',
    a18: 'Change password ',
    a19: 'Error sending message ',
    a20: 'Same old password ',
    a21: 'Old password incorrectly entered ',
    a22: 'illegal text exists ',
    a23: 'Password too long ',
    a24: 'other errors ',
    a25: 'Password authentication error ',
  },

  //公共指南
  commonInfoSetting: {
    a1: 'Guide ',
    a2: 'Last Update Person ',
    a3: 'Query ',
    a4: 'Make sure to delete the option ',
    a5: 'OK ',
    a6: 'Cancel ',
    a7: 'Delete ',
    a8: 'Guide Type ',
    a9: 'Show time period ',
    a10: 'Important information ',
    a11: 'add ',
    a12: 'Change ',
    a13: 'Notification Settings ',
    a14: 'piece of data ',
    a15: 'Public ',
    a16: 'Equipment ',
    a17: 'Energy ',
    a18: 'access Control ',
    a19: 'video send ',
    a20: 'Simplicity and Energy Saving ',
    a21: 'air Conditioning Energy Saving ',
    a22: 'Demand response ',
    a23: 'access Control Data Output ',
    a24: 'Change successful ',
    a25: 'Change failed: ',
    a26: ' add success ',
    a27: ' add failed :',
    a28: ' Delete succeeded ',
    a29: ' delete failed :',
    a30: ' New/Modify ',
    a31: ' Last Updated Date Time ',
    a32: ' Last Update Person ',
    a33: ' show object ',
    a34: ' Show start time ',
    a35: ' Display end time ',
  },

  //下载
  downloadSetting: {
    a1: 'Download Name ',
    a2: 'file name ',
    a3: 'Last Update Person ',
    a4: 'Query ',
    a5: 'Make sure to delete the option ',
    a6: 'OK ',
    a7: 'Cancel ',
    a8: 'delete ',
    a9: 'add ',
    a10: 'Change ',
    a11: 'Notification Settings ',
    a12: 'piece of data ',
    a13: 'New/Modify ',
    a14: 'Last Updated Date Time ',
    a15: 'Last Update Person ',
    a16: 'show object ',
    a17: 'Change successful ',
    a18: 'Change failed: ',
    a19: 'add success ',
    a20: 'add failed: ',
    a21: 'Delete succeeded ',
    a22: 'delete failed: ',
    a23: 'upload',
    a24: 'Only one item can be modified ',
  },

  //详情显示窗口
  editModal: {
    a1: 'Display period',
    a2: 'Important information',
  },

  //通知设置管理主页
  equipment: {
    a1: 'Public Guide ',
    a2: 'Enquiry Office ',
    a3: 'Inheritance ',
    a4: 'Download ',
    a5: 'Notification Settings ',
  },

  // 设备模块--设备执行时间表控制
  lightRunTimetable: {
    a1: 'Equipment Execution Schedule ',
    a2: 'Schedule name: ',
    a3: 'Cancel ',
    a4: 'Confirm ',
    a5: 'card mode ',
    a6: 'Free mode ',
    a7: " Repeat mode ",
    a8: " Enter card/exit free mode ",
    a9: " Enter freedom/Return mode ",
    a10: "Please select a schedule ",
    a11: "Copy between schedules ",
    a12: "Schedule replication ",
    a13: "Schedule full table replication ",
    a14: "Replication source schedule ",
    a15: "Replication source ",
    a16: "Copy to ",
    a17: 'Monday ',
    a18: 'Tuesday ',
    a19: 'Wednesday ',
    a20: 'Thursday ',
    a21: 'Friday',
    a22: 'Saturday ',
    a23: 'Sunday ',
    a24: 'holiday ',
    a25: 'Special Day 1',
    a26: 'T day 2',
    a27: 'T-day 3',
    a28: 'Special Day 4',
    a29: 'Equipment Schedule ',
    a30: 'Week not specified correctly, please specify it again ',
    a31: ' Mailing Schedule List ',
    a32: 'Name of the email sending schedule',
    a33: 'New',
    a34: 'Delete',
    a35: 'edit',
    a36: 'Calendar ',
    a37: 'Please set the calendar first',
  },

  //咨询处
  inquireSetting: {
    a1: 'Enquiry ',
    a2: 'Last Update Person ',
    a3: 'Query ',
    a4: 'Make sure to delete the option ',
    a5: 'OK ',
    a6: 'Cancel ',
    a7: 'Delete ',
    a8: 'add ',
    a9: 'Change ',
    a10: 'Notification Settings ',
    a11: 'piece of data ',
    a12: 'New/Modify ',
    a13: 'Last Updated Date Time ',
    a14: 'Last Update Person ',
    a15: 'show object ',
    a16: 'Show start time ',
    a17: 'Display end time ',
    a18: 'Change successful ',
    a19: 'Change failed: ',
    a20: 'add success ',
    a21: 'add failed: ',
    a22: 'Delete succeeded ',
    a23: 'delete failed: ',
    a24: 'handover matters ',
  },

  //帐号设置密码修改窗口
  passwordModal: {
    a1: 'current password 1',
    a2: 'new password 1',
    a3: 'Confirm password 1',
    a4: 'current password 2',
    a5: 'new password 2',
    a6: 'Confirm password 2',
  },

  //信息通知主页
  infoHome: {
    a1: 'Notice ',
    a2: 'Last Updated: ',
    a3: 'Public Guide ',
    a4: 'Shortcut ',
    a5: 'Enquiry address ',
    a6: 'Download ',
    a7: 'Register/Settings ',
    a8: 'handover matters ',
    a9: 'popover name ',
    a10: 'account Settings ',
    a11: 'add success ',
    a12: 'add failed: ',
    a13: 'update user information ',
    a14: 'Updated successfully ',
    a15: 'Update failed ',
  },

  //设置管理主页
  setHome: {
    a1: 'Customer Centre ',
    a2: 'User Settings ',
    a3: 'Notification Settings ',
    a4: 'card number management ',
    a5: 'Controller Management ',
    a6: 'alert management ',
    a7: 'Monitoring Center ',
    a8: 'Customer, complex and building site data: ',
    a9: 'menu error ',
  },

  //警报管理页
  notificationEquipment: {
    a1: 'Alarm Settings ',
    a2: 'Alarm Log Information ',
    a3: 'Notification Settings ',
    a4: 'Alert management ',
    a5: 'Email Sending Schedule',
  },

  //警报通知设置
  notification: {
    a1: 'select site information ',
    a2: 'Camera ',
    a3: 'VCR ',
    a4: 'Query ',
    a5: 'restrain ',
    a6: 'do not suppress ',
    a7: 'Make sure to set ',
    a8: 'OK ',
    a9: 'Cancel ',
    a10: 'Settings ',
    a11: 'Make sure to unset ',
    a12: ' Notification Settings ',
    a13: ' piece of data ',
    a14: 'alert ID',
    a15: ' Alert message ',
    a16: ' Alarm Receive ',
    a17: 'Control Notice ',
    a18: 'Setup failed, no set value ',
    a19: 'Setup successful ',
    a20: 'Setup failed: ',
  },

  //警报记录
  warningRecord: {
    a1: 'Loading.... ',
    a2: 'Building Code ',
    a3: 'Start Date ',
    a4: 'End Date ',
    a5: 'Options ',
    a6: 'values',
    a7: 'Enterprise Code ',
    a8: 'Send data category ',
    a9: 'Class of Control ',
    a10: 'Query ',
    a11: 'Alert Record List ',
    a12: ' Forced Restore ',
    a13: ' Category Change ',
    a14: ' The current Posting type is :',
    a15: ' after change :',
    a16: 'Settings',
    a17: 'Cancel ',
    a18: 'ALL ',
    a19: 'Customer Number ',
    a20: 'name of building ',
    a21: ' manage code ',
    a22: ' name of monitoring center ',
    a23: ' Send data not recovered ',
    a24: ' Test Send Data ',
    a25: 'alarm',
    a26: ' Job Send Data ',
    a27: ' Centrally Send Data ',
    a28: ' Communication abnormal ',
    a29: ' Hitachi Buildings ',
    a30: ' Official Alert ',
    a31: ' Test Alert ',
    a32: ' Job Alert ',
    a33: ' Bulk Alert ',
    a34: ' Change of issuing type ',
    a35: ' piece of data ',
    a36: ' Business name ',
    a37: ' Customer name ',
    a38: ' Customer Management Number ',
    a39: ' name of building ',
    a40: ' name of monitoring center ',
    a41: ' device name ',
    a42: ' Signal/terminal name ',
    a43: ' Posting type ',
    a44: ' Alarm type ',
    a45: ' Alarm content ',
    a46: ' Date of Occurrence ',
    a47: ' Restoration date ',
    a48: ' Deal with mail ',
    a49: 'comments',
    a50: ' Please select the item to change the category ',
    a51: ' Please select the item to be restored ',
  },
  //联系方式选择页
  deviceContact: {
    a1: 'Loading.... ',
    a2: 'customer name ',
    a3: 'name of building ',
    a4: 'Contact Name ',
    a5: 'Contact Information 1~3',
    a6: 'Query ',
    a7: 'Contact Information List ',
    a8: 'Contact 1',
    a9: 'Contact Information 2',
    a10: 'Contact Information 3',
    a11: 'add ',
    a12: 'updated',
    a13: 'delete',
    a14: 'sure',
    a15: ' Input point device edit ',
    a16: ' piece of data ',
    a17: ' Authentication terminal not selected ',
    a18: ' Please select the authentication terminal to be changed ',
    a19: ' Contact information not selected ',
    a20: ' Please select the contact information to be deleted ',
    a21: ' Delete succeeded ',
    a22: ' Please select the contact information to be updated ',
    a23: ' Updated successfully ',
  },
  //警报设置编辑页--编辑设备
  deviceEditor: {
    a1: 'Loading.... ',
    a2: 'equipment ',
    a3: 'Name ',
    a4: 'Registered Device GR1 ',
    a5: 'Registered device GR2 ',
    a6: 'Registered Device GR3 ',
    a7: 'Query ',
    a8: 'All equipment ',
    a9: 'add ',
    a10: 'Delete ',
    a11: 'Registered Device ',
    a12: 'Settings ',
    a13: 'input point device edit ',
    a14: 'piece of data ',
    a15: 'Access Control ',
    a16: 'All ',
    a17: 'electrical lock ',
    a18: 'Automatic door ',
    a19: 'brake ',
    a20: 'Alert ',
    a21: 'Contact ',
    a22: 'video ',
    a23: 'Camera ',
    a24: 'VCR ',
    a25: 'device name ',
    a26: 'Signal name ',
    a27: 'Type ',
    a28: 'Authentication terminal not selected ',
    a29: 'Please select the authentication terminal to be changed ',
    a30: 'device not selected ',
    a31: 'Please select the device to be changed ',
  },
  //警报管理页--警报设置
  warningSetting: {
    a1: 'select site information ',
    a2: 'Alarm type ',
    a3: 'responsible person ',
    a4: 'Query ',
    a5: 'delete ',
    a6: 'edit ',
    a7: 'New ',
    a8: 'Enterprise Code ',
    a9: 'customer number ',
    a10: 'customer name ',
    a11: 'name of building ',
    a12: 'Alarm Object ',
    a13: 'Equipment ',
    a14: 'System ',
    a15: 'device name ',
    a16: 'Type ',
    a17: 'Alarm Settings ',
    a18: 'Description ',
    a19: 'Disposal ',
    a20: 'Equipment contact information 1',
    a21: 'Contact Information of Equipment 2',
    a22: 'Contact Information of Equipment 3',
    a23: 'Contact Information of Equipment 4',
    a24: 'Equipment Contact Information 5',
    a25: 'Contact Information of Equipment 6',
    a26: 'Alert Handling Manual ',
    a27: 'Correspondence discrimination ',
    a28: 'Do you want to move? ',
    a29: 'telephone correspondence ',
    a30: 'HBS out ',
    a31: 'Alarm display ',
    a32: 'show',
    a33: 'origin',
    a34: 'is',
    a35: 'no.',
    a36: 'mail send ',
    a37: ' Send on startup ',
    a38: ' Send on recovery ',
    a39: ' Sound Settings ',
    a40: ' No alarm ',
    a41: ' Fire broke out ',
    a42: ' Power outage ',
    a43: ' Major failure ',
    a44: ' A glitch occurred ',
    a45: ' Update time ',
    a46: ' Updater ',
    a47: ' Send Settings ',
    a48: ' user select ',
    a49: ' send email address ',
    a50: ' Send schedule ',
    a51: ' send calendar ',
    a52: 'Settings',
    a53: 'cancel',
    a54: ' device selection ',
    a55: ' Contact Information ',
    a56: ' piece of data ',
    a57: ' Equipment contact 1 name ',
    a58: ' Equipment contact 1 way 1',
    a59: ' Equipment contact 1 way 2',
    a60: ' Equipment contact 1 way 3',
    a61: ' create people ',
    a62: ' Monitoring Schedule ',
    a63: ' Please select Add Personnel Site Information ',
  },
  energy: {
    ok: 'Setting',
    cancel: 'Cancel',
    add: 'Add',
    remove: 'Remove',
    search: 'Search',
    showTotal: '${total} Items',
    placeSelect: 'Place Select ',
    setting: {
      reportGr: {
        inputLabel: 'Place Input ${label}',
        spLabel: '${label} Can\'t contain special characters!',
        lenLabel: '${label} length must be between ${min} and ${max}',
        deleLabel: 'Are you sure you want to delete ${label} [${name}]?',
        saveLabel: 'Are you sure you want to save ${label} [${name}]?',
        saveSuce: 'Data saved successfully',
        saveFail: 'Data saving failed',
        loadFail: 'Data loading failed',
        daily: { name: ' Daily Report Group', nameLabel: 'Daily Report Group Name', edit: 'Point Edit' },
        monthly: { name: ' Monthly Report Group', nameLabel: 'Monthly Report Group Name', edit: 'Point Edit' },
        yearly: { name: ' Yearly Report Group', nameLabel: 'Yearly Report Group Name', edit: 'Point Edit' },
        trendGr: { name: 'Trend Group ', nameLabel: 'Trend Group Name ', edit: 'Point Edit ',chartName:'Trend Graph' },
        timeTrendGr: { name: 'Time Setup Trend Group ', nameLabel: 'Time Setup Trend Group Name', edit: 'Point Edit ' ,chartName:'Time Setup Trend Graph'},
        empty: 'Please select a group.',
        stop: 'The following special characters are disallowed',
        grlabel: 'Group Name',
        kikiName: 'Equipment Name',
        pointName: 'Point Name',
        dataKind: 'Data Type',
        numberTips: 'The maximum number of measurement points that can be set in the Daily Report/Monthly Report/Yearly report is 32.',
        trendTips: 'The maximum number of measurement points that can be set for trend group / time trend group is 12.',
        saveMP: 'Are you sure to save the selected measurement points?',
        addMp: 'Please select the measurement point to be added.',
        removeMp: 'Please select the measurement point to be deleted.',
        limitMp: 'The number of measurement points selected cannot exceed 32.',
        groupingLabel:"Measurement parameters of different grades are classified into the same group. Do you want to continue？",
      },
    },
    enedata001: "Statistical Data",
    enedata002: "Category",
    enedata003: "Measurement Object",
    enedata004: "Chart Type",
    enedata005: "Target Year",
    enedata006: "Search",
    enedata007: "Cumulative Amount",
    enedata008: "Cumulative",
    enedata009: "Usage Amount",
    enedata010: "Forecast Data",
    enedata011: "Actual Data",
    enedata012: "Display Objects",
    enedata013: "Line Chart",
    enedata014: "Bar Chart",
    enedata015: "Pie Chart",
    enedata016: "Time",
    enedata017: "Date",
    enedata018: "Comparison Chart",
    enedata019: "Actual usage",
    enedata020: "Original unit index",
    enedata021: "CO2 Conversion Coefficient",
    enedata022: "※The upper end represents \"usage\" and the lower end represents \"heat\"（ In the case of water energy, only the usage is shown)",
    enedata023: "Set Up",
    enedata024: "Measurement Point",
    enedata025: "Select All",
    enedata026: "Clear All",
    enedata027: "Print Preview",
    enedata028: "Change of Registered Equipment",
    enedata029: "Cost CSV Output",
    enedata030: "Execute",
    enedata031: "Please select a group.",
    enedata032: "Purpose",
    enedata033: "Choose a building.",
    enedata034: "Daily Report",
    enedata035: "Monthly Report",
    enedata036: "Yearly Report",
    enedata037: "Time limit",
    enedata038: "Please enter a number.",
    enedata039: "The date period of daily data cannot exceed one day.",
    enedata040: "The date period of monthly data cannot exceed one month.",
    enedata041: "The date period of annual data shall not exceed one year.",
    enedata042: "Report export",
    enedata043: "Energy Report",
    enedata044: "Data Output",
    enedata045: "Measurement Point Category",
    enedata046: "Year",
    enedata047: "Output",
    enedata048: "Output Date",
    enedata049: "Set the time you want to output, and then click the output button.",
    enedata050: "Within 1 year",
    enedata051: "Within 6 months",
    enedata052: "Within 3 months",
    enedata053: "Within 1 year",
    enedata054: "Within 13 months",
    enedata055: "Monthly data period should be within one year",
    enedata056: "Daily data period should be within 6 months",
    enedata057: "Hourly data period should be within 3 months",
    enedata058: "Conversion coefficient data period should be within one year",
    enedata059: "Equalization of electricity demand leveling data should be within 13 months",
    enedata060: "Energy type",
    enedata061: "Leveling Type",
    enedata062: "Energy Saving Management",
    enedata063: "Statistical year comparison",
    enedata064: "Building comparison",
    enedata065: "No Data",
    enedata066: "No Measurement Point",
    enedata067: "Target (Leveling After Conversion)",
    enedata068: "Actual (Leveling After Conversion)",
    enedata069: "Unit(Month)",
    enedata070: "Unit(Year)",
    enedata071: "Leveling Before Conversion",
    enedata072: "Leveling Conversion Value",
    enedata073: "Leveling After Conversion",
    enedata074: "Annual Total",
    enedata075: "Display Hierarchy",
    enedata076: "Client",
    enedata077: "Building",
    enedata078: "Site",
    enedata079: "Tenant",
    enedata080: "No visible energy in target year",
    enedata081: "Set successfully",
    enedata082: "Setup failed",
    enedata083: "Tenant input",
    enedata084: "Cost calculation",
    enedata085: "Actual Value Input",
    enedata086: "No Input confirm",
    enedata087: "Energy",
    enedata088: "Option",
    enedata089: "Include Tenants",
    enedata090: "Identify objects",
    enedata091: "Only display no input",
    enedata092: "Select the Client and click the Identify object button",
    enedata093: "Not Entered",
    enedata094: "Entered",
    enedata095: "There are no entries. Please execute again after entering all items.",
    enedata096: "Unit Price",
    enedata097: "FEN",
    enedata098: "JIAO",
    enedata099: "YUAN",
    enedata100: "Electricity Category",
    enedata101: "Peak Valley Period",
    enedata102: "Peak",
    enedata103: "Flat",
    enedata104: "Valley",
    enedata105: "Measurement value",
    enedata106: "Metering counter",
    enedata107: "Accumulated Data",
    enedata108: "Hourly Data",
    enedata109: "Daily Data",
    enedata110: "Monthly Data",
    enedata111: "Maintenance registration",
    enedata112: "Weight",
    enedata113: "Unit",
    enedata114: "Sound category",
    enedata115: "Upper and lower limit monitoring",
    enedata116: "Upper limit abnormal monitoring",
    enedata117: "Lower limit abnormal monitoring",
    enedata118: "Upper limit of utilization",
    enedata119: "Lower limit of utilization",
    enedata120: "Rate interval",
    enedata121: " Second",
    enedata122: "Usage (usage / rate interval)",
    enedata123: "Present value",
    enedata124: "Comments registration",
    enedata125: "Up to 5 comments",
    enedata126: "Click to write a comment",
    enedata127: "Clear",
    enedata128: "Refresh",
    enedata129: "Confirm",
    enedata130: "Associated signal point",
    enedata131: "Value range",
    enedata132: "Upper limit",
    enedata133: "Lower limit",
    enedata134: "Dead zone",
    enedata135: "0 ~ (upper limit - lower limit) range",
    enedata136: "Click Edit name",
    enedata137: "Power factor",
    enedata138: "Data loading failed",
    enedata139: "Trend chart",
    enedata140: "Are you sure you want to clear all comments?",
    enedata141: "Status Value",
    enedata142: "On Name",
    enedata143: "Off Name",
    enedata144: "Maintenance monitoring",
    enedata145: "Schedule Control",
    enedata146: "Control Status",
    enedata147: "The above comments are common to the equipment of this point.",
    enedata148: "Cos Monitoring",
    enedata149: "Video",
    enedata151: "Operation",
    enedata152: "Status Category",
    enedata153: "Condition inconsistency monitoring",
    enedata154: "Monitoring time",
    enedata155: "Set value",
    enedata156: "The fault alarm time exceeds the fault monitoring alarm time",
    enedata157: "Hour",
    enedata158: "The number of fault alarms exceeds the number of fault monitoring alarms",
    enedata159: "Times",
    enedata160: "The accumulated operation time exceeds the operation time of the monitoring point",
    enedata161: "Continuous running time exceeds monitoring continuous running time",
    enedata162: "The number of state changes exceeds the number of monitoring state changes",
    enedata163: "Basic Unit Index",
    enedata164: "Name",
    enedata165: "Unit",
    enedata166: "Input Type",
    enedata167: "Calculation Method",
    enedata168: "Annual Value",
    enedata169: "Delete",
    enedata170: "Manual Input",
    enedata171: "Calculation",
    enedata172: "Average",
    enedata173: "Register",
    enedata174: "Name not entered.",
    enedata175: "Unit not entered.",
    enedata176: "The calculation method was not selected. Please select the calculation method.",
    enedata177: "The calculation method cannot choose itself.",
    enedata178: "Calculation method cannot select the same item.",
    enedata179: "Calculation method cannot select unregistered items.",
    enedata180: "Data registration successful.",
    enedata181: "Data registration failed.",
    enedata182: "Data deleted successfully!",
    enedata183: "Data deletion failed!",
    enedata184: "Are you sure you want to register the settings?",
    enedata185: "Are you sure you want to delete?",
    enedata186: "Are you sure you want to discard the edited data?",
    enedata187: "Are you sure you want to delete the operation point?",
    enedata188: "Formula",
    enedata189: "Edit",
    enedata190: "Point GR setting",
    enedata191: "Add",
    enedata192: "Registered name",
    enedata193: "Expression type",
    enedata194: "Operation type",
    enedata195: "Total ${total} Data",
    enedata196: "Arithmetic point setting",
    enedata197: "Please select the arithmetic point to delete first",
    enedata198: "Expression",
    enedata199: "Group",
    enedata200: "Home page data display",
    enedata201: "Yes",
    enedata202: "No",
    enedata203: "Unit name",
    enedata204: "When you select free unit, you can enter the unit in free unit; Unit modification does not affect the operation.",
    enedata205: "Please select company name!",
    enedata206: "Free unit",
    enedata207: "Decimal places",
    enedata208: "Signal type",
    enedata209: "Equipment list",
    enedata210: "Addition and subtraction operation settings",
    enedata211: "Add select device list",
    enedata212: "Choice",
    enedata213: "Relieve",
    enedata214: "Subtraction selection device list",
    enedata215: "General operation settings",
    enedata216: "Selection expression",
    enedata217: "Please select the signal point of the equipment",
    enedata218: "Cannot contain special characters",
    enedata219: "Operation point name length must be within   one   reach   Between 30",
    enedata220: "Free unit length must be within   0   reach   Between 30",
    enedata221: "Formula length must be in   0   reach   Between 30",
    enedata222: "Please enter the operation point name",
    enedata223: "Please input the formula of operation point",
    enedata224: "Please select operation point type",
    enedata225: "Please select an expression",
    enedata227: "Please select decimal places",
    enedata228: "Please select group",
    enedata229: "Are you sure you want to save the set operation point information?",
    enedata230: "Please select the device you want to set up.",
    enedata231: "First year setup of power demand leveling Report",
    enedata232: "Measurement type",
    enedata233: "July inspection day",
    enedata234: "December inspection day",
    enedata235: "Metering signal No",
    enedata236: "Name of metering signal",
    enedata237: "Please select energy type",
    enedata238: "Are you sure you want to discard the edited energy type measurement object information?",
    enedata239: "Are you sure you want to save the set energy type measurement object information?",
    enedata240: "Reduction targets",
    enedata241: "Building / tenant",
    enedata242: "Include  buildings",
    enedata243: "Include architecture",
    enedata244: "※When customers or building groups choose, all reduction targets (except tenants) below the class will be covered.   In addition, if you select [include tenants], all the reduction targets including tenants below that floor will be covered. Please note.",
    enedata245: "※The reduction target is to automatically inherit the data of the previous year. Therefore, if you need to change it, please reset it every year.  ",
    enedata246: "Energy consumption",
    enedata247: "Compared with the previous year",
    enedata248: "Target value",
    enedata249: "Last update date",
    enedata250: "Are you sure you want to save the set reduction target?",
    enedata251: "If you change the reduction target value to blank well for registration, the reduction target and the last modification will be deleted as scheduled. Are you sure you want to register?",
    enedata252: "Basic Unit Index Value",
    enedata253: "Are you sure you want to save the basic unit index value?",
    enedata254: "Building name",
    enedata255: "All year round",
    enedata256: "Virtual point settings",
    enedata257: "Are you sure you want to delete the virtual point?",
    enedata258: "Please select the virtual point to delete",
    enedata259: "Energy / options",
    enedata260: "The name cannot contain special characters!",
    enedata261: "Please enter the virtual point name",
    enedata262: "The length of the virtual point name must be less than   one   reach   Between 30",
    enedata263: "Please select energy / options",
    enedata264: "Are you sure you want to save the set virtual point information?",
    enedata265: "Energy use type settings",
    enedata266: "Now registered energy",
    enedata267: "Gas type",
    enedata268: "Energy supply company",
    enedata269: "Division of tenant rights",
    enedata270: "Cost",
    enedata271: "Energy conversion",
    enedata272: "Daytime",
    enedata273: "Nighttime",
    enedata274: "Gas production rate",
    enedata275: "Value",
    enedata276: "First annual report on power demand leveling",
    enedata278: "Types of energy use",
    enedata279: "Are you sure you want to save the set energy use type?",
    enedata280: "The cost signal point has been set and cannot be cancelled; Please confirm the setting of the cost signal point in the metering point - division setting screen of our company / tenant.",
    enedata281: "The original metering signal point of the tenant right has been set for the selected energy use type, and it is not allowed to cancel the setting; Please confirm the original metering signal point setting of tenant right on the metering point - division setting screen of our company / tenant.",
    enedata282: "The metering signal point has been set for the selected energy use type, and it is not allowed to cancel the registration; Please confirm the metering signal point setting in the metering point energy type setting screen.",
    enedata283: "Are you sure you want to delete the GR information of the selected point?",
    enedata284: "Are you sure you want to save the set point GR information?",
    enedata286: "Total usage",
    enedata287: "Tenant usage",
    enedata288: "The selected device metering point has been registered",
    enedata289: "There is no signal point for the corresponding energy use type of equipment; Please register in operation point management and set in energy type.",
    enedata290: "Please select building / tenant",
    enedata291: "Please select target year",
    enedata292: "Measurement point management",
    enedata293: "Set content",
    enedata294: "Division of company / tenant",
    enedata295: "Measurement object Division",
    enedata296: "Virtual point",
    enedata297: "Supplier",
    enedata298: "No.",
    enedata299: "Are you sure you want to save the set metering object group details?",
    enedata300: "Are you sure you want to save the set measurement object group name?",
    enedata301: "Are you sure you want to delete the measurement object group [${text}]?",
    enedata302: "Please enter the measurement object group name",
    enedata303: "Measurement object group name length must be within   one   reach   Between 30",
    enedata304: "Measurement object group",
    enedata305: "Measurement object group name cannot contain special characters!",
    enedata306: "Click edit equipment name.",
    enedata307: "Please enter {text}	",
    enedata308: "{text} length must be within   {star}   reach   Between {end}	",
    enedata309: "{text} cannot contain special characters!	",
    enedata310: "Apr",
    enedata311: "May",
    enedata312: "Jun",
    enedata313: "Jul",
    enedata314: "Aug",
    enedata315: "Sep",
    enedata316: "Oct",
    enedata317: "Nov",
    enedata318: "Dec",
    enedata319: "Jan",
    enedata320: "Feb",
    enedata321: "Mar",
    enedata322: "Please select {text}	",
    enedata323: "Please enter Formula	",
    enedata324: "Please enter Free unit	",
    enedata325: "Add Point GR	",
    enedata326: "Please select Point GR	",
    enedata327: "Point GR	",
    enedata328: "Please enter Point GR Name	",
    enedata329: "Point GR Name cannot contain special characters!	",
    enedata330: "Point GR Name length must be within   1   reach   Between 30	",
    enedata331: "Add Measurement object group	",
    enedata332: "Please select Measurement object	",
    enedata333: "Reset	",
    enedata334: "History",
    enedata335: "History List",
    enedata336: "History Category",
    enedata337: "Logging",
    enedata338: "Trend",
    enedata339: "Start Date",
    enedata340: "End Date",
    enedata341: "Equipment Name",
    enedata342: "Signal No",
    enedata343: "Signal Name",
    enedata344: "Signal Point",
    enedata345: "Equipment",
    enedata346: "Please select start date",
    enedata347: "Please select end date",
    enedata348: "Electricity Consumption",
    enedata349: "Time",
    enedata350: "Date",
    enedata351: "Signal No",
    enedata352: "Measurement value",
    enedata353: "Unit",
    enedata354: "Copy the conversion factor.",
    enedata355: "The current year is selected in the target year. Are you sure to copy the conversion factor?",
    enedata356: "The past year is selected in the target year. Are you sure to copy the conversion factor?",
    enedata357: " Are you sure to copy the conversion factor?",
    enedata358: "Failed to get conversion factor.",
    enedata359: "No converted units were found for the energy type group.",
    enedata360: "Registration successful.",
    enedata361: "It's hard to contact now. It's troublesome for you. Please wait for a while.",
    enedata362: "Conversion value exceeds the set value limit, please re-enter.",
    enedata363: "Energy conversion value exceeds the set value limit, please re-enter.",
    enedata364: "Before adjustment, the CO2 conversion value exceeds the set value limit, please re-enter.",
    enedata365: "After adjustment, the CO2 conversion value exceeds the set value limit, please re-enter.",
    enedata366: "LPG unit conversion quantity exceeds the set value limit, please re-enter.",
    enedata367: "If the conversion value of No. {num} is not entered, the OK check box cannot be selected.",
    enedata368: "The conversion value of No. {num}, please input all or none.",
    enedata369: "Because it exceeds the scope of the previous year's replication, it cannot be implemented in {num} year.",
    enedata370: "Copy Last Year",
    enedata371: "{name} input is out of range.",
    enedata372: "{name} input is out of range. Set in the range between {min} and {max}.",
    enedata373: "Wrong input value for {name}.",
    enedata374: "Lower limit value is greater than upper limit.",
    enedata375: "Confirmation status",
    enedata376: 'No data, unable to generate file, unable to download.',
    enedata377: "Water Consumption",
    enedata378: "Trend:Data recorded by the device every 5 minutes.",
    enedata379: "Logging:Data recorded by the device every  hour.",
    enedata380: "Actual value the actual monthly energy consumption of the energy website. This function provides customers with the ability to enter and modify the actual energy consumption and actual cost.",
    enedata381: "No input confirmation function is to confirm the input data in the customer performance input screen every month and confirm the data.",
    enedata382: "The energy saving management screen is a function of displaying the monthly changes of energy consumption and cost with charts. It compares and displays the actual data and energy saving target data of each month.",
    enedata383: "Statistical year comparison screen is a screen that uses charts to compare the actual energy consumption of each month and each year of\"current year\",\"previous year\"and\"previous year\" ",
    enedata384: "Building comparison screen is a screen showing the comparison of buildings with energy use or cost and tenant use.",
    enedata385: "The statistics screen is a chart display of the energy consumption of each measurement object in month, day and time.",
    enedata386: "Cumulative chart screen is a screen that displays the energy consumption of each measurement object group in month, day and hour.",
    enedata387: "The actual performance picture of consumption is the picture of monthly conversion of original units and CO2 emissions for electricity, gas, oil, water and other energy sources.",
    enedata388: "The reduction target is the picture of registering the energy-saving target of energy use in a specified year.",
    enedata389: "Registration screen of denominator used in basic unit indicator calculation.",
    enedata390: "The input screen of buom indicator is the screen for registering and confirming the buom indicator of each month's unit.",
    enedata391: "The energy use type setting screen is the screen for registering the energy supply company and type of the building.",
    enedata392: "The metering point management screen is the screen for registering and confirming each building and leased energy supply company and point.",
    enedata393: "The metering point management screen includes four screens: energy type setting screen, division registration screen of our company / tenant, division registration screen of metering objects and virtual point registration screen.",
    enedata394: "Energy type setting screen is a screen for point-to-point setting of supply company and energy type.",
    enedata395: "The division / tenant division registration screen is a registration screen that distinguishes points (total usage / tenant usage / original division of tenant right); the division / tenant division can only set operation points and virtual points.",
    enedata396: "Metering object discrimination registration screen is a registration screen for distinguishing metering objects (e.g. air conditioning / Lighting etc.) from points.",
    enedata397: "The virtual point registration screen is a screen for registering points used to receive data other than fees and automatic metering.",
    enedata398: "User Defined Date",
    enedata399: "Starting Month",
    enedata400: "Floor/Tenant",
    enedata401: "Click edit point name.",
    enedata402:"Release information",
    enedata403:"Title font",
    enedata404:"Subtitle font",
    enedata405:"Label item name font",
    enedata406:"Label item content font",
    enedata407:"Warning prompt font",
    enedata408:"Note font",
    enedata409:"Send to display",
    enedata410:"size",
    enedata411:"color",
    enedata412:"Save only",
    enedata413:"Sync to display",
    enedata414:"Font style settings",
    enedata415:"Please enter title.",
    enedata416:"Please enter subtitle.",
    enedata417:"Please enter label item name.",
    enedata418:"Please enter label item content.",
    enedata419:"Please enter warning prompt.",
    enedata420:"Please enter comments.",
    enedata421:"Title length must be between 1 and 30.",
    enedata422:"Subtitles must be between 1 and 30 in length.",
    enedata423:"Label item name length must be between 1 and 30.",
    enedata424:"Label item content length must be between 1 and 30.",
    enedata425:"Warning prompt length must be between 1 and 30.",
    enedata426:"Comments length must be between 1 and 100.",
    enedata427:"Publish information only",
    enedata428:"Set font style only",
    enedata429:"Publish information and set font style at the same time",
    enedata430:"Please select a date",
    enedata431:"Select color",
    enedata432:"Please select a color",
    enedata433:"Color code format error",
    enedata434: "Click to jump to the alert Settings page",
    enedata435: "Measurement information",
    enedata436:"Date of last measurement",
    enedata437: "Term of measurement (month)",
    enedata438: "Next measurement date",
    enedata439:"Date ",
    enedata440:"Select Week ",
    enedata441:"Equipment/Signal Name ",
    enedata442:"Month ",
    enedata443:"Carbon Emission",
    enedata444: "Equipment linkage setting",
    enedata445: "Previous Day",
    enedata446: "Next Day",
    enedata447: "Previous Hour",
    enedata448: "Next Hour",
    enedata449:"Trend List",
    enedata450: "Please select a year",
    enedata451: "Region",
    enedata452: "Please select a region",
    enedata453: "Conversion factor",
    enedata454: "Please enter conversion factor",
    enedata455: "UOM",
    enedata456: "Please enter conversion unit",
    enedata457: "Conversion unit length must be between 1 and 30",
    enedata458: "Are you sure you want to save the set conversion factor information?",
    enedata459: "Are you sure you want to delete this conversion factor?",
    enedata460: "Please select the conversion factor to delete first!",
    enedata461: "Are you sure you want to delete the selected conversion factor?",
    enedata462: "Site energy conversion factor setting",
    enedata463: "Regional energy conversion factor setting",
    enedata464: "Energy conversion factor",
    enedata465: "Site energy conversion factor",
    enedata466: "Regional energy conversion factor",
    enedata467:'Equipment type ',
    enedata468:'Please select a equipment type!',
    enedata469:'Please select an association signal!',
    enedata470:'Please enter the virtual equipment name!',
    enedata471:'Virtual equipment name length must be between 1 and 30',
    enedata472:'Please select ',
    enedata473:'Equipment Group ',
    enedata474:'Virtual equipment settings ',
    enedata475:'Are you sure you want to delete the virtual equipment?',
    enedata476:'Please select the virtual equipment to be deleted first!',
    enedata477:'Are you sure you want to delete the selected conversion factor?',
    enedata478:'Virtual equipment management',
    enedata479:'Arithmetic point management',
    enedata480:'Please enter the name of the production line!',
    enedata481:'The length of the production line name must be between 1 and 30',
    enedata482:'Production line',
    enedata483:'Production line settings',
    enedata484:'Production line name',
    enedata485:'Are you sure you want to save the set production line information?',
    enedata486:'Are you sure you want to delete this production line?',
    enedata487:'Add production line',
    enedata488:'Edit production line',
    enedata489:'Product name',
    enedata490:'Production order number',
    enedata491:'Production start date',
    enedata492:'Production end date',
    enedata493:'Production quantity',
    enedata494:'Product unit',
    enedata495:'Energy consumption',
    enedata496:'Energy consumption unit',
    enedata497:'Production unit consumption',
    enedata498:'Unit',
    enedata499:'Production unit consumption management',
    enedata500:'Please select a production line',
    enedata501:'Please enter the product name',
    enedata502:'Please enter the production order number',
    enedata503:'Please select the production start date',
    enedata504:'Please select the production end date',
    enedata505:'Please enter the production quantity',
    enedata506:'Please enter the product unit',
    enedata507:'Please enter the energy consumption',
    enedata508:'Please enter the energy consumption unit',
    enedata509:'Please enter the production unit consumption',
    enedata510:'Please enter unit consumption',
    enedata511:'Production date',
    enedata512:'Please select a production date',
    enedata513:'Product name length must be between 1 and 30',
    enedata514:'The length of the production order number must be between 1 and 30',
    enedata515:'Product unit length must be between 1 and 30',
    enedata516:'Energy consumption unit length must be between 1 and 30',
    enedata517:'Note that the length must be between 1 and 300',
    enedata518:'Are you sure you want to save the set production energy consumption information?',
    enedata519:'Are you sure you want to delete this production energy consumption?',
    enedata520:'Please select the production energy consumption to delete first!',
    enedata521:'Are you sure you want to delete the selected production energy consumption?',
    enedata522:'Production quantity cannot be zero!',
    enedata523:'Production unit consumption cannot be zero!',
    enedata524:'Value cannot be zero!',
    enedata525:'CSV import',
    enedata526:'Import production energy consumption data',
    enedata527:'Template file',
    enedata528:'Production energy consumption template file',
    enedata529:'Download production energy consumption template file',
    enedata530:'Error information',
    enedata531:'Download error information',
    enedata532:'No, production line name, product name, production order number, production start date, production end date, production quantity, product unit, energy consumption, energy consumption unit, remarks',
    enedata533: 'Group by',
    enedata534: 'Production Unit Consumption Report',
    enedata535:'Please select a metering point ',
    enedata536:'Time Trend Chart ',
    enedata537:'Data Comparison Trend  Chart ',
    enedata538:'Data Output ',
    enedata539:'Data Comparison Trend  Group Settings ',
    enedata540:'Time Trend Group Settings ',
    enedata541: 'The selected date already exists, please do not select again',
    enedata542: 'Add up to 7 dates.',
    enedata543: 'File format error, please select CSV file.',
    enedata544: 'Today',
    enedata545: 'Current month',
    enedata546: 'Last month',
    enedata547:'Trend record information',
    enedata548:'Record generation interval',
    enedata549:'Read interval',
    enedata550:'Latest trend recording time',
    enedata551:' Minute',
    enedata552:'Trend reading status',
    enedata553:'Reading',
    enedata554:'Stop reading',
    enedata555:'Last read time',
    enedata556:'Last read number',
    enedata557:'Data batch import',
    enedata558:'Batch import of annual report data',
    enedata559:'Batch import of monthly report data',
    enedata560:'Batch import of daily report data ',
    enedata561:'Annual report batch entry template file',
    enedata562:'Monthly report batch entry template file',
    enedata563:'Daily report batch entry template file',
    enedata564:'Month / unit',
    enedata565:'Day / unit',
    enedata566:'Hour / unit',
    enedata567:'Parameter error',
    enedata568:'Signal point usage modification',
    enedata569:'Usage modification mode',
    enedata570:'Modify according to device information',
    enedata571:'Manual input usage modification',
    enedata572:'The usage of current date and future date cannot be modified.',
    enedata573:'Daytime usage',
    enedata574:'Night usage',
    enedata575:'Usage and night usage can only be distinguished every month / day.',
    enedata576:'The daytime time period is 9:00 ~ 22:00.',
    enedata577:'The night time period is from 1:00 to 8:00 and from 23:00 to 24:00.',
    enedata578:'Data source',
    enedata579:'Database',
    enedata580:'Device',
    enedata581:'Synchronize update data',
    enedata582:'Yes',
    enedata583:'No',
    enedata584:'Update data synchronously: modify the hourly data and update the daily and monthly data synchronously, except for the data of the current day and the current month. Modify the daily data and update the monthly data synchronously, except the data of the current month. There is no synchronous update of data when modifying monthly data.',
    enedata585:'KPI date',
    enedata586:'Operation date',
    enedata587:'Execution date',
    enedata588:'Alarm state',
    enedata589:'Running state',
    enedata590:'KPI target value',
    enedata591:'Annual rule',
    enedata592:'Energy efficiency KPI alarm setting',
    enedata593:'Enabled',
    enedata594:'Stop',
    enedata595:'Execute',
    enedata596:'Please select the KPI target alert to be set first.',
    enedata597:'Please select the alarm level.',
    enedata598: 'Energy efficiency KPI automatic task management',
    enedata599: 'Please enter the daytime usage',
    enedata600: 'Please enter the night usage',
    enedata601: 'Please enter the amount of day',
    enedata602: 'Daytime usage value range is 0~999999999.',
    enedata603: 'Nighttime usage value range is 0~999999999.',
    enedata604: 'The range of all day usage value is 0~199999999998.',
    enedata605: 'Site energy consumption comparison ',
    enedata606: 'Parent node',
    enedata607: 'Device type',
    enedata608: 'Parent node',
    enedata609: 'Calculation formula input error',
    enedata610: 'Balance',
  },
  video: {
    viddata001: 'ActiveX Download',
    viddata002: 'Video ActiveX',
    viddata003: 'Download',
    viddata004: 'Camera type',
    viddata005: 'Camera ID',
    viddata006: 'Camera name',
    viddata007: 'Camera  IP',
    viddata008: 'Camera port',
    viddata009: 'VCR name',
    viddata010: 'VCR IP',
    viddata011: 'VCR port',
    viddata012: 'VCR type',
    viddata013: 'Bandwidth limit',
    viddata014: 'Bandwidth limit batch setting',
    viddata015: 'Omnidirectional correction',
    viddata016: 'Omnidirectional correction batch setting',
    viddata017: 'standard',
    viddata018: 'Task view',
    viddata019: 'Double panorama',
    viddata020: 'Registrable cameras list',
    viddata021: 'Registered camera list',
    viddata022: 'Camera group name',
    viddata023: 'Camera group settings',
    viddata024: 'Camera settings',
    viddata025: 'Create group',
    viddata026: 'Delete group',
    viddata027: 'Edit group name',
    viddata028: 'Permission to set is not allowed.',
    viddata029: 'Batch editing',
    viddata030: 'Camera grouping',
    viddata031: 'Live',
    viddata032: 'playback',
    viddata033: 'Camera list',
    viddata034: 'Video recording list',
    viddata035: 'Attention ',
    viddata036: 'Storage location of video files',
    viddata037: 'Storage location of capture files',
    viddata038: 'Full screen',
    viddata039: 'Single screen play',
    viddata040: 'Play on {num} screen',
    viddata041: 'Enable electronic amplification',
    viddata042: 'Disable electronic amplification',
    viddata043: 'Capture',
    viddata044: 'Start recording',
    viddata045: 'End  recording',
    viddata046: 'Play',
    viddata047: 'Pause',
    viddata048: 'Stop ',
    viddata049: 'Slow down play',
    viddata050: 'Speed up play',
    viddata051: 'Save',
    viddata052: 'Local play',
    viddata053: 'Reconnection',
    viddata054: 'Close',
    viddata055: "Status panel",
    viddata056: "Zoom in",
    viddata057: "Recovery",
    viddata058: "Video record name",
    viddata059: "Start time",
    viddata060: "End time",
    viddata061: "The system does not support camera type",
    viddata062: "You have not installed a plug-in. Please download the plug-in on the download function page to install it.",
    viddata063: "All Stop",
    viddata064: "Camera",
    viddata065: "Number of Cameras",
    viddata066: "Failed to stop preview!",
    viddata067: "Preview failed!",
    viddata068: "The device does not support websocket streaming!",
    viddata069: "VCR has been logged in!",
    viddata070: "VCR login connection failed!",
    viddata071: "Cameras Total Number",
    viddata072: "Grouped Cameras Total Number",
    viddata073: "Ungrouped Cameras Total Number",
  },
  // 电梯模块--设备页
  elevatorequipment: {
    a1: 'Type selectivity',
    a2: 'ALL',
    a3: 'The Equipment',
    a4: 'Terminal',
    a5: 'loading...',
    a6: 'normal',
    a7: 'Abnormal communication',
    a8: 'Maintain',
    a9: 'Abnormal machine',
    a10: 'Failure/alarm',
    a11: 'Maintenance Alarm/LLS Alarm · Operation Stop Exceptions',
    a12: 'Query',
    a13: 'The site data is abnormal. Contact the administrator ',
  },
  // 电梯模块--远程控制
  elevatorecontrol: {
    a1: 'Remote Control ',
    a2: 'Inside the house visits',
    a3: 'The remote call the ladder',
    a4: 'Indoor called ladder',
    a5: 'The remote authorization',
    a6: 'loading...',
    a7: 'Floor Time Period',
  },
  // 电梯模块--栋内互访页
  elevatorexchangevisits: {
    a1: 'Exchange of visits between the process',
    a2: "Visitor's location ",
    a3: 'location of interviewee ',
    a4: 'Done ',
    a5: 'Select [Floor] and [Room] ',
    a6: 'Click [Exchange Visit] ',
    a7: 'Indoor Call Ladder ',
    a8: 'Click [Indoor Call Ladder] ',
    a9: 'Exchange of visits ',
    a10: "Visitor's Room",
    a11: "Respondent Floor",
    a12: "ALL",
    a13: "Visitor's Floor ",
    a14: "Operation successful",
    a15: "Operation failed",
  },
  // 电梯模块--远程召梯页
  elevatorRemoteemErgencyRecall: {
    a1: 'ALL',
    a2: 'Please select elevator location ',
    a3: 'Head to floor ',
    a4: 'Go to the room ',
    a5: 'Ladder only ',
    a6: 'Call up stairs and open floor permissions ',
    a7: 'Operation successful ',
    a8: 'Operation failed ',
  },
  // 电梯模块--室内召梯页
  elevatorIndoorErgencyRecall: {
    a1: 'ALL',
    a2: 'Please select elevator location ',
    a3: 'Ladder location ',
    a4: 'Indoor call stairs ',
    a5: 'Operation successful ',
    a6: 'Operation failed ',
  },
  // 电梯模块--远程授权页
  controlElevator: {
    a1: 'All elevators ',
    a2: 'Please select the elevator ',
    a3: 'Set Restricted Elevator Floor ',
    a4: 'lift',
    a5: 'in',
    a6: 'Note: Once lifted or put in, the elevator can not automatically lift the state, the need to manually switch the state.',
    a7: 'Temporary Release of Single Floor Restrictions ',
    a8: 'The location where the permissions are temporarily lifted ',
    a9: 'This floor is not temporarily accessible ',
    a10: 'Unrestricted ',
    a11: 'Operation successful ',
    a12: 'Operation failed ',
  },
  //Elevator module - floor time period setting page
  controlTimetable:{
    a1: 'Loading...',
    a2: 'Floor Time Period',
    a3: 'Floor Time Period List',
    a4: 'Edit',
    a5: 'Application',
    a6: 'Please select elevator',
    a7: 'Floor Schedule Setting',
    a8: 'Setting',
    a9: 'Cancel',
    a10: 'Floor',
    a11: 'Please select a floor',
    a12: 'Main door',
    a13: 'Auxiliary door',
    a14: 'Please select a schedule',
    a15: 'Application',
    a16: 'Current Floor',
    a17: 'All floors',
    a18: 'Public',
    a19: 'Security',
    a20: 'M',
    a21: 'S',
    a22: 'The floor list has been updated, please click [Application] to take effect.',
    a23: 'Floor list update failed. Details: ',
    a24: 'SET TIME PERIOD',
    a25: 'This operation will update the floor information time period, please confirm whether to update?',
    a26: 'Confirm',
    a27: 'Cancel',
    a28: 'Application succeeded',
    a29: 'Application failed, reason',
    //TB table
    tb1: 'Physical layer',
    tb2: 'Digital display',
    tb3: 'Attribute',
    tb4: 'Elevator door',
    tb5: 'Main gate time period',
    tb6: 'Auxiliary door time period',
  },
  // 设置 - 电梯时间表
  elevatorTimetable: {
    a1: 'loading...',
    a2: 'Add Elevator Schedule',
    a3: 'Elevator Schedule',
    a4: 'associated controller',
    a5: 'Schedule',
    a6: 'Association',
    a7: 'Schedule Name',
    a8: 'Card Mode',
    a9: 'EXIT Card Mode',
    a10: 'ENABLE',
    a11: 'CLOSE',
    a12: 'please select elevator schedule',
    a13: 'Schedule Copy',
    a14: 'Add Association',
    a15: 'Application',
    a16: 'Add schedule',
    a17: 'Please enter the schedule name',
    a18: 'Setting',
    a19: 'Cancel',
    a20: 'copy source',
    a21: 'Target source',
    a22: 'Schedule Name Update',
    a23: 'equipment batch Association',
    a24: 'associated controller',
    a25: 'Please select the associated controller',
    a26: 'Copy Table succeeded',
    a27: 'Copy Table process failed',
    a28: 'Failed to get the serial number of the next time period',
    a29: 'Application succeeded',
    a30: 'Setting application failed',
  },
  // 设置 - 电梯时间表 - 时间表页
  timetableModule:{
    a1: 'loading...',
    a2: 'Monday',
    a3: 'Tuesday',
    a4: 'Wednesday',
    a5: 'Thursday',
    a6: 'Friday',
    a7: 'Saturday',
    a8: 'Sunday',
    a9: 'Week',
    a10: 'MODE',
    a11: 'EFFECTIVE DATE SETTING',
    a12: 'Effective Date',
    a13: 'Setting',
    a14: 'Cancel',
    a15: 'set week',
    a16: '-',
    a17: '-',
    a18: '',
    a19: 'TO',
  },
  // 设置 - 电梯时间表 - 时间表页 - 表
  timetableModuleDT:{
    a1: 'loading...',
    a2: 'Schedule Change',
    a3: 'Schedule Type',
    a4: 'Elevator Schedule',
    a5: 'Schedule Name',
    a6: 'Since the initial value is',
    a7: 'EXIT CARD MODE',
    a8: 'so please set other modes A8 ',
    a9: 'H',
    a10: 'M',
    a11: 'Setting',
    a12: 'Cancel',
    a13: 'Card Mode',
  },
  timedeviceModule:{
    a1: 'loading...',
    a2: 'Remove',
    a3: 'No Device Associated!',
    a4: 'Are you sure to cancel the association with the elevator schedule?',
    a5: 'Removal Succeeded!',
    a6: 'Please deactivate the schedule before unbinding.',
    a7: 'Settings applied successfully!',
    a8: 'Controller enabling time period failed.',
    a9: 'Controller disabling time period failed.',
    a10: 'Cancel association with elevator schedule.',
  },
  // 记录--卡片记录
  elevatorCardRecords: {
    a0: 'Loading ...',
    a1: 'Card History',
    a2: 'Card number ',
    a3: 'Result',
    a4: 'Start Date ',
    a5: 'End Date ',
    a6: 'Query ',
    a7: 'List of records ',
    a8: 'Please select a date or time ',
    a9: 'DATE OF OCCURRENCE ',
    a10: 'Authentication type ',
    a11: 'name',
    a12: 'card number ',
    a13: 'temperature',
    a14: 'Name of elevator ',
    a15: 'Signal/terminal name ',
    a16: 'name of building ',
    a17: 'comments',
    a18: 'result',
    a19: 'success',
    a20: 'failure',
    a21: 'Please enter the card number',
    a22: 'ALL',
    a23: 'total',
    a24: 'bar of data ',
    a25: 'Card'
  },
  // 记录--二维码记录
  elevatorQrCodeRecords: {
    a0: 'Loading....',
    a1: 'QrCode History',
    a2: 'QR code',
    a3: 'result ',
    a4: 'Start Date ',
    a5: 'End Date ',
    a6: 'Query',
    a7: 'List of records ',
    a8: 'Please select date or time ',
    a9: 'Occurrence date ',
    a10: 'Please enter QR code ',
    a11: 'All',
    a12: 'total',
    a13: 'piece of data ',
    a14: '',
    a15: '',
    a16: '',
    a17: '',
    a18: '',
    a19: '',
    a20: '',
    a21: '',
    a22: '',
    a23: '',
    a24: '',
    a25: '',
    a26: '',
    a27: '',
    a28: '',
    a29: '',
    td1: 'date of occurrence ',
    td2: 'applicant ',
    td3: 'no.',
    td4: 'QR code Certificate ',
    td5: 'code status',
    td6: 'application start time ',
    td7: 'valid duration ',
    td8: 'starting floor ',
    td9: 'name of elevator ',
    td10: 'signal/Terminal name ',
    td11: 'name of Building ',
    td12: 'notes ',
    td13: 'room ',
    td14: 'sync time ',
    td15: 'sync results ',
    s1: 'success',
    s2: 'failure',
    s3: 'exception',
    s4: 'be uploading',
    s5: 'effective',
    s6: 'invalid',

  },
  // 记录--远程召梯记录
  elevatorRemoteRecords: {
    a0: 'Loading ...',
    a1: 'Remote Control History',
    a2: 'Card number ',
    a3: 'Result',
    a4: 'Start Date ',
    a5: 'End Date ',
    a6: 'Query ',
    a7: 'List of records ',
    a8: 'Please select a date or time ',
    a9: 'DATE OF OCCURRENCE ',
    a10: 'Authentication type ',
    a11: 'name',
    a12: 'card number ',
    a13: 'temperature',
    a14: 'Name of elevator ',
    a15: 'Signal/terminal name ',
    a16: 'name of building ',
    a17: 'comments',
    a18: 'result',
    a19: 'success',
    a20: 'failure',
    a21: 'Please enter the card number',
    a22: 'ALL',
    a23: 'total',
    a24: 'bar of data ',
    a25: 'Card',
    td1: 'Mandatory order',
    td2: 'CRT',
    td3: 'response time',
    td4: 'Name',
    td5: 'Num',
    td6: 'ID',
    td7: 'Subordinate to the room',
    td8: 'Visit the room',
    td9: 'Surveyed the room',
    td10: 'Description',
    td11: 'Results',
    s1: 'ALL',
    s2: 'Remote control arm',
    s3: 'Indoor called ladder',
    s4: 'Reciprocal visits',
    s5: 'WeChat called ladder',
    s6: 'fail',
    s7: 'Information abnormity',
    s8: 'Device is offline',
    s9: 'ladder 1',
    s10: 'ladder 2',
    s11: 'ladder 3',
    s12: 'ladder 4',
    s13: 'ladder 5',
    s14: 'ladder 6',
    s15: 'ladder 7',
    s16: 'ladder 8',
    s17: 'ladder 9',
    s18: 'ladder 10',
    s19: 'ladder 11',
    s20: 'ladder 12',
    s21: 'ladder 13',
    s22: 'ladder 14',
    s23: 'ladder 15',
    s24: 'ladder 16'
  },
  // 电梯模块--实际数据
  elevatorRealdata: {
    a1: 'Loading ...',
    a2: 'Report Date',
    a3: 'ALL',
    a4: 'Card',
    a5: 'Card Reader',
    a6: 'Type',
    a7: 'Monthly Bulletin ',
    a8: 'Daily ',
    a9: 'Output period: ',
    a10: 'file split score: ',
    a11: 'output days: ',
    a12: '1 generates a file without splitting ',
    a13: '2 Generate files every 15 days (including the end date of the last file) ',
    a14: '3 Generate files every 20 days (including the end date of last file) ',
    a15: '4 Generate files every 7 days (including the end date of the last file) ',
    a16: '5 divide every 6 days (including the end date of the last file) generate file ',
    a17: 'If the output is large, the number of divisions will naturally change to 5',
    a18: 'Download file name: ',
    a19: 'Output...',
    a20: 'output ',
    a21: 'File Splitting Instructions ',
    a22: 'Name ',
    a23: 'Please enter name ',
    a24: 'card number ',
    a25: 'Please enter card number ',
    a26: 'ID card',
    a27: 'Please enter card ID',
    a28: 'Access card reader',
    a29: 'Elevator card reader',
    a30: 'Query ',
    a31: 'Authentication Information List ',
    a32: 'bar of data ',
    a33: 'Manage ownership ',
    a34: 'No date selected ',
    a35: 'Please select a date ',
    a36: 'device group name ',
    a37: 'total',
    a38: 'deviceName ',
    a39: 'Please enter device name ',
    a40: 'Door List ',
  },
  // 电梯模块--运行状态
  elevatorRunning: {
    a1: 'Loading ...',
    a2: 'Filter',
    a3: 'Fire Protection/Fire Protection',
    a4: 'VIP headed for',
    a5: 'ITM EXIT',
    a6: 'ITM ENABLED',
    a7: 'Malfunction',
    a8: 'Device NOT connected',
    a9: 'Requesting elevator status',
    a10: 'Failed to obtain elevator status',
    a11: 'isv.INVALID_PARAMETERS',
    a12: 'The elevator does not exist',
    a13: 'Abnormal controller parameters',
    a14: 'Normal',
    a15: 'UP DIRECTION',
    a16: 'Movement UPWARD',
    a17: 'DOWN DIRECTION',
    a18: 'Movement DOWNWARD',
    a19: 'STATIC/DIRECTIONLESS',
  },
  // 认证信息
  information: {
    a1: "loading...",
    a2: "Card information",
    a3: "Personnel information",
    a4: "card selection",
    a5: "name",
    a6: "please enter your name",
    a7: "management code",
    a8: "please enter the management code",
    a9: "card number",
    a10: "please enter the card number",
    a11: "card number",
    a12: "please enter the card number",
    a13: "display options",
    a14: "slave",
    a15: "all",
    a16: "usage type",
    a17: "All usage types",
    a18: "ordinary card",
    a19: "temporary card",
    a20: "usage status",
    a21: "All usage status",
    a22: "unassigned",
    a23: "in use",
    a24: "card status",
    a25: "All card status",
    a26: "available · lent",
    a27: "unusable · returned",
    a28: "stop using",
    a29: "query",
    a30: "Card information list",
    a31: "delete",
    a32: "Edit",
    a33: "select operation",
    a34: "card edit",
    a35: "add",
    a36: "pieces of data",
    a37: "available",
    a38: "not available",
    a39: "stop using",
    a40: "lending",
    a41: "return",
    a42: "management ownership",
    a43: "associated building",
    a44: "start date",
    a45: "validity period",
    a46: "final use date",
    a47: "remarks",
    a48: "operation",
    a49: "Are you sure you want to delete the card information?",
    a50: "change",
    a51: "close",
    a52: "add subordinate",
    a53: "Name:",
    a54: "please enter a name",
    a55: "name change",
    a56: "are you sure you want to delete",
    a57: "are you sure?",
    a58: "Add card information",
    a59: "card type",
    a60: "card ownership",
    a61: "card number cannot be empty",
    a62: "card number cannot be empty",
    a64: "Edit card information",
    a65: "setting",
    a66: "manage selection",
    a67: "department",
    a68: "please enter a department",
    a69: "Personnel information list",
    a70: "personnel editing",
    a71: "personnel recharge",
    a72: "manage edits",
    a73: "personnel addition",
    a74: "card type setting",
    a75: "account recharge",
    a76: "batch add registration",
    a77: "account type",
    a78: "bookkeeping",
    a79: "cash",
    a80: "balance",
    a81: "registration date",
    a82: "Company1",
    a83: "Company2",
    a84: "Company3",
    a85: "Company4",
    a86: "Company5",
    a87: "Batch registration of personnel information",
    a88: "Personnel information",
    a89: "Manage ownership selection",
    a90: "Card ownership selection",
    a91: "Access site selection",
    a92: "Personnel information (XLSX、CSV) upload",
    a93: "* management ownership, please select“all management ownership”",
    a94: "select file",
    a95: "Upload",
    a96: "Information download",
    a97: "download subordinate management at the same time",
    a98: "download",
    a99: "refers to vein information (. CSV) upload",
    a100: "refers to vein information (. CSV) download",
    a101: "download only the registered data of finger vein",
    a102: "vein information",
    a103: "license plate information",
    a104: "license plate information (. CSV) upload",
    a105: "license plate information (. CSV) download",
    a106: "download only the registered data of license plate",
    a107: "file format error",
    a108: "please select a file with. CSV file format",
    a109: "card type setting",
    a110: "consumption card type setting",
    a111: "personnel management",
    a113: "basic information",
    a114: "mobile phone number",
    a116: "please enter your mobile phone number",
    a117: "belongs to:",
    a118: "group:",
    a119: "registration date:",
    a120: "Update Date:",
    a121: "change of ownership",
    a122: "card",
    a123: "card information",
    a124: "Remove the card",
    a125: "associated sites",
    a126: "select a site",
    a127: "ordinary card",
    a128: "card No.:",
    a129: "validity period:",
    a130: "card status:",
    a131: "available",
    a132: "not available",
    a133: "stop using",
    a134: "finger vein",
    a135: "first refers to information:",
    a136: "second refers to information:",
    a137: "threshold level:",
    a138: "face",
    a139: "face information:",
    a140: "privilege setting",
    a141: "cleaner privilege:",
    a142: "None",
    a143: "yes",
    a144: "refers to venous privilege:",
    a145: "consumption settings",
    a146: "card type:",
    a147: "settlement method:",
    a148: "ladder control",
    a149: "room name:",
    a150: "associated elevator",
    a151: "select elevator",
    a152: "site name",
    a153: "traffic mode 1",
    a154: "traffic mode",
    a155: "validity period",
    a156: "traffic mode 2",
    a157: "traffic mode 3",
    a158: "traffic mode 4",
    a159: "traffic mode 5",
    a160: "elevator name",
    a161: "floor",
    a162: "room name",
    a163: "not registered",
    a164: "registered",
    a165: "collected",
    a166: "no management is selected",
    a167: "please select the management information",
    a168: "manage selection and editing",
    a169: "card selection",
    a170: "Card No",
    a171: "please enter the card ID",
    a172: "card number",
    a173: "please enter the card number",
    a174: "query",
    a175: "card list",
    a176: "card number",
    a177: "Card No",
    a178: "card status",
    a179: "available",
    a180: "not available",
    a181: "stop using",
    a182: "lending",
    a183: "return",
    a184: "card type",
    a185: "ordinary card",
    a186: "temporary card",
    a187: "card use start date",
    a188: "operation",
    a189: "validity period setting",
    a190: "Cancel",
    a191: "confirm",
    a192: "initial date",
    a193: "termination date",
    a194: "venous collection",
    a195: "first finger:",
    a196: "read",
    a197: "second finger:",
    a198: "equipment: the venous collector is successfully connected!",
    a199: "equipment: the venous collector is not connected!",
    a200: "to be read",
    a201: "reading...",
    a202: "read failed",
    a203: "collected",
    a204: "face picture settings",
    a205: "select a picture",
    a206: "Note: It is recommended to use standard ID photos, the image format is jpg.",
    a207: "site selection",
    a208: "setting",
    a209: "close",
    a210: "unregistered site list",
    a211: "add ↓",
    a212: "list of registered sites",
    a213: "delete ↑",
    a214: "pieces of data",
    a215: "name",
    a216: "address",
    a217: "type",
    a218: "number",
    a219: "access mode selection",
    a220: "list of traffic modes",
    a221: "traffic mode",
    a222: "start date",
    a223: "end date",
    a224: "selected",
    a225: "this traffic mode has been selected, please select another traffic mode",
    a226: "the upper limit of addition has been reached",
    a227: "only 5 traffic modes can be added",
    a228: "elevator selection",
    a229: "unregistered elevator list",
    a230: "list of registered elevators",
    a231: "floor name",
    a232: "room number",
    a233: "Account recharge ",
    a234: "Recharge ",
    a235: "Prepaid card Number query: ",
    a236: "Please enter the card number ",
    a237: "Basic Information ",
    a238: "Consumer Information ",
    a239: "Account balance ",
    a240: "Payment method ",
    a241: "Date of Registration ",
    a242: "Update date ",
    a243: "Recent recharge amount ",
    a244: "Last recharge time ",
    a245: "Charge cards need not be recharged ",
    a246: "Recharge amount ",
    a247: "Please enter the recharge amount ",
    a248: "No information about this person can be found ",
    a249: "Please check your input for errors ",
    a250: "Card number not entered ",
    a251: "Recharge amount not entered ",
    a252: "no room number selected",
    a253: "please select a room number",
    a254: "The image size cannot exceed 6M",
    a255: "Please select a JGP file",
    a256: "Nationality",
    a257: "Elevator information",
    a258: "elevator information (.CSV) upload",
    a259: "elevator information (.CSV) download",
    a260: "management code cannot be empty",
    a261: "name cannot be empty",
    a262: "please fill in the correct mobile phone number",
    a263: 'traffic mode details',
    a264: 'access mode name:',
    a265: 'enterprise name',
    a266: 'building name',
    a267: 'floor · tenant name',
    a268: 'device name',
    a269: 'authentication terminal name',
    a270: 'access type',
    a271: 'alert setting',
    a272: 'schedule name',
    a273: 'calendar name',
    a274: 'Please enter the name of traffic mode',
    a275: 'total quantity：',
    a276: 'added quantity：',
    a277: 'update quantity：',
    a278: 'number of failures：',
    a279: 'download details',
    a280: 'data details',
    a281: 'please select a site',
    a282: 'list of registrable access modes',
    a283: 'list of registered traffic modes',
    a284: 'name search',
    a285: 'the quantity has exceeded the maximum of 5 items',
    a286: 'Permission setting',
    a287: 'Face information',
    a288: 'Upload face information (. zip)',
    a289: 'Download face information (. zip)',
    a290: 'Please select a file with the file format of. zip',
    a291: 'Enter search text',
    a292: 'Personnel access equipment information download',
    a293: 'Personnel access information download',
    a294: 'Last batch imported personnel information download',
    a295:'Select picture',
    a296:'Photo editor',
    a297:'Picture example description',
  },
  // 消费管理模块
  consume: {
    a1: "loading....",
    a2: "Equipment Details ",
    a3: "Close ",
    a4: "Basic Information ",
    a5: "Consumption period ",
    a6: "Price list ",
    a7: "Normal operation ",
    a8: "Off-line/maintenance ",
    a9: "Breakfast ",
    a10: "Lunch ",
    a11: "Dinner ",
    a12: "midnight snack",
    a13: "Terminal name ",
    a14: "Site name ",
    a15: "Customer name ",
    a16: "status ",
    a17: "Monday ",
    a18: "Tuesday ",
    a19: "Wednesday ",
    a20: "Thursday ",
    a21: "Friday ",
    a22: "Saturday ",
    a23: "Sunday ",
    a24: "Card record ",
    a25: "Name ",
    a26: "Please enter your name ",
    a27: "Card number ",
    a28: "Please enter the card number ",
    a29: "Manage code ",
    a30: "Please enter administrative code ",
    a31: "Consumer machine name ",
    a32: "Please enter the name of the consumer machine ",
    a33: "Consumer number ",
    a34: "Please enter the name of the consumer machine ",
    a35: "Start date ",
    a36: "End date ",
    a37: "Enquiries ",
    a38: "Record list ",
    a39: "Please select a date or time ",
    a40: "piece of data ",
    a41: "Date of occurrence ",
    a42: "Consumption pattern ",
    a43: "Account type ",
    a44: "Name ",
    a45: "Manage code ",
    a46: "Card number ",
    a47: "Site name ",
    a48: "Consumer number ",
    a49: "Consumer machine name ",
    a50: "Amount spent ",
    a51: "Balance ",
    a52: "Number of meals",
    a53: "Belong to 1",
    a54: "Belong to 2",
    a55: "Belong to 3",
    a56: "Belong to 4",
    a57: "Belong to 5",
    a58: "Daily menu Settings ",
    a59: "Select file ",
    a60: "Note: Only XLSX file uploads are accepted.",
    a61: "Upload ",
    a62: "Consumption Settings ",
    a63: "Consumption period parameter setting ",
    a64: "Consumer machine parameter Settings ",
    a65: "Time between swipe (s)",
    a66: "Daily consumption limit (yuan)",
    a67: "Daily consumption limit ",
    a68: "Voice prompt type ",
    a69: "-",
    a70: "1-consumer success voice alert ",
    a71: "2-beep one color ",
    a72: "3- Long one color ",
    a73: "4 - VOICEType1 ",
    a74: "5 - VOICEType2",
    a75: "Settings ",
    a76: "Consumption Settings?",
    a77: "Whether to modify the consumption parameter Settings.",
    a78: "Settlement rule setting ",
    a79: "Price List for Each table ",
    a80: "Edit ",
    a81: "Meal Price Setting ",
    a82: "Cancel ",
    a83: "Confirm ",
    a84: "Price setting ",
    a85: "Breakfast (yuan) ",
    a86: "Lunch (yuan) ",
    a87: "Dinner (yuan) ",
    a88: "night snack (yuan)",
    a89: "Operation ",
    a90: "Consumption Statement ",
    a91: "Report period selection ",
    a92: "Canteen Choice ",
    a93: "time period ",
    a94: "during output ",
    a95: "one month ",
    a96: "One day ",
    a97: "Custom time ",
    a98: "Output report type selection ",
    a99: "All ",
    a100: "Download file name ",
    a101: "Output...",
    a102: "output ",
    a103: "Consumption Statement ",
    a104: "Daily Personal Consumption Report ",
    a105: "Monthly Personal Consumption Report ",
    a106: "Comprehensive Consumption Statement ",
    a107: "Departmental Consumption Statement ",
    a108: "Meal Report ",
    a109: "Data output ",
    a110: "Start date ",
    a111: "Please enter the start date ",
    a112: "End date ",
    a113: "Please enter the end date ",
    a114: "Data chart ",
    a115: "(Number of swipes) ",
    a116: "Please select a date ",
    a117: "Consumption statistics",
    a118: "Total card swiping times：",
  },
  // Visitor module -- visitor reservation record
  visitorBookingRecord: {
    a1: 'loading ',
    a2: 'Visitor Reservation Record ',
    a3: 'custom condition 1',
    a4: 'custom condition 2',
    a5: 'time ',
    a6: 'Start Date ',
    a7: 'Select end date/time ',
    a8: 'Query ',
    a9: 'Visitor Reservation Record List ',
    a10: 'Please select date or time ',
    a11: 'Piece of Data ',
    a12: 'Visitor Name ',
    a13: 'Visitor Phone ',
    a14: 'Visitor Company ',
    a15: 'Order Status ',
    a16: 'Visiting Status ',
    a17: 'Booking Access Date ',
    a18: 'Booking End Date ',
    a19: 'Purpose of visit ',
    a20: 'Visit instructions ',
    a21: 'Visitor Car Number ',
    a22: 'Respondent Code ',
    a23: 'Respondent Name  ',
    a24: "Respondent's Company ",
    a25: 'Visitor Number  ',
    a26: 'Application Time ',
    a27: 'Order number ',
    a28: 'Caller number ',
    a29: 'Details',
    a30: 'Access cancelled ',
    a31: 'Not accessed ',
    a32: 'Visiting ',
    a33: 'Access completed',
    a34: 'Lost access ',
    a35: 'Not visited ',
    a36: 'Already visited ',
    a37: 'Left ',
    a38: 'Finished ',
    a39: 'Business negotiation ',
    a40: 'Government Administration ',
    a41: 'Technical communication ',
    a42: 'Market Research ',
    a43: 'Interview ',
    a44: 'Delivery ',
    a45: 'Maintenance ',
    a46: 'Temporary staff ',
    a47: 'Other ',
    a48: 'operation ',
    a49: 'Cancel ',
    a50: 'Select operation ',
    a51: 'Visitor information',
    a52: 'Interviewee information',
    a53: 'All (reasons for visit)',
    a54: 'All (visit status)',
    a55: 'All (work order status)',
    a56: 'All (authentication type)'
  },

  // Visitor module -- Visitor logs

  visitorPeopleRecord: {
    a1: 'loading ',
    a2: 'Visitor Records ',
    a3: 'Custom condition 1',
    a4: 'Custom condition 2',
    a5: 'Date Selection ',
    a6: 'Start Date ',
    a7: 'End Date ',
    a8: 'Query ',
    a9: 'Visitor Record List ',
    a10: 'Please select date or time ',
    a11: 'piece of data ',
    a12: 'Visitor name ',
    a13: 'Visitor telephone ',
    a14: 'Visitor Company ',
    a15: 'Order Status',
    a16: 'Visiting Status ',
    a17: 'Booking Access Date ',
    a18: 'Booking End Date ',
    a19: 'Authentication Type ',
    a20: 'Detention time ',
    a21: 'Visitor code ',
    a22: 'Respondent Number ',
    a23: 'Respondent Name  ',
    a24: "Respondent's ownership",
    a25: 'Respondent telephone ',
    a26: 'Application time ',
    a27: 'Order No.',
    a28: 'Interviewee telephone ',
    a29: 'Details ',
    a30: 'Access cancelled ',
    a31: 'Not accessed ',
    a32: 'Visiting ',
    a33: 'access completed',
    a34: 'Lost access ',
    a35: 'Not visited ',
    a36: 'Already visited ',
    a37: 'Left ',
    a38: 'Finished ',
    a39: 'Card Authentication ',
    a40: 'Hand operation ',
    a41: 'Card certification + Finger vein certification ',
    a42: 'Card authentication + fingerprint authentication ',
    a43: 'Finger vein certification ',
    a44: 'Fingerprint authentication ',
    a45: 'bluetooth authentication',
    a46: 'Digital key authentication ',
    a47: 'Identity card Authentication',
    a48: 'TWO-DIMENSIONAL code certification',
    a49: 'Face authentication ',
    a50: 'Delayed visitors only ',
    a51: 'clear ',
    a52: 'Select operation ',
    a53: 'Entry Equipment',
    a54: 'Exit Equipment',
  },

  // Visitor module -- future visitor statistics record

  visitorFutureRecord: {
    a1: 'loading ',
    a2: 'Future Visitor Statistics record ',
    a3: 'display object ',
    a4: 'Show items ',
    a5: 'time ',
    a6: 'Initial date selection ',
    a7: 'Select End date ',
    a8: 'Query ',
    a9: 'Visitor Reservation Record List ',
    a10: 'Please select date or time ',
    a11: 'piece of data ',
    a12: 'time ',
    a13: 'Total Visitor Number  ',
    a14: 'Calls Number ',
    a15: 'Visiting Number ',
    a16: 'Departures Number ',
    a17: 'Finish Number ',
    a18: 'Stays Number ',
    a19: 'Cancel number ',
    a20: 'Missed  Number ',
    a21: 'Please select display object ',
    a22: 'Respondent Code ',
    a23: 'Respondent Name  ',
    a24: "Respondent's Company",
    a25: 'Visitor Number  ',
    a26: 'Application Time ',
    a27: 'The week ahead ',
    a28: 'Next Month ',
    a29: 'Tenant ',
    a30: 'Visitor Name ',
    a31: 'Visitor Company ',
  },

  // Visitor module -- Visitor statistics report
  visitorStatisticalReport: {
    a1: 'loading ',
    a2: 'Visitor Statistics Report ',
    a3: 'display object ',
    a4: 'Show items ',
    a5: 'time ',
    a6: 'Start Date ',
    a7: 'Select end date/time ',
    a8: 'Query ',
    a9: 'Visitor Company ',
    a10: 'Visitor Name ',
    a11: 'piece of data ',
    a12: 'time ',
    a13: 'Total Visitor Number  ',
    a14: 'Calls Number ',
    a15: 'Visiting Number ',
    a16: 'Departures Number ',
    a17: 'Finish Number ',
    a18: 'Stays Number ',
    a19: 'Cancel number ',
    a20: 'Missed  Number ',
    a21: 'Visitor mobile number ',
    a22: 'Visitor type ',
    a23: "Respondent's Company ",
    a24: 'Respondent Name  ',
    a25: 'Respondent code ',
    a26: 'Mobile phone number of respondent ',
    a27: 'Annual Statistics ',
    a28: 'Monthly Statistics ',
    a29: 'Daily Statistics ',
    a30: 'Tenant ',
  },

  // Visitor module -- visitor information

  visitorRecord: {
    a1: 'loading ',
    a2: 'Visitor Information Management ',
    a3: 'custom condition ',
    a4: 'Add ',
    a5: 'modify ',
    a6: 'Delete ',
    a7: 'Operation ',
    a8: 'Query ',
    a9: 'Visitor Information List ',
    a10: 'Please select date or time ',
    a11: 'piece of data ',
    a12: 'Visitor type ',
    a13: 'Visitor Name ',
    a14: 'Visitor mobile number ',
    a15: 'Visitor Id Type ',
    a16: 'Visitor Id Number ',
    a17: 'Visitor Company ',
    a18: 'Visitor department ',
    a19: 'Visitor duties ',
    a20: 'Visitor fixed number ',
    a21: 'Visitor wechat ',
    a22: 'Visitor address ',
    a23: 'Date ',
    a24: 'Remarks ',
    a25: 'Success ',
    a26: 'Failed ',
    a27: 'Please enter the visitor name',
    a28: 'Please enter the visitor phone number',
  },

  // Guest module -- guest blacklist information

  visitorBlacklist: {
    a1: 'loading ',
    a2: 'Visitor Blacklist Management ',
    a3: 'custom condition ',
    a4: 'Add ',
    a5: 'modify ',
    a6: 'Delete ',
    a7: 'Operation ',
    a8: 'Query ',
    a9: 'Visitor Blacklist ',
    a10: 'Please select date or time ',
    a11: 'piece of data ',
    a12: 'Visitor Name ',
    a13: 'Visitor mobile number ',
    a14: 'Visitor Id Type ',
    a15: 'Visitor Id Number ',
    a16: 'Edit date ',
    a17: 'Remarks ',
    a18: 'Success ',
    a19: 'Failed ',
    a20: 'Upload blacklist',
    a21: 'The import of visitor blacklist information failed.',
    a22: 'Are you sure you want to delete this information?',
  },

  // Visitor module -- the person's upper and lower level Settings
  upperAndLowerSetting: {
    a1: 'Personnel upper and lower level Settings ',
    a2: 'No. : ',
    a3: 'Personnel Information Table ',
    a4: 'Table of Superior Personnel ',
    a5: 'Table of Subordinates ',
    a6: 'Personnel number ',
    a7: 'name ',
    a8: 'owning ',
    a9: 'Name of superior ',
    a10: 'Text or not ',
    a11: 'Whether to send wechat ',
    a12: 'Name of subordinate ',
    a13: 'Select superior ',
    a14: 'Select subordinates ',
    a15: 'Delete ',
    a16: 'Delete all personnel ',
    a17: 'Settings ',
    a18: 'Success ',
    a19: 'Failed ',
    a20: 'piece of data ',
    a21: 'Query ',
    a22: 'custom condition ',
    a23: 'Loading....',
    a24: 'Please select a condition',
    a25: 'Name of superior personnel',
    a26: 'Name of subordinate personnel'
  },

  // Visitor module -- Visitor rule setting
  visitorRuleSetting: {
    a1: 'Whether to connect to wechat ',
    a2: 'wechat Connection URL',
    a3: 'Advance/delay value of travel time ',
    a4: 'Visitor Access Authorization Mode ',
    a5: "according to interviewer's authority ",
    a6: 'according to traffic mode ',
    a7: 'Visitor mode ',
    a8: 'Delete ',
    a9: 'Select traffic mode ',
    a10: 'Visitor authentication mode ',
    a11: 'Settings ',
    a12: 'Select traffic mode ',
    a13: 'Visitor Rule Settings ',
    a14: 'Success ',
    a15: 'Failed ',
    a16: 'Yes',
    a17: 'No',
    a18: 'traffic mode ',
    a20: 'piece of data ',
    a21: 'Query ',
    a22: 'custom condition ',
    a23: 'Are you sure you want to synchronize the visitor information on WeChat?',
    a24: 'Are you sure you want to synchronize the visitor blacklist with WeChat?',
    a25: 'Are you sure you want to synchronize dictionary parameters on WeChat?',
    a26: 'Visitor information WeChat synchronization ',
    a27: 'Visitor blacklist WeChat synchronization ',
    a28: 'Dictionary parameter WeChat synchronization ',
  },

  // Visitor module -- card
  visitor: {
    a1: 'time: ',
    a2: 'Visitor: ',
    a3: 'visited: ',
    a4: 'Cause: ',
    a5: 'status: ',
    a6: "SF Logistics",
    a7: 'Front Desk staff ',
    a8: 'meeting ',
    a9: 'Not visited ',
    a10: 'Ordinary visitor ',
    a11: ' type :',
  },

  //Visitor module - visitor details window
visitorDetailsModel:{
  a1: 'Basic information',
  a2: 'Interviewee information',
  a3: 'Applicant information',
  a4: 'Visitor information',
  a5: 'Access processing information',
  a6: 'Order number',
  a7: 'Order Status',
  a8: 'Visit status',
  a9: 'Access Date',
  a10: 'Application Date',
  a11: 'Visitor type',
  a12: 'Access reason',
  a13: 'Access notes',
  a14: 'Other remarks',
  a15: 'Management code',
  a16: 'Name',
  a17: 'Belonging',
  a18: 'Mobile phone number',
  a19: 'Certificate type',
  a20: 'Certificate number',
  a21: 'Visitor information',
  a22: 'Authentication information',
  a23: 'Access authority',
  a24: 'Operation',
  a25: 'Occurrence time',
  a26: 'Action type',
  a27: 'Action information',
  a28: 'Operator code',
  a29: 'Operator name',
  a30: 'Remarks',
  a31: 'Visitor code',
  a32: 'Authentication method',
  a33: 'Access mode',
  a34: 'Passage time',
  },

  // Data comparison trend group list
  dataCompareTrendList: {
    a1: 'loading.... ',
    a2: 'CSV export ',
    a3: 'reload ',
    a4: 'Date 1',
    a5: 'piece of data ',
    a6: 'data type ',
    a7: 'Device name ',
    a8: 'Signal name ',
    a9: 'unit ',
    a10: 'hour/date ',
    a11: 'status /30 points ',
  },

  sitecard: {
    a1: 'Electricity consumption in the past 14 days',
    a2: 'Water consumption in the past 14 days',
    a3: 'Number of floors/doors',
    a4: 'Number of floors/air conditioners ',
    a5: 'Location/equipment number ',
    a6: 'Type/number of equipment ',
    a7: 'Area/Number of light circuits ',
    a8: 'Number of Passway Equipment ',
    a9: 'Monthly statistics',
    a10: 'Number of floors/equipment',
    a11: 'Air conditioning operation number',
    a12: 'Number of passes per hour',
    a13: 'Number of device access certifications',
    a14: 'Number of passes and certifications in the past 14 days',
  },
  carThroughrecords: {
    a0: 'Loading....',
    a1: 'Car History ',
    a2: 'Name ',
    a3: 'License Plate ',
    a4: 'Start Date ',
    a5: 'End Date ',
    a6: 'Query ',
    a7: 'Record List ',
    a8: 'Please select date or time ',
    a9: 'License Plate Authentication Picture ',
    a10: 'Add Comment ',
    a11: 'Select Operation ',
    a12: 'Comments ',
    a13: 'Cancel ',
    a14: 'Confirm ',
    a15: 'Please enter the content....',
    a16: 'Piece of Data ',
    a17: 'Occurrence Date ',
    a18: 'Car Type ',
    a19: 'Name ',
    a20: 'License Plate ',
    a21: 'Body Temperature ',
    a22: 'Device Group Name ',
    a23: 'Device Name ',
    a24: 'Signal/Terminal Name ',
    a25: 'Building Name ',
    a26: 'Comments ',
    a27: 'Operation ',
    a28: 'Please enter name ',
    a29: 'Please enter license plate ',
    a30: 'Location ',
    a31: 'Mask ',
    a32: 'License plate picture reading failed, please try again later.',
    a33: 'Management Code ',
    a34: 'License Plate Authentication Record ',
    a35: 'Car Action',
    a36: 'Click to enlarge the picture',
    a37: 'Click to hide the picture',
  },

  // Controller management page

  controller: {
    a1: 'Controller Management ',
    a2: 'Building Information ',
    a3: 'Query ',
    a4: 'Terminal information ',
    a5: 'Equipment Information Record ',
    a6: 'Controller status Management ',
    a7: 'Controller status send record ',
    a8: 'Operating status ',
    a9: 'comment ',
    a10: 'Controller Operating State setting ',
    a11: 'Device type: ',
    a12: 'not started ',
    a13: 'Test ',
    a14: 'run ',
    a15: 'stop',
    a16: 'Update successful ',
    a17: 'Update failed ',
    a18: 'Normal',
    a19: 'Anomaly',
    a20: 'Unknown',
    a21: 'Confirm ',
    a22: 'Cancel ',
    a23: 'Get communication status ',
    a24: 'It takes some time to obtain the communication status of the controller in real time. Are you sure? ',
    a25: 'controller',
    a26: 'IP address',
    a27: 'Communication state',
    a28: 'Inspection day',
    a29: 'Personal number',
    a30: 'Site record reading management',
    a31: 'Do not read',
    a32: 'read',
    a33:'Save',
    a34:'Settings',
    a35:'Cancel',
    a36:'Parameter type',
    a37:'Read status',
    a38:'Read frequency (min/time)',
    a39:'last read time',
    a40:'Operation',
    a41:'Error reading frequency input parameter',
    a42:'Please enter an integer; If the input value is -1, the read state is set to no read.',
    a43:'LAST ID input parameter error',
    a44:'Please enter a positive integer; When the input value is 0, the record numbered 1 is read.',
    a45:'Are you sure you want to update the modification information?',
  },
  // 客户中心
  customerCenter: {
    a1: "Loading....",
    a2: "Customer Center ",
    a3: "edit ",
    a4: "List of Buildings ",
    a5: "Customer Information Setting",
    a6: "Cancelled ",
    a7: "confirm",
    a8: "Customer Name ",
    a9: "Please enter customer name ",
    a10: "Alias ",
    a11: "Please enter an alias ",
    a12: "Contact Information 1",
    a13: "Please enter your contact information ",
    a14: "Contact Information 2",
    a15: "Contact ",
    a16: "Please enter a contact ",
    a17: "address",
    a18: "Please enter your address ",
    a19: "Functional site ",
    a20: "Calendar Settings ",
    a21: "Add festival ",
    a22: "Site Information ",
    a23: "Site name ",
    a24: "Alias ",
    a25: "Administrative code ",
    a26: "Contact ",
    a27: "building Code",
    a28: "GW name ",
    a29: "Floor information ",
    a30: "modify ",
    a31: "Contract Information",
    a32: "No contract ",
    a33: "Preparing ",
    a34: "in service",
    a35: "Building Information Setting ",
    a36: "Building Name ",
    a37: "Please enter building name ",
    a38: "description",
    a39: "Please enter a description ",
    a40: "Site information Setting ",
    a41: "Please enter site name ",
    a42: "site alias ",
    a43: "Festival Settings ",
    a44: "Festival Name ",
    a45: "Please enter festival name ",
    a46: "Date ",
    a47: "Date designation ",
    a48: "Week specified ",
    a49: "Repeat ",
    a50: "Term designation ",
    a51: "Festival type ",
    a52: "holiday ",
    a53: "Special day 1",
    a54: "Special day 2",
    a55: "Special day 3",
    a56: "Special day 4",
    a57: "Calendar name update ",
    a58: "Calendar name ",
    a59: "Please enter calendar name ",
    a60: "Floor information Update ",
    a61: "Floor · Tenant Name ",
    a62: "Floor · Tenant Alias ",
    a63: "tenant address",
    a64: "Tenant Contact 1",
    a65: "Tenant Contact 2",
    a66: "Contract Information Updates ",
    a67: "No contract ",
    a68: "Preparing ",
    a69: "in service ",
    a70: "Jan",
    a71: "Feb",
    a72: "Mar",
    a73: "Apr",
    a74: "May",
    a75: "Jun",
    a76: "Jul",
    a77: "Aug",
    a78: "Sep",
    a79: "Oct",
    a80: "Nov",
    a81: "Dec",
    a82: "1st",
    a83: "2nd",
    a84: "3rd",
    a85: "4th",
    a86: "5th",
    a87: "6th",
    a88: "7th",
    a89: "8th",
    a90: "9th",
    a91: "10th",
    a92: "11th",
    a93: "12th",
    a94: "13th",
    a95: "14th",
    a96: "15th",
    a97: "16th",
    a98: "17th",
    a99: "18th",
    a100: "19th",
    a101: "20th",
    a102: "21st",
    a103: "22nd",
    a104: "23rd",
    a105: "24th",
    a106: "25th",
    a107: "26th",
    a108: "27th",
    a109: "28th",
    a110: "29th",
    a111: "30th",
    a112: "31st",
    a113: "Week 1 ",
    a114: "Week 2 ",
    a115: "Week 3 ",
    a116: "Week 4 ",
    a117: "Week 5 ",
    a118: "Monday ",
    a119: "Tuesday ",
    a120: "Wednesday ",
    a121: "Thursday ",
    a122: "Friday ",
    a123: "Saturday ",
    a124: "Sunday ",
    a125: "every week ",
    a126: "every month ",
    a127: "Operation ",
    a128: "Service name ",
    a129: "Service status",
    a130: "Please enter calendar name ",
    a131: "Festival deleted?",
    a132: "Confirm to delete the festival ",
    a133: "Please select a different state ",
    a134: "Please select a different state and modify it ",
    a135: "input search text",
    a136: "Contract information updated successfully",
    a137: "Failed to update contract information",
  },
  // 用户设置
  UserSettings: {
    a1: "Loading...",
    a2: "User Settings ",
    a3: "User type ",
    a4: "System User ",
    a5: "Customer user ",
    a6: "Customer ",
    a7: "Please select user group ",
    a8: "user group ID",
    a9: "Maximum number of users ",
    a10: "Users ",
    a11: "edit",
    a12: "Sites with permissions ",
    a13: "Set up site ",
    a14: "site ID",
    a15: "Building ",
    a16: "User List ",
    a17: "New user ",
    a18: "user name",
    a19: "grade",
    a20: "login account",
    a21: "Account Validity ",
    a22: "Password validity days",
    a23: "Authentication Code",
    a24: "Create user group ",
    a25: "Cancelled ",
    a26: "confirm",
    a27: "Group name: ",
    a28: "Access Permission Group selection ",
    a29: "Select HBS Administrator User Group ",
    a30: "Select Building Owner User Group ",
    a31: "Select replicate original user group ",
    a32: "Site Settings ",
    a33: "display",
    a34: "Operation ",
    a35: "Setup ",
    a36: "User information update ",
    a37: "User group name ",
    a38: "Please enter user group name ",
    a39: "Maximum number of users ",
    a40: "Please enter the maximum number of users ",
    a41: "ID",
    a42: "Please enter your account Id",
    a43: "account number",
    a44: "Please enter your account number ",
    a45: "Verification code ",
    a46: "Please enter the verification code ",
    a47: "Account validity ",
    a48: "Please enter account expiration date ",
    a49: "Email address ",
    a50: "Please enter email address ",
    a51: "To reset your password",
    a52: "Password 1",
    a53: "Please enter password 1",
    a54: "Password 2",
    a55: "Please enter password 2",
    a56: "Please enter the maximum number of users ",
    a57: "Please enter numbers ",
    a58: "Please enter a number greater than the number of current users less than 20 ",
    a59: "Please enter user group name ",
    a60: "Account ID cannot be empty ",
    a61: "Account cannot be empty ",
    a62: "Verification code cannot be empty ",
    a63: "Password validity period cannot be empty ",
    a64: "Password cannot be empty ",
    a65: "Please enter the correct email format ",
    a66: "Mailbox cannot be empty ",
    a67: "Building Owner ",
    a68: "tenant",
    a69: "Building Manager ",
    a70: "Permission site Settings ",
    a71: "site ID",
    a72: "Building ",
    a73: "User group permissions ",
    a74: "Menu Service Permissions ",
    a75: "Services: ",
    a76: "Out-of-privileges Service Menu ",
    a77: "add ↓",
    a78: "Services within Permission Menu ",
    a79: "delete ↑",
    a80: "reset ",
    a81: "Confirm ",
    a82: "Services ",
    a83: "Menu ",
    a84: "display",
    a85: "Update successful ",
    a86: "Update failed ",
    a87: "permanent",
  },
  // Generic description

  general: {
    a1: "Loading...",
    a2: "Setup ",
    a3: "add ",
    a4: "Modified ",
    a5: "delete",
    a6: "Success ",
    a7: "Failure ",
    a8: "Confirm ",
    a9: "Cancelled ",
    a10: "Edit ",
    a11: "Update ",
    a12: "Query ",
    a13: 'Sure you want to delete it? ',
    a14: 'Please select the item to delete',
    a15: "Can't be empty",
    a16: 'Options ',
    a17: 'values',
  },
  meeting: {
    m1: 'Meeting Management',
    m2: 'Finish In Advance',
    m3: 'Finish Delay',
    m4: 'Finish On Time',
    m5: 'Approval Failed',
    m6: 'Meeting Cancelled',
  },
  personnelIOStatistics: {
    a1: "Personnel statistics",
    a2: "Category",
    a3: "Company/Group",
    a4: "Please enter company/group",
    a5: "Nationality",
    a6: "Please enter nationality",
    a7: "Name",
    a8: "Please enter name",
    a9: "Start Date",
    a10: "End Date",
    a11: "Group by",
    a12: "Search",
    a13: "Personnel access statistics",
    a14: "Personnel access record",
    a15: "Download",
    a16: "Personnel Stay statistics",
    a17: "Personnel Stay record",
  },

    edge:{
      edge001:'Smart Gateway Management',
      edge002:'Type',
      edge003:'Name',
      edge004:'Place',
      edge005:'IP',
      edge006:'Port',
      edge007:'Account',
      edge008:'Password',
      edge009:'URL',
      edge010:'Check URL',
      edge011:'Notification Time Limit',
      edge012:'Check Mode',
      edge013:'Monitoring Mode',
      edge014:'Comments',
      edge015:'Description',
      edge016:'Build Code',
      edge017:'Build Name',
      edge018:'Connect Status',
      edge019:'Running Status',
      edge020:'Connect Date',
      edge021:'Collection Device',
      edge022:'Version',
      edge023:'Upgrade Date',
      edge024:'Action',
      edge025:'Add Smart Gateway',
      edge026:'Edit Smart Gateway',
      edge027:'Software Upgrade',
      edge028:'Upload Software File',
      edge029:'Please Enter Name',
      edge030:'Please Enter Place',
      edge031:'Please Enter IP',
      edge032:'Please Enter Port',
      edge033:'Please Enter Account',
      edge034:'Please Enter Password',
      edge035:'Please Enter URL',
      edge036:'Please Enter Check URL',
      edge037:'Please Enter Notification Time Limit',
      edge038:'Please Enter Check Mode',
      edge039:'Please Enter Monitoring Mode',
      edge040:'Please Enter Comments',
      edge041:'Please Enter Description',
      edge042:'Smart Gateway Setting',
      edge043:'Name length must be within 1 reach between 30',
      edge044:'Place length must be within 1 reach between 30',
      edge045:'IP length must be within 1 reach between 30',
      edge046:'Account length must be within 6 reach between 30',
      edge047:'Password length must be within 6 reach between 20',
      edge048:'URL length must be within 1 reach between 200',
      edge049:'Check URL length must be within 1 reach between 200',
      edge050:'Comments length must be within 1 reach between 800',
      edge051:'Description length must be within 1 reach between 200',
      edge052:'Are you sure you want to save smart gateway information?',
      edge053:'Are you sure you want to delete the selected smart gateway information?',
      edge054:'Upgrade',
      edge055:'File Type',
      edge056:'Select File',
      edge057:'Uploading',
      edge058:'Upload',
      edge059:'Upload successfully.',
      edge060:'Upload failed.',
      edge061:'Gateways/Controllers List',
      edge062:'File List',
      edge063:'NO OPERATION',
      edge064:'TEST                          ',
      edge065:'OPERATION                     ',
      edge066:'WORKING NOTIFICATION          ',
      edge067:'CONCENTRATED NOTIFICATION     ',
      edge068:'SUSPENSION                    ',
      edge069:'DELETED NOTIFICATION  ',
      edge070:'Please Enter Build Code',
      edge071:'Please Select Gateway Type',
      edge072:'Configure',
      edge073:'Gateway number configuration',
      edge074:'Original GWC No',
      edge075:'Original FLC / FMC No',
      edge076:'New GWC No',
      edge077:'New FLC / FMC No',
      edge078:'Action command',
      edge079:'Command type',
      edge080:'Select gateway / controller',
      edge081:'Select action command',
      edge082:'Select file',
      edge083:'OK',//Confirm
      edge084:'Upgrade',
      edge085:'File sending',
      edge086:'File sending and upgrading',
      edge087:'File deletion',
      edge088:'Please select the command type; Or please click the OK button to operate the corresponding command.',
      edge089:'Please click OK to upgrade the program.',
      edge090:'Please select a file',
      edge091:'To upgrade the program, select the program file',
      edge092:'Please enter a new GWC No',
      edge093:'Please enter a new FLC / FMC No',
      edge094:'The length of the new GWC number must be 8 digits.',
      edge095:'FLC number range must be 101-140.',
      edge096:'FMC number range must be 17-19.',
      edge097:'File Management',
      },

      linkage:{
        chooseSignal :" Please select signal point ",
        hour: "the hour",
        compare: "comparison",
        critical: "critical value",
        greater: "more than",
        less: "less than",
        equal: "equals",
        index: "Conditions for the serial number",
        condition: "conditions",
        relation :" Relation between conditions ",
        output: "output",
        addRuleAndInput :" Add rule input criteria item ",
        addruleCondition :" Add rule conditions ",
        addCondition :" addCondition ",
        and: "and",
        or: "or",
      },
    peopleDevice:{
        a1:"Device communication failure message ",
        a2:"Keep sending the data ",
        a3:"I know ",
        a4:"If you continue sending data, you may fail. Are you sure to continue sending data?",
    },
    faceErrorMessage:{
        a1:"Face failure ",
        a2:"I know ",
    },
}