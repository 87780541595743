// 拓扑图设置
module.exports = {
    topologySet:{
        a1: 'Topology map settings',
        a2: 'Add topology node',
        a3: 'Please select a node',
        a4: 'Node name:',
        a5: 'Device list',
        a6: 'Device edit',
        a7: 'Grouping setting',
        a8: 'Add',
        a9: 'Group edit',
        a10: 'Delete',
        a11: 'Release',
        a12: 'Add node',
        a13: 'Cancel',
        a14: 'Confirm',
        a15: 'Name:',
        a16: 'Please enter the name',
        a17: 'Energy type:',
        a18: 'Electricity',
        a19: 'Gas',
        a20: 'Water',
        a21: 'Oil',
        a22: 'Others',
        a23: 'Association point type:',
        a24: 'Virtual point',
        a25: 'Association point:',
        a26: 'Node modification',
        a27: 'Metering point',
        a28: 'Edit equipment list',
        a29: 'Increase ↓',
        a30: 'Delete ↑',
        a31: 'Add group',
        a32: 'Device name',
        a33: 'Name:',
        a34: 'The name already exists',
        a35: 'Confirm to delete the node',
        a36: 'Operation point',
        a37: 'Please enter the device name',
        a38: 'Name',
        a39: 'Topology diagram',
        a40: 'Daily data',
        a41: 'Monthly data',
        a42: 'Annual data',
        a43: 'No subordinate equipment is set'
    }
}