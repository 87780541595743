import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCN from './zhCN/index'
import zhTW from './zhTW/index'
import jaJP from './jaJP/index'
import enUS from './enUS/index'


Vue.use(VueI18n)

const messages = {
    'zhcn':zhCN,
    'enus':enUS,
    'zhtw':zhTW,
    'jajp':jaJP
}
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'zhcn',
    messages,
    silentTranslationWarn: true
})

export default i18n
