// 测速模块
module.exports = {
    velocityMeasurement:{
        a1: '加載中…',
        a2: '車輛超速統計',
        a3: '設備',
        a4: '設備詳情',
        a5: '關閉',
        a6: '設備名稱',
        a7: '測速記錄',
        a8: '測速點',
        a9: '車牌號碼',
        a10: '速度：',
        a11: '査詢',
        a12: '車牌號碼：',
        a13: '車牌顏色：',
        a14: '車身顏色：',
        a15: '測速地點：',
        a16: '抓拍時間：',
        a17: '車速：',
        a18: '車輛類型：',
        a19: '詳情',
        a20: '條數據',
        a21: '車輛圖片',
        a22: '抓拍時間',
        a23: '車輛類型',
        a24: '行車方向',
        a25: '東->西',
        a26: '西->東',
        a27: '南->北',
        a28: '北->南',
        a29: '東南->西北',
        a30: '西北->東南',
        a31: '東北->西南',
        a32: '西南->東北',
        a33: '測速地點',
        a34: '速度',
        a35: '車牌號碼',
        a36: '操作',
        a37: '資料統計',
        a38: '測速記錄清單',
        a39: '超速速度設定',
        a40: '確定',
        a41: '超速速度：',
        a42: '未超速',
        a43: '超速'
    }
}