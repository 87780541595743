// 测速模块
module.exports = {
    velocityMeasurement:{
        a1: '加载中...',
        a2: '车辆超速统计',
        a3: '设备',
        a4: '设备详情',
        a5: '关闭',
        a6: '设备名称',
        a7: '测速记录',
        a8: '测速点',
        a9: '车牌号码',
        a10: '速度：',
        a11: '查询',
        a12: '车牌号码：',
        a13: '车牌颜色：',
        a14: '车身颜色：',
        a15: '测速地点：',
        a16: '抓拍时间：',
        a17: '车速：',
        a18: '车辆类型：',
        a19: '详情',
        a20: '条数据',
        a21: '车辆图片',
        a22: '抓拍时间',
        a23: '车辆类型',
        a24: '行车方向',
        a25: '东->西',
        a26: '西->东',
        a27: '南->北',
        a28: '北->南',
        a29: '东南->西北',
        a30: '西北->东南',
        a31: '东北->西南',
        a32: '西南->东北',
        a33: '测速地点',
        a34: '速度',
        a35: '车牌号码',
        a36: '操作',
        a37: '数据统计',
        a38: '测速记录列表',
        a39: '超速速度设置',
        a40: '确定',
        a41: '超速速度：',
        a42: '未超速',
        a43: '超速'
    }
}