import axios from 'axios'
import { Modal } from 'ant-design-vue'
import store from '@/store'
import { getToken, removeToken } from '@/utils/auth'

const service = axios.create({
  // baseURL: "new",
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30 * 100000
})


service.interceptors.request.use(
  config => {
    if (config.url.indexOf('download') > -1) {
      config.responseType = 'blob'  // 服务请求类型
    }
    if (store.getters.token) {
      config.headers['Authorization'] = `BIVALE ${getToken('Admin-Token')}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    // console.log('window',window.vm.$t("login.Q7"));
    const res = response.data
    // console.log('reject', res)
    if (res.errorCode == '64') {
      
      Modal.error({
        title: window.vm.$t("login.Q18"),
        content: res.msg,//window.vm.$t("login.Q12"),//'账户或密码错误',
        okText: window.vm.$t("login.Q9"),
        width: 320,
        maskClosable: true,
        centered: true,
      })
      return Promise.reject(new Error(res.msg))
    } else if (res.errorCode == '65') {
      Modal.error({
        title: window.vm.$t("login.Q18"),
        content:  window.vm.$t("login.Q13"),//'该账户已在其他地方登录',
        width: 320,
        maskClosable: true,
        centered: true,
      })
      return Promise.reject(new Error(res.msg))
    } else if (res.errorCode == '68') {
      Modal.error({
        title: window.vm.$t("login.Q18"),
        content:   res.msg,//window.vm.$t("login.Q14"),//'登录账户登录次数超过限制',
        width: 320,
        maskClosable: true,
        centered: true,
      })
      return Promise.reject(new Error(res.msg))
    } else if (res.errorCode == 'EE') {
      Modal.error({
        title: window.vm.$t("login.Q18"),
        content:  window.vm.$t("login.Q15"),//'系统错误',
        width: 320,
        maskClosable: true,
        centered: true,
      })
      return Promise.reject(new Error(res.msg))
    } else if (res.errorCode == '66') {
      Modal.error({
        title: window.vm.$t("login.Q18"),
        content:  window.vm.$t("login.Q16"),//'密码过期',
        width: 320,
        maskClosable: true,
        centered: true,
      })
      return Promise.reject(new Error(res.msg))
    } else {
      return res
    }
  },
  error => {
    console.log('reject', error.response)
    let data = error.response.data
    console.log('data', data)
    if (data.status == 401) {
      Modal.error({
        title: window.vm.$t("login.Q10"),
        content:  window.vm.$t("login.Q17"),//'您的登录状态已经失效，请重新登录',
        okText: window.vm.$t("login.Q11"),
        centered: true,
        zIndex:9999,
        onOk: () => {
          removeToken('Admin-Token');
          location.reload();
          sessionStorage.clear();
        }
      })
    }
    return Promise.reject(error)
  }
)

export default service
