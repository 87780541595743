module.exports = {
    eqct:{
        a1	:'車場配寘資訊',
        a2	:'設備映射關係設定',
        a3	:'設備區域資訊',
        a4	:'車場人員組織架構',
        a5	:'介面資訊',
        a6	:'終端品牌',
        a7	:'用戶名',
        a8	:'密碼',
        a9	:'API路徑',
        a10	:'介面版本',
        a11	:'同步訪問',
        a12	:'APP ID',
        a13	:'APP Key',
        a14	:'設備資訊',
        a15	:'設備編號',
        a16	:'設備名稱',
        a17	:'設備IP',
        a18	:'設備閘道IP',
        a19	:'設備子網路遮罩',
        a20	:'選擇門',
        a21	:'關聯終端類型',
        a22	:'設備/終端名稱',
        a23	:'關聯道閘終端',
        a24	:'移除轉換',
        a25	:'全部移除',
        a26	:'移除轉換',
        a27	:'區域初始化',
        a28	:'區域名稱',
        a29	:'設備清單',
        a30	:'本地人員組織架構資訊',
        a31	:'車場人員組織架構資訊',
        a32	:'設備轉換',
        a33	:'設備類型',
        a34	:'認證終端清單',
        a35	:'選擇認證終端',
        a36	:'關聯終端名稱',
        a37	:'人臉終端名稱',
        a38	:'序號',
        a39	:'設備品牌',
        a40	:'是否帶測溫',
        a41	:'車場區域設置',
        a42	:'設備映射清單',
        a43	:'全選',
        a44	:'關聯設備',
        a45	:'設備/終端類型',
        a46	:'出入口設備',
        a47	:'訪客設備',
        a48	:'考勤設備',
        a49	:'清除提示',
        a50:'是',
        a51:'否',
        a52:'請輸入協力廠商介面帳戶。',
        a53:'請輸入協力廠商介面帳戶密碼。',
        a54:'請輸入協力廠商介面地址。',
        a55:'請選擇終端品牌。',
        a56:'請輸入協力廠商介面版本。',
        a57:'車場道閘設備資訊設定',
        a58:'閘機設備清單',
        a59:'確認要取消該設備的轉換資訊嗎？',
        a60:'確認要取消所有的轉換資訊嗎？',
        a61:'確認要取消所選擇的設備的轉換資訊嗎？',
        a62:'確認要保存所設定的資訊嗎？',
        a63:'出入類型',
        a64:'站點名稱',
        a65:'協力廠商介面資訊',
        a66:'確認要取消該設備的轉換資訊嗎？',
    }
}