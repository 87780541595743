
import Layout from '@/layout'

const asyncRoutes = [
  // {
  //   path: '/book',
  //   name: 'book',
  //   component: Layout,
  //   redirect: '/book/create',
  //   meta: { title: '图书管理', icon: 'documentation', roles: ['admin', 'editor'] },
  // },
  { path: '*', hidden: true, redirect: '/404' }
]

export default asyncRoutes
