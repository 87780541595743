// 看板 -- 日文
module.exports = {
    Kanban:{
        a1:'年度累計',
        a2:'月度累計',
        a3:'日度累計',
        a4:'ループ比',
        a5:'リアルタイム電力',
        a6:'年度KPI',
        a7:'月度KPI',
        a8:'炭素排出',
        a9:'クリーンエネルギー発電',
        a10:'看板設定',
        a11:'リアルタイム電力',
        a12:'総電力',
        a13:'表示',
        a14:'データ型',
        a15:'表示レベル',
        a16:'関連サイト',
        a17:'サイトアドレス',
        a18:'データ比較',
        a19:'分割規則使用',
        a20:'サイト分割規則を使用する',
        a21:'建築区分規則を統一する',
        a22:'顧客セグメント化規則を統一的に使用する',
        a23:'「サイト分割ルールを使用する」を選択した場合、対応するサイトに分割ルールが設定されていない場合は、建物の分割ルールを使用します。データ型が「日」を選択した場合、建築区分規則を統一して使用する。',
        a24:'「サイト分割ルールを使用する」を選択した場合、対応するサイトに分割ルールが設定されていない場合は、顧客の分割ルールが使用されます。データ型が「日」を選択した場合、顧客セグメント化ルールを統合して使用します。',
        a25:'毎月ショートカット全選択設定',
        a26:'毎時ショートカット全選択設定',
        a27:'ショートカット選択',
        a28:'厳重注意',
        a29:'一般的な警告',
        a30:'回復した',
        a31:'回復していない',
        a32:'戻る',
        a33:'毎日の炭素排出',
        a34:'毎日の使用量',
        a35:'注意',
        a36:'KPI規則',
        a37:'設定する',
        a38:'看板パラメータ設定',
        a39:"会社情報を追加する",
        a40:"会社情報の編集",
        a41:"エネルギー使用量信号点",
        a42:"クリーンエネルギー発電量信号点",
        a43:"会社名",
        a44:"色を表示する",
        a45:"信号点を選択して追加する",
        a46:"信号点を削除する",
        a47:"編集した情報を保存することを確認しますか？",
        a48:"削除を確認しますか？",
        a49:"選択された信号点はすでに存在します。選択を繰り返さないでください。",
        a50:"お客様/建物/サイト情報を選択してください！",
        a51:"詳細アドレスを入力してください。",
        a52:"詳細なアドレス長は1 ~ 100の間でなければなりません。",
        a53:"詳細アドレスに特殊文字を含めることはできません。",
        a54:'会社情報の削除',
        a55:'KPIルールの選択',
        a56:'グループアドレス',
        a57:'発電量',
        a58:'グループ全体の電力使用量',
        a59:'グループ全体の炭素排出量',
        a60:'目標達成${num}家',
        a61:'目標未達成${num}家',
        a62:'グループ月次KPI',
        a63:'各社のKPI完成状況',
        a64:'目標達成',
        a65:'目標未達成',
        a66:'峰谷分析',
        a67:'エネルギー比較',
        a68:'電力使用ランキング',
        a69:'大気圧',
        a70:'降水量',
        a71:'視認性',
        a72:'体感温度',
        a73:'紫外線',
        a74:'相対湿度',
        a75:'北風',
        a76:'北東風',
        a77:'北西風',
        a78:'南風',
        a79:'南東風',
        a80:'南西風',
        a81:'西風',
        a82:'東風',
        a83:'レベル',
        a84: 'グループ年度KPI',
        a85: 'グループ毎日KPI',
        a86: '取得していない',
        a87: '時間進捗',
    }
}