// 照明路由表
export default [
    {
        path: '/homepage/light/lightDetails', //照明模块--电灯详情页
        component: () => import('@/views/lightDetails/lightDetails'),
    },
    {
        path: '/homepage/light',//照明主页
        component: () => import('@/views/light/lightLayout'),
        children: [
            
            {
            path: '/homepage/light/equipment', //照明模块--设备页
            name: 'LIGHTING_FAC_EQUIPMENT',
            component: () => import('@/views/light/equipment'),
            meta: { hidden: true }
            },            
            {
            path: '/homepage/light/controllSeason', //照明模块--设备季节切换控制
            name: 'LIGHTING_FAC_KISETU',
            component: () => import('@/views/common/controllSeason'),
            },
            {
            path: '/homepage/light/seasonGroup', //照明模块--设备季节切换控制组设置
            name: 'LIGHTING_FAC_KISETU_GR',
            component: () => import('@/views/common/seasonGroup'),
            },
            {
            path: '/homepage/light/controllIntermittent', //照明模块--设备间歇运行控制
            name: 'LIGHTING_FAC_KANKETU',
            component: () => import('@/views/common/controllIntermittent'),
            },
            {
                path: '/homepage/light/timeSetupTrendChart', //照明模块--时间设置趋势图
                name: 'LIGHTING_timeSetupTrendChart',
                component: () => import('@/views/common/trend/timeSetupTrendChart'),
            },
            {
                path: '/homepage/light/terminalEditor', //照明模块--联动控制设备编辑
                name: 'LIGHTING_terminalEditor',
                component: () => import('@/views/common/terminalEditor'),
            },
            {
            path: '/homepage/light/schedule', //照明模块--设备时间表控制组
            name: 'LIGHTING_FAC_SCEDULE_GR',
            component: () => import('@/views/common/controllGroup'),
            },
            {
            path: '/homepage/light/controllGroupIntermittent', //照明模块--设备间歇运行控制组设置
            name: 'LIGHTING_FAC_KANKETU_GR',
            component: () => import('@/views/common/controllGroupIntermittent'),
            },
            {
            path: '/homepage/light/lightTimetable', //照明模块--时间表控制
            name: 'LIGHTING_FAC_SCEDULE',
            component: () => import('@/views/light/lightTimetable'),
            },
            {
            path: '/homepage/light/lightRunTimetable', //照明模块--执行时间表控制
            name: "LIGHTING_FAC_RUN_SCEDULE",
            component: () => import('@/views/light/lightRunTimetable'),
            },
            {
            path: '/homepage/light/linkage', //照明模块--设备联动控制组设置
            name: 'LIGHTING_FAC_KIKIRENDOU_GR',
            component: () => import('@/views/common/controllGroupLinkage'),
            },
            {
            path: '/homepage/light/linkageCtrl', //照明模块--设备联动控制
            name: 'LIGHTING_FAC_KIKIRENDOU',
            component: () => import('@/views/common/controllLinkage'),
            },
            {
            path: '/homepage/light/alarm',
            name: 'LIGHTING_FAC_ALARM',
            component: () => import('@/views/common/alarm'),//照明模块--警报
            },
            {
            path: '/homepage/light/controlRecord',
            name: 'LIGHTING_FAC_HISTORY',
            component: () => import('@/views/door/controlRecord'),//照明模块--操作控制记录（与门禁使用同一页面）
            },
            {
                path: '/homepage/light/userRecords',
                name: 'LIGHTING_userRecords',
                component: () => import('@/views/light/userRecord'),
            },
            {
                path: '/homepage/light/trendGR', //照明模块--趋势组
                name: 'LIGHTING_FAC_TOLEND_GR',
                component: () => import('@/views/common/trend/controllGroupTrend'),
            },
            {
                path: '/homepage/light/timeTrendGR', //照明模块--时间趋势组
                name: 'LIGHTING_FAC_TIME_TLEND_GR',
                component: () => import('@/views/common/trend/controllGroupTimeTrend'),
            },
            {
                path: '/homepage/light/dataCompareTrend', //照明模块--数据比较趋势
                name: 'LIGHTING_FAC_DATA_HIKAKU_TLEND',
                component: () => import('@/views/common/trend/dataCompareTrend'),
            },  
            {
                path: '/homepage/light/dataCompareTrendList', //照明模块--数据比较趋势列表
                name: 'LIGHTING_compareTrendList',
                component: () => import('@/views/common/trend/dataCompareTrendList'),
            },
            {
                path: '/homepage/light/timeSetupTrend', //照明模块--时间设置趋势
                name: 'LIGHTING_FAC_TIME_TLEND',
                component: () => import('@/views/common/trend/timeSetupTrend'),
            }, 
            {
                path: '/homepage/light/timeSetupTrendList', //照明模块--数据比较趋势列表
                name: 'LIGHTING_timeSetupTrendList',
                component: () => import('@/views/common/trend/timeSetupTrendList'),
            }, 
            {
                path: '/homepage/light/loggingData', //照明模块--数据输出
                name: 'LIGHTING_FAC_DATA_OUTPUT',
                component: () => import('@/views/common/loggingData'),
            },          
        ],
    },   
]