import request from '@/utils/request'
import fileDownload from '@/utils/fileDownload'


// 设置管理--客户中心--获取所有客户列表信息
export function getcustlist() {
    return request({
        url: "bivale/sysmanage/customcenter/custlist",
        method: 'get',
    });
}

// 设置管理--客户中心--获取对应id的客户详细信息
export function getcustinfo(id) {
    return request({
        url: `bivale/sysmanage/customcenter/custinfo/${id}`,
        method: 'get',
    });
}

// 设置管理--客户中心--获取对应id的建筑物列表 
export function getbuildinglist(id) {
    return request({
        url: `bivale/sysmanage/customcenter/buildinglist/${id}`,
        method: 'get',
    });
}

// 设置管理--客户中心--更新客户信息
export function custupdate(params) {
    return request({
        url: "bivale/sysmanage/customcenter/custupdate",
        method: 'post',
        data: params,
    });
}

// 设置管理--建筑物详情页--根据建筑物ID获取站点列表与控制器信息
export function getsitelistandctrl(id) {
    return request({
        url: `bivale/sysmanage/customcenter/sitelistandctrl/${id}`,
        method: 'get',
    });
}

// 设置管理--建筑物详情页--获取所有的合同列表
export function getcontractlist() {
    return request({
        url: "bivale/sysmanage/customcenter/contractlist",
        method: 'get',
    });
}

// 设置管理--建筑物详情页--根据站点获取签署的合同列表
export function getsitecontractlist(params) {
    return request({
        url: "bivale/sysmanage/customcenter/sitecontractlist",
        method: 'get',
        params,
    });
}

// 设置管理--建筑物详情页--根据站点id获取楼层信息 
export function gettenantlist(siteId) {
    return request({
        url: `bivale/sysmanage/customcenter/tenantlist/${siteId}`,
        method: 'get',
    });
}

// 设置管理--建筑物详情页--根据站点id获取日历列表
export function getcalendarlist(params) {
    return request({
        url: "bivale/calender/siteList",
        method: 'get',
        params,
    });
}

// 设置管理--建筑物详情页--修改建筑物信息
export function buildingupdate(id, params) {
    return request({
        url: `bivale/sysmanage/customcenter/buildingupdate/${id}`,
        method: 'post',
        params,
    });
}

// 设置管理--建筑物详情页--修改站点信息
export function siteupdate(params) {
    return request({
        url: "bivale/sysmanage/customcenter/siteupdate",
        method: 'post',
        params,
    });
}

// 设置管理--建筑物详情页--根据当前月份获取节日数据 
export function monthholiday(params) {
    return request({
        url: "bivale/calender/holiday/monthholiday",
        method: 'get',
        params,
    });
}

// 设置管理--建筑物详情页--更新日历列表名称
export function updateName(params) {
    return request({
        url: "bivale/calender/update/name",
        method: 'post',
        params,
    });
}

// 设置管理--建筑物详情页--节日更新或新增节日
export function updateFestival(params) {
    return request({
        url: "bivale/calender/holiday/update",
        method: 'post',
        params,
    });
}

// 设置管理--建筑物详情页--节日删除
export function deleteFestival(params) {
    return request({
        url: "bivale/calender/holiday/delete",
        method: 'post',
        params,
    });
}

// 设置管理--建筑物详情页--楼层信息更新
export function tenantupdate(params) {
    return request({
        url: "bivale/sysmanage/customcenter/tenantupdate",
        method: 'post',
        params,
    });
}

// 设置管理--建筑物详情页--合同信息更新
export function addContract(params) {
    return request({
        url: "bivale/sysmanage/customcenter/addContract",
        method: 'post',
        params,
    });
}

// 设置管理--用户设置--获取登录的系统用户所属分组
export function getsysusergr() {
    return request({
        url: "bivale/sysmanage/user/sysusergr",
        method: 'get',
    });
}

// 设置管理--用户设置--获取所有的客户用户分组
export function getclientallusergr(params) {
    return request({
        url: "bivale/sysmanage/user/clientallusergr",
        method: 'get',
        params,
    });
}

// 设置管理--用户设置--获取用户分组具有权限访问的站点
export function getusergrwithsite(params) {
    return request({
        url: "bivale/sysmanage/user/usergrwithsite",
        method: 'get',
        params,
    });
}

// 设置管理--用户设置--获取用户分组下的用户列表
export function getUserList(params) {
    return request({
        url: "bivale/sysmanage/user/list",
        method: 'get',
        params,
    });
}

// 设置管理--用户设置--根据用户组获取其权限下的建筑物、站点、楼层
export function allbuildingwithsiteandtenant(params) {
    return request({
        url: "bivale/sysmanage/user/allbuildingwithsiteandtenant",
        method: 'get',
        params,
    });
}

// 设置管理--用户设置--更新分组基础信息 
export function updategr(params) {
    return request({
        url: "bivale/sysmanage/user/updategr",
        method: 'post',
        params,
    });
}

// 设置管理--用户设置--用户列表更新
export function userUpdate(params) {
    return request({
        url: "bivale/sysmanage/user/setuser",
        method: 'post',
        params,
    });
}

// 设置管理--用户设置--用户密码修改 added by li_yj on 2021-10-09
export function userPasswordUpdate(params) {
    return request({
        url: "bivale/sysmanage/user/update",
        method: 'post',
        params,
    });
}

// 设置管理--用户设置--设置用户分组访问站点的权限
export function setusergrsiteandsitegrpermision(body, query) {
    return request({
        url: "bivale/sysmanage/user/setusergrsiteandsitegrpermision",
        method: 'post',
        data: body,
        params: query,
    });
}

// 设置管理--权限站点设置--根据服务合同获取客户用户分组具有权限访问的服务（菜单）（权限内） 
export function usergrservcies(params) {
    return request({
        url: "bivale/sysmanage/user/usergrservcies",
        method: 'get',
        params,
    });
}

// 设置管理--权限站点设置--获取合同具有的服务（菜单（所有）
export function siteservcies(params) {
    return request({
        url: "bivale/sysmanage/user/siteservcies",
        method: 'get',
        params,
    });
}

// 设置管理--用户设置--设置用户具有权限访问的服务（菜单）
export function setusergrservices(body, query) {
    return request({
        url: "bivale/sysmanage/user/setusergrservices",
        method: 'post',
        data: body,
        params: query,
    });
}


// 设置管理--更新用户信息added by li_yj on 2022-9-20
export function updateUserInfo(body) {
    return request({
      url: "bivale/sysmanage/user/update/info",
      method: 'post',
      data: body,
    });
  }
