// 通用设备路由表
export default [
    {
        path: '/homepage/facility/facilityDetails', //通用设备模块--设备详情页
        component: () => import('@/views/facility/detail/facilityDetails'),
    },
    {
        path: '/homepage/facility/facilitySignalDetails', //通用设备模块--设备信号点详情页
        component: () => import('@/views/facility/detail/facilitySignalDetails')
    },
    {
        path: '/homepage/facility',//通用设备模块主页
        component: () => import('@/views/facility/facilityLayout'),
        children: [
            {
                path: '/homepage/facility/equipment', //通用设备模块--通用设备模块主页
                name: 'FACILITIES_FAC_EQUIPMENT',
                component: () => import('@/views/facility/equipment'),
            },
            {
                path: '/homepage/facility/seasonInfoEditor', //通用设备模块--季节设置
                name: 'FACILITIES_seasonInfoEditor',
                component: () => import('@/views/air-condition/seasonInfoEditor'),
            },
            {
                path: '/homepage/facility/controllSeason', //通用设备模块--设备季节切换控制
                name: 'FACILITIES_FAC_KISETU',
                component: () => import('@/views/common/controllSeason'),
            },
            {
                path: '/homepage/facility/seasonGroup', //通用设备模块--设备季节切换控制组设置
                name: 'FACILITIES_FAC_KISETU_GR',
                component: () => import('@/views/common/seasonGroup'),
            },
            {
                path: '/homepage/facility/controllGroupIntermittent', //通用设备模块--设备间歇运行控制组设置
                name: 'FACILITIES_FAC_KANKETU_GR',
                component: () => import('@/views/common/controllGroupIntermittent'),
            },
            {
                path: '/homepage/facility/controllIntermittent', //通用设备模块--设备间歇运行控制
                name: 'FACILITIES_FAC_KANKETU',
                component: () => import('@/views/common/controllIntermittent'),
            },
            {
                path: '/homepage/facility/terminalEditor', //通用设备模块--设备编辑
                name: 'FACILITIES_terminalEditor',
                component: () => import('@/views/common/terminalEditor'),
            },
            {
                path: '/homepage/facility/loggingData', //通用设备模块--数据输出
                name: 'FACILITIES_FAC_DATA_OUTPUT',
                component: () => import('@/views/common/loggingData'),
            },
            {
                path: '/homepage/facility/loggingData2', //通用设备模块--数据输出
                name: 'FACILITIES_FAC_DATA',
                component: () => import('@/views/common/loggingData'),
            },
            {
                path: '/homepage/facility/dataCompareTrend', //通用设备模块--数据比较趋势
                name: 'FACILITIES_FAC_DATA_HIKAKU_TLEND',
                component: () => import('@/views/energy/trend/eneTrendChart'),
                meta: { hidden: true }
            },  
            {
                path: '/homepage/facility/dataCompareTrendList', //通用设备模块--数据比较趋势列表
                name: 'FACILITIES_compareTrendList',
                component: () => import('@/views/common/trend/dataCompareTrendList'),
            },
            {
                path: '/homepage/facility/timeSetupTrend', //通用设备模块--时间设置趋势
                name: 'FACILITIES_FAC_TIME_TLEND',
                // component: () => import('@/views/common/trend/timeSetupTrend'),
                component: () => import('@/views/energy/trend/eneTimeTrendChart'),
                meta: { hidden: true }
            }, 
            {
                path: '/homepage/facility/timeSetupTrendList', //通用设备模块--数据比较趋势列表
                name: 'FACILITIES_timeSetupTrendList',
                component: () => import('@/views/common/trend/timeSetupTrendList'),
            },           
            {
                path: '/homepage/facility/scheduleGR', //通用设备模块--时间表
                name: 'FACILITIES_FAC_SCEDULE_GR',
                component: () => import('@/views/common/controllGroup'),
            },
            {
                path: '/homepage/facility/schedule', //通用设备模块--时间表
                name: 'FACILITIES_FAC_SCEDULE',
                component: () => import('@/views/light/lightTimetable'),
            },
            {
                path: '/homepage/facility/lightRunTimetable', //通用设备模块--执行时间表控制
                name: "FACILITIES_FAC_RUN_SCEDULE",
                component: () => import('@/views/light/lightRunTimetable'),
            },
            {
                path: '/homepage/facility/linkageGR', //通用设备模块--设备联动控制组设置
                name: 'FACILITIES_FAC_KIKIRENDOU_GR',
                component: () => import('@/views/common/controllGroupLinkage'),
            },
            {
                path: '/homepage/facility/trendGR', //通用设备模块--趋势组
                name: 'FACILITIES_FAC_TOLEND_GR',
                // component: () => import('@/views/common/trend/controllGroupTrend'),
                component: () => import('@/views/energy/trend/eneTrendGr'),
            },
            {
                path: '/homepage/facility/timeTrendGR', //通用设备模块--时间趋势组
                name: 'FACILITIES_FAC_TIME_TLEND_GR',
                // component: () => import('@/views/common/trend/controllGroupTimeTrend'),
                component: () => import('@/views/energy/trend/eneTimeTrendGr'),
                meta: { hidden: true }
            },
            {
                path: '/homepage/facility/linkage', //通用设备模块--设备联动控制
                name: 'FACILITIES_FAC_KIKIRENDOU',
                component: () => import('@/views/common/controllLinkage'),
            },
            {
                path: '/homepage/facility/comfortGR', //通用设备模块--设备舒适控制组设置
                name: 'FACILITIES_FAC_SAITEKI_GR',
                component: () => import('@/views/common/controllGroupComfort'),
            },
            {
                path: '/homepage/facility/comfort', //通用设备模块--设备舒适控制
                name: 'FACILITIES_FAC_SAITEKI',
                component: () => import('@/views/common/controllComfort'),
            },
            {
                path: '/homepage/facility/controlRecord',
                name: 'FACILITIES_FAC_HISTORY',
                // component: () => import('@/views/door/controlRecord'),//通用设备模块--记录 0.0
                component: () => import('@/views/energy/history'),
            },
            {
                path: '/homepage/facility/controlRecord',
                name: 'FACILITIES_FAC_EDGE_TREND',
                // component: () => import('@/views/door/controlRecord'),//通用设备模块--记录 0.0
                component: () => import('@/views/energy/history'),
            },
            {
                path: '/homepage/facility/edge/history',
                name: 'FACILITIES_FAC_EDGE_HISTORY',
                component: () => import('@/views/facility/edge/edgeEndHistory'),//通用设备模块--边缘终端记录
            },   
            {
                path: '/homepage/facility/alarm',
                name: 'FACILITIES_FAC_ALARM',
                component: () => import('@/views/energy/alarm'),//通用设备模块--警报
            },     
          
            {
                path: '/homepage/facility/edgeEndSetting',
                name: 'FACILITIES_FAC_EQUIPMENT_END_SETTING',
                component: () => import('@/views/facility/edge/edgeEndSetting'),    //通用设备模块--边缘终端设置
            },
            {
                path: '/homepage/facility/edgeEndMapSetting',
                name: 'FACILITIES_FAC_EQUIPMENT_MAP_SETTING',
                component: () => import('@/views/facility/edge/edgeEndMapSetting'),//通用设备模块--边缘终端设备映射关系设置
            },
            {
                path: '/homepage/facility/convertSetting',
                name: 'FACILITIES_FAC_EQUIPMENT_CONVERT_SETTING',
                component: () => import('@/views/facility/setting/equipmentConvertSetting'),//通用设备模块--设备转换设置
            },      
            {
                path: '/homepage/facility/cameraSetting',
                name: 'FACILITIES_FAC_EQUIPMENT_CAMERA_SETTING',
                component: () => import('@/views/facility/setting/equipmentCameraSetting'),//通用设备模块--设备摄像头设置
            }, 
            {
                path: '/homepage/facility/camera', //通用设备模块----摄像机管理
                name: 'FACILITIES_FAC_EDGE_VID',
                component: () => import('@/views/monitoring/cameras'),
                meta: { hidden: true }
            },
            {
                path: '/homepage/facility/record', //通用设备模块--录像机管理
                name: 'FACILITIES_FAC_EDGE_RECORDER',
                component: () => import('@/views/monitoring/recorder'),
                meta: { hidden: true }
            },
            
        ],
    },  
]