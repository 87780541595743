
export default [
  {
    path: '/homepage/passageway', //出入口模块--门禁主页
    component: () => import('@/views/passageway/passagewayLayout'),
    meta: { hidden: true },
    children: [
       //设备 1.1
      {
        path: '/homepage/passageway/equipment', //出入口模块--设备页
        name: 'IOW_EQUIPMENT',
        component: () => import('@/views/door/equipment'),
        meta: { hidden: true }
      },
      //警报 2.1
      {
        path: '/homepage/passageway/alarm', //出入口模块--报警页
        name: 'IOW_ALARM',
        component: () => import('@/views/door/alarm'),
        meta: { hidden: true }
      },
      //记录 3.1
      {
        path: '/homepage/passageway/throughRecords', //出入口模块--通行记录
        name: 'IOW_HISTORY',
        component: () => import('@/views/door/throughRecords'),
        meta: { hidden: true }
      },
      {
        path: '/homepage/passageway/throughRecords', //出入口模块--通行记录
        name: 'IOW_RIREKI',
        component: () => import('@/views/door/throughRecords'),
        meta: { hidden: true }
      },
      //记录 3.2
      {
        path: '/homepage/passageway/carRecord', //出入口模块--车辆记录
        name: 'IOW_RIREKI_CAR',
        component: () => import('@/views/passageway/carThroughRecords'),
        meta: { hidden: true }
      },
      //状况显示 4.1
      {
        path: '/homepage/passageway/strandedPersonnel', //出入口模块--滞留人员显示
        name: 'IOW_ZANRYU',
        component: () => import('@/views/passageway/relationship'),
        meta: { hidden: true }
      },
      //4.2
      {
        path: '/homepage/passageway/indoorPersonne', //出入口模块--室内人员显示
        name: 'IOW_ZAISITU',
        component: () => import('@/views/passageway/relationship'),
        meta: { hidden: true }
      },

      //数据 5.1
      {
        path: '/homepage/passageway/dataOutput', //出入口模块--数据输出
        name: 'IOW_DATA',
        component: () => import('@/views/door/reportData'),
        meta: { hidden: true }
      },

      {
        path: '/homepage/passageway/dataOutput', //出入口模块--数据输出
        name: 'IOW_DATAOUTPUT',
        component: () => import('@/views/door/reportData'),
        meta: { hidden: true }
      },
      //5.2人员统计
      {
        path: '/homepage/passageway/personnelStatistics', //出入口模块--数据输出
        name: 'IOW_PERSONNEL_STATISTICS',
        component: () => import('@/views/passageway/personnelStatistics'),
        meta: { hidden: true }
      },
      
      
      
       //5.3 室内人员统计
       {
        path: '/homepage/passageway/personnelStayStatistics', //出入口模块--数据输出
        name: 'IOW_PERSONNEL_STAY_STATISTICS',
        component: () => import('@/views/passageway/personnelStayStatistics'),
        meta: { hidden: true }
      },

      //设置 6.1
      {
        path: '/homepage/passageway/facialInfo', //出入口模块--人脸终端信息
        name: 'IOW_FACE_NINSHOU',
        component: () => import('@/views/door/facialInfo'),
        meta: { hidden: true }
      },
      //6.2
      {
        path: '/homepage/passageway/cardNumber', //出入口模块--车牌管理
        name: 'IOW_CAR_PLATENUMBER',
        component: () => import('@/views/door/plateNumber'),
        meta: { hidden: true }
      },
      //6.3
      {
        path: '/homepage/passageway/carPark', //出入口模块--车场设置
        name: 'IOW_CAR_PARK',
        component: () => import('@/views/door/parkingLotSet'),
        meta: { hidden: true }
      },

      //6.4
      {
        path: '/homepage/passageway/doorTimetable', //出入口模块--门禁时间表
        name: 'IOW_NYUTAI_SCE',
        component: () => import('@/views/door/doorTimetable'),
        meta: { hidden: true }
      },
      //6.5
      {
        path: '/homepage/passageway/trafficPatterns', //出入口模块--通行模式
        name: 'IOW_TUKO_PATTERN',
        component: () => import('@/views/door/trafficPatterns'),
        meta: { hidden: true }
      },
      //6.6
      {
        path: '/homepage/passageway/cardReaderAlarm', //出入口模块--读卡器报警
        name: 'IOW_CR_ALERM',
        component: () => import('@/views/door/cardReaderAlarm'),
        meta: { hidden: true }
      },
      //6.7
     
      {
        path: '/homepage/passageway/area', //出入口模块--滞留人员显示
        name: 'IOW_AREA',
        component: () => import('@/views/passageway/relationship'),
        meta: { hidden: true }
      },
      //6.8
      {
        path: '/homepage/passageway/stayArea', //出入口模块--室内人员显示
        name: 'IOW_AREA_STAY',
        component: () => import('@/views/passageway/relationship'),
        meta: { hidden: true }
      },
    ]
  }
]