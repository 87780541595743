<!--主页侧边栏--信息通知页-->
<template>
  <div id="infrombutton" @click="goInfo" :title="$t('sidebar.e1')">
    <div :class="bgimage">
      <div class="new" v-show="isNew">NEW</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bgimage(){
      let route = this.$route.matched[1].path;
      switch (route) {
        case undefined:
          return this.normalImage;
          break;
        case "/info":
          return 'selectedImage'
          break;
        default:
          return 'normalImage'
          break;
      }
    }
  },
  methods: {
    goInfo() {
      this.isNewS = false;
      this.$store.commit("menu/loading", false);
      this.$router.push({
        path: "/info/Home",
      });
    },
  },
};
</script>

<style scoped>
#infrombutton {
  width: 80px;
  height: 80px;
  flex-shrink:0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
}
.new {
  position: absolute;
  top: -5px;
  right: -10px;
  font-size: 12px;
  color: #fff;
  padding: 0px 5px;
  border-radius: 10px;
  background-color: #EA6914;
}
.normalImage{
  position: relative;
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.2);
  border-radius: 50%;
  background-color: #f2f4f7;
  background-image: url('../../../../public/images/sidebar/infrom_normal.png');
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: 13px 13px;
  transition: 400ms ease-out;
}
.normalImage:hover{
  box-shadow: 0 0px 7px #7682ce;
}
.selectedImage{
  position: relative;
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-radius: 50%;
  background-color: #7682ce;
  background-image: url('../../../../public/images/sidebar/infrom_selected.png');
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: 13px 13px;
  transition: 400ms ease-out;
}
</style>