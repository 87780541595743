<!--主页侧边栏--设置管理-->
<template>
  <div id="setbutton" :title="$t('sidebar.d1')" @click="goSetHome">
    <div :class="bgimage"></div>
  </div>
</template>

<script>
export default {
  methods: {
    goSetHome() {
      this.$store.commit("menu/loading", false);
      this.$router.push({
        path: "/set/Home",
      });
    },
  },
  computed: {
    bgimage(){
      let route = this.$route.matched[1].path;
      switch (route) {
        case undefined:
          return this.normalImage;
          break;
        case "/set":
          return 'selectedImage'
          break;
        default:
          return 'normalImage'
          break;
      }
    },
  }
}
</script>

<style scoped>
#setbutton {
  width: 80px;
  height: 80px;
  flex-shrink:0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
}
.normalImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.2);
  border-radius: 50%;
  background-color: #f2f4f7;
  background-image: url('../../../../public/images/sidebar/set_normal.png');
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: 12px 12px;
  transition: 400ms ease-out;
}
.normalImage:hover{
  box-shadow: 0 0px 7px #7682ce;
}
.selectedImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-radius: 50%;
  background-color: #7682ce;
  background-image: url('../../../../public/images/sidebar/set_selected.png');
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: 12px 12px;
  transition: 400ms ease-out;
}
</style>