module.exports = {
    distribution:{
        a1:'顯示模塊',
        a2:'請選擇綁定用戶',
        a3:'綁定用戶',
        a4:'頁面刷新時間（分鐘）',
        a5:'請輸入頁面刷新時間（分鐘）',
        a6:'標題',
        a7:'請輸入標題',
        a8:'主題',
        a9:'請選擇主題',
        a10:'發佈資訊',
        a11:'設備KEY',
        a12:'請輸入設備KEY',
        a13:'訪問地址',
        a14:'請輸入訪問地址',
        a15:'服務埠',
        a16:'請輸入服務埠',
        a17:'服務IP',
        a18:'請輸入服務IP',
        a19:'設備埠',
        a20:'請輸入設備埠',
        a21:'設備IP',
        a22:'請輸入設備IP',
        a23:'配寘資訊',
        a24:'複製連結',
        a25:'設備訪問碼',
        a26:'荧幕類型',
        a27:'設備種類',
        a28:'所屬',
        a29:'名稱',
        a30:'設備編號',
        a31:'基本資訊',
        a32:'普通大屏',
        a33:'獨立部署服務大屏',
        a34:'普通小屏',
        a35:'平板荧幕',
        a36:'人臉終端荧幕',
        a37:'廣告屏',
        a38:'主題一',
        a39:'主題二',
        a40:'請輸入標題',
        a41:'請輸入數據重繪時間',
        a42:'請選擇顯示模塊',
        a43:'複製成功！',
        a44:'確認要保存所設定的發佈資訊參數嗎？',
        a45:'確認要保存所設定的配寘資訊嗎？',
        a46:'添加設備',
        a47:'請輸入設備名稱！',
        a48:'請選擇荧幕類型',
        a49:'控制器',
        a50:'請選擇控制器',
        a51:'設備名稱長度必須在1到30之間',
        a52:'人臉終端資訊',
        a53:'圖片埠',
        a54:'背景圖片',
        a55:'全部删除',
        a56:'删除',
        a57:'選擇圖片',
        a58:'上傳圖片',
        a59:'設備現場圖',
        a60:'讀取圖片',
        a61:'讀取全部圖片',
        a62:'只能上傳png和jpg格式的圖片！',
        a63:'圖片大小不能超過300KB！',
        a64:'確認要保存所設定的資訊嗎？',
        a65:'確認要上傳所選擇的圖片嗎？',
        a66:'確認要删除該圖片嗎？',
        a67:'確認要删除所有圖片嗎？',
        a68:'設備設定',
        a69:'',
    }
}