// 拓扑图设置
module.exports = {
    topologySet:{
        a1:'トポロジ設定',
        a2:'トポロジノードの追加',
        a3:'ノードを選択してください',
        a4:'ノード名:',
        a5:'デバイスリスト',
        a6:'デバイス編集',
        a7:'グループ設定',
        a8:'追加',
        a9:'グループ編集',
        a10:'削除',
        a11:'解除',
        a12:'新規ノード',
        a13:'キャンセル',
        a14:'確認',
        a15:'名称：',
        a16:'名前を入力してください',
        a17:'エネルギータイプ：',
        a18:'電気',
        a19:'ガス',
        a20:'水',
        a21:'油',
        a22:'その他',
        a23:'関連点タイプ：',
        a24:'仮想ポイント',
        a25:'関連点：',
        a26:'ノード修正',
        a27:'計量点',
        a28:'デバイスリスト編集',
        a29:'追加↓',
        a30:'↑を削除',
        a31:'グループを追加する',
        a32:'デバイス名',
        a33:'名称：',
        a34:'この名前は既に存在します',
        a35:'ノードの削除を確認する',
        a36:'演算点',
        a37:'デバイス名を入力してください',
        a38:'名前',
        a39:'トポロジー図',
        a40:'日データ',
        a41:'月データ',
        a42:'年間データ',
        a43:'下位デバイスが設定されていない'
    }
}