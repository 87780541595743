// 空调设备页
module.exports = {
    airconditiondDevice:{
        a1: '加载中....',
        a2: '图标',
        a3: '表格',
        a4: '展开',
        a5: '收起',
        a6: '批量设置',
        a7: '正常',
        a8: '异常',
        a9: '上下限警报',
        a10: '制冷',
        a11: '制热',
        a12: '送风',
        a13: '抽湿',
        a14: '低风',
        a15: '中风',
        a16: '高风',
        a17: '详情页',
        a18: '关闭',
        a19: '确认',
        a20: '开关',
        a21: '空调运行模式',
        a22: '空调风速',
        a23: '温度设置',
        a24: '开',
        a25: '关',
        a26: '室温',
        a27: '维护或故障状态',
        a28: '空调不可设置',
        a29: '关机状态',
        a30: '空调模式不可设置',
        a31: '条数据',
        a32: '空调风速不可设置',
        a33: '风速',
        a34: '维护',
        a35: '设备名称',
        a36: '设备状态',
        a37: '开关',
        a38: '设置温度',
        a39: '室内温度',
        a40: '操作',
        a41: '批量设置失败列表',
        a42: '请选择空调设备',
        a43: '未选择空调设备',
        a44: '选择操作',
        a45: '设置',
        a46: '空调设置'
    }
}