// 门禁模块 -- 详情页
module.exports = {
    doorDetails:{
        a1: '加载中....',
        a2: '设备名称',
        a3: '设备ID',
        a4: '设备GUID',
        a5: '左入口',
        a6: '名称',
        a7: '客户名称',
        a8: '类型',
        a9: '功能站点',
        a10: '楼层·租户名称',
        a11: '取消',
        a12: '确认',
        a13: '项目',
        a14: '状态·操作',
        a15: '状态变化时间',
        a17: '选择',
        a18: '时间表设置',
        a19: '设备详情信息',
        a20: '时间表',
        a21: '日历',
        a22: '门开有效时间',
        a23: '门开超时检测时间',
        a24: '有无声音',
        a25: '有声音',
        a26: '无声音',
        a27: '电气锁动作次数',
        a28: '电气锁动作次数超过监视',
        a29: '电气锁动作次数上限',
        a30: '确定要进行设定内容注册吗?',
        a31: '门状态',
        a32: '异常开',
        a33: '暂时解锁',
        a34: '开超过',
        a35: '入侵',
        a36: '上解锁状态',
        a37: '无循环',
        a38: '通行状态',
        a39: '运行状态',
        a40: '接受禁止状态',
        a41: '非法通行警报',
        a42: '设备异常警报',
        a43: '入方向门开',
        a44: '出方向门开',
        a45: '入方向通行禁止',
        a46: '出方向通行禁止',
        a47: '连续通过设置',
        a48: '火灾信号输出',
        a49: '卡NG信号输出',
        a50: '指定目标',
        a51: '门时间表',
        a52: '卡认证时间表',
        a53: '机械警备时间表',
        a54: '模式1',
        a55: '模式2',
        a56: '模式3',
        a57: '模式4',
        a58: '模式5',
        a59: '初始值为“卡模式”，如需更改请新设置',
        a60: '卡模式',
        a61: '自由模式',
        a62: '重复模式',
        a63: '进入卡/退出自由模式',
        a64: '进入自由/退出卡模式',
        a65: '设备详情信息',
        a66: '时间表',
        a67: '日历',
        a68: '数字键超时时间(秒)',
        a69: '数字键终端有无',
        a70: '警戒温度下限(℃)',
        a71: '警戒温度上限(℃)',
        a72: '警戒温度异常是否开门',
        a73: '否',
        a74: '是',
        a75: '认证模式',
        a76: '运转状态',
        a77: '非法卡',
        a78: '时间表恢复',
        a79: '周期自动复原',
        a80: '有效',
        a81: '无效',
        a82: '周期恢复时间1',   
        a83: '周期恢复时间2',
        a84: '周期恢复时间3',
        a85: '周期恢复时间4',
        a86: '周期恢复时间5',
        a87: '设备IP',
        a89: '设备子网掩码',
        a90: '设备网关',
        a91: '操作',
        a92: '关联设备网络信息',
        a93: '操作成功，开门按钮将在5秒钟后恢复关闭状态',
        a94: '开门操作失败',
        a95: '当日时间表',
        a96: '当日',
        a97: '次日时间表',
        a98: '次日',
        a99: '时间表手动恢复',
        a100: '设置',
        a101: '操作状态/故障·报警',
        a102: '时',
        a103: '分',
        a104: '名称更新',
        a105: '名称修改',
        a106: '关闭',
        a107: '设置',
        a108: '请输入名称',
        a109: '操作状态',
        a110: '设备详情',
        a111: '时间表设置',
        a112: '通行权限',
        a113: '通行人员',
        a114: '解锁',
        a115: '复位',
        a116: '上锁',
        a117: '状态',
        a118: '(0-99秒)',
        a119: '(0-3599秒)',
        a120: '请选择通行模式',
        a121: '(0~999,999次)',
        a122: '时间没有被正确指定，请再次指定',
        a123: '输入搜索文本',
        a124: '已注册的认证终端',
        a125: '显示内容',
        a126: '通行模式复制',
        a127: '认证终端编辑',
        a128: '复制原通行模式',
        a129: '条数据',
        a130: '企业名称',
        a131: '建筑名称',
        a132: '楼层·租户名称',
        a133: '设备名称',
        a134: '认证终端名称',
        a135: '出入类型',
        a136: '警戒设定',
        a137: '无',
        a138: '有',
        a139: '时间表名称',
        a140: '日历名称',
        a141: '未选择通行模式',
        a142: '请选择需要复制的通行模式',
        a143: '人员信息列表',
        a144: '当前页导出',
        a145: '全部导出',
        a146: '人员信息导出',
        a147: '删除',
        a148: '人员编辑',
        a149: '选择操作',
        a150: '管理代码',
        a151: '姓名',
        a152: '部门',
        a153: '卡编号',
        a154: '国籍',
        a155: '注册日期',
        a156: '关联建筑',
        a157: '所属1',
        a158: '所属2',
        a159: '所属3',
        a160: '所属4',
        a161: '所属5',
        a162: '操作',
        a163: '确定要删除'
    }
}