// 门禁模块--设置--通行模块设置--通行权限人员信息组件 lht 2023-3-31
module.exports = {
    accessStaffInfo:{
        a1: '通行権限者情報',
        a2: 'キャンセル',
        a3: 'ロード中...',
        a4: '個人情報リスト',
        a5: '現在のページのエクスポート',
        a6: 'すべてエクスポート',
        a7: '個人情報のエクスポート',
        a8: '表示内容',
        a9: '管理コード',
        a10: '氏名',
        a11: '部門',
        a12: 'カード番号',
        a13: '国籍',
        a14: '登録日',
        a15: '関連建築',
        a16: '所属1',
        a17: '所属2',
        a18: '所属3',
        a19: '所属4',
        a20: '所属5'
    }
}