// 自动报表
module.exports = {
    automaticReport:{
        a1:'自動レポート設定',
        a2:'レポートリスト',
        a3:'追加',
        a4:'レポートを選択してください',
        a5:'毎日エクスポート時間',
        a6:'テンプレートをエクスポート',
        a7:'信号点リスト',
        a8:'起動',
        a9:'設定',
        a10:'ファイルは7部のみ保存',
        a11:'キャンセル',
        a12:'確認',
        a13:'レポートの追加',
        a14:'レポート名',
        a15:'レポート・タイプ',
        a16:'名前を入力してください',
        a17:'レポート名変更',
        a18:'信号点リスト',
        a19:'ファイルリスト',
        a20:'レポートの削除の決定',
        a21:'ダウンロード',
        a22:'操作'
    }
}