// 水冷空调路由表
export default [
    {
        path: '/homepage/bsdsac', //水冷空调模块--水冷空调主页
        component: () => import('@/views/air-conditionwater/airConditionWaterLayout'),
        meta: { hidden: true },
        children: [
          {
            path: '/homepage/air-conditionwater/equipment', //水冷空调模块--设备页
            name: 'BSDSAC_FAC_EQUIPMENT',
            component: () => import('@/views/air-conditionwater/equipment'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/air-conditionwater/alarm',
            name: 'BSDSAC_FAC_ALARM',
            component: () => import('@/views/door/alarm'),//水冷空调模块--警报（与门禁使用同一页面）
          },
          {
            path: '/homepage/air-conditionwater/controlRecord',
            name: 'BSDSAC_FAC_HISTORY',
            // component: () => import('@/views/door/controlRecord'),//水冷空调模块--操作控制记录（与门禁使用同一页面）
            component: () => import('@/views/energy/history'),
            },
          {
            path: '/homepage/air-conditionwater/timeSetupTrendChart', //水冷空调模块--时间设置趋势图
            name: 'BSDSAC_timeSetupTrendChart',
            component: () => import('@/views/common/trend/timeSetupTrendChart'),
        },
        {
            path: '/homepage/air-conditionwater/terminalEditor', //水冷空调模块--联动控制设备编辑
            name: 'BSDSAC_terminalEditor',
            component: () => import('@/views/common/terminalEditor'),
        },
        {
        path: '/homepage/air-conditionwater/schedule', //水冷空调模块--设备时间表控制组
        name: 'BSDSAC_FAC_SCEDULE_GR',
        component: () => import('@/views/common/controllGroup'),
        },
        {
        path: '/homepage/air-conditionwater/controllGroupIntermittent', //水冷空调模块--设备间歇运行控制组设置
        name: 'BSDSAC_FAC_KANKETU_GR',
        component: () => import('@/views/common/controllGroupIntermittent'),
        },
        {
        path: '/homepage/air-conditionwater/lightTimetable', //水冷空调模块--时间表控制
        name: 'BSDSAC_FAC_SCEDULE',
        component: () => import('@/views/light/lightTimetable'),
        },
        {
        path: '/homepage/air-conditionwater/lightRunTimetable', //水冷空调模块--执行时间表控制
        name: "BSDSAC_FAC_RUN_SCEDULE",
        component: () => import('@/views/light/lightRunTimetable'),
        },
        {
        path: '/homepage/air-conditionwater/linkage', //水冷空调模块--设备联动控制组设置
        name: 'BSDSAC_FAC_KIKIRENDOU_GR',
        component: () => import('@/views/common/controllGroupLinkage'),
        },
        {
        path: '/homepage/air-conditionwater/linkageCtrl', //水冷空调模块--设备联动控制
        name: 'BSDSAC_FAC_KIKIRENDOU',
        component: () => import('@/views/common/controllLinkage'),
        },
          {
            path: '/homepage/air-conditionwater/trendGR', //水冷空调模块--趋势组
            name: 'BSDSAC_FAC_TOLEND_GR',
            component: () => import('@/views/common/trend/controllGroupTrend'),
          },
          {
              path: '/homepage/air-conditionwater/timeTrendGR', //水冷空调模块--时间趋势组
              name: 'BSDSAC_FAC_TIME_TLEND_GR',
              component: () => import('@/views/common/trend/controllGroupTimeTrend'),
          },
          {
              path: '/homepage/air-conditionwater/dataCompareTrend', //水冷空调模块--数据比较趋势
              name: 'BSDSAC_FAC_DATA_HIKAKU_TLEND',
              component: () => import('@/views/common/trend/dataCompareTrend'),
          },  
          {
              path: '/homepage/air-conditionwater/dataCompareTrendList', //水冷空调模块--数据比较趋势列表
              name: 'BSDSAC_compareTrendList',
              component: () => import('@/views/common/trend/dataCompareTrendList'),
          },
          {
              path: '/homepage/air-conditionwater/timeSetupTrend', //水冷空调模块--时间设置趋势
              name: 'BSDSAC_FAC_TIME_TLEND',
              component: () => import('@/views/common/trend/timeSetupTrend'),
          }, 
          {
              path: '/homepage/air-conditionwater/timeSetupTrendList', //水冷空调模块--数据比较趋势列表
              name: 'BSDSAC_timeSetupTrendList',
              component: () => import('@/views/common/trend/timeSetupTrendList'),
          }, 
          {
              path: '/homepage/air-conditionwater/loggingData', //水冷空调模块--数据输出
              name: 'BSDSAC_FAC_DATA_OUTPUT',
              component: () => import('@/views/common/loggingData'),
          },

        ],
    },
    {
    path: '/homepage/air-conditionwater/airConditionWaterDetails', //水冷空调模块--水冷空调详情页
    component: () => import('@/views/air-conditionwaterDetails/airConditionWaterDetails'),
    }, 
]