module.exports = {
    cleanEnergyAnalysis:{
        a1:'加載中….',
        a2:'清潔能源',
        a3:'詳情數據',
        a4:'比例分析',
        a5:'能源對比',
        a6:'自定義起始月',
        a7:'査詢',
        a8:'CSV匯出',
        a9:'請選擇條件進行査詢',
        a10:'電量(kWh)',
        a11:'占比(%)',
        a12:'累積電量(kWh)'
    }
}