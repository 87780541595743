// 空调设备页
module.exports = {
    airconditiondDevice:{
        a1: 'ロード中...',
        a2: 'アイコン',
        a3: 'テーブル',
        a4: '展開',
        a5: 'しまって',
        a6: '一括設定',
        a7: '正常',
        a8: '異常',
        a9: '上下限警報',
        a10: '冷房',
        a11: '暖房',
        a12: '送風',
        a13: '抽湿',
        a14: '低風',
        a15: '中風',
        a16: '高風',
        a17: '詳細ページ',
        a18: '閉じる',
        a19: '確認',
        a20: 'スイッチ',
        a21: '空調運転モード',
        a22: '空調風速',
        a23: '温度設定',
        a24: '開',
        a25: '関',
        a26: '室温',
        a27: 'メンテナンスまたは故障状態',
        a28: 'エアコンの設定不可',
        a29: 'シャットダウン状態',
        a30: '空調モード設定不可',
        a31: 'ストライプデータ',
        a32: '空調風速設定不可',
        a33: '風速',
        a34: 'メンテナンス',
        a35: 'デバイス名',
        a36: '設備状態',
        a37: 'スイッチ',
        a38: '設定温度',
        a39: '室内温度',
        a40: '操作',
        a41: '一括設定失敗リスト',
        a42: 'エアコンを選んでください',
        a43: '空調設備が選択されていない',
        a44: '選択操作',
        a45: '設定',
        a46: '空調設定'
    }
}