// 清洁能源设置
module.exports = {
    cleanEnergySetting:{
        a1:'クリーンエネルギー設定',
        a2:'グループを選択してください',
        a3:'総発電量',
        a4:'並列電力量',
        a5:'自家用電力量',
        a6:'総使用電力量',
        a7:'電力網の電源を入れる',
        a8:'グループを選択してください',
        a9:'リセット',
        a10:'設定',
        a11:'グループを追加する',
        a12:'キャンセル',
        a13:'確認',
        a14:'グループ名',
        a15:'名前を入力してください',
        a16:'グループ名変更',
        a17:'レポートの削除の決定'
    }
}