module.exports = {
    eqct:{
        a1	:'Yard configuration information',
        a2	:'Device Mapping Relationship Settings',
        a3	:'Equipment area information',
        a4	:'Organizational structure of parking lot personnel',
        a5	:'Interface information',
        a6	:'Terminal brand',
        a7	:'user name',
        a8	:'password',
        a9	:'API Path',
        a10	:'Interface version',
        a11	:'Synchronous access',
        a12	:'APP ID',
        a13	:'APP Key',
        a14	:'Device Information',
        a15	:'Equipment No',
        a16	:'Equipment name',
        a17	:'Device IP',
        a18	:'Device gateway IP',
        a19	:'Device subnet mask',
        a20	:'Select Door',
        a21	:'Associated terminal type',
        a22	:'Device/terminal name',
        a23	:'Associated barrier terminal',
        a24	:'Remove Transform',
        a25	:'remove all ',
        a26	:'Remove Transform',
        a27	:'Zone initialization',
        a28	:'Area Name',
        a29	:'Equipment list',
        a30	:'Local Personnel Organization Structure Information',
        a31	:'Organization structure information of parking lot personnel',
        a32	:'Device conversion',
        a33	:'Equipment type',
        a34	:'List of authentication terminals',
        a35	:'Select authentication terminal',
        a36	:'Associated terminal name',
        a37	:'Face terminal name',
        a38	:'serial number',
        a39	:'Equipment brand',
        a40	:'Temperature measurement',
        a41	:'Regional setting of parking lot',
        a42	:'Device Mapping List',
        a43	:'Select All',
        a44	:'Associated Devices',
        a45	:'Equipment/terminal type',
        a46	:'Entrance and exit equipment',
        a47	:'Visitor equipment',
        a48	:'Attendance equipment',
        a49	:'Clear Prompt',
        a50: 'Enable',
        a51: 'Disable',
        a52: 'Please enter the third-party interface account.',
        a53: 'Please enter the password of the third-party interface account.',
        a54: 'Please enter the third-party interface address.',
        a55: 'Please select the terminal brand.',
        a56: 'Please enter the version of the third-party interface.',
        a57: 'Information setting of parking lot road gate',
        a58: 'Gate equipment list',
        a59: 'Are you sure you want to cancel the conversion information of the device?',
        a60: 'Are you sure you want to cancel all conversion information?',
        a61: 'Are you sure you want to cancel the conversion information of the selected device?',
        a62: 'Are you sure you want to save the set information?',
        a63: 'Access type',
        a64: 'Site name',
        a65: 'Third party interface information',
        a66: 'Are you sure you want to cancel the conversion information of this device?',
    }
}