// 信息发布路由表
export default [
    {
        path: '/homepage/distribution/equipmentDetail', //信息发布模块--设备详情页
        component: () => import('@/views/distribution/equipmentDetail'),
    },
    {
        path: '/homepage/distribution/faceEquipmentDetail', //信息发布模块--设备详情页
        component: () => import('@/views/distribution/faceDeviceBgImageDetail'),
    },
    {
        path: '/homepage/distribution',//信息发布模块主页
        component: () => import('@/views/distribution/distributionLayout'),
        children: [

            {
                path: '/homepage/distribution/equipment', //信息发布模块--信息发布模块主页
                name: 'INFO_FAC_EQUIPMENT',
                component: () => import('@/views/distribution/equipment'),
            },

            {
                path: '/homepage/distribution/equipment/icon', //信息发布模块--信息发布模块主页
                name: 'INFO_FAC_ICON',
                component: () => import('@/views/distribution/equipment'),
            },

            {
                path: '/homepage/distribution/equipment/table', //信息发布模块-设备管理（表格）
                name: 'INFO_FAC_HYO',
                component: () => import('@/views/distribution/equipmentList'),
                // meta: { hidden: true }
            },
            
            {
                path: '/homepage/distribution/scheduleGR', //信息发布模块--时间表
                name: 'INFO_FAC_SCEDULE_GR',
                component: () => import('@/views/common/controllGroup'),
            },
            {
                path: '/homepage/distribution/schedule', //信息发布模块--时间表
                name: 'INFO_FAC_SCEDULE',
                component: () => import('@/views/light/lightTimetable'),
            },
            {
                path: '/homepage/distribution/lightRunTimetable', //信息发布模块--执行时间表控制
                name: "INFO_FAC_RUN_SCEDULE",
                component: () => import('@/views/light/lightRunTimetable'),
            },
          
            {
                path: '/homepage/distribution/history',
                name: 'INFO_FAC_HISTORY',
                component: () => import('@/views/energy/history'),//记录
            },
            {
                path: '/homepage/distribution/alarm',
                name: 'INFO_FAC_ALARM',
                component: () => import('@/views/energy/alarm'),//信息发布模块--警报
            },    
            {
                path: '/homepage/distribution/information',
                name: 'INFO_FAC_RIREKI',
                component: () => import('@/views/energy/history'),//记录
            },
            {
                path: '/homepage/distribution/notification',
                name: 'INFO_FAC_KEIHOU',
                component: () => import('@/views/energy/alarm'),//信息发布模块--警报
            },     
        ],
    },  
]