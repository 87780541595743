// 能效KPI设置
module.exports = {
    analyseKPISet:{
        a1:'エネルギー効率KPI設定',
        a2:'検索',
        a3:'年度:',
        a4:'ルール：',
        a5:'比較年度：',
        a6:'月：',
        a7:'比較月：',
        a8:'同期比',
        a9:'週末比率均等化',
        a10:'祝祭日の割合の均等化',
        a11:'照会',
        a12:'年間総目標値：',
        a13:'目標値:',
        a14:'実際の使用量：',
        a15:'総目標値：',
        a16:'週末と平日の割合：',
        a17:'休日と勤務日数の比率：',
        a18:'週末と平日の割合：',
        a19:'休日と平日量の比：',
        a20:'リセット',
        a21:'1、実際の電力使用量：現在の日付までに選択された計量点の累計電力使用量',
        a22:'2、予想使用電力量：現在年度の予想総使用電力量、終了年度は実際の使用電力量',
        a23:'3、使用電力前年比：総使用電力量の昨年実際の使用電力量に対する比率を予測する',
        a24:'4、節電目標設定：現在の年間省エネ目標を設定し、昨年の実際の総使用電力量より節約割合を設定する',
        a25:'5、目標値設定：現在の年間の総使用電力量目標を設定し、節電目標設定と同期し、2つの設定方式はできるだけ1つのを採用し',
        a26:'6、設定日：現在の年の現在の目標値の設定時間',
        a27:'7、終了年度目標値は設定不可',
        a28:'備考',
        a29:'ルール管理',
        a30:'自動更新',
        a31:'起動アラート',
        a32:'設定',
        a33:'閉じる',
        a34:'ルールリスト',
        a35:'追加',
        a36:'規則名',
        a37:'開始月',
        a38:'設定',
        a39:'削除',
        a40:'年度',
        a41:'実際の使用量',
        a42:'予定使用量',
        a43:'使用量は前年同期比',
        a44:'節約目標設定（%）',
        a45:'目標値',
        a46:'設定日',
        a47:'月',
        a48:'目標値占有率',
        a49:'ロック',
        a50:'実際の占有率',
        a51:'日付',
        a52:'ルール名を入力してください',
        a53:'年度規則がない',
        a54:'規則管理により規則を追加してください',
        a55:'節約目標値',
        a56:'節約目標値フォーマットが間違っています。数字を入力してください。',
        a57:'節約目標値は100%を超えてはならない。',
        a58:'目標値はゼロ未満ではありません。',
        a59:'目標値フォーマットが間違っています。数字を入力してください。',
        a60:'目標値の占有率はゼロ未満ではありません。',
        a61:'目標値の占有率フォーマットが間違っています。数字を入力してください。',
    }
}