module.exports = {
    distribution:{
        a1:'显示模块',
        a2:'请选择绑定用户',
        a3:'绑定用户',
        a4:'页面刷新时间(分钟)',
        a5:'请输入页面刷新时间(分钟)',
        a6:'标题',
        a7:'请输入标题',
        a8:'主题',
        a9:'请选择主题',
        a10:'发布信息',
        a11:'设备KEY',
        a12:'请输入设备KEY',
        a13:'访问地址',
        a14:'请输入访问地址',
        a15:'服务端口',
        a16:'请输入服务端口',
        a17:'服务IP',
        a18:'请输入服务IP',
        a19:'设备端口',
        a20:'请输入设备端口',
        a21:'设备IP',
        a22:'请输入设备IP',
        a23:'配置信息',
        a24:'复制链接',
        a25:'设备访问码',
        a26:'屏幕类型',
        a27:'设备类型',
        a28:'所属',
        a29:'名称',
        a30:'设备编号',
        a31:'基本信息',
        a32:'普通大屏',
        a33:'独立部署服务大屏',
        a34:'普通小屏',
        a35:'平板屏幕',
        a36:'人脸终端屏幕',
        a37:'广告屏',
        a38:'主题一',
        a39:'主题二',
        a40:'请输入标题',
        a41:'请输入数据刷新时间',
        a42:'请选择显示模块',
        a43:'复制成功！',
        a44:'确认要保存所设置的发布信息参数吗？',
        a45:'确认要保存所设置的配置信息吗？',
        a46:'添加设备',
        a47:'请输入设备名称！',
        a48:'请选择屏幕类型',
        a49:'控制器',
        a50:'请选择控制器',
        a51:'设备名称长度必须在 1 到 30之间',
        a52:'人脸终端信息',
        a53:'图片端口',
        a54:'背景图片',
        a55:'全部删除',
        a56:'删除',
        a57:'选择图片',
        a58:'上传图片',
        a59:'设备现场图',
        a60:'读取图片',
        a61:'读取全部图片',
        a62:'只能上传png和jpg格式的图片！',
        a63:'图片大小不能超过300KB!',
        a64:'确认要保存所设置的信息吗？',
        a65:'确认要上传所选择的图片吗？',
        a66:'确认要删除该图片吗？',
        a67:'确认要删除所有图片吗？',
        a68:'设备设置',
        a69:'',
    }
}