import request from '@/utils/request'
import fileDownload from '@/utils/fileDownload'

// 设置管理--获取警报设置列表
export function getWarningSettingList(params) {
    return request({
        url: 'bivale/sysmanage/warning/warningSettingList',
        method: 'get',
        params
    });
}

// 设置管理--获取警报通知设置列表
export function getMonitoringAlarmInfo(params) {
    return request({
        url: 'bivale/sysmanage/warning/monitoringAlarmInfo',
        method: 'get',
        params
    });
}

// 设置管理--警报通知设置
export function setMonitoringAlarmInfo(body) {
    return request({
        url: "bivale/sysmanage/warning/setMonitoringAlarmInfo",
        method: 'post',
        data: body,
    });
}

// 设置管理--删除警报设置
export function deleteWarningSetting(body) {
    return request({
        url: "bivale/sysmanage/warning/deleteWarningSetting",
        method: 'post',
        data: body,
    });
}

// 设置管理--添加警报设置
export function addWarningSetting(body) {
    return request({
        url: "bivale/sysmanage/warning/addWarningSetting",
        method: 'post',
        data: body,
    });
}

// 设置管理--更新警报设置
export function updateWarningSetting(body) {
    return request({
        url: "bivale/sysmanage/warning/updateWarningSetting",
        method: 'post',
        data: body,
    });
}

// 设置管理--警报设置--获取设备信号点列表
export function getFacilityAllPointList(params) {
    return request({
        url: 'bivale/sysmanage/warning/facilityAllPoint',
        method: 'get',
        params
    });
}

// 设置管理--警报设置--获取设备信号点列表
export function getEnterAllPointList(params) {
    return request({
        url: 'bivale/sysmanage/warning/enterAllPoint',
        method: 'get',
        params
    });
}

// 设置管理--警报设置--获取设备信号点列表
export function getVideoAllPointList(params) {
    return request({
        url: 'bivale/sysmanage/warning/videoAllPoint',
        method: 'get',
        params
    });
}

// 设置管理--警报设置--获取联系方式列表
export function getContactSetting(params) {
    return request({
        url: 'bivale/sysmanage/warning/contactSetting',
        method: 'get',
        params
    });
}

// 设置管理--警报设置--删除联系方式
export function delContactData(body) {
    return request({
        url: "bivale/sysmanage/warning/deleteContact",
        method: 'post',
        data: body,
    });
}

// 设置管理--警报设置--新增联系方式
export function addContactData(body) {
    return request({
        url: "bivale/sysmanage/warning/addContact",
        method: 'post',
        data: body,
    });
}

// 设置管理--警报设置--更新联系方式
export function updateContactData(body) {
    return request({
        url: "bivale/sysmanage/warning/updateContact",
        method: 'post',
        data: body,
    });
}

// 设置管理--警报记录信息--获取警报记录
export function getNotificationHistoryData(params) {
    return request({
        url: 'bivale/alarm/history/list',
        method: 'get',
        params
    });
}

// 设置管理--警报记录--强制恢复
export function forceRecover(body) {
    return request({
        url: "bivale/alarm/history/forceRecover",
        method: 'post',
        data: body,
    });
}

// 设置管理--警报记录--变更类别
export function changeCategory(body) {
    return request({
        url: "bivale/alarm/history/changeCategory",
        method: 'post',
        data: body,
    });
}

// 设置管理--通知设置信息--获取通知信息记录
export function getInformation(params) {
    return request({
        url: 'bivale/sysmanage/notification/information',
        method: 'get',
        params
    });
}

// 设置管理--通知设置信息--获取公共指南记录
export function getCommonInfo(params) {
    return request({
        url: 'bivale/sysmanage/notification/commonInfo',
        method: 'get',
        params
    });
}

// 设置管理--通知设置信息--删除通知信息记录
export function deleteCommonInfo(body) {
    return request({
        url: "bivale/sysmanage/notification/deleteCommonInfo",
        method: 'post',
        data: body,
    });
}

// 设置管理--通知设置信息--删除公共指南记录
export function deleteInformation(body) {
    return request({
        url: "bivale/sysmanage/notification/deleteInformation",
        method: 'post',
        data: body,
    });
}

// 设置管理--通知设置信息--添加通知信息记录
export function addInformation(body) {
    return request({
        url: "bivale/sysmanage/notification/addInformation",
        method: 'post',
        data: body,
    });
}

// 设置管理--通知设置信息--添加公共指南记录
export function insertCommonInfo(body) {
    return request({
        url: "bivale/sysmanage/notification/insertCommonInfo",
        method: 'post',
        data: body,
    });
}

// 设置管理--通知设置信息--变更公共指南记录
export function updateCommonInfo(body) {
    return request({
        url: "bivale/sysmanage/notification/updateCommonInfo",
        method: 'post',
        data: body,
    });
}

// 设置管理--通知设置信息--变更通知信息记录
export function updateInformation(body) {
    return request({
        url: "bivale/sysmanage/notification/updateInformation",
        method: 'post',
        data: body,
    });
}

// 设置管理--通知设置信息--获取通知设置咨询记录
export function getInquireInfo(params) {
    return request({
        url: 'bivale/sysmanage/notification/inquireInfo',
        method: 'get',
        params
    });
}

// 设置管理--通知设置信息--获取继承事项记录
export function getTransferInfo(params) {
    return request({
        url: 'bivale/sysmanage/notification/transferInfo',
        method: 'get',
        params
    });
}

// 设置管理--通知设置信息--获取下载记录
export function getDownloadInfo(params) {
    return request({
        url: 'bivale/sysmanage/notification/downloadInfo',
        method: 'get',
        params
    });
}

// 设置管理--警报设置--插入邮件发送时间表
export function insertSchedule(params) {
    return request({
        url: "bivale/sysmanage/mailSchedule/insertSchedule",
        method: 'post',
        params
    });
}

// 设置管理--警报设置--删除邮件发送时间表
export function deleteSchedule(params) {
    return request({
        url: "bivale/sysmanage/mailSchedule/deleteSchedule",
        method: 'post',
        params
    });
}

// 设置管理--警报设置--获取发送日历信息
export function getCalendars(params) {
    return request({
        url: 'bivale/sysmanage/warning/getCalendars',
        method: 'get',
        params
    });
}

// 设置管理--警报设置--获取邮件时间表信息
export function getMailSchedule(params) {
    return request({
        url: 'bivale/sysmanage/mailSchedule/mailScheduleTree',
        method: 'get',
        params
    });
}

// 设置管理--警报设置--获取邮件时间表详细信息
export function getMailScheduleDetail(params) {
    return request({
        url: 'bivale/sysmanage/mailSchedule/mailScheduleDetail',
        method: 'get',
        params
    });
}

// 设置管理--警报设置--变更邮件时间表信息
export function updateMailSchedule(body) {
    return request({
        url: "bivale/sysmanage/mailSchedule/updateMailSchedule",
        method: 'post',
        data: body,
    });
}

// 设置管理--警报设置--拷贝整个邮件发送时间表
export function copySchedule(params) {
    return request({
        url: "bivale/sysmanage/mailSchedule/copySchedule",
        method: 'post',
        params
    });
}

// 设置管理--警报设置--拷贝邮件发送时间表某一天
export function copyScheduleDay(params) {
    return request({
        url: "bivale/sysmanage/mailSchedule/copyScheduleDay",
        method: 'post',
        params
    });
}

// 设置管理--通知设置--上传文件
export function uploadFile(body) {
    return request({
        url: 'bivale/sysmanage/notification/uploadFile',
        method: 'post',
        data: body,
    });
}

//设置管理--通知设置--下载文件
export function downloadFile(body) {
    return request({
        url: "bivale/sysmanage/notification/downloadFile",
        method: 'post',
        // responseType: "blob",
        data: body,
    });
}

// 设置管理--控制器管理--获取FLC控制器信息列表
export function getFLCList(siteId) {
    return request({
        url: "/bivale/sysmanage/flc/list/"+siteId,
        method: 'get',
    });
}

// 设置管理--控制器管理--获取FLC控制器信息
export function getFLCInfo(params) {
    return request({
        url: '/bivale/sysmanage/flc/info',
        method: 'get',
        params
    });
}
//设置管理--控制器管理--更新控制器运行状态
export function updateFLC(body) {
    return request({
        url: "/bivale/sysmanage/flc/update",
        method: 'post',
        data: body,
    });
}

//设置管理--控制器管理--获取控制器的实时状态 --add by pzj 2023 02 17
export function getFLCPresentStatus(params) {
    return request({
        url: '/bivale/sysmanage/flc/presentValue',
        method: 'get',
        params
    });
}
//设置管理--控制器管理--更新控制器的读取频率 --add by pzj 2023 02 17
export function updateControlHistoryRead(body) {
    return request({
        url: '/bivale/sysmanage/controller/historyRead/update',
        method: 'post',
        data: body,
    });
}


//设置管理--通知管理--连接服务
export function connectSSE(params) {
    return request({
        url: "/bivale/sysmanage/notificationSSE/connect",
        method: 'get',
        params
    });
}

//设置管理--通知管理--关闭连接
export function closeSSE(params) {
    return request({
        url: "/bivale/sysmanage/notificationSSE/close",
        method: 'post',
        params
    });
}

//设置管理--通知管理--推送新信息
export function pushMsg(body) {
    return request({
        url: "/bivale/sysmanage/notificationSSE/pushMsg",
        method: 'post',
        data: body,
    });
}

//测试--推送新信息websocket
export function pushMsgWebsocket(params) {
    return request({
        url: "/bivale/test/message",
        method: 'get',
        params,
    });
}

// 设置管理--获取警报设置--获取用户列表
export function getUserSelectList(params) {
    return request({
        url: 'bivale/sysmanage/warning/getUserSelectList',
        method: 'get',
        params
    });
}

// 设置管理--获取警报设置--获取发送時間表
export function getDefaultSchedule(params) {
    return request({
        url: 'bivale/sysmanage/warning/getDefaultSchedule',
        method: 'get',
        params
    });
}

// 设置管理--警报记录信息--获取警报记录
export function getUserOperationHistoryData(params) {
    return request({
        url: 'bivale/history/user/operate/list',
        method: 'post',
        data:params
    });
}