// 门禁模块--设置--通行模块设置--通行权限人员信息组件 lht 2023-3-31
module.exports = {
    accessStaffInfo:{
        a1: 'Access permission personnel information',
        a2: 'Cancel',
        a3: 'Loading....',
        a4: 'Personnel information list',
        a5: 'Current page export',
        a6: 'Export all',
        a7: 'Personnel information export',
        a8: 'Display content',
        a9: 'Management Code',
        a10: 'Name',
        a11: 'Department',
        a12: 'Card No.',
        a13: 'Nationality',
        a14: 'Registration date',
        a15: 'Associated buildings',
        a16: 'Affiliated 1',
        a17: 'Affiliation 2',
        a18: 'Affiliation 3',
        a19: 'Affiliation 4',
        a20: 'Affiliated 5'
    }
}