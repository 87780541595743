// 拓扑图设置
module.exports = {
    topologySet:{
        a1:'拓撲圖設定',
        a2:'添加拓撲圖節點',
        a3:'請選擇節點',
        a4:'節點名稱：',
        a5:'設備清單',
        a6:'設備編輯',
        a7:'分組設定',
        a8:'添加',
        a9:'分組編輯',
        a10:'删除',
        a11:'解除',
        a12:'新增節點',
        a13:'取消',
        a14:'確認',
        a15:'名稱：',
        a16:'請輸入名稱',
        a17:'能源類型：',
        a18:'電',
        a19:'燃氣',
        a20:'水',
        a21:'油',
        a22:'其他',
        a23:'關聯點類型：',
        a24:'虛擬點',
        a25:'關聯點：',
        a26:'節點修改',
        a27:'計量點',
        a28:'設備清單編輯',
        a29:'新增↓',
        a30:'删除↑',
        a31:'添加分組',
        a32:'設備名稱',
        a33:'名稱：',
        a34:'該名稱已存在',
        a35:'確認删除該節點',
        a36:'運算點',
        a37:'請輸入裝置名稱',
        a38:'名稱',
        a39:'拓撲圖',
        a40:'日數據',
        a41:'月數據',
        a42:'年數據',
        a43:'未設定下級設備'
    }
}