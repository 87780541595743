// import sidebar from "../langDocument/login"
// 中文简体
module.exports = {
  // 登录页
  login: {
    Q1: '登录',
    Q2: '用户名',
    Q3: '密码',
    Q4: '验证码',
    Q5: '语言',
    Q6: '登录',
    Q7: '请输入密码',
    Q8: '请输入用户名',
    Q18:"登录失败",
    Q9:"重新输入",
    Q10:"登录失效",
    Q11:"重新登录",
    Q12:"账户或密码错误",
    Q13:"该账户已在其他地方登录",
    Q14:"账户已锁定，请稍后再试",
    Q15:"系统错误",
    Q16:"密码过期",
    Q17:"您的登录状态已经失效，请重新登录",
    Q19:'忘记密码？',
    Q20:'重置密码',
    Q21:'认证码',
    Q22:'请输入认证码!',
    Q23:'电子邮箱',
    Q24:'请输入电子邮箱!',
    Q25:'输入的电子邮箱无效！',
    Q26:'发送',
    Q27:'返回登录',
    Q28:'请输入ID与认证码及电子邮箱，并点击发送按钮。',
    Q29:'将给事先注册的电子邮箱发送新密码1和密码2。',
    Q30:'请输入验证码',
    ERR0:'密码重置成功，请注意查收电子邮件。',
    ERR1:'用户名、认证码或电子邮件地址错误。',
    ERR2:'用户已登录。',
    ERR3:'邮件发送失败。',
    ERR4:'超过密码规定重置次数。',
    ERR5:'密码重置操作无效。',
    ERR6:'密码重置失败。',
  },
  // 侧边栏 a:头像/b:站点/c:认证信息/d:设置管理/e:通知/f:帮助/g:退出
  // sidebar,
  sidebar: {
    a1: '我的',
    a2: '我的IBMS',
    b1: '站点',
    c1: '人员管理',
    c2: '卡片信息',
    c3: '人员信息',
    d1: '设置管理',
    e1: '通知',
    e2: '警报',
    f1: '帮助',
    f2: 'CSV 导出',
    f3: '打印',
    f4: '帮助',
    f5: '网站声明',
    g1: '退出登录',
    g2: '是否确定退出登录',
    g3: '退出登录后须重新登录，才能重新操作',
    a3: '用户名称：',
    a4: '登录账户：',
    a5: '邮箱：',
    a6: '修改密码',
    a7: '密码1',
    a8: '密码2',
    a9: '请输入密码',
    a10: '关闭',
    a11: '确定',
    a12: '新密码1',
    a13: '新密码2',
    a14: '请重新登录',
    a15: '能效看板',
    a16: '更多',
    a17: '编辑',
    a18: '手机：',
    a19: '是否开通微信',
    a20: '请输入手机号',
    a21: '请输入微信用户名',
    a22: '微信用户名：',
    a23: '密码两次输入不匹配！',
    a24: '密码格式错误,密码需包括特殊字符、数字、字母三种组合；且密码长度在8~16之间。',
    a25: '密码1和密码2不能相同，请重新输入！',
    a26: '用户设置',
    a27: '确认新密码1',
    a28: '确认新密码2',
    a29: '微信设置',
    a30: '重置密码',
    a31: '输入登录账户',
    a32: '输入邮箱'
  },
  // 门禁模块--报警页
  alarm: {
    a0: '加载中....',
    a1: '警报',
    a2: '设备名称',
    a3: '楼层/租户选择',
    a4: '初始日期/时间选择',
    a5: '终止日期/时间选择',
    a6: '查询',
    a7: '警报列表',
    a8: '条数据',
    a9: '警报确认',
    a10: '手动恢复',
    a11: '处理',
    a12: '顺位恢复',
    a13: '警报输出',
    a14: '添加注释',
    a15: '选择操作',
    a16: '添加注释',
    a17: '请输入内容......',
    a18: '请选择日期或时间',
    a19: '加载中....',
    a20: '发生日期',
    a21: '恢复日期',
    a22: '发报类型',
    a23: '警报类型',
    a24: '建筑物名称',
    a25: '设备名称',
    a26: '视频',
    a27: '信号/终端名称',
    a28: '警报内容',
    a29: '处理状态',
    a30: '姓名',
    a31: '卡ID',
    a32: '体温',
    a33: '注释',
    a34: '操作',
    a35: '未确认',
    a36: '未恢复',
    a37: '未处理',
    a38: '全部',
    a39: '请输入设备名称',
    a40: '取消',
    a41: '确认',
    a42: '处理状态变更',
    a43: '目前的处理状态：',
    a44: '变更后：',
    a45: '处理中',
    a46: '处理后',
    a47: '确定要进行被选择的警报的确认登记吗?',
    a48: '确定要进行手动修复吗?',
    a49: '警报状态',
    a50: '显示内容',
    a51: 'CSV导出',
    a52: 'KPI类型',
    a53: '当前值',
    a54: '限值',
    a55: '死区',
    a56: '占比（%）',
    a57: '警报等级',
    a58:'请选择站点信息',
  },
  // 门禁模块--卡片记录（通行记录）
  throughrecords: {
    a0: '加载中....',
    a1: '卡片记录',
    a2: '姓名',
    a3: '卡片编号',
    a4: '初始日期/时间选择',
    a5: '终止日期/时间选择',
    a6: '查询',
    a7: '记录列表',
    a8: '请选择日期或时间',
    a9: '人脸识别图片',
    a10: '添加注释',
    a11: '选择操作',
    a12: '注释',
    a13: '取消',
    a14: '确认',
    a15: '请输入内容....',
    a16: '条数据',
    a17: '发生日期',
    a18: '认证类型',
    a19: '姓名',
    a20: '卡片编号',
    a21: '体温',
    a22: '设备组名称',
    a23: '设备名称',
    a24: '信号/终端名称',
    a25: '建筑物名称',
    a26: '注释',
    a27: '操作',
    a28: '请输入姓名',
    a29: '请输入卡片编号',
    a30: '地点',
    a31: '口罩',
    a32: '人脸照片读取失败，请稍后再试。',
    a33: '管理代码',
    a34: '人脸识别记录信息',
    a35: '访客通行记录',
    a36: '请输入管理代码',
    a37: '请输入信号/总端名称',
    a38: '请输入设备组名称',
    a39: '请输入设备名称',
  },
  // 门禁模块--考勤记录
  attendance: {
    a1: '加载中....',
    a2: '考勤记录',
    a3: '人员所属',
    a4: '员工编号',
    a5: '人员姓名',
    a6: '初始日期/时间选择',
    a7: '终止日期/时间选择',
    a8: '查询',
    a9: '考勤记录列表',
    a10: '请选择日期或时间',
    a11: '条数据',
    a12: '员工编号',
    a13: '员工姓名',
    a14: '考勤日期',
    a15: '考勤开始时间',
    a16: '考勤结束时间',
    a17: '请输入人员所属',
    a18: '请输入员工编号',
    a19: '请输入人员姓名',
  },

  // 门禁模块--滞留人员显示
  strandedPersonnel: {
    a1: '加载中....',
    a2: '滞留人员显示',
    a3: '区域选择',
    a4: '查询',
    a5: '滞留人员列表',
    a6: '删除',
    a7: '超时设置',
    a8: '设置',
    a9: '关闭',
    a10: '超过时间',
    a11: 'HH时mm分',
    a12: '条数据',
    a13: '区域名称',
    a14: '姓名',
    a15: '进门时间',
    a16: '卡号码',
    a17: '滞留时间',
    a18: '部门',
    a19: '所属1',
    a20: '所属2',
    a21: '所属3',
    a22: '所属4',
    a23: '所属5',
    a24: '操作',
    a25: '确定要删除',
    a26: '的信息',
    a27: '请选择时间',
  },
  // 门禁模块--室内人员显示
  indoorPersonne: {
    a1: '加载中....',
    a2: '室内人员显示',
    a3: '区域选择',
    a4: '查询',
    a5: '室内人员列表',
    a6: '重置',
    a7: '室内人员设置',
    a8: '设置',
    a9: '关闭',
    a10: '卡编号',
    a11: '请输入卡编号',
    a12: '管理代码',
    a13: '请输入管理代码',
    a14: '查询',
    a15: '所有个人列表',
    a16: '添加',
    a17: '登记人员列表',
    a18: '删除',
    a19: '条数据',
    a20: '区域名称',
    a21: '姓名',
    a22: '部门',
    a23: '在/不在',
    a24: '进门/出门时间',
    a25: '所属1',
    a26: '分组名称',
    a27: '卡编号',
    a28: '所属2',
    a29: '所属3',
    a30: '所属4',
    a31: '所属5',
    a32: '操作',
    a33: '卡编号',
    a34: '管理代码',
    a35: '姓名',
    a36: '所属1',
    a37: '操作',
    a38: '确定要更新',
    a39: '的信息',
    a40: '请选择区域',
  },
  // 门禁模块--门禁时间表
  doortimetable: {
    a1: '加载中....',
    a2: '门禁时间表',
    a3: '时间表名称：',
    a4: '取消',
    a5: '确认',
    a6: '卡模式',
    a7: '自由模式',
    a8: '重复模式',
    a9: '进入卡/退出自由模式',
    a10: '进入自由/退出卡模式',
    a11: '卡认证',
    a12: '卡认证+数字键认证',
    a13: '卡认证+指静脉认证',
    a14: '不可通行',
    a15: '可通行',
    a16: '非警戒',
    a17: '警戒中',
    a18: '警戒无效时间段',
    a19: '时间表间复制',
    a20: '时间表复制',
    a21: '时间表全表复制',
    a22: '设置',
    a23: '取消',
    a24: '复制原时间表',
    a25: '请选择时间表',
    a26: '时间表复制',
    a27: '复制源',
    a28: '复制到',
    a29: '星期一',
    a30: '星期二',
    a31: '星期三',
    a32: '星期四',
    a33: '星期五',
    a34: '星期六',
    a35: '星期日',
    a36: '假日',
    a37: '特日1',
    a38: '特日2',
    a39: '特日3',
    a40: '特日4',
    a41: '星期没有被正确指定，请再次指定',
    a42: '没有选择时间表，请选择时间表',
    a43: '没有选择星期，请选择星期',
    a44: '请先设置日历',
    a45: '请输入时间表名称',
    a46: '时间表名称更新',
    b1: '时间表变更',
    b2: '时间表类型：',
    b3: '时间表名称：',
    b4: '项目：',
    b5: '由于初始值是“卡模式”，所以请设置其它的模式。',
    b6: '模式1',
    b7: '模式2',
    b8: '模式3',
    b9: '模式4',
    b10: '模式5',
    b11: '重置',
    b12: '门时间表',
    b13: '卡认证时间表',
    b14: '通行时间表',
    b15: '警备时间表',
    b16: '确定要进行设定内容注册吗?',
    b17: '时间没有被正确指定，请再次指定',
    b18: '时',
    b19: '分',
    b20: '数量不能超过12',
  },
  // 门禁模块--车牌管理
  plateNumber: {
    a1: "加载中",
    a2: "车牌管理",
    a3: "管理代码",
    a4: "请输入管理代码",
    a5: "人员姓名",
    a6: "请输入姓名",
    a7: "手机号码",
    a8: "请输入手机号码",
    a9: "车牌号码",
    a10: "请输入车牌号码",
    a11: "车牌颜色",
    a12: "无",
    a13: "白牌",
    a14: "黑牌",
    a15: "蓝牌",
    a16: "黄牌",
    a17: "绿牌",
    a18: "认证模式",
    a19: "无",
    a20: "白名单",
    a21: "黑名单",
    a22: "临时车",
    a23: "VIP车",
    a24: "月租车",
    a25: "时间",
    a26: "无",
    a27: "开始日期",
    a28: "有效日期",
    a29: "查询",
    a30: "车牌列表",
    a31: "删除",
    a32: "人员车牌管理",
    a33: "车牌管理",
    a34: "选择操作",
    a35: "批量车牌管理",
    a36: "添加车牌",
    a37: "人员车牌管理",
    a38: "设置",
    a39: "关闭",
    a40: "基本信息",
    a41: "管理代码",
    a42: "姓名",
    a43: "部门",
    a44: "手机号码",
    a45: "邮箱",
    a46: "所属站点",
    a47: "所属分组",
    a48: "车牌信息",
    a49: "添加车牌",
    a50: "车牌所属",
    a51: "车身颜色",
    a52: "汽车品牌",
    a53: "认证模式",
    a54: "开始日期",
    a55: "有效日期",
    a56: "请输入手机号码",
    a57: "请输入车牌号码",
    a58: "请选择时间",
    a59: "条数据",
    a60: "操作",
    a61: "开始日期",
    a62: "请选择开始日期",
    a63: "确定",
    a64: "结束日期",
    a65: "请选择结束日期",
    a66: "每个人最多添加5块车牌",
    a67: "手机号码格式错误",
    a68: "车牌号码格式错误",
    a69: "请选择认证模式",
    a71: "白名单",
    a72: "黑名单",
    a73: "临时车",
    a74: "VIP车",
    a75: "月租车",
    a76: "免检车",
    a77: "非免检车",
    a78: "临时卡",
    a79: "长租卡",
    a80: "月租卡",
    a81: "贵宾卡",
    a82: "万全卡",
    a83: "储值卡",
    a84: "免费卡",
    a85: "访客卡",
    a86: "确认要删除车牌吗？",
    a87: "请输入车牌所属人员信息。",
    a88: "请选择车牌颜色",
    a89: "下载模板文件",
    a90: "上传车牌信息",
    a91: "导出车牌信息",
    a92: "通行区域",
    a93: "车主性别",
    a94: "女",
    a95: "男",
    a96: "组织部门",
    a97: "请选择组织部门",
    a98: "第三方系统",
    a99: "同步数据",
    a100: "请选择通行区域",
    a101: "导入文件模板",
    a102: "人员类型",
    a103: "系统人员",
    a104: "普通人员",
    a105: "请选择所属站点",
    a106: "请选择人员信息",
    a107: "输入的电子邮件无效！",
    a108: "确认要保存所设置的车牌信息吗？",
    a109: "选择导入文件",
    a110: "车牌信息导入失败。",
    a111: "批量上传车牌信息",
  },
  // 门禁模块--室内人员区域
  indoorArea: {
    a1: '加载中....',
    a2: '室内人员区域',
    a3: '区域选择',
    a4: '查询',
    a5: '登记的门',
    a6: '添加区域/分组',
    a7: '删除区域/分组',
    a8: '区域编辑',
    a9: '变更名称',
    a10: '设置',
    a11: '关闭',
    a12: '添加分组/区域选择',
    a13: '分组',
    a14: '区域',
    a15: '分组/区域名称',
    a16: '室内人员区域名称',
    a17: '室内区域设置',
    a18: '区域名称：',
    a19: '所有的门',
    a20: '添加',
    a21: '已注册的门',
    a22: '删除',
    a23: '请输入区域名称',
    a24: '条数据',
    a25: '设备名称',
    a26: '认证终端名称',
    a27: '楼层·租户名称',
    a28: '操作',
    a29: '确定要添加分组/区域吗？',
    a30: '请输入分组/区域名称',
    a31: '确定要删除',
    a32: '吗？',
    a33: '确定要进行设定内容注册吗？',
  },
  // 门禁模块--滞留人员区域
  detentionArea: {
    a1: '加载中....',
    a2: '滞留人员区域',
    a3: '区域选择',
    a4: '查询',
    a5: '登记的门',
    a6: '添加区域/分组',
    a7: '删除区域/分组',
    a8: '区域编辑',
    a9: '变更名称',
    a10: '设置',
    a11: '关闭',
    a12: '添加分组/区域选择',
    a13: '分组',
    a14: '区域',
    a15: '分组/区域名称',
    a16: '滞留人员区域名称',
    a17: '滞留区域设置',
    a18: '区域名称：',
    a19: '所有的门',
    a20: '添加',
    a21: '已注册的门',
    a22: '删除',
    a23: '请输入区域名称',
    a24: '条数据',
    a25: '设备名称',
    a26: '认证终端名称',
    a27: '楼层·租户名称',
    a28: '操作',
    a29: '确定要添加分组/区域吗？',
    a30: '请输入分组/区域名称',
    a31: '确定要删除',
    a32: '吗？',
    a33: '确定要进行设定内容注册吗？',
  },
  // 门禁模块--车场设置
  parkingLotSet: {
    a1: '加载中',
    a2: '车场设置',
    a3: '车场人员结构架构信息',
    a4: '车场配置信息',
    a5: '同步组织架构',
    a6: '删除同步组织架构',
    a7: '暂无数据',
    a8: '没有选择需要更新的组织结构',
    a9: '请选择需要更新的组织架构',
    a10: '确定',
    a11: '确定要更新所选组织架构及其子架构信息到第三方系统吗?',
  },
  // 门禁模块--人脸认证信息设置
  facialInfo: {
    a1: '人脸认证信息',
    a2: '设备状态',
    a3: '设备名称',
    a4: '查询',
    a5: '人脸设备列表',
    a6: '识别记录管理',
    a7: '编辑',
    a8: '选择操作',
    a9: '设备管理',
    a10: '设备序列号',
    a11: '关联读卡器',
    a12: '设备类型',
    a13: '设备IP',
    a14: '设备密码',
    a15: '心跳回调URL',
    a16: '认证回调URL',
    a17: '设备回调URL',
    a18: '设备备注',
    a19: '条数据',
    a20: '备注',
    a21: '操作',
    a22: '宇泛',
    a23: '奇智',
    a24: '海康',
    a25: '确定要设置所编辑的信息吗?',
    a26: '全部',
    a27: '正常',
    a28: '故障',
    a29: '加载中....',
    a30: '请输入设备名称',
    a31: '终端品牌',
    a32: '上传背景图',
    a33: '关闭',
    a34: '人脸终端',
    a35: '设置',
    a36: '图片端口',
    a37: '背景图片',
    a38: '全部删除',
    a39: '删除',
    a40: '选择图片',
    a41: '上传图片',
    a42: '设备现场图',
    a43: '读取图片',
    a44: '读取全部图片',
    a45: '人脸终端待机背景图设置',
    a46: '只能上传png和jpg格式的图片!',
    a47: '图片大小不能超过300KB!',
    a48: '确认要保存所设置的信息吗？',
    a49: '确认要上传所选择的图片吗？',
    a50: '确认要删除该图片吗？',
    a51: '确认要删除所有图片吗？'
  },
  // 门禁模块--读卡器报警
  cardreaderalarm: {
    a1: '加载中....',
    a2: '读卡器报警',
    a3: '认证终端',
    a4: '请输入认证终端',
    a5: '查询',
    a6: '认证终端列表',
    a7: '警告设置',
    a8: '警报设置',
    a9: '开门超时警报:',
    a10: '无',
    a11: '有',
    a12: '(警报提示时间:',
    a13: '秒',
    a14: '恢复警报时停止)',
    a15: '门故障警报:',
    a16: '异常开门警报:',
    a17: '强行进门警报:',
    a18: '无循环警报:',
    a19: '条数据',
    a20: '认证终端',
    a21: '开超过鸣动',
    a22: '门故障鸣动',
    a23: '异常开鸣动',
    a24: '入侵鸣动',
    a25: '无循环鸣动',
    a26: '操作',
    a27: '确定要进行设定内容注册吗?',
  },
  // 门禁模块--实际数据
  realdata: {
    a1: '加载中....',
    a2: '报表数据',
    a3: '全部',
    a4: '门',
    a5: '卡',
    a6: '认证操作历史记录',
    a7: '月报',
    a8: '日报',
    a9: '输出期间：',
    a10: '文件拆分数：',
    a11: '输出日数：',
    a12: '1 生成一个文件而不进行拆分',
    a13: '2 每15天划分一次(包括最后一个文件的结束日期)生成文件',
    a14: '3 每20天划分一次(包括最后一个文件的结束日期)生成文件',
    a15: '4 每7天划分一次(包括最后一个文件的结束日期)生成文件',
    a16: '5 每6天划分一次(包括最后一个文件的结束日期)生成文件',
    a17: '如果输出量很大，则除法的数目会自然更改为5',
    a18: '下载文件名称：',
    a19: '输出中...',
    a20: '输出',
    a21: '文件拆分说明',
    a22: '姓名',
    a23: '请输入姓名',
    a24: '卡编号',
    a25: '请输入卡编号',
    a26: '卡ID',
    a27: '请输入卡ID',
    a28: '管理代码',
    a29: '请输入管理代码',
    a30: '查询',
    a31: '认证信息列表',
    a32: '条数据',
    a33: '管理所属',
    a34: '没有选择日期',
    a35: '请选择日期',
    a36: '设备组名称',
    a37: '请输入设备组名称',
    a38: '设备名称',
    a39: '请输入设备名称',
    a40: '门列表',
  },
  // 门禁模块--通行模式
  trafficpatterns: {
    a1: '加载中....',
    a2: '通行模式',
    a3: '通行模式名称：',
    a4: '通行模式编号：',
    a5: '已注册的认证终端',
    a6: '请选择通行模式',
    a7: '通行模式复制',
    a8: '认证终端编辑',
    a9: '复制原通行模式',
    a10: '未选择通行模式',
    a11: '请选择需要复制的通行模式',
    a12: '认证终端名称',
    a13: '查询',
    a14: '全部的认证终端',
    a15: '添加',
    a16: '删除',
    a17: '通行时间表·日历变更',
    a18: '设置',
    a19: '时间表·日历选择',
    a20: '通行时间表：',
    a21: '请选择通行时间表',
    a22: '日历：',
    a23: '请选择日历',
    a24: '警戒/解锁切换操作设置：',
    a25: '有效',
    a26: '无效',
    a27: '条数据',
    a28: '企业名称',
    a29: '建筑名称',
    a30: '楼层·租户名称',
    a31: '设备名称',
    a32: '认证终端名称',
    a33: '出入类型',
    a34: '警戒设定',
    a35: '无',
    a36: '有',
    a37: '时间表名称',
    a38: '日历名称',
    a39: '认证终端没有被选择',
    a40: '请选择需要被改变的认证终端',
    a41: '有未设定时间表或日历的终端',
    a42: '请在未设定时间表和日历的终端，设定时间表和日历',
    a43: '通行模式名称更新',
    a44: '请输入通行模式名称',
    a45: '输入搜索文本',
    a46: '通行权限人员信息'
  },
  // 详情页
  details: {
    a1: '加载中....',
    a2: '设备名称',
    a3: '设备ID',
    a4: '设备GUID',
    a5: '左入口',
    a6: '名称',
    a7: '客户名称',
    a8: '类型',
    a9: '功能站点',
    a10: '楼层·租户名称',
    a11: '取消',
    a12: '确认',
    a13: '项目',
    a14: '状态·操作',
    a15: '状态变化时间',
    a17: '选择',
    a18: '时间表设置',
    a19: '设备详情信息',
    a20: '时间表',
    a21: '日历',
    a22: '门开有效时间',
    a23: '门开超时检测时间',
    a24: '有无声音',
    a25: '有声音',
    a26: '无声音',
    a27: '电气锁动作次数',
    a28: '电气锁动作次数超过监视',
    a29: '电气锁动作次数上限',
    a30: '确定要进行设定内容注册吗?',
    a31: '门状态',
    a32: '异常开',
    a33: '暂时解锁',
    a34: '开超过',
    a35: '入侵',
    a36: '上解锁状态',
    a37: '无循环',
    a38: '通行状态',
    a39: '运行状态',
    a40: '接受禁止状态',
    a41: '非法通行警报',
    a42: '设备异常警报',
    a43: '入方向门开',
    a44: '出方向门开',
    a45: '入方向通行禁止',
    a46: '出方向通行禁止',
    a47: '连续通过设置',
    a48: '火灾信号输出',
    a49: '卡NG信号输出',
    a50: '指定目标',
    a51: '门时间表',
    a52: '卡认证时间表',
    a53: '机械警备时间表',
    a54: '模型1',
    a55: '模型2',
    a56: '模型3',
    a57: '模型4',
    a58: '模型5',
    a59: '初始值为“卡模式”，如需更改请新设置',
    a60: '卡模式',
    a61: '自由模式',
    a62: '重复模式',
    a63: '进入卡/退出自由模式',
    a64: '进入自由/退卡模式',
    a65: '设备详情信息',
    a66: '时间表',
    a67: '日历',
    a68: '数字键超时时间(秒)',
    a69: '数字键终端有无',
    a70: '警戒温度下限(℃)',
    a71: '警戒温度上限(℃)',
    a72: '警戒温度异常是否开门',
    a73: '否',
    a74: '是',
    a75: '认证模式',
    a76: '运转状态',
    a77: '非法卡',
    a78: '时间表恢复',
    a79: '周期自动复原',
    a80: '有效',
    a81: '无效',
    a82: '周期恢复时间1',
    a83: '周期恢复时间2',
    a84: '周期恢复时间3',
    a85: '周期恢复时间4',
    a86: '周期恢复时间5',
    a87: '设备IP',
    a89: '设备子网掩码',
    a90: '设备网关',
    a91: '操作',
    a92: '关联设备网络信息',
    a93: '操作成功，开门按钮将在5秒钟后恢复关闭状态',
    a94: '开门操作失败',
    a95: '当日时间表',
    a96: '当日',
    a97: '次日时间表',
    a98: '次日',
    a99: '时间表手动恢复',
    a100: '设置',
    a101: '操作状态/故障·报警',
    a102: '时',
    a103: '分'
  },
  // 设备模块--操作\控制记录
  operaterecords: {
    a1: '操作·控制记录',
    a2: '设备名称',
    a3: '初始日期/时间选择',
    a4: '终止日期/时间选择',
    a5: '查询',
    a6: '记录列表',
    a7: '请选择日期或时间',
    a8: '警报确认',
    a9: '手动恢复',
    a10: '应对',
    a11: '顺位恢复',
    a12: '警报输出',
    a13: '添加注释',
    a14: '选择操作',
    a15: '请输入内容......',
    a16: '条数据',
    b1: '发生日期',
    b2: '要因',
    b3: '服务',
    b4: '状态',
    b5: '设备组名称',
    b6: '设备名称',
    b7: '信号/终端名称',
    b8: '注释',
    b9: '操作',
  },
  // 设备模块--数据输出
  dataoutput: {
    a1: '设备',
    a2: '信号',
    a3: '初始日期/时间选择',
    a4: '终止日期/时间选择',
    a5: '查询',
    a6: '数据预览',
    a7: '数据下载',
    a8: '按信号单独显示',
    a9: '按设备合并显示',
    a10: '生成文件',
    a11: '文件列表',
    a12: '生成的输出文件会在该列表显示，供后续下载',
    a13: '生成的输出文件仅保存3天，3天后文件会被删除；如需下载请重新导出生成',
    a14: '条数据',
    b1: '设备编号',
    b2: '设备名称',
    b3: '信号点ID',
    b4: '信号名称',
    b5: '信号编号',
    b6: '计量值',
    b7: '计量单位',
    b8: '日期',
    b9: '时间',
  },
  // 设备模块--设备联动控制
  controlllinkage: {
    a1: '组名称',
    a2: '编号：',
    a3: '输入点',
    a4: '输出点',
    a5: '联动点',
    a6: '联动条件',
    a7: '请选择时间表',
    a8: '设置',
    a9: '名称已存在',
    b1: '状态',
    b2: '警报',
    b3: '条数据',
    b4: '设备名称',
    b5: '信号名称',
    b6: '条件',
    b7: '显示（维护）',
    b8: '操作',
    b9: '有效',
    b10: '无效',
    b11: '控制',
    b12: '不控制',
    b13: '联动控制',
    b14: '输出设备操作',
    b15: '停电或等待恢复的情况',
    b16: '输出延迟',
    b17: '类型',
    b18: '开',
    b19: '关',
    a10: '加载中....',
    a11: '设备联动控制',
    a12: '名称更新',
    a13: '关闭',
    a14: '确定',
    a15: '名称：',
    a16: '请输入名称',
    a17: '名称不能为空'
  },
  // 设备模块--季节控制
  controllseason: {
    a1: '组名称',
    a2: '编号：',
    a3: '模拟设置设备',
    a4: '上下限预警设置设备',
    a5: '运行模式设置设备',
    a6: '请选择时间表',
    a7: '季节设置',
    a8: '参数和运行模式设置',
    b1: "季节设置",
    b2: "夏季（夏季模式）",
    b3: "月",
    b4: "日",
    b5: "冬季（冬季模式）",
    b6: "其他季节（其他季节模式）",
    b7: "夏季与冬季之外",
    b8: "临时变更（运行模式）",
    b9: "从今天开始的设置",
    b10: "确定",
    b11: "设置",
    b12: "无临时变更",
    b13: "夏季模式",
    b14: "冬季模式",
    b15: "其他模式",
    c1: "加载中...",
    c2: "参数、运行模式设置",
    c3: "模拟值",
    c4: "模拟设备列表",
    c5: "模拟值设置",
    c6: "上下限值",
    c7: "上下限警报列表",
    c8: "上下限警报设置",
    c9: "运行模式列表",
    c10: "运行模式设置",
    c11: "设置",
    c12: "有效",
    c13: "无效",
    c14: "设备名称",
    c15: "信号名称",
    c16: "设定值",
    c17: "有效/无效",
    c18: "中间季模式",
    c19: "类型",
    c20: "条数据",
    c21: '上限值',
    c22: '下限值',
    a9: '加载中....',
    a10: '季节切换控制',
    a11: '名称更新',
    a12: '关闭',
    a13: '确定',
    a14: '名称：',
    a15: '请输入名称',
    a16: '名称不能为空'
  },
  // 设备模块--间歇运行控制
  controllIntermittent: {
    a1: '组名称',
    a2: '编号：',
    a3: '对象设备',
    a4: '控制条件',
    a5: '请选择时间表',
    a6: '设置',
    a7: "设备名称",
    a8: "信号名称",
    a9: "类型",
    a10: "条数据",
    a11: "间歇运行控制",
    a12: "有效",
    a13: "无效",
    a14: "分",
    a15: "启动",
    a16: "停止",
    a17: "运行时间",
    a18: "停止时间",
    a19: "测得点",
    a20: "季节分组",
    a21: "夏季目标温度",
    a22: "冬季目标温度",
  },

  // 设备模块--设备执行时间表控制
  lightRunTimetable: {
    a1: '设备执行时间表',
    a2: '时间表名称：',
    a3: '取消',
    a4: '确认',
    a5: '卡模式',
    a6: '自由模式',
    a7: "重复模式",
    a8: "进入卡/退出自由模式",
    a9: "进入自由/退卡模式",
    a10: "请选择时间表",
    a11: "时间表间复制",
    a12: "时间表复制",
    a13: "时间表全表复制",
    a14: "复制原时间表",
    a15: "复制源",
    a16: "复制到",
    a17: '星期一',
    a18: '星期二',
    a19: '星期三',
    a20: '星期四',
    a21: '星期五',
    a22: '星期六',
    a23: '星期日',
    a24: '假日',
    a25: '特日1',
    a26: '特日2',
    a27: '特日3',
    a28: '特日4',
    a29: '设备时间表',
    a30: '星期没有被正确指定，请再次指定',
    a31: '邮件发送时间表列表',
    a32: '邮件发送时间表名称',
    a33: '新增',
    a34: '删除',
    a35: '修改',
    a36: '日历',
    a37: '请先设置日历',
  },
  //设备模块--时间表控制页
  controllGroup: {
    a1: '组名称',
    a2: '编号：',
    a3: '注册设备',
    a4: '请选择时间表',
    a5: '注册设备编辑',
    a6: '时间表复制',
    a7: '复制原时间表',
    a8: '未选择时间表',
    a9: '请选择需要复制的时间表',
  },
  //设备模块--编辑设备
  terminaleditor: {
    a1: '加载中....',
    a2: '注册设备编辑',
    a3: '注册设备Gr1',
    a4: '查询',
    a5: '全部的设备',
    a6: '添加',
    a7: '删除',
    a8: '已注册的设备',
    a9: '设置',
    a10: '时间表·日历选择',
    a11: '通行时间表：',
    a12: '日历：',
    a13: '警戒/解锁切换操作设置：',
    a14: '有效',
    a15: '无效',
    a16: '条数据',
    a17: '设备名称',
    a18: '信号名称',
    a19: '类型',
    a20: '输入点设备编辑',
    a21: '输出点设备编辑',
    a22: '联动点设备编辑',
    a23: '间歇运行点编辑',
    a24: '测量点编辑',
    a25: '无',
    a26: '模拟设置设备',
    a27: '上下限预警设置设备',
    a28: '运行模式设置设备',
    a29: '温度测量点',
    a30: '空调监控点',
    a31: '趋势点编辑',
    a32: '时间设置趋势点编辑',
    a33: '设备没有被选择',
    a34: '请选择需要被改变的设备',
    a35: '有未设定时间表或日历的终端',
    a36: '请在未设定时间表和日历的终端，设定时间表和日历',
    a37: '只能选择一项',
    a38: '站点',
    a39: '联动控制输入',
    a40: '启停输出',
    a41: '计量点',
    a42: '运算点',
    a43: '虚拟点',
    a44: '模拟输出',
    a45: '多态输出',
  },

  //设备模块--联动控制组设置页
  controllGroupLinkage: {
    a1: '组名称',
    a2: '编号：',
    a3: '输入点',
    a4: '输出点',
    a5: '联锁点',
    a6: '请选择组',
    a7: '输入点编辑',
    a8: '输出点编辑',
    a9: '联锁点编辑',
  },
  //设备模块--季节切换控制组设置页
  seasonGroup: {
    a1: '组名称',
    a2: '编号：',
    a3: '模拟设置设备',
    a4: '上下限预警设置设备',
    a5: '运行模式设置设备',
    a6: '请选择组',
    a7: '模拟设置设备编辑',
    a8: '上下限预警设置设备编辑',
    a9: '运行模式设置设备编辑',
  },
  //设备模块--间歇运行控制组页
  controllGroupIntermittent: {
    a1: '组名称',
    a2: '编号：',
    a3: '间歇运行点',
    a4: '测量点',
    a5: '请选择组',
    a6: '测量点编辑',
    a7: '间歇运行点编辑',
    a8: '运行模式设置设备编辑',
  },
  //设备模块--舒适控制组页
  controllGroupComfort: {
    a1: '组名称',
    a2: '编号：',
    a3: '空调监控点',
    a4: '温度测量点',
    a5: '请选择组',
    a6: '测量点编辑',
    a7: '温度测量点编辑',
    a8: '空调监控点编辑',
    a9: '对象设备',
    a10: '控制条件',
    a11: '设置',
  },
  //时间趋势组
  timeTrend: {
    a1: '组名称',
    a2: '编号：',
    a3: '趋势点',
    a4: '请选择组',
    a5: '趋势点编辑',
    a6: '条数据',
    a7: '设备名称',
    a8: '信号名称',
    a9: '类型',
    a10: '收集周期',
    a11: '添加',
    a12: '修改',
    a13: '删除',
    a14: '确定要删除?',
    a15: '确定',
    a16: '取消',
    a17: '成功',
    a18: '失败',
    a19: '名称已存在',
  },
  //时间设置趋势组
  timeSetupTrend: {
    a1: '时间趋势',
    a2: '时间趋势组：',
    a3: '编号：',
    a4: '时间轴视图',
    a5: '请选择时间',
    a6: '1日',
    a7: '1小时',
    a8: '日期选择',
    a9: '时间选择',
    a10: '轴格式',
    a11: '状态',
    a12: '多状态',
    a13: '请选择组',
    a14: '列表显示',
    a15: '图表显示',
    a16: '左轴',
    a17: '右轴',
    a18: '单位',
    a19: '最大值',
    a20: '最小值',
    a21: '轴分割数',
    a22: '设备名称',
    a23: '信号名称',
    a24: '数据类型',
    a25: '图表类型',
    a26: '无设备信号点，请先到时间趋势组设置页面进行设置',
  },
  //数据比较趋势组
  dataCompareTrend: {
    a1: '数据比较趋势',
    a2: '趋势组：',
    a3: '编号：',
    a4: '添加',
    a5: '确定要删除?',
    a6: '删除',
    a7: '状态',
    a8: '多状态',
    a9: '请选择组',
    a10: '列表显示',
    a11: '图表显示',
    a12: '左轴',
    a13: '右轴',
    a14: '单位',
    a15: '最大值',
    a16: '最小值',
    a17: '轴分割数',
    a18: '日期',
    a19: '星期',
    a20: '图表类型',
    a21: '日期操作',
    a22: '信号点',
    a23: '设备名称',
    a24: '信号名称',
    a25: '类型',
    a26: '请添加日期',
    a27: '折线图',
    a28: '多状态图',
  },
  //照明详细信息
  lightDetails: {
    a1: '开关照度',
    a2: '环境照度',
    a3: '开关设置',
    a4: '开关状态',
    a5: '故障',
    a6: '调光设置',
    a7: '调光状态',
    a8: '操作/状态/故障.警报',
    a9: '评论登记栏',
    a10: '维护监视',
    a11: '正常',
    a12: '最多5条评论',
    a13: '时间表控制',
    a14: '上限值',
    a15: '上下限值监视',
    b1: '累计运行时间超过监控点运行时间',
    b2: '连续运行时间超过监视连续运行时间',
    b3: '状态变化次数超过监视状态变化次数',
    b4: '故障报警时间超过监视故障报警时间',
    b5: '故障报警次数超过监视故障次数',
    b6: '小时',
    b7: '次',
    c1: 'RS名称',
    c2: '对象ID',
    c3: '趋势',
    c4: '单元类别',
    c5: '模块',
    c6: '扩展',
    c7: '端子no',
    d1: '变更时间：',
    d2: '因素：',
    d3: '维护登记',
    d4: '重要设备登记',
    d5: '声音类别：',
    d6: '开',
    d7: '关',
    d8: '操作',
    d9: '状态',
    d10: '故障警报',
    d11: '设置值',
    d12: '测量值',
    d13: '设置',
    d14: '设置成功',
    d15: '设置失败',
  },
  //空调详细信息
  airConditionDetails: {
    a1: '遥控模式',
    a2: '冷风',
    a3: '热风',
    a4: '节能',
    a5: '风速',
    a6: '开关设置',
    a7: '开关状态',
    a8: '故障',
    a9: '温度设置',
    a10: '吸入温度',
    a11: '运转模式设置',
    a12: '风量设置',
    a13: '遥控器禁止温度开关状态',
    a14: '操作/状态/故障.警报',
    a15: '评论登记栏',
    a16: '维护监视',
    a17: '设备名称',
    a18: '运行',
    a19: '制冷',
    a20: '制热',
    a21: '正常',
    a22: '弱风',
    a23: '中风',
    a24: '强风',
    a25: '无',
    a26: '停止',
    a27: '室温',
    a28: '火灾信号',
    a29: '送风',
    a30: '抽湿',
    a31: '维护',
    a32: '维护警报/上下限警报·运行异常',
  },
  //环境详细信息
  EnvironmentDetails: {
    a1: '环境刻度',
    a2: '故障',
    a3: '评论登记栏',
    a4: '维护监视',
    a5: '仪器名称',
    a6: '测量值',
    a7: '上下限值监视',
    a8: '设置',
    a9: '上限值比下限值小',
    a10: '设置值不在上下限范围内',
    a11: '运行',
    a12: "停止",
  },

  // 电梯模块--设备页
  elevatorequipment: {
    a1: '类型选择',
    a2: '全部',
    a3: '控制器',
    a4: '认证终端',
    a5: '加载中...',
    a6: '正常',
    a7: '通讯异常',
    a8: '维护',
    a9: '机器异常',
    a10: '故障/警报',
    a11: '维护警报/上下限警报·运行异常',
    a12: '查询',
    a13: '站点数据异常，请联系管理员'
  },
  // 电梯模块--远程控制
  elevatorecontrol: {
    a1: '远程控制',
    a2: '栋内互访',
    a3: '远程召梯',
    a4: '室内召梯',
    a5: '远程授权',
    a6: '加载中...',
    a7: '楼层时间段',
  },
  // 电梯模块--栋内互访页
  elevatorexchangevisits: {
    a1: '互访流程',
    a2: '来访者位置',
    a3: '被访者位置',
    a4: '完成',
    a5: '选择【楼层】和【房间】',
    a6: '点击【互访】',
    a7: '室内召梯',
    a8: '点击【室内召梯】',
    a9: '互访',
    a10: '来访者房间',
    a11: '被访者楼层',
    a12: '全部',
    a13: '来访者楼层',
    a14: '操作成功',
    a15: '操作失败',
  },
  // 电梯模块--远程召梯页
  elevatorRemoteemErgencyRecall: {
    a1: '全部',
    a2: '请选择召梯位置',
    a3: '前往楼层',
    a4: '前往房间',
    a5: '只召梯',
    a6: '召梯并开放楼层权限',
    a7: '操作成功',
    a8: '操作失败',
  },
  // 电梯模块--室内召梯页
  elevatorIndoorErgencyRecall: {
    a1: '全部',
    a2: '请选择召梯位置',
    a3: '召梯位置',
    a4: '室内召梯',
    a5: '操作成功',
    a6: '操作失败',
  },
  // 电梯模块--远程授权页
  controlElevator: {
    a1: '全部电梯',
    a2: '请选择电梯',
    a3: '设置电梯受限楼层',
    a4: '解除',
    a5: '投入',
    a6: '注意：一旦解除或投入后，电梯无法自动解除状态，需要手动进行状态切换。',
    a7: '临时解除单一楼层受限',
    a8: '临时解除权限的位置',
    a9: '该楼层暂无可解除权限的位置',
    a10: '解除受限',
    a11: '操作成功',
    a12: '操作失败',
  },
  // 电梯模块--楼层时间段设置页
  controlTimetable:{
    a1: '加载中....',
    a2: '楼层时间段',
    a3: '楼层时间段列表',
    a4: '编辑',
    a5: '应用',
    a6: '请选择电梯',
    a7: '楼层时间表设置',
    a8: '设置',
    a9: '取消',
    a10: '楼层',
    a11: '请选择楼层',
    a12: '主门',
    a13: '副门',
    a14: '请选择时间表',
    a15: '应用',
    a16: '当前楼层',
    a17: '全部楼层',
    a18: '公共层',
    a19: '权限层',
    a20: '主',
    a21: '副',
    a22: '楼层参数已更新,请点击[应用]才能生效。',
    a23: '楼层更新失败。详情：',
    a24: '设置时间段',
    a25: '该操作将更新楼层信息时间段，请确认是否更新？',
    a26: '确认',
    a27: '取消',
    a28: '应用成功',
    a29: '应用失败,原因',
    // tb 表
    tb1: '物理层',
    tb2: '数显',
    tb3: '属性',
    tb4: '电梯门',
    tb5: '主门时间段',
    tb6: '副门时间段',
  },
  // 设置 - 电梯时间表
  elevatorTimetable: {
    a1: '加载中....',
    a2: '添加电梯时间表',
    a3: '电梯时间表',
    a4: '关联控制器',
    a5: '时间表',
    a6: '关联',
    a7: '时间表名称',
    a8: '卡模式',
    a9: '退出卡模式',
    a10: '启用',
    a11: '关闭',
    a12: '请选择电梯时间表',
    a13: '时间表复制',
    a14: '添加关联',
    a15: '应用',
    a16: '添加时间表',
    a17: '请输入时间表名称',
    a18: '设置',
    a19: '取消',
    a20: '复制源',
    a21: '目标源',
    a22: '时间表名称更新',
    a23: '设备批量关联',
    a24: '关联的控制器',
    a25: '请选择关联的控制器',
    a26: '复制表成功',
    a27: '复制表过程失败',
    a28: '获取下一个时间段序列号时失败',
    a29: '应用成功',
    a30: '设置应用失败',
  },
  // 设置 - 电梯时间表 - 时间表页
  timetableModule:{
    a1: '加载中...',
    a2: '星期一',
    a3: '星期二',
    a4: '星期三',
    a5: '星期四',
    a6: '星期五',
    a7: '星期六',
    a8: '星期日',
    a9: '星期',
    a10: '模式',
    a11: '有效日期设置',
    a12: '有效日期',
    a13: '设置',
    a14: '取消',
    a15: '设置星期',
    a16: '年',
    a17: '月',
    a18: '日',
    a19: '至',
  },
  // 设置 - 电梯时间表 - 时间表页 - 表
  timetableModuleDT:{
    a1: '加载中...',
    a2: '时间表变更',
    a3: '时间表类型',
    a4: '电梯时间表',
    a5: '时间表名称',
    a6: '由于初始值是',
    a7: '退出卡模式',
    a8: '，所以请设置其它的模式。',
    a9: '时',
    a10: '分',
    a11: '设置',
    a12: '取消',
    a13: '卡模式',
  },
  // 设置 - 电梯时间表 - 设备关联页
  timedeviceModule:{
    a1: '加载中...',
    a2: '移除',
    a3: '未关联任何设备',
    a4: '确定与电梯时间表进行取消关联?',
    a5: '移除成功。',
    a6: '请先停用时间表，才能进行解绑。',
    a7: '设置应用成功',
    a8: '控制器启用时间段失败',
    a9: '控制器禁用时间段失败',
    a10: '与电梯时间表取消关联。',
  },
  // 电梯模块--卡片记录
  elevatorCardRecords: {
    a0: '加载中....',
    a1: '卡片记录',
    a2: '卡片编号',
    a3: '结果',
    a4: '初始日期/时间选择',
    a5: '终止日期/时间选择',
    a6: '查询',
    a7: '记录列表',
    a8: '请选择日期或时间',
    a9: '发生日期',
    a10: '认证类型',
    a11: '姓名',
    a12: '卡片编号',
    a13: '体温',
    a14: '电梯名称',
    a15: '信号/终端名称',
    a16: '建筑物名称',
    a17: '注释',
    a18: '结果',
    a19: '成功',
    a20: '失败',
    a21: '请输入卡片编号',
    a22: '全部',
    a23: '共',
    a24: '条数据',
    a25: '卡片'
  },
  elevatorQrCodeRecords: {
    a0: '加载中....',
    a1: '二维码记录',
    a2: '二维码',
    a3: '同步结果',
    a4: '初始日期/时间选择',
    a5: '终止日期/时间选择',
    a6: '查询',
    a7: '记录列表',
    a8: '请选择日期或时间',
    a9: '发生日期',
    a10: '请输入二维码',
    a11: '全部',
    a12: '共',
    a13: '条数据',
    a14: '',
    a15: '',
    a16: '',
    a17: '',
    a18: '',
    a19: '',
    a20: '',
    a21: '',
    a22: '',
    a23: '',
    a24: '',
    a25: '',
    a26: '',
    a27: '',
    a28: '',
    a29: '',
    td1: '发生日期',
    td2: '申请人',
    td3: '编号',
    td4: '二维码凭证',
    td5: '码状态',
    td6: '申请起始时间',
    td7: '有效时长',
    td8: '起始楼层',
    td9: '电梯名称',
    td10: '信号/终端名称',
    td11: '建筑物名称',
    td12: '注释',
    td13: '所属房间',
    td14: '同步时间',
    td15: '同步结果',
    s1: '成功',
    s2: '失败',
    s3: '信息异常',
    s4: '上传中',
    s5: '有效',
    s6: '无效',

  },
  elevatorRemoteRecords: {
    a0: '加载中....',
    a1: '手机召梯记录',
    a2: '指令',
    a3: '结果',
    a4: '初始日期/时间选择',
    a5: '终止日期/时间选择',
    a6: '查询',
    a7: '记录列表',
    a8: '请选择日期或时间',
    a9: '发生日期',
    a10: '认证类型',
    a11: '姓名',
    a12: '卡片编号',
    a13: '体温',
    a14: '电梯名称',
    a15: '信号/终端名称',
    a16: '建筑物名称',
    a17: '注释',
    a18: '结果',
    a19: '成功',
    a20: '失败',
    a21: '请输入卡片编号',
    a22: '全部',
    a23: '共',
    a24: '条数据',
    a25: '卡片',
    td1: '召梯指令',
    td2: '请求时间',
    td3: '响应时间',
    td4: '姓名',
    td5: '编号',
    td6: 'ID',
    td7: '所属房间',
    td8: '来访房间',
    td9: '被访房间',
    td10: '注释',
    td11: '请求结果',
    s1: '全部',
    s2: '远程开锁',
    s3: '室内召梯',
    s4: '互访',
    s5: '微信召梯',
    s6: '失败',
    s7: '信息异常',
    s8: '设备不在线',
    s9: '1号梯响应',
    s10: '2号梯响应',
    s11: '3号梯响应',
    s12: '4号梯响应',
    s13: '5号梯响应',
    s14: '6号梯响应',
    s15: '7号梯响应',
    s16: '8号梯响应',
    s17: '9号梯响应',
    s18: '10号梯响应',
    s19: '11号梯响应',
    s20: '12号梯响应',
    s21: '13号梯响应',
    s22: '14号梯响应',
    s23: '15号梯响应',
    s24: '16号梯响应'
  },
  // 电梯模块--实际数据
  elevatorRealdata: {
    a1: '加载中',
    a2: '报表数据',
    a3: '全部',
    a4: '卡',
    a5: '读卡器',
    a6: '类型',
    a7: '月报',
    a8: '日报',
    a9: '输出时间：',
    a10: '文件拆分数：',
    a11: '输出日数：',
    a12: '1 生成一个文件而不进行拆分',
    a13: '2 每15天划分一次(包括最后一个文件的结束日期)生成文件',
    a14: '3 每20天划分一次(包括最后一个文件的结束日期)生成文件',
    a15: '4 每7天划分一次(包括最后一个文件的结束日期)生成文件',
    a16: '5 每6天划分一次(包括最后一个文件的结束日期)生成文件',
    a17: '如果输出量很大，则除法的数目会自然更改为5',
    a18: '下载文件名称：',
    a19: '输出中...',
    a20: '输出',
    a21: '文件拆分说明',
    a22: '姓名',
    a23: '请输入姓名',
    a24: '卡片编号',
    a25: '请输入卡片编号',
    a26: '卡ID',
    a27: '请输入卡ID',
    a28: '电梯读卡器',
    a29: '门禁读卡器',
    a30: '查询',
    a31: '认证信息清单',
    a32: '条数据',
    a33: '管理所属',
    a34: '没有选择日期',
    a35: '请选择日期',
    a36: '所属控制器',
    a37: '共',
    a38: '设备名称',
    a39: '请输入装置名称',
    a40: '门清单',
  },
  // 电梯模块--运行状态
  elevatorRunning: {
    a1: '加载中',
    a2: '电梯筛选',
    a3: '消防/火灾',
    a4: 'VIP直驶',
    a5: 'ITM退出',
    a6: 'ITM投入',
    a7: '故障',
    a8: '设备未接入平台',
    a9: '正在请求电梯状态',
    a10: '获取电梯状态失败',
    a11: '参数异常',
    a12: '电梯未存在',
    a13: '控制器参数异常',
    a14: '正常',
    a15: '上行方向',
    a16: '运动上行',
    a17: '下行方向',
    a18: '运动下行',
    a19: '静止/无方向',
  },
  //帐号设置
  accountModal: {
    a1: '帐号分组',
    a2: '所属',
    a3: '帐号ID',
    a4: '帐号',
    a5: '验证码',
    a6: '有效',
    a7: '无效',
    a8: '帐号权限',
    a9: '发送测试邮件',
    a10: '密码',
    a11: '修改密码',
    a12: '最后修改日期',
    a13: '密码有效期',
    a14: '发送邮件',
    a15: '记录未下载成功发送邮件',
    a16: '下载失败',
    a17: '次发送邮件',
    a18: '更改密码',
    a19: '发送邮件出错',
    a20: '新旧密码相同',
    a21: '旧密码输入错误',
    a22: '存在非法文字',
    a23: '密码太长',
    a24: '其他错误',
    a25: '密码验证错误',
  },

  //公共指南
  commonInfoSetting: {
    a1: '指南',
    a2: '最后更新人员',
    a3: '查询',
    a4: '确定要删除所选项',
    a5: '确定',
    a6: '取消',
    a7: '删除',
    a8: '指南类型',
    a9: '显示时间段',
    a10: '重要信息',
    a11: '添加',
    a12: '变更',
    a13: '通知设置',
    a14: '条数据',
    a15: '公共',
    a16: '设备',
    a17: '能源',
    a18: '门禁',
    a19: '视频发送',
    a20: '简单节能',
    a21: '空调能源节约',
    a22: '需求响应',
    a23: '门禁数据输出',
    a24: '变更成功',
    a25: '变更失败：',
    a26: '添加成功',
    a27: '添加失败：',
    a28: '删除成功',
    a29: '删除失败：',
    a30: '新建/修改',
    a31: '最后更新日期时间',
    a32: '最后更新人员',
    a33: '显示对象',
    a34: '显示开始时间',
    a35: '显示结束时间',
    a36: '公共指南'
  },

  //下载
  downloadSetting: {
    a1: '下载名称',
    a2: '文件名称',
    a3: '最后更新人员',
    a4: '查询',
    a5: '确定要删除所选项',
    a6: '确定',
    a7: '取消',
    a8: '删除',
    a9: '添加',
    a10: '变更',
    a11: '通知设置',
    a12: '条数据',
    a13: '新建/修改',
    a14: '最后更新日期时间',
    a15: '最后更新人员',
    a16: '显示对象',
    a17: '变更成功',
    a18: '变更失败：',
    a19: '添加成功',
    a20: '添加失败：',
    a21: '删除成功',
    a22: '删除失败：',
    a23: '上传',
    a24: '只能选择一项进行修改',
  },

  //详情显示窗口
  editModal: {
    a1: '显示时间段',
    a2: '重要信息',
  },

  //通知设置管理主页
  equipment: {
    a1: '公共指南',
    a2: '咨询处',
    a3: '继承事项',
    a4: '下载',
    a5: '通知设置',
  },

  //咨询处
  inquireSetting: {
    a1: '咨询',
    a2: '最后更新人员',
    a3: '查询',
    a4: '确定要删除所选项',
    a5: '确定',
    a6: '取消',
    a7: '删除',
    a8: '添加',
    a9: '变更',
    a10: '通知设置',
    a11: '条数据',
    a12: '新建/修改',
    a13: '最后更新日期时间',
    a14: '最后更新人员',
    a15: '显示对象',
    a16: '显示开始时间',
    a17: '显示结束时间',
    a18: '变更成功',
    a19: '变更失败：',
    a20: '添加成功',
    a21: '添加失败：',
    a22: '删除成功',
    a23: '删除失败：',
    a24: '交接事项',
  },

  //帐号设置密码修改窗口
  passwordModal: {
    a1: '当前密码1',
    a2: '新密码1',
    a3: '确认密码1',
    a4: '当前密码2',
    a5: '新密码2',
    a6: '确认密码2',
  },

  //信息通知主页
  infoHome: {
    a1: '通知',
    a2: '最后更新日期：',
    a3: '公共指南',
    a4: '快捷方式',
    a5: '咨询地址',
    a6: '下载',
    a7: '注册/设定',
    a8: '交接事项',
    a9: '弹出窗名称',
    a10: '帐号设置',
    a11: '添加成功',
    a12: '添加失败：',
    a13: '更新用户信息',
    a14: '更新成功',
    a15: '更新失败',
  },

  //设置管理主页
  setHome: {
    a1: '客户中心',
    a2: '用户设置',
    a3: '通知设置',
    a4: '卡号管理',
    a5: '控制器管理',
    a6: '警报管理',
    a7: '监控中心',
    a8: '客户、建筑群和建筑站点数据：',
    a9: 'menu 出错',
  },

  //警报管理页
  notificationEquipment: {
    a1: '警报设置',
    a2: '警报记录信息',
    a3: '通知设置',
    a4: '警报管理',
    a5: '邮件发送时间表',
  },

  //警报通知设置
  notification: {
    a1: '选择站点信息',
    a2: '摄像机',
    a3: '录像机',
    a4: '查询',
    a5: '抑制',
    a6: '不抑制',
    a7: '确定要进行设置',
    a8: '确定',
    a9: '取消',
    a10: '设置',
    a11: '确定取消设置',
    a12: '通知设置',
    a13: '条数据',
    a14: '警报ID',
    a15: '警报信息',
    a16: '警报接收',
    a17: '管制通知',
    a18: '设置失败，无设置值',
    a19: '设置成功',
    a20: '设置失败：',
  },

  //警报记录
  warningRecord: {
    a1: '加载中....',
    a2: '建筑代码',
    a3: '初始日期/时间选择',
    a4: '终止日期/时间选择',
    a5: '选项',
    a6: '值',
    a7: '企业代码',
    a8: '发送数据类别',
    a9: '管制类别',
    a10: '查询',
    a11: '警报记录列表',
    a12: '强制恢复',
    a13: '类别变更',
    a14: '目前的发报类型是：',
    a15: '变更后：',
    a16: '设置',
    a17: '取消',
    a18: '全部',
    a19: '客户编号',
    a20: '建筑名称',
    a21: '管理代码',
    a22: '监控中心名称',
    a23: '未恢复发送数据',
    a24: '测试发报',
    a25: '正式发报',
    a26: '作业发报',
    a27: '批量发报',
    a28: '通信异常',
    a29: '日立楼宇',
    a30: '正式警报',
    a31: '试验警报',
    a32: '作业警报',
    a33: '批量警报',
    a34: '发报类型变更',
    a35: '条数据',
    a36: '企业名称',
    a37: '客户名称',
    a38: '客户管理编号',
    a39: '建筑物名称',
    a40: '监控中心名称',
    a41: '设备名称',
    a42: '信号/终端名称',
    a43: '发报类型',
    a44: '警报类型',
    a45: '警报内容',
    a46: '发生日期',
    a47: '恢复日期',
    a48: '处理邮件',
    a49: '注释',
    a50: '请选择要变更类别的项',
    a51: '请选择要恢复的项',
  },
  //联系方式选择页
  deviceContact: {
    a1: '加载中....',
    a2: '客户名称',
    a3: '建筑物名称',
    a4: '联系方式名称',
    a5: '联系方式1~3',
    a6: '查询',
    a7: '联系方式列表',
    a8: '联系方式1',
    a9: '联系方式2',
    a10: '联系方式3',
    a11: '添加',
    a12: '更新',
    a13: '删除',
    a14: '确定',
    a15: '输入点设备编辑',
    a16: '条数据',
    a17: '认证终端没有被选择',
    a18: '请选择需要被改变的认证终端',
    a19: '联系方式没有被选择',
    a20: '请选择需要被删除的联系方式',
    a21: '删除成功',
    a22: '请选择要更新的联系方式',
    a23: '更新成功',
    a24: '联系方式名称已存在',
  },
  //警报设置编辑页--编辑设备
  deviceEditor: {
    a1: '加载中....',
    a2: '设备',
    a3: '名称',
    a4: '注册设备Gr1',
    a5: '注册设备Gr2',
    a6: '注册设备Gr3',
    a7: '查询',
    a8: '全部的设备',
    a9: '添加',
    a10: '删除',
    a11: '已注册的设备',
    a12: '设置',
    a13: '输入点设备编辑',
    a14: '条数据',
    a15: '门禁',
    a16: '全部',
    a17: '电气锁',
    a18: '自动门',
    a19: '闸机',
    a20: '警备',
    a21: '接点',
    a22: '视频',
    a23: '摄像机',
    a24: '录像机',
    a25: '设备名称',
    a26: '信号名称',
    a27: '类型',
    a28: '认证终端没有被选择',
    a29: '请选择需要被改变的认证终端',
    a30: '设备没有被选择',
    a31: '请选择需要被改变的设备',
    a32: '只能添加相同类型设备信号',
  },
  //警报管理页--警报设置
  warningSetting: {
    a1: '选择站点信息',
    a2: '警报类型',
    a3: '负责人',
    a4: '查询',
    a5: '删除',
    a6: '编辑',
    a7: '新增',
    a8: '企业代码',
    a9: '客户编号',
    a10: '客户名称',
    a11: '建筑名称',
    a12: '警报对象',
    a13: '设备',
    a14: '系统',
    a15: '设备名称',
    a16: '类型',
    a17: '警报设置',
    a18: '说明',
    a19: '处理方法',
    a20: '设备联系方式1',
    a21: '设备联系方式2',
    a22: '设备联系方式3',
    a23: '设备联系方式4',
    a24: '设备联系方式5',
    a25: '设备联系方式6',
    a26: '警报处理手册',
    a27: '对应区分',
    a28: '是否出动',
    a29: '电话对应',
    a30: 'HBS出动',
    a31: '警报显示',
    a32: '显示',
    a33: '发报',
    a34: '是',
    a35: '否',
    a36: '邮件发送',
    a37: '启动时发送',
    a38: '恢复时发送',
    a39: '声音设置',
    a40: '没有警报',
    a41: '发生火灾',
    a42: '发生停电',
    a43: '发生大故障',
    a44: '发生小故障',
    a45: '更新时间',
    a46: '更新人员',
    a47: '发送设置',
    a48: '用户选择',
    a49: '发送邮件地址',
    a50: '发送时间表',
    a51: '发送日历',
    a52: '设置',
    a53: '取消',
    a54: '设备选择',
    a55: '联系方式',
    a56: '条数据',
    a57: '设备联系1名称',
    a58: '设备联系1方式1',
    a59: '设备联系1方式2',
    a60: '设备联系1方式3',
    a61: '创建人员',
    a62: '监视时间表',
    a63: '请选择添加人员站点信息',
    a64: '发送邮件地址(To)',
    a65: '发送邮件地址(Cc)',
    a66: '发送邮件地址(Bcc)',
  },


  energy: {
    ok: '设置',
    cancel: '取消',
    add: '添加',
    remove: '删除',
    search: '查询',
    showTotal: '共 ${total} 条数据',
    placeSelect: '请选择',
    setting: {
      reportGr: {
        inputLabel: '请输入${label}',
        spLabel: '${label}不能包含特殊字符!',
        lenLabel: '${label}长度必须在 ${min} 到 ${max}之间',
        deleLabel: '确认要删除${label}【${name}】吗？',
        saveLabel: '确认要保存所设置的${label}吗？',
        saveSuce: '数据保存成功',
        saveFail: '数据保存失败',
        loadFail: '数据加载失败',
        daily: { name: '日报组', nameLabel: '日报组名称', edit: '日报计量点编辑' },
        monthly: { name: '月报组', nameLabel: '月报组名称', edit: '月报计量点编辑' },
        yearly: { name: '年报组', nameLabel: '年报组名称', edit: '年报计量点编辑' },
        trendGr: { name: '趋势组', nameLabel: '趋势组名称', edit: '计量点编辑',chartName:'趋势图' },
        timeTrendGr: { name: '时间设置趋势组', nameLabel: '时间设置趋势组名称', edit: '计量点编辑',chartName:'时间设置趋势图' },
        empty: '请选择一个分组。',
        stop: '禁止输入以下特殊字符',
        grlabel: '分组名称',
        kikiName: '设备名称',
        pointName: '信号名称',
        dataKind: '数据类型',
        numberTips: '日报/月报/年报可设置的计量点最大数量为32个。',
        trendTips: '趋势组/时间趋势组可设置的计量点最大数量为12个。',
        saveMP: '确认要保存所设选择的计量点吗？',
        addMp: '请选择需要添加的计量点。',
        removeMp: '请选择需要删除的计量点。',
        limitMp: '所选择的计量点数量不可超过32个。',
        groupingLabel:"非同一等级的计量参数被分入同一分组，是否继续？",
      },
      eneLinkage:{
        title:'联动控制规则组',
        empty: '请选择一个规则组！',
        groupName:'规则组名称',
        name:'规则名称',
        add: '添加',
        rule: '规则',
        save: '保存',
        inputLabel: '请输入${label}',
        spLabel: '${label}不能包含特殊字符!',
        lenLabel: '${label}长度必须在 ${min} 到 ${max}之间',
        deleLabel: '确认要删除${name}吗？',
        saveLabel: '确认要保存所设置的${label}吗？',
        stop: '禁止输入以下特殊字符',
        repetition:"信号点重复选择",
        loadFail: '数据加载失败',
        outputAlert:"存在不完整输出项，请补充完整后重试",
        inputAlert:"存在不完整条件项，请补充完整后重试",
      },
      enePeakValleySet:{
        title:'分段设置',
        empty: '请选择一个分段！',
        groupName:'分段名称',
        subGroupName:'分组名称',
        periodOfTimeName:'时间段名称',
        add: '添加',
        rule: '规则',
        save: '保存',
        inputLabel: '请输入${label}',
        spLabel: '${label}不能包含特殊字符!',
        lenLabel: '${label}长度必须在 ${min} 到 ${max}之间',
        deleLabel: '确认要删除${name}吗？',
        saveLabel: '确认要保存所设置的${label}吗？',
        stop: '禁止输入以下特殊字符',
        conflict:'生效周期冲突',
        inputPeriod:'请选择时间段',
      },
    },
    enedata001: "统计数据",
    enedata002: "类型",
    enedata003: "计量对象",
    enedata004: "图表类型",
    enedata005: "目标年度",
    enedata006: "查询",
    enedata007: "累计使用量",
    enedata008: "累计",
    enedata009: "使用量",
    enedata010: "预测数据",
    enedata011: "实际数据",
    enedata012: "显示对象",
    enedata013: "折线图",
    enedata014: "柱状图",
    enedata015: "饼状图",
    enedata016: "时间",
    enedata017: "日期",
    enedata018: "对比图表",
    enedata019: "实际使用量",
    enedata020: "原单位指标",
    enedata021: "CO2换算系数",
    enedata022: "※上端表示「使用量」、下端表示「热量」。(水能的情况下仅显示使用量)",
    enedata023: "设置",
    enedata024: "计量点",
    enedata025: "全选",
    enedata026: "全不选",
    enedata027: "打印预览",
    enedata028: "注册设备变更",
    enedata029: "费用CSV输出",
    enedata030: "执行",
    enedata031: "请选择一个分组。",
    enedata032: "用途",
    enedata033: "请选择建筑站点。",
    enedata034: "日报",
    enedata035: "月报",
    enedata036: "年报",
    enedata037: "时间期限",
    enedata038: "请输入数字。",
    enedata039: "日度数据日期期限不可超过一天。",
    enedata040: "月度数据日期期限不可超过一个月。",
    enedata041: "年度数据日期期限不可超过一年。",
    enedata042: "报表导出",
    enedata043: "综合报表",
    enedata044: "数据输出",
    enedata045: "计量点类型",
    enedata046: "年度",
    enedata047: "输出",
    enedata048: "输出日期",
    enedata049: "设置想输出的时间，然后点击输出按钮。",
    enedata050: "1年以内",
    enedata051: "6个月以内",
    enedata052: "3个月以内",
    enedata053: "1年以内",
    enedata054: "13个月以内",
    enedata055: "每月数据期限需在1年以内",
    enedata056: "每日数据期限需在6个月以内",
    enedata057: "每小时数据期限需在3个月以内",
    enedata058: "换算系数数据期限需在1年以内",
    enedata059: "电力需求平准化数据期限需在13个月以内",
    enedata060: "能源类型",
    enedata061: "平准化",
    enedata062: "节能管理",
    enedata063: "统计年度比较",
    enedata064: "建筑物比较",
    enedata065: "没有数据。",
    enedata066: "没有计量点。",
    enedata067: "目标(平准化换算后)",
    enedata068: "实际(平准化换算后)",
    enedata069: "单位(月)",
    enedata070: "单位(年度)",
    enedata071: "平准化换算前",
    enedata072: "平准化换算值",
    enedata073: "平准化换算后",
    enedata074: "年度合计",
    enedata075: "显示层级",
    enedata076: "客户",
    enedata077: "建筑",
    enedata078: "站点",
    enedata079: "租户",
    enedata080: "目标年度没有可见能源",
    enedata081: "设置成功",
    enedata082: "设置失败",
    enedata083: "租户输入",
    enedata084: "费用计算",
    enedata085: "实际值输入",
    enedata086: "未输入确认",
    enedata087: "能源",
    enedata088: "选项",
    enedata089: "包括租户",
    enedata090: "确定对象",
    enedata091: "仅显示未输入",
    enedata092: "选择客户并点击确定对象按钮",
    enedata093: "未输入",
    enedata094: "已输入",
    enedata095: "存在未输入项。请在输入所有项后再次执行。",
    enedata096: "单价",
    enedata097: "分",
    enedata098: "角",
    enedata099: "元",
    enedata100: "用电类型",
    enedata101: "峰谷时段",
    enedata102: "高峰",
    enedata103: "平段",
    enedata104: "低谷",
    enedata105: "计量值",
    enedata106: "计量计数器",
    enedata107: "累计数据",
    enedata108: "时数据",
    enedata109: "日数据",
    enedata110: "月数据",
    enedata111: "维护登记",
    enedata112: "权重",
    enedata113: "计量单位",
    enedata114: "声音类别",
    enedata115: "上下限值监视",
    enedata116: "上限值异常监视",
    enedata117: "下限值异常监视",
    enedata118: "使用率的上限值",
    enedata119: "使用率的下限值",
    enedata120: "速率间隔",
    enedata121: "秒",
    enedata122: "使用率(使用量/速率间隔)",
    enedata123: "当前值",
    enedata124: "评论注册栏",
    enedata125: "最多5条评论",
    enedata126: "点击编写评论",
    enedata127: "清空",
    enedata128: "刷新",
    enedata129: "确认",
    enedata130: "关联信号点",
    enedata131: "取值范围",
    enedata132: "上限值",
    enedata133: "下限值",
    enedata134: "死区",
    enedata135: "0~（上限值-下限值）范围内设置",
    enedata136: "点击编辑名称",
    enedata137: "功率因素",
    enedata138: "数据加载失败",
    enedata139: "趋势图",
    enedata140: "确定要清除所有评论内容吗?",
    enedata141: "状态值",
    enedata142: "ON名称",
    enedata143: "OFF名称",
    enedata144: "维护监视",
    enedata145: "时间表控制",
    enedata146: "控制状态",
    enedata147: "上述评论对于本点所属设备是共通的。",
    enedata148: "COS监视",
    enedata149: "视频",
    enedata151: "操作",
    enedata152: "状态类别",
    enedata153: "条件不一致监视",
    enedata154: "监控时间",
    enedata155: "设置值",
    enedata156: "故障报警时间超过故障监视报警时间",
    enedata157: "小时",
    enedata158: "故障报警次数超过故障监视报警次数",
    enedata159: "次",
    enedata160: "累计运行时间超过监控点运行时间",
    enedata161: "连续运行时间超过监视连续运行时间",
    enedata162: "状态变化次数超过监视状态变化次数",
    enedata163: "基本单位指标",
    enedata164: "名称",
    enedata165: "单位",
    enedata166: "输入方法",
    enedata167: "计算方法",
    enedata168: "全年值",
    enedata169: "删除",
    enedata170: "手动输入",
    enedata171: "计算",
    enedata172: "平均",
    enedata173: "注册",
    enedata174: "名称没有输入。",
    enedata175: "单位没有输入。",
    enedata176: "计算方法没有被选择。请选择计算方法。",
    enedata177: "计算方法不能选择自己。",
    enedata178: "计算方法不能选择相同的项目。",
    enedata179: "计算方法不能选择未注册的项目。",
    enedata180: "数据注册成功。",
    enedata181: "数据注册失败。",
    enedata182: "数据删除成功！",
    enedata183: "数据删除失败！",
    enedata184: "确认要进行设定内容注册吗？",
    enedata185: "确认要进行删除操作吗？",
    enedata186: "确认要放弃所编辑的数据吗？",
    enedata187: "确认要删除该运算点吗?",
    enedata188: "公式",
    enedata189: "编辑",
    enedata190: "点GR设置",
    enedata191: "添加",
    enedata192: "注册名称",
    enedata193: "运算式类型",
    enedata194: "运算类型",
    enedata195: "共${total}条数据",
    enedata196: "运算点设置",
    enedata197: "请先选择需要删除的运算点",
    enedata198: "运算式",
    enedata199: "所属",
    enedata200: "首页数据展示",
    enedata201: "是",
    enedata202: "否",
    enedata203: "单位名称",
    enedata204: "选择“自由单位”时可以在【自由单位】中输入单位；单位修改不影响运算。",
    enedata205: "请选择单位名称!",
    enedata206: "自由单位",
    enedata207: "小数点以下位数",
    enedata208: "信号类型",
    enedata209: "设备列表",
    enedata210: "加减运算设置",
    enedata211: "加法选择设备列表",
    enedata212: "选择",
    enedata213: "解除",
    enedata214: "减法选择设备列表",
    enedata215: "一般运算设置",
    enedata216: "选择运算式",
    enedata217: "请选择设备信号点",
    enedata218: "不能包含特殊字符",
    enedata219: "运算点名称长度必须在 1 到 30之间",
    enedata220: "自由单位长度必须在 0 到 30之间",
    enedata221: "公式长度必须在 0 到 30之间",
    enedata222: "请输入运算点名称",
    enedata223: "请输入运算点公式",
    enedata224: "请选择运算点类型",
    enedata225: "请选择运算式",
    enedata227: "请选择小数点以下位数",
    enedata228: "请选择所属",
    enedata229: "确认要保存所设置的运算点信息吗？",
    enedata230: "请选择所要设置的设备。",
    enedata231: "电力需求平准化报告首年度设置",
    enedata232: "计量类型",
    enedata233: "7月检查日",
    enedata234: "12月检查日",
    enedata235: "计量信号编号",
    enedata236: "计量信号名称",
    enedata237: "请选择能源类型",
    enedata238: "确认要放弃所编辑的能源类型计量对象信息吗？",
    enedata239: "确认要保存所设置的能源类型计量对象信息吗？",
    enedata240: "削减目标",
    enedata241: "建筑/租户",
    enedata242: "包括建筑群",
    enedata243: "包括建筑",
    enedata244: "※当客户或建筑组选择时，该阶层以下的全部削减目标（除了租户）将被覆盖。 另外，选择【包括租户】的话，那个楼层以下包括租户的削减目标将全部被覆盖，请注意。",
    enedata245: "※削减目标是自动继承前一年度的数据。因此，如果需要变更的话，请每年重新设定。 ",
    enedata246: "能源使用量",
    enedata247: "上一年度比",
    enedata248: "目标值",
    enedata249: "最后更新日",
    enedata250: "确认要保存所设置的削减目标吗？",
    enedata251: "如果将削减目标值更改为空白井进行注册，则削减目标和上次修改如期将被删除，确定要注册吗？",
    enedata252: "基本单位指标值",
    enedata253: "确认要保存所设置的基本单位指标值吗？",
    enedata254: "建筑名称",
    enedata255: "全年通用",
    enedata256: "虚拟点设置",
    enedata257: "确认要删除该虚拟点吗？",
    enedata258: "请选择需要删除的虚拟点",
    enedata259: "能源/选项",
    enedata260: "名称不能包含特殊字符!",
    enedata261: "请输入虚拟点名称",
    enedata262: "虚拟点名称长度必须在 1 到 30之间",
    enedata263: "请选择能源/选项",
    enedata264: "确认要保存所设置的虚拟点信息吗？",
    enedata265: "能源使用类型设置",
    enedata266: "现在已注册能源",
    enedata267: "燃气类型",
    enedata268: "能源供应公司",
    enedata269: "租户权原分",
    enedata270: "费用",
    enedata271: "能源换算",
    enedata272: "白天",
    enedata273: "夜间",
    enedata274: "产气率",
    enedata275: "值",
    enedata276: "第一年度电力需求平准化报告",
    enedata278: "能源使用类型",
    enedata279: "确认要保存所设置的能源使用类型吗？",
    enedata280: "已设置费用信号点，不许取消设置；请在计量点-本司/租户区分设置画面确认费用信号点设置。",
    enedata281: "所选择能源使用类型已设置租户权原分计量信号点，不许取消设置；请在计量点-本司/租户区分设置画面确认租户权原分计量信号点设置。",
    enedata282: "所选择能源使用类型已设置计量信号点，不许取消注册；请在计量点-能源类型设置画面确认计量信号点设置。",
    enedata283: "确认要删除所选择的点Gr信息吗？",
    enedata284: "确认要保存所设置的点Gr信息吗？",
    enedata286: "总使用量",
    enedata287: "租户使用量",
    enedata288: "所选择设备计量点已被注册",
    enedata289: "还没有相应能源使用类型的设备信号点；请在运算点管理中进行注册，并在能源类型中进行设置。",
    enedata290: "请选择建筑/租户",
    enedata291: "请选择目标年度",
    enedata292: "计量点管理",
    enedata293: "设置内容",
    enedata294: "本司/租户区分",
    enedata295: "计量对象区分",
    enedata296: "虚拟点",
    enedata297: "供应商",
    enedata298: "编号",
    enedata299: "确认要保存所设置的计量对象组详细信息吗？",
    enedata300: "确认要保存所设置的计量对象组名称吗？",
    enedata301: "确认要删除计量对象组【${text}】吗？",
    enedata302: "请输入计量对象组名称",
    enedata303: "计量对象组名称长度必须在 1 到 30之间",
    enedata304: "计量对象组",
    enedata305: "计量对象组名称不能包含特殊字符!",
    enedata306: "点击编写设备名称",
    enedata307: "请输入{text}	",
    enedata308: "{text}长度必须在{star} 到 {end}之间	",
    enedata309: "{text}不能包含特殊字符!	",
    enedata310: "4月",
    enedata311: "5月",
    enedata312: "6月",
    enedata313: "7月",
    enedata314: "8月",
    enedata315: "9月",
    enedata316: "10月",
    enedata317: "11月",
    enedata318: "12月",
    enedata319: "1月",
    enedata320: "2月",
    enedata321: "3月",
    enedata322: "请选择{text}	",
    enedata323: "请输入公式	",
    enedata324: "请输入自由单位	",
    enedata325: "添加点Gr	",
    enedata326: "请选择点所属	",
    enedata327: "点Gr	",
    enedata328: "请输入点Gr名称	",
    enedata329: "点Gr名称不能包含特殊字符!	",
    enedata330: "点Gr名称长度必须在 1 到 30之间	",
    enedata331: "添加计量对象组	",
    enedata332: "请选择计量对象	",
    enedata333: "重置	",
    enedata334: "记录",
    enedata335: "记录列表",
    enedata336: "记录类型",
    enedata337: "日志记录",
    enedata338: "趋势记录",
    enedata339: "开始日期",
    enedata340: "结束日期",
    enedata341: "设备名称",
    enedata342: "信号编号",
    enedata343: "信号名称",
    enedata344: "信号点",
    enedata345: "设备",
    enedata346: "请选择开始日期",
    enedata347: "请选择结束日期",
    enedata348: "用电量",
    enedata349: "时间",
    enedata350: "日期",
    enedata351: "信号编号",
    enedata352: "计量值",
    enedata353: "计量单位",
    enedata354: "复制换算系数。",
    enedata355: "在目标年度中选择了当前年度。确认复制换算系数吗？",
    enedata356: "在目标年度中选择了过去的年度。确认复制换算系数吗？",
    enedata357: "确认复制换算系数吗？",
    enedata358: "取得换算系数失败。",
    enedata359: "找不到能源类型组的换算值单位。",
    enedata360: "注册成功。",
    enedata361: "现在处于难以联系的状态，给您添麻烦。请过一段时间再执行。",
    enedata362: "换算值超过设置值限制，请重新输入。",
    enedata363: "能量换算值超过设置值限制，请重新输入。",
    enedata364: "调整前CO2换算值超过设置值限制，请重新输入。",
    enedata365: "调整后CO2换算值超过设置值限制，请重新输入。",
    enedata366: "LPG单位变换换算量超过设置值限制，请重新输入。",
    enedata367: "No.{num}的换算值未输入的情况下，不能选择确定检查框。",
    enedata368: "No.{num}的换算值，请全部未输入或全部输入。",
    enedata369: "因为超过了上年度复制的范围，所以{num}年不能实行。",
    enedata370: "上年度复制",
    enedata371: "{name}的输入超出范围。",
    enedata372: "{name}的输入超出范围。请在{min}～{max}的范围内设置。",
    enedata373: "{name}输入值错误。",
    enedata374: "下限值大于上限值。",
    enedata375: "确认状态",
    enedata376: '无数据，不能生成文件，无法下载。',
    enedata377: "用水量",
    enedata378: "趋势记录：设备每隔5分钟记录一次的数据。",
    enedata379: "日志记录：设备每隔一小时记录一次的数据。",
    enedata380: "实际值能源站点每月实际的能源使用量。该功能是提供给客户输入和修改实际的能源使用量和实际费用的。",
    enedata381: "未输入确认功能是对顾客实绩输入画面中的输入数据，每个月进行确认以及进行数据的确定的功能。",
    enedata382: "节能管理画面是用图表显示能源使用量和费用的月变化的功能。对比显示每个月的实际数据与节能目标数据。",
    enedata383: "统计年度比较画面是使用图表比较「当年度」「上年度」「上上年度」每月、每年度的实际能源使用量的画面",
    enedata384: "建筑比较画面是显示能源使用量或费用及租户使用量的建筑物比较的画面。",
    enedata385: "统计数据画面是将每个计量对象的能源使用量以月为单位、日为单位、时间为单位进行图表显示的画面。",
    enedata386: "累计图表画面是将每个计量对象组的能源使用量以月为单位、日为单位、小时为单位进行图表显示的画面。",
    enedata387: "使用量实绩画面是对电，煤气，油，水，其他的能源，按每月进行原单位换算和CO2排放量换算的画面。",
    enedata388: "削减目标是注册指定年度的能源使用量的节能目标的画面。",
    enedata389: "基本单位指标的注册,计算基本单位时使用的分母的注册画面。 ",
    enedata390: "基本单位指标的输入画面是为每个月单位的基本单位指标的登记、确认的画面。",
    enedata391: "能源使用类型设置画面是进行建筑物的能源供给公司和类型的登记的画面。",
    enedata392: "计量点管理画面是登记、确认各建筑物和租赁的能源供应公司及点的画面。",
    enedata393: "计量点管理画面包含了能源类型设定画面、本司/租户区分注册画面、计量对象区分注册画面、虚拟点注册画面4个画面。",
    enedata394: "能源类型设置画面是对点设定供给公司和能源类型的画面。  ",
    enedata395: "本司/租户区分注册画面对点进行区分（总使用量/租户使用量/租户权原分）的注册画面;本司/租户区分仅可以设置运算点及虚拟点。",
    enedata396: "计量对象区分注册画面是对点进行计量对象区分（例：空调/照明等）的注册画面。",
    enedata397: "虚拟点注册画面是登记用于接收费用和自动计量以外的数据的点的画面。",
    enedata398: "自定义日期",
    enedata399: "起始月份",
    enedata400: "楼层/租户",
    enedata401: "点击编写信号点名称",
    enedata402:"发布信息",
    enedata403:"标题字体设置",
    enedata404:"副标题字体设置",
    enedata405:"标签项名称字体设置",
    enedata406:"标签项内容字体设置",
    enedata407:"警告提示字体设置",
    enedata408:"备注字体设置",
    enedata409:"是否发送到显示器",
    enedata410:"大小",
    enedata411:"颜色",
    enedata412:"仅保存",
    enedata413:"同步到显示器",
    enedata414:"字体样式设置",
    enedata415:"请输入标题。",
    enedata416:"请输入副标题。",
    enedata417:"请输入标签项名称。",
    enedata418:"请输入标签项内容。",
    enedata419:"请输入警告提示。",
    enedata420:"请输入备注。",
    enedata421:"标题长度必须在1到30之间。",
    enedata422:"副标题长度必须在1到30之间。",
    enedata423:"标签项名称长度必须在1到30之间。",
    enedata424:"标签项内容长度必须在1到30之间。",
    enedata425:"警告提示长度必须在1到30之间。",
    enedata426:"备注长度必须在1到100之间。",
    enedata427:"仅发布信息",
    enedata428:"仅设置字体样式",
    enedata429:"同时发布信息和设置字体样式",
    enedata430:"请选择日期",
    enedata431:"选择颜色",
    enedata432:"请选择颜色",
    enedata433:"颜色代码格式错误",
    enedata434: "点击跳转警报设置页面",
    enedata435: "计量信息",
    enedata436:"上次计量日期",
    enedata437: "计量有效期(月)",
    enedata438: "下次计量日期",
    enedata439:"日期",
    enedata440:"选择周",
    enedata441:"设备/信号名称",
    enedata442:"月份",
    enedata443:"碳排放",
  	enedata444:"设备联动设置",
    enedata445:"上一日",
    enedata446:"下一日",
    enedata447:"上一小时",
    enedata448:"下一小时",
    enedata449:"趋势列表",
    enedata450:"请选择年度",
    enedata451:"区域",
    enedata452:"请选择区域",
    enedata453:"换算系数",
    enedata454:"请输入换算系数",
    enedata455:"换算单位",
    enedata456: "请输入换算单位",
    enedata457: "换算单位长度必须在 1 到 30之间",
    enedata458: "确认要保存所设置的换算系数信息吗？",
    enedata459: "确认要删除该换算系数吗？",
    enedata460: "请先选择需要删除的换算系数！",
    enedata461: "确认要删除所选择的换算系数吗？",
    enedata462: "站点能源换算系数设置",
    enedata463: "区域能源换算系数设置",
    enedata464: "能源换算系数",
    enedata465: "站点能源换算系数",
    enedata466: "区域能源换算系数",
    enedata467: '设备类型',
    enedata468: '请选择设备类型！',
    enedata469: '请选择关联信号！',
    enedata470: '请输入虚拟设备名称！',
    enedata471: "虚拟设备名称长度必须在 1 到 30之间",
    enedata472: '请选择',
    enedata473: '设备所属',
    enedata474: '虚拟设备设置',
    enedata475: "确认要删除该虚拟设备吗？",
    enedata476: "请先选择需要删除的虚拟设备！",
    enedata477: "确认要删除所选择的虚拟设备吗？",
    enedata478: '虚拟设备管理',
    enedata479: '运算点管理',
    enedata480: '请输入生产线名称！',
    enedata481: "生产线名称长度必须在 1 到 30之间",
    enedata482: '生产线',
    enedata483: "生产线设置",
    enedata484: "生产线名称",
    enedata485: "确认要保存所设置的生产线信息吗？",
    enedata486: "确认要删除该生产线吗？",
    enedata487: "添加生产线",
    enedata488: "编辑生产线",
    enedata489: "产品名称",
    enedata490: "生产订单号",
    enedata491: "生产开始日期",
    enedata492: "生产结束日期",
    enedata493: "生产数量",
    enedata494: "产品单位",
    enedata495: "生产能耗",
    enedata496: "能耗单位",
    enedata497: "生产单耗",
    enedata498: "单耗单位",
    enedata499: '生产单耗管理',
    enedata500: '请选择生产线',
    enedata501: '请输入产品名称',
    enedata502: '请输入生产订单号',
    enedata503: "请选择生产开始日期",
    enedata504: "请选择生产结束日期",
    enedata505: "请输入生产数量",
    enedata506: "请输入产品单位",
    enedata507: "请输入生产能耗",
    enedata508: "请输入能耗单位",
    enedata509: "请输入生产单耗",
    enedata510: "请输入单耗单位",
    enedata511: "生产日期",
    enedata512: "请选择生产日期",

    enedata513: "产品名称长度必须在 1 到 30之间",
    enedata514: "生产订单号长度必须在 1 到 30之间",
    enedata515: "产品单位长度必须在 1 到 30之间",
    enedata516: "能耗单位长度必须在 1 到 30之间",
    enedata517: "备注说明长度必须在 1 到 300之间",

    enedata518: "确认要保存所设置的生产能耗信息吗？",
    enedata519: "确认要删除该生产能耗吗？",
    enedata520: "请先选择需要删除的生产能耗！",
    enedata521: "确认要删除所选择的生产能耗吗？",
    enedata522: "生产数量不能为零！",
    enedata523: "生产单耗不能为零！",
    enedata524: "值不能为零！",
    enedata525: "CSV导入",
    enedata526: "导入生产能耗数据",
    enedata527: "模板文件",
    enedata528: "生产能耗模板文件",
    enedata529: "下载生产能耗模板文件",
    enedata530: "错误信息",
    enedata531: "下载错误信息",
    enedata532: "NO,生产线名称,产品名称,生产订单号,生产开始日期,生产结束日期,生产数量,产品单位,生产能耗,能耗单位,备注",
    enedata533: '分组依据',
    enedata534: '生产能耗报表',
    enedata535: "请选择计量点",
    enedata536:'时间设置趋势图表',
    enedata537:'数据比较趋势图表',
    enedata538:'数据输出',
    enedata539:'数据比较趋势组设置',
    enedata540:'时间设置趋势组设置',

    enedata541: '所选日期已存在，请勿重复选择',
    enedata542: '最多添加7个日期。',
    enedata543: '文件格式错误，请选择CSV文件。',
    enedata544: '今天',
    enedata545: '当月',
    enedata546: '上月',
    enedata547:'趋势记录信息',
    enedata548:'记录生成间隔时间',
    enedata549:'读取间隔时间',
    enedata550:'最新趋势记录时间',
    enedata551:'分钟',
    enedata552:'趋势读取状态',
    enedata553:'读取中',
    enedata554:'停止读取',
    enedata555:'最近读取时间',
    enedata556:'最近读取编号',
    enedata557:'数据批量导入',
    enedata558:'年报数据批量导入',
    enedata559:'月报数据批量导入',
    enedata560:'日报数据批量导入',
    enedata561:'年报批量录入模板文件',
    enedata562:'月报批量录入模板文件',
    enedata563:'日报批量录入模板文件',
    enedata564:'月/单位',
    enedata565:'日/单位',
    enedata566:'时/单位',
    enedata567:'参数错误',
    enedata568:'信号点使用量修改',
    enedata569:'使用量修改模式',
    enedata570:'自动',
    enedata571:'手动',
    enedata572:'当前日期和未来日期的使用量不可修改。',
    enedata573:'白天使用量',
    enedata574:'夜间使用量',
    enedata575:'每月/每日才区分白天和夜间使用量。',
    enedata576:'白天时间段为9点~22点。',
    enedata577:'夜间时间段为1点~8点和23点~24点。',
    enedata578:'数据来源',
    enedata579:'数据库',
    enedata580:'设备',
    enedata581:'同步更新数据',
    enedata582:'是',
    enedata583:'否',
    enedata584:'同步更新数据:修改每小时数据，同步更新每日和每月的数据，当天和当月数据除外。修改每日数据，同步更新每月的数据，当月数据除外。修改每月数据无同步更新数据。',
    enedata585:'KPI日期',
    enedata586:'操作日期',
    enedata587:'执行日期',
    enedata588:'警报状态',
    enedata589:'运行状态',
    enedata590:'KPI目标值',
    enedata591:'年度规则',
    enedata592:'能效KPI警报设置',
    enedata593:'启用',
    enedata594:'停止',
    enedata595:'执行',
    enedata596:'请先选择需要设置的KPI目标警报。',
    enedata597:'请选择警报等级。',
    enedata598:'能效KPI自动任务管理',
    enedata599:'请输入白天使用量',
    enedata600:'请输入夜间使用量',
    enedata601:'请输入全天使用量',
    enedata602:'白天使用量值范围在0~999999999之间。',
    enedata603:'夜间使用量值范围在0~999999999之间。',
    enedata604:'全天使用量值范围在0~1999999998之间。',
    enedata605:'站点能耗对比',
    enedata606:'父节点',
    enedata607:'设备类型',
    enedata608:'父节点',
    enedata609:'运算公式输入错误',
    enedata610:'实时结余',
  },
  video: {
    viddata001: '控件下载',
    viddata002: '视频控件',
    viddata003: '下载',
    viddata004: '摄像机类型',
    viddata005: '摄像机ID',
    viddata006: '摄像机名称',
    viddata007: '摄像机IP',
    viddata008: '摄像机端口',
    viddata009: '录像机名称',
    viddata010: '录像机IP',
    viddata011: '录像机端口',
    viddata012: '录像机类型',
    viddata013: '带宽限制',
    viddata014: '带宽限制批量设置',
    viddata015: '全方位校正',
    viddata016: '全方位校正批量设置',
    viddata017: '标准',
    viddata018: '任务视图',
    viddata019: '双全景',
    viddata020: '可注册摄像机列表',
    viddata021: '已注册摄像机列表',
    viddata022: '摄像机分组名称',
    viddata023: '摄像机分组设置',
    viddata024: '摄像机设置',
    viddata025: '创建分组',
    viddata026: '删除分组',
    viddata027: '编辑名称',
    viddata028: '设置权限未被许可。',
    viddata029: '批量编辑',
    viddata030: '摄像机分组',
    viddata031: '实时视频',
    viddata032: '回放',
    viddata033: '摄像机列表',
    viddata034: '视频记录列表',
    viddata035: '注意',
    viddata036: '视频录像文件存放位置',
    viddata037: '抓图文件存放位置',
    viddata038: '全屏',
    viddata039: '单屏播放',
    viddata040: '{num}屏播放',
    viddata041: '启用电子放大',
    viddata042: '禁用电子放大',
    viddata043: '抓图',
    viddata044: '开始录像',
    viddata045: '结束录像',
    viddata046: '播放',
    viddata047: '暂停',
    viddata048: '停止',
    viddata049: '减速播放',
    viddata050: '加速播放',
    viddata051: '保存',
    viddata052: '本地播放',
    viddata053: '重连',
    viddata054: '关闭',
    viddata055: "状态面板",
    viddata056: "电子放大",
    viddata057: "恢复",
    viddata058: "视频记录名称",
    viddata059: "开始时间",
    viddata060: "结束时间",
    viddata061: "系统不支持摄像机类型",
    viddata062: "您还未安装过插件，请在下载功能页面下载插件进行安装。",
    viddata063: "全部停止",
    viddata064: "摄像机",
    viddata065: "摄像机数量",
    viddata066: "停止预览失败！",
    viddata067: "预览失败！",
    viddata068: "设备不支持Websocket取流！",
    viddata069: "录像机已登录过！",
    viddata070: "录像机登录连接失败！",
    viddata071: "摄像机总数",
    viddata072: "已分组摄像机总数",
    viddata073: "未分组摄像机总数",
  },
  // 认证信息
  information: {
    a1: "加载中....",
    a2: "卡片信息",
    a3: "人员信息",
    a4: "卡所属选择",
    a5: "姓名",
    a6: "请输入姓名",
    a7: "管理代码",
    a8: "请输入管理代码",
    a9: "卡号",
    a10: "请输入卡号",
    a11: "卡编号",
    a12: "请输入卡编号",
    a13: "显示选项",
    a14: "从所属",
    a15: "全部",
    a16: "使用类型",
    a17: "全部使用类型",
    a18: "普通卡",
    a19: "临时卡",
    a20: "使用状态",
    a21: "全部使用状态",
    a22: "未分配",
    a23: "使用中",
    a24: "卡状态",
    a25: "全部卡状态",
    a26: "可使用·借出",
    a27: "不可使用·返还",
    a28: "停止使用",
    a29: "查询",
    a30: "卡片信息列表",
    a31: "删除",
    a32: "编辑",
    a33: "选择操作",
    a34: "卡所属编辑",
    a35: "添加",
    a36: "条数据",
    a37: "可使用",
    a38: "不可使用",
    a39: "停止使用",
    a40: "借出",
    a41: "返还",
    a42: "管理所属",
    a43: "关联建筑",
    a44: "开始使用日期",
    a45: "有效期限",
    a46: "最终使用日期",
    a47: "备注",
    a48: "操作",
    a49: "确定要删除卡片信息?",
    a50: "变更",
    a51: "关闭",
    a52: "添加下级所属",
    a53: "所属名称：",
    a54: "请输入名称",
    a55: "所属名称变更",
    a56: "确定要删除",
    a57: "吗？",
    a58: "添加卡片信息",
    a59: "卡类型",
    a60: "卡所属",
    a61: "卡号不能为空",
    a62: "卡编号不能为空",
    a64: "编辑卡片信息",
    a65: "设置",
    a66: "管理所属选择",
    a67: "部门",
    a68: "请输入部门",
    a69: "人员信息列表",
    a70: "人员编辑",
    a71: "人员充值",
    a72: "管理所属编辑",
    a73: "人员添加",
    a74: "卡类型设置",
    a75: "账户充值",
    a76: "批量添加注册",
    a77: "账户类型",
    a78: "记账",
    a79: "现金",
    a80: "余额",
    a81: "注册日期",
    a82: "所属1",
    a83: "所属2",
    a84: "所属3",
    a85: "所属4",
    a86: "所属5",
    a87: "人员信息批量注册",
    a88: "人员信息",
    a89: "管理所属选择",
    a90: "卡片所属选择",
    a91: "通行站点选择",
    a92: "人员信息（XLSX、CSV）上传",
    a93: "*管理所属，请选择“全部管理所属”",
    a94: "选择文件",
    a95: "上传",
    a96: "信息下载",
    a97: "同时下载下级的管理所属",
    a98: "下载",
    a99: "指静脉信息（.CSV）上传",
    a100: "指静脉信息（.CSV）下载",
    a101: "只下载手指静脉已注册的数据",
    a102: "指静脉信息",
    a103: "车牌信息",
    a104: "车牌信息（.CSV）上传",
    a105: "车牌信息（.CSV）下载",
    a106: "仅下载车牌已注册的数据",
    a107: "文件格式错误",
    a108: "请选择文件格式为.csv的文件",
    a109: "卡类型设置",
    a110: "消费卡类型设置",
    a111: "人员管理",
    a113: "基本信息",
    a114: "手机号码",
    a116: "请输入手机号码",
    a117: "所属：",
    a118: "所属分组：",
    a119: "注册日期：",
    a120: "更新日期：",
    a121: "所属变更",
    a122: "卡",
    a123: "卡信息",
    a124: "删除卡片",
    a125: "关联站点",
    a126: "选择站点",
    a127: "普通卡",
    a128: "卡编号：",
    a129: "有效期限：",
    a130: "卡状态：",
    a131: "可使用",
    a132: "不可使用",
    a133: "停止使用",
    a134: "指静脉",
    a135: "第一指信息：",
    a136: "第二指信息：",
    a137: "阈值水平：",
    a138: "人脸",
    a139: "人脸信息：",
    a140: "特权设置",
    a141: "清洁员特权：",
    a142: "无",
    a143: "有",
    a144: "指静脉特权：",
    a145: "消费设置",
    a146: "卡类型：",
    a147: "结账方式：",
    a148: "梯控",
    a149: "房间名：",
    a150: "关联电梯",
    a151: "选择电梯",
    a152: "站点名称",
    a153: "通行模式1",
    a154: "通行模式",
    a155: "有效期限",
    a156: "通行模式2",
    a157: "通行模式3",
    a158: "通行模式4",
    a159: "通行模式5",
    a160: "电梯名称",
    a161: "楼层",
    a162: "房间名",
    a163: "未注册",
    a164: "已注册",
    a165: "已采集",
    a166: "没有选择管理所属",
    a167: "请选择管理所属信息",
    a168: "管理所属选择及编辑",
    a169: "卡选择",
    a170: "卡号",
    a171: "请输入卡ID",
    a172: "卡编号",
    a173: "请输入卡编号",
    a174: "查询",
    a175: "卡列表",
    a176: "卡编号",
    a177: "卡号",
    a178: "卡状态",
    a179: "可使用",
    a180: "不可使用",
    a181: "停止使用",
    a182: "借出",
    a183: "返还",
    a184: "卡类型",
    a185: "普通卡",
    a186: "临时卡",
    a187: "卡使用开始日期",
    a188: "操作",
    a189: "有效期限设置",
    a190: "取消",
    a191: "确认",
    a192: "初始日期",
    a193: "终止日期",
    a194: "指静脉采集",
    a195: "第一指：",
    a196: "读取",
    a197: "第二指：",
    a198: "设备：指静脉采集器连接成功！",
    a199: "设备：指静脉采集器未连接！",
    a200: "待读取",
    a201: "读取中...",
    a202: "读取失败",
    a203: "已采集",
    a204: "人脸图片设置",
    a205: "选择图片",
    a206: "注：建议使用标准证件照，图片格式 jpg。",
    a207: "站点选择",
    a208: "设置",
    a209: "关闭",
    a210: "未注册站点列表",
    a211: "添加 ↓",
    a212: "注册的站点列表",
    a213: "删除 ↑",
    a214: "条数据",
    a215: "名称",
    a216: "地址",
    a217: "类型",
    a218: "编号",
    a219: "通行模式选择",
    a220: "通行模式列表",
    a221: "通行模式",
    a222: "开始日期",
    a223: "结束日期",
    a224: "已选用",
    a225: "该通行模式已经选用，请选择其他通行模式",
    a226: "达到添加上限",
    a227: "仅能添加5条通行模式",
    a228: "电梯选择",
    a229: "未注册电梯列表",
    a230: "注册的电梯列表",
    a231: "楼层名称",
    a232: "房间号",
    a233: "账户充值",
    a234: "充值",
    a235: "充值卡号查询：",
    a236: "请输入卡号",
    a237: "基本信息",
    a238: "消费信息",
    a239: "账户余额",
    a240: "结账方式",
    a241: "注册日期",
    a242: "更新日期",
    a243: "最近充值金额",
    a244: "最近充值时间",
    a245: "记账卡无需充值",
    a246: "充值金额",
    a247: "请输入充值金额",
    a248: "查无此人员信息",
    a249: "请检查输入是否有误",
    a250: "未输入卡号",
    a251: "未输入充值金额",
    a252: "没有选择房间号",
    a253: "请选择选择房间号",
    a254: "图片大小不能超过6M",
    a255: "请选择JGP格式的文件",
    a256: "国籍",
    a257: "电梯信息",
    a258: "电梯信息（.CSV）上传",
    a259: "电梯信息（.CSV）下载",
    a260: "管理代码不能为空",
    a261: "姓名不能为空",
    a262: "请填写正确的手机号码",
    a263: '通行模式详情',
    a264: '通行模式名称：',
    a265: '企业名称',
    a266: '建筑名称',
    a267: '楼层·租户名称',
    a268: '设备名称',
    a269: '认证终端名称',
    a270: '出入类型',
    a271: '警戒设定',
    a272: '时间表名称',
    a273: '日历名称',
    a274: '请输入通行模式名称',
    a275: '总数量：',
    a276: '添加数量：',
    a277: '更新数量：',
    a278: '失败数量：',
    a279: '详情下载',
    a280: '数据详情',
    a281: '请选择站点',
    a282: '可注册通行模式列表',
    a283: '已注册通行模式列表',
    a284: '名称搜索',
    a285: '数量已超过5条上限',
    a286: '权限设置',
    a287: '人脸信息',
    a288: '人脸信息(.zip)上传',
    a289: '人脸信息(.zip)下载',
    a290: '请选择文件格式为.zip的文件',
    a291: '输入搜索文本',
    a292: '人员通行设备信息下载',
    a293: '人员通行信息下载',
    a294: '最近批量导入人员信息下载',
    a295:'选择图片',
    a296:'图片编辑',
    a297:'图片示例说明',
  },
  //消费管理模块
  consume: {
    a1: "加载中....",
    a2: "设备详情",
    a3: "关闭",
    a4: "基本信息",
    a5: "消费时段",
    a6: "价格一览",
    a7: "正常运行",
    a8: "离线/维修",
    a9: "早餐",
    a10: "午餐",
    a11: "晚餐",
    a12: "宵夜",
    a13: "终端名称",
    a14: "站点名称",
    a15: "客户名称",
    a16: "状态",
    a17: "星期一",
    a18: "星期二",
    a19: "星期三",
    a20: "星期四",
    a21: "星期五",
    a22: "星期六",
    a23: "星期日",
    a24: "卡片记录",
    a25: "姓名",
    a26: "请输入姓名",
    a27: "卡片编号",
    a28: "请输入卡片编号",
    a29: "管理代码",
    a30: "请输入管理代码",
    a31: "消费机名称",
    a32: "请输入消费机名称",
    a33: "消费机号",
    a34: "请输入消费机名称",
    a35: "开始日期",
    a36: "结束日期",
    a37: "查询",
    a38: "记录列表",
    a39: "请选择日期或时间",
    a40: "条数据",
    a41: "发生日期",
    a42: "消费方式",
    a43: "账户类型",
    a44: "姓名",
    a45: "管理代码",
    a46: "卡片编号",
    a47: "站点名称",
    a48: "消费机号",
    a49: "消费机名称",
    a50: "消费金额",
    a51: "余额",
    a52: "餐次",
    a53: "所属1",
    a54: "所属2",
    a55: "所属3",
    a56: "所属4",
    a57: "所属5",
    a58: "每日菜单设置",
    a59: "选择文件",
    a60: "备注：只接受文件格式为xlsx上传。",
    a61: "上传",
    a62: "消费设置",
    a63: "消费时段参数设置",
    a64: "消费机参数设置",
    a65: "刷卡间隔时间(秒)",
    a66: "日消费额度限额(元)",
    a67: "日消费次数限制",
    a68: "语音提示类型",
    a69: "--",
    a70: "1-消费成功语音提醒",
    a71: "2-短嘀一色",
    a72: "3-长嘀一色",
    a73: "4-VOICEType1",
    a74: "5-VOICEType2",
    a75: "设置",
    a76: "消费参数设置?",
    a77: "是否修改消费参数设置。",
    a78: "结算规则设置",
    a79: "各餐位分餐次价格设置一览表",
    a80: "编辑",
    a81: "餐次价格设置",
    a82: "取消",
    a83: "确认",
    a84: "价格设置",
    a85: "早餐（元）",
    a86: "午餐（元）",
    a87: "晚餐（元）",
    a88: "宵夜（元）",
    a89: "操作",
    a90: "消费报表",
    a91: "报表时间段选择",
    a92: "食堂选择",
    a93: "时间段",
    a94: "输出期间",
    a95: "一个月",
    a96: "一天",
    a97: "自定义时间",
    a98: "输出报表类型选择",
    a99: "全部",
    a100: "下载文件名称",
    a101: "输出中...",
    a102: "输出",
    a103: "消费明细报表",
    a104: "个人消费日报表",
    a105: "个人消费月报表",
    a106: "综合消费报表",
    a107: "部门消费报表",
    a108: "报餐报表",
    a109: "数据统计",
    a114: "数据图表",
    a115: "（刷卡次数）",
    a116: "请选择日期",
    a117: "消费金额统计",
    a118: "总刷卡次数：",
  },
  // 访客模块--访客预约记录
  visitorBookingRecord: {
    a1: '加载中',
    a2: '访客预约记录',
    a3: '自定义条件1',
    a4: '自定义条件2',
    a5: '时间',
    a6: '初始日期/时间选择',
    a7: '终止日期/时间选择',
    a8: '查询',
    a9: '访客预约记录列表',
    a10: '请选择日期或时间',
    a11: '条数据',
    a12: '访客姓名',
    a13: '访客电话',
    a14: '访客单位/公司',
    a15: '工单状态',
    a16: '到访状态',
    a17: '预约来访时间',
    a18: '访问结束时间',
    a19: '拜访事由',
    a20: '拜访说明',
    a21: '访客车牌号码',
    a22: '管理代码',
    a23: '受访人姓名',
    a24: '受访人所属',
    a25: '来访人数',
    a26: '申请时间',
    a27: '订单编号',
    a28: '受访人电话',
    a29: '详情',
    a30: '访问取消',
    a31: '未访问',
    a32: '访问中',
    a33: '访问完成',
    a34: '访问失约',
    a35: '未到访',
    a36: '已到访',
    a37: '已离开',
    a38: '已结束',
    a39: '商务洽谈',
    a40: '政府行政事务',
    a41: '技术交流',
    a42: '市场调查',
    a43: '面试',
    a44: '送货',
    a45: '维修',
    a46: '临时员工',
    a47: '其他',
    a48: '操作',
    a49: '取消',
    a50: '选择操作',
    a51: '访客信息',
    a52: '受访人信息',
    a53: '全部（拜访事由）',
    a54: '全部（到访状态）',
    a55: '全部（工单状态）',
    a56: '全部（认证类型）'
  },
  // 访客模块--访客人员记录
  visitorPeopleRecord: {
    a1: '加载中',
    a2: '访客人员记录',
    a3: '自定义条件1',
    a4: '自定义条件2',
    a5: '时间',
    a6: '初始日期/时间选择',
    a7: '终止日期/时间选择',
    a8: '查询',
    a9: '访客人员记录列表',
    a10: '请选择日期或时间',
    a11: '条数据',
    a12: '访客姓名',
    a13: '访客电话',
    a14: '访客单位/公司',
    a15: '工单状态',
    a16: '到访状态',
    a17: '预约来访时间',
    a18: '访问结束时间',
    a19: '认证类型',
    a20: '滞留时间',
    a21: '访客码',
    a22: '管理代码',
    a23: '受访人姓名',
    a24: '受访人所属',
    a25: '受访人电话',
    a26: '申请时间',
    a27: '订单编号',
    a28: '受访人电话',
    a29: '详情',
    a30: '访问取消',
    a31: '未访问',
    a32: '访问中',
    a33: '访问完成',
    a34: '访问失约',
    a35: '未到访',
    a36: '已到访',
    a37: '已离开',
    a38: '已结束',
    a39: '卡认证',
    a40: '掌形操作',
    a41: '卡认证+指静脉认证',
    a42: '卡认证+指纹认证',
    a43: '指静脉认证',
    a44: '指纹认证',
    a45: '蓝牙认证',
    a46: '数字键认证',
    a47: '身份证认证',
    a48: '二维码认证',
    a49: '人脸认证',
    a50: '仅显示滞留访客',
    a51: '清除',
    a52: '选择操作',
    a53: '进门设备',
    a54: '出门设备',
  },
  // 访客模块--未来访客统计记录
  visitorFutureRecord: {
    a1: '加载中',
    a2: '未来访客统计记录',
    a3: '显示对象',
    a4: '显示项目',
    a5: '时间',
    a6: '初始日期/时间选择',
    a7: '终止日期/时间选择',
    a8: '查询',
    a9: '访客预约记录列表',
    a10: '请选择日期或时间',
    a11: '条数据',
    a12: '时间',
    a13: '访客总数',
    a14: '待访数',
    a15: '在访数',
    a16: '离开数',
    a17: '完成数',
    a18: '滞留数',
    a19: '取消数',
    a20: '失约数',
    a21: '请选择显示对象',
    a22: '受访人编号',
    a23: '受访人姓名',
    a24: '受访人所属',
    a25: '来访人数',
    a26: '申请时间',
    a27: '未来一周',
    a28: '未来一月',
    a29: '租户',
    a30: '访客姓名',
    a31: '访客单位/公司',
  },
  // 访客模块--访客统计报表
  visitorStatisticalReport: {
    a1: '加载中',
    a2: '访客统计报表',
    a3: '显示对象',
    a4: '显示项目',
    a5: '时间',
    a6: '初始日期/时间选择',
    a7: '终止日期/时间选择',
    a8: '查询',
    a9: '访客单位/公司',
    a10: '访客姓名',
    a11: '条数据',
    a12: '时间',
    a13: '访客总数',
    a14: '待访数',
    a15: '在访数',
    a16: '离开数',
    a17: '完成数',
    a18: '滞留数',
    a19: '取消数',
    a20: '失约数',
    a21: '访客手机号码',
    a22: '访客类型',
    a23: '受访人所属',
    a24: '受访人姓名',
    a25: '受访人代码',
    a26: '受访人手机号码',
    a27: '年度统计',
    a28: '月度统计',
    a29: '日度统计',
    a30: '租户',
  },
  // 访客模块--访客信息
  visitorRecord: {
    a1: '加载中',
    a2: '访客信息管理',
    a3: '自定义条件',
    a4: '添加',
    a5: '修改',
    a6: '删除',
    a7: '操作',
    a8: '查询',
    a9: '访客信息列表',
    a10: '请选择日期或时间',
    a11: '条数据',
    a12: '访客类型',
    a13: '访客姓名',
    a14: '访客手机号码',
    a15: '访客证件类型',
    a16: '访客证件号',
    a17: '访客单位/公司',
    a18: '访客所属部门',
    a19: '访客职务',
    a20: '访客固定号码',
    a21: '访客微信',
    a22: '访客地址',
    a23: '日期',
    a24: '备注',
    a25: '成功',
    a26: '失败',
    a27: '请输入访客姓名',
    a28: '请输入访客手机号码'
  },
  // 访客模块--访客黑名单信息
  visitorBlacklist: {
    a1: '加载中',
    a2: '访客黑名单管理',
    a3: '自定义条件',
    a4: '添加',
    a5: '修改',
    a6: '删除',
    a7: '操作',
    a8: '查询',
    a9: '访客黑名单列表',
    a10: '请选择日期或时间',
    a11: '条数据',
    a12: '访客姓名',
    a13: '访客手机号码',
    a14: '访客证件类型',
    a15: '访客证件号',
    a16: '编辑日期',
    a17: '备注',
    a18: '成功',
    a19: '失败',
    a20: '上传黑名单',
    a21: '访客黑名单信息导入失败。',
    a22: '确定要删除此信息吗？',
  },
  // 访客模块--人员上下级设置
  upperAndLowerSetting: {
    a1: '人员上下级设置',
    a2: '编号：',
    a3: '人员信息表',
    a4: '上级人员表',
    a5: '下级人员表',
    a6: '人员编号',
    a7: '姓名',
    a8: '所属',
    a9: '上级姓名',
    a10: '是否发短信',
    a11: '是否发微信',
    a12: '下级姓名',
    a13: '选择上级人员',
    a14: '选择下级人员',
    a15: '删除',
    a16: '删除所有人员',
    a17: '设置',
    a18: '成功',
    a19: '失败',
    a20: '条数据',
    a21: '查询',
    a22: '自定义条件',
    a23: '加载中.....',
    a24: '请选择条件',
    a25: '上级人员姓名',
    a26: '下级人员姓名'
  },
  // 访客模块--访客规则设置
  visitorRuleSetting: {
    a1: '是否对接微信',
    a2: '微信对接URL',
    a3: '可通行时间提前/延后值',
    a4: '访客通行授权模式',
    a5: '根据受访人权限',
    a6: '根据通行模式',
    a7: '访客通行模式',
    a8: '删除',
    a9: '选择通行模式',
    a10: '访客认证模式',
    a11: '设置',
    a12: '通行模式选择',
    a13: '访客规则设置',
    a14: '成功',
    a15: '失败',
    a16: '是',
    a17: '否',
    a18: '通行模式',
    a20: '条数据',
    a21: '查询',
    a22: '自定义条件',
    a23: '确认要进行访客信息微信同步吗？',
    a24: '确认要进行访客黑名单微信同步吗？',
    a25: '确认要进行字典参数微信同步吗？',
    a26: '访客信息微信同步设置',
    a27: '访客黑名单微信同步设置',
    a28: '字典参数微信同步设置',
  },

  // 访客模块--卡片
  visitor: {
    a1: '时间：',
    a2: '访客：',
    a3: '被访：',
    a4: '事由：',
    a5: '状态：',
    a6: '顺丰物流',
    a7: '前台人员',
    a8: '开会',
    a9: '未到访',
    a10: '普通访客',
    a11: '类型：',
  },

  // 访客模块--访客详情窗口
  visitorDetailsModel:{
    a1: '基本信息',
    a2: '受访人信息',
    a3: '申请人信息',
    a4: '访客人员信息',
    a5: '访问处理信息',
    a6: '订单编号',
    a7: '订单状态',
    a8: '拜访状态',
    a9: '访问时间',
    a10: '申请时间',
    a11: '访客类型',
    a12: '访问事由',
    a13: '访问备注',
    a14: '其他备注',
    a15: '管理代码',
    a16: '姓名',
    a17: '所属',
    a18: '手机号码',
    a19: '证件类型',
    a20: '证件号码',
    a21: '访客信息',
    a22: '认证信息',
    a23: '通行权限',
    a24: '操作',
    a25: '发生时间',
    a26: '动作类型',
    a27: '动作信息',
    a28: '操作人员代码',
    a29: '操作人员姓名',
    a30: '备注说明',
    a31: '访客码',
    a32: '认证方式',
    a33: '通行模式',
    a34: '通行时间',

  },

  //数据比较趋势组列表
  dataCompareTrendList: {
    a1: '加载中....',
    a2: 'CSV导出',
    a3: '重载',
    a4: '日期1',
    a5: '条数据',
    a6: '数据种类',
    a7: '设备名称',
    a8: '信号名称',
    a9: '单位',
    a10: '时/日期',
    a11: '状态/30分',
  },
  sitecard: {
    a1: '过往14日用电量',
    a2: '过往14日用水量',
    a3: '楼层/门禁数',
    a4: '楼层/空调数',
    a5: '地点/设备数',
    a6: '种类/设备数',
    a7: '区域/电灯线路数',
    a8: '出入口设备数',
    a9: '当月统计数',
    a10: '楼层/设备数',
    a11: '空调运行数',
    a12: '每小时通行认证数',
    a13: '设备通行认证数',
    a14: '过往14日通行认证数',
  },
  // 出入口模块--车辆记录（通行记录）
  carThroughrecords: {
    a0: '加载中....',
    a1: '车辆记录',
    a2: '姓名',
    a3: '车牌号码',
    a4: '初始日期/时间选择',
    a5: '终止日期/时间选择',
    a6: '查询',
    a7: '记录列表',
    a8: '请选择日期或时间',
    a9: '车辆识别图片',
    a10: '添加注释',
    a11: '选择操作',
    a12: '注释',
    a13: '取消',
    a14: '确认',
    a15: '请输入内容....',
    a16: '条数据',
    a17: '发生日期',
    a18: '车辆类型',
    a19: '姓名',
    a20: '车牌号码',
    a21: '体温',
    a22: '设备组名称',
    a23: '设备名称',
    a24: '信号/终端名称',
    a25: '建筑物名称',
    a26: '注释',
    a27: '操作',
    a28: '请输入姓名',
    a29: '请输入车牌号码',
    a30: '地点',
    a31: '口罩',
    a32: '车牌照片读取失败，请稍后再试。',
    a33: '管理代码',
    a34: '车牌识别记录信息',
    a35: '车辆动作',
    a36: '点击放大图片',
    a37: '点击隐藏图片',
  },

  //控制器管理页
  controller: {
    a1: '控制器管理',
    a2: '建筑物信息',
    a3: '查询',
    a4: '终端信息',
    a5: '设备信息记录',
    a6: '控制器状态管理',
    a7: '控制器状态发送记录',
    a8: '运行状态',
    a9: '注释',
    a10: '控制器运行状态设置',
    a11: '设备类型：',
    a12: '未启动',
    a13: '测试',
    a14: '运行',
    a15: '停止',
    a16: '更新成功',
    a17: '更新失败',
    a18: '正常',
    a19: '异常',
    a20: '未知',
    a21: '确认',
    a22: '取消',
    a23: '获取通信状态',
    a24: '实时获取控制器的通信状态需要等待一些时间，是否确定 ?',
    a25: '控制器',
    a26: 'IP地址',
    a27: '通信状态',
    a28: '检查日',
    a29: '个人编号',
    a30:'站点记录读取管理',
    a31:'不读取',
    a32:'读取',
    a33:'保存',
    a34:'设置',
    a35:'取消',
    a36:'参数类型',
    a37:'读取状态',
    a38:'读取频率 (分钟/次)',
    a39:'最后读取时间',
    a40:'操作',
    a41:'读取频率输入参数错误',
    a42:'请输入整数;输入值为-1时,读取状态将设置为不读取.',
    a43:'LAST ID输入参数错误',
    a44:'请输入正整数;输入值为0时,读取编号为1的记录.',
    a45:'确认要更新修改信息吗?',
  },
  // 客户中心
  customerCenter: {
    a1: "加载中....",
    a2: "客户中心",
    a3: "编辑",
    a4: "建筑物列表",
    a5: "客户信息设置",
    a6: "取消",
    a7: "确认",
    a8: "客户名称",
    a9: "请输入客户名称",
    a10: "别名",
    a11: "请输入别名",
    a12: "联系方式1",
    a13: "请输入联系方式",
    a14: "联系方式2",
    a15: "联系人",
    a16: "请输入联系人",
    a17: "地址",
    a18: "请输入地址",
    a19: "功能站点",
    a20: "日历设置",
    a21: "添加节日",
    a22: "站点信息",
    a23: "站点名称",
    a24: "别名",
    a25: "管理代码",
    a26: "联系人",
    a27: "建筑代码",
    a28: "GW名称",
    a29: "楼层信息",
    a30: "修改",
    a31: "合同信息",
    a32: "无合同",
    a33: "准备中",
    a34: "服务中",
    a35: "建筑物信息设置",
    a36: "建筑物名称",
    a37: "请输入建筑物名称",
    a38: "描述",
    a39: "请输入描述",
    a40: "站点信息设置",
    a41: "请输入站点名称",
    a42: "站点别名",
    a43: "节日设置",
    a44: "节日名称",
    a45: "请输入节日名称",
    a46: "日期",
    a47: "日期指定",
    a48: "星期指定",
    a49: "重复",
    a50: "期限指定",
    a51: "节日类型",
    a52: "假日",
    a53: "特日1",
    a54: "特日2",
    a55: "特日3",
    a56: "特日4",
    a57: "日历名称更新",
    a58: "日历名称",
    a59: "请输入日历名称",
    a60: "楼层信息更新",
    a61: "楼层·租户名称",
    a62: "楼层·租户别名",
    a63: "租户地址",
    a64: "租户联系方式1",
    a65: "租户联系方式2",
    a66: "合同信息更新",
    a67: "无合同",
    a68: "准备中",
    a69: "服务中",
    a70: "01月",
    a71: "02月",
    a72: "03月",
    a73: "04月",
    a74: "05月",
    a75: "06月",
    a76: "07月",
    a77: "08月",
    a78: "09月",
    a79: "10月",
    a80: "11月",
    a81: "12月",
    a82: "01日",
    a83: "02日",
    a84: "03日",
    a85: "04日",
    a86: "05日",
    a87: "06日",
    a88: "07日",
    a89: "08日",
    a90: "09日",
    a91: "10日",
    a92: "11日",
    a93: "12日",
    a94: "13日",
    a95: "14日",
    a96: "15日",
    a97: "16日",
    a98: "17日",
    a99: "18日",
    a100: "19日",
    a101: "20日",
    a102: "21日",
    a103: "22日",
    a104: "23日",
    a105: "24日",
    a106: "25日",
    a107: "26日",
    a108: "27日",
    a109: "28日",
    a110: "29日",
    a111: "30日",
    a112: "31日",
    a113: "第1周",
    a114: "第2周",
    a115: "第3周",
    a116: "第4周",
    a117: "第5周",
    a118: "星期一",
    a119: "星期二",
    a120: "星期三",
    a121: "星期四",
    a122: "星期五",
    a123: "星期六",
    a124: "星期日",
    a125: "每周",
    a126: "每月",
    a127: "操作",
    a128: "服务名称",
    a129: "服务状态",
    a130: "请输入日历名称",
    a131: "节日删除?",
    a132: "确定删除该节日",
    a133: "请选择不同状态",
    a134: "请选择不同状态，再进行修改",
    a135: "输入搜索文本",
    a136: "合同信息更新成功",
    a137: "合同信息更新失败",
  },
  // 用户设置
  UserSettings: {
    a1: "加载中...",
    a2: "用户设置",
    a3: "用户类型",
    a4: "系统用户",
    a5: "客户用户",
    a6: "客户",
    a7: "请选择用户组",
    a8: "用户组ID",
    a9: "用户上限数",
    a10: "用户数",
    a11: "编辑",
    a12: "具有权限的站点",
    a13: "设置站点",
    a14: "站点ID",
    a15: "所属建筑",
    a16: "用户列表",
    a17: "新建用户",
    a18: "用户名称",
    a19: "级",
    a20: "登录账户",
    a21: "账户有效期",
    a22: "密码有效天数",
    a23: "认证代码",
    a24: "新建用户组",
    a25: "取消",
    a26: "确认",
    a27: "用户组名称：",
    a28: "访问许可用户群组选择",
    a29: "选择HBS管理者用户组",
    a30: "选择大厦业主用户组",
    a31: "选择复制原用户组",
    a32: "站点设置",
    a33: "显示",
    a34: "操作",
    a35: "设置",
    a36: "用户信息更新",
    a37: "用户组名称",
    a38: "请输入用户组名称",
    a39: "用户上限数",
    a40: "请输入用户上限数",
    a41: "账号ID",
    a42: "请输入账号Id",
    a43: "账号",
    a44: "请输入账号",
    a45: "验证码",
    a46: "请输入验证码",
    a47: "账户有效期",
    a48: "请输入账号有效期",
    a49: "邮箱地址",
    a50: "请输入邮箱地址",
    a51: "重置密码",
    a52: "密码1",
    a53: "请输入密码1",
    a54: "密码2",
    a55: "请输入密码2",
    a56: "请输入用户上限数",
    a57: "请输入数字",
    a58: "请输入大于当前用户数小于20的数字",
    a59: "请输入用户组名称",
    a60: "账号ID不能为空",
    a61: "账号不能为空",
    a62: "验证码不能为空",
    a63: "密码有效期不能为空",
    a64: "密码不能为空",
    a65: "请输入正确邮箱格式",
    a66: "邮箱不能为空",
    a67: "大厦业主",
    a68: "租户",
    a69: "大厦管理员",
    a70: "权限站点设置",
    a71: "站点ID",
    a72: "所属建筑",
    a73: "用户组权限",
    a74: "菜单服务权限",
    a75: "服务：",
    a76: "权限外服务菜单",
    a77: "添加↓",
    a78: "权限内服务菜单",
    a79: "删除↑",
    a80: "重置",
    a81: "确定",
    a82: "服务",
    a83: "菜单",
    a84: "显示",
    a85: "更新成功",
    a86: "更新失败",
    a87: "永久"
  },
  //通用的描述
  general: {
    a1: "加载中...",
    a2: "设置",
    a3: "添加",
    a4: "修改",
    a5: "删除",
    a6: "成功",
    a7: "失败",
    a8: "确定",
    a9: "取消",
    a10: "编辑",
    a11: "更新",
    a12: "查询",
    a13: '确定要删除?',
    a14: '请选择要删除的项',
    a15: '不能为空',
    a16: '选项',
    a17: '值',
  },
  meeting: {
    m1: '会议管理',
    m2: '提前结束',
    m3: '延时结束',
    m4: '正常结束',
    m5: ' 审核不通过',
    m6: '会议取消',
  },
  personnelIOStatistics:{
    a1:"人员统计",
    a2:"类型",
    a3:"所属公司/单位",
    a4:"请输入所属公司/单位",
    a5:"国籍",
    a6:"请输入国籍",
    a7:"姓名",
    a8:"请输入姓名",
    a9:"开始日期",
    a10:"结束日期",
    a11:"分组",
    a12:"查询",
    a13:"人员出入记录",
    a14:"人员出入统计",
    a15:"下载",
    a16:"室内人员统计",
    a17:"室内人员记录",
  },
  edge:{
    edge001:'智能网关管理',
    edge002:'网关类型',
    edge003:'网关名称',
    edge004:'所属位置',
    edge005:'网关IP',
    edge006:'端口',
    edge007:'网关账户',
    edge008:'网关密码',
    edge009:'网关访问URL',
    edge010:'状态检测URL',
    edge011:'警报周期',
    edge012:'状态检测模式',
    edge013:'监控模式',
    edge014:'说明',
    edge015:'备注',
    edge016:'建筑代码',
    edge017:'建筑名称',
    edge018:'通信状态',
    edge019:'运行状态',
    edge020:'连接时间',
    edge021:'读取设备信息',
    edge022:'软件版本',
    edge023:'软件更新日期',
    edge024:'操作',
    edge025:'添加',//新增智能网关
    edge026:'编辑',//智能网关
    edge027:'软件升级',
    edge028:'上传升级文件',
    edge029:'请输入网关名称',
    edge030:'请输入所属位置',
    edge031:'请输入网关IP',
    edge032:'请输入端口',
    edge033:'请输入网关账户',
    edge034:'请输入网关密码',
    edge035:'请输入网关访问URL',
    edge036:'请输入状态检测URL',
    edge037:'请输入警报周期',
    edge038:'请输入状态检测模式',
    edge039:'请输入监控模式',
    edge040:'请输入说明',
    edge041:'请输入备注',
    edge042:'智能网关设置',
    edge043:'网关名称长度必须在1到30之间',
    edge044:'所属位置长度必须在1到30之间',
    edge045:'网关IP长度必须在1到30之间',
    edge046:'网关账户长度必须在6到30之间',
    edge047:'网关密码长度必须在6到20之间',
    edge048:'网关访问URL长度必须在1到200之间',
    edge049:'状态检测URL长度必须在1到200之间',
    edge050:'说明长度必须在1到800之间',
    edge051:'备注长度必须在1到200之间',
    edge052:'确认要保存所设置的智能网关信息吗？',
    edge053:'确认要删除所选择的智能网关信息吗？',
    edge054:'升级',
    edge055:'文件类型',
    edge056:'选择文件',
    edge057:'上传中',
    edge058:'上传',
    edge059:'上传成功',
    edge060:'上传失败',
    edge061:'待升级软件网关/控制器列表',
    edge062:'文件列表',
    edge063:'未启动',
    edge064:'试验/维护',
    edge065:'运转',
    edge066:'作业告警',
    edge067:'集中告警',
    edge068:'停止',
    edge069:'删除告警',
    edge070:'请输入建筑代码',
    edge071:'请选择网关类型',
    edge072:'配置',
    edge073:'配置',//网关编号配置
    edge074:'原GWC编号',
    edge075:'原FLC/FMC编号',
    edge076:'新GWC编号',
    edge077:'新FLC/FMC编号',
    edge078:'动作命令',
    edge079:'命令类型',
    edge080:'选择网关/控制器',
    edge081:'选择动作命令',
    edge082:'选择文件',
    edge083:'确认',
    edge084:'升级',
    edge085:'文件发送',
    edge086:'文件发送并升级',
    edge087:'文件删除',
    edge088:'请选择命令类型；或者请点击确定按钮进行对应命令操作。',
    edge089:'请点击确定按钮进行升级程序。',
    edge090:'请选择文件',
    edge091:'准备升级程序，请选择程序文件',
    edge092:'请输入新GWC编号',
    edge093:'请输入新FLC/FMC编号',
    edge094:'新GWC编号长度必须为8位。',
    edge095:'FLC编号范围必须在101-140之间。',
    edge096:'FMC编号范围必须在17-19之间。',
    edge097:'文件管理',
    },
    energyEquipmentList:{
      a1:"设备列表",
      a2:"设备名称",
      a3:"初始日期",
      a4:"终止日期",
      a5:"查询",
      a6:"记录",
      a7:"姓名",
      a8:"请输入姓名",
      a9:"开始日期",
      a10:"结束日期",
      a11:"分组",
      a12:"查询",
      a13:"人员出入记录",
      a14:"人员出入统计",
      a15:"下载",
      a16:"室内人员统计",
      a17:"室内人员记录",
      b1:"站点ID",
      b2:"名称",
      b3:"标题",
      b4:"描述",
      b5:"组类型",
      b6:"上级ID",
      b7:"层级",
      b8:"层级节点",
      b9:"图标号",
      b10:"使用类型",
      b11:"代码",
      b12:"操作",
      b13:"",
      b14:"",
      b15:"",
    },

    linkage:{
      chooseSignal:"请选择信号点",
      hour:"小时",
      compare:"比较",
      critical:"临界值",
      greater:"大于",
      less:"小于",
      equal:"等于",
      index:"条件序号",
      condition:"条件",
      relation:"条件间关系",
      output:"输出",
      addRuleAndInput:"添加规则输入条件项目",
      addruleCondition:"添加规则条件项",
      addCondition:"添加条件",
      and:"与",
      or:"或者",
    },
    peopleDevice:{
     a1:"设备通信失败信息",
     a2:"继续下发数据",
     a3:"我知道了",
     a4:"继续下发可能会下发失败，确定继续下发数据吗？",
    },
    faceErrorMessage:{
     a1:"人脸下发失败",
     a2:"我知道了",
    },
}
