module.exports = {
    weather:{
        WEATHER_100:'Sunny',
        WEATHER_101:'Cloudy',
        WEATHER_102:'Few clouds',
        WEATHER_103:'Partly cloudy',
        WEATHER_104:'Overcast',
        WEATHER_150:'Clear night',
        WEATHER_151:'Cloudy night',
        WEATHER_152:'Few clouds night',
        WEATHER_153:'Partly cloudy night',
        WEATHER_154:'Overcast',
        WEATHER_300:'Shower rain',
        WEATHER_301:'Heavy shower rain',
        WEATHER_302:'Thundershower',
        WEATHER_303:'Heavy thunderstorm',
        WEATHER_304:'Thundershower with hail',
        WEATHER_305:'Light rain',
        WEATHER_306:'Moderate rain',
        WEATHER_307:'Heavy rain',
        WEATHER_308:'Extreme rain',
        WEATHER_309:'Drizzle rain',
        WEATHER_310:'Storm',
        WEATHER_311:'Heavy storm',
        WEATHER_312:'Severe storm',
        WEATHER_313:'Freezing rain',
        WEATHER_314:'Light to moderate rain',
        WEATHER_315:'Moderate to heavy rain',
        WEATHER_316:'Heavy rain to storm',
        WEATHER_317:'Storm to heavy storm',
        WEATHER_318:'Heavy to severe storm',
        WEATHER_350:'Shower rain night',
        WEATHER_351:'Heavy shower rain night',
        WEATHER_399:'Rain',
        WEATHER_400:'Light snow',
        WEATHER_401:'Moderate snow',
        WEATHER_402:'Heavy snow',
        WEATHER_403:'Snowstorm',
        WEATHER_404:'Sleet',
        WEATHER_405:'Rain and snow',
        WEATHER_406:'Shower snow',
        WEATHER_407:'Snow flurry',
        WEATHER_408:'Light to moderate snow',
        WEATHER_409:'Moderate to heavy snow',
        WEATHER_410:'Heavy snow to snowstorm',
        WEATHER_456:'Shower snow night',
        WEATHER_457:'Snow flurry night',
        WEATHER_499:'Snow',
        WEATHER_500:'Mist',
        WEATHER_501:'Foggy',
        WEATHER_502:'Haze',
        WEATHER_503:'Sand',
        WEATHER_504:'Dust',
        WEATHER_507:'Duststorm',
        WEATHER_508:'Sandstorm',
        WEATHER_509:'Dense fog',
        WEATHER_510:'Strong fog',
        WEATHER_511:'Moderate haze',
        WEATHER_512:'Heavy haze',
        WEATHER_513:'Severe haze',
        WEATHER_514:'Heavy fog',
        WEATHER_515:'Extra heavy fog',
        WEATHER_800:'New moon',
        WEATHER_801:'Waxing crescent',
        WEATHER_802:'First quarter',
        WEATHER_803:'Waxing gibbous',
        WEATHER_804:'Full moon',
        WEATHER_805:'Waning gibbous',
        WEATHER_806:'Last quarter',
        WEATHER_807:'Waning crescent',
        WEATHER_900:'Hot',
        WEATHER_901:'Cold',
        WEATHER_999:'Unknown',
        WEATHER_1001:'Typhoon',
        WEATHER_1002:'Tornado',
        WEATHER_1003:'Rainstorm',
        WEATHER_1004:'Snow storm',
        WEATHER_1005:'Cold wave',
        WEATHER_1006:'Gale',
        WEATHER_1007:'Sandstorm warning',
        WEATHER_1008:'Low temperature freeze',
        WEATHER_1009:'High temperature',
        WEATHER_1010:'Heat wave',
        WEATHER_1011:'Dry hot air',
        WEATHER_1012:'Downburst',
        WEATHER_1013:'Avalanche',
        WEATHER_1014:'Lightning',
        WEATHER_1015:'Hail',
        WEATHER_1016:'Frost',
        WEATHER_1017:'Heavy fog warning',
        WEATHER_1018:'Low level wind shearl',
        WEATHER_1019:'Haze warning',
        WEATHER_1020:'Thunder gust',
        WEATHER_1021:'Road icing',
        WEATHER_1022:'Drought',
        WEATHER_1023:'Gale at sea',
        WEATHER_1024:'Heat stroke',
        WEATHER_1025:'Wildfire',
        WEATHER_1026:'Grassland fire',
        WEATHER_1027:'Freeze',
        WEATHER_1028:'Space weather',
        WEATHER_1029:'Heavy air pollution',
        WEATHER_1030:'Low temperature rain and snow',
        WEATHER_1031:'Strong convection',
        WEATHER_1032:'Ozone',
        WEATHER_1033:'Heavy snow warning',
        WEATHER_1034:'Cold warning',
        WEATHER_1035:'Continuous rain',
        WEATHER_1036:'Waterlogging',
        WEATHER_1037:'Geological hazard',
        WEATHER_1038:'Heavy rainfall',
        WEATHER_1039:'Severely falling temperature',
        WEATHER_1040:'Snow disaster',
        WEATHER_1041:'Wildfire grassland',
        WEATHER_1042:'Medical meteorology',
        WEATHER_1043:'Thunderstorm',
        WEATHER_1044:'School closure',
        WEATHER_1045:'Factory closure',
        WEATHER_1046:'Maritime risk',
        WEATHER_1047:'Spring dust',
        WEATHER_1048:'Falling temperature',
        WEATHER_1049:'Typhoon and rainstorm',
        WEATHER_1050:'Severe cold',
        WEATHER_1051:'Sand dust',
        WEATHER_1052:'Sea thunderstorms',
        WEATHER_1053:'Sea fog',
        WEATHER_1054:'Sea thunder',
        WEATHER_1055:'Sea typhoon',
        WEATHER_1056:'Low temperature',
        WEATHER_1057:'Road ice and snow',
        WEATHER_1058:'Thunderstorm and gale',
        WEATHER_1059:'Continuous low temperature',
        WEATHER_1061:'Strong dust',
        WEATHER_1064:'Short lived heavy shower rain',
        WEATHER_1101:'Flood',
        WEATHER_1302:'Mudflow',
        WEATHER_1402:'Storm surge',
        WEATHER_1601:'Very hot weather',
        WEATHER_1602:'Strong monsoon signal',
        WEATHER_1603:'Landslip',
        WEATHER_1604:'Tropical cyclone',
        WEATHER_1605:'Fire danger',
        WEATHER_1606:'Flooding in the northern new territories',
        WEATHER_1607:'Cold weather',
        WEATHER_2001:'Wind',
        WEATHER_2002:'Snow ice',
        WEATHER_2003:'Fog',
        WEATHER_2004:'Coastal event',
        WEATHER_2005:'Forest fire',
        WEATHER_2006:'Rain warning',
        WEATHER_2007:'Rain flood',
        WEATHER_2008:'Freezing rain icing',
        WEATHER_2009:'Ground frost',
        WEATHER_2010:'Dust raising winds',
        WEATHER_2011:'Strong surface winds',
        WEATHER_2012:'Hot day',
        WEATHER_2013:'Warm night',
        WEATHER_2014:'Cold day',
        WEATHER_2015:'Thunderstorm and lightning',
        WEATHER_2016:'Hailstorm',
        WEATHER_2017:'Sea area warning',
        WEATHER_2018:'Fishermen warning',
        WEATHER_2019:'Low humidity',
        WEATHER_2020:'Accumulated rain',
        WEATHER_2021:'Hazardous surf warning',
        WEATHER_2022:'Marine wind warning',
        WEATHER_2023:'Road weather alert',
        WEATHER_2024:'Thunder rain',
        WEATHER_2025:'Thunder rain and fog',
        WEATHER_2026:'Damaging winds',
        WEATHER_2027:'Veld fire conditions',
        WEATHER_2028:'Weather advisory',
        WEATHER_9998:'Severe weather warning',
        WEATHER_9999:'Warning default',
    }
}