// 电能--能耗流向
module.exports = {
    energyFlow:{
        a1: 'エネルギー消費フローチャート',
        a2: 'エネルギー消費量',
        a3: 'カーボンフットプリント',
        a4: 'お客様',
        a5: 'けんちく',
        a6: 'サイト',
        a7: 'コンテンツの表示',
        a8: 'グループの表示',
        a9: 'ディスプレイデバイス',
        a10:'テナントの表示',
        a11:'年',
        a12:'月',
        a13:'日',
        a14:'カスタム開始月',
        a15:'1月',
        a16:'2月',
        a17:'3月',
        a18:'4月',
        a19:'5月',
        a20:'6月',
        a21:'7月',
        a22:'8月',
        a23:'9月',
        a24:'10月',
        a25:'11月',
        a26:'12月',
        a27:'クエリー',
        a28:'クエリーに条件を選択してください',
        a29:'けいりょうてん',
    }
}