// 电梯模块路由表
export default [
    {
        path: '/homepage/elevator', //电梯模块--电梯主页
        component: () => import('@/views/elevator/elevatorLayout'),
        meta: { hidden: true },
        children: [
          {
            path: '/homepage/elevator/equipment', //电梯模块--设备页
            name: 'ELE_EQUIPMENT',
            component: () => import('@/views/elevator/equipment'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/elevator/control', //电梯模块--远程控制
            name: 'ELE_CONTROL',
            component: () => import('@/views/elevator/remoteControl'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/elevator/alarm', //电梯模块--警报
            name: 'ELE_ALARM',
            component: () => import('@/views/elevator/alarm'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/elevator/history', //电梯模块--卡记录
            name: 'ELE_CAR_RIREKI',
            component: () => import('@/views/elevator/history'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/elevator/qrhistory', //电梯模块--二维码记录
            name: 'ELE_QRCODE_RIREKI',
            component: () => import('@/views/elevator/qrhistory'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/elevator/remotehistory', //电梯模块--远程记录
            name: 'ELE_REMOTE_RIREKI',
            component: () => import('@/views/elevator/remotehistory'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/elevator/elevatorTimetable', //电梯模块--时间段
            name: 'SCHEDULE_ELEVATOR',
            component: () => import('@/views/elevator/elevatorTimetable'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/elevator/running', //电梯模块--数据报表
            name: 'ELE_RUNNING',
            component: () => import('@/views/elevator/running'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/elevator/reportData', //电梯模块--数据报表
            name: 'ELE_DATA',
            component: () => import('@/views/elevator/reportData'),
            meta: { hidden: true }
          },
        ],
      }
]