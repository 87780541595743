<template>
  <div
    id="morebutton"
    @mouseenter="enterIcon"
    @mouseleave="leaveIcon"
    :title="$t('sidebar.f1')"
  >
  <a-popover placement="right">
        <template slot="content">
          
        </template>
        <img class="button" :src="getImage" />
      </a-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedImage: require("../../../../public/images/sidebar/more_selected.png"),
      normalImage: require("../../../../public/images/sidebar/more_normal.png"),
      url: "/iogin",
    };
  },
  computed: {
    getImage: function () {
      let route = this.$route.matched[1].path;
      if (route == this.url) {
        return this.selectedImage;
      } else {
        return this.normalImage;
      }
    },
  },
  methods: {
    enterIcon() {
      this.normalImage = require("../../../../public/images/sidebar/more_selected.png");
    },
    leaveIcon() {
      this.normalImage = require("../../../../public/images/sidebar/more_normal.png");
    },
  },
};
</script>

<style scoped>
#morebutton {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 10px 0;
}
.button {
  width: 50px;
}
</style>