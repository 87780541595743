// 清洁能源流向
module.exports = {
    cleanEnergyFlows:{
        a1:'ロード中...',
        a2:'クリーンエネルギーの流れ',
        a3:'電気量',
        a4:'カーボンフットプリント',
        a5:'カスタム開始月',
        a6:'照会',
        a7:'クエリーに条件を選択してください',
        a8:'計量点',
        a9:'カーボンフットプリント'
    }
}