// 门禁模块 -- 详情页
module.exports = {
    doorDetails:{
        a1: '加載中….',
        a2: '設備名稱',
        a3: '設備ID',
        a4: '設備GUID',
        a5: '左入口',
        a6: '名稱',
        a7: '客戶名稱',
        a8: '類型',
        a9: '功能站點',
        a10: '樓層·租戶名稱',
        a11: '取消',
        a12: '確認',
        a13: '項目',
        a14: '狀態·操作',
        a15: '狀態變化時間',
        a17: '選擇',
        a18: '時間表設定',
        a19: '設備詳情資訊',
        a20: '時間表',
        a21: '行事曆',
        a22: '門開有效時間',
        a23: '門開超時檢測時間',
        a24: '有無聲音',
        a25: '有聲音',
        a26: '無聲音',
        a27: '電力鎖動作次數',
        a28: '電力鎖動作次數超過監視',
        a29: '電力鎖動作次數上限',
        a30: '確定要進行設定內容注册嗎？',
        a31: '門狀態',
        a32: '异常開',
        a33: '暫時解鎖',
        a34: '開超過',
        a35: '入侵',
        a36: '上解鎖狀態',
        a37: '無迴圈',
        a38: '通行狀態',
        a39: '運行狀態',
        a40: '接受禁止狀態',
        a41: '非法通行警報',
        a42: '設備异常警報',
        a43: '入方向門開',
        a44: '出方向門開',
        a45: '入方向通行禁止',
        a46: '出方向通行禁止',
        a47: '連續通過設定',
        a48: '火灾訊號輸出',
        a49: '卡NG訊號輸出',
        a50: '指定目標',
        a51: '門時間表',
        a52: '卡認證時間表',
        a53: '機械警備時間表',
        a54: '模式1',
        a55: '模式2',
        a56: '模式3',
        a57: '模式4',
        a58: '模式5',
        a59: '初始值為“卡模式"，如需更改請新設定',
        a60: '卡模式',
        a61: '自由模式',
        a62: '重複模式',
        a63: '進入卡/退出自由模式',
        a64: '進入自由/退出卡模式',
        a65: '設備詳情資訊',
        a66: '時間表',
        a67: '行事曆',
        a68: '數字鍵超時時間（秒）',
        a69: '數字鍵終端有無',
        a70: '警戒溫度下限（℃）',
        a71: '警戒溫度上限（℃）',
        a72: '警戒溫度异常是否開門',
        a73: '否',
        a74: '是',
        a75: '認證模式',
        a76: '運轉狀態',
        a77: '非法卡',
        a78: '時間表恢復',
        a79: '週期自動復原',
        a80: '有效',
        a81: '無效',
        a82: '週期恢復時間1',
        a83: '週期恢復時間2',
        a84: '週期恢復時間3',
        a85: '週期恢復時間4',
        a86: '週期恢復時間5',
        a87: '設備IP',
        a89: '設備子網路遮罩',
        a90: '設備閘道',
        a91: '操作',
        a92: '關聯設備網絡資訊',
        a93: '操作成功，開門按鈕將在5秒鐘後恢復關閉狀態',
        a94: '開門操作失敗',
        a95: '當日時間表',
        a96: '當日',
        a97: '次日時間表',
        a98: '次日',
        a99: '時間表手動恢復',
        a100: '設定',
        a101: '操作狀態/故障·報警',
        a102: '時',
        a103: '分',
        a104: '名稱更新',
        a105: '名稱修改',
        a106: '關閉',
        a107: '設定',
        a108: '請輸入名稱',
        a109: '操作狀態',
        a110: '設備詳情',
        a111: '時間表設定',
        a112: '通行許可權',
        a113: '通行人員',
        a114: '解鎖',
        a115: '復位',
        a116: '上鎖',
        a117: '狀態',
        a118: '（0-99秒）',
        a119: '（0-3599秒）',
        a120: '請選擇通行模式',
        a121: '（0~999999次）',
        a122: '時間沒有被正確指定，請再次指定',
        a123: '輸入蒐索文字',
        a124: '已注册的認證終端',
        a125: '顯示內容',
        a126: '通行模式複製',
        a127: '認證終端編輯',
        a128: '複製原通行模式',
        a129: '條數據',
        a130: '企業名稱',
        a131: '建築名稱',
        a132: '樓層·租戶名稱',
        a133: '設備名稱',
        a134: '認證終端名稱',
        a135: '出入類型',
        a136: '警戒設定',
        a137: '無',
        a138: '有',
        a139: '時間表名稱',
        a140: '行事曆名稱',
        a141: '未選擇通行模式',
        a142: '請選擇需要複製的通行模式',
        a143: '人員信息清單',
        a144: '當前頁匯出',
        a145: '全部匯出',
        a146: '人員資訊匯出',
        a147: '删除',
        a148: '人員編輯',
        a149: '選擇操作',
        a150: '管理程式碼',
        a151: '姓名',
        a152: '部門',
        a153: '卡編號',
        a154: '國籍',
        a155: '注册日期',
        a156: '關聯建築',
        a157: '所屬1',
        a158: '所屬2',
        a159: '所屬3',
        a160: '所屬4',
        a161: '所屬5',
        a162: '操作',
        a163: '確定要删除'
    }
}