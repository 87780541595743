import { getstairmenu } from '@/api/user';

const state = {
    oneMenu: [],
    oneSelect: Number(sessionStorage.getItem("oneNav")) || 0,
    twoMenu: [],
    twoSelect: Number(sessionStorage.getItem("twoNav")) || 0,
    threeMenu: [],
    threeSelect: 0,
    siteMenu: JSON.parse(sessionStorage.getItem("sitemenu")) || [],
    siteName: sessionStorage.getItem("sitename"),
    siteServiceTypeName: sessionStorage.getItem("siteServiceTypeName"),//2021-12-07取服务类型名称
    siteType: sessionStorage.getItem("siteType"),
    clientId: sessionStorage.getItem("clientId"),
    sitegrId: sessionStorage.getItem("sitegrId"),
    loading: false,
};

const mutations = {
    // 初始化各级导航栏
    SET_MENU: (state, Menu) => {
        let twomenu = Menu[state.oneSelect].siteGrList;
        let threemenu = twomenu[state.twoSelect].siteList;
        let clientId = Menu[state.oneSelect].id;
        let sitegrId = twomenu[state.twoSelect].id;
        state.oneMenu = Menu;
        state.clientId = clientId;
        sessionStorage.setItem("clientId", clientId);
        state.twoMenu = twomenu;
        state.sitegrId = sitegrId;
        sessionStorage.setItem("sitegrId", sitegrId);
        state.threeMenu = threemenu;
    },
    HANDLE_ONEMENU: (state, value) => {
        let twomenu = state.oneMenu[value].siteGrList;
        let threemenu = twomenu[0].siteList;
        let clientId = state.oneMenu[value].id;
        let sitegrId = twomenu[0].id
        state.twoMenu = twomenu;
        state.threeMenu = threemenu;
        state.clientId = clientId;
        sessionStorage.setItem("clientId", clientId);
        state.sitegrId = sitegrId;
        sessionStorage.setItem("sitegrId", sitegrId);
        state.oneSelect = value
    },
    HANDLE_TWOMENU: (state, value) => {
        let threemenu = state.twoMenu[value].siteList;
        let sitegrId = state.twoMenu[value].id;
        state.threeMenu = threemenu;
        state.sitegrId = sitegrId;
        sessionStorage.setItem("sitegrId", sitegrId);
        state.twoSelect = value
    },
    SITE_MENU: (state, value) => {
        state.siteValue = value;
        if(!state.threeMenu[value]){
            return ;
        }
        if(state.threeMenu[value].siteServices){
            state.siteMenu = state.threeMenu[value].siteServices;
            sessionStorage.setItem("sitemenu", JSON.stringify(state.threeMenu[value].siteServices));
        }else{
            // state.siteMenu = {};
            // sessionStorage.setItem("sitemenu", "");
        }
        state.siteName = state.threeMenu[value].name;
        state.siteServiceTypeName=state.threeMenu[value].siteServiceTypeName;//2021-12-07取服务类型名称
        state.siteType = state.threeMenu[value].siteServiceTypeENname;
        sessionStorage.setItem("sitename", state.threeMenu[value].name);
        sessionStorage.setItem("siteServiceTypeName", state.threeMenu[value].siteServiceTypeName);//2021-12-07保存服务类型名称
        sessionStorage.setItem("siteid", state.threeMenu[value].id);
        sessionStorage.setItem("siteType", state.threeMenu[value].siteServiceTypeENname);
    },
    loading: (state, value) => {
        state.loading = value;
    },
    outLogin: (state) => {
        state.oneSelect = 0;
        state.twoSelect = 0;
        state.threeSelect = 0;
        state.oneMenu = [];
        state.twoMenu = [];
        state.threeMenu = [];
        state.siteMenu = [];
    }
};

const actions = {
    getlist({ commit }) {
        commit('loading', true);
        getstairmenu().then(res => {
            const { data } = res;
            console.log("导航数据", data)
            commit('SET_MENU', data);
            commit('loading', false);
        }).catch(err => {
            commit('loading', false);
            console.log('menu 出错', err);
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
