// 门禁模块 -- 详情页
module.exports = {
    doorDetails:{
        a1: 'ローディング中....',
        a2: 'デバイス名',
        a3: 'デバイスid ',
        a4: 'デバイスguid ',
        a5: '左入口',
        a6: '名前',
        a7: '顧客名',
        a8: 'タイプ',
        a9: '機能サイト',
        a10: 'フロア・テナント名',
        a11: 'キャンセル',
        a12: '確認',
        a13: 'プロジェクト',
        a14: 'ステータス・オペレーション',
        a15: '状態変化時間',
        a17: '選択',
        a18: 'スケジュール設定',
        a19: '設備詳細情報',
        a20: '時間割',
        a21: 'カレンダー',
        a22: 'ドア開有効時間',
        a23: 'ドア開タイムアウト検出時間',
        a24: '音の有無',
        a25: '有声音',
        a26: '無音',
        a27: '電気ロック動作回数',
        a28: '電気ロック動作回数オーバー監視',
        a29: '電気ロック動作回数上限',
        a30: '設定内容の登録はokですか?',
        a31: 'ゲート状態',
        a32: '異常開',
        a33: '一時的にロック解除',
        a34: 'オープンオーバー',
        a35: '侵入',
        a36: 'アンロック状態',
        a37: '無循環',
        a38: '通行状態',
        a39: '運行状態',
        a40: '禁止状態を受け入れます',
        a41: '不法通行警報',
        a42: '設備異常警報',
        a43: '入方向ドア開',
        a44: '出方向ドア開',
        a45: '入方面通行禁止',
        a46: '出先通行禁止',
        a47: 'コンティニュアス設定',
        a48: '火災信号出力',
        a49: 'カードng信号出力',
        a50: 'ターゲットを指定します',
        a51: 'ドア時刻表',
        a52: 'カード認証スケジュール',
        a53: '機械警備時間割',
        a54: 'モード1',
        a55: 'モード2',
        a56: 'モード3',
        a57: 'モード4',
        a58: 'モード5',
        a59: '初期値を「カードモード」に変更する場合は新たに設定してください',
        a60: 'カードモード',
        a61: 'フリーモード',
        a62: '反復パターン',
        a63: 'カード・アウト・フリーモード',
        a64: 'フリー/アウトカードモードになります',
        a65: '機器詳細情報',
        a66: '時刻表',
        a67: 'カレンダー',
        a68: 'テンキータイムアウト時間(秒)',
        a69: 'テンキー端末の有無',
        a70: '警戒温度下限値(℃)',
        a71: '警戒温度上限(℃)',
        a72: '温度異常を警戒してドアを開けるかどうか',
        a73: ' no ',
        a74: 'はい',
        a75: '認証モード',
        a76: '稼働状態',
        a77: '不正カード',
        a78: 'スケジュール回復',
        a79: '周期自動復元',
        a80: '有効',
        a81: '無効',
        a82: '周期回復時間1',
        a83: '周期回復時間2',
        a84: '周期回復時間3',
        a85: '周期回復時間4',
        a86: '周期回復時間5',
        a87: 'デバイスip ',
        a89: 'デバイスサブネットマスク',
        a90: 'デバイス・ゲートウェイ',
        a91: 'オペレーション',
        a92: '関連機器ネットワーク情報',
        a93: '操作成功、ドアボタンは5秒後にクローズ状態に戻ります',
        a94: '開扉操作失敗',
        a95: '当日の時刻表',
        a96: 'その日',
        a97: '翌日の時間割',
        a98: '翌日',
        a99: 'スケジュール手動復旧',
        a100: '設定',
        a101: '動作状態/障害・アラーム',
        a102: 'とき',
        a103: 'ふん',
        a104: '名称更新',
        a105: '名称変更',
        a106: '閉じる',
        a107: '設定',
        a108: '名前を入力してください',
        a109: '動作状態',
        a110: 'デバイス詳細',
        a111: 'スケジュール設定',
        a112: '通行許可',
        a113: '通行人',
        a114: 'ロック解除',
        a115: 'リセット',
        a116: '鍵をかける',
        a117: '状態',
        a118: '（0〜99秒）',
        a119: '（0-3599秒）',
        a120: '通行モードを選択してください',
        a121: '(0 ~ 999999回)',
        a122: '時間が正しく指定されていないので、再度指定してください',
        a123: '検索テキストを入力',
        a124: '登録された認証端末',
        a125: '表示内容',
        a126: '通行パターンコピー',
        a127: '認証端末編集',
        a128: '元の通行パターンをコピーする',
        a129: 'ストライプデータ',
        a130: '企業名',
        a131: '建物名',
        a132: 'フロア・テナント名',
        a133: 'デバイス名',
        a134: '認証端末名',
        a135: '出入りタイプ',
        a136: '警戒設定',
        a137: 'なし',
        a138: 'あります',
        a139: 'スケジュール名',
        a140: 'カレンダー名',
        a141: '通行モードが選択されていない',
        a142: 'コピーする通行パターンを選択してください',
        a143: '個人情報リスト',
        a144: '現在のページのエクスポート',
        a145: 'すべてエクスポート',
        a146: '個人情報のエクスポート',
        a147: '削除',
        a148: '人員編集',
        a149: '選択操作',
        a150: '管理コード',
        a151: '名前',
        a152: '部門',
        a153: 'カード番号',
        a154: '国籍',
        a155: '登録日',
        a156: '関連建築',
        a157: '所属1',
        a158: '所属2',
        a159: '所属3',
        a160: '所属4',
        a161: '所属5',
        a162: '操作',
        a163: '削除することを決定します'
    }
}