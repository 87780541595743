<!--主页侧边栏--报警按钮-->
<template>
  <div id="warningbutton" @click="goInfo" :title="$t('sidebar.e2')">    
    <a-popover :placement="placement" v-if="msgs.length !== 0" :getPopupContainer="(triggerNode) => triggerNode.parentNode">
      <template slot="content">
        <ul>
          <router-link v-for="(item, index) in msgs.slice(0,10)" :key="index" :to="url">
            <li class="activeClass" @click="change(item,index)">
              {{ $t(item.title) + $t(item.content)}}
            </li>
          </router-link>
        </ul>
      </template>
      <div>
        <a-badge :count="numberOfMsg" :number-style="{ backgroundColor: '#EA6914' }" :offset="[-10,10]">
          <div :class="bgimage"></div>
        </a-badge>
      </div>        
    </a-popover>
    <div :class="bgimage" v-else></div>
    <audio id="audio" :src="warningAudio"></audio>
  </div>  
</template>

<script>
export default {
  props: {
    msgs: {
			type: Array,
			default:function(){
				return [];
			}
    },
    numberOfMsg:{
      type: Number,
      default: 0,
    },
    placement:{
      type:String,
      default:'right'
    }
  },
  data() {
    return {
      warningAudio:require("../../../../public/video/wave/bell.wav"),
      // warningAudio:require("../../../../public/video/alarm.mp3"),//src 后面的链接是保存在public/video文件夹下的一段报警声   
      url:"/warning/warning",
    };
  },
  watch: {
    "numberOfMsg":{
      handler(newValue,oldValue){
          console.log("numberOfMsg change：",newValue);
          if(newValue>0){
            this.playaudio();
          }
      },
      deep:true,
    }
  },
  computed: {
    bgimage: function () {
      let route = this.$route.matched[1].path;
      let image = null;
      switch (route) {
        case "/warning":
          return 'selectedImage'
          break;
        default:
        return 'normalImage'
          break;
      } 
    }, 
    pluginsDataChange () {
      // console.log("WarningButton computed pluginsDataChange:",this.msgs); 
      const dataStr=JSON.stringify(this.msgs);             
      let data = JSON.parse(dataStr);
      return dataStr;
    },
  },
  methods:{
    // 点击时收起展开框
    change(item, index) {
      console.log("WarningButton change item：",item);
      console.log("WarningButton change index",index);
      this.$router.push({
        path: "/warning/warning",
        query: { msg: item  },
      });      
      this.msgs.splice(index, 1);
      if(this.msgs.length==0)
        this.$emit('clearMsg');      
    },
    playaudio() {
      var audio = document.getElementById('audio')
      audio.play()  //播放一段音频
      //audio.pause()  //暂停一段音频
		},
    goInfo(){
      this.isNewS=false;
      this.$router.push({
        path: "/warning/warning",
      });
    }
  }
}
</script>

<style scoped>
#warningbutton {
  width: 80px;
  height: 80px;
  flex-shrink:0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
}
ul{
  max-height: 300px;
  padding: 10px 0px;
  overflow: auto;
}
.activeClass {
  list-style: none;
  white-space: nowrap;
  height: 40px;
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 3px;
  overflow: hidden;
  background-color: #ffffff;
  transition: 300ms;
  color: #000;
}
.activeClass:hover{
  color: #7682ce;
}
.normalImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.2);
  border-radius: 50%;
  background-color: #f2f4f7;
  background-image: url('../../../../public/images/sidebar/alarm_normal.png');
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: 13px 10px;
  transition: 400ms ease-out;
}
.normalImage:hover{
  box-shadow: 0 0px 7px #7682ce;
}
.selectedImage{
  width: 55px;
  height: 55px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  border-radius: 50%;
  background-color: #7682ce;
  background-image: url('../../../../public/images/sidebar/alarm_selected.png');
  background-repeat: no-repeat;
  background-size: 30px 30px;
  background-position: 13px 10px;
  transition: 400ms ease-out;
}
div /deep/.ant-popover-inner-content{
  padding: 0px;
}
div /deep/.ant-popover{
  z-index: 999;
}
</style>