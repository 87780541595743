// 能耗报表设置
module.exports = {
    energyConsumptionReportSettings:{
        a1:'能耗报表设置',
        a2:'模版列表',
        a3:'添加',
        a4:'合计总用量',
        a5:'展示明细',
        a6:'设置',
        a7:'取消',
        a8:'确认',
        a9:'名称：',
        a10:'请输入名称',
        a11:'名称修改',
        a12:'确定删除模版'
    }
}