// 门禁模块 -- 设备页
module.exports = {
    doorequipment:{
        a1: 'Type selection',
        a2: 'Floor/tenant selection',
        a3: 'All',
        a4: 'Door',
        a5: 'Authentication terminal',
        a6: 'Normal',
        a7: 'Communication exception',
        a8: 'Maintenance',
        a9: 'Machine exception',
        a10: 'Fault/alarm',
        a11: 'Maintenance alarm/upper and lower limit alarm · abnormal operation',
        a12: 'Loading....',
        a13: 'Wrong card type',
        a14: 'If the operation is successful, the door open button will return to the closed state after 5 seconds',
        a15: 'Door opening operation failed',
        a16: 'Icon',
        a17: 'Table',
        a18: 'Expand',
        a19: 'Put away',
        a20: 'Details',
        a21: 'data',
        a22: 'Floor/tenant',
        a23: 'Device name',
        a24: 'Switch',
        a25: 'Video',
        a26: 'Status',
        a27: 'Operation',
        a28:'Fault'
    }
}