// 电能--能耗流向
module.exports = {
    energyFlow:{
        a1:'Energy Consumption Flow Chart',
        a2:'Energy consumption',
        a3:'Carbon footprint',
        a4:'Customer',
        a5:'Building',
        a6:'Site',
        a7:'Display content',
        a8:'Display grouping',
        a9:'Display device',
        a10:'Display tenants',
        a11:'year',
        a12:'month',
        a13:'day',
        a14:'custom start month',
        a15:'January',
        a16:'February',
        a17:'March',
        a18:'April',
        a19:'May',
        a20:'June',
        a21:'July',
        a22:'August',
        a23:'September ',
        a24:'October',
        a25:'November',
        a26:'December',
        a27:'query',
        a28:'please select criteria to query',
        a29:'metering point',
    }
}