//顧客統計：日文
module.exports = {
    customerStatistics:{
        a1: '顧客情報統計',
        a2: '顧客情報',
        a3: '顧客数',
        a4: '建築数',
        a5: 'サイト数',
        a6: 'サービス数',
        a7: 'フロア/テナント数',
        a8: '設備数',
        a9: '設備情報統計',
        a10: '設備の種類',
        a11: '設備の種類を選択してください！',
        a12: '設備数',
        a13: 'サービス情報統計',
        a14: 'サービス名',
        a15: '顧客名',
        a16: '建物名',
        a17: '建築情報',
        a18: '建築情報を選んでください！',
        a19: 'お客様情報を選択してください！',
        a20: 'を選択してください',
        a21: 'サイト名',
        a22: 'サイト情報',
        a23: 'サイト情報を選択してください！',
        a24: 'サービスタイプを選択してください！',
        a25: 'フロア／テナント情報',
        a26: 'フロア/テナント名',
        a27: '全設備の種類パケット合計',
        a28: 'クライアント設備の種類パケット合計',
        a29: '建築設備タイプグループ合計',
        a30: 'サイト設備の種類パケット合計',
        a31: '全設備合計',
        a32: '顧客機器合計',
        a33: '建築設備合計',
        a34: 'サイトデバイス合計',
        a35: 'すべてのサイトデバイスの詳細',
        a36: '顧客サイト装置詳細',
        a37: '建築サイト設備詳細',
        a38: 'サイトデバイス詳細',
        a39: 'サービス情報',
        a40: 'デバイス情報',
        a41: '統計分類を選択してください！',
        a42: '合計',
        a43: '現在$1～$2/合計$3データ',
        a44:'',
        a45:'',
        a46:'',
        a47:'',
        a48:'',
        a49:'',
    }
}