// 能效KPI
module.exports = {
    analyseKPI:{
        a1:'加載中….',
        a2:'能效KPI',
        a3:'蒐索',
        a4:'實際數值',
        a5:'目標達成',
        a6:'査詢',
        a7:'CSV下載',
        a8:'請選擇設備進行査詢',
        a9:'未選擇設備',
        a10:'年度',
        a11:'月度',
        a12:'日度',
        a13:'KPI報表',
        a14:'年目標值',
        a15:'月目標值',
        a16:'月實際值',
        a17:'年累積值',
        a18:'日目標值',
        a19:'日實際值',
        a20:'月累積值',
        a21:'月度值',
        a22:'年度值',
        a23:'已達成目標',
        a24:'未達成目標',
        a25:'累計用電進度',
        a26:'預計用電進度',
        a27:'用電量',
        a28:'目標值',
        a29:'累積值',
        a30:'小時使用量',
        a31:'累積量',
        a32:'日度值',
        a33:'組織：'
    }
}