module.exports = {
    weather:{
        WEATHER_100:'晴',
        WEATHER_101:'多云',
        WEATHER_102:'少云',
        WEATHER_103:'晴间多云',
        WEATHER_104:'阴',
        WEATHER_150:'晴',
        WEATHER_151:'多云',
        WEATHER_152:'少云',
        WEATHER_153:'晴间多云',
        WEATHER_154:'阴',
        WEATHER_300:'阵雨',
        WEATHER_301:'强阵雨',
        WEATHER_302:'雷阵雨',
        WEATHER_303:'强雷阵雨',
        WEATHER_304:'雷阵雨伴有冰雹',
        WEATHER_305:'小雨',
        WEATHER_306:'中雨',
        WEATHER_307:'大雨',
        WEATHER_308:'极端降雨',
        WEATHER_309:'毛毛雨/细雨',
        WEATHER_310:'暴雨',
        WEATHER_311:'大暴雨',
        WEATHER_312:'特大暴雨',
        WEATHER_313:'冻雨',
        WEATHER_314:'小到中雨',
        WEATHER_315:'中到大雨',
        WEATHER_316:'大到暴雨',
        WEATHER_317:'暴雨到大暴雨',
        WEATHER_318:'大暴雨到特大暴雨',
        WEATHER_350:'阵雨',
        WEATHER_351:'强阵雨',
        WEATHER_399:'雨',
        WEATHER_400:'小雪',
        WEATHER_401:'中雪',
        WEATHER_402:'大雪',
        WEATHER_403:'暴雪',
        WEATHER_404:'雨夹雪',
        WEATHER_405:'雨雪天气',
        WEATHER_406:'阵雨夹雪',
        WEATHER_407:'阵雪',
        WEATHER_408:'小到中雪',
        WEATHER_409:'中到大雪',
        WEATHER_410:'大到暴雪',
        WEATHER_456:'阵雨夹雪',
        WEATHER_457:'阵雪',
        WEATHER_499:'雪',
        WEATHER_500:'薄雾',
        WEATHER_501:'雾',
        WEATHER_502:'霾',
        WEATHER_503:'扬沙',
        WEATHER_504:'浮尘',
        WEATHER_507:'沙尘暴',
        WEATHER_508:'强沙尘暴',
        WEATHER_509:'浓雾',
        WEATHER_510:'强浓雾',
        WEATHER_511:'中度霾',
        WEATHER_512:'重度霾',
        WEATHER_513:'严重霾',
        WEATHER_514:'大雾',
        WEATHER_515:'特强浓雾',
        WEATHER_800:'新月',
        WEATHER_801:'蛾眉月',
        WEATHER_802:'上弦月',
        WEATHER_803:'盈凸月',
        WEATHER_804:'满月',
        WEATHER_805:'亏凸月',
        WEATHER_806:'下弦月',
        WEATHER_807:'残月',
        WEATHER_900:'热',
        WEATHER_901:'冷',
        WEATHER_999:'未知',
        WEATHER_1001:'台风',
        WEATHER_1002:'龙卷风',
        WEATHER_1003:'暴风雨',
        WEATHER_1004:'暴风雪',
        WEATHER_1005:'寒潮',
        WEATHER_1006:'大风',
        WEATHER_1007:'沙尘暴警报',
        WEATHER_1008:'低温冷冻',
        WEATHER_1009:'高温',
        WEATHER_1010:'热浪',
        WEATHER_1011:'干热空气',
        WEATHER_1012:'下击暴流',
        WEATHER_1013:'雪崩',
        WEATHER_1014:'闪电',
        WEATHER_1015:'冰雹',
        WEATHER_1016:'严寒天气',
        WEATHER_1017:'大雾警告',
        WEATHER_1018:'低层风切变',
        WEATHER_1019:'烟雾警告',
        WEATHER_1020:'雷声阵阵',
        WEATHER_1021:'道路结冰',
        WEATHER_1022:'干旱',
        WEATHER_1023:'海上大风',
        WEATHER_1024:'中暑',
        WEATHER_1025:'野火',
        WEATHER_1026:'草原火灾',
        WEATHER_1027:'冻结',
        WEATHER_1028:'空间天气',
        WEATHER_1029:'严重的空气污染',
        WEATHER_1030:'低温雨雪',
        WEATHER_1031:'强对流',
        WEATHER_1032:'臭氧',
        WEATHER_1033:'大雪警告',
        WEATHER_1034:'寒冷警告',
        WEATHER_1035:'连绵的雨',
        WEATHER_1036:'内涝',
        WEATHER_1037:'地质灾害',
        WEATHER_1038:'暴雨',
        WEATHER_1039:'严重降温',
        WEATHER_1040:'雪灾',
        WEATHER_1041:'草原野火',
        WEATHER_1042:'医学气象学',
        WEATHER_1043:'雷雨',
        WEATHER_1044:'学校停课',
        WEATHER_1045:'工厂关闭',
        WEATHER_1046:'海上风险',
        WEATHER_1047:'春尘',
        WEATHER_1048:'降温',
        WEATHER_1049:'台风和暴雨',
        WEATHER_1050:'严寒',
        WEATHER_1051:'沙尘',
        WEATHER_1052:'海上雷暴',
        WEATHER_1053:'海雾',
        WEATHER_1054:'海雷',
        WEATHER_1055:'海上台风',
        WEATHER_1056:'低温',
        WEATHER_1057:'道路冰雪',
        WEATHER_1058:'雷雨和大风',
        WEATHER_1059:'连续低温',
        WEATHER_1061:'强尘',
        WEATHER_1064:'短暂的暴雨',
        WEATHER_1101:'洪水',
        WEATHER_1302:'泥石流',
        WEATHER_1402:'风暴潮',
        WEATHER_1601:'非常热的天气',
        WEATHER_1602:'强烈季候风信号',
        WEATHER_1603:'滑坡',
        WEATHER_1604:'热带气旋',
        WEATHER_1605:'火灾危险',
        WEATHER_1606:'新界北部水浸',
        WEATHER_1607:'寒冷的天气',
        WEATHER_2001:'风',
        WEATHER_2002:'冰雪',
        WEATHER_2003:'雾',
        WEATHER_2004:'沿海事件',
        WEATHER_2005:'森林火灾',
        WEATHER_2006:'下雨警告',
        WEATHER_2007:'雨洪',
        WEATHER_2008:'冰雨结冰',
        WEATHER_2009:'地面霜',
        WEATHER_2010:'扬尘风',
        WEATHER_2011:'强地面风',
        WEATHER_2012:'热天',
        WEATHER_2013:'温暖的夜晚',
        WEATHER_2014:'冷天',
        WEATHER_2015:'雷雨和闪电',
        WEATHER_2016:'冰雹',
        WEATHER_2017:'海域警告',
        WEATHER_2018:'渔民警告',
        WEATHER_2019:'低湿度',
        WEATHER_2020:'积雨',
        WEATHER_2021:'危险海浪警告',
        WEATHER_2022:'海上风力警报',
        WEATHER_2023:'道路天气警报',
        WEATHER_2024:'雷雨',
        WEATHER_2025:'雷雨雾',
        WEATHER_2026:'有害风',
        WEATHER_2027:'草原火灾条件',
        WEATHER_2028:'天气预报',
        WEATHER_9998:'恶劣天气警告',
        WEATHER_9999:'警告默认',
    }
}