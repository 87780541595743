<!--警报管理按钮-->
<template>
  <div id="alarmbut" :title="$t('sidebar.e2')">
    <router-link to="/homepage/management/warning/equipment">
      <img :src="getImage" />
    </router-link>
  </div>
</template>

<script>
export default {
  name:'alarmbut',
  data() {
    return {
      selectedImage: require("../../../../public/images/sidebar/alarm_selected.png"),
      normalImage: require("../../../../public/images/sidebar/alarm_normal.png"),
      url: "/homepage/management/warning/equipment",
    };
  },
  computed: {
    getImage: function () {
      const length = this.$route.matched.length;
      if (length <= 2) {
        return this.normalImage;
      } else {
        const route = this.$route.matched[2].path;
        if (route == this.url) {
          return this.selectedImage;
        } else {
          return this.normalImage;
        }
      }
    },
  },
};
</script>

<style scoped>
#alarmbut {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
#alarmbut img {
  width: 50px;
}
</style>