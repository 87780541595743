import { getBelonging, getPersonList } from '@/api/info'

const state = {
    homepage_loading: false,
}

const mutations = {
    HomepageLoading: (state, data) => {
        state.cardList = data
    },
}

export default {
    namespaced: true,
    state,
    mutations,
}
