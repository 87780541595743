module.exports = {
    weather:{
        WEATHER_100:'晴',
        WEATHER_101:'多雲',
        WEATHER_102:'少雲',
        WEATHER_103:'晴間多雲',
        WEATHER_104:'陰',
        WEATHER_150:'晴',
        WEATHER_151:'多雲',
        WEATHER_152:'少雲',
        WEATHER_153:'晴間多雲',
        WEATHER_154:'陰',
        WEATHER_300:'陣雨',
        WEATHER_301:'强陣雨',
        WEATHER_302:'雷陣雨',
        WEATHER_303:'强雷陣雨',
        WEATHER_304:'雷陣雨伴有冰雹',
        WEATHER_305:'小雨',
        WEATHER_306:'中雨',
        WEATHER_307:'大雨',
        WEATHER_308:'極端降雨',
        WEATHER_309:'毛毛雨/細雨',
        WEATHER_310:'豪雨',
        WEATHER_311:'大暴雨',
        WEATHER_312:'特大豪雨',
        WEATHER_313:'凍雨',
        WEATHER_314:'小到中雨',
        WEATHER_315:'中到大雨',
        WEATHER_316:'大到豪雨',
        WEATHER_317:'豪雨到大暴雨',
        WEATHER_318:'大暴雨到特大豪雨',
        WEATHER_350:'陣雨',
        WEATHER_351:'强陣雨',
        WEATHER_399:'雨',
        WEATHER_400:'小雪',
        WEATHER_401:'中雪',
        WEATHER_402:'大雪',
        WEATHER_403:'暴雪',
        WEATHER_404:'雨夾雪',
        WEATHER_405:'雨雪天氣',
        WEATHER_406:'陣雨夾雪',
        WEATHER_407:'陣雪',
        WEATHER_408:'小到中雪',
        WEATHER_409:'中到大雪',
        WEATHER_410:'大到暴雪',
        WEATHER_456:'陣雨夾雪',
        WEATHER_457:'陣雪',
        WEATHER_499:'雪',
        WEATHER_500:'薄霧',
        WEATHER_501:'霧',
        WEATHER_502:'霾',
        WEATHER_503:'揚沙',
        WEATHER_504:'浮塵',
        WEATHER_507:'沙塵暴',
        WEATHER_508:'强沙塵暴',
        WEATHER_509:'濃霧',
        WEATHER_510:'强濃霧',
        WEATHER_511:'中度霾',
        WEATHER_512:'重度霾',
        WEATHER_513:'嚴重霾',
        WEATHER_514:'大霧',
        WEATHER_515:'特强濃霧',
        WEATHER_800:'新月',
        WEATHER_801:'蛾眉月',
        WEATHER_802:'上弦月',
        WEATHER_803:'盈凸月',
        WEATHER_804:'滿月',
        WEATHER_805:'虧凸月',
        WEATHER_806:'下弦月',
        WEATHER_807:'殘月',
        WEATHER_900:'熱',
        WEATHER_901:'冷',
        WEATHER_999:'未知',
        WEATHER_1001:'颱風',
        WEATHER_1002:'龍捲風',
        WEATHER_1003:'暴風雨',
        WEATHER_1004:'暴風雪',
        WEATHER_1005:'寒潮',
        WEATHER_1006:'大風',
        WEATHER_1007:'沙塵暴警報',
        WEATHER_1008:'低溫冷凍',
        WEATHER_1009:'高溫',
        WEATHER_1010:'熱浪',
        WEATHER_1011:'幹熱空氣',
        WEATHER_1012:'下擊暴流',
        WEATHER_1013:'雪崩',
        WEATHER_1014:'閃電',
        WEATHER_1015:'冰雹',
        WEATHER_1016:'嚴寒天氣',
        WEATHER_1017:'大霧警告',
        WEATHER_1018:'低層風切變',
        WEATHER_1019:'煙霧警告',
        WEATHER_1020:'雷聲陣陣',
        WEATHER_1021:'道路結冰',
        WEATHER_1022:'乾旱',
        WEATHER_1023:'海上大風',
        WEATHER_1024:'中暑',
        WEATHER_1025:'野火',
        WEATHER_1026:'草原火灾',
        WEATHER_1027:'凍結',
        WEATHER_1028:'空間天氣',
        WEATHER_1029:'嚴重的空氣污染',
        WEATHER_1030:'低溫雨雪',
        WEATHER_1031:'強對流',
        WEATHER_1032:'臭氧',
        WEATHER_1033:'大雪警告',
        WEATHER_1034:'寒冷警告',
        WEATHER_1035:'連綿的雨',
        WEATHER_1036:'內澇',
        WEATHER_1037:'地質災害',
        WEATHER_1038:'豪雨',
        WEATHER_1039:'嚴重降溫',
        WEATHER_1040:'雪灾',
        WEATHER_1041:'草原野火',
        WEATHER_1042:'醫學氣象學',
        WEATHER_1043:'雷雨',
        WEATHER_1044:'學校停課',
        WEATHER_1045:'工廠關閉',
        WEATHER_1046:'海上風險',
        WEATHER_1047:'春塵',
        WEATHER_1048:'降溫',
        WEATHER_1049:'颱風和豪雨',
        WEATHER_1050:'嚴寒',
        WEATHER_1051:'沙塵',
        WEATHER_1052:'海上雷暴',
        WEATHER_1053:'海霧',
        WEATHER_1054:'海雷',
        WEATHER_1055:'海上颱風',
        WEATHER_1056:'低溫',
        WEATHER_1057:'道路冰雪',
        WEATHER_1058:'雷雨和大風',
        WEATHER_1059:'連續低溫',
        WEATHER_1061:'强塵',
        WEATHER_1064:'短暫的豪雨',
        WEATHER_1101:'洪水',
        WEATHER_1302:'泥石流',
        WEATHER_1402:'風暴潮',
        WEATHER_1601:'非常熱的天氣',
        WEATHER_1602:'强烈季候風訊號',
        WEATHER_1603:'滑坡',
        WEATHER_1604:'熱帶氣旋',
        WEATHER_1605:'火灾危險',
        WEATHER_1606:'新界北部水浸',
        WEATHER_1607:'寒冷的天氣',
        WEATHER_2001:'風',
        WEATHER_2002:'冰雪',
        WEATHER_2003:'霧',
        WEATHER_2004:'沿海事件',
        WEATHER_2005:'森林火灾',
        WEATHER_2006:'下雨警告',
        WEATHER_2007:'雨洪',
        WEATHER_2008:'冰雨結冰',
        WEATHER_2009:'地面霜',
        WEATHER_2010:'揚塵風',
        WEATHER_2011:'强地面風',
        WEATHER_2012:'熱天',
        WEATHER_2013:'溫暖的夜晚',
        WEATHER_2014:'冷天',
        WEATHER_2015:'雷雨和閃電',
        WEATHER_2016:'冰雹',
        WEATHER_2017:'海域警告',
        WEATHER_2018:'漁民警告',
        WEATHER_2019:'低濕度',
        WEATHER_2020:'積雨',
        WEATHER_2021:'危險海浪警告',
        WEATHER_2022:'海上風力警報',
        WEATHER_2023:'道路天氣警報',
        WEATHER_2024:'雷雨',
        WEATHER_2025:'雷雨霧',
        WEATHER_2026:'有害風',
        WEATHER_2027:'草原火灾條件',
        WEATHER_2028:'天氣預報',
        WEATHER_9998:'惡劣天氣警告',
        WEATHER_9999:'警告默認',
    }
}