export default [
    {
        path: '/homepage/energy', //能源模块--能源主页
        component: () => import('@/views/energy/energyLayout'),
        meta: { hidden: true },
        children: [
          // {
          //   path: '/homepage/energy/equipment/icon', //能源模块--设备页
          //   name: 'ENE_ICON',
          //   component: () => import('@/views/energy/equipment'),
          //   meta: { hidden: true }
          // },  
          {
            path: '/homepage/energy/equipment', //能源模块--设备页
            name: 'ENE_EQUIPMENT',
            component: () => import('@/views/energy/Ichnography'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/Ichnography', //能源模块--平面图
            name: 'ENE_ICON',
            component: () => import('@/views/energy/Ichnography'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/treeModule', //能源模块--拓扑图
            name: 'ENE_TOPOLOGY',
            component: () => import('@/views/energy/treeModule'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/equipmentList', //能源模块--设备列表页
            name: 'ENE_EQUIPMENTLIST',
            component: () => import('@/views/energy/equipmentList'),
            meta: { hidden: true }
          },
          //警报 2.1
          {
            path: '/homepage/energy/alarm', //能源模块--警报记录页
            name: 'ENE_ALARM',
            component: () => import('@/views/energy/alarm'),
            meta: { hidden: true }
          },
          //记录 3.1
          {
            path: '/homepage/energy/history', //能源模块--趋势记录
            name: 'ENE_HISTORY',
            component: () => import('@/views/energy/history'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/input', //能源模块--实际值输入
            name: 'ENE_ENE_INPUT',
            component: () => import('@/views/energy/analysis/energyActuaInputData'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/uninput', //能源模块--未输入确认
            name: 'ENE_MINYUURYOKU_KAKUNIN',
            component: () => import('@/views/energy/analysis/energyActuaUnInputData'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/saving', //能源模块--节能管理
            name: 'ENE_SHOUENE',
            component: () => import('@/views/energy/analysis/energyAnalysisData'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/annual', //能源模块--统计年度比较
            name: 'ENE_TOUKEINENDO',
            component: () => import('@/views/energy/analysis/energyAnalysisYearData'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/building', //能源模块--建筑物比较
            name: 'ENE_SITEHIKAKU',
            component: () => import('@/views/energy/analysis/energyAnalysisBuildData'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/statistics', //能源模块--统计数据
            name: 'ENE_TOUKEIDATA',
            component: () => import('@/views/energy/data/energyPointStatistics'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/comparisonChart', //能源模块--对比图表
            name: 'ENE_SEKISOUDATA',
            component: () => import('@/views/energy/data/energyComparisonData'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/actualUsage', //能源模块--实际使用量
            name: 'ENE_SIYOURYOU',
            component: () => import('@/views/energy/data/energyActualUsage'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/dataOut', //能源模块--数据输出
            name: 'ENE_DATA_OUPUT',
            component: () => import('@/views/energy/data/energyDataOutput'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/daily', //能源模块-日报
            name: 'ENE_NIPPOU',
            component: () => import('@/views/energy/data/energyDailyData'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/monthly', //能源模块--月报
            name: 'ENE_GEPPOU',
            component: () => import('@/views/energy/data/energyMonthlyData'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/yearly', //能源模块-年报
            name: 'ENE_NENPOU',
            component: () => import('@/views/energy/data/energyYearlyData'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/report', //能源模块- 综合报表
            name: 'ENE_REPORT',
            component: () => import('@/views/energy/data/energyMeterReading'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/setup/reductionTarget', //能源模块--设置模块--削减目标设置页
            name: 'ENE_SAKUGEN',
            component: () => import('@/views/energy/setup/reductionTarget'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/setup/basicSetup', //能源模块--设置模块--基本设置页(原单位指标设置)
            name: 'ENE_KIHON',
            component: () => import('@/views/energy/setup/basicSetup'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/setup/intensityIndex', //能源模块--设置模块--原单位指标值设置
            name: 'ENE_GENTANNI',
            component: () => import('@/views/energy/setup/intensityIndex'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/setup/eneUseKind', //能源模块--设置模块--能源使用类型设置
            name: 'ENE_SHUBETU',
            component: () => import('@/views/energy/setup/energyUseKindSetup'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/setup/eneMeasurePoint', //能源模块--设置模块--计量点管理
            name: 'ENE_POINT_GUID',
            component: () => import('@/views/energy/setup/eneMeasurePoint'),
            meta: { hidden: true },
          },
          {
            path: '/homepage/energy/setup/eneMeasureGroup', //能源模块--设置模块--计量对象组管理
            name: 'ENE_GROUP_GUID',
            component: () => import('@/views/energy/setup/eneMeasureGroup'),
            meta: { hidden: true },
            
          },
          {
            path: '/homepage/energy/dailyGr', //能源模块-日报组
            name: 'ENE_NIPPOU_GR',
            component: () => import('@/views/energy/setup/eneDailyGr'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/monthlyGr', //能源模块--月报组
            name: 'ENE_GEPPOU_GR',
            component: () => import('@/views/energy/setup/eneMonthlyGr'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/yearlyGr', //能源模块-年报组
            name: 'ENE_NENPOU_GR',
            component: () => import('@/views/energy/setup/eneYearlyGr'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/calcPoint', //能源模块-运算点设置
            name: 'ENE_ENZANPOINT',
            component: () => import('@/views/energy/setup/eneCalcPoint'),
            meta: { hidden: true }
          },


          /*****************************************************************add by chw 20220511  *************************************************************/

          {
            path: '/homepage/energy/equivalentdata', //能源模块-设置-能源换算系数
            name: 'ENE_EQUIVALENT',
            component: () => import('@/views/energy/setup/energyEquiv'),
            meta: { hidden: true }
          },

          {
            path: '/homepage/energy/virtial/equipment', //能源模块-能耗管理-虚拟设备管理
            name: 'ENE_CONSUMPTION_VIRTUAL',
            component: () => import('@/views/energy/setup/eneVirtialEquipment'),
            meta: { hidden: true }
          },

          {
            path: '/homepage/energy/production/report', //能源模块-能耗管理-生产单耗报表
            name: 'ENE_CONSUMPTION_PRODUCTION_REPORT',
            component: () => import('@/views/energy/production/productionReport'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/production/consumption', //能源模块-能耗管理-生产单耗
            name: 'ENE_CONSUMPTION_PRODUCTION',
            component: () => import('@/views/energy/production/productionLine'),
            meta: { hidden: true }
          },


          {
            path: '/homepage/energy/consumption/build', //能源模块-能耗管理-站点能耗对比
            name: 'ENE_CONSUMPTION_SITE',
            component: () => import('@/views/energy/data/energySitesPointStatistics'),
            meta: { hidden: true }
          },



          {
              path: '/homepage/energy/trendDataOut', //能源模块-趋势数据--数据输出
              name: 'ENE_TREND_OUTPUT',
              component: () => import('@/views/common/loggingData'),
              meta: { hidden: true }
          },
          {
            path: '/homepage/energy/trendGr', //能源模块--趋势组
            name: 'ENE_TREND_GR',
            component: () => import('@/views/energy/trend/eneTrendGr'),
            meta: { hidden: true }
          },
          {
              path: '/homepage/energy/timeTrendGr', //能源模块--时间趋势组
              name: 'ENE_TIME_TREND_GR',
              component: () => import('@/views/energy/trend/eneTimeTrendGr'),
              meta: { hidden: true }
          },


          {
              path: '/homepage/energy/dataCompareTrend', //能源模块-趋势数据--数据比较趋势
              name: 'ENE_DATA_HIKAKU_TREND',
              component: () => import('@/views/energy/trend/eneTrendChart'),
              meta: { hidden: true }
          },  
          // {
          //     path: '/homepage/energy/dataCompareTrendList', //能源模块-趋势数据--数据比较趋势列表
          //     name: 'ENE_DATA_HIKAKU_TREND_LIST',
          //     component: () => import('@/views/common/trend/dataCompareTrendList'),
          //     meta: { hidden: true }
          // },
          {
              path: '/homepage/energy/timeTrendChart', //能源模块-趋势数据--时间设置趋势
              name: 'ENE_TIME_TREND',
              component: () => import('@/views/energy/trend/eneTimeTrendChart'),
              meta: { hidden: true }
          }, 
          // {
          //     path: '/homepage/energy/timeSetupTrendList', //能源模块-趋势数据--数据比较趋势列表
          //     name: 'ENE_TIME_TREND_LIST',
          //     component: () => import('@/views/common/trend/timeSetupTrendList'),
          //     meta: { hidden: true }
          // },  

          {
              path: '/homepage/energy/terminalEditor', //通用设备模块--设备编辑
              name: 'ENE_TREND_terminalEditor',
              component: () => import('@/views/common/terminalEditor'),
              meta: { hidden: true }
          },
          
          /*****************************************************************add by chw 20220511  *************************************************************/

          /***** begin, added by li_yj on 2022-06-06 *****/
          {
            path: '/homepage/energy/eneLinkage', // 设置--设备联动设置
            name: 'ENE_SETTING_EQUIPTMENT_LINKAGE',
            component: () => import('@/views/energy/setup/eneLinkage'),
            meta: { hidden: true }
          },
          /***** end, added by li_yj on 2022-06-06 *****/
          /***** begin, added by li_yj on 2022-07-06 *****/
          {
            path: '/homepage/energy/enePeakValleySet', // 设置--能源峰谷设置
            name: 'ENE_CONSUMPTION_P_V_SETTING',
            component: () => import('@/views/energy/setup/enePeakValleySet'),
            meta: { hidden: true }
          },
          /***** end, added by li_yj on 2022-07-06 *****/

          /***** add by lht 20220531 *****/
          {
            path: '/homepage/energy/topologySet', // 设置--拓扑图设置
            name: 'ENE_TPY',
            component: () => import('@/views/energy/setup/topologySet'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/analyseKPI', // 能效管理--能效KPI
            name: 'ENE_KPI_CHART',
            component: () => import('@/views/energy/analyseKPI'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/analyseKPISet', // 能效管理--能效KPI设置
            name: 'ENE_KPI_SETTING',
            component: () => import('@/views/energy/analyseKPISet'),
            meta: { hidden: true }
          },

          {
            path: '/homepage/energy/energyFlow', // 能耗管理--能耗流向图
            name: 'ENE_CONSUMPTION_SANKEY',
            component: () => import('@/views/energy/energyFlow'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/peakValleyAnalyse', // 能耗管理--峰谷能耗
            name: 'ENE_CONSUMPTION_P_V',
            component: () => import('@/views/energy/peakValleyAnalyse'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/cleanEnergyAnalysis', // 清洁能源--清洁能源
            name: 'ENE_CLEAN_CHART',
            component: () => import('@/views/energy/cleanEnergyAnalysis'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/cleanEnergyFlows', // 清洁能源--清洁能源流向
            name: 'ENE_CLEAN_FLOW',
            component: () => import('@/views/energy/cleanEnergyFlows'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/cleanEnergySetting', // 清洁能源--清洁能源设置
            name: 'ENE_CLEAN_SETTING',
            component: () => import('@/views/energy/cleanEnergySetting'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/energyEquipmentDetails', //能源模块--能源设备详情页
            component: () => import('@/views/energy/energyEquipmentDetails'),
            meta: { hidden: false }
          },
          {
            path: '/homepage/energy/energySignalDetails', //能源模块--能源设备信号点详情页
            component: () => import('@/views/energy/energySignalDetails'),
            meta: { hidden: false }
          },
          {
            path: '/homepage/energy/energyConsumptionReportSettings', //能源模块--能耗报表设置
            name: 'ENE_CONSUMPTION_REPORT_SETTING',
            component: () => import('@/views/energy/energyConsumptionReportSettings'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/automaticReport', //能源模块--自动报表
            name: 'ENE_CONSUMPTION_REPORT_AUTO',
            component: () => import('@/views/energy/automaticReport'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/eneKPIAlarmSetup', // 能效管理--能效KPI警报设置
            name: 'ENE_KPI_ALARM',
            component: () => import('@/views/energy/setup/eneKPIAlarmSetup.vue'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/eneKPITaskSetup', // 能效管理--能效KPI自动任务设置
            name: 'ENE_KPI_TASK',
            component: () => import('@/views/energy/setup/eneKPITaskSetup'),
            meta: { hidden: true }
          },
          {
            path: '/homepage/energy/scheduleGR', //通用设备模块--时间表
            name: 'ENE_SCEDULE_GR',
            component: () => import('@/views/common/controllGroup'),
            meta: { hidden: true }
          },
          {
              path: '/homepage/energy/schedule', //通用设备模块--时间表
              name: 'ENE_SCEDULE',
              component: () => import('@/views/light/lightTimetable'),
              meta: { hidden: true }
          },
          {
              path: '/homepage/energy/runSchedule', //通用设备模块--执行时间表控制
              name: "ENE_RUN_SCEDULE",
              component: () => import('@/views/light/lightRunTimetable'),
              meta: { hidden: true }
          },
        ]
      },
      // {
      //   path: '/homepage/energy/setup/eneOwnTenObject', //能源模块--计量点--本司/租户区分页
      //   name: 'ENE_SIYO_OWNER',
      //   component: () => import('@/views/energy/setup/eneOwnTenObject'),
      //   meta: { hidden: true }
      // },
      // {
      //   path: '/homepage/energy/setup/eneMeasureObject', //能源模块--计量点--计量对象区分页
      //   name: 'ENE_SIYO_KEIRYO',
      //   component: () => import('@/views/energy/setup/eneMeasureObject'),
      //   meta: { hidden: true }
      // },
      // {
      //   path: '/homepage/energy/setup/eneVirtialPoint', //能源模块--计量点--虚拟点页
      //   name: 'ENE_POINT_SET',
      //   component: () => import('@/views/energy/setup/eneVirtialPoint'),
      //   meta: { hidden: true }
      // },
      // {
      //   path: '/homepage/energy/energyEquipmentDetails', //能源模块--能源设备详情页
      //   component: () => import('@/views/energy/energyEquipmentDetails')
      // },
      // {
      //   path: '/homepage/energy/energySignalDetails', //能源模块--能源设备信号点详情页
      //   component: () => import('@/views/energy/energySignalDetails')
      // }
]